import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Button from '@material-ui/core/Button';
import articlesStyle from "../../assets/jss/material-dashboard-pro-react/views/articlesStyle.jsx";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from "@material-ui/core/Divider";
import { connect } from "react-redux";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Disclaimers from "../disclaimers/disclaimers-home-page.jsx";
import Categories from "../categories/categories-home-page.jsx";
import GoogleAnalytics from "../google-analytics/google-analytics-home-page.jsx";
import TokenHome from "../../scheduler/views/access-token-home.jsx";
import Templates from '../templates/templates-home-page.jsx';
import FaviconLogo from "../favicon-logo/favicon-logo-home-page.jsx";
import MapDomain from '../map-domain/map-domain.jsx';
import SocialAccount from '../social-account/social-account';
import PagesHome from '../pages/pages-home';
import DltWebsite from '../dlt-website/dlt-website-home-page';

class WebsiteManagement extends React.PureComponent {

  state = {
    disclaimers: false,
    categories: false,
    favicon: false,
    ga: false,
    platformIntegration: false,
    mapDomain: false,
    templates: false,
    facebookToken: false,
    socialaccount: false,
    PageHome: false,
    dltWebsite : false,
  }

  handleChange = (field) => {
    //let value = this.state[field];
    //console.log(`an`, value);
    this.setState((prev) => ({
      [field]: !prev[field],
    }));
  }

  render() {
    const { classes, featurePermision } = this.props;
    const isTrail = featurePermision && (featurePermision.indexOf('TRIAL') > -1);
    return (
      <>

        <ExpansionPanel expanded={this.state.mapDomain} onChange={() => { this.handleChange('mapDomain') }} className={classes.expansionContainer}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.column}>
              <Typography className={classes.articleTitle} style={{ textTransform:"uppercase", paddingLeft:'0px'}}>
              Map Custom Domain
              </Typography>
              <Typography className={classes.secondaryHeading}>
              Connect your Pubninja website with your custom domain name that you've bought from Godaddy, Namecheap, etc. Enter the domain name and follow the steps.
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <Divider
            style={{
              height: "1px",
              backgroundColor: '#ced3dc',
              margin: '0 auto',
              width: '95%',
            }} />
          {
            this.state.mapDomain
            &&
            <ExpansionPanelDetails>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12} >
                  <MapDomain />
                </GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          }
        </ExpansionPanel>

        <ExpansionPanel expanded={this.state.favicon} onChange={() => { this.handleChange('favicon') }} className={classes.expansionContainer}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.column}>
              <Typography className={classes.articleTitle} style={{ textTransform:"uppercase", paddingLeft:'0px'}}>
                Name and Logo
              </Typography>
              <Typography className={classes.secondaryHeading}>
                Update your website Name, Description and Logo
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <Divider
            style={{
              height: "1px",
              backgroundColor: '#ced3dc',
              margin: '0 auto',
              width: '95%',
            }} />
          {
            this.state.favicon
            &&
            <ExpansionPanelDetails>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12} >
                  <FaviconLogo />
                </GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          }
        </ExpansionPanel>

        <ExpansionPanel expanded={this.state.socialaccount} onChange={() => { this.handleChange('socialaccount') }} className={classes.expansionContainer}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.column}>
              <Typography className={classes.articleTitle} style={{ textTransform:"uppercase", paddingLeft:'0px'}}>
                Social Accounts
              </Typography>
              <Typography className={classes.secondaryHeading}>
                Add your social account's link on your website. Accounts you add here will show up on your website homepage and footer section.
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <Divider
            style={{
              height: "1px",
              backgroundColor: '#ced3dc',
              margin: '0 auto',
              width: '95%',
            }} />
          {
            this.state.socialaccount
            &&
            <ExpansionPanelDetails>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12} >
                  <SocialAccount />
                </GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          }
        </ExpansionPanel>

        <ExpansionPanel expanded={this.state.PageHome} onChange={() => { this.handleChange('PageHome') }} className={classes.expansionContainer}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.column}>
              <Typography className={classes.articleTitle} style={{ textTransform:"uppercase", paddingLeft:'0px'}}>
                Pages
              </Typography>
              <Typography className={classes.secondaryHeading}>
                Add pages on your website like "about-us", "contact-us". We have some default pages already setup for you, they will start showing up on website footer once you add content to them. You can add and host as many pages as you want.
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <Divider
            style={{
              height: "1px",
              backgroundColor: '#ced3dc',
              margin: '0 auto',
              width: '95%',
            }} />
          {
            this.state.PageHome
            &&
            <ExpansionPanelDetails>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12} >
                  <PagesHome />
                </GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          }
        </ExpansionPanel>

        <ExpansionPanel expanded={this.state.categories} onChange={() => { this.handleChange('categories') }} className={classes.expansionContainer}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.column}>
              <Typography className={classes.articleTitle} style={{ textTransform:"uppercase", paddingLeft:'0px'}}>
                Categories
              </Typography>
              <Typography className={classes.secondaryHeading}>
                Define your website categories and subcategories. These categories show up in the navigation section of your website.
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <Divider
            style={{
              height: "1px",
              backgroundColor: '#ced3dc',
              margin: '0 auto',
              width: '95%',
            }} />
          {
            this.state.categories
            &&
            <ExpansionPanelDetails>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12} >
                  <Categories />
                </GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          }
        </ExpansionPanel>

        <ExpansionPanel expanded={this.state.ga} onChange={() => { this.handleChange('ga') }} className={classes.expansionContainer}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.column}>
              <Typography className={classes.articleTitle} style={{ textTransform:"uppercase", paddingLeft:'0px'}}>
                Google Analytics
              </Typography>
              <Typography className={classes.secondaryHeading}>
                Connect your Google Analytics account with your website. We will add the GA script on all the website pages.
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <Divider
            style={{
              height: "1px",
              backgroundColor: '#ced3dc',
              margin: '0 auto',
              width: '95%',
            }} />
          {
            this.state.ga
            &&
            <ExpansionPanelDetails>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12} >
                  <GoogleAnalytics />
                </GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          }
        </ExpansionPanel>

        {/* <ExpansionPanel expanded={this.state.facebookToken} onChange={() => { this.handleChange('facebookToken') }} className={classes.expansionContainer}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.articleTitle}>Facebook Token</Typography>
          </ExpansionPanelSummary>
          <Divider
            style={{
              height: "1px",
              backgroundColor: '#ced3dc',
              margin: '0 auto',
              width: '95%',
            }} />
          {
            this.state.facebookToken
            &&
            <ExpansionPanelDetails>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12} >
                  <TokenHome />
                </GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          }
        </ExpansionPanel> */}

        {!isTrail && <ExpansionPanel expanded={this.state.disclaimers} onChange={() => { this.handleChange('disclaimers') }} className={classes.expansionContainer}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.articleTitle} style={{paddingLeft:'0px'}}>DISCLAIMERS</Typography>
          </ExpansionPanelSummary>
          <Divider
            style={{
              height: "1px",
              backgroundColor: '#ced3dc',
              margin: '0 auto',
              width: '95%',
            }} />
          {
            this.state.disclaimers
            &&
            <ExpansionPanelDetails>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12} >
                  <Disclaimers />
                </GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          }
        </ExpansionPanel>}

        <ExpansionPanel expanded={this.state.dltWebsite} onChange={() => { this.handleChange('dltWebsite') }} className={classes.expansionContainer}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.column}>
              <Typography className={classes.articleTitle} style={{ textTransform:"uppercase", paddingLeft:'0px'}}>
                Delete My Website
              </Typography>
              <Typography className={classes.secondaryHeading}>
                Delete your website permanently.
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <Divider
            style={{
              height: "1px",
              backgroundColor: '#ced3dc',
              margin: '0 auto',
              width: '95%',
            }} />
          {
            this.state.dltWebsite
            &&
            <ExpansionPanelDetails>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12} >
                  <DltWebsite />
                </GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          }
        </ExpansionPanel>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  const {
    domain: {
      featurePermision
    }
  } = store.login;
  return {
    featurePermision,
  }
}

const mapDispatchToProps = (dispatch) => {

  return {
  }
}

export default withStyles(articlesStyle)(connect(mapStateToProps, mapDispatchToProps)(WebsiteManagement));
