import React from "react";
import Button from "components/CustomButtons/Button.jsx";
const mandatoryPermissions = ['email', 'manage_pages', 'publish_pages', 'read_insights'];

class MangageSocialApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
    };
    this.connectFB = this.connectFB.bind(this);
  }


  getDebugTokenDB(obj){
    return new Promise((res, rej) => {
      window.FB.api(`/debug_token?input_token=${obj.access_token}`, r => {
        if (r.error) return rej(r);
        obj.debug = r;
        return res(obj);
      });
    });
  }

  debugToken(pageDetails){
    return new Promise((res, rej) => {
      Promise.all(pageDetails.map((obj) => this.getDebugTokenDB(obj))).then((d)=>res(d)).catch(e=>rej(e));
    });
  }

  getAllAccounts(grantedPermissions) {
    return new Promise((res, rej) => {
      window.FB.api('/me/accounts', (r) => {
        if (r.data && r.data.length) {
          return res(r.data);
        }
        return rej(`Error while getting Facebook accounts`);
      });
    });
  }

  checkMandatoryPermissions(auth) {
    return new Promise((res, rej) => {
      window.FB.api('/me/permissions', (r) => {
        if (r.data && r.data.length) {
          const grantedPermissions = [];
          Object.keys(r.data).forEach((index) => {
            const obj = r.data[index];
            if (obj.status === 'granted') {
              grantedPermissions.push(obj.permission);
            }
          });
          const permDiff = mandatoryPermissions.filter((perm) => grantedPermissions.indexOf(perm) === -1 );
          if (permDiff.length === 0) {
            return res(grantedPermissions);
          } else {
            return rej(`Please allow mandatory permissions ${permDiff.join(', ')}`);
          }
        } else {
          return rej(`Unknown Error occurred`);
        }
      });
    });
  }

  fbLogin() {
    this.setState({error: ''});
    return new Promise((resolve, reject) => {
      window.FB.login((res)=>{
        if (res.status === 'connected') {
          return resolve(res.authResponse);
        }
        return reject(`Login error, pls login with facebook`);
      }, {scope: mandatoryPermissions.join(',')});
    });
  }

  connectFB() {
    if (window.FB) {
      this.fbLogin()
        .then((auth) => {
          return this.checkMandatoryPermissions(auth)
        }).then((grantedPermissions) => {
          return this.getAllAccounts(grantedPermissions)
        }).then((f) => {
          return this.debugToken(f)
        }).then(data => {
          this.props.pages({data});
        }).catch((e) => {
          this.setState({error: e});
          return new Error(e);
          //this.props.pages({error: e});
        });
    } else {
      // Load FB script
    }
  }

  render() {
    return <div>
      <Button color='facebook' onClick={this.connectFB}> Continue with Facebook </Button>
      <div style={{color: 'red'}}>{this.state.error}</div>
    </div>;
  }
}

export default MangageSocialApp;
