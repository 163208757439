import React from "react";
import AddIcon from "@material-ui/icons/Add";
import withFileUpload from "../components/with-file-upload";

import AddStoryForm from './add-story-form.jsx';
import Showstory from './show-story.jsx';
import LoaderModal from './loader-modal.jsx';

const AddStoryIcon = () => {
  return (
    <div id="createworkspaceaddstories" style={{ borderRadius: '50%',padding:'3px', border:'1.3px solid #ced3dc'}}>
      <div
        style={{
          //padding: '10px',
          cursor: 'pointer',
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          //marginTop: '10px',
          color: 'white',
          backgroundColor: "#ced3dc",
          //paddingTop:'15px',
        }}
      >
        <AddIcon />
      </div>
    </div>
  );
}

const FileUploaderButton = withFileUpload(AddStoryIcon);

class ManageStories extends React.Component {
  state = {
    step: 0,
    showStory: null,
    content: {},
    file: null,
    imageSrc: null,
    disableSubmitButton: false,
    showStoryModal: false,
  }
  setStep = (step) => {
    this.setState({ step });
  }
  changeStory = (story) => {
    this.setState({ showStory: story });
  }

  handleFileUpload = (target, type) => {
    const file = target && target.files[0];
    const uploadType = type && type.split("/")[0];
    const extension = file && file.type.split("/")[1];
    if (file && type && !(file.type.indexOf(uploadType) > -1)) {
      this.props.InvalidFileError();
      this.setStep(0);
      return;
    }
    if (uploadType == 'image' && extension == 'webp') {
      this.setStep(0);
      this.props.InvalidFileError('Webp images are not allowed, as not supported by Safari browser.');
      return;
    }
    if (file && file.name) {
      this.setState({ showStoryModal: true });
      this.setStep(1);
      const formData = new FormData();
      formData.append("file", file, file.name);
      const fr = new FileReader();
      fr.onload = (stream) => {
        this.setState({
          file,
          imageData: stream.target.result,
        });
        this.saveMediaToPisco();
        //this.togglePostForm();
      }
      fr.readAsDataURL(file);
    }
  }

  saveMediaToPisco = () => {
    this.props.saveMediaToPisco(this.state.file).then(res => {
      this.setState({ content: res.localUploadResponse, imageSrc: res.preSaveResponse.s3Url, step: 2 })
    })
      .catch(err => {
        this.props.setStep(0);
      })
  }

  addNewStory = (croppedAreaPixels, heading, actionLink) => {
    this.setState({ disableSubmitButton: true });
    const { content } = this.state;
    this.props.addNewStory(content, croppedAreaPixels, heading, actionLink, this.afterAdd);
  }

  afterAdd = () => {
    this.setState({ step: 0, disableSubmitButton: false })
  }
  deleteStory = (id) => {
    this.props.deleteStory(id);
    this.setState({ showStory: null });
  }
  closeAddStoryModal = () => {
    this.setState({
      step: 0,
      showStory: null,
      content: {},
      file: null,
      imageSrc: null,
      disableSubmitButton: false,
      showStoryModal: false,
    })
  }

  render() {
    const {
      storiesList
    } = this.props;
    return (
      <>
        <LoaderModal
          showModal={this.props.isDeletingStory}
          showLoader={this.props.isDeletingStory}
        />
        {
          (this.state.step >= 1)
          && this.state.showStoryModal &&
          <AddStoryForm
            image={this.state.imageSrc}
            creatingStory={this.props.creatingStory}
            saveMediaToPisco={this.props.saveMediaToPisco}
            step={this.state.step}
            setStep={this.setStep}
            addNewStory={this.addNewStory}
            disableSubmitButton={this.state.disableSubmitButton}
            closeAddStoryModal={this.closeAddStoryModal}
          />
        }
        <div style={{
          width: '100%',
          overflow: 'hidden',
        }}>
          <div style={{
            display: 'flex',
            overflow: 'scroll',
            width: '100%',
            height: '100%',
            paddingRight: '0',
            paddingBottom: '15px',
            marginBottom: '-15px',
            boxSizing: 'content-box',
          }}>
            <FileUploaderButton type="image/*" onChange={this.handleFileUpload}>Upload File</FileUploaderButton>
            {
              storiesList && storiesList.map((item, index) => {
                return (
                  <div key={index} style={{ marginLeft: "10px", borderRadius: '50%', padding: '3px', border: '1.3px solid #ced3dc' }}>
                    <div
                      onClick={() => { this.changeStory(item) }}
                      style={{
                        //padding: '10px',
                        cursor: 'pointer',
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        //marginTop: '10px',
                        color: 'white',
                        backgroundColor: "#ced3dc",
                        //paddingTop:'15px',
                      }}
                    >
                      <img
                        src={item.titleUrl}
                        alt="click to view story"
                        style={{
                          width: '50px',
                          height: '50px',
                          objectFit: 'cover',
                          borderRadius: '50%',
                        }}
                      />
                    </div>
                  </div>
                  // <div
                  //   key={index}
                  //   style={{
                  //     padding: '10px',
                  //     cursor: 'pointer',
                  //   }}
                  //   onClick={()=>{this.changeStory(item)}}
                  // >
                  //   <img
                  //     src={item.titleUrl}
                  //     alt="click to view story"
                  //     style={{
                  //       width: '50px',
                  //       height: '50px',
                  //       objectFit: 'cover',
                  //       borderRadius: '50%',
                  //       border: '2px solid #fb4d24',
                  //       padding: '2px',
                  //     }}
                  //   />
                  // </div>
                )
              })
            }
            {
              this.state.showStory
              &&
              <Showstory
                story={this.state.showStory}
                changeStory={this.changeStory}
                deleteStory={this.deleteStory}
                isDeletingStory={this.props.isDeletingStory}
              />
            }
          </div>
        </div>
      </>

    )
  }
}

export default ManageStories;
