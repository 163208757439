import React from "react";
import SidePane from "react-sliding-pane";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from '@material-ui/core';
import SettingsIcon from "@material-ui/icons/Settings";
import Button from "@material-ui/core/Button";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";

class ListSettings extends React.Component {
  state = {
    showSidePane: false,
    expiryTime: 24
  }

  toggleSidePane = () => {
    this.setState((prev) => {
      return {
        showSidePane: !prev.showSidePane,
      }
    });
  }

  deleteStoryList = () => {
    this.props.deleteStoryList();
    this.toggleSidePane();
  }

  handleExpiryTimeChange = (event) => {
    this.props.updateStoryListExpiry(event.target.value)
  }
  render() {
    const { classes, labelColor, activeStoryList, activeStoryListExpiry } = this.props;
    return (
      <>
        <SettingsIcon key="story_list_settings" style={{ alignSelf:'center' }} onClick={this.toggleSidePane}/>
        <SidePane
        key="story_list_sidepane"
        overlayClassName='pisco-pane'
        isOpen={this.state.showSidePane}
        title='Settings'
        subtitle='List Settings'
        from='right'
        width='20%'
        onRequestClose={this.toggleSidePane}
        >
          <div style={{ marginTop: '50px', margin:'auto 50px' }}>
          <FormControl
          fullWidth
          className={classes.selectLabel}
        >
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
            style={{ color: `${labelColor}`}}
          >
            Story Expiry Time
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={activeStoryListExpiry}
            onChange={this.handleExpiryTimeChange}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select",
              classes: {
                icon: labelColor && classes.icon
              },
            }}
          >
            <MenuItem
              key='24'
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value={24}
            >
              24 Hours
            </MenuItem>
            <MenuItem
              key='48'
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value={48}
            >
              48 Hours
            </MenuItem>
            <MenuItem
              key='72'
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value={72}
            >
              72 Hours
            </MenuItem>
          </Select>
            </FormControl>
            <div style={{  marginTop:'50px'}}>
              <Button variant="contained" color="primary" onClick={this.deleteStoryList}>Delete List</Button>
            </div>
          </div>
        </SidePane>
    </>
    )
  }
}
export default withStyles(customSelectStyle)(ListSettings)
