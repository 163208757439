import React from 'react';
import Post1 from '../template/template1.jsx';
import Post2 from '../template/template2.jsx';
import Post3 from '../template/template3.jsx';
import Post4 from '../template/template4.jsx';

const PostsView = function (props) {
  const { title = '', summary = '', coverPicture = '', imgExtension = '.jpeg' } = props.storyDetail;
  let viewData = {
    title,
    description: summary,
    action: `SWIPE UP`,
    coverImage: coverPicture
  }
  return (
    props.result.map((item, key) => {
      return <div id={`postmainView${key}`} style={{ display: 'flex', justifyContent: 'center' }} item={key} key={key}>
        {item.name == "post1" &&
          < Post1 storyData={viewData} style={JSON.parse(item.styles)} />}

        {item.name == "post2" &&
          < Post3 storyData={viewData} style={JSON.parse(item.styles)} />
        }

        {item.name == "post3" &&
          < Post3 storyData={viewData} style={JSON.parse(item.styles)} />
        }
        {item.name == "post4" &&
          < Post3 storyData={viewData} style={JSON.parse(item.styles)} />
        }
        {item.name == "post5" &&
          < Post4 storyData={viewData} style={JSON.parse(item.styles)} />
        }
      </div >
    })
  )
}

export default PostsView;
