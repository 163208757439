
import {
  QuickContentAction,
  QuickPostAction,
  QuickStoryAction,
  MessageTypes,
  NotificationTypes,
} from "../../app-constants/index.js";

import { setAppMessage, clearAllAppMessages,addAppNotification } from "../../app-actions.js";
import { MeawwApi } from "../../utils/api.js";




/** --------------------------- create post action -------------------------------*/

function quickCmsPiscoSaveRequest() {
  return {
    type: QuickPostAction.PISCO_SAVE.REQEUST,
  }
}

function quickCmsPiscoSaveSuccess() {
  return {
    type: QuickPostAction.PISCO_SAVE.SUCCESS,
  }
}

function quickCmsPiscoSaveFailure() {
  return {
    type: QuickPostAction.PISCO_SAVE.FAILURE,
  }
}

function quickCmsCreateNewPostRequest() {
  return {
    type: QuickPostAction.CREATE_POST.REQEUST,
  }
}

function quickCmsCreateNewPostSuccess() {
  return {
    type: QuickPostAction.CREATE_POST.SUCCESS,
  }
}

function quickCmsCreateNewPostFailure() {
  return {
    type: QuickPostAction.CREATE_POST.FAILURE,
  }
}

function getCategoriesFailure() {
  return {
    type: QuickPostAction.CATEGORIES.FAILURE,
  }
}

function getCategoriesSuccess(categories) {
  return {
    type: QuickPostAction.CATEGORIES.SUCCESS,
    categories,
  }
}

function feedUpdateRequest() {
  return {
    type: QuickContentAction.UPDATE.REQUEST,
  }
}

function feedUpdateFailure() {
  return {
    type: QuickContentAction.UPDATE.FAILURE,
  }
}

function feedUpdateSuccess(articleId, data, status) {
  return {
    type: QuickContentAction.UPDATE.SUCCESS,
    articleId,
    data,
    status
  }
}

export function saveMediaToPisco(file) {
  return async (dispatch, getState) => {
    try {
      if (!file) {
        dispatch(setAppMessage('Invalid Media File', { type: MessageTypes.ERROR }));
        return Promise.reject(new Error('Invalid Media File'));
      }
      let name = file.name.replace(/[^a-zA-Z.0-9]/g, '');
      file = new File([file], name, { type: file.type });
      const formData = new FormData();
      formData.append("file", file, file.name);
      dispatch(quickCmsPiscoSaveRequest());
      const { login: { user: { activeDomain } } } = getState();
      let preSaveResponse = await piscoPreSave(file, activeDomain);
      let localUploadResponse = await localUpload(file, activeDomain);
      dispatch(quickCmsPiscoSaveSuccess());
      return Promise.resolve({ preSaveResponse, localUploadResponse });
    } catch (error) {
      dispatch(quickCmsPiscoSaveFailure());
      dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
      return Promise.reject(error);
    }
  }
}

export function clearArticleCache(articleId) {
  return (dispatch, getState) => {
    const {
      activeDomain
    } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/article/${activeDomain}/clearCache/${articleId}`,
          (error, data) => {
          });
      });
  }
}

export function AddNewPost(articleId, data, status, silent) {
  return async (dispatch, getState) => {
    try {
      dispatch(quickCmsCreateNewPostRequest());
      const { login: { user: { activeDomain } } } = getState();
      const newArticle = await createNewArticle(activeDomain);
      const { result } = newArticle;
      let publish = {};
      if (!result || !result.metadata) throw new Error('Cannot create article');
      data.category = data.category || result.metadata.categoryId;
      const setMeta = await updateMetaData(activeDomain, result.metadata.articleId, data);
      if (status === 'publish') {
        publish = await publishArticle(activeDomain, result.metadata.articleId);
      };
      if (!silent) dispatch(resetFeed()) && dispatch(getFeed('draft&live'));
      if(!silent) dispatch(setAppMessage(`${status === 'draft' ? 'Draft Saved Successfully.' : 'Media Published Successfully.'}`, { type: MessageTypes.SUCCESS }));
      dispatch(quickCmsCreateNewPostSuccess());
      return Promise.resolve({ activeDomain, newArticle, setMeta, publish });
    } catch (error) {
      if (error.message == 'Verify your email to continue') {
        dispatch(setAppMessage('Please verify email to start publishing.Click OKAY to get verification email.', { type: MessageTypes.ERROR, onOk: () => dispatch(sendResetPasswordLink()), onCancel: () => dispatch(clearAppMessage()) }));
        dispatch(resetFeed()) && dispatch(getFeed('draft&live'));
      } else {
        dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
      }
      dispatch(quickCmsCreateNewPostFailure());
      return Promise.reject(error);
    }
  }
}

export function EditPost(articleId, data, status) {
  return async (dispatch, getState) => {
    try {
      dispatch(feedUpdateRequest());
      const { login: { user: { activeDomain } } } = getState();
      let publish = {};
      const setMeta = await updateMetaData(activeDomain, articleId, data);
      if (status === 'publish') {
        publish = await publishArticle(activeDomain, articleId);
      };
      dispatch(setAppMessage(`${status === 'publish' ? 'Article Published Successfully.' : 'Changes Saved Successfully.'}`, {
        type: MessageTypes.SUCCESS
      }));
      dispatch(feedUpdateSuccess(articleId, data, status));
      if (status == 'live') {
        dispatch(clearArticleCache(articleId));
      }
      return Promise.resolve({ setMeta, publish });

    } catch (error) {
      if (error.message == 'Verify your email to continue') {
        dispatch(setAppMessage('Please verify email to start publishing.Click OKAY to get verification email.', { type: MessageTypes.ERROR, onOk: () => dispatch(sendResetPasswordLink()) , onCancel: () => dispatch(clearAppMessage()) }));
      } else {
        dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
      }      
      dispatch(feedUpdateFailure());
      return Promise.reject(error);
    }
  }
}

function sendResetPasswordLink() {
  return (dispatch, getState) => {
    const { login: { user: { email } } } = getState();
    MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/user/password/forgot`, {
          email,
        },
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            dispatch(setAppMessage('Verification email sent.', { type: MessageTypes.SUCCESS }));
            return Promise.resolve(data);
          })
      });
  }
}

function clearAppMessage() {
  return (dispatch, getState) => {
    dispatch(clearAllAppMessages());
  }
}

export function InvalidFileError(msg) {
  return async (dispatch, getState) => {
    dispatch(setAppMessage(msg || 'Invalid File Type', { type: MessageTypes.ERROR }));
    return Promise.reject();
  }
}

/** --------------------------- end create post action -------------------------------*/


function piscoPreSave(file, activeDomain) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", file, file.name);
    MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/pisco/preSave?domainId=${activeDomain}`, {
          isFormData: true,
          data: formData,
        },
          (error, data) => {
            if (error) return reject(error);
            return resolve(data);
          })
      })
  })
}

function localUpload(file, activeDomain) {
  return new Promise((resolve, reject) => {
    let mediaType = 'Image';
    if (file.type.startsWith('video')) mediaType = 'Video';
    if (file.type.startsWith('audio')) mediaType = 'Audio';
    const formData = new FormData();
    formData.append("file", new Blob());
    formData.append('filename', file.name);
    formData.append('title', file.name);
    formData.append('tags', ['quick-cms'])
    formData.append('imageSource', 'LOCAL');
    formData.append('type', mediaType);
    formData.append('dimensions', {});
    formData.append('caption', file.name);
    MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/pisco/local/upload?domainId=${activeDomain}`, {
          isFormData: true,
          data: formData,
        },
          (error, data) => {
            if (error) return reject(error);
            return resolve(data);
          })
      })
  })
}

function createNewArticle(activeDomain) {
  return new Promise((resolve, reject) => {
    MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/quick-article/${activeDomain}`, {
          contentType: 2,
        },
          (error, data) => {
            if (error) return reject(error);
            return resolve(data);
          })
      })
  });
}

//--------------------------------Stories
export function addNewStory(content, croppedData, heading, linkAction, cb) {
  return (dispatch, getState) => {
    const {
      cms: {
        storyCms: {
          activeStoryList } },
      login: {
        user: {
          activeDomain } }
    } = getState();
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/stories/${activeDomain}/story/default-quick-cms-stories_${activeDomain}`, { content, croppedData, heading, linkAction },
          (error, data) => {
            cb();
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            dispatch(setAppMessage(data.result.message, { type: MessageTypes.SUCCESS }));
            dispatch(getStories());
            return Promise.resolve(data.result);
          });
      });
  }
}

function getStoriesSuccess(stories) {
  return {
    type: QuickStoryAction.LOAD.SUCCESS,
    stories,
  }
}

export function getCategories() {
  return (dispatch, getState) => {
    const { login: { user: { activeDomain } } } = getState();
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/category/${activeDomain}`,
          (error, data) => {
            if (error) return;
            dispatch(getCategoriesSuccess(data.result));
            return;
          })
      })
  }
}

export function deleteStory(storyId) {
  return (dispatch, getState) => {
    dispatch({ type: QuickStoryAction.DELETE.REQUEST });
    const { cms: { storyCms: { activeStoryList } }, login: { user: { activeDomain } } } = getState();
    return MeawwApi()
      .auth()
      .then(api => {
        return api.delete(`/stories/${activeDomain}/story/${storyId}`,
          (error, data) => {
            if (error) {
              dispatch({ type: QuickStoryAction.DELETE.SUCCESS });
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            dispatch({ type: QuickStoryAction.DELETE.SUCCESS });
            dispatch(setAppMessage('Deleted Successfully', { type: MessageTypes.SUCCESS }))
            dispatch(getStories());
            return Promise.resolve("Deleted");
          });
      });
  }
}

export function getStories(param) {
  return (dispatch, getState) => {
    const { cms: { storyCms: { activeStoryList } }, login: { user: { activeDomain } } } = getState();
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/stories/${activeDomain}/story/0/default-quick-cms-stories_${activeDomain}`,
          (error, data) => {
            if (error) {
              if(param === 'initial'){
                window.location = '/my-site';
              }
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }))
              return Promise.reject(error);
            }
            dispatch(getStoriesSuccess(data.result.stories));
            return;
          });
      });
  }
}

function getDuration(file) {
  return new Promise((resolve,reject) => {
    try {
      const audio = new Audio();
      audio.src = file
      audio.addEventListener('loadeddata', () => {
        resolve(audio.duration)
      })
      audio.addEventListener('error', () => {
        reject(new Error('not loaded audio'))
      })
    }
    catch (e) {
      reject(e)
    }
  })
}

function updateMetaData(activeDomain, articleId, data) {
  return  new Promise( async (resolve, reject) => {
    let duration;
    if (data.type && data.type.indexOf('audio') >= 0) {
      try {
        duration = await getDuration(data.metadata.s3Url)
        if (duration == 'Infinity') {
          duration = window.recordDuration
        }
        data.metadata = {...data.metadata , duration}
      }
      catch (e) {
        console.log(e)
      }
    }
    const apiData = {
      title: data.title,
      headerContent: JSON.stringify(data.metadata),
      paidContent: data.premium || false,
    };
    if (data.type) apiData.headerType = data.type;
    if (data.category) apiData.categoryId = data.category;
    if (data.description) apiData.description = data.description;
    MeawwApi()
      .auth()
      .then(api => {
        return api.put(`/quick-article/${activeDomain}/meta/${articleId}`, apiData,
          (error, data) => {
            if (error) return reject(error);
            return resolve(data);
          })
      })
  });
}

function publishArticle(activeDomain, articleId) {
  return new Promise((resolve, reject) => {
    MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/quick-article/${activeDomain}/publish/${articleId}`, {
          shadow: 0,
        },
          (error, data) => {
            if (error) return reject(error);
            return resolve(data);
          })
      })
  });
}

function getFeedActionRequest() {
  return {
    type: QuickContentAction.GET.REQUEST,
  }
}

function getFeedSearchActionRequest(){
  const feed = [];
  return {
    type: QuickContentAction.SEARCH.REQUEST,
    feed
  }
}

function getFeedActionFailure() {
  return {
    type: QuickContentAction.GET.FAILURE,
  }
}

function getFeedActionSuccess(feed, nextPage) {
  return {
    type: QuickContentAction.GET.SUCCESS,
    feed,
    nextPage
  }
}

function searchFeedActionSuccess(feed, nextPage){
  return {
    type: QuickContentAction.SEARCH.FEED,
    feed,
    nextPage
  }
}

function deleteFeedFromReducer(id) {
  return {
    type: QuickContentAction.FEED.REMOVE,
    id
  }
}

export function resetFeed() {
  return {
    type: QuickContentAction.FEED.RESET,
  }
}

export function getFeed(feedType, headerType = '', currentPageVal) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    let { currentPage, feedPerPage } = getState().cms.quickCms;
    if(currentPageVal != undefined){
      currentPage = currentPageVal;
    }
    dispatch(getFeedActionRequest());
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/quick-article/${activeDomain}/?${feedType}&page=${currentPage + 1}&perPage=${feedPerPage}&header_type=${headerType}`,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              dispatch(getFeedActionFailure())
              return Promise.reject(error);
            }
            dispatch(getFeedActionSuccess(data.result, currentPage + 1))
            return Promise.resolve(data);
          });
      })
  }
}

export function searchFeed(searchKeyword) {
  return (dispatch, getState) => {
    const { cms, login: { user: { activeDomain } } } = getState();
    const { feedPerPage } = getState().cms.quickCms;
    const currentPage = 1;
    dispatch(getFeedSearchActionRequest());
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/quick-article/${activeDomain}/search`, {
          "searchQuery": searchKeyword,
          "page": currentPage,
          "perPage": feedPerPage
        },
          (error, data) => {
            if (error) {
              dispatch(getFeedActionFailure());
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }))
              return Promise.reject(error);
            }
            dispatch(searchFeedActionSuccess(data.result, currentPage))
            return Promise.resolve(data);
          });
      });
  }
}

export function changeCoverPicture(changeCoverForm, articleId) {
  return (dispatch, getState) => {
    const { login: { user: { activeDomain } } } = getState();
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/article/${activeDomain}/cover/${articleId}`,
          changeCoverForm,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.toString(), { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            dispatch(resetFeed());
            dispatch(getFeed('draft&live'));
            dispatch(addAppNotification("Cover Image Updated Successfully", { type: NotificationTypes.SUCCESS }));
            return Promise.resolve(data);
          })
      })
  }
}

export function updateArticleContent(htmlContent, mapObject, articleId) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi().auth().then(api => {
      return api.put(`/quick-article/${activeDomain}/draft/${articleId}`, {
        "html": htmlContent,
        "objectMap": JSON.stringify(mapObject),
      }, (error, data) => {
        if (error) {
          dispatch(setAppMessage(error.message.toString(), { type: MessageTypes.ERROR }));
          return
        }
        dispatch(addAppNotification("Content saved Successfully!", { type: NotificationTypes.SUCCESS, }));
        return data;
      });
    });
  }
}

export function fetchArticleDetails(articleId) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi().auth().then(api => {
      return api.get(`/quick-article/${activeDomain}/draft/${articleId}`, (error, data) => {
        if (error) {
          dispatch(setAppMessage(error.message.toString(), { type: MessageTypes.ERROR }));
          return Promise.reject(error);
        }
        return Promise.resolve(data);
      });
    });
  }
}

export function deleteFeed(id, feedContent) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    const { status } = feedContent;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/quick-article/${activeDomain}/${status == 0 ? 'delete' : 'unpublish'}/${id}`, {
          reason: 'user deleted'
        },
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            dispatch(deleteFeedFromReducer(id))
            dispatch(setAppMessage('Feed Deleted Successfully.', { type: MessageTypes.SUCCESS }));
            return Promise.resolve(data);
          });
      })
  }
}

export function resetFilterFeed(feedType) {
  return {
    type: QuickContentAction.RESET.FEED,
    feedType
  }
}

export function resetHeaderType(headerType) {
  return {
    type: QuickContentAction.RESET.HEADER_TYPE,
    headerType
  }
}

export function resetBothFilter() {
  return {
    type: QuickContentAction.RESET.ALL,
  }
}

export function getVideoThumbnail(source) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/quick-article/${activeDomain}/thumb`, {
          source
        },
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      })
  }
}

export function getBlurImage(source) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/quick-article/${activeDomain}/blur`, {
          source
        },
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      })
  }
}