
import { SOCIAL_LINK } from "../../app-constants";

const defaultSocialLink = {
    isLoading: false,
    fb_url: null
}

const socialLink = (state = defaultSocialLink, action) => {
    switch (action.type) {
        case SOCIAL_LINK.GET.SUCCESS: {
            return { ...state, ...action.data,isLoading:false}
        }
        case SOCIAL_LINK.GET.REQUEST: {
            return {...state,isLoading:true}
        }
        default: return state;
    }

}
export default socialLink;