import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Card from "components/Card/Card.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";
import { Redirect, withRouter } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { fetchFeaturePermision } from "../../login/login-actions";
import AppLoader from "../../app-components/app-loader.jsx";
import { registerDomain, ShowSuccessMessage ,setOtherDomainDetail} from "../../domain-management/domain-management-action";
// import FaviconLogo from "../../website-management/favicon-logo/favicon-logo-home-page";
import { fetchUserDomains, setActiveDomain } from "../../user-settings/user-settings-actions";
import { SetSocialAccount } from "../../website-management/social-account/social-account-action";
import { setFaviconLogo } from "../../website-management/website-management-actions";
import { saveMediaToPisco } from "../../cms/quick-cms/quick-cms-actions";
import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import { InvalidFileError } from '../../cms/quick-cms/quick-cms-actions.js';
import Switch from "@material-ui/core/Switch";
import Button from '../../components/CustomButtons/Button.jsx';
import withFileUpload from "../components/file-upload";
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import { updateUser } from "../../manage-social/manage-social-actions";
import { setCreateType, getCreateType, bringYourContent } from "../onboarding-action";

class Addwebsite extends React.Component {
  state = {
    registeringDomain: false,
    uploadingLogo: false,
    domain: this.props.domain,
    step: 2,
    title: this.props.user.firstName + ' ' + (this.props.user.lastName ? this.props.user.lastName : '') || '',
    domainRegMsg: '',
    description: this.props.description,
    file: this.props.file,
    tag: '',
    imageData: this.props.imageData,
    fb: '',
    insta: '',
    twitter: '',
    youtube: '',
    url: '',
    isValid: true,
    website: this.props.website,
    migratueUrl: this.props.migratueUrl,
    showMigration: false,
    isWebsiteEnable: false,
    isCreatingWebiste :false,
    error: {
      domainError: '',
      websiteError :'',
      titleError: '',
      tag: ''
    }
  }
  componentDidMount() {
    // window.scrollTo(0, 0);
    if (this.props.migratueUrl) {
      this.setState({showMigration:true})
    }
    this.props.getCreateType().then(() => {
      try {
        const { createType } = this.props;
        if (Object.keys(createType).length && Object.values(createType.createType).length && Object.values(createType.createType).includes(true)) {
          this.setState({ isValid: false }, () => {
            if (!this.state.isValid && this.state.step == 1) {
              this.increaseStep()
            }
          })
        }
      }
      catch (e) {

      }
    })
  }

  handleChange = (event) => {
    const { id } = event.target;
    this.setState({ [id]: event.target.value }, () => {
      id && id == 'website' && this.validate();
      id && id == 'domain' && this.addWebsiteWithDomain(this.state.domain.replace(/\s/g, ''));
      id && id == 'title' && this.userValidate();
    });
  }
  addWebsiteWithDomain = (val) => {
    let rootDomain = val.toLowerCase();
   !this.state.isWebsiteEnable && this.setState({website:rootDomain.replace(/[^a-z0-9]/g, '-')})
  }
  handleMigrateUrlChange = (e) => {
    this.setState({ migratueUrl: e.target.value })
  }
  componentDidUpdate(prevProps) {
    window.scrollTo(0, 0);
    if (prevProps.user.firstName != this.props.user.firstName) {
      const { firstName = '', lastName = '' } = this.props.user;
      this.setState({ title: `${firstName} ${lastName ? lastName : ''}` })
    }
    try {
      if (prevProps.createType.createType != this.props.createType.createType) {
        const { createType } = this.props;
        if (Object.keys(createType).length && Object.values(createType.createType).length && Object.values(createType.createType).includes(true)) {
          this.setState({ isValid: false })
        }
      }
    }
    catch (e) {

    }
  }
  registerNewDomain = () => {
    const { domain,website,title, description,migratueUrl,file,imageData } = this.state;
    const index = title.trim().indexOf(' ')
    let name = null;
    if (index == -1) {
      name = {
        firstName: title
      }
    }
    else {
      name = {
        firstName: title.slice(0, index) || '',
        lastName: title.slice(index + 1) || ''
      }
    }
    if (!this.validate()) {
      return;
    }
    if (this.props.website) {
      this.props.setOtherDomainDetail(this.props.activeDomain, description, domain);
      this.state.file && this.AddwebsiteLogo(this.state.file);
      this.state.migratueUrl && this.setbringYourContent(this.state.migratueUrl);
      return;
    }
    this.toggleLoader();
    this.setState({ isCreatingWebiste: true });

    this.props.registerDomain({ domain: website, onOk: this.registerDomainSuccess, description, name, title: domain })
      .then(res => {
        Promise.all(
          [
            this.state.file && this.AddwebsiteLogo(this.state.file),
            this.state.migratueUrl && this.setbringYourContent(this.state.migratueUrl),
            this.props.updateDomainDetailForOnBoard({ domainId: res.data.domainId, domain, website, title, description, migratueUrl, file, imageData }),
          ]

        ).then(() => {
          this.registerDomainSuccess(res.data.domainId, res.message, res.data.url);
        })
          .catch(() => {
          this.registerDomainSuccess(res.data.domainId, res.message, res.data.url);
        })
      })
      .catch(res => {
        this.setState({ isCreatingWebiste: false });
        this.toggleLoader();
      });
  }

  registerDomainSuccess = (domainId, msg, url) => {
    this.props.setActiveDomain(domainId);
    this.props.fetchFeaturePermision();
    this.props.fetchUserDomains();
    this.props.getNewsletter().then(res => {
      this.setState({ isCreatingWebiste: false });
      this.props.enableNextTab('share');
      this.toggleLoader();
      this.setState({});
      this.props.disableTab('websiteButton');
      this.props.increaseState();
      
    }).catch(() => {
      this.setState({ isCreatingWebiste: false });
    })
  }

  toggleLoader = () => {
    this.setState((prev) => {
      return {
        registeringDomain: !prev.registeringDomain,
      }
    });
  }
  addtwitterLink = (twitter) => {
    this.props.SetSocialAccount({
      twitter_url: twitter,
    })
    this.setState({ twitter })
  }
  addSocialAccounts = () => {
    const { fb, insta, twitter, youtube } = this.state;
    //return;
    this.props.SetSocialAccount({
      fb_url: fb,
      insta_url: insta,
      twitter_url: twitter,
      yt_url: youtube,
    }, this.state.domainRegMsg).finally(res => {
      const { featurePermision } = this.props;
      // if (featurePermision && featurePermision.includes('TRIAL')) {
      //   this.props.history.push("/my-website");
      //   this.setState({ step: 0 })
      //   return;
      // }
      // this.props.history.push("/my-site");
      // this.setState({ step: 0 })
      this.increaseStep()
    })
  }

  handleFileUpload = (target, type) => {
    const file = target && target.files[0];
    const uploadType = type && type.split("/")[0];
    const extension = file && file.type.split("/")[1];
    if (uploadType == 'image' && extension == 'webp') {
      this.props.InvalidFileError('Webp images are not allowed, as not supported by Safari browser.');
      return;
    }
    if (file && type && !(file.type.indexOf(uploadType) > -1)) {
      this.props.InvalidFileError();
      return;
    }
    this.setState({ uploadingLogo: true });
    if (file && file.name) {
      const fr = new FileReader();
      fr.onload = (stream) => {
        this.setState({ file, imageData: stream.target.result });
      }
      fr.readAsDataURL(file);
    }
  }

  showSuccessMessage = () => {
    // this.increaseStep()
    // const { featurePermision } = this.props;
    // if (featurePermision && featurePermision.includes('TRIAL')) {
    //   this.props.history.push("/my-website");
    this.props.ShowSuccessMessage(this.state.domainRegMsg);
    //   this.setState({ step: 0 })
    //   return;
    // }
    // this.props.history.push("/my-site");
    // this.props.ShowSuccessMessage(this.state.domainRegMsg);
    // this.setState({ step: 0 })
  }
  AddwebsiteLogo = (file) => {
    return this.props.saveMediaToPisco(file).then(res => {
      const { localUploadResponse } = res;
      let logo = localUploadResponse.cdn;
      if (localUploadResponse && localUploadResponse.cdn.indexOf('https') == -1) {
        logo = `https:${localUploadResponse.cdn}`;
      }
      this.props.setFaviconLogo(null, logo, true);
    }).catch(console.log).finally(res => {
      this.setState({ step: this.state.step + 1, uploadingLogo: false });
    });
  }
  userValidate = () => {
    const { title } = this.state;
    this.setState({
      error: {
        ...this.state.error,
        titleError: (!title ? "User name required *" : '')
      }
    })
  }
  validate = () => {
    const { website } = this.state;
    if (!website) {
      this.setState({
        error: {
          ...this.state.error,
          websiteError: "Website name required *"
        }
      })
      return false;
    }
    if (!/^[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*$/.test(website)) {
      this.setState({
        error: {
          ...this.state.error,
          websiteError: "Please enter a valid Website Name"
        }
      })
      return false;
    } else {
      this.setState({
        error: {
          ...this.state.error,
          websiteError: ""
        }
      })
    }
    return true;
  }
  increaseStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 })
  }
  setbringYourContent = (url) => {
    return this.props.bringYourContent(url)
  }
  onWebsiteChange = (e) => {
    this.setState({ website: e.target.value, isWebsiteEnable: true }, () => {
      this.validate()
    })
  }
  _handleClick = () => {
    this.refs.imgField.click();
  }
  _onUpload = (event) => {
    this.handleFileUpload(event.target, this.props.type);
    event.target.value = null
  }
  handleMigrateChange = (event) => {
    this.setState({showMigration:event.target.checked})
  }
  render() {
    const { classes, setCreateType, isDomainLoading, createType } = this.props;
    const { uploadingLogo, domain, tag, title, error, description,
      fb,
      insta,
      twitter,
      youtube,
      error: {
        websiteError
      },
      isCreatingWebiste
    } = this.state;
    return (
      <>
        <div className={classes.container} style={{
          display: 'flex', justifyContent: 'center',alignItems:'center' ,
          marginTop: isWidthDown('xs', this.props.width) ? '15px' : '30px',
        }}>
            <GridItem xs={12} sm={8} md={6} style={{ border: isWidthDown('xs', this.props.width) ? 'none' : '1px solid rgb(206, 211, 220)'}}>
              {/* {this.state.step === 2 && <div style={{ border: isWidthDown('xs', this.props.width) ? 'none' : '1px solid rgb(206, 211, 220)', }}> */}
                <GridContainer  style={{ marginTop: isWidthDown('sm', this.props.width) ? '10px' : '10px',display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center' }}>
                  <GridItem xs={12} sm={10} style={styles.FormtagStyle}>
                    <h1 style={{
                      lineHeight: '1.1',
                      letterSpacing: '0.5px',
                      textAlign: 'center',
                      fontFamily: 'PlayfairDisplay',
                      width:'100%'
                    }}>Your Website!</h1>
                    <hr style={{
                      border: '1px solid #E1E4E9',
                      width: '10%',
                      margin: ' auto',
                      marginBottom: '10px'
                    }} />
                  </GridItem>
                </GridContainer>
                <GridContainer  style={{ marginTop: '-20px',display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center'  }}>
                  <GridItem xs={12} sm={10} md={8} style={{width:'100%'}}>
                    <h5 style={{
                      width: '100%',
                      textAlign: 'left',
                      marginBottom: '-20px',
                      marginTop: '30px',
                      fontSize: '16px',
                      fontWeight: '600',
                      color: '#2b2d42',
                    }}>Give your brand name</h5>
                  </GridItem>
                  <GridItem xs={12} sm={10} md={8} style={{width:'100%'}}>
                    <CustomInput
                      id="domain"
                      value={domain}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: "e.g. SUNDAYCULTURE",
                        onChange: this.handleChange,
                      }}
                />
                {(this.state.website || this.state.isWebsiteEnable || this.props.website) && <p style={{ display: 'flex', marginTop: '-10px', marginBottom: '15px', marginLeft: '0px', fontSize: '12px', textAlign: 'left' }}>Your website  <span style={{ fontWeight: 'bold', color: 'black' }}>{<input id='website' name='website' disabled={this.props.website ? true : false} style={{ padding: '0px', textAlign:'right', marginLeft: '3px' }} value={this.state.website} onChange={this.onWebsiteChange} />}.pubninja.com</span></p>}
                {error.websiteError && <p style={{ fontSize: '12px', marginTop: '-15px' }}>{error.websiteError}</p>}
              </GridItem>
                </GridContainer>
                <GridContainer  style={{ marginTop: '-15px' ,display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center' }}>
                  <GridItem xs={12} sm={10} md={8} style={{width:'100%'}}>
                    {/* <h5 style={{
                      width: '100%',
                      textAlign: 'left',
                      marginTop: '10px',
                      fontSize: '16px',
                      fontWeight: '600',
                      color: '#2b2d42',
                      marginBottom:'0px'
                }}>What is your website about&nbsp;:</h5> */}
                <p style={{
                  width: '100%',
                  marginTop: '25px',
                  fontSize: '16px',
                  fontWeight: '600',
                  color: '#2b2d42',
                }}>What is your website about?</p>
                </GridItem>
                  <GridItem xs={12} sm={10} md={8} style={{width:'100%',marginBottom:'-30px'}}>
                    {/* <p style={{
                      width: '100%',
                      textAlign: 'left',
                      fontSize: '14px',
                      color: '#99a3b4',
                      
                    }}>Describe your website in about 50 words.</p> */}
                <p style={{
                  width: '100%',
                  marginTop: '-15px',
                  marginBottom: '10px',
                  fontSize: '14px',
                  fontWeight: '600',
                  color: '#ced3dc',
                }}> Describe your website in about 50 words</p>
                  </GridItem>
                  <GridItem xs={12} sm={10} md={8} style={{width:'100%'}}>
                    <CustomInput
                      id="description"
                      value={description}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      style={{
                        paddingTop:'0px'
                      }}
                      inputProps={{
                        placeholder: "e.g. THE SUNDAY CULTURE IS A HEALTH & WELLNESS WEBSITE...",
                        onChange: this.handleChange,
                        multiline: true,
                        rows: 5,
                        rowsMax: 5,
                        className: "multilineFormFix"
                      }}
                    />
                  </GridItem>
                  
                </GridContainer>
              {/**-------------------------------------------------- Logo Uploads -----------------------------------------------------------*/}
              
                {/* <GridContainer style={{ display: 'flex', justifyContent: 'center' }}>
                  <GridItem xs={12} sm={10} md={8} style={{ marginBottom: '20px'}}>
                    <p style={{
                      width: '100%',
                      fontSize: '16px',
                      fontWeight: '600',
                      color: '#2b2d42',
                    }}>Upload a logo for your website</p>
                    <p style={{
                      width: '100%',
                      marginTop: '-15px',
                      marginBottom: '10px',
                      fontSize: '14px',
                      fontWeight: '600',
                      color: '#ced3dc',
                    }}> The recommended logo size is 800 * 200</p>
                    <div style={{ display: 'flex', justifyContent: 'center' ,position:'relative', background:'#d7d7d7'}}>
                      <img style={{ height: '150px' }} onClick={this._handleClick} src={
                        !this.state.imageData ? 'https://cdn.meaww.com/assets/img/Cover.png'
                          : this.state.imageData
                    } />
                    <input ref="imgField" accept={'image/*'} onChange={this._onUpload} id="file-upload" type="file" style={{ display: 'none' }} />
                    <p style={{position:'absolute',bottom:'10px'}}>Click here to upload</p> */}
                      {/* {uploadingLogo && <AppLoader size={20} />} */}
                      {/* <WithUploadButton fullWidth type="image/*" color="secondary" onClick={this.handleFileUpload} disabled={uploadingLogo} style={{width: isWidthDown('xs', this.props.width) ? '100%' : 'auto'}} /> */}
                    {/* </div> */}
                    {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                      <WithUploadButton fullWidth type="image/*" color="secondary" onChange={this.handleFileUpload} disabled={uploadingLogo} style={{width: isWidthDown('xs', this.props.width) ? '100%' : 'auto'}} >choose file</WithUploadButton>
                      {uploadingLogo && <AppLoader size={20} />}
                    </div> */}
                  {/* </GridItem>
                 </GridContainer> */}
                   {/* <GridContainer>
                   <GridItem xs={12} sm={10} md={8} style={{margin:'auto'}}>
                    <h5 style={{
                      width: '100%',
                      textAlign: 'left',
                      marginBottom: '-20px',
                      marginTop: '10px',
                      fontSize: '16px',
                      fontWeight: '600',
                      color: '#2b2d42',
                      width:'100%'
                    }}>Do you have an existing website or social profile you want to import content from?
                    &nbsp;</h5>
                <div style={{ marginTop: '10px'}}>
                  <span >No</span>
                  <Switch checked={this.state.showMigration} onChange={this.handleMigrateChange} color='primary' />
                  <span >Yes</span>
                      </div>
                  </GridItem> */}
                  {/* {this.state.showMigration &&  <GridItem xs={12} sm={10} md={8} style={{width:'100%',margin:'auto',marginTop:'-15px'}}>
                    <CustomInput
                      id="migration"
                      value={this.state.migratueUrl}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        placeholder: "e.g. https://www.youtube.com/channel/UCDZPR11b_pK3HwmlMnytYlA",
                        onChange:  this.handleMigrateUrlChange,
                      }}
                        />
                <p style={{
                  width: '100%',
                  marginTop: '-15px',
                  marginBottom: '10px',
                  fontSize: '12px',
                  fontWeight: '600',
                  color: '#ced3dc',
                }}>
                  Separate multiple URLs with ",". Leave it bank if you want do not want to import anything.
                </p>
                <p style={{
                  fontSize: '12px',
                  lineHeight: '1.42',
                  color: '#99a3b4',
                  textAlign: 'center',
                  marginTop: '15px'
                }}>
                  * we will reach out to you over email for confirmation
                </p>
                  </GridItem>   }
                  </GridContainer>*/}
                <GridContainer  style={{ marginBottom: '50px' }}>
                  <GridItem xs={12} md={8} sm={10} style={{margin:'auto'}}>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px', marginBottom: '10px',width:'100%' }}>
                      <Button disabled={(isCreatingWebiste || (!this.state.website) || !!websiteError)}
                          color="secondary"
                          fullWidth
                          onClick={this.registerNewDomain}
                          >
                          CREATE MY WEBSITE
                         </Button>
                          {isCreatingWebiste && <AppLoader size={20} />}
                        </div>
                        <p>* You can always add/edit these settings later</p>
                      </GridItem>
                </GridContainer>
            </GridItem>
        </div >
      </>)
  }
}
const mapStateToProps = (state) => {
  const { isDomainLoading, createType } = state.app;
  let { user } = state.login;
  const { featurePermision } = state.login.domain;
  const { activeDomain } = state.login.user;
  const firstName = localStorage.getItem("firstName") || ''
  const lastName = localStorage.getItem("lastName") || '';
  const {
    websiteManagementReducer: {
      faviconLogoReducer: {
        favicon, logo, isLoadingFaviconLogo
      },
      siteInfo: {
        title,
      }
    }
  } = state;
  if (!user.firstName) {
    user = {
      firstName,
      lastName: lastName ? lastName : ''
    }
  }
  return { isDomainLoading, featurePermision, createType, user,title,logo,activeDomain };
}

const mapDispatchToProps = (dispatch) => {

  return {
    fetchUserDomains: () => dispatch(fetchUserDomains()),
    fetchFeaturePermision: () => dispatch(fetchFeaturePermision()),
    registerDomain: (domain) => dispatch(registerDomain(domain)),
    setActiveDomain: (domainId) => dispatch(setActiveDomain(domainId)),
    SetSocialAccount: (social, msg) => dispatch(SetSocialAccount(social, msg)),
    saveMediaToPisco: (file) => dispatch(saveMediaToPisco(file)),
    setFaviconLogo: (favicon, logo, silent) => dispatch(setFaviconLogo(favicon, logo, silent)),
    ShowSuccessMessage: (msg) => dispatch(ShowSuccessMessage(msg)),
    InvalidFileError: (msg) => dispatch(InvalidFileError(msg)),
    setCreateType: (type) => dispatch(setCreateType(type)),
    getCreateType: () => dispatch(getCreateType()),
    bringYourContent: (migration) => dispatch(bringYourContent(migration)),
    updateUser: (user) => dispatch(updateUser(user)),
    setOtherDomainDetail :(domainId, description,title)=> dispatch(setOtherDomainDetail(domainId, description,title))
  }
}
const styles = {
  domainForms: {
    width: '60%',
    margin: "auto"
  },
  FormtagStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(registerPageStyle)(withWidth()(Addwebsite))));
