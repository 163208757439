import React, { PureComponent, Fragment } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import TextField from "@material-ui/core/TextField";
import AppLoader from "../../../app-components/app-loader";

const CustomDialogTitle = withStyles(theme => ({
  root: {
    // borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, setFeedbackModal } = props;
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="subheading">
        <div style={{ display: "flex", justifyContent: "center", fontWeight: "bold" }}>
          {children}
        </div>

      </Typography>
      {setFeedbackModal ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={() => setFeedbackModal(false)}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

class FeedbackModal extends PureComponent {
  constructor(props) {
    super(props);
    this.feedbackMsg = React.createRef();
    this.feedbackModal = React.createRef();
    this.state = {
      feedback: ""
    }
  }

  setFeedbackText = (event) => {
    this.setState({
      feedback: event.target.value
    })
  }


  handleSubmit = (event) => {
    // evnt.preventDefault();
    // const { appendBlockQuote, hideBlockQuote } = this.props;
    // const { text } = this.state;
    // this.setState({ text: "" });
    // const formattedContent = this.formatContent(text);
    // if (formattedContent && formattedContent.length > 0) {
    //   appendBlockQuote(formattedContent);
    // }
    // hideBlockQuote();
    event.preventDefault();
    const { sendFeedback, articleState } = this.props;
    const { feedback } = this.state;
    if (feedback && feedback.length > 0) {
      sendFeedback(feedback, articleState);
    }
  }

  render() {
    const { sendingFeedback, showFeedbackModal, setFeedbackModal } = this.props;
    return (
      <Dialog
        id="feebackContainer"
        open={showFeedbackModal}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
      >
        <CustomDialogTitle
          id="customized-dialog-title"
          setFeedbackModal={setFeedbackModal}
        >
          Send Feedback
        </CustomDialogTitle>
        <DialogContent>
          <form ref={this.feedbackModal} onSubmit={this.handleSubmit}>
            <TextField
              ref={this.feedbackMsg}
              id="feedback"
              multiline={true}
              margin="normal"
              fullWidth
              rows={5}
              rowsMax={7}
              onChange={this.setFeedbackText}
            >
            </TextField>
          </form>
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button onClick={() => {
            setFeedbackModal(false)
          }} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleSubmit} color="primary">
            {
              sendingFeedback && <span style={{ display: "flex" }}>{"Send"}{" "}<AppLoader isSignOutLoader={true} color="inherit" size={20} /></span> || "Send"
            }
          </Button>
        </DialogActions>

      </Dialog>
    )
  }
}

export default FeedbackModal;