import React from "react";
import Button from "@material-ui/core/Button";

class CreatePost extends React.Component {
  render() {
    return (
      <Button variant="contained">Create Post</Button>
    )
  }
}

export default CreatePost;
