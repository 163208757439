import React, { Component } from 'react';
import Button from "components/CustomButtons/Button.jsx";

class RoundButton extends Component {
    render() {
        const { type, location = {}, buttonbackground = "#2B2D42", iconcolor, onclick } = this.props;
        return (
            <Button
                onClick={onclick}
                color='secondary'
                round
                justIcon
                style={{ background: `#ced3dc`, cursor: "pointer", ...location }}
            >
                <i className={`material-icons`} style={{ color: `${iconcolor}` }}>{type}</i>
            </Button >
        )
    }
}
export default RoundButton;
