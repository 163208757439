import { MeawwApi } from "../utils/api.js";
import { MessageTypes, SOCIAL_LINK } from "../app-constants";
import { setAppMessage } from "../app-actions.js";
import { AppAction } from "../app-constants/index.js";

export function onboardUser(email, firstName, lastName, search) {
  return dispatch => {
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(search ? `/user/unified-register${search}`:'/user/unified-register', {
          email,
          medium:'creator',
          //firstName, Needs clarification
          //lastName, Needs clarification
        }, (error, data) => {
          if (error) {
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
            return Promise.reject(error);
          }
          try {
            window.fbPixel(email);
            window.snapChat(email);
            window.adWords();
            window.linkedin();
            window.quoraPixel();
            window.tiktok();
          } catch (Err) {
            
          }
          
          //let successMsg = `Registration with id ${email} is successful. Please check your mail to reset the password and start using Pubninja.`
          //dispatch(setAppMessage(successMsg, { type: MessageTypes.SUCCESS }));
          return Promise.resolve(data);
        })
      });
  }
}

export function setCreateType(type) {
  return dispatch => {
    return MeawwApi()
      .auth()
      .then(api => {
        return api.put("/user",
          {
            store: JSON.stringify({ 'createType': type })
          },
          (error, data) => {
            if (error) {
              console.log(error.message)
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          })
      });
  }
}

export function getCreateType() {
  return dispatch => {
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get("/user/store",
          (error, data) => {
            if (error) {
              console.log(error.message)
              // dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            dispatch({ type: AppAction.SETWEBSITE_INFO.SUCCESS, createType: JSON.parse(data.store) })
            return Promise.resolve(data);
          })
      });
  }
}

export function bringYourContent(migration) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/domain/${activeDomain}/migrate`,
          {
            "migrationUrl": migration
          },
          (error, data) => {
            if (error) {
              console.log(error.message)
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          })
      });
  }
}

export function enabldeAd(val) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/domain/${activeDomain}/configure_ads`,
          {
            "enabled": val
          },
          (error, data) => {
            if (error) {
              console.log(error.message)
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          })
      });
  }
}

function getSocialLinkSuccess(data) {
  return {
    type: SOCIAL_LINK.GET.SUCCESS,
    data
  }
}


/***----------------------------------------- for tracking purpose -----------------------------------------****/

