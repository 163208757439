import React from "react";
import ArticleList from "./view/article-list.jsx";

class ArticleHomePage extends React.PureComponent {
  render() {
    return (
      <ArticleList />
    );
  }
}

export default ArticleHomePage;
