import React, { PureComponent } from "react";
import ReactTable from "react-table";
import moment from "moment";


class ReveneuStatsTable extends PureComponent {

  add(data, prop) {
    var total = 0
    for (var i = 0, _len = data.length; i < _len; i++) {
      total += data[i][prop]
    }
    return total
  }

  calTableTotal = (data) => {
    const sessionPr = this.add(data, "pre_sessions").toFixed(0)
    const sessionRow = this.add(data, "row_sessions").toFixed(0)
    const eCPMPr = (this.add(data, "pre_cpm") / data.length).toFixed(2)
    const eCPMRow = (this.add(data, "row_cpm") / data.length).toFixed(2)
    const revenuePr = this.add(data, "pre_revenue").toFixed(2)
    const revenueRow = this.add(data, "row_revenue").toFixed(2)
    const totalRevenue = this.add(data, "total_revenue").toFixed(2)
    return { sessionPr, sessionRow, eCPMPr, eCPMRow, revenuePr, revenueRow, totalRevenue }

  }

  render() {
    const { revenues } = this.props;
    let { sessionPr, sessionRow, eCPMPr, eCPMRow, revenuePr, revenueRow, totalRevenue } = revenues.length > 0 && this.calTableTotal(revenues);

    return (
      <div style={{ color: "black", width: "100%" }}>
        <ReactTable
          data={revenues}
          noDataText="No Data Found"
          columns={[
            {
              Header: "",
              columns: [
                {
                  Header: "Date",
                  accessor: d => moment(d.date).utc().format("YYYY[-]MM[-]DD"),
                  id: "date"
                },
              ]
            },
            {
              Header: "Sessions",
              headerStyle: { textAlign: "center", fontWeight: "bold" },
              columns: [
                {
                  Header: "PR-Premium",
                  id: "pre_sessions",
                  accessor: d => Number(Number(d.pre_sessions).toFixed(0)),
                  Footer: (
                    sessionPr && < span >
                      <strong>Total:</strong>{" "}
                      {sessionPr}
                    </span>
                  )
                },
                {
                  Header: "ROW",
                  id: "row_sessions",
                  accessor: d => Number(Number(d.row_sessions).toFixed(0)),
                  Footer: (
                    sessionRow && <span>
                      <strong>Total:</strong>{" "}
                      {sessionRow}
                    </span>
                  )
                }
              ]
            },
            {
              Header: "eCPM per 1k users",
              headerStyle: { textAlign: "center", fontWeight: "bold" },
              columns: [
                {
                  Header: "PR ($)",
                  id: "pre_cpm",
                  accessor: d => Number(Number(d.pre_cpm).toFixed(2)),
                  Footer: (
                    eCPMPr && <span>
                      <strong>Avg:</strong>{" "}
                      {eCPMPr}
                    </span>
                  )
                },
                {
                  Header: "ROW ($)",
                  id: "row_cpm",
                  accessor: d => Number(Number(d.row_cpm).toFixed(2)),
                  Footer: (
                    eCPMRow && <span>
                      <strong>Avg:</strong>{" "}
                      {eCPMRow}
                    </span>
                  )
                }
              ]
            },
            {
              Header: "Revenue",
              headerStyle: { textAlign: "center", fontWeight: "bold" },
              columns: [
                {
                  Header: "PR ($)",
                  id: "pre_revenue",
                  accessor: d => Number(Number(d.pre_revenue).toFixed(2)),
                  Footer: (
                    revenuePr && <span>
                      <strong>Total:</strong>{" "}
                      {revenuePr}
                    </span>
                  )
                },
                {
                  Header: "ROW ($)",
                  id: "row_revenue",
                  accessor: d => Number(Number(d.row_revenue).toFixed(2)),
                  Footer: (
                    revenueRow && <span>
                      <strong>Total:</strong>{" "}
                      {revenueRow}
                    </span>
                  )
                }
              ]
            },
            {
              Header: "",
              columns: [
                {
                  Header: "Total Revenue ($)",
                  id: "total_revenue",
                  accessor: d => Number(Number(d.total_revenue).toFixed(2)),
                  Footer: (
                    totalRevenue && <span>
                      <strong>Total:</strong>{" "}
                      {totalRevenue}
                    </span>
                  )
                }
              ]
            },

          ]}
          style={{
            // height: "400px" // This will force the table body to overflow and scroll, since there is not enough room
          }}
          defaultPageSize={10}
          className="-striped -highlight"
        />
      </div >
    )
  }

}
export default ReveneuStatsTable;
