import React from 'react';
import { connect } from 'react-redux';

import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import AppLoader from "../../app-components/app-loader.jsx";

import {
  setAnalytics, fetchAnalytics
} from '../website-management-actions.js';

class GoogleAnalytics extends React.Component {
  state = {
    gaID: ''
  }
  componentDidMount(){
      this.props.fetchAnalytics();
  }
  componentWillReceiveProps(nextProps){
      if(nextProps.gaID){
        this.setState({...this.state, gaID: nextProps.gaID});
      }
  }
  setgaID = () => {
    this.props.setAnalytics(this.state.gaID);
  }

  handleChange = (event, field) => {
    this.setState({[field]: event.target.value});
  }

  render(){
    const {isLoadingAnalytics} = this.props;
    if(isLoadingAnalytics){
      return <AppLoader message={"Loading Analytics"}/>
    }
    return (
      <>
      <GridContainer>
        <GridItem xs={12} md={6}>
          { !isLoadingAnalytics && !this.props.gaID &&
            <p> You haven't added your Google Analytics ID yet. </p>
          }
          { !isLoadingAnalytics && this.props.gaID &&
            <p> Your Google Analytics ID is currently set to {this.props.gaID} </p>
          }
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} md={6}>
        <CustomInput
          id="AnalyticsInputfield"
          labelText="Add your Google Analytics ID"
          inputProps={{
            onChange: (event)=>{this.handleChange(event, 'gaID')}
          }}
          formControlProps={{
            fullWidth: true
          }}
          value={this.state.gaID}
        />
        <Button
          color="secondary"
          onClick={this.setgaID}
        >
          Submit
        </Button>
        </GridItem>
      </GridContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    websiteManagementReducer: {
      analyticsReducer: {
        gaID, isLoadingAnalytics
      }
    }
  } = state;
  return { gaID, isLoadingAnalytics };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAnalytics: () => dispatch(fetchAnalytics()),
    setAnalytics: (gaID) => dispatch(setAnalytics(gaID))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(GoogleAnalytics);
