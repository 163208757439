

import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { isValidEmailAddress } from "../../../utils/utils.js";
import CustomInput from "components/CustomInput/CustomInput.jsx";

class StartNewsLetter extends Component {
  state = {
    email: null,
    error: {
      emailError: false
    }
  }
  handleChange = (e) => {
    if (!isValidEmailAddress(e.target.value)) {
      this.setState({
        [e.target.id]: e.target.value,
        error: {
          ...this.state.error,
          emailError: true
        }
      });
    }
    else {
      const error = {
        emailError: false
      }
      this.setState({ [e.target.id]: e.target.value, error })
    }
  }
  render() {
    return (
      <GridContainer justify="center">
        <Card style={{ width: '60%' }}>
          <h3 style={{ textAlign: 'center', marginTop: '5%' }}>Signup for NewsLetter</h3>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            <GridItem xs={12} sm={6} md={7} style={{ display: 'flex', marginTop: '5%' }}>
              <CustomInput
                labelText="Enter Your Mailchamp id"
                id="email"
                infoText="Mailchamp Id"
                value={this.state.email}
                formControlProps={{
                  style: {
                    width: '80%'
                  }
                }}
                inputProps={{
                  onChange: this.handleChange,
                }}
                error={this.state.error.emailError}
                success={!!(this.state.email && !this.state.error.emailError)}
                helpText={this.state.error.emailError ? 'email not valid' : ''}
              // onChange={this.handleChange}
              />
              <Button style={{
                background: "#fb4d24",
                color: "white",
                height: "40px",
                margin: "auto"

              }} >Go
         </Button>
            </GridItem>
            <GridItem xs={12} sm={6} md={7} style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{
                marginTop: '50px',
                marginBottom: '50px',
                fontWeight: 'bold',
                fontSize: '20px'
              }}>Or</div>
            </GridItem>

            <GridItem xs={12} sm={6} md={7} style={{ display: 'flex', justifyContent: 'center' }}>
              <Button style={{
                background: "#fb4d24",
                color: "white",
                width: '100%',
                marginBottom: '20%'
              }}

              >Continue with pubninja
          </Button>
            </GridItem>
          </div>
        </Card>
      </GridContainer >
    )
  }
}
const mapDispatchToProps = () => {
  return {
    
  }
}

export default StartNewsLetter;
