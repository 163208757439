import React from 'react';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AppLoader from "app-components/app-loader.jsx";

class FeatureSelection extends React.Component {
  state = {
    features: null,
    defaultFeatures: [
      {title: `Support ${this.props.domainName}`, checked: false },
      {title: 'Member Only posts and stories', checked: false },
      {title: 'Exclusive Newsletters', checked: false },
      {title: 'Ad free Experience', checked: false },
    ],
    totalChecks: 0,
    showFeatureForm: false,
    newFeature: '',
    monthlyPrice: '',
    annualPrice: '',
    monthlyPriceFloat: '',
    annualPriceFloat: '',
    subscriptionEnabled: 0,
    currencyType: '',
    currencySymbol: '',
    monthlyPriceError:'',
    annualPriceError: '',
  }

  componentDidMount() {
    const { subscriptionEnabled } = this.props;
    if(this.props.features == null)
      return;
    if(subscriptionEnabled == 0) {
      this.setState({
        features: this.state.defaultFeatures,
        subscriptionEnabled: 0,
        currencyType: this.props.features.currency,
        currencySymbol: this.props.features.currencySymbol,
      });
    }
    if(subscriptionEnabled == 1) {
      let propFeatures = this.props.features.monthly.offerings;
      const features = this.state.defaultFeatures;
      const currencyType = this.props.features.currency;
      const currencySymbol = this.props.features.currencySymbol;
      const monthlyPrice = this.props.features.monthly.price;
      const annualPrice = this.props.features.annually.price;
      const monthlyPriceFloat = monthlyPrice.toFixed(2);
      const annualPriceFloat = annualPrice.toFixed(2);
      let totalChecks = 0;
      let buffer = [];
      for (let item of features) {
        if(propFeatures.indexOf(item.title) > -1) {
          item.checked = true;
          totalChecks++;
          buffer.push(item.title);
        }
      }
      for(let item of propFeatures) {
        if(buffer.indexOf(item) === -1){
          features.push({ title: item, checked: true });
          totalChecks++;
        }
      }
      this.setState({
        features,
        totalChecks,
        monthlyPrice,
        annualPrice,
        monthlyPriceFloat,
        annualPriceFloat,
        subscriptionEnabled: 1,
        currencyType,
        currencySymbol
      });
    }
  }

  handleChange = (event, field) => {
    let { features, totalChecks } = this.state;
    let value = (event.target.value === 'false');
    for (let item of features) {
      if(item.title === field) {
        item.checked = value;
      }
    }
    if(value === true)
      totalChecks++;
    else totalChecks--;
    this.setState({ features, totalChecks });
  }

  submitFeatures = () => {
    let { features } = this.state;
    let payload = this.state;
    if(this.state.subscriptionEnabled === 0) {
      payload.annualPriceFloat = 1;
      payload.monthlyPriceFloat = 1;
    }
    if (this.props.isSettingFeatures || this.state.totalChecks < 1 || this.state.totalChecks > 6 || this.state.annualPrice == '' || this.state.monthlyPrice == '') {
      return;
    }
    this.props.setFeatures(payload);
  }

  handleChangeNewFeature = (event, field) => {
    this.setState({ [field]: event.target.value });
  }

  addNewFeature = () => {
    const { features } = this.state;
    features.push({ title: this.state.newFeature, checked: false });
    this.setState({ features, newFeature: '', showFeatureForm: false });
  }

  handleAmountChange = (event, field) => {
    let value = event.target.value;
    if(!isNaN(value) || value==''){
      if(value != ''){
        let n = Math.floor(Number(value));
        let condition = value=='' || (n !== Infinity && String(n) === value && n >= 0);
        //value = parseInt(value);
      }
      const previewField = field=='monthlyPrice'?'monthlyPriceFloat':'annualPriceFloat';
      this.setState({ [field]: value, [previewField]: Number(value).toFixed(2) });
      if (field == 'monthlyPrice') {
        if (+value > 178956970) {
          this.setState({ monthlyPriceError: 'Monthly Price cannot be higher than 178956970.'});
          return;
        }
        this.setState({ monthlyPriceError: '' });
        this.setAnnualPrices((value * 12), 'annualPrice', 'annualPriceFloat');
      }
      if (field == 'annualPrice') {
        if (+value < +this.state.monthlyPrice) {
          this.setState({ annualPriceError: 'Annual Price cannot be less then montly price'});
          return;
        }
        if (+value > +(this.state.monthlyPrice*12)) {
          this.setState({ annualPriceError: 'Annual Price cannot be more than 12 times montly price' });
          return;
        }
        this.setState({ annualPriceError: '' });
      }
    }
  }

  setAnnualPrices = (value, field, previewField) => {
    this.setState({ [field]: value, [previewField]: Number(value).toFixed(2), annualPriceError: ''  });
  }

  changeCurrency = (currency) => {
    //console.log(currency);
    let currencySymbol = this.state.currencyType==='usd'?'$':'र';
    this.setState({ currencyType: currency, currencySymbol })
  }

  toggleSubscription = (value) => {
    this.setState({ subscriptionEnabled: parseInt(value) });
  }

  render() {
    return (
      <>
       <GridContainer>
          <GridItem xs={12} md={4}>
            <div style={{fontWeight: 'bold', fontSize: '1.2em', marginTop: '12px'}}>Enable Subscriptions for Website</div>
          </GridItem>
          <GridItem xs={12} md={3}>
            <div style={{display: 'flex', cursor: 'pointer'}}>
              <Button color={this.state.subscriptionEnabled==1 ? 'secondary' : 'primary'} onClick={()=>this.toggleSubscription(1)}>
                Yes
              </Button>
              <Button color={this.state.subscriptionEnabled==1 ? 'primary' : 'secondary'} onClick={()=>this.toggleSubscription(0)}>
                No
              </Button>
            </div>
          </GridItem>
        </GridContainer>
        {
          this.state.subscriptionEnabled==1 &&
          <>
            <GridContainer>
            {/*  <GridItem xs={12}>
                <div style={{fontWeight: 'bold', marginTop: '20px', margin: '20px 0 10px'}}>Enter the Currency type for the subscription charges</div>
              </GridItem>
              <GridItem xs={12} md={6}>
                <div style={{display: 'flex'}}>
                  <input type="radio" name="currencyType" value="usd" onChange={()=>this.changeCurrency('USD')} checked={this.state.currencyType==='USD'}/>
                  <span style={{margin: '-5px 20px 0 5px'}}>US DOLLAR</span>
                  <input type="radio" name="currencyType" value="inr" onChange={()=>this.changeCurrency('INR')} checked={this.state.currencyType!=='USD'}/>
                  <span style={{margin: '-5px 20px 0 5px'}}>INDIAN RUPEE</span>
                </div>
              </GridItem>  */}
              <GridItem xs={12}>
                <div style={{fontWeight: 'bold', marginTop: '10px'}}>Enter the Subscription Prices for your website</div>
              </GridItem>
              <GridItem xs={6} md={3} style={{ marginTop: '-25px' }}>
                <CustomInput
                  id="monthlyprice"
                  labelText="Enter Monthly Price"
                  inputProps={{
                    onChange: (event) => { this.handleAmountChange(event, 'monthlyPrice') }
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpText={this.state.monthlyPriceError}
                  value={this.state.monthlyPrice}
                />
                <div style={{ fontSize: '0.9em', color: 'gray', marginTop: '-15px' }}>Monthly Plan: {this.state.currencySymbol || '$'}{this.state.monthlyPriceFloat}/ Month</div>
              </GridItem>
              <GridItem xs={6} md={3} style={{marginTop: '-25px'}}>
                <CustomInput
                  id="annualprice"
                  labelText="Enter Annual Price"
                  inputProps={{
                    onChange: (event)=>{this.handleAmountChange(event, 'annualPrice')}
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                  value={this.state.annualPrice}
                  helpText={this.state.annualPriceError}
                />
                <div style={{fontSize: '0.9em', color: 'gray', marginTop: '-15px'}}>Annual Plan: {this.state.currencySymbol || '$'}{this.state.annualPriceFloat}/ Year</div>
              </GridItem>

            </GridContainer>
            <GridContainer>
              <GridItem xs={12} md={6} style={{marginTop: '50px'}}>
                <div style={{fontWeight: 'bold', marginBottom: '20px'}}>What features would you offer for Subscribers?</div>
                <div style={{fontSize: '0.9em', color: 'gray'}}> You can select atleast 1 item, and atmost 6 items.</div>
              </GridItem>
              {
                this.state.features && this.state.features.length>0 &&
                this.state.features.map((item, index) => {
                  return(
                    <GridItem xs={12} md={12} key={index}>
                      <div style={{padding: '0', fontSize: '1.2em'}}>
                        <input type="checkbox" name={item.title} value={item.checked} onChange={(event)=>this.handleChange(event, `${item.title}`)} checked={item.checked} />
                        <span style={{marginLeft: '5px'}}>{item.title}</span>
                      </div>
                    </GridItem>
                  )
                })
              }
              <GridItem xs={12} sm={4}>
              {
                 !(this.state.showFeatureForm) ?
                 <div onClick={()=>{this.setState({ showFeatureForm: true })}} style={{ display: "flex", alignItems: "center", cursor: "pointer", margin: "20px 0 20px" }}>
                  <AddCircleIcon style={{ color:'#2B2D42' }} />&nbsp;&nbsp;<span style={{ color: "#2B2D42" }}>Add Custom Item</span>
                </div>
                :
                <>
                  <CustomInput
                    id="new Feature"
                    labelText="Enter Text"
                    inputProps={{
                      onChange: (event)=>{this.handleChangeNewFeature(event, 'newFeature')}
                    }}
                    formControlProps={{
                      fullWidth: true
                    }}
                    value={this.state.newFeature}
                  />
                  <Button color="primary" onClick={this.addNewFeature} disabled={this.state.newFeature.trim()===''}>
                    Add
                  </Button>
                </>
              }

              </GridItem>
              <GridItem xs={false} sm={8}>
              </GridItem>
            </GridContainer>
          </>
        }
        {((!this.props.subscriptionEnabled == 0) || (this.state.subscriptionEnabled == 1)) &&
          <GridContainer>
            <GridItem xs={4} style={{ marginTop: '10px' }}>
              <div style={{ display: 'flex' }}>
              <Button color="secondary" onClick={this.submitFeatures} disabled={this.props.isSettingFeatures || this.state.totalChecks < 1 || this.state.totalChecks > 6 || this.state.annualPrice == '' || this.state.monthlyPrice == '' || this.state.annualPriceError != '' || this.state.monthlyPriceError != ''}>
                  Submit
                </Button>
                {this.props.isSettingFeatures && <AppLoader size={20} />}
              </div>
            </GridItem>
          </GridContainer>}
      </>
    )
  }
}

export default FeatureSelection;
