import React from 'react';
import moment from 'moment';

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Assignment from "@material-ui/icons/Assignment";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import Datetime from 'react-datetime';
import Button from "components/CustomButtons/Button.jsx";
import { MessageTypes } from "../../../app-constants/index.js";
import '../../../assets/scss/website-styles.scss';

class DateTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: '',
      flag: false,
      buttonText: 'Change'
    };
    this.onChange = this.onChange.bind(this);
  }
  onChange(e, type) {
    if(type === 'time'){
      let curTime = moment().toDate();
      let newTime = moment(e.target.value).toDate();
      var diff = moment(newTime).diff(curTime, 'days')
      //console.log(`cur, sch`, diff);
    }
    if (diff < 0 || diff > 150) {
      this.props.setAppMessage('Date has to be between today and 5 months from now.', { type: MessageTypes.ERROR });
    }
    else {
      this.setState({ time: e.target.value });
      this.props.time(e.target.value);
    }
  }

  changeDateTime = (value) => {
    let curTime = moment().toDate();
    let newTime = moment(value).toDate();
    var diff = moment(newTime).diff(curTime, 'days')
    if(diff < 0 || diff > 150){
      this.props.setAppMessage('Date has to be between today and 5 months from now.', { type: MessageTypes.ERROR });
      return;
    }
    this.setState({time: moment(newTime).format(`YYYY-MM-DDTHH:mm`)});
    this.props.time(moment(newTime).format(`YYYY-MM-DDTHH:mm`));
  }

  toggleDatePicker = (flag) => {
    let oldFlag = this.state.flag;
    let oldButton = this.state.buttonText;
    this.setState({
      flag: flag===null ? !oldFlag : flag,
      buttonText: flag===null ? (oldButton=='Change'?'Done':'Change') : flag===true?'Done':'Change'
     });
  }

  render() {
    return (
      <div className="rescheduleContainer" style={{display: 'flex', justifyContent: 'center'}}>
        {/* <Datetime
          open={this.state.flag}
          onFocus={()=>{this.toggleDatePicker(true)}}
          inputProps={{
            placeholder: "",
            value: this.state.time==''?'':moment(this.state.time).format("DD/MM/YYYY, hh:mm A") || '',
            style: {minWidth: '100px', maxWidth: '150px', margin: '25px 0'}
          }}
          onChange={(event)=>this.changeDateTime(event)}
        />
        <div style={{marginTop: '12px', marginLeft: '15px'}}>
          <Button color="primary" onClick={()=>this.toggleDatePicker(null)}>
            {this.state.buttonText}
          </Button>
        </div> */}
        <CustomInput
              labelText="Select Date"
              id="time"
              inputProps={{
                onChange: event =>{
                  this.onChange(event, "time")
                },
                type: "datetime-local",
                value: this.state.time
              }}
              formControlProps={{fullWidth: true }}
        />
      </div>
    );
  }
}

export default DateTime;
