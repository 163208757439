import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button";
import { withStyles, Typography, IconButton } from "@material-ui/core";
import { MessageTypes } from "../app-constants/index.js";


const CustomDialogTitle = withStyles(theme => ({
  root: {
    // borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, toggleProgressModal } = props;
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="subheading">
        <div style={{ marginTop: '20px', display: "flex", justifyContent: "center", fontFamily: 'Playfair Display', fontSize: '2em' }}>
          {children}
        </div>
        <div style={{ width: '50px', borderTop: 'solid 1px #cecece', margin: '20px auto 25px'}}></div>
      </Typography>
      <IconButton aria-label="Close" className={classes.closeButton} onClick={() => {
        toggleProgressModal(false)
      }}>
      </IconButton>
    </DialogTitle>
  );
});

class ConfirmationBox extends PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      confirmationMessage,
      onConfirm,
      showConfirmationModal,
      toggleConfirmationBox,
      removeArticleIdMenuList
    } = this.props;

    return (
      <Dialog
      id="publishArticleContainer"
      open={showConfirmationModal}
      fullWidth={true}
      onClose={this.hide}
      maxWidth="xs"
      aria-labelledby="customized-dialog-title">
      <CustomDialogTitle>Action</CustomDialogTitle>
        <DialogContent style={{ textAlign:"center"}}>
          <h5>{confirmationMessage}</h5>
      </DialogContent>
      <DialogActions style={{justifyContent: 'center', marginBottom: '20px'}}>
      <Button color="secondary" onClick={onConfirm}>
          YES
      </Button>
          <Button  color="primary" onClick={() => { if(removeArticleIdMenuList) removeArticleIdMenuList();toggleConfirmationBox(false) }} >
          CLOSE
      </Button>
      </DialogActions>
    </Dialog>

    )
  }
}

function CustomDesc(props) {
  const { item } = props;
  return (<span style={styles[item.status]}>{item.desc}</span>)
}
const styles = {
  done: {
    color: "#008000",
  },
  error: {
    color: "#DC143C",
  },
  todo: {
    color: "#A9A9A9",
  }
}

export default ConfirmationBox;
