
import React, { Component } from 'react';
import PropTypes from "prop-types";

import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import editProfilePageStyle from "assets/jss/material-dashboard-pro-react/views/editProfilePageStyle.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import AppLoader from "../../app-components/app-loader.jsx";
import InfoTooltip from "../../app-components/information-tooltip";

import { PHONE_NUMBER, URL_VALIDATION, FACEBOOK_VALIDATION, TWITTER_VALIDATION } from "../../app-constants/common.js";
import { DEFULT_PROFILE_PICTURE_URL, DEFAULT_COVER_PICURE_URL, } from "../../app-constants/image-url.js";

let mobileErrorMessage = 'mobile number is required *';
let fbErrorMessage = 'facbook profile is required *';
let twitterErrorMessage = 'twitter profile is required *';
let blogErrorMessage = '';

class EditUserProfile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userDetails: {
        ...props.userDetails,
        name: "",
        description: "",
        firstName: "",
        lastName: "",
        mobile: "",
        fbProfile: "",
        twitterPage: "",
        blogPage: "",
        coverImage: null,
        avator: null
      },
      error: {
        name: false,
        description: false,
        firstName: false,
        lastName: false,
        mobile: false,
        fbProfile: false,
        twitterPage: false,
        blogPage: false
      },
      submittedFiles: "",
    }
  }

  handleChange = event => {
    const { id, value } = event.target;
    let userDetails = { ...this.state.userDetails, [id]: value };
    this.setState({ userDetails })
    this.onChangeValidation(id, value)
  }

  onChangeValidation = (name, value) => {
    this.setState((prevState) => {
      const error = { ...prevState.error }
      value ? (error[name] = false) : (error[name] = true)
      return { error }
    })
    if (name == "description") {
      let error = { ...this.state.error, [name]: false }
      this.setState({ error });
    }
    if (name == "firstName") {
      let error = { ...this.state.error, [name]: false }
      this.setState({ error });
    }
    if (name == "lastName") {
      let error = { ...this.state.error, [name]: false }
      this.setState({ error });
    }
    if (name == "mobile" && !PHONE_NUMBER.test(value)) {
      let error;
      if (value == "") {
        mobileErrorMessage = 'Mobile number is required *';
        error = { ...this.state.error, [name]: false };
      }
      else {
        mobileErrorMessage = 'Invalid Mobile Number *'
        error = { ...this.state.error, [name]: true };
      }
      // value == "" ? mobileErrorMessage = 'mobile number is required *' : mobileErrorMessage = 'Invalid Mobile Number *';
      // let error = { ...this.state.error, 'mobile': true };
      this.setState({ error });
    }

    if (name == 'fbProfile' && !FACEBOOK_VALIDATION.test(value)) {
      let error;
      if (value == "") {
        fbErrorMessage = 'facebook profile is required *';
        error = { ...this.state.error, [name]: false };
      }
      else {
        twitterErrorMessage = 'Invalid Facebook Url *'
        error = { ...this.state.error, [name]: true };
      }
      //value == "" ? fbErrorMessage = 'facebook profile is required *' : fbErrorMessage = 'Invalid Facebook Url *';
      //let error = { ...this.state.error, [name]: true };
      this.setState({ error });
    }

    if (name == 'twitterPage' && !TWITTER_VALIDATION.test(value)) {
      let error;
      if (value == "") {
        twitterErrorMessage = 'twitter profile is required *';
        error = { ...this.state.error, [name]: false };
      }
      else {
        twitterErrorMessage = 'Invalid Twitter Url *'
        error = { ...this.state.error, [name]: true };
      }
      //value == "" ? twitterErrorMessage = 'twitter profile is required *' : twitterErrorMessage = 'Invalid Twitter Url *';
      //let error = { ...this.state.error, [name]: true };
      this.setState({ error });
    }

    if (name == 'blogPage' && !URL_VALIDATION.test(value)) {
      let error;
      if (value == "") {
        blogErrorMessage = 'blog profile is  empty';
        error = { ...this.state.error, [name]: false };
      }
      else {
        blogErrorMessage = 'Invalid blog Url *';
        error = { ...this.state.error, [name]: true };
      }
      this.setState({ error });
    }
  }
  onChangeCoverImage = path => {
    const userDetails = { ...this.state.userDetails, 'coverImage': path, isCoverChanged: true }
    this.setState({ userDetails });
  }
  onChangeAvator = path => {
    const userDetails = { ...this.state.userDetails, 'avator': path, isProfileChanged: true }
    this.setState({ userDetails });
  }

  isvalid = () => {
    const { name, description, firstName, lastName, mobile, fbProfile, twitterPage, blogPage } = this.state.error;
    if (!name && !description && !mobile && !fbProfile && !twitterPage && !blogPage) {
      return true
    }
    else {
      return false
    }
  }

  picBlob = async (url) => {
    return new Promise((res, rej) => {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', `${url}`, true);
      xhr.responseType = 'blob';
      xhr.onload = function(e) {
        if (this.status == 200) {
          var myBlob = this.response;
          return res(myBlob);
        }
      };
      xhr.send();
    });
  }
  onSubmit = async () => {
    if (this.isvalid()) {
      const { updateFormData } = this.props;
      const { description, firstName, lastName, mobile, fbProfile, twitterPage, blogPage, avator, coverImage, isCoverChanged, isProfileChanged } = this.state.userDetails;
      let formData = new FormData();
      formData.set("description", description || '');
      formData.set("firstName", firstName || '');
      formData.set("lastName", lastName || '');
      formData.set("profilePicture", avator);
      formData.set("coverPicture", coverImage);
      formData.set("phoneNumber", mobile || '');
      formData.set("fbProfile", fbProfile || '');
      formData.set("twitterProfile", twitterPage || '');
      formData.set("ownWebsite", blogPage || '');
      updateFormData(formData)
    }
    else {
    }
  }

  componentDidMount() {
    let { description, firstName, lastName, phoneNumber: mobile, fbProfile, twitterProfile: twitterPage, ownWebsite: blogPage, profilePicture: avator, coverPicture: coverImage } = this.props.userDetails;
    let userDetails = { ...this.state.userDetails, description, firstName, lastName, mobile, fbProfile, twitterPage, blogPage, avator, coverImage };
    console.log("user Details", userDetails);
    this.setState({ userDetails });
  }


  render() {
    const { classes, isUpdatingUserDetails = false, userDetails } = this.props;
    const { profilePicture: avator, coverPicture: coverImage } = userDetails;

    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={7} >
            <form  >
              <Card className={classes[this.state.cardAnimaton]}>
                <CardBody>
                  <GridContainer justify="center">

                    <GridItem align="center" xs={12} sm={6} md={6} >
                      <ImageUpload
                        addButtonProps={{
                          color: "secondary",
                          round: false
                        }}
                        changeButtonProps={{
                          color: "primary",
                          round: false
                        }}
                        removeButtonProps={{
                          color: "secondary",
                          round: false
                        }}
                        InvalidFileError={this.props.InvalidFileError}
                        onChangeFileValue={this.onChangeAvator}
                        margin="normal"
                        defaultStoreImage={avator || this.state.userDetails.avator || DEFULT_PROFILE_PICTURE_URL}
                        // header="Profile Image"
                        imageTagName="Select Image"
                      />
                    </GridItem>
                    <GridItem align="center" xs={12} sm={6} md={6} >
                      <ImageUpload
                        addButtonProps={{
                          color: "secondary",
                          round: false
                        }}
                        changeButtonProps={{
                          color: "primary",
                          round: false
                        }}
                        removeButtonProps={{
                          color: "secondary",
                          round: false
                        }}
                        InvalidFileError={this.props.InvalidFileError}
                        onChangeFileValue={this.onChangeCoverImage}
                        margin="normal"
                        defaultStoreImage={coverImage || this.state.userDetails.coverImage || DEFAULT_COVER_PICURE_URL}
                        // header="Cover Picture"
                        imageTagName="Select Cover"
                      />
                    </GridItem>
                  </GridContainer>

                  <CustomInput
                    labelText="About You (optional)"
                    id="description"
                    infoText="Brief description about you."
                    value={this.state.userDetails.description}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: this.handleChange,
                      multiline: true,
                      rowsMax: 15
                    }}
                    error={this.state.error.description}
                    success={!!(this.state.userDetails.description && !this.state.error.description)}
                    helpText={this.state.error.description ? 'description is required *' : ''}
                    onChange={this.handleChange}
                  />

                  <CustomInput
                    labelText="First Name (required)"
                    id="firstName"
                    infoText="Robert"
                    value={this.state.userDetails.firstName}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: this.handleChange,
                      multiline: true,
                      rowsMax: 15
                    }}
                    error={this.state.error.firstName}
                    success={!!(this.state.userDetails.firstName && !this.state.error.firstName)}
                    helpText={this.state.error.firstName ? 'First Name is required *' : ''}
                    onChange={this.handleChange}
                  />

                  <CustomInput
                    labelText="Last Name (required)"
                    id="lastName"
                    infoText="Downey Jr."
                    value={this.state.userDetails.lastName}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: this.handleChange,
                      multiline: true,
                      rowsMax: 15
                    }}
                    error={this.state.error.lastName}
                    success={!!(this.state.userDetails.lastName && !this.state.error.lastName)}
                    helpText={this.state.error.lastName ? 'lastName is required *' : ''}
                    onChange={this.handleChange}
                  />
                  <CustomInput
                    labelText="Mobile Number (optional)"
                    id="mobile"
                    infoText="Enter the mobile number with the country code."
                    value={this.state.userDetails.mobile}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      // endAdornment: (
                      //   <InputAdornment position="end">
                      //     <Icon className={classes.inputAdornmentIcon} />
                      //   </InputAdornment>
                      // ),
                      onChange: this.handleChange,
                      placeholder: `Mobile number with country code.(+XX XXXX-XXXX)`
                    }}
                    error={this.state.error.mobile}
                    success={!!(this.state.userDetails.mobile && !this.state.error.mobile)}
                    helpText={this.state.error.mobile ? `${mobileErrorMessage}` : ''}
                  />
                  <CustomInput
                    labelText="Fb Profile (optional)"
                    id="fbProfile"
                    infoText="Enter your facebook profile link."
                    value={this.state.userDetails.fbProfile}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      // endAdornment: (
                      //   <InputAdornment position="end">
                      //     <Icon className={classes.inputAdornmentIcon} />
                      //   </InputAdornment>
                      // ),
                      onChange: this.handleChange
                    }}
                    error={this.state.error.fbProfile}
                    success={!!(this.state.userDetails.fbProfile && !this.state.error.fbProfile)}
                    helpText={this.state.error.fbProfile ? `${fbErrorMessage}` : ''}
                    margin="normal"
                  />
                  <CustomInput
                    labelText="Twitter Profile (optional)"
                    id="twitterPage"
                    infoText="Enter your twitter profile link."
                    value={this.state.userDetails.twitterPage}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      // endAdornment: (
                      //   <InputAdornment position="end">
                      //     <Icon className={classes.inputAdornmentIcon} />
                      //   </InputAdornment>
                      // ),
                      onChange: this.handleChange
                    }}
                    error={this.state.error.twitterPage}
                    success={!!(this.state.userDetails.twitterPage && !this.state.error.twitterPage)}
                    helpText={this.state.error.twitterPage ? `${twitterErrorMessage}` : ''}
                    margin="normal"
                  />
                  <CustomInput
                    labelText="Blog Profile (optional)"
                    infoText="Enter your personal blog link."
                    id="blogPage"
                    value={this.state.userDetails.blogPage ? this.state.userDetails.blogPage : ""}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      // endAdornment: (
                      //   <InputAdornment position="end">
                      //     <Icon className={classes.inputAdornmentIcon} />
                      //   </InputAdornment>
                      // ),
                      onChange: this.handleChange
                    }}
                    error={this.state.error.blogPage}
                    success={!!(this.state.userDetails.blogPage && !this.state.error.blogPage)}
                    helpText={this.state.error.blogPage ? `${blogErrorMessage}` : ''}
                    margin="normal"
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button disabled={!this.isvalid()} color="secondary" size="lg" onClick={this.onSubmit} style={{ pointerEvents: `${isUpdatingUserDetails ? "none" : "auto"}` }}>
                    Submit {isUpdatingUserDetails ? <AppLoader isMarginZero size={20} /> : null}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer >
      </div >
    );
  }
}



EditUserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  phoneNumber: PropTypes.number,
  description: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  fbProfile: PropTypes.string,
  twitterProfile: PropTypes.string,
  profilePictureUrl: PropTypes.string,
  coverPictureUrl: PropTypes.string
}

export default withRouter((withStyles(editProfilePageStyle)(EditUserProfile)));
