import React, { PureComponent } from "react";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import { withStyles } from '@material-ui/core';
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

class ArticleCategory extends PureComponent {
  constructor(props) {
    super(props);
  }
  handleCategoryChange = (e) => {
    this.props.setCategoryType(e.target.value);
    const subCategory = this.props.categories.find((category) => category.id == e.target.value).yCategory;
    this.props.setSubCategory(subCategory)
  }
  render() {
    const { classes, categoryId, categories } = this.props;
    if (!categories) {
      return null;
    }
    return (
      <div style={{ marginBottom: "20px" }}>
        <FormControl
          fullWidth
          className={classes.selectLabel}
        >
          <InputLabel
            htmlFor="article-category"
            className={classes.selectLabel}
          >
            Category
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={categoryId ? categoryId : 0}
            onChange={this.handleCategoryChange}
            inputProps={{
              name: "article-category",
              id: "article-category",
              classes: {
                icon: classes.iconMargin
              },
            }}
          >
            {
              categories.map(({ id, categoryVal }) => {
                if (id === 0) {
                  return null;
                }
                return (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    key={id}
                    value={id}
                  >
                    {categoryVal}
                  </MenuItem>)
              })
            }
          </Select>
        </FormControl>
      </div>
    )
  }
}
export default withStyles(customSelectStyle)(ArticleCategory);