import React, { PureComponent } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SocialLink from './social-link.jsx';
import { DEFULT_PROFILE_PICTURE_URL } from "../../../app-constants/image-url.js";
import moment from 'moment';
import CATEGORY_PARAMS from '../../../utils/category-config.js';
import withStyles from "@material-ui/core/styles/withStyles";
import landingPageStyle from '../../../assets/jss/material-dashboard-pro-react/views/landingPageStyle';

class LandingPageCard extends PureComponent {
  state = { showMenu: true }
  setIdForMenu(e, id) {
    this.props.setIdForMenuDropDown(id)
    this.props.setAnchorEl(e.currentTarget)
  }
  getHighlightedText(text, higlight, slug, activeFqdn) {
    if (!text) return text
    let parts = text.split(new RegExp(`(${higlight})`, 'gi'));
    if (slug) {
      return <a href={`https://${activeFqdn.trim()}/profile/${slug}`} target="_blank"> {parts.map((part, i) =>
        <span key={i} style={part.toLowerCase() === higlight.toLowerCase() ? { fontWeight: 'bold' } : {}}>
          {part}
        </span>)
      } </a>
    } else {
      return <> {parts.map((part, i) =>
        <span key={i} style={part.toLowerCase() === higlight.toLowerCase() ? { fontWeight: 'bold' } : {}}>
          {part}
        </span>)
      } </>
    }
  }
  render() {
    const open = true;
    const { landingPage,
      categoryName,
      searchText,
      subCategoryName,
      activeFqdn,
      classes } = this.props;
    const { id,
      slug,
      displayName,
      description,
      twitterUrl,
      fbUrl,
      instaUrl,
      active,
      heroImage } = landingPage;
    const date = CATEGORY_PARAMS[subCategoryName] ? CATEGORY_PARAMS[subCategoryName]['field'] : 'date';
    return (
      <div className={classes.lpMainContainer}>
        <div className={classes.lpCont}>
          <div style={{ display: 'flex' }}>
            <div >
              <img className={classes.lpHeroImage} src={`${heroImage || DEFULT_PROFILE_PICTURE_URL}`} />
            </div>
            <div style={{ paddingLeft: '15px' }}>
              <h3 className={classes.lpDisplayName}>
                {displayName ? this.getHighlightedText(displayName, searchText, slug, activeFqdn) : ''}
              </h3>
              <div style={{ display: 'flex' }}>
                <span className={classes.lpIcon} style={{ background: `${active == 1 ? '#24c32a' : '#8d8d8d'}` }}></span>
                <p className={classes.lpDate}>
                  {`${landingPage[date] ? moment(landingPage[date]).format("YYYY-MM-DD") : 'No date'}`}
                </p>
              </div>
            </div>
          </div>
          <div style={{ marginLeft: 'auto', paddingRight: '5px' }}>
            <IconButton
              style={{ marginLeft: '10px' }}
              aria-label="More"
              aria-owns={open ? 'long-menu' : undefined}
              aria-haspopup="true"
              onClick={(e) => { this.setIdForMenu(e, id) }}
            >
              <MoreVertIcon />
            </IconButton>
          </div>
        </div >
        <p className={classes.description} >
          {description ? this.getHighlightedText(description, searchText) : ''}
        </p>
        <SocialLink twitterUrl={twitterUrl} fbUrl={fbUrl} instaUrl={instaUrl} />
      </div >
    )
  }
}
export default withStyles(landingPageStyle)(LandingPageCard);
