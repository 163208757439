/* Article action option s
*/
import { ActionOnArticle } from "../app-constants/index.js";
const ArticleActionOptions = {
    Draft: [
        {
            key: "Edit",
            icon: "Edit",
            text: "Edit",
            requiredPermissions: ["CREATE:WRITE"],
            action: ActionOnArticle.EDIT,
        }, {
            key: "Submit",
            icon: "Submit",
            text: "Submit",
            requiredPermissions: ["CREATE:WRITE"],
            action: ActionOnArticle.SUBMIT,
            //this will come from reducer
            // loaderName: "isSubmitting"
        }, {
            key: "separator"
        }, {
            key: "Delete",
            icon: "Delete",
            text: "Delete",
            requireConfirm: true,
            confirmMsg: 'Do you want to delete the article ?',
            requiredPermissions: ["CREATE:WRITE"],
            action: ActionOnArticle.DELETE,
            loaderName: "isDeleting"
        },
        {
            key: "Preview",
            icon: "Preview",
            text: "Preview",
            requiredPermissions: ["CREATE:WRITE", "CREATE:EDIT", "CREATE:PUBLISH"],
            action: ActionOnArticle.PREVIEW
        }
    ],
    Submit: [
        {
            key: "Review",
            icon: "Review",
            requiredPermissions: ["CREATE:EDIT"],
            action: ActionOnArticle.REVIEW_AND_EDIT,
            text: (articleDetails = {}, user) => {
                if (!articleDetails.inReviewBy) {
                    return "Review & Edit";
                }
                if (articleDetails.inReviewBy && articleDetails.inReviewBy.id === user.id) {
                    return "Continue Editing"
                } else {
                    return null;
                }
            }
        },
        {
            key: "FinishEdit",
            icon: "finish",
            requiredPermissions: ["CREATE:EDIT"],
            action: ActionOnArticle.FINISH_EDIT,
            loaderName: "isFinishingEdit",
            text: (articleDetails = {}, user) => {
                if (articleDetails.inReviewBy && articleDetails.inReviewBy.id === user.id) {
                    return "Finish Editing"
                } else {
                    return null;
                }
            }
        },
        {
            key: "Approve",
            icon: "Approve",
            text: "Approve",
            requiredPermissions: ["CREATE:EDIT"],
            requireConfirm: true,
            confirmMsg: 'Do you want to approve the article ?',
            action: ActionOnArticle.APPROVE,
            // loaderName: "isApproving"
        },
        {
            key: "separator"
        }, {
            key: "SendFeedback",
            icon: "feedback",
            text: "Send Feedback",
            requiredPermissions: ["CREATE:EDIT"],
            action: ActionOnArticle.SEND_FEEDBACK,
        }, {
            key: "ForceEdit",
            icon: "ForceEdit",
            requiredPermissions: ["CREATE:EDIT",],
            action: ActionOnArticle.FORCE_EDIT,
            text: (articleDetails = {}, user) => {
                if (articleDetails.inReviewBy && articleDetails.inReviewBy.id !== user.id) {
                    return "Force Edit";
                }
                return null
            }

        },
        {
            key: "Preview",
            icon: "Preview",
            text: "Preview",
            requiredPermissions: ["CREATE:WRITE", "CREATE:EDIT", "CREATE:PUBLISH"],
            action: ActionOnArticle.PREVIEW
        }
    ],
    Approve: [
        {
            key: "Review",
            icon: "Review",
            requiredPermissions: ["CREATE:EDIT",],
            action: ActionOnArticle.REVIEW_AND_EDIT,
            text: (articleDetails = {}, user) => {
                if (!articleDetails.inReviewBy) {
                    return "Review & Edit";
                }
                if (articleDetails.inReviewBy && articleDetails.inReviewBy.id === user.id) {
                    return "Continue Editing"
                } else {
                    return null;
                }
            }
        },
        {
            key: "FinishEdit",
            icon: "finish",
            requiredPermissions: ["CREATE:EDIT"],
            action: ActionOnArticle.FINISH_EDIT,
            loaderName: "isFinishingEdit",
            text: (articleDetails = {}, user) => {
                if ((articleDetails.inReviewBy && articleDetails.inReviewBy.id) === user.id) {
                    return "Finish Editing"
                } else {
                    return null;
                }
            }
        },
        {
            key: "Publish",
            icon: "Publish",
            action: ActionOnArticle.PUBLISH,
            requireConfirm: true,
            confirmMsg: 'Are you sure to publish the article ?',
            requiredPermissions: ["CREATE:PUBLISH"],
            text: "Publish",

        },
        {
            key: "PublishLater",
            icon: "Publish Later",
            requiredPermissions: ['CREATE:PUBLISH'],
            text: "Publish Later",
            action: ActionOnArticle.SCHEDULE_ARTICLE,
        },
        {
            key: "separator"
        }, {
            key: "SendFeedback",
            icon: "feedback",
            action: ActionOnArticle.SEND_FEEDBACK,
            requiredPermissions: ["CREATE:EDIT"],
            text: "Send Feedback"
        },
        {
            key: "ForceEdit",
            icon: "ForceEdit",
            requiredPermissions: ["CREATE:EDIT",],
            action: ActionOnArticle.FORCE_EDIT,
            text: (articleDetails = {}, user) => {
                if (articleDetails.inReviewBy && articleDetails.inReviewBy.id !== user.id) {
                    return "Force Edit";
                }
                return null
            }
        },
        {
          key: "Enable on Msn Gallery",
          requiredPermissions: ["CREATE:EDIT"],
          action: ActionOnArticle.TOGGLE_MSN_GALLERY,
          requireConfirm: true,
          confirmMsg: 'Are you sure?',
          text: (articleDetails = {}) => {
            if (typeof articleDetails.enabledMsnGallery === 'boolean' && articleDetails.enabledMsnGallery === true) {
              return "Disable on Msn Gallery";
            } else if (typeof articleDetails.enabledMsnGallery === 'boolean' && articleDetails.enabledMsnGallery === false) {
              return "Enable on Msn Gallery";
            } else {
              return "Enable on Msn Gallery";
            }
          }
        }
    ],
    Queue: [
        {
            key: "Review",
            icon: "Review",
            requiredPermissions: ["CREATE:EDIT",],
            action: ActionOnArticle.REVIEW_AND_EDIT,
            text: (articleDetails = {}, user) => {
                if (!articleDetails.inReviewBy) {
                    return "Review & Edit";
                }
                if (articleDetails.inReviewBy && articleDetails.inReviewBy.id === user.id) {
                    return "Continue Editing"
                } else {
                    return null;
                }
            }
        },
        {
            key: "FinishEdit",
            icon: "finish",
            requiredPermissions: ["CREATE:EDIT"],
            action: ActionOnArticle.FINISH_EDIT,
            loaderName: "isFinishingEdit",
            text: (articleDetails = {}, user) => {
                if ((articleDetails.inReviewBy && articleDetails.inReviewBy.id) === user.id) {
                    return "Finish Editing"
                } else {
                    return null;
                }
            }
        },
        {
            key: "Publish",
            icon: "Publish",
            action: ActionOnArticle.PUBLISH,
            requireConfirm: true,
            confirmMsg: 'Are you sure to publish the article ?',
            requiredPermissions: ["CREATE:PUBLISH"],
            text: "Publish",

        },
        {
            key: "PublishLater",
            icon: "Publish Later",
            requiredPermissions: ['CREATE:PUBLISH'],
            text: "Publish Later",
            action: ActionOnArticle.SCHEDULE_ARTICLE,
        },
        {
            key: "CancelScheduledArticle",
            icon: "CancelScheduledArticle",
            requiredPermissions: ["CREATE:EDIT"],
            requireConfirm: true,
            confirmMsg: 'Do you want to Cancel Scheduled Article ?',
            text: "Cancel Scheduled Article",
            action: ActionOnArticle.CANCEL_SCHEDULED_ARTICLE,
        },
        {
            key: "separator"
        }, {
            key: "SendFeedback",
            icon: "feedback",
            action: ActionOnArticle.SEND_FEEDBACK,
            requiredPermissions: ["CREATE:EDIT"],
            text: "Send Feedback"
        },
        {
            key: "ForceEdit",
            icon: "ForceEdit",
            requiredPermissions: ["CREATE:EDIT",],
            action: ActionOnArticle.FORCE_EDIT,
            text: (articleDetails = {}, user) => {
                if (articleDetails.inReviewBy && articleDetails.inReviewBy.id !== user.id) {
                    return "Force Edit";
                }
                return null
            }
        },
        {
          key: "Enable on Msn Gallery",
          requiredPermissions: ["CREATE:EDIT"],
          action: ActionOnArticle.TOGGLE_MSN_GALLERY,
          requireConfirm: true,
          confirmMsg: 'Are you sure?',
          text: (articleDetails = {}) => {
            if (typeof articleDetails.enabledMsnGallery === 'boolean' && articleDetails.enabledMsnGallery === true) {
              return "Disable on Msn Gallery";
            } else if (typeof articleDetails.enabledMsnGallery === 'boolean' && articleDetails.enabledMsnGallery === false) {
              return "Enable on Msn Gallery";
            } else {
              return "Enable on Msn Gallery";
            }
          }
        }
    ],
    Live: [
        {
            key: "ViewOnWebsite",
            icon: "view",
            requiredPermissions: ["CREATE:WRITE", "CREATE:EDIT", "CREATE:PUBLISH"],
            text: "View On Website",
            action: ActionOnArticle.VIEW_ON_WEBSITE,
        },
        {
            key: "Share",
            icon: "Share",
            requiredPermissions: ["CREATE:WRITE", "CREATE:EDIT", "CREATE:PUBLISH"],
            text: "Share",
            action: ActionOnArticle.SHARE,
        },
        {
            key: "EnableAds",
            icon: "Share",
            text: (articleDetails = {}, user) => {
                if (articleDetails.noAds) {
                    return "Enable Ads.";
                } else if (!articleDetails.noAds) {
                    return "Disable Ads.";
                }
                return null;
            },
            requiredFeaturePermission: 'DISABLE_ADS_SPECIFIC_ARTICLE',
            requiredPermissions: ['CREATE:PUBLISH'],
            action: ActionOnArticle.TOGGLE_ADS,
        },
        {
            key: "Review",
            icon: "Review",
            requiredPermissions: ["CREATE:EDIT",],
            action: ActionOnArticle.REVIEW_AND_EDIT,
            text: (articleDetails = {}, user) => {
                if (!articleDetails.inReviewBy) {
                    return "Review & Edit";
                }
                if (articleDetails.inReviewBy && articleDetails.inReviewBy.id === user.id) {
                    return "Continue Editing"
                } else {
                    return null;
                }
            }
        },
        {
            key: "FinishEdit",
            icon: "finish",
            requiredPermissions: ["CREATE:EDIT"],
            action: ActionOnArticle.FINISH_EDIT,
            loaderName: "isFinishingEdit",
            text: (articleDetails = {}, user) => {
                if ((articleDetails.inReviewBy && articleDetails.inReviewBy.id) === user.id) {
                    return "Finish Editing"
                } else {
                    return null;
                }
            }
        },
        {
            key: "ClearCache",
            icon: "Clear Cache",
            requiredPermissions: ["CREATE:PUBLISH"],
            text: "Clear Cache",
            requireConfirm: true,
            confirmMsg: 'Do you want to clear cache ?',
            action: ActionOnArticle.CLEAR_CACHE,
            loaderName: "isDeletingCache",
        },
        {
            key: "ShareDebug",
            icon: "Share Debug",
            requiredPermissions: ["CREATE:PUBLISH"],
            text: "Share Debug",
            requireConfirm: false,
            action: ActionOnArticle.SHARE_DEBUG,
            loaderName: "isShareDebug",
        },
        {
            key: "separator"
        },
        {
            key: "ForceEdit",
            icon: "ForceEdit",
            requiredPermissions: ["CREATE:EDIT",],
            action: ActionOnArticle.FORCE_EDIT,
            text: (articleDetails = {}, user) => {
                if (articleDetails.inReviewBy && articleDetails.inReviewBy.id !== user.id) {
                    return "Force Edit";
                }
                return null
            }
        },
        {
            key: "Unpublish",
            icon: "Unpublish",
            requiredPermissions: ["CREATE:PUBLISH"],
            requireConfirm: true,
            confirmMsg: 'Do you want to unpublish article ?',
            text: "Unpublish",
            action: ActionOnArticle.UNPUBLISH,
        },
        {
            key: "Preview",
            icon: "Preview",
            text: "Preview",
            requiredPermissions: ["CREATE:WRITE", "CREATE:EDIT", "CREATE:PUBLISH"],
            action: ActionOnArticle.PREVIEW
        },
        {
            key: "separator"
        },
        {
            key: "ScheduleOnFacebook",
            icon: "Clear Cache",
            requiredFeaturePermission: 'SOCIAL_SCHEDULE',
            requiredPermissions: ['SHARE:SOCIAL_SCHEDULE'],
            text: "Schedule On Facebook",
            action: ActionOnArticle.FACEBOOK_SCHEDULE,
        },
        {
            key: "ExploitSchedule",
            icon: "Clear Cache",
            requiredPermissions: ["CREATE:PUBLISH"],
            text: "Schedule as Exploit",
            action: ActionOnArticle.EXPLOIT_SCHEDULE,
        },
        {
            key: "CancelExploitArticle",
            icon: "CancelExploitArticle",
            requiredPermissions: ["CREATE:PUBLISH"],
            requireConfirm: true,
            confirmMsg: 'Do you want to Cancel Schedule as Exploit ?',
            text: "Cancel Scheduled as Exploit",
            action: ActionOnArticle.CANCEL_SCHEDULE_EXPLOIT_ARTICLE,
        },
        {
            key: "ScheduleOnTwitter",
            icon: "Clear Cache",
            requiredFeaturePermission: 'SOCIAL_SCHEDULE',
            requiredPermissions: ['SHARE:SOCIAL_SCHEDULE'],
            text: "Schedule On Twitter",
            action: ActionOnArticle.TWITTER_SCHEDULE,
        },
        {
            key: "SendBrowserNotification",
            icon: "Clear Cache",
            requiredFeaturePermission: 'BROWSER_NOTIFICATION',
            requiredPermissions: ['BROWSER_NOTIFICATION'],
            text: "Send Browser Notification",
            action: ActionOnArticle.SEND_NOTIFICATION,
            loaderName: "isSendingNotifications"
        },
        {
          key: "Enable on Msn Gallery",
          requiredPermissions: ["CREATE:EDIT"],
          action: ActionOnArticle.TOGGLE_MSN_GALLERY,
          requireConfirm: true,
          confirmMsg: 'Are you sure?',
          text: (articleDetails = {}) => {
            if (typeof articleDetails.enabledMsnGallery === 'boolean' && articleDetails.enabledMsnGallery === true) {
              return "Disable on Msn Gallery";
            } else if (typeof articleDetails.enabledMsnGallery === 'boolean' && articleDetails.enabledMsnGallery === false) {
              return "Enable on Msn Gallery";
            } else {
              return "Enable on Msn Gallery";
            }
          }
        }
    ]
}

export default ArticleActionOptions;
