import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { getArticles, resetCurrentPage, toggleConfirmationBox, getNewArticles } from "../articles-actions.js";
import {
  setArticleIdForMenuList,
  setArticleIdForPreviewArticle,
  removeArticleIdForMenuList,
  toggleProgressModal,
  toggleShareArticleModal,
  setCurrentLiveArticleHeight,
  toggleScheduleArticleModal,
  toggleExploitArticleModal,
  toggleScheduleTwitterArticleModal,
  setSchedularModal
} from "../../article/article-action.js";
import ArticlesList from "../components/article-list";
import AppLoader from "../../../app-components/app-loader.jsx";
import { ArticlesState } from "../../../app-constants/index.js";
import FeedbackModal from "../../article/component/feeback-modal.jsx";
import CreateNewArticleContainer from "./create-new-article-container.jsx";
import {
  sendFeedback,
  setFeedbackModal,
  postSchedular,
  loadArticleContentForApproved,
} from "../../article/article-action.js";
import { withRouter } from "react-router-dom";
import SchedularModal from "../../article/component/schedular-modal.jsx";



function hasUserPermission(permisssions, requiredPermission) {
  if (permisssions && permisssions.length > 0 && permisssions.includes(requiredPermission)) {
    return true;
  }
  return false;
}
class ViewArticles extends PureComponent {

  handleFocusChange = () => {
    // document.getElementById("mainPanel") && document.getElementById("mainPanel").scroll({ top: 0, behavior: 'auto' });
    !this.props.isLiveSearch && this.props.getNewArticles();
  }
  componentDidMount() {
    window.onfocus = this.handleFocusChange;
    this.props.resetCurrentPage();
    if (this.props.lastLocation == 'edit-page' && this.props.articleState == "LiveArticles") {
      return
    }
    !this.props.isLiveSearch && this.props.getArticles();
  }
  render() {
    const {
      articlesIds,
      articlesByIds,
      getArticles,
      articleLetter,
      menuListAnchorEl,
      fetchPreviewArticleDetail,
      menuListArticleId,
      removeArticleIdForMenuList,
      setArticleIdForPreviewArticle,
      setArticleIdForMenuList,
      setCurrentLiveArticleHeight,
      options,
      showPublishProgressModal,
      progressModalData,
      toggleProgressModal,
      toggleShareArticleModal,
      showShareArticleModal,
      shareArticleData,
      toggleScheduleArticleModal,
      toggleExploitArticleModal,
      showScheduleArticleModal,
      showExploitArticleModal,
      scheduleArticleData,
      exploitArticleData,
      toggleScheduleTwitterArticleModal,
      showScheduleTwitterArticleModal,
      scheduleTwitterArticleData,
      articlesSettings: {
        loadingArticles,
        currentPage,
        hasMoreData,
      },
      user,
      domain,
      articleState,

      sendFeedback,
      sendingFeedback,
      showFeedbackModal,
      setFeedbackModal,
      liveSearch,
      isLiveSearch,
      lastLocation,
      currentLiveHeight,
      showConfirmationModal,
      confirmationMessage,
      onConfirm,
      toggleConfirmationBox,
      showArticleSchedular,
      setSchedularModal,
      postSchedular,
      currentArticleDetail,
      loadArticleContentForApproved
    } = this.props;

    return (
      <>
        {
          articleState === ArticlesState.DRAFT_ARTICLES &&
          user && hasUserPermission(user.permissions, "CREATE:WRITE")
          && <CreateNewArticleContainer />
        }
        <ArticlesList
          showConfirmationModal={showConfirmationModal}
          confirmationMessage={confirmationMessage}
          onConfirm={onConfirm}
          toggleConfirmationBox={toggleConfirmationBox}
          lastLocation={lastLocation}
          currentLiveHeight={currentLiveHeight}
          articleState={articleState}
          currentPage={currentPage}
          articlesByIds={articlesByIds}
          articlesIds={articlesIds}
          articleLetter={articleLetter}
          getArticles={getArticles}
          getNewArticles={getNewArticles}
          loadingArticles={loadingArticles}
          setArticleIdForMenuList={setArticleIdForMenuList}
          setArticleIdForPreviewArticle={setArticleIdForPreviewArticle}
          hasMoreData={hasMoreData}
          menuListArticleId={menuListArticleId}
          toggleShareArticleModal={toggleShareArticleModal}
          showShareArticleModal={showShareArticleModal}
          shareArticleData={shareArticleData}
          toggleScheduleArticleModal={toggleScheduleArticleModal}
          showScheduleArticleModal={showScheduleArticleModal}
          toggleExploitArticleModal={toggleExploitArticleModal}
          showExploitArticleModal={showExploitArticleModal}
          scheduleArticleData={scheduleArticleData}
          exploitArticleData={exploitArticleData}
          toggleScheduleTwitterArticleModal={toggleScheduleTwitterArticleModal}
          showScheduleTwitterArticleModal={showScheduleTwitterArticleModal}
          scheduleTwitterArticleData={scheduleTwitterArticleData}
          toggleProgressModal={toggleProgressModal}
          showPublishProgressModal={showPublishProgressModal}
          progressModalData={progressModalData}
          removeArticleIdForMenuList={removeArticleIdForMenuList}
          fetchPreviewArticleDetail={fetchPreviewArticleDetail}
          menuListAnchorEl={menuListAnchorEl}
          options={options}
          user={user}
          domain={domain}
          liveSearch={liveSearch}
          isLiveSearch={isLiveSearch}
          setCurrentLiveArticleHeight={setCurrentLiveArticleHeight}

        />
        {
          loadingArticles && <AppLoader
            message={`Loading ${articlesIds.length > 0 ? 'more ' : ''}Articles...`}
          />
        }{
          showFeedbackModal && <FeedbackModal
            sendingFeedback={sendingFeedback}
            sendFeedback={sendFeedback}
            showFeedbackModal={showFeedbackModal}
            setFeedbackModal={setFeedbackModal}
            articleState={articleState}
          />
        }{
          showArticleSchedular && <SchedularModal
            showArticleSchedular={showArticleSchedular}
            setSchedularModal={setSchedularModal}
            postSchedular={postSchedular}
            getArticles={getArticles}
            currentArticleDetail={currentArticleDetail}
          />
        }
      </>
    )
  }
}
const mapStateToProps = (store, ownProps) => {
  const { articleState } = ownProps;
  const articleType = articleState.charAt(0).toLowerCase() + articleState.substr(1);
  const {
    articles: {
      articlesByIds,
      [`${articleType}`]: {
        articlesSettings,
        articlesIds
      },
      articlesSettings: {
        confirmationBox: {
          showConfirmationModal,
          confirmationMessage,
          onConfirm,
        },
      }
    },
    articleSettings: {
      menuListArticleId,
      sendingFeedback,
      showPublishProgressModal,
      progressModalData,
      shareArticleData,
      showShareArticleModal,
      showScheduleArticleModal,
      showExploitArticleModal,
      scheduleArticleData,
      exploitArticleData,
      showScheduleTwitterArticleModal,
      scheduleTwitterArticleData,
      showFeedbackModal,
      lastLocation,
      currentLiveHeight,
      showArticleSchedular,
    }
  } = store.cms;
  const { login: {
    user: { id: activeUser },
    domain
  } } = store;
  const currentArticleDetail = articlesByIds[menuListArticleId];
  return {
    showConfirmationModal,
    confirmationMessage,
    onConfirm,
    lastLocation,
    currentLiveHeight,
    articlesByIds,
    articlesIds,
    articlesSettings,
    menuListArticleId,
    showShareArticleModal,
    showScheduleArticleModal,
    showExploitArticleModal,
    scheduleArticleData,
    exploitArticleData,
    showScheduleTwitterArticleModal,
    scheduleTwitterArticleData,
    shareArticleData,
    showPublishProgressModal,
    progressModalData,
    sendingFeedback: sendingFeedback,
    showFeedbackModal: showFeedbackModal,
    user: store.userManagement.accountManagement.userList[activeUser],
    domain,
    showArticleSchedular,
    currentArticleDetail
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { articleState } = ownProps;
  return {
    toggleShareArticleModal: (visible, articleData) => dispatch(toggleShareArticleModal(visible, articleData)),
    toggleScheduleArticleModal: (visible, articleData) => dispatch(toggleScheduleArticleModal(visible, articleData)),
    toggleExploitArticleModal: (visible, articleData) => dispatch(toggleExploitArticleModal(visible, articleData)),
    toggleScheduleTwitterArticleModal: (visible, articleData) => dispatch(toggleScheduleTwitterArticleModal(visible, articleData)),
    toggleProgressModal: (visible) => dispatch(toggleProgressModal(visible)),
    getArticles: () => dispatch(getArticles(articleState)),
    resetCurrentPage: () => dispatch(resetCurrentPage(articleState)),
    setArticleIdForMenuList: (articleId) => dispatch(setArticleIdForMenuList(articleId)),
    removeArticleIdForMenuList: () => dispatch(removeArticleIdForMenuList()),
    setArticleIdForPreviewArticle: (articleId) => dispatch(setArticleIdForPreviewArticle(articleId)),
    sendFeedback: (feedback, articleState) => dispatch(sendFeedback(feedback, articleState)),
    setFeedbackModal: (status) => dispatch(setFeedbackModal(status)),
    setCurrentLiveArticleHeight: (height) => dispatch(setCurrentLiveArticleHeight(height)),
    toggleConfirmationBox: (show, message, onConfirm) => dispatch(toggleConfirmationBox(show, message, onConfirm)),
    getNewArticles: () => dispatch(getNewArticles(articleState)),
    setSchedularModal: (status) => dispatch(setSchedularModal(status)),
    postSchedular: (date) => dispatch(postSchedular(date)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewArticles));
