import React, { Component } from "react";
import { Spin, Icon, Input } from "antd";
import { connect } from "react-redux";
import { Prompt } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
//import Button from '@material-ui/core/Button';
import Button from "components/CustomButtons/Button";
import AppLoader from "../../../app-components/app-loader.jsx";

import {
  setCoverPictureCropData,
  setCoverPictureCropImage,
  changeCoverPicture,
  setCoverPictureImageSource
} from "../new-story-actions.js";
import { PiscoContentTypes } from "app-constants/index.js";
import "../../../assets/scss/material-dashboard-pro-react/plugins/_pisco-pane.scss";

const Configs = {
  NEW_ARTICLE: {
    COVER_PICTURE: {
      COVER_PICTURE_MAX_SIZE: 2097152,
      COVER_IMAGE_MIN_WIDTH: 1200,
      COVER_IMAGE_MIN_HEIGHT: 630,
      COVER_SCALE: {
        "scale": 1,
        "angle": 0,
        "x": 0,
        "y": 0,
        "w": 1200,
        "h": 630,
      }
    }
  },
  NEW_NEWS: {
    COVER_PICTURE: {
      COVER_PICTURE_MAX_SIZE: 2097152,
      COVER_IMAGE_MIN_WIDTH: 1200,
      COVER_IMAGE_MIN_HEIGHT: 630,
      COVER_SCALE: {
        "scale": 1,
        "angle": 0,
        "x": 0,
        "y": 0,
        "w": 1200,
        "h": 630,
      }
    }
  },
}

const { NEW_ARTICLE: { COVER_PICTURE: CoverPictureConfigs } } = Configs;
const DEFAULT_COVER_PICTURE = "https://cheesecake.articleassets.meaww.com/assets/new_default_cover_pic_1200x630.png";


const styles = {
  coverPictureForm: {
    display: "flex",
    flexDirection: "column",
    marginTop: "8px",
    alignItems: "flex-end",
    top: "0px",
    right: "10px",
    position: "absolute",
    float: "right",
  },
  inputImageWrapper: {
    overflow: "hidden",
    position: "relative",
  },
  coverImage: {
    fontSize: "50px",
    position: "absolute",
    bottom: 0,
    right: 0,
    opacity: 0,
    height: "50px",
    lineHeight: 1,
    cursor: "pointer",
  }
}


class CoverPicture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    }

    this.changeCoverForm = React.createRef();
    this.coverPicture = React.createRef();
    this.cropPicture = React.createRef();

    this.changeCoverImage = this.changeCoverImage.bind(this);

    this.setZoomOut = this.setZoomOut.bind(this);
    this.setFit = this.setFit.bind(this);
    this.setZoomIn = this.setZoomIn.bind(this);

    this.handleSave = this.handleSave.bind(this);
    this.chooseFile = this.chooseFile.bind(this);

    //Modal
    this.handleClose = this.handleClose.bind(this);
  }

  chooseFile(event) {
    this.props.onPiscoOpen(PiscoContentTypes.COVER_IMAGE);
    event.preventDefault();
  }

  setZoomOut() {
    window.$("#crop_picture").guillotine("zoomOut");
  }

  setZoomIn() {
    window.$("#crop_picture").guillotine("zoomIn");
  }

  setFit() {
    window.$("#crop_picture").guillotine("fit");
  }

  changeCoverImage(evnt) {
    let file, fileType, fileSize, coverPictureKlass = this;

    file = this.props.coverUrl;
    fileType = 'URL';
    const ref = this;
    let img;
    if (file) {
      //load image
      const cropImageModal = this;
      img = new Image();
      img.src = file;
      this.props.setCoverPictureImageSource(file);

      //on image load
      img.onload = function () {


        //check dimention
        if ((this.width < CoverPictureConfigs.COVER_IMAGE_MIN_WIDTH) || (this.height < CoverPictureConfigs.COVER_IMAGE_MIN_HEIGHT)) {
          alert("Cover Image Resolution must be atleast " + CoverPictureConfigs.COVER_IMAGE_MIN_WIDTH + "x" + CoverPictureConfigs.COVER_IMAGE_MIN_HEIGHT);
          coverPictureKlass.coverPicture.current.value = "";
          cropImageModal.setState({ visible: false })
          return;
        } else {


          const handleModal = () => {
            cropImageModal.setState({ visible: true });

            var picture = window.$(coverPictureKlass.cropPicture.current);

            picture.on("load", function (root) {


              //guillotine initialization
              picture.guillotine({
                width: CoverPictureConfigs.COVER_IMAGE_MIN_WIDTH,
                height: CoverPictureConfigs.COVER_IMAGE_MIN_HEIGHT,
                eventOnChange: "guillotinechange"
              });
              ref.setFit();
            });
            picture.on("guillotinechange", function (ev, data, action) {
              data.scale = parseFloat(data.scale.toFixed(4));
              coverPictureKlass.props.setCoverPictureCropData(JSON.stringify(data));
            })
            //Make sure the "load" event is triggered at least once( for cache image)
            //try to understand the logic
            if (picture.prop("complete")) {
              picture.trigger("load");
            }
          }
          setTimeout(handleModal, 1000);
        }
      }
      img.onerror = function () {
        alert("Something went wrong, Please try again!");
        coverPictureKlass.coverPicture.current.value = "";
      }
    }
  }

  handleClose() {
    this.setState({
      visible: false,
    });
    this.coverPicture.current.value = "";
    this.props.setPictureUrl(null);
  }

  handleSave(data) {
    let {
      setCoverPictureCropData,
      changeCoverPicture,
      coverPicture: { image },
      title: articleTitle,
      coverUrl
    } = this.props;

    let changeCoverFormData = new FormData();

    changeCoverFormData.set("crop_data", JSON.stringify(window.$("#crop_picture").guillotine("getData")));

    const proto = window.location.protocol;
    if (coverUrl && coverUrl.indexOf('http') === -1) {
      coverUrl = proto + coverUrl;
    }

    changeCoverFormData.set("source", !!coverUrl ? coverUrl : articleTitle);

    var formData = new FormData();
    formData.set("crop_data", JSON.stringify(window.$("#crop_picture").guillotine("getData")))

    formData.set("source", coverUrl)
    changeCoverPicture({ "source": coverUrl, "cropData": window.$("#crop_picture").guillotine("getData") });
    this.setState({
      visible: false,
    });
    setCoverPictureCropData(JSON.stringify(CoverPictureConfigs.COVER_SCALE));
    window.$("#crop_picture").guillotine("remove");
  }

  componentDidUpdate(prevProps) {
    if (this.props.coverUrl && this.props.coverUrl !== prevProps.coverUrl) {
      this.changeCoverImage(new CustomEvent('piscoimage', { url: this.props.coverUrl }));
    }
  }
  render() {
    const {
      coverPicture: {
        cropData,
        imageSource
      },
      setCoverPictureImageSource,
      articleDetails: {
        coverPicture,
        imgExtension,
        uploadingCoverPicture },
      coverUrl
    } = this.props;
    const { visible } = this.state;
    let image = DEFAULT_COVER_PICTURE;

    if (coverPicture && coverPicture.length > 0) {
      image = coverPicture + "_1200_630" + imgExtension;
    }
    const coverImageDiv = <img style={{ width: "100%", maxWidth: "690px", height: "auto", margin: "0 auto", display: "inline-block" }}
      src={image} alt="Default Cover Image" alt={imageSource} />;
    return (
      <>
        <div style={{ display: "flex", justifyContent: "center" }} >
          <div style={{ position: "relative" }}>
            {
              uploadingCoverPicture ?
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                  <div style={{ opacity: '0.3' }}>
                    {coverImageDiv}
                  </div>
                  <div style={{ position: "absolute" }}>
                    <AppLoader
                      message="Loading cover image ...."
                    />
                  </div>
                </div>
                : coverImageDiv
            }
            <Prompt when={false} message="Are you sure?" />
            <form id="changeCover" ref={this.changeCoverForm} style={styles.coverPictureForm}>
              <Prompt when={false} message="Are you sure?" />
              <div style={styles.inputImageWrapper}>
                <div style={{ cursor: "pointer", marginRight:'5px' }}>
                  <Button
                    color="primary"
                  >
                    <i className="material-icons">
                      add_a_photo
                    </i>ADD IMAGE
                  </Button>
                </div>
                <input ref={this.coverPicture} style={styles.coverImage} type="file" name="cover_image" onClick={this.chooseFile} />
              </div>
              <br />
              <Input value={cropData} pleaseholder="Crop Data" name="crop_data" style={{ display: "none" }} onChange={() => { }} />
              <input type="hidden" value="article" name="type" />
            </form>
          </div>

          <Dialog
            open={visible}
            onClose={this.handleClose}
            fullWidth={true}
            maxWidth="md"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Upload Cover Image</DialogTitle>
            <DialogContent>
              <img ref={this.cropPicture} id="crop_picture" src={coverUrl} />
              <div style={{ display: "flex", justifyContent: "center", paddingTop: "20px" }}>
                <Button type="primary" style={{ paddingLeft: "10px", paddingRight: "10px" }} onClick={this.setZoomOut}>ZoomOut</Button>
                <Button type="primary" style={{ paddingLeft: "10px", paddingRight: "10px" }} onClick={this.setFit}>Fit</Button>
                <Button type="primary" style={{ paddingLeft: "10px", paddingRight: "10px" }} onClick={this.setZoomIn}>ZoomIn</Button>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handleSave} color="primary">
                Crop
               </Button>
            </DialogActions>
          </Dialog>
        </div>

      </>
    )
  }
}

const mapStateToProps = state => {
  const {
    articleSettings: {
      coverPicture,
      articleId
    },
    articles: {
      articlesByIds = {}
    },
    newStory: {
      coverUrl
    }
  } = state.cms;
  const { title = undefined } = articlesByIds[articleId] || {};
  return {
    coverPicture,
    title,
    articleDetails: articlesByIds[articleId] || {},
    coverUrl
  }
}

const mapDispatchToProps = dispatch => ({
  setCoverPictureCropData: (cropData) => dispatch(setCoverPictureCropData(cropData)),
  changeCoverPicture: (changeCoverForm) => dispatch(changeCoverPicture(changeCoverForm)),
  setCoverPictureImageSource: (imageSource) => dispatch(setCoverPictureImageSource(imageSource)),
})
export default connect(mapStateToProps, mapDispatchToProps)(CoverPicture);
