import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import landingPageStyle from '../../../assets/jss/material-dashboard-pro-react/views/landingPageStyle';
import { Editor } from '@tinymce/tinymce-react';
import throttle from 'lodash.throttle';
import SweetAlert from 'sweetalert-react';
import axios from 'axios';

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLpId: 0,
      lp: [],
      editorShow: false,
      editorContent: '',
      showAlert: false,
      alertTitle: '',
      alertText: '',
    }
    this.throttledPostContent = throttle(this.postContent.bind(this), 5000);
  }
  componentDidMount() {
    if (!localStorage.getItem('accessToken')) {
      this.props.history.push("/login-home/login");
    } else {
      this.getTagsApi();
    }
  }
  getTagsApi() {
    axios.get(`https://master.pubninja.com/lp/${this.props.activeDomain}/n_entry`, {
      headers: {Authorization: localStorage.getItem('accessToken')}
    }).then((d) => {
      d.data.result.sort((a, b) => {
        return a.displayName - b.displayName;
      });
      this.setState({lp: d.data.result});
    }).catch((e) => {

    });
  }
  getContent(slugId) {
    axios.get(`https://master.pubninja.com/lp/${this.props.activeDomain}/content/${slugId}`, {
      headers: {Authorization: localStorage.getItem('accessToken')}
    }).then((d) => {
      this.setState({editorShow: true, editorContent: d.data.html || ''});
    }).catch((e) => {
      alert(e.message);
    });
  }
  onTagSelect(e) {
    if (e.target.value) {
      this.setState({selectedLpId: e.target.value});
      this.getContent(e.target.value);
    }
  }
  postContent(fromState, html) {
    let content = this.state.editorContent;
    if (!fromState) {
      content = html;
    }
    axios.post(`https://master.pubninja.com/lp/${this.props.activeDomain}/content/${this.state.selectedLpId}`,
        {html: content},
        {headers: {Authorization: localStorage.getItem('accessToken')}}
      ).then((d) => {
        this.setState({
          showAlert: true,
          alertTitle: "Response Received",
          alertText: 'Updated'
        });
      }).catch((e) => {
        alert(e.message);
        this.setState({
          showAlert: true,
          alertTitle: "Response Received",
          alertText: e.message
        });
      });
  }
  handleEditorChange = (content, editor) => {
    // this.throttledPostContent(0, content);
    this.setState({editorContent: content});
  }
   hideAlert = () => {
    this.setState({ showAlert: false });
  }
  render() {
    return (
      <div>
        <select onChange={this.onTagSelect.bind(this)}>
          <option value="">***Choose Tag***</option>
          {this.state.lp && !!this.state.lp.length &&
            this.state.lp.map((obj, key) => {
              return <option key={key} value={obj.id}>{obj.displayName}</option>
            })
          }
        </select>
        {this.state.editorShow &&
          <div style={{marginTop: '15px'}}>
            <Editor
              initialValue="<p>This is the initial content of the editor</p>"
              contenteditable="true"
              apiKey="nnqf35khd0owkobaobxk84b0n5xf0w5uzcv4kaepewkyfk5u"
              value={this.state.editorContent} // set the value from the state
              init={{
                height: 500,
                width: '80%',
                branding: false,
                menubar: false,
                placeholder: "Start writing........",
                plugins: [
                  'table paste link wordcount fullscreen'
                ],
                toolbar: 'bold italic underline | link | table | formatselect | H+',
                block_formats: 'Paragraph=p; Header 2=h2;',
                setup: function (editor) {
                  editor.addButton('H+', {
                    text: 'H+',
                    onClick: function () {
                      const content = editor.selection.getContent({format: 'text'});
                      const id = content.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
                      const className = 'quickLinks';
                      editor.insertContent(`<h2 id="${id}" class="${className}">${content}</h2>`);
                    }
                  });
                }
              }}
              onEditorChange={this.handleEditorChange.bind(this)}
            />
          </div>
        }
        <br/>
        <button onClick={this.postContent.bind(this, 1)}>Save</button>
        <SweetAlert
          show={this.state.showAlert}
          title={this.state.alertTitle}
          text={this.state.alertText}
          onConfirm={this.hideAlert}
        />
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    activeDomain: store.login.user.activeDomain
  };
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(landingPageStyle)(LandingPage)));
