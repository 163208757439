import React, { PureComponent } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import Face from "@material-ui/core/Face";

import { withRouter } from 'react-router-dom';
// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import { registerNewUser } from "../register-user-actions.js";
import { isValidEmailAddress } from "../../../utils/utils.js";
import AppLoader from "../../../app-components/app-loader.jsx";

class RegisterUserForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      error: {
        emailError: "",
        firstNameError: "",
        lastNameError: ""
      }
    }
  }

  registerUser = (e) => {
    e.preventDefault();
    const { firstName, lastName, email } = this.state;
    const { registerNewUser } = this.props;
    registerNewUser({ firstName, lastName, email:email.trim() })
      .then(data => {
        this.setState({
          firstName: "",
          lastName: "",
          email: "",
          error: {
            emailError: "",
            firstNameError: "",
            lastNameError: ""
          }
        }, () => {
          this.props.history.push(`/settings/user-management/account-management`);
        })
      })
      .catch(err => { });
  }
  setUserInfo = (field, value) => {
    this.setState({ [field]: value })
  }

  setEmail = ({ target: { value } }) => {
    this.setUserInfo("email", value);
    if (!isValidEmailAddress(value.trim())) {
      this.setState({
        error: {
          ...this.state.error,
          emailError: "Please Enter Valid Email!"
        }
      })
    } else {
      this.setState({
        error: {
          ...this.state.error,
          emailError: ""
        }
      })
    }
  }

  setFirstName = ({ target: { value } }) => {
    this.setUserInfo("firstName", value);
    if (value && value.length > 2) {
      this.setState({
        error: {
          ...this.state.error,
          firstNameError: ""
        }
      })
    } else {
      this.setState({
        error: {
          ...this.state.error,
          firstNameError: "Firstname should be atleast 2 characters long!"
        }
      })
    }
  }
  setLastName = ({ target: { value } }) => {
    this.setUserInfo("lastName", value);
    if (value && value.length > 2) {
      this.setState({
        error: {
          ...this.state.error,
          lastNameError: ""
        }
      })
    } else {
      this.setState({
        error: {
          ...this.state.error,
          lastNameError: "Lastname should be atleast 2 characters long!"
        }
      })
    }
  }

  render() {
    const { classes, registeringNewUser } = this.props;
    const { email, firstName, lastName } = this.state;
    return (
      <form onSubmit={this.registerUser}>
        <Card login className={classes[this.state.cardAnimaton]}>
          <CardBody>
            <CustomInput
              labelText="Email..."
              infoText="Enter the email address to add."
              id="email"
              value={email}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Email className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                onChange: this.setEmail,
              }}
              helpText={this.state.error.emailError}
            />
            <CustomInput
              labelText="First Name"
              id="firstName"
              infoText="Enter the first name."
              value={firstName}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon className={classes.inputAdornmentIcon}>
                      face
                        </Icon>
                  </InputAdornment>
                ),
                onChange: this.setFirstName
              }}
              helpText={this.state.error.firstNameError}
            />
            <CustomInput
              labelText="Last Name"
              id="lastName"
              infoText='Enter the last name.'
              value={lastName}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon className={classes.inputAdornmentIcon}>
                      face
                          </Icon>
                  </InputAdornment>
                ),
                onChange: this.setLastName,
              }}
              helpText={this.state.error.lastNameError}
            />
          </CardBody>
          <CardFooter className={classes.justifyContentCenter}>
            <Button type="submit" color="secondary" onClick={this.registerUser}>
              Register User &nbsp; {registeringNewUser && <AppLoader isSignOutLoader={true} marginZero={true} color="inherit" size={20} />}
            </Button>
          </CardFooter>
        </Card>
      </form>
    )
  }
}

const mapStateToProps = (store) => {
  const { registeringNewUser } = store.userManagement.registerUser;
  return {
    registeringNewUser: registeringNewUser
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    registerNewUser: (userDetails) => dispatch(registerNewUser(userDetails))
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterUserForm));
