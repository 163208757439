import React, { Fragment } from "react";
import { DEFULT_PROFILE_PICTURE_URL } from "../../../app-constants/image-url.js";
import GridItem from "components/Grid/GridItem.jsx";
import { uppercaseFirstLetter } from "../../../utils/utils.js";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  userImage: {
    paddingRight: "0px !important"
  }
}

function UserDetailsForMobile({
  classes,
  userDetails: {
    firstName,
    lastName,
    email,
    joinDate,
    profilePicture,
  }
}) {
  return (
    <Fragment>
      <GridItem className={classes.userImage} xs={2} displayFlexType={true} alignItemCenter={true} style={{ paddingRight: "0px!important" }}>
        <img src={profilePicture || DEFULT_PROFILE_PICTURE_URL} style={{ width: "40px", minWidth: "40px", height: "40px", marginTop: "8px", marginBottom: "8px", borderRadius: "50%", boxShadow: "0px 0px 0px 4px rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12)" }} alt="" />
      </GridItem>
      <GridItem xs={9} style={{ padding: "0px" }}>
        <h2 style={{ fontSize: "14px", fontWeight: "bold", paddingLeft: "0px", marginBottom: "10px" }}>{`${uppercaseFirstLetter(firstName)} ${uppercaseFirstLetter(lastName)}`}</h2>
        <h5 style={{ fontSize: "12px", color: "#8d8d8d", marginBottom: "8px" }}><strong>Joined Date: </strong>{joinDate && joinDate.split("T")[0]}</h5>
        <h5 style={{ fontSize: "12px", color: "#8d8d8d", marginTop: "8px" }}>{email}</h5>
      </GridItem>

    </Fragment>
  )
}
export default withStyles(styles)(UserDetailsForMobile);