import React, { PureComponent } from "react";
import { connect } from "react-redux";
//import Button from '@material-ui/core/Button';
import Button from "components/CustomButtons/Button.jsx";
import { allowFillMetaData } from "../new-story-actions.js";

class ApprovalSubmit extends PureComponent {
  constructor(props) {
    super(props);
  }
  // allowToAddMetaData = () => {
  //   const { title, summary, allowSubmission } = this.props;
  //   if (!allowSubmission) {
  //     return false;
  //   } else if (title === "Untitled" || title.length === 0) {
  //     return false;
  //   } else if (summary == null || summary == undefined || summary.trim().length < 100) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }
  render() {
    const { allowSubmission, allowFillMetaData, goToArticles } = this.props;

    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Button
          color="secondary"
          onClick={goToArticles}
          style={{ marginTop: "15px" }}
        >
          Save and Go Back
        </Button>
        <Button
          disabled={!allowSubmission}
          onClick={allowFillMetaData}
          color="primary"
          style={{ marginTop: "15px" }}
        >
          Add Meta Data
        </Button>
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  const {
    articleSettings: { articleId },
    articles: { articlesByIds = {} } } = state.cms;
  const { title, summary, allowSubmission } = articlesByIds[articleId] || {};
  return {
    allowSubmission: allowSubmission,
    title: title,
    summary: summary,
  }
}
const mapDispatchToProps = dispatch => ({
  allowFillMetaData: () => dispatch(allowFillMetaData()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalSubmit);
