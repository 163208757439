
import React, { Component } from 'react';
import { connect } from "react-redux";
import getNewsletter from "../news-letter-action";
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import articleHomeStyle from '../../../assets/jss/material-dashboard-pro-react/views/articlehomeStyle';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { postNewsletter } from "../news-letter-action";
import AppLoader from "../../../app-components/app-loader.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";
import Subscribe from "./subscribe";
import Campaigns from "./campaigns";


function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 2 }}>
      {props.children}
    </Typography>
  );
}

class SelectType extends Component {
  state = {
    value: 0
  }

  componentDidMount() {
    this.props.getNewsletter()
  }
  handleChange = (event, value) => {
    this.setState({ value })
  }

  render() {
    const { website_visible, postNewsletter, isNewsLetterLoading, isNewsLetterActionLoading, classes } = this.props;
    const { value } = this.state;
    if (isNewsLetterLoading) {
      return <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <AppLoader message="Loading..." />
      </div>
    }
    if (!!!website_visible) {
      return <div style={{ display: 'flex',justifyContent:'center' }}>
        {/* <FormControlLabel
          control={
            <Switch
              checked={!!website_visible}
              onChange={postNewsletter}
              color="primary"
            />
          }
          label="News Letter"
        /> */}

        <Button disabled={isNewsLetterActionLoading} onClick={postNewsletter} color='primary'>Start Newsletter</Button>
        {
          isNewsLetterActionLoading && <AppLoader />
        }
      </div>
    }
    return (
      <Paper xs={12} sm={12} md={12} className={classes.root} >

        <Tabs
          fullWidth
          value={value}
          onChange={this.handleChange}
          scrollable
          scrollButtons="on"
          textColor="inherit"
          classes={{
            root: classes.tabsRoot
          }}
        >
          <Tab label="Subscribers" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
          <Tab label="Campaign " classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
        </Tabs>
        {value == 0 && <TabContainer><Subscribe /></TabContainer>}
        {value == 1 && <TabContainer><Campaigns /></TabContainer>}
      </Paper>
    )
  }
}

const mapStateToProps = (state) => {
  const { website_visible, isNewsLetterLoading, isNewsLetterActionLoading } = state.manageSocialReducer;
  return {
    website_visible,
    isNewsLetterLoading,
    isNewsLetterActionLoading
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getNewsletter: () => dispatch(getNewsletter()),
    postNewsletter: () => dispatch(postNewsletter())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(articleHomeStyle)(SelectType));
