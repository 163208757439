import React, { PureComponent } from 'react';

class Search extends PureComponent {
  state = {
    searchText: ""
  }
  getValueFromInput = (e) => {
    this.setState({...this.state, searchText: e.currentTarget.value});
  }

  startSearch = (event) => {
    event.preventDefault();
    this.props.startSearch(this.state.searchText.trim());
    //this.setState({...this.state, searchText: ''});
  }

  render() {
    return (
      <div style={{ position: 'relative'}}>
        <form onSubmit={this.startSearch}>
          <input
            id="liveArticleSearch"
            onChange={(e) => { this.getValueFromInput(e) }}
            placeholder = "Search here..."
            value = {this.state.searchText}
            style={{
              width: "100%",
              height: "40px",
              paddingTop: '5px',
              paddingBottom:'5px',
              //borderRadius: "5px",
              border: "none",
              backgroundColor: "#f5f5f5",
              paddingLeft: "10px",
              fontSize: "16px",
              border: '1px solid #CED3DC',
              //boxShadow: " 0 1px 8px 0 rgba(0, 0, 0, 0.06)"
            }} />
          </form>
        <div style={{ position: "absolute", right: '15px', top: '5px', cursor: "pointer" }}>
          <i className="material-icons" style={{color: '#2B2D42'}} onClick={this.startSearch}>
            search
            </i>
        </div>
      </div>
    )
  }
}
export default Search;
