
const storyPostAction = {
  LOAD: {
    SUCCESS: 'Story.Load.Success',
  },
  DELETE: {
    REQUEST: 'Story.Delete.Request',
    SUCCESS: 'Story.Delete.Success',
  }
}

export default storyPostAction;
