import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import { fetchVariableTimeSpecificRevenues, setRevenuesDateRangesInterval } from "../revenue-actions.js";
import { VariableTimeSpecificRevenueTypes } from "../../../app-constants/index.js";
import { RevenueDataTypes } from "../../../app-constants/index.js";
import RevenueStatsTable from "../components/revenue-stats-table.jsx";

class RevenueStats extends PureComponent {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const { startDate, endDate } = this.props;
    this.fetchRevenues({ startDate, endDate });
  }

  componentWillReceiveProps({ activeDomain }) {
    const { activeDomain: prevActiveDomain } = this.props;
    if (activeDomain !== prevActiveDomain) {
      this.fetchRevenues();
    }
  }
  fetchRevenues = () => {
    const { fetchRevenueStats } = this.props;
    fetchRevenueStats({ output: [RevenueDataTypes.ECPM, RevenueDataTypes.REVENUE, RevenueDataTypes.SESSIONS] });
  }
  setDateRangeInterval = ({ startDate, endDate }) => {
    const { setDateInterval } = this.props;
    setDateInterval({ startDate, endDate, output: [RevenueDataTypes.ECPM, RevenueDataTypes.REVENUE, RevenueDataTypes.SESSIONS] });
  }

  render() {
    const { renderTable } = this.props;
    return (
      <div>
        {renderTable({
          ...this.props,
          renderData: RevenueStatsTable,
          showTitleOnHeader: true,
          isShowingRevenueStats: true,
          setDateRangeInterval: this.setDateRangeInterval,
          header: "Revenue Stats"

        })}
      </div>
    )
  }
}
const mapStatToProps = state => {
  return {
    ...state.analysis.revenue.variableTimeSpecificRevenues.revenueStats,
    activeDomain: state.login.user.activeDomain
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchRevenueStats: (options) => dispatch(fetchVariableTimeSpecificRevenues(VariableTimeSpecificRevenueTypes.REVENUE_STATS, options)),
    setDateInterval: (options) => dispatch(setRevenuesDateRangesInterval(VariableTimeSpecificRevenueTypes.REVENUE_STATS, options))
  }
}

export default connect(mapStatToProps, mapDispatchToProps)(RevenueStats)