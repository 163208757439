import React, { PureComponent, Fragment } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from "../../../components/CustomButtons/Button.jsx";
import { Link } from 'react-router-dom';
import TextField from "@material-ui/core/TextField";
import AppLoader from "../../../app-components/app-loader";
import { DateRange } from 'react-date-range';
import moment from 'moment';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

class schedularModal extends PureComponent {
  state = {
    startDate: this.props.currentArticleDetail && this.props.currentArticleDetail.scheduleTime && moment(this.props.currentArticleDetail.scheduleTime).toDate() || null
  };

  handleChange = date => {
    this.setState({
      startDate: date
    });
  };
  setSchedular = () => {
    const date = this.state.startDate;
    this.props.postSchedular(moment(date).utc().unix()).then((data) => {
      if (data) {
        this.props.getArticles();
      }
      this.props.setSchedularModal(false);
    });
  }
  render() {
    const { showArticleSchedular, setSchedularModal, currentArticleDetail } = this.props;
    return (
      <Dialog
        id="articleSchedularContainer"
        open={showArticleSchedular}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle >
          Schedule your article
        </DialogTitle>
        <DialogContent style={{
          minHeight: '400px'
        }}>
          <InputLabel htmlFor="demo-controlled-open-select">Calendar</InputLabel>

          {/* <Select
            value={`${this.state.startDate}`}
            renderValue={() => `${this.state.startDate}`}
          >
          </Select> */}
          <div style={{ margin: '20px', display: 'flex', flexWrap: 'wrap' }}>
            <DatePicker
              selected={this.state.startDate}
              onChange={this.handleChange}
              showTimeSelect
              timeIntervals={15}
            />
            <i
              style={{
                verticleAlign: 'middle'
              }}
              className="material-icons">
              calendar_today
             </i>
            {
              this.state.startDate &&
              <p style={{ marginLeft: '15px' }}> <span style={{
                fontWeight: 'bold'
              }}>Article Scheduled : &nbsp;</span>{moment(this.state.startDate).format('LLL')}</p>
            }
          </div>
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button onClick={() => {
            setSchedularModal(false)
          }} color="primary">
            Cancel
          </Button>
          <Button color="secondary" onClick={this.setSchedular}>
            Schedule
          </Button>
        </DialogActions>

      </Dialog>
    )
  }
}

export default schedularModal;