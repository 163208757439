import { UserPermisssionAction, MessageTypes } from "../../app-constants/index.js";
import { setAppMessage } from "../../app-actions.js";
import { MeawwApi } from "../../utils/api.js";
import { func } from "prop-types";

function fetchUserPermissionRequest() {
  return {
    type: UserPermisssionAction.FETCH.PREMISSION.REQUEST
  }
}

function fetchUserPermissionSucceess(permissions, userId) {
  return {
    type: UserPermisssionAction.FETCH.PREMISSION.SUCCESS,
    permissions: permissions,
    userId: userId
  }
}

function fetchUserPermissionFailure() {
  return {
    type: UserPermisssionAction.FETCH.PREMISSION.FAILURE
  }
}

export function fetchUserPermissions(userId, userDetails = {}, isLoadingForAccountMgmt = true) {
  return (dispatch, getState) => {
    const { userManagement: { accountManagement: { userList } }, login: { user: { activeDomain } } } = getState();
    const { email } = userList[userId] || userDetails;

    isLoadingForAccountMgmt && dispatch(fetchUserPermissionRequest());

    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/acl/domain/${userDetails.activeDomain || activeDomain}/user/${email}/permissions`,
          (error, data) => {
            if (error) {
              if (isLoadingForAccountMgmt) {
                dispatch(fetchUserPermissionFailure());
                dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              }
              return Promise.reject(error);
            }
            // dispatch(fetchUserPermissionSucceess(data, userId));
            isLoadingForAccountMgmt && dispatch(fetchUserPermissionSucceess(data, userId));
            return Promise.resolve(data);
          });
      });
  }
}
function updateDomainUserPermissionRequest(userId, userPermission, status) {
  return {
    type: UserPermisssionAction.UPDATE.PREMISSION.REQUEST,
    userId: userId,
    userPermission: userPermission,
    status: status
  }
}
function updateDomainUserPermissionFailure(userId, userPermission, status) {
  return {
    type: UserPermisssionAction.UPDATE.PREMISSION.FAILURE,
    userId: userId,
    userPermission: userPermission,
    status: status
  }
}
function updateDomainUserPermissionSuccess(userId, userPermission, status) {
  return {
    type: UserPermisssionAction.UPDATE.PREMISSION.SUCCESS,
    userPermission: userPermission,
    status: status,
    userId: userId
  }
}

export function updateUserDomainPermission(userId, userPermission, status) {
  return (dispatch, getState) => {
    const { userManagement: { accountManagement: { userList } }, login: { user: { activeDomain } } } = getState();
    const { email } = userList[userId] || {};
    dispatch(updateDomainUserPermissionRequest(userId, userPermission, status));
    return MeawwApi()
      .auth()
      .then(api => {
        return api[status ? "post" : "delete"](`/acl/domain/${activeDomain}/user/${email}/permission/${userPermission}`,
          (error, data) => {
            if (error) {
              dispatch(updateDomainUserPermissionFailure(userId, userPermission, status));
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            dispatch(updateDomainUserPermissionSuccess(userId, userPermission, status));
            return
          });
      });
  }
}


export function fetchMyPermission(activeDomain) {
  return (dispatch, getState) => {
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/acl/domain/${activeDomain}/user/permissions`,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error)
            }
            return Promise.resolve(data);
          })
      })
  }
}

export function showSuccessMsg(msg) {
  return (dispatch) => {
    dispatch(setAppMessage(msg, { type: MessageTypes.SUCCESS }));
  }
}
