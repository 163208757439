import { MeawwApi } from "../../utils/api.js";
import socket from "../../utils/socket";
import {
  ArticleAction,
  ArticlesAction,
  ArticlesState,
  ArticlesAPIState,
  MessageTypes,
  PreviewModalAction,
  ActionOnArticle
} from "../../app-constants/index.js";
import { setAppMessage } from "../../app-actions.js";
import { isValidDisclaimerId } from "../new-story/new-story-actions.js";
import { activeCategorie, activeSubCategorie } from "../../tool/landing-page/landing-page-action.js";
import { toggleConfirmationBox } from "../articles/articles-actions"

function setArticleIdMenuList(articleId) {
  return {
    type: ArticlesAction.SET.MENU_LIST_ARTICLE_ID,
    menuListArticleId: articleId
  };
}
function setArticleIdPreivewList(articleId) {
  return {
    previewArticleId: articleId,
    type: ArticlesAction.SET.PREVIEW_ARICLE_ID
  };
}
function removeArticleIdMenuList() {
  return {
    menuListArticleId: undefined,
    type: ArticlesAction.REMOVE.MENU_LIST_ARTICLE_ID
  };
}
function removePreviewArticleId() {
  return {
    type: ArticlesAction.REMOVE.PREVIEW_ARICLE_ID,
    previewArticleId: undefined
  };
}
export const fetchPreviewArticleDetail = articleId => {
  return (dispatch, getState) => {
    const {
      login: {
        user: { activeDomain }
      }
    } = getState();
    dispatch({ articleId, type: ArticlesAction.UPDATE.REQUEST });
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(
          `/article/${activeDomain}/preview/${articleId}`,
          (error, data) => {
            if (error) {
              dispatch({ articleId, type: ArticlesAction.UPDATE.FAILURE });
              return Promise.reject(error);
            }
            const { result } = data;
            dispatch({
              result,
              articleId,
              type: ArticlesAction.UPDATE.SUCCESS
            });
            return;
          }
        );
      });
  };
};

export function setArticleIdForMenuList(articleId) {
  return setArticleIdMenuList(articleId);
}


// function setCurrentState(state) {
//   return {
//     type: ArticleAction.SET_STATE.CURRENT_STATE,
//     state: state
//   }

// }
// function setNextState(state) {
//   return {
//     type: ArticleAction.SET_STATE.NEXT_STATE,
//     state: state
//   }
// }

// function articlepreviewAction(previewActions) {
//   return {
//     type: ArticleAction.MOVE_STATE.ACTION,
//     previewActions: previewActions
//   }
// }

export function toggleShareArticleModal(visible, shareArticleData = {}) {
  return {
    type: ArticleAction.SET.SHARE_ARTICLE_MODAL,
    visible,
    shareArticleData,
  }
}
export function shareLiveArticle(shareArticleData) {
  return toggleShareArticleModal(true, shareArticleData);
}

export function toggleScheduleArticleModal(visible, scheduleArticleData = {}) {
  return {
    type: ArticleAction.SET.SCHEDULE_ARTICLE_MODAL,
    visible,
    scheduleArticleData,
  }
}
export function scheduleLiveArticle(scheduleArticleData) {
  return toggleScheduleArticleModal(true, scheduleArticleData);
}

export function toggleExploitArticleModal(visible, exploitArticleData = {}) {
  return {
    type: ArticleAction.SET.EXPLOIT_ARTICLE_MODAL,
    visible,
    exploitArticleData,
  }
}
export function exploitLiveArticle(exploitArticleData) {
  return toggleExploitArticleModal(true, exploitArticleData);
}

export function toggleScheduleTwitterArticleModal(visible, scheduleTwitterArticleData = {}) {
  return {
    type: ArticleAction.SET.SCHEDULE_TWITTER_ARTICLE_MODAL,
    visible,
    scheduleTwitterArticleData,
  }
}
export function scheduleTwitterLiveArticle(scheduleTwitterArticleData) {
  return toggleScheduleTwitterArticleModal(true, scheduleTwitterArticleData);
}

function setPreviewModalAction(previewModalAction) {
  return {
    type: ArticleAction.SET.PREVIEW_MODAL_ACTION,
    previewModalAction: previewModalAction
  }
}

export function sendBrowserNotification(articleId) {
  return (dispatch, getState) => {
    const {
      cms: {
        articleSettings: { isSendingNotifications },
      },
      login: { user: { activeDomain } }
    } = getState();
    if (isSendingNotifications === true)
      return;
    dispatch(sendNotificationRequest());
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/fcm/${activeDomain}/notify`, {
          article_id: `${articleId}`
        },
          (error, data) => {
            dispatch(removeArticleIdMenuList());
            if (error) {
              dispatch(sendNotificationFailure());
              dispatch(setAppMessage('Failed to send notifications. Please retry.', { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            dispatch(sendNotificationSuccess());
            dispatch(setAppMessage('Sent notifications successfully', { type: MessageTypes.SUCCESS }));
            return;
          }
        );
      });
  }
}

function sendNotificationRequest() {
  return {
    type: ArticleAction.NOTIFICATION.REQUEST
  }
}

function sendNotificationFailure() {
  return {
    type: ArticleAction.NOTIFICATION.FAILURE
  }
}

function sendNotificationSuccess() {
  return {
    type: ArticleAction.NOTIFICATION.SUCCESS
  }
}

export function setArticleIdForPreviewArticle(articleId, {
  requiredAction = PreviewModalAction.SUBMIT,
  // currentState = undefined,
  // nextState = undefined
} = {}) {
  return dispatch => {
    dispatch(setArticleIdPreivewList(articleId));
    if (requiredAction) {
      dispatch(setPreviewModalAction(requiredAction));
    }

    // if (currentState) {
    //   dispatch(setCurrentState(currentState));
    // }
    // if (nextState) {
    //   dispatch(setNextState(nextState));
    // }
    dispatch(fetchPreviewArticleDetail(articleId));
  };
}

export function removeArticleIdForMenuList() {
  return removeArticleIdMenuList();
}

export function removeArticleIdForPreviewArticle() {
  return (dispatch) => {
    dispatch(removePreviewArticleId());
    // dispatch(articlepreviewAction({}));
    dispatch(setPreviewModalAction(undefined));
  }
}
///////////////////////////////////////////////////////

export function setArticleId(articleId) {
  if (articleId) {
    if (typeof articleId === "string") {
      articleId = parseInt(articleId)
    }
  }
  return {
    type: ArticleAction.SET.ARTICLE_ID,
    articleId: articleId
  };
}



function loadArticleDetailsRequest(articleId) {
  return {
    type: ArticleAction.LOAD.ARTICLE_DETAILS.REQUEST,
    articleId: articleId
  }
}

function loadArticleDetailsFailure(articleId) {
  return {
    type: ArticleAction.LOAD.ARTICLE_DETAILS.FAILURE,
    articleId: articleId
  }
}

function loadArticleDetailsSuccess(articleId, articleDetails) {
  return {
    type: ArticleAction.LOAD.ARTICLE_DETAILS.SUCCESS,
    articleId: articleId,
    articleDetails: articleDetails
  }
}

export function loadArticleDetails() {
  return (dispatch, getState) => {
    const {
      cms: { articleSettings: { articleId } },
      login: { user: { activeDomain, id, email, firstName, lastName, profilePicture } }
    } = getState();

    dispatch(loadArticleDetailsRequest(articleId));
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/article/${activeDomain}/meta/${articleId}`,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message.toString(), { type: MessageTypes.ERROR }));
              dispatch(loadArticleDetailsFailure());
              return Promise.reject(error);
            }
            const inReviewBy = Object.assign({}, { id, email, 'firstname': firstName, 'lastname': lastName, 'profilePictureUrl': profilePicture });
            data.result.inReviewBy = inReviewBy;
            dispatch(loadArticleDetailsSuccess(articleId, data.result));
            dispatch(loadArticleCategories());
            return Promise.resolve(data.result);
          });
      });
  }
}




function loadArticleContentRequest() {
  return {
    type: ArticleAction.LOAD.ARTICLE_CONTENTS.REQUEST
  }
}

function loadArticleContentFailure() {
  return {
    type: ArticleAction.LOAD.ARTICLE_CONTENTS.FAILURE
  }
}
function loadArticleContentSuccess(articleId, content) {
  return {
    type: ArticleAction.LOAD.ARTICLE_CONTENTS.SUCCESS,
    articleId: articleId,
    content: content
  }
}

export function loadArticleContent() {
  return (dispatch, getState) => {
    const {
      cms: { articleSettings: { articleId } },
      login: { user: { activeDomain } }
    } = getState();
    dispatch(loadArticleContentRequest())
    return MeawwApi()
      .auth()
      .then(api => {
        api.get(`/article/${activeDomain}/draft/${articleId}`,
          (error, data) => {
            if (error) {
              dispatch(loadArticleContentFailure());
              dispatch(setAppMessage(error.message.toString(), { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            dispatch(loadArticleContentSuccess(articleId, data.result));
            return Promise.resolve(data.result);
          })
      })
  }
}

function loadArticleCategoriesRequest() {
  return {
    type: ArticleAction.LOAD.ARTICLE_CATEGORIES.REQUEST
  }
}
function loadArticleCategoriesfailure() {
  return {
    type: ArticleAction.LOAD.ARTICLE_CATEGORIES.FAILURE
  }
}

function loadArticleCategoriesSuccess(articleId, categories) {
  return {
    type: ArticleAction.LOAD.ARTICLE_CATEGORIES.SUCCESS,
    articleId: articleId,
    categories: categories
  }
}

export default function loadArticleCategories() {
  return (dispatch, getState) => {
    const {
      cms: { articleSettings: { articleId } },
      login: { user: { activeDomain } }
    } = getState();

    dispatch(loadArticleCategoriesRequest());
    return MeawwApi()
      .auth()
      .then(api => api.get(`/category/${activeDomain}`, (error, data) => {
        if (error) {
          dispatch(loadArticleCategoriesfailure());
          dispatch(setAppMessage(error.message.toString(), { type: MessageTypes.ERROR }));
          return Promise.reject(error);
        }
        dispatch(loadArticleCategoriesSuccess(articleId, data.result));
        dispatch(activeCategorie(data.result[0]["id"], data.result[0]["category"]));
        const { yCategory } = data.result[0];
        try {
          const { id, category } = yCategory[0];
          dispatch(activeSubCategorie(id, category, yCategory))
        }
        catch (e) {

        }
        return data.result;
      })
      )
  }
}





//state changing

export function saveAricle() {
  return (dispatch) => {
    return Promise.all([
      dispatch(setArticleIdMenuList(undefined)),
      dispatch(removeArticleIdForPreviewArticle())
    ])
  }
}
export function setLastLocation(location = 'none') {
  return {
    type: ArticleAction.LAST_LOCATION.SET,
    location,
  }
}

export function setCurrentLiveArticleHeight(height) {

  return {
    type: ArticleAction.CURRENT_HEIGHT.SET,
    height,
  }
}

function submitArticleRequest() {
  return {
    type: ArticleAction.SUBMIT.REQUEST
  }
}
function submitArticleFailure() {
  return {
    type: ArticleAction.SUBMIT.FAILURE
  }
}
function submitArticleSuccess(articleId, articleState) {
  return {
    type: ArticleAction.SUBMIT.SUCCESS,
    articleState: articleState,
    articleId: articleId,
  }
}
export function exploitArticle(time) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    const { articleSettings: { menuListArticleId, articleId }, articles: { articlesByIds } } = getState().cms;
    const id = menuListArticleId ? menuListArticleId : articleId;
    const articleDetails = articlesByIds[id];
    // const { isValid, message } = isValidDisclaimerId(articleDetails);
    if (false) {
      // dispatch(setAppMessage(message, { type: MessageTypes.ERROR }));
      return Promise;
    } else {
      // dispatch(submitArticleRequest());
      return MeawwApi()
        .auth()
        .then(api => {
          return api.post(`/refurbish_links/${activeDomain}/schedule/${id}`,
          {
            "time": time,
          },
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
        });
    }
    // }
  }
}
// End article form submission

export function submitArticle() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    const { articleSettings: { menuListArticleId, articleId }, articles: { articlesByIds } } = getState().cms;
    const id = menuListArticleId ? menuListArticleId : articleId;
    const articleDetails = articlesByIds[id];
    // const { isValid, message } = isValidDisclaimerId(articleDetails);
    if (false) {
      // dispatch(setAppMessage(message, { type: MessageTypes.ERROR }));
      return Promise;
    } else {
      dispatch(submitArticleRequest());
      return MeawwApi()
        .auth()
        .then(api => {
          return api.post(`/article/${activeDomain}/submit/${id}`,
            (error, data) => {
              if (error) {
                dispatch(submitArticleFailure());
                dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
                return Promise.reject();
              }

              // dispatch(approveArticleSuccess(id, ArticlesState.SUBMITTED_ARTICLES)),
              //   dispatch(approveArticleSuccess(id, ArticlesState.APPROVED_ARTICLES)),
              //   dispatch(setAppMessage(data.result, { type: MessageTypes.SUCCESS })),
              //   dispatch(setArticleIdMenuList(undefined)),
              //   dispatch(removeArticleIdForPreviewArticle())

              return Promise.all([
                dispatch(submitArticleSuccess(id, ArticlesState.DRAFT_ARTICLES)),
                dispatch(submitArticleSuccess(id, ArticlesState.SUBMITTED_ARTICLES)),
                dispatch(setAppMessage(data.result, { type: MessageTypes.SUCCESS })),
                dispatch(setArticleIdMenuList(undefined)),
                dispatch(removeArticleIdForPreviewArticle())
              ])
            });
        });
    }
    // }
  }
}

//delete action
function deleteArticleRequest() {
  return {
    type: ArticleAction.DELETE.REQUEST,
  }
}
function deleteArticleFailure() {
  return {
    type: ArticleAction.DELETE.FAILURE,
  }
}
function deleteArticleSuccess(articleId, articleState) {
  return {
    type: ArticleAction.DELETE.SUCCESS,
    articleId: articleId,
    articleState: articleState
  }
}

function getArticleStatus(status) {
  switch (status) {
    case 0: return ArticlesState.DRAFT_ARTICLES;
    case 1: return ArticlesState.SUBMITTED_ARTICLES;
    case 2: return ArticlesState.APPROVED_ARTICLES;
    case 3: return ArticlesState.LIVE_ARTICLES;
    default: return null;
  }
}

function msnGalleryArticleRequest() {
  return {
    type: ArticleAction.MSN_MODIFY.REQUEST,
  }
}
function msnGalleryArticleFailure() {
  return {
    type: ArticleAction.MSN_MODIFY.FAILURE,
  }
}
function msnGalleryArticleSuccess(articleId, articleState) {
  return {
    type: ArticleAction.MSN_MODIFY.SUCCESS,
    articleId: articleId,
    articleState: articleState
  }
}
export function toggleArticleMsnGallery(enable) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    const { articleSettings: { menuListArticleId, articleId }, articles: { articlesByIds } } = getState().cms;
    const id = menuListArticleId ? menuListArticleId : articleId;
    dispatch(msnGalleryArticleRequest());
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/article/${activeDomain}/msngallery/${id}`,
          {
            "enable": enable,
          },
          (error, data) => {
            if (error) {
              dispatch(msnGalleryArticleFailure());
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject();
            }
            dispatch(setArticleIdMenuList(undefined));
            dispatch(setAppMessage(data.message, { type: MessageTypes.SUCCESS }))
            return Promise.resolve();
          });
      });
  }
}
export function deleteArticle() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    const { articleSettings: { menuListArticleId, articleId }, articles: { articlesByIds } } = getState().cms;
    const id = menuListArticleId ? menuListArticleId : articleId;
    const articleDetails = articlesByIds[id] || {};
    const { status = 0 } = articleDetails;

    dispatch(deleteArticleRequest());
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/article/${activeDomain}/delete/${id}`,
          (error, data) => {
            if (error) {
              dispatch(deleteArticleFailure());
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject();
            }
            dispatch(deleteArticleSuccess(id, getArticleStatus(status)));
            dispatch(setArticleIdMenuList(undefined));
            dispatch(setAppMessage(data.result, { type: MessageTypes.SUCCESS }))
            return Promise.resolve();
          });
      });
  }
}
//end delete option



//approve article

function approveArticleRequest() {
  return {
    // type: NewStoryAction.SAVE.REQUEST
    type: ArticleAction.APPROVE.REQUEST
  }
}
function approveArticleFailure() {
  return {
    type: ArticleAction.APPROVE.FAILURE
  }
}
function approveArticleSuccess(articleId, articleState) {
  return {
    type: ArticleAction.APPROVE.SUCCESS,
    articleId: articleId,
    articleState: articleState
  }
}
export function approveArticle() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    const { articleSettings: { menuListArticleId, articleId }, articles: { articlesByIds } } = getState().cms;
    const id = menuListArticleId ? menuListArticleId : articleId;
    const articleDetails = articlesByIds[id];
    // const { isValid, message } = isValidDisclaimerId(articleDetails);
    if (false) {
      // dispatch(setAppMessage(message, { type: MessageTypes.ERROR }));
      return Promise;
    } else {
      dispatch(approveArticleRequest());
      return MeawwApi()
        .auth()
        .then(api => {
          return api.post(`/article/${activeDomain}/approve/${id}`,
            (error, data) => {
              if (error) {
                dispatch(approveArticleFailure());
                dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
                return Promise.reject();
              }
              // dispatch(approveArticleSuccess(id));
              // dispatch(setDisclaimerModal(false));
              dispatch(setAppMessage(data.result, {
                type: MessageTypes.SUCCESS
              }));

              return Promise.all([
                dispatch(approveArticleSuccess(id, ArticlesState.SUBMITTED_ARTICLES)),
                dispatch(approveArticleSuccess(id, ArticlesState.APPROVED_ARTICLES)),
                dispatch(setAppMessage(data.result, { type: MessageTypes.SUCCESS })),
                dispatch(setArticleIdMenuList(undefined)),
                dispatch(removeArticleIdForPreviewArticle())
              ]);
            });
        });

    }
    // }
  }
}
// End article approve

function setArticleLockDetails(inReviewBy, articleId) {
  /**
   * action generator for setting article lock details
   * @return {Object} action
   */
  return {
    type: ArticleAction.SET.ARTICLE_LOCK_DATA,
    inReviewBy,
    articleId,
  }
}
// Edit Article with Lock and redirect

function lockArticleRequest() {
  return {
    type: ArticleAction.LOCK.REQUEST,
  }
};
function lockArticleSuccess() {
  return {
    type: ArticleAction.LOCK.SUCCESS,
  }
};
function lockArticleFailure() {
  return {
    type: ArticleAction.LOCK.FAILURE
  }
};
export function LockAndEditArticle(forced) {
  /**
  * will get the lock on the article and start editing the article
  * @param {boolean} forced // set to true for force edit
  */
  return (dispatch, getState) => {
    const { activeDomain, id, email, firstName, lastName, profilePicture } = getState().login.user;
    const { articleSettings: { menuListArticleId, articleId }, articles: { articlesByIds } } = getState().cms;
    const currentArticleId = menuListArticleId ? menuListArticleId : articleId;
    const articleDetails = articlesByIds[currentArticleId];
    const inReviewBy = Object.assign({}, { id, email, 'firstname': firstName, 'lastname': lastName, 'profilePictureUrl': profilePicture });
    dispatch(lockArticleRequest());
    if ((articleDetails && articleDetails.inReviewBy && articleDetails.inReviewBy.email) === email) {
      return Promise.resolve();
    }
    return MeawwApi()
      .auth()
      .then((api) => {
        return api.post(`/article/${activeDomain}/lock/${currentArticleId}`, { forced: Number(forced || 0) },
          (error, data) => {
            if (error) {
              dispatch(lockArticleFailure());
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject();
            }
            return Promise.all([dispatch(setArticleLockDetails(inReviewBy, currentArticleId))
            ]);
          });
      });
  }
};
//End Edit Article Lock and redirect

// Clear cache for live article
function clearArticleCacheRequest() {
  return {
    type: ArticleAction.CLEAR_CACHE.REQUEST,
  }
};
function clearArticleCacheSuccess() {
  return {
    type: ArticleAction.CLEAR_CACHE.SUCCESS,
  }
};
function clearArticleCacheFailure() {
  return {
    type: ArticleAction.CLEAR_CACHE.FAILURE,
  }
};
export function clearArticleCache() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    const { articleSettings: { menuListArticleId, articleId }, articles: { articlesByIds } } = getState().cms;
    const id = menuListArticleId ? menuListArticleId : articleId;
    dispatch(clearArticleCacheRequest());
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/article/${activeDomain}/clearCache/${id}`,
          (error, data) => {
            dispatch(setArticleIdMenuList(undefined));
            if (error) {
              dispatch(clearArticleCacheFailure());
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject();
            }
            dispatch(clearArticleCacheSuccess(id));
            dispatch(setAppMessage(data.result, { type: MessageTypes.SUCCESS }));
            return Promise.resolve()
          });
      });
  }
}
// end of clear cache for live

export function shareArticleDebug() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    const { articleSettings: { menuListArticleId, articleId }, articles: { articlesByIds } } = getState().cms;
    const id = menuListArticleId ? menuListArticleId : articleId;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/schedule-manage/${activeDomain}/invalidate-post`, {article_id: id},
          (error, data) => {
            if (error) {
              // dispatch(invalidateFailure(error.message));
              // dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }))
              return Promise.reject(error);
            }
            // dispatch(invalidateSuccess(data.message));
            // dispatch(setAppMessage(data.message, { type: MessageTypes.SUCCESS }))
            return Promise.resolve(data);
          });
      });
  }
}

//Feed back
export function setFeedbackModal(status) {
  return {
    type: ArticleAction.SET.FEEDBACK_MODAL,
    status: status
  }
}

function sendFeedbackRequest() {
  return {
    // type: NewStoryAction.SAVE.REQUEST
    type: ArticleAction.SEND_FEEDBACK.REQUEST
  }
}
function sendFeedbackFailure() {
  return {
    type: ArticleAction.SEND_FEEDBACK.FAILURE
  }
}
function sendFeedbackSuccess(articleId, articleState, isUpdateDraft) {
  return {
    type: ArticleAction.SEND_FEEDBACK.SUCCESS,
    articleId: articleId,
    isUpdateDraft,
    articleState,
  }
}
export function sendFeedback(feedback, articleState) {
  return (dispatch, getState) => {
    const { activeDomain, id: userId } = getState().login.user;
    const { articleSettings: { menuListArticleId, articleId }, articles: { articlesByIds } } = getState().cms;
    const id = menuListArticleId ? menuListArticleId : articleId;
    const articleDetails = articlesByIds[id];
    // const { isValid, message } = isValidDisclaimerId(articleDetails);
    if (false) {
      // dispatch(setAppMessage(message, { type: MessageTypes.ERROR }));
      return Promise;
    } else {
      // dispatch(setAppMessage("In Progress!", { type: MessageTypes.INFO }));
      dispatch(sendFeedbackRequest());
      return MeawwApi()
        .auth()
        .then(api => {
          return api.post(`/article/${activeDomain}/feedback/${id}`, {
            comment: feedback
          },
            (error, data) => {
              dispatch(setArticleIdMenuList(undefined));
              if (error) {
                dispatch(sendFeedbackFailure());
                dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
                return Promise.reject();
              }
              let isUpdateDraft = !!(articleDetails.author.id === userId);
              dispatch(sendFeedbackSuccess(id, articleState, isUpdateDraft));
              dispatch(setAppMessage(data.result, {
                type: MessageTypes.SUCCESS,
              }));
              dispatch(setFeedbackModal(false));
              return Promise.resolve()
            });
        });
    }
  }
}
// End Feedback

// finish editing article
function finishEditingRequest() {
  return {
    type: ArticleAction.FINISH_EDIT.REQUEST,
  }
}
function finishEditingSuccess() {
  return {
    type: ArticleAction.FINISH_EDIT.SUCCESS,
  }
}
function finishEditnigFailure() {
  return {
    type: ArticleAction.FINISH_EDIT.FAILURE,
  }
}

/**
 * remove the lock from the current user.
 * @return {Object} redux thunk
 */
export function finishEditingArticle() {
  return (dispatch, getState = {}) => {
    const { activeDomain } = getState().login.user;
    const { articleSettings: { menuListArticleId, articleId }, articles: { articlesByIds } } = getState().cms;
    const id = menuListArticleId ? menuListArticleId : articleId;
    dispatch(finishEditingRequest());
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/article/${activeDomain}/unlock/${id}`,
          {
            "forced": 0
          },
          (error, data) => {
            dispatch(setArticleIdMenuList(undefined));
            if (error) {
              dispatch(finishEditnigFailure());
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject();
            }
            return Promise.all([
              dispatch(setAppMessage(data.result, {
                type: MessageTypes.SUCCESS,
              })),
              dispatch(setArticleLockDetails(null, id)),
              dispatch(finishEditingSuccess())
            ]);
          });
      });
  }
}
//publish article

function publishArticleRequest() {
  return {
    type: ArticleAction.PUBLISH.REQUEST
  }
}

export function toggleProgressModal(visible) {
  return {
    type: ArticleAction.SET.PROGRESS_MODAL,
    visible,
  }
}
function publishArticleFailure() {
  return {
    type: ArticleAction.PUBLISH.FAILURE
  }
}

function setProgressModalData(progressModalData) {
  return {
    type: ArticleAction.SET.PROGRESS_MODAL_DATA,
    progressModalData,
  }
}
function publishArticleSuccess(articleId, articleState) {
  return {
    type: ArticleAction.PUBLISH.SUCCESS,
    articleType: ArticlesState.DRAFT_ARTICLES,
    articleId: articleId,
    articleState: articleState,
  }
}
export function publishArticle() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    const { articleSettings: { menuListArticleId, articleId }, articles: { articlesByIds } } = getState().cms;
    const id = menuListArticleId ? menuListArticleId : articleId;
    const articleDetails = articlesByIds[id];
    const socketRoomId = `${id}|${getState().login.user.id}`;
    const progressModalData = [];
    socket.emit('room', socketRoomId);
    dispatch(toggleProgressModal(true));
    socket.onevent = (packet) => {
      const { data } = packet;
      const msg = data[0] || '', desc = data[1] || '';
      if (msg.indexOf('Complete') > -1) {
        progressModalData.push({ status: 'done', desc });
      } else if (msg.indexOf('Fail') > -1) {
        progressModalData.push({ status: 'error', desc });
      } else if (msg.indexOf('leave') > -1) {
        socket.emit('leave', socketRoomId);
      } else {
        progressModalData.push({ status: 'todo', desc });
      }
      dispatch(setProgressModalData(progressModalData.slice()));
    };
    // const { isValid, message } = isValidDisclaimerId(articleDetails);
    if (false) {
      // dispatch(setAppMessage(message, { type: MessageTypes.ERROR }));
      return Promise;
    } else {
      dispatch(publishArticleRequest());
      return MeawwApi()
        .auth()
        .then(api => {
          return api.post(`/article/${activeDomain}/publish/${id}`,
            {
              "shadow": 0
            },
            (error, data) => {
              if (error) {
                dispatch(publishArticleFailure());
                dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
                return Promise.reject();
              }
              return Promise.all([
                dispatch(publishArticleSuccess(id, ArticlesState.LIVE_ARTICLES)),
                dispatch(setArticleIdMenuList(undefined)),
                dispatch(removeArticleIdForPreviewArticle()),
                dispatch(publishArticleSuccess(id, ArticlesState.APPROVED_ARTICLES)),
              ]);
            });
        });

    }
    // }
  }
}
// End article publish


//publish article

function unpublishArticleRequest() {
  return {
    // type: NewStoryAction.SAVE.REQUEST
    type: ArticleAction.UNPUBLISH.REQUEST
  }
}
function unpublishArticleFailure() {
  return {
    type: ArticleAction.UNPUBLISH.FAILURE
  }
}
function unpublishArticleSuccess(articleId) {
  return {
    type: ArticleAction.UNPUBLISH.SUCCESS,
    articleState: ArticlesState.LIVE_ARTICLES,
    articleId: articleId,
  }
}
export function unpublishArticle(redirectToArticles) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    const { articleSettings: { menuListArticleId, articleId }, articles: { articlesByIds } } = getState().cms;
    const id = menuListArticleId ? menuListArticleId : articleId;
    const articleDetails = articlesByIds[id];
    // const { isValid, message } = isValidDisclaimerId(articleDetails);
    if (false) {
      // dispatch(setAppMessage(message, { type: MessageTypes.ERROR }));
      return Promise;
    } else {
      const socketRoomId = `${id}|${getState().login.user.id}`;
      const progressModalData = [];
      socket.emit('room', socketRoomId);
      dispatch(toggleConfirmationBox(false));
      dispatch(toggleProgressModal(true));
      socket.onevent = (packet) => {
        const { data } = packet;
        const msg = data[0] || '', desc = data[1] || '';
        if (msg.indexOf('Complete') > -1) {
          progressModalData.push({ status: 'done', desc });
        } else if (msg.indexOf('Fail') > -1) {
          progressModalData.push({ status: 'error', desc });
        } else if (msg.indexOf('leave') > -1) {
          socket.emit('leave', socketRoomId);
        } else {
          progressModalData.push({ status: 'todo', desc });
        }
        dispatch(setProgressModalData(progressModalData.slice()));
      };
      dispatch(unpublishArticleRequest());
      return MeawwApi()
        .auth()
        .then(api => {
          return api.post(`/article/${activeDomain}/unpublish/${id}`, { reason: "Unpublish live article" },
            (error, data) => {
              dispatch(setArticleIdForMenuList(undefined));
              if (error) {
                dispatch(unpublishArticleFailure());
                dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
                return Promise.reject();
              }
              dispatch(unpublishArticleSuccess(id));

              return Promise.resolve()
            });
        });

    }
    // }
  }
}
export function setDisplayPreviewAction() {
  return {
    type: ArticleAction.SET.DISPLAY_PREVIEW_ACTION
  }
}
export function removeDisplayPreviewAction() {
  return {
    type: ArticleAction.REMOVE.DISPLAY_PREVIEW_ACTION
  }
}
// End article publish

export function toggleAdsForArticle(disable) {

  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    const { articleSettings: { menuListArticleId, articleId }, articles: { articlesByIds } } = getState().cms;
    const id = menuListArticleId ? menuListArticleId : articleId;
    dispatch(toggleAdsRequest());
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/article/${activeDomain}/ads/${id}`,
          {
            "disable": disable,
          },
          (error, data) => {
            dispatch(setArticleIdMenuList(undefined));
            if (error) {
              dispatch(toggleAdsError());
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject();
            }
            return Promise.all([
              dispatch(setArticleAdsDetails(disable, id)),
              dispatch(setAppMessage(data.result, {
                type: MessageTypes.SUCCESS,
              })),
              dispatch(toggleAdsSuccess())
            ]);
          });
      });
  }
}

function toggleAdsRequest() {
  return {
    type: ArticleAction.TOGGLE_ADS.REQUEST,
  }
}

function toggleAdsSuccess() {
  return {
    type: ArticleAction.TOGGLE_ADS.SUCCESS,
  }
}

function toggleAdsError() {
  return {
    type: ArticleAction.TOGGLE_ADS.FAILURE,
  }
}

function setArticleAdsDetails(disable, articleId) {
  /**
   * action generator for setting article ads details
   * @return {Object} action
   */
  return {
    type: ArticleAction.SET.ARTICLE_ADS_DISABLE,
    disable,
    articleId,
  }
}

export function setSchedularModal(status) {
  return {
    type: ArticlesAction.SCHEDULE.SHOW,
    status
  }
}

export function setExploiterModal(status) {
  return {
    type: ArticlesAction.EXPLOIT.SHOW,
    status
  }
}

export function postSchedular(date) {
  return (dispatch, getState) => {
    const { login: { user: { activeDomain } } } = getState();
    const { articleSettings: { menuListArticleId, articleId } } = getState().cms;
    const id = menuListArticleId ? menuListArticleId : articleId;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/article/${activeDomain}/schedule/${id}`, {
          time: date
        },
          (error, data) => {
            if (error) {
              //dispatch(getArticlesFailure(articleState));
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }))
              return Promise.reject(error);
            }
            dispatch(setAppMessage(data.result, { type: MessageTypes.SUCCESS }));
            return Promise.resolve(data.result);
          });
      });
  }
}

function deleteScheduleArticleRequest() {
  return {
    type: ArticleAction.DELETE_SCHEDULE.REQUEST
  }
}
function deleteScheduleArticleFailure() {
  return {
    type: ArticleAction.DELETE_SCHEDULE.FAILURE
  }
}
function deleteScheduleArticleSuccess(articleId) {
  return {
    type: ArticleAction.DELETE_SCHEDULE.SUCCESS,
    articleId: articleId,
  }
}
export function deleteScheduleArticle(redirectToArticles) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    const { articleSettings: { menuListArticleId, articleId }, articles: { articlesByIds } } = getState().cms;
    const id = menuListArticleId ? menuListArticleId : articleId;
    dispatch(deleteScheduleArticleRequest());
    return MeawwApi()
      .auth()
      .then(api => {
        return api.delete(`/article/${activeDomain}/schedule/${id}`, { time: new Date() },
          (error, data) => {
            dispatch(setArticleIdForMenuList(undefined));
            if (error) {
              dispatch(deleteScheduleArticleFailure());
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject();
            }
            dispatch(deleteScheduleArticleSuccess(id));
            return Promise.resolve()
          });
      });
  }
}

function deleteScheduleAsExploitArticleRequest() {
  return {
    type: ArticleAction.DELETE_SCHEDULE_AS_EXPLOIT.REQUEST
  }
}
function deleteScheduleAsExploitArticleFailure() {
  return {
    type: ArticleAction.DELETE_SCHEDULE_AS_EXPLOIT.FAILURE
  }
}
function deleteScheduleAsExploitArticleSuccess(articleId) {
  return {
    type: ArticleAction.DELETE_SCHEDULE_AS_EXPLOIT.SUCCESS,
    articleId: articleId,
  }
}
export function deleteScheduleAsExploitArticle(redirectToArticles) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    const { articleSettings: { menuListArticleId, articleId }, articles: { articlesByIds } } = getState().cms;
    const id = menuListArticleId ? menuListArticleId : articleId;
    dispatch(deleteScheduleAsExploitArticleRequest());
    return MeawwApi()
      .auth()
      .then(api => {
        return api.delete(`/refurbish_links/${activeDomain}/schedule/${id}`, {status: -2},
          (error, data) => {
            dispatch(setArticleIdForMenuList(undefined));
            if (error) {
              dispatch(deleteScheduleAsExploitArticleFailure());
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject();
            }
            dispatch(deleteScheduleAsExploitArticleSuccess(id));
            return Promise.resolve()
          });
      });
  }
}
