import React from "react";
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import cx from "classnames";

const styles = theme => ({
  progress: {
    margin: theme.spacing.unit * 2,
  },
  signoutProgress: {
    margin: "0 0 0 10px",
  },
  marginZero: {
    margin: "0 10px"
  }
});

function setStyle(styles = {}) {
  return theme => ({
    progress: {
      margin: styles.margin ? styles.margin : theme.spacing.unit * 2,

      // color: '#6798e5',
      // animationDuration: '550ms',
      // position: 'absolute',
      // // left: 0,
    },

  });
}

function AppLoader(props) {
  const { message, classes, color = "primary", size = 40, isSignOutLoader = false, isMarginZero = false } = props;
  const classNames = classes.progress + " " + cx({
    [classes.signoutProgress]: isSignOutLoader,
    [classes.marginZero]: isMarginZero,
  })
  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", height: "100%" }}>
      <CircularProgress
        className={classNames}
        size={size}
        // disableShrink
        variant="indeterminate"
        // disableShrink
        // className={classes.facebook2}
        size={size}
        style={{color: 'black'}}
      // thickness={4}
      />
      {message && <strong style={{ position: "relative", top: "-10px" }}>{message}</strong>}
    </div >
  );
}
// export function AppLoaderWrapper({ styles = {}, ...rest }) {
//   console.log("withStyles(setStyle())===", withStyles(setStyle()));
//   console.log("AppLoader")
//   return withStyles(setStyle())(AppLoader);
// }

AppLoader.propTypes = {
  classes: PropTypes.object.isRequired,

};

export default withStyles(styles)(AppLoader);
