import React, { PureComponent } from 'react';
import { setArticleId, loadArticleDetails, loadArticleContent } from "../article/article-action.js";
import StoryPageContent from "./views/story-page-content.jsx";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class EditStorypage extends PureComponent {
    constructor(props) {
        super(props);
    }
    componentWillMount() {
        const { setArticleId, articleId } = this.props;
        setArticleId(articleId)
    }
    _fetchArticleDetailsAndContent(articleId) {
        const { loadArticleDetails, loadArticleContents } = this.props;
        loadArticleDetails(articleId)
            .then(() => {
                loadArticleContents(articleId);
            })
            .catch(err => {
            });
    }

    componentDidMount() {
        const { articleId } = this.props;
        this._fetchArticleDetailsAndContent(articleId);
    }
    render() {
        return (
            <StoryPageContent />
        )
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        articleId: ownProps.match.params.articleId
    }
}

const mapDispatchToProps = dispatch => ({
    loadArticleDetails: (articleId) => dispatch(loadArticleDetails(articleId)),
    setArticleId: (articleId) => dispatch(setArticleId(articleId)),
    loadArticleContents: (articleId) => dispatch(loadArticleContent(articleId)),

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditStorypage));
