import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { LocalStorageKeys, ArticleTypes, SessionKeys } from "app-constants/index.js";
import { setArticleId, loadArticleDetails, loadArticleContent } from "../article/article-action.js";
import { createNewStory } from "./new-story-actions.js";
import StoryPageContent from "./views/story-page-content.jsx";

class NewStoryPage extends PureComponent {
  constructor(props) {
    super(props);
  }

  _fetchArticleDetailsAndContent(articleId) {
    const { loadArticleDetails, loadArticleContent } = this.props;
    loadArticleDetails();
    loadArticleContent();
  }
  componentDidMount() {
    const {
      articleId,
      location: {
        state: { articleType = ArticleTypes.NORMAL } = {}
      } = {}
    } = this.props;
    if (!articleId) {
      this.props
        .createNewStory(articleType)
        .then(artilceId => {
          this._fetchArticleDetailsAndContent(artilceId);
        })
        .catch(err => {
          // redirect the user to articles page
          this.props.history.push("/cms/articles");
        });
    } else {
      this._fetchArticleDetailsAndContent(articleId);
    }
  }

  render() {
    return (<div>
      <StoryPageContent {...this.props} />
    </div>);
  }
}

const mapStateToPRops = state => {
  return {
    articleId: state.cms.articleSettings.articleId,
    isCreatingNewArticle: state.cms.newStory.isCreatingNewArticle,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setArticleId: (articleId) => dispatch(setArticleId(articleId)),
    createNewStory: () => dispatch(createNewStory()),
    loadArticleDetails: () => dispatch(loadArticleDetails()),
    loadArticleContent: () => dispatch(loadArticleContent()),
  };
};

NewStoryPage.propTypes = {
  articleId: PropTypes.oneOfType([PropTypes.number], undefined),
  setArticleId: PropTypes.func,
  createNewStory: PropTypes.func,
  location: PropTypes.object
};

export default connect(
  mapStateToPRops,
  mapDispatchToProps
)(NewStoryPage);
