import React, { PureComponent, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SidePane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Pisco from "pisco-ui";
import "pisco-ui/lib/styles.css";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// import "../../../styles/pisco-pane.scss";
import ArticlePreview from "../../article/view/article-preview.jsx";


import ArticleDetailsContainer from "./article-details-container.jsx";
import { getArticleDetailsFromArticleId } from "app-selector.js";
import {
  setArticleVideoHeader,
  deleteArticleVideoheader,
  hidePiscoModal,
  setPiscoContent,
  showPiscoModal as showPiscoModalAction,
  setCoverPictureUrl,
  setArticleCoverCaption,
  clearArticleCoverCaption,
} from "../new-story-actions.js";
import { PiscoContentTypes, ArticleTypes } from "app-constants";
import AppLoader from "app-components/app-loader.jsx";

import CoverPicture from "./cover-picture.jsx";
import GalleryContents from "./gallery-content.jsx";
import StoryContents from "./story-content.jsx";
import ApprovalSubmit from "./approval-submit.jsx";

import { setArticleIdForPreviewArticle, setLastLocation } from "../../article/article-action.js";

class StoryPageContent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isPaneOpen: false,
      isPaneOpenLeft: false
    };
    this.tinyMCEEditor = React.createRef();
  }
  _hidePiscoModal() {
    this.setState({
      activePiscoContent: undefined
    });
    this.props.hidePiscoModal();
  }

  goToArticles = () => {
    //this.props.clearArticleCoverCaption();
    this.props.setLastLocation('edit-page');
    this.props.history.push("/cms/articles");
  }
  componentWillUnmount() {
    this.props.clearArticleCoverCaption();
  }
  render() {
    const {
      setArticleIdForPreviewArticle,
      deleteArticleVideoheader,
      contentType,
      activePiscoContentType,
      showPiscoModalAction,
      setArticleVideoHeader,
      shareType,
      isCreatingNewArticle,
      activeDomain,
      setLastLocation
    } = this.props;
    if (isCreatingNewArticle) {
      return <AppLoader />
    }
    let piscoType = "Both";
    if (activePiscoContentType === PiscoContentTypes.COVER_IMAGE) {
      piscoType = "Image"
    }
    if (activeDomain != 15 && activeDomain != 107) {
      piscoType = "Image"
    }

    return (
      <Fragment>
        <GridContainer>
          <GridItem xs={false} md={2}></GridItem>
          <GridItem xs={12} md={8} style={{ border: '1px solid #ced3dc', borderRadius: '2px' }}>
            <div style={{padding:'30px 20px'}}>
            <CoverPicture activePiscoContentType={activePiscoContentType} onPiscoOpen={showPiscoModalAction} setPictureUrl={this.props.setCoverPictureUrl} />
            <ArticleDetailsContainer setArticleIdForPreviewArticle={setArticleIdForPreviewArticle} tinyMCEEditor={this.tinyMCEEditor}/>
            {
              <StoryContents activePiscoContentType={activePiscoContentType} tinyMCEEditor={this.tinyMCEEditor}/>
              // contentType == ArticleTypes.ARTICLE &&
              // <StoryContents activePiscoContentType={activePiscoContentType} /> ||
              // <GalleryContents activePiscoContentType={activePiscoContentType} />
            }
            <ApprovalSubmit goToArticles={this.goToArticles} />
            <ArticlePreview />
            </div>
          </GridItem>
        </GridContainer>
        <SidePane
            overlayClassName='pisco-pane'
            isOpen={this.props.showPiscoModal}
            title='PISCO'
            subtitle='Media Search'
            from='right'
            width='80%'
            onRequestClose={this.props.hidePiscoModal}
          >
            <Pisco
            endpoint={process.env.REACT_APP_API_SERVER}
            headers={{
              authorization: localStorage.getItem('accessToken')
            }
            }
              injectQuery={{ domainId: activeDomain, cover_img: activePiscoContentType === PiscoContentTypes.COVER_IMAGE ? 1 : 0 }}
              disableGetty={this.props.domain !== 'meaww'}
              type={piscoType}
              mode="embed"
            onEmbed={content => {
              if (content.cdn) content.cdn = content.cdn.replace('small_200_', '');
              if (content.s3Url) content.s3Url = content.s3Url.replace('small_200_', '');
                if (activePiscoContentType === PiscoContentTypes.COVER_IMAGE) {
                  this.props.setCoverPictureUrl(content.cdn);
                  this.props.setArticleCoverCaption(content.caption);
                } else {
                  this.props.setPiscoContent(content);
                }
                this.props.hidePiscoModal();
              }} />
          </SidePane>
      </Fragment>

    );
  }
}
const mapStateToProps = (state) => {
  const {
    cms: {
      articleSettings: {
        articleId
      },
      newStory: {
        showPiscoModal,
        activePiscoContentType
      }
    },
    login: { user }
  } = state;
  const articleDetails = getArticleDetailsFromArticleId(state.cms, articleId);
  //remvoe default value
  const { contentType, domain = "meaww", shareType = "meaww" } = articleDetails;
  return {
    showPiscoModal,
    activePiscoContentType,
    shareType: shareType,
    domain: domain,
    contentType: contentType,
    activeDomain: user.activeDomain
  }
}

const mapDispatchToProps = dispatch => ({
  hidePiscoModal: () => dispatch(hidePiscoModal()),
  showPiscoModalAction: (piscoContentType) => dispatch(showPiscoModalAction(piscoContentType)),
  setPiscoContent: (content) => dispatch(setPiscoContent(content)),
  setCoverPictureUrl: url => dispatch(setCoverPictureUrl(url)),
  clearArticleCoverCaption: () => dispatch(clearArticleCoverCaption()),
  setArticleCoverCaption: url => dispatch(setArticleCoverCaption(url, null)),
  setArticleVideoHeader: (url) => dispatch(setArticleVideoHeader(url)),
  deleteArticleVideoheader: () => dispatch(deleteArticleVideoheader()),
  setLastLocation: (location) =>  dispatch(setLastLocation(location)),

  //preview
  setArticleIdForPreviewArticle: (articleId, states) => dispatch(setArticleIdForPreviewArticle(articleId, states))

})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StoryPageContent));
