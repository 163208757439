import { NewStoryAction, UserAction, ArticleAction, SessionKeys, PiscoContentTypes } from "app-constants/index.js";
import { ArticleTypes } from "../../app-constants";
const DEFAULT_COVER_PICTURE = "https://cdn.meaww.com/assets/img/Blur.png";

const getArticleDefaultValue = () => {
  return sessionStorage.getItem(SessionKeys.ARTICLE_ID) || window.articleId;
}

const newStoryInitaialValues = {
  allowSubmission: false,
  isCreatingNewArticle: false,
  articleId: getArticleDefaultValue(),
  allowContentUpdate: true,
  showImageFlipperModal: false,
  uploadingImageFlipper: false,

  editorContent: "Start writing...",

  submittingArticle: false,
  //image uploadImage
  imageUploading: false,

  allowAuthorName: false,

  articleDetails: {
    metaTagsList: [],
    subCategoriesList: [],
    showSubCategoriesModal: false,
    showMetaDataModal: false,

    fetchingArticleDetails: false,
    shareType: undefined,
    categoryType: 0,
    subCategories: [],
    storyType: undefined,
    title: "",
    summary: "",
    coverCaption: "",
    seoTitle: "",
    postCaption: "",
    customUrl: "",
    metaTags: [],
    articleType: -1,
    timeSpecific: -1,
    authorName: ""
  },
  //api data
  categories: [],
  //pisco
  showPiscoModal: false,
  activePiscoContentType: PiscoContentTypes.CONTENT,
  //cover
  coverUrl: null,

  showBlockQuoteModal: false,

  piscoContent: {},

  //meta modal
  showMetaDataModal: false,
  //disclaimer
  showDisclaimerModal: false,
  //disclaimers
  disclaimers: [],
  isDisclaimerLoading: false


}

function newStory(state = newStoryInitaialValues, action) {
  switch (action.type) {
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return newStoryInitaialValues;
    }

    //start new article config
    case NewStoryAction.SET.NEW_ARTICLE.ALLOW_UPDATE:
      return Object.assign({}, state, { allowContentUpdate: action.update });
    case NewStoryAction.SET.NEW_ARTICLE.ARTICLE_ID:
      return { ...state, articleId: action.articleId };

    case NewStoryAction.SET.NEW_ARTICLE.CATEGORIES: {
      const { articleDetails, articleDetails: {
        categoryType
      } } = state;
      const { categories, resetCategryType } = action;
      let updatedCategoryType = 0;
      if (!resetCategryType) {
        updatedCategoryType = categoryType;
      }
      return {
        ...state,
        articleDetails: {
          ...articleDetails,
          categoryType: updatedCategoryType
        },
        categories: [...categories]
      }
    }
    //end new article configs
    //start create new story
    case NewStoryAction.CREATE.REQUEST: {
      return { ...state, isCreatingNewArticle: true }
    }
    case NewStoryAction.CREATE.FAILURE:
    case NewStoryAction.CREATE.SUCCESS: {
      return { ...state, isCreatingNewArticle: false }
    }
    // end create new story

    // start image flipper
    case NewStoryAction.SET.IMAGE_FLIPPER.REQUEST: {
      return Object.assign({}, state, { uploadingImageFlipper: true });
    }
    case NewStoryAction.SET.IMAGE_FLIPPER.SUCCESS: {
      const { content } = action;
      const { editorContent } = state;
      return Object.assign({}, state, {
        uploadingImageFlipper: false,
        showImageFlipperModal: false,
      });
    }
    case NewStoryAction.SET.IMAGE_FLIPPER.SHOW: {
      return Object.assign({}, state, { showImageFlipperModal: true });
    }
    case NewStoryAction.SET.IMAGE_FLIPPER.HIDE: {
      return Object.assign({}, state, { showImageFlipperModal: false });
    }
    // end of image flipper

    //dont need this reducer
    case NewStoryAction.APPEND.BLOCK_QUOTE.CONTENT: {
      const { blockQuote } = action;
      const { editorContent } = state;
      return Object.assign({}, state, {
        showBlockQuoteModal: false,
        // editorContent: editorContent + blockQuote
      });
    }

    case NewStoryAction.APPEND.EDITOR.CONTENT: {
      return state;
    }



    // start image uploading

    // end of image uplaoding

    //start article details form
    case NewStoryAction.SET.ARTICLE_DETAILS.SHARE_TYPE: {
      const { articleDetails } = state;
      const { shareType } = action;
      return {
        ...state,
        articleDetails: {
          ...articleDetails,
          shareType: shareType
        }
      }
    }

    case NewStoryAction.SET.ARTICLE_DETAILS.CATEGORY_TYPE: {
      const { articleDetails } = state;
      const { categoryType } = action;
      return {
        ...state,
        articleDetails: {
          ...articleDetails,
          categoryType: categoryType
        }
      }
    }
    case NewStoryAction.SET.ARTICLE_DETAILS.SUB_CATEGORY_TYPE: {
      const { articleDetails } = state;
      const { subCategories } = action;
      return {
        ...state,
        articleDetails: {
          ...articleDetails,
          subCategories: [...subCategories],
        }
      }
    }

    case NewStoryAction.SET.ARTICLE_DETAILS.STORY_TYPE: {
      const { articleDetails } = state;
      const { storyType } = action;
      return {
        ...state,
        articleDetails: {
          ...articleDetails,
          storyType: storyType
        }
      }
    }

    case NewStoryAction.SET.ARTICLE_DETAILS.ARTICLE_TITLE: {
      const { articleDetails } = state;
      const { title } = action;
      return {
        ...state,
        articleDetails: {
          ...articleDetails,
          title: title
        }
      }
    }

    case NewStoryAction.SET.ARTICLE_DETAILS.ARTICLE_SUMMARY: {
      const { articleDetails } = state;
      const { summary } = action;
      return {
        ...state,
        articleDetails: {
          ...articleDetails,
          summary: summary
        }
      }
    }
    case ArticleAction.SET.ARTICLE_COVER_CAPTION: {
      const { articleDetails } = state;
      const { cover_caption } = action;
      //console.log(`reducer`, action);
      return {
        ...state,
        articleDetails: {
          ...articleDetails,
          coverCaption: cover_caption
        }
      }
    }
    case ArticleAction.SET.CLEAR_ARTICLE_COVER_CAPTION: {
      const { articleDetails } = state;
      return {
        ...state,
        articleDetails: {
          ...articleDetails,
          coverCaption: ""
        }
      }
    }

    case NewStoryAction.SET.ARTICLE_DETAILS.SEO_TITLE: {
      const { articleDetails } = state;
      const { seoTitle } = action;
      return {
        ...state,
        articleDetails: {
          ...articleDetails,
          seo_title: seoTitle
        }
      }
    }

    case NewStoryAction.SET.ARTICLE_DETAILS.POST_CAPTION: {
      const { articleDetails } = state;
      const { postCaption } = action;
      return {
        ...state,
        articleDetails: {
          ...articleDetails,
          post_caption: postCaption
        }
      }
    }

  case NewStoryAction.SET.ARTICLE_DETAILS.CUSTOM_URL: {
      const { articleDetails } = state;
      const { customUrl } = action;
      return {
        ...state,
        articleDetails: {
          ...articleDetails,
          custom_url: customUrl
        }
      }
    }

    case NewStoryAction.SET.ARTICLE_DETAILS.META_TAGS: {
      const { articleDetails } = state;
      const { metaTags } = action;
      return {
        ...state,
        articleDetails: {
          ...articleDetails,
          metaTags: metaTags
        }
      }
    }
    case NewStoryAction.SET.ARTICLE_DETAILS.ARTICLE_TYPE: {
      const { articleDetails } = state;
      const { articleType } = action;
      return {
        ...state,
        articleDetails: {
          ...articleDetails,
          articleType: articleType
        }
      }
    }

    case NewStoryAction.SET.ARTICLE_DETAILS.TIME_SPECIFIC: {
      const { articleDetails } = state;
      const { timeSpecific } = action;
      return {
        ...state,
        articleDetails: {
          ...articleDetails,
          timeSpecific: timeSpecific
        }
      }
    }

    case NewStoryAction.SET.ARTICLE_DETAILS.AUTHOR_NAME: {
      const { articleDetails } = state;
      const { authorName } = action;
      return {
        ...state,
        articleDetails: {
          ...articleDetails,
          authorName: authorName
        }
      }
    }
    case NewStoryAction.SET.ARTICLE_DETAILS.META_TAGS_LIST: {
      const { articleDetails } = state;
      const { metaTagsList } = action;
      return {
        ...state,
        articleDetails: {
          ...articleDetails,
          metaTagsList: metaTagsList,
        }
      }
    }
    case NewStoryAction.SET.ARTICLE_DETAILS.SUB_CATEGORIES_LIST: {
      const { articleDetails } = state;
      const { subCategoriesList } = action;
      return {
        ...state,
        articleDetails: {
          ...articleDetails,
          subCategoriesList: subCategoriesList,
          showSubCategoriesModal: true,
        }
      }
    }
    case NewStoryAction.SET.ARTICLE_DETAILS.SUB_CATEGORIES_LIST_MODAL: {
      const { articleDetails } = state;
      const { visible } = action;
      return {
        ...state,
        articleDetails: {
          ...articleDetails,
          showSubCategoriesModal: visible,
        }
      }
    }
    case NewStoryAction.SET.ARTICLE_DETAILS.META_DATA_MODAL: {
      const { articleDetails } = state;
      const { visible } = action;
      return {
        ...state,
        articleDetails: {
          ...articleDetails,
          showMetaDataModal: visible,
        }
      }
    }

    case NewStoryAction.SET.ARTICLE_DETAILS.META_DETAILS: {
      const { articleDetails, coverPicture } = state;
      const { articleMetaInfo } = action;
      const {
        article_data: {
          share_type: shareType,
          category_id: categoryType,
          y_category_id: storyType,
          title,
          summary,
          seo_title: seoTitle,
          post_caption: postCaption,
          custom_url: customUrl,
          el_type: articleType,
          time_specific: timeSpecific,
          alias: authorName,
          cover_picture: coverImage = DEFAULT_COVER_PICTURE,
          img_extension: imgExtension,
          source,
        },
        article_tags: articleTags
      } = articleMetaInfo;
      const shareTypeNew = (shareType == "meaww" || shareType == "thedailynet") ? shareType : undefined;

      let updatedAuthorName = authorName;
      if (authorName === undefined) {
        updatedAuthorName = "";
      }
      return {
        ...state,
        allowAuthorName: updatedAuthorName && updatedAuthorName.length > 0,
        articleDetails: {
          ...articleDetails,
          shareType: shareTypeNew,
          categoryType: categoryType,
          storyType: storyType,
          title: title,
          summary: summary,
          seoTitle: seoTitle,
          postCaption: postCaption,
          customUrl: customUrl,
          articleType: articleType,
          timeSpecific: timeSpecific,
          authorName: updatedAuthorName,
          metaTags: articleTags && articleTags.map(eachTag => eachTag.tag) || []
        },
        coverPicture: {
          ...coverPicture,
          image: !!coverImage
            ? coverImage + "_1200_630" + imgExtension
            : coverPicture.image,
          imageSource: source,
        }
      }
    }
    case NewStoryAction.FETCH.ARTICLE_DETAILS.REQUEST: {
      const { articleDetails } = state;
      const { authorName } = action;
      return {
        ...state,
        articleDetails: {
          ...articleDetails,
          fetchingArticleDetails: true,
        }
      }
    }
    case NewStoryAction.FETCH.ARTICLE_DETAILS.FAILURE: {
      const { articleDetails } = state;
      const { authorName } = action;
      return {
        ...state,
        articleDetails: {
          ...articleDetails,
          fetchingArticleDetails: false,
        }
      }
    }
    case NewStoryAction.FETCH.ARTICLE_DETAILS.SUCCESS: {
      const { articleDetails } = state;
      const { authorName } = action;
      return {
        ...state,
        articleDetails: {
          ...articleDetails,
          fetchingArticleDetails: false,
        }
      }
    }

    //end article details form


    //start cover image

    case NewStoryAction.UPLOAD.COVER_PICTURE.REQUEST: {
      const { coverPicture } = state;
      return {
        ...state,
        coverPicture: {
          ...coverPicture,
          uploadingCoverPicture: true
        }
      }
    }
    case NewStoryAction.UPLOAD.COVER_PICTURE.FAILURE: {
      const { coverPicture } = state;
      return {
        ...state,
        coverPicture: {
          ...coverPicture,
          uploadingCoverPicture: false
        }
      }
    }
    case NewStoryAction.UPLOAD.COVER_PICTURE.SUCCESS: {
      const { image, imgExtension } = action;
      const { coverPicture } = state;
      return {
        ...state,
        coverPicture: {
          ...coverPicture,
          uploadingCoverPicture: false,
          image: image + "_1200_630" + imgExtension
        }
      }
    }

    //end cover image

    case NewStoryAction.SAVE.REQUEST: {
      return { ...state, submittingArticle: true };
    }
    case NewStoryAction.SAVE.FAILURE: {
      return { ...state, submittingArticle: false };
    }
    case NewStoryAction.SAVE.SUCCESS: {
      return { ...state, submittingArticle: false };
    }



    //===================PISCO START=====================
    case NewStoryAction.SET.PISCO.CONTENT: {
      return { ...state, piscoContent: action.content }
    }

    case NewStoryAction.PISCO.OPEN: {
      return {
        ...state,
        showPiscoModal: true,
        activePiscoContentType: action.contentType,
      }
    }

    case NewStoryAction.PISCO.CLOSE: {
      return {
        ...state,
        showPiscoModal: false,
        activePiscoContentType: undefined
      }
    }

    case NewStoryAction.SET.PISCO.COVER: {
      return { ...state, coverUrl: action.content }
    }

    case NewStoryAction.SET.PISCO.RESET_COVER: {
      return { ...state, coverUrl: action.content }
    }
    //===================PISCO END=====================
    case ArticleAction.SET.COVER_PICTURE.CROP_DATA: {
      const { cropData } = action;
      const { coverPicture } = state;
      return {
        ...state,
        coverPicture: {
          ...coverPicture,
          cropData: cropData
        }
      }
    }
    case ArticleAction.SET.COVER_PICTURE.IMAGE_SOURCE: {
      const { imageSource } = action;
      const { coverPicture } = state;
      return {
        ...state,
        coverPicture: {
          ...coverPicture,
          imageSource
        }
      }
    }
    //===================BLOCKQUOTE START=====================
    case NewStoryAction.SET.BLOCK_QUOTE.SHOW: {
      return { ...state, showBlockQuoteModal: true };
    }
    case NewStoryAction.SET.BLOCK_QUOTE.HIDE:
    case NewStoryAction.APPEND.BLOCK_QUOTE.CONTENT: {
      return { ...state, showBlockQuoteModal: false };
    }
    //===================BLOCKQUOTE END=====================



    //===================IMAGE UPLOADING START=====================
    case NewStoryAction.UPLOAD.IMAGE.REQUEST: {
      return { ...state, imageUploading: true };
    }
    case NewStoryAction.UPLOAD.IMAGE.FAILURE:
    case NewStoryAction.UPLOAD.IMAGE.SUCCESS: {
      return { ...state, imageUploading: false };
    }
    //===================IMAGE UPLOADING END=====================


    //do this on content load also
    case ArticleAction.SET.CONTENTS: {
      const { contents, articleDetails: { contentType } } = action;

      let allowSubmission = false;
      if (contentType === ArticleTypes.GALLERY) {
        let parsedContents = contents;
        if (contents === "") {
          parsedContents = [];
        }
        // allowSubmission = isValidGalleryItems(parsedContents);
      } else {
        const results = contents.replace(/<\/?[^>]+(>|$)/g, "");
        const lines = results.split("\n");
        const contentLength = results.split(" ").length + (lines.length - 1);
        allowSubmission = contentLength > 25;
      }

      return {
        ...state,
        allowSubmission: allowSubmission,
      };
    }

    //meta modal
    case ArticleAction.SET.META_DATA_MODAL: {
      return { ...state, showMetaDataModal: action.visible };
    }

    //discalimer modal
    case ArticleAction.SET.DISCLAIMER_MODAL: {
      return { ...state, showDisclaimerModal: action.visible };
    }

    //start disclaimer loading
    case ArticleAction.LOAD.ARTICLE_DISCLAIMER.REQUEST: {
      return { ...state, isDisclaimerLoading: true };
    }
    case ArticleAction.LOAD.ARTICLE_DISCLAIMER.FAILURE: {
      return { ...state, isDisclaimerLoading: false };
    }
    case ArticleAction.LOAD.ARTICLE_DISCLAIMER.SUCCESS: {
      const { disclaimers } = action;
      return {
        ...state,
        disclaimers: disclaimers,
        isDisclaimerLoading: false,
      };
    }
    //end disclaimer loading
    default:
      return state;
  }
}

export default newStory;
