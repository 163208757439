const articleHomeStyle = theme => ({
  root: {
    // flexGrow: 1,
    width: '100%',
    boxShadow: 'none',
    fontFamily: 'Source Sans Pro, sans-serif'
  },
  tabsRoot: {
    // width: '60%'
  },
  tabRoot: {
    color: '#5d737e',
    textTransform: "uppercase",
    fontSize: "14px",
    margin: "auto 10px",
    fontFamily: 'Source Sans Pro, sans-serif'
  },
  flexContainer: {
    // width:'60%'
  },
  indicatiorColor: {
    backgroundColor: '#5d737e',
  },
  tabSelected: {
    color: '#2b2d42',
    fontWeight: '600',
    fontFamily: 'Source Sans Pro, sans-serif'
  },
  tabsIndicator: {
    backgroundColor: '#5d737e',
  }
});
export default articleHomeStyle;
