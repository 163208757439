
const beingEditedStyle = {
    beingEditedWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "default",
        marginTop: '-15px',
        marginBottom: '15px'
    },
    name: {
        color: "#fb4d24",
        fontSize: "14px",
        fontWeight: "500"
    },
    round: {
        background: "#f7c2b6",
        width: "31.8px",
        height: "31.8px",
        borderRadius: "50px",
         display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: "2"
    },
    beingEditedText: {
        display: "flex",
        fontSize:"0.9em",
        alignItems: "center",
        justifyContent: "center",
        background: "#f6f6f6",
        color: "#8d8d8d",
        borderTopRightRadius: "14px",
        borderBottomRightRadius: "14px",
        width: "175px",
        height: "24px",
        marginLeft: "-10px"
    },
    beingEditedprofilePic: {
        width: "40px",
        height: "40px",
        marginTop: "8px",
        marginBottom: "8px",
        borderRadius: "50%",
        background: "white",
        boxShadow: "0px 0px 0px 4px rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12)"
    }
}
export default beingEditedStyle;
