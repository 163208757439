
import React, { Component } from 'react';
import Mic from "@material-ui/icons/Mic";
import Stop from "@material-ui/icons/Stop";
import Tooltip from '@material-ui/core/Tooltip';
import ShowAudio from "./show-audio";
import Button from "../../../components/CustomButtons/Button.jsx";

const AudioContext = window.AudioContext || window.webkitAudioContext;
const Recorder = window.Recorder;
const URL = window.URL || window.webkitURL;
let timerId;

class RecordAudio extends Component {
  state = {
    isRecording: false,
    recordedChunks: [],
    options: [],
    device: '',
    url: '',
    recorder: {},
    timer: '',
    gumStram : null,
  }
  componentDidMount() {
    navigator.mediaDevices.enumerateDevices()
      .then((deviceInfos) => {
        const options = [];
        for (let i = 0; i !== deviceInfos.length; ++i) {
          const deviceInfo = deviceInfos[i];
          const option = {};
          option.value = deviceInfo.deviceId;
          if (deviceInfo.kind === 'audioinput') {
            option.label = deviceInfo.label || 'microphone ' + (9 + 1);
            options.push(option)
          }
          else {
            console.log('Found another kind of device: ', deviceInfo);
          }
        }
        this.setState({ options, device: (options[0].value) })
      })
  }
  startRecording = () => {
    const handleSuccess = (stream) => {
      try {
        this.setState({ isRecording: true });
        // const chunks = [];
        // let  timer;
        // this.setState({ recorder: new MediaRecorder(stream) });
        // const { recorder } = this.state;
        // recorder.start();
        // recorder.addEventListener("dataavailable", event => {
        //   try {
        //     chunks.push(event.data);
        //   }
        //   catch (e) {
        //     console.log(e)
        //   }
        // });
        const audioContext = new AudioContext();
        const input = audioContext.createMediaStreamSource(stream);
        const rec = new Recorder(input, { numChannels: 1 });
        this.setState({ recorder: rec,gumStram :stream });
        rec.record();
        // recorder.addEventListener('start', () => {
        //   let i = 0;
        //   timerId = setInterval(() => {
        //     const  timer = `${++i} sec`;
        //     this.setState({ timer })
        //   }, 1000);
        // });
        let i = 0;
        timerId = setInterval(() => {
          const  timer = `${++i} sec`;
          this.setState({ timer })
        }, 1000);

        // recorder.addEventListener("stop", () => {
        //   try {
        //     clearInterval(timerId);
        //     const blob = new Blob(chunks);
        //     const url = URL.createObjectURL(blob);
        //     this.setState({ url: url, blob });
        //     window.recordDuration = parseInt(`${this.state.timer}`.replace('sec', ''), 10);
        //     // let mimetype = 'audio/webm';
        //     // console.log('....',mimetype)
        //     const file = new File([blob], "audio", { lastModified: new Date(), duration: this.state.timer, type: 'audio/*', uploadType: 'audio/*' });
        //     this.props.handleFileUpload({ files: [file] }, 'audio/*');
        //   }
        //   catch(e){
        //     console.log(e)
        //   }
        // });
      }
      catch (e) {
        console.log(e)
      }
    };


    navigator.mediaDevices.getUserMedia({ audio: { deviceId: { exact: this.state.device } }, video: false })
      .then(handleSuccess).catch((error) => {
        console.error('Error: ', error);
        alert(error.message);
      });
  }
  stopRecording = () => {
    const { recorder ,gumStream} = this.state;
    recorder.stop();
    gumStream &&  gumStream.getAudioTracks()[0].stop();
    recorder.exportWAV((blob) => {
      clearInterval(timerId);
      const url = URL.createObjectURL(blob);
      this.setState({ url: url, blob });
      window.recordDuration = parseInt(`${this.state.timer}`.replace('sec', ''), 10);
      //     // let mimetype = 'audio/webm';
      //     // console.log('....',mimetype)
      const file = new File([blob], "audio", { lastModified: new Date(), duration: this.state.timer, type: 'audio/wav', uploadType: 'audio/wav' });
      this.props.handleFileUpload({ files: [file] }, 'audio/*');
    })
    // recorder.stream.getTracks().forEach(i => i.stop())
    this.setState({ isRecording: false ,recorder:{},gumStream :null})
    this.props.closeAudioModal();
  }
  handleActiveChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  resetRecordingSetting = () => {
    this.setState({ isRecording: false, recordedChunks: [], url: '', timer: '' })
  }
  render() {

    return (
      <div style={{ display: 'block', width: '96%', border: '1px solid #ced3dc', padding: '10px' }}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-around',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: `${this.state.url ? '10px' : '50px'}`
        }}>
          {
            !this.state.isRecording &&
              this.state.url ? <>
                <ShowAudio src={this.state.url} timer={parseInt(`${this.state.timer}`.replace('sec', ''), 10)} />
                <div style={{ display: 'flex', paddingTop: '10px', width: '100%', justifyContent: 'flex-end' }}>
                  <Button color="primary" style={{ marginRight: '10px' }} onClick={this.resetRecordingSetting}>RESET</Button>
                  <Button color="primary">Upload</Button>
                </div>
              </>
              : !this.state.isRecording && <>
                {/* <FormControl style={{ width: '50%', marginTop: '1%' }}>
                  <InputLabel htmlFor="age-simple">Select Audio type <InformationTooltip text="Please select input type" /></InputLabel>
                  <Select
                    value={this.state.device}
                    // renderValue={() => `${this.state.active == 1 ? 'Active' : 'Inactive'}`}
                    onChange={this.handleActiveChange}
                    inputProps={{
                      name: 'device',
                      id: 'device',
                    }}
                  >
                    {
                      this.state.options.map(({ label, value }, key) => <MenuItem key={key} value={value}>{label}</MenuItem>)
                    }
                  </Select>
                </FormControl> */}
                <Mic style={{ paddingTop: '40px', fontSize: '100px', cursor: 'pointer' }} onClick={this.startRecording} />
                <p style={{ color: '#2B2D42' }}>Click on mic and start recording</p>
              </>
          }
          {
            this.state.isRecording && <>
              {/* <Equalizer style={{ fontSize: '100px' }} /> */}
              <h2>{this.state.timer}</h2>
              <h3 style={{ color: '#2B2D42', fontSize: '16px' }}> Recording ...</h3>
              <Tooltip title="stop recording" placement='right'>
                <Stop style={{ fontSize: '75px', cursor: 'pointer' }} onClick={this.stopRecording} />
              </Tooltip>
              {/* <p>STOP</p> */}
            </>
          }

        </div>
      </div>
    )
  }
}

export default RecordAudio;
