import React from 'react';
import Button from "components/CustomButtons/Button.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppLoader from "../../../app-components/app-loader.jsx";
import { FullDetailLoaderContext, FullDetailContentContext } from "../view/campaigns";


class MoreInfo extends React.Component {

  render() {
    const { openViewModal, closeModal } = this.props;
    // const { isFullDetailLoading, fullDetail } = this.context
    return (
      <>
        <Dialog
          fullWidth
          maxWidth='sm'
          id="news-letter-modal"
          open={openViewModal}
          onClose={closeModal}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title"> Full Details </DialogTitle>
          <DialogContent>
            <FullDetailLoaderContext.Consumer>
              {(isFullDetailLoading) => (
                <FullDetailContentContext.Consumer>
                  {
                    (fullDetail = {}) => (
                      <>
                        {isFullDetailLoading && <AppLoader message={"loading full detail"} />}
                        {
                          (!isFullDetailLoading &&
                            Object.keys(fullDetail).length) &&
                          < div >
                            <p><span style={{ fontWeight: 'bold' }}>{`Subject: `}</span> {fullDetail && fullDetail.subject}</p>
                            <p style={{ display: 'flex' }}><span style={{ fontWeight: 'bold' }}>{`Preamble:`}&nbsp;</span>
                              {
                                fullDetail &&
                                <div dangerouslySetInnerHTML={{
                                  __html: fullDetail.preamble
                                }}>
                                </div>
                              }
                            </p>
                            <p><span style={{ fontWeight: 'bold' }}>{`Total Audience: `}</span> {fullDetail && fullDetail.totalAudience}</p>
                            <p><span style={{ fontWeight: 'bold' }}>{`Opened: `}</span> {fullDetail && fullDetail.opened}</p>
                            <p><span style={{ fontWeight: 'bold' }}>{`Clicked: `}</span> {fullDetail && fullDetail.clicked}</p>
                            <p><span style={{ fontWeight: 'bold' }}>{`Date: `}</span> {fullDetail && fullDetail.date}</p>
                            <p><span style={{ fontWeight: 'bold' }}>{`Unsubscribe: `}</span> {fullDetail && fullDetail.unsubscribe}</p>
                            <p><span style={{ fontWeight: 'bold' }}>{`Target Audience:`}</span> {fullDetail && fullDetail.segment[0]}</p>
                            <p><span style={{ fontWeight: 'bold' }}>{fullDetail.article && `Article: `}</span> <ul>{fullDetail && fullDetail.article && fullDetail.article.map((value, key) => {
                              return <li key={value.article_id}>
                                <a href={value.link} target="_blank">{value.title}</a>
                              </li>
                            })
                            }
                            </ul>
                            </p>
                          </div>
                        }
                      </>
                    )
                  }
                </FullDetailContentContext.Consumer>
              )}
            </FullDetailLoaderContext.Consumer>

          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );

  }
}
export default MoreInfo;

