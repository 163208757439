import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { matchPath } from 'react-router-dom';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";

// material-ui icons
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";
//import MenuIcon from "@material-ui/icons/Menu";

// core components
import HeaderLinks from "./HeaderLinks";
import DomianList from "./DomainList.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerStyle from "assets/jss/material-dashboard-pro-react/components/headerStyle.jsx";

function Header({ ...props }) {
  function makeBrand() {
    var name;
    props.routes.map(prop => {
      if (prop.collapse) {
        prop.views.map(prop => {
          //remove this later
          // if (prop.path === props.location.pathname) {
          //   name = prop.name;
          // }
          const isPathMatch = matchPath(props.location.pathname, {
            path: prop.path,
            exact: true,
            strict: false
          })
          if (isPathMatch) {
            name = prop.name;
          }
          return null;
        });
      }
      // remove this later
      // if (prop.path === props.location.pathname) {
      //   name = prop.name;
      // }
      const isPathMatch = matchPath(props.location.pathname, {
        path: prop.path,
        exact: true,
        strict: false
      })
      if (isPathMatch) {
        name = prop.name;
      }
      return null;
    });
    if (name) {
      return name;
    } else {
      return "Default Brand Name";
    }
  }
  const { classes, color, rtlActive } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive
    });
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown implementation="css">
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <Button
                justIcon
                color="white"
                onClick={props.sidebarMinimize}
                style={{border:"none", boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)'}}
              >
                <Menu className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
                <Button
                  justIcon
                  style={{border:"none", boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)'}}
                  color="white"
                  onClick={props.sidebarMinimize}
                >
                  <Menu className={classes.sidebarMiniIcon} />
                </Button>
              )}
          </div>
        </Hidden>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button className={classes.title} color="transparent">
            {makeBrand()}
          </Button>
        </div>
        <Hidden smDown implementation="css">
          {/* <HeaderLinks rtlActive={rtlActive} /> */}
          <div style={{ paddingRight: "15px", position: "relative", top: "-12px" }}>
            <DomianList domains={[]}/>
          </div>
        </Hidden>
        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
            style={{
                //boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                border: 'solid 1px grey',
            }}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar >
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool
};

export default withStyles(headerStyle)(Header);
