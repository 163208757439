
// view of news lettter
import React, { PureComponent } from 'react';
import StartNewsLetter from './view/start-news-letter';

class NewsLetter extends PureComponent {
    render() {
        return (
            <StartNewsLetter />
        )
    }
}
export default NewsLetter;