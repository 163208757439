import React, { PureComponent } from "react";
import Button from "components/CustomButtons/Button.jsx";
import { connect } from 'react-redux';
import DltModal from '../components/dlt-modal';
import {dltMyWebsite,showMessage} from '../../website-management-actions';
import { fetchUserDomains, setActiveDomain } from "user-settings/user-settings-actions.js";
import { fetchFeaturePermision } from "login/login-actions";

class DltWebsiteView extends PureComponent {
  state = {
    openDltModal: false
  }
  toggleModal = () => {
    this.setState((state,props) => {
      this.setState({openDltModal : !state.openDltModal})
    })
  }
  getDomainName = () => {
    const { domainMap, activeDomain } = this.props;
    let domainName = '';
    try {
      domainName = domainMap[activeDomain][0]['fqdn']
    }
    catch (err) {
      domainName = ''
    }
    return domainName;
  }
  render() {
    return (
      <>
          <p >
          Once you delete your website, there is no going back. This will disable your website and delete all your website content and subscribers. We recommended exporting your newsletter email list if you want to reach out to your audience at any later time. Your account will still remain active and continue to work on other websites you are part of, or create a new one.
          </p >
          <Button color="secondary" onClick={this.toggleModal}>Delete</Button>
          {
            this.state.openDltModal && <DltModal
              openDltModal={this.state.openDltModal}
              toggleModal={this.toggleModal}
              domainName={this.getDomainName()}
              dltMyWebsite = {this.props.dltMyWebsite}
              fetchFeaturePermision = {this.props.fetchFeaturePermision}
              fetchUserDomains = {this.props.fetchUserDomains}
              setActiveDomain={this.props.setActiveDomain}
              showMessage ={this.props.showMessage}
            />
          }
      </>
    )
  }
}
const mapStateToProps = (state) => {
  const { activeDomain, domainMap } = state.login.user;
  return {
    activeDomain, domainMap 
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
     dltMyWebsite : ()=> dispatch(dltMyWebsite()),
     fetchUserDomains: () => dispatch(fetchUserDomains()),
     fetchFeaturePermision: () => dispatch(fetchFeaturePermision()),
     setActiveDomain: (domainId) => dispatch(setActiveDomain(domainId)),
     showMessage :(data,fun)=> dispatch(showMessage(data,fun))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DltWebsiteView);
