import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Button from "components/CustomButtons/Button.jsx";
import { setLiveArticlesPageNumber } from "../stories-action.js";

class ArticlesPagination extends PureComponent {
  loadPrevPageArticles = () => {
    this.props.setLiveArticlesPageNumber(this.props.pageNo - 1);
  }
  loadNextPageArticles = () => {
    this.props.setLiveArticlesPageNumber(this.props.pageNo + 1);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.pageNo !== nextProps.pageNo) {
      this.props.loadLiveStories();
    }
  }

  render() {
    const { articlelistLength, pageNo: currentPage } = this.props;
    let prevPageDisable = false;
    let nextPageDisable = false;

    if (articlelistLength < 10) {
      nextPageDisable = true;
    }
    if (currentPage === 1) {
      prevPageDisable = true;
    }
    if (currentPage === 1 && articlelistLength < 10) {
      return null;
    }

    return (
      <div style={{ textAlign: "center" }}>
        <Button
          style={{ marginRight: "10px" }}
          disabled={prevPageDisable}
          onClick={this.loadPrevPageArticles}
        >prev</Button>
        <Button
          disabled={nextPageDisable}
          onClick={this.loadNextPageArticles}
        >next</Button>
      </div >
    )
  }
}
const mapStateToProps = state => {
  return {}
}
const mapDispatchToProps = (dispatch) => {
  return {
    setLiveArticlesPageNumber: (pageNum) => dispatch(setLiveArticlesPageNumber(pageNum))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesPagination);
