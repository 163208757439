import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import ArticleList from "./views/article-list.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

class StoriesHomePage extends PureComponent {
  render() {
    return (
      <div>
        <Helmet title="Stories | PubNinja - You Publish. The rest we manage." />
        <ArticleList classes={this.props.classes} />
      </div>)
  }
}

export default withStyles(dashboardStyle)(StoriesHomePage);
