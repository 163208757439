import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import { fetchVariableTimeSpecificRevenues, setRevenuesDateRangesInterval } from "../revenue-actions.js";
import { VariableTimeSpecificRevenueTypes } from "../../../app-constants/index.js";
import { RevenueDataTypes } from "../../../app-constants/index.js";

class DailyECPMGraph extends PureComponent {
  componentDidMount() {
    this.fetchRevenues();
  }
  componentWillReceiveProps({ activeDomain }) {
    const { activeDomain: prevActiveDomain } = this.props;
    if (activeDomain !== prevActiveDomain) {
      this.fetchRevenues();
    }
  }

  fetchRevenues = () => {
    const { fetchDailyECPMRevenues } = this.props;
    fetchDailyECPMRevenues({ output: [RevenueDataTypes.ECPM] });
  }
  setDateRangeInterval = () => {
    const { setDateInterval } = this.props;
    setDateInterval({ output: [RevenueDataTypes.ECPM] });
  }
  render() {
    const { renderGraph } = this.props;
    return (
      <div>
        {renderGraph({
          ...this.props,
          graphDataType: RevenueDataTypes.ECPM,
          header: "eCPM (USD)"
        })}
      </div>
    )
  }
}

const mapStatToProps = state => {
  return {
    ...state.analysis.revenue.variableTimeSpecificRevenues.dailyECPMRevenues,
    activeDomain: state.login.user.activeDomain
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchDailyECPMRevenues: (options) => dispatch(fetchVariableTimeSpecificRevenues(VariableTimeSpecificRevenueTypes.DAILY_ECPM, options)),
    setDateInterval: (options) => dispatch(setRevenuesDateRangesInterval(VariableTimeSpecificRevenueTypes.DAILY_ECPM, options))
  }
}

export default connect(mapStatToProps, mapDispatchToProps)(DailyECPMGraph);
