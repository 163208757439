import React, { PureComponent } from "react";
import { VictoryTheme, VictoryChart, VictoryLine, VictoryGroup, VictoryVoronoiContainer, VictoryScatter, VictoryZoomContainer, VictoryTooltip, VictoryAxis, round } from "victory";
import moment from 'moment';
import { RevenueDataTypes } from "../../../app-constants/index.js";


const RevenueGraphFieldmapping = {
  [RevenueDataTypes.ECPM]: {
    y: "pre_cpm"
  },
  [RevenueDataTypes.REVENUE]: {
    y: "total_revenue"
  },
}

class RevenueGraph extends PureComponent {
  constructor(props) {
    super(props);
  }
  componentWillReceiveProps(nextProps) {
    const { startDate: nextStartDate, endDate: nextEndDate } = nextProps;
    const { startDate, endDate } = this.props;
    if (startDate !== nextStartDate || endDate !== nextEndDate) {
      this.setState({
        startDate: nextStartDate,
        endDate: nextEndDate
      })
    }
  }
  render() {
    const { revenues, graphDataType } = this.props;
    let data = revenues.map(({ date: a, [RevenueGraphFieldmapping[graphDataType]["y"]]: b }) => {
      b = Number(b).toFixed(2);
      return {
        x: moment(a).toDate(),
        y: Number(b),
      }
    });

    let minY = data[0].y;
    let maxY = data[0].y;
    data.forEach(({ y }) => {
      if (minY > y) {
        minY = y;
      }
      if (maxY < y) {
        maxY = y;
      }
    });


    return (
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
        <VictoryChart
          padding={{ top: 30, right: 40, bottom: 30, left: 78 }}
          width={600}
          height={470}
          theme={VictoryTheme.material}
          animate={{ duration: 1000 }}
          scale={{ x: "time" }}
        >
          <VictoryGroup
            color="grey"
            labels={(d) => {
              const date = moment(d.x).format("Do[,]MMM");
              return `${date}, ${d.y}`
            }}
            labelComponent={
              <VictoryTooltip
                flyoutStyle={{
                  stroke: "#fb4d24",
                  strokeWidth: 1,
                  fill: "white",
                  pointerEvents: "none"
                }}

                style={{ fontSize: 18, background: "green" }}
              />
            }
            data={data}
            domain={{ y: [minY - (maxY - minY) / 6, maxY + (maxY - minY) / 10] }}
          >
            <VictoryLine
              interpolation="natural"
            />
            <VictoryScatter
              size={(d, a) => { return a ? 8 : 5; }}
              style={{ data: { fill: "#fb4d24" } }}
            />

          </VictoryGroup>
          <VictoryAxis
            theme={VictoryTheme.material}
            style={{
              tickLabels: { fontSize: 16 }
            }}
          />
          <VictoryAxis
            orientation="left"
            theme={VictoryTheme.material}
            dependentAxis
            label={`( ${graphDataType} )`}
            padding={10}
            style={{
              tickLabels: { fontSize: 16 },
              axisLabel: { fontSize: 18, fontWeight: 'bold', padding: 50, overflow: "visible" }
            }} />
        </VictoryChart>
      </div>
    )
  }
}

export default RevenueGraph;