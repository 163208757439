const FeedAction = {
  LOAD: {
    FEED: {
      TWITTER: {
        REQUEST: "FeedAction.Load.Feed.Twitter.Request",
        SUCCESS: "FeedAction.Load.Feed.Twitter.Success",
        FAILURE: "FeedAction.Load.Feed.Twitter.Failure",
      },
      INSTA: {
        REQUEST: "FeedAction.Load.Feed.Insta.Request",
        SUCCESS: "FeedAction.Load.Feed.Insta.Success",
        FAILURE: "FeedAction.Load.Feed.Insta.Failure",
      },
      YOUTUBE: {
        REQUEST: "FeedAction.Load.Feed.Youtube.Request",
        SUCCESS: "FeedAction.Load.Feed.Youtube.Success",
        FAILURE: "FeedAction.Load.Feed.Youtube.Failure",
      }
    }
  },
  MOVE: {
    FEED: {
      TWITTER: "FeedAction.Move.Feed.Twitter",
      INSTA: "FeedAction.Move.Feed.Insta",
      YOUTUBE: "FeedAction.Move.Feed.Youtube",
    },
    UNHIGHLIGHT: {
      TWITTER: "FeedAction.Unhighlight.Feed.Twitter",
      INSTA: "FeedAction.Unhighlight.Feed.Insta",
      YOUTUBE: "FeedAction.Unhighlight.Feed.Youtube",
    }
  },
  RESET: {
    FEED:'FeedsAction.Reset.Feed'
  },
  CHANNELS: {
    ADD: "FeedsAction.Load.Channels.Add",
    ADDING: "FeedsAction.Load.Channels.Adding",
    DELETE: "FeedsAction.Load.Channels.Delete",
    REQUEST: "FeedsAction.Load.Channels.Request",
    SUCCESS: "FeedsAction.Load.Channels.Success",
    FAILURE: "FeedsAction.Load.Channels.Failure",
  }
}
export default FeedAction;