import React from "react";
import moment from "moment";
import KeyboardArrowDownSharp from '@material-ui/icons/KeyboardArrowDownSharp';

function formatDateDisplay(startDate, endDate, defaultText) {
  let date = "";
  if (!startDate) {
    date = defaultText;
  } else {
    date = startDate.format("Do[, ]MMM[ -- ]")
  }
  if (!endDate) {
    date += defaultText;
  } else {
    date += endDate.format("Do[, ]MMM")
  }
  return date;
}


const SelectedDateRange = ({ startDate, endDate, onClick, showPstDate }) => {
  if (!moment(new Date(startDate)).isValid() && !moment(new Date(endDate)).isValid()) {
    return (
      <div style={{ marginBottom: "30px" }}>
        <input style={{ paddingLeft:"10px",  fontSize: "16px", paddingRight: "20px", cursor: "pointer", fontWeight: "bold", width: "auto", fontFamily:"Source Sans Pro, sans-serif" }} onClick={onClick} value={`Select Date`} readOnly />
        <span style={{ fontSize: "16px", marginLeft: "10px", fontWeight: "Bold" }}>{showPstDate ? '' : `( All Dates in PST )`}</span>
      </div>
    )
  }
  return (
    <div style={{ marginBottom: "30px" }}>
      <input style={{ paddingLeft:"10px", fontSize: "16px", paddingRight: "20px", cursor: "pointer", fontWeight: "bold", width: "auto", fontFamily:"Source Sans Pro, sans-serif" }} onClick={onClick} value={formatDateDisplay(startDate, endDate, "Continue")} readOnly />
      <span style={{ fontSize: "16px", marginLeft: "10px", fontWeight: "Bold" }}>{showPstDate ? '' : `( All Dates in PST )`}</span>
    </div>
  )
}
export default SelectedDateRange;
