import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import feedStyle from "../../../assets/jss/material-dashboard-pro-react/components/feedStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import landingPageStyle from "../../../assets/jss/material-dashboard-pro-react/views/landingPageStyle";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import NewsRoomCard from "../components/news-room-card.jsx";
import Search from "../components/search.jsx";
import AppLoader from "../../../app-components/app-loader.jsx";
import CategorySelect from "../components/category-selector.jsx";
import NoDataFound from "../../../app-components/no-data-found.jsx";
import NewsRoomModal from '../components/news-room-modal.jsx';
import debounce from "lodash.debounce";
import RoundButton from '../../../components/RoundButton/roundbutton';
import ProgressModal from "../components/progress-modal";
import Button from "components/CustomButtons/Button";
import {
  loadNewsRoomArticles,
  loadNewsRoomCategories,
  activeCategorie,
  setCardId,
  setLiveArticlesPageNumber,
  openNewsRoomModal,
  closeNewsRoomModal,
  saveToDraft,
  startSearch,
  unmountRoutine,
  refreshArticles,
  toggleProgressModal
} from "../news-room-actions.js";

class associatedPressContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      categoryValue: "",
      topButtonShow: false,
      newsRoomData: []
    };
    this.loadArticlesOnScroll = debounce(this.loadArticlesOnScroll, 200);
    this.loadButton = debounce(this.loadButton, 0);
  }

  loadArticlesOnScroll = () => {
    this.props.setLiveArticlesPageNumber(this.props.pageNo + 1);
    this.props.loadNewsRoomArticles();
  }

  loadButton = () => {
    const scrolledTop = document.getElementById("mainPanel").scrollTop
    if (scrolledTop > 5) {
      if (!this.state.topButtonShow) {
        this.setState({ topButtonShow: true })
      }
    }
    else {
      if (this.state.topButtonShow) {
        this.setState({ topButtonShow: false })
      }
    }
  }

  fetchArticles = () => {
    const windowHeight = window.innerHeight;
    let rect = document.getElementById('scrollFixer').getBoundingClientRect().top;
    if (rect - window.innerHeight < 100) {
      this.loadArticlesOnScroll();
    }
  }

  componentDidMount() {
    document.getElementById("mainPanel").addEventListener('scroll', this.fetchArticles);
    document.getElementById("mainPanel").addEventListener('scroll', this.loadButton);

    this.props.loadNewsRoomCategories();

  }

  componentWillUnmount() {
    //reset the state values while leaving the newsroom page
    this.props.unmountRoutine();
    document.getElementById("mainPanel").removeEventListener('scroll', this.fetchArticles);
    document.getElementById("mainPanel").removeEventListener('scroll', this.loadButton);
  }

  topFunction = () => {
    document.getElementById("mainPanel").scroll({ top: 0, behavior: 'smooth' });
  }

  handleClick = (id) => {
    if (this.props.showModal === false)
      this.props.openNewsRoomModal(id);
    else {
      this.props.closeNewsRoomModal();
    }
  }

  startSearch = (searchText) => {
    this.props.startSearch(searchText);
  }

  render() {
    const {
      classes,
      categories = [],
      activeCategorie,
      isLoading,
      loadNewsRoomArticles,
      setLiveArticlesPageNumber,
      newsRoomData = [],
      articlesData = [],
      categoryId,
      categoryName,
      setCardId,
      pageNo,
      openNewsRoomModal,
      closeNewsRoomModal,
      showModal,
      modalData,
      saveToDraft,
      draftButtonMessage,
      refreshArticles,
      searchState,
      toggleProgressModal,
      showDraftProgressModal,
      progressModalData,
    } = this.props;

    return (
      <div style={{ minHeight: '400px' }}>
        {this.state.topButtonShow &&
          <RoundButton
            onclick={this.topFunction}
            type="arrow_upward"
            buttonbackground="#ced3dc"
            location={{
              right: '30px',
              bottom: "95px",
              position: 'fixed',
              border:'0',
              background: '#ced3dc',
              cursor: "pointer",
              zIndex: 9999
            }}
            iconcolor="#2b2d42"
          />}
        <Search startSearch={this.startSearch} />
        <CategorySelect
          categories={categories}
          activeCategorie={activeCategorie}
          loadNewsRoomArticles={loadNewsRoomArticles}
          categoryValue={categoryName}
        />
        <Button onClick={refreshArticles} color='primary'>REFRESH</Button>
        {(!isLoading && (!articlesData || articlesData.length === 0)) && <NoDataFound dataTypeName="Media" />}
        {(isLoading) && <AppLoader message="Articles Loading..." />}

        <div id="newsroom-container" className={classes.lpContainer} style={{ justifyContent: 'center', margin: '0' }}>
        <GridContainer justify="center">
          {articlesData && articlesData.length > 0 && articlesData.map((item, index) => {
            return (
              <GridItem xs={12} md={6} key={index}>
              <NewsRoomCard
                item={item}
                searchText={this.state.searchText}
                handleClick={() => { this.handleClick(item.item.altids.itemid) }}
              />
              </GridItem>
            );
          })}
        </GridContainer>
        </div>
        <div id="scrollFixer" style={{ fontSize: '1.5em' }}>
          {(!isLoading && (articlesData && articlesData.length > 0)) && <AppLoader message="Articles Loading..." />}
        </div>
        <NewsRoomModal showModal={showModal} modalData={modalData} handleClick={this.handleClick} saveToDraft={saveToDraft} draftButtonMessage={draftButtonMessage} style={{ maxWidth: '100%' }} />
        <ProgressModal
          showDraftProgressModal={showDraftProgressModal}
          progressModalData={progressModalData}
          toggleProgressModal={toggleProgressModal}>
        </ProgressModal>
      </div>
    );
  }
}

const mapStateToProps = store => {
  const {
    newsRoom: {
      newsRoomReducer: { isLoading, pageNo, categories, newsRoomData, articlesData, categoryName, categoryId, searchState, showDraftProgressModal, progressModalData },
      newsRoomSetting: { token, showModal, modalData, draftButtonMessage }
    },

  } = store.cms;

  return {
    categories,
    isLoading,
    newsRoomData,
    articlesData,
    categoryName,
    categoryId,
    pageNo,
    showModal,
    modalData,
    draftButtonMessage,
    searchState,
    showDraftProgressModal,
    progressModalData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadNewsRoomArticles: (categoryId) => dispatch(loadNewsRoomArticles()),
    loadNewsRoomCategories: () => dispatch(loadNewsRoomCategories()),
    activeCategorie: (categoryId, categoryName) => dispatch(activeCategorie(categoryId, categoryName)),
    setLiveArticlesPageNumber: (pageNo) => dispatch(setLiveArticlesPageNumber(pageNo)),
    openNewsRoomModal: (id) => dispatch(openNewsRoomModal(id)),
    closeNewsRoomModal: (id) => dispatch(closeNewsRoomModal(id)),
    saveToDraft: (id) => dispatch(saveToDraft(id)),
    startSearch: (searchText) => dispatch(startSearch(searchText)),
    unmountRoutine: () => dispatch(unmountRoutine()),
    refreshArticles: () => dispatch(refreshArticles()),
    toggleProgressModal: () => dispatch(toggleProgressModal())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(landingPageStyle)(associatedPressContainer));
