const defaultAccountInfoState = {

  isSubmittingInfo: false,
  isSubmittingOtp: false,
  showOtpModal: false,
  otpError: '',
  otpVerified: false,
  blacklistedBanks: [],
  agreementHtml: null,
  bankDetails: null,
  fetchingBankDetails: false,
  features: null,
  subscriptionEnabled: null,
  adsEnabled: false,
  isFetchingAdsStatus: false,
  isFetchingFeatures: false,
  isSettingFeatures: false,
  submittingAgreement: false,
  agreementSubmitted: false,

};

const AccountInfoReducer = (state = defaultAccountInfoState, action) => {
  switch(action.type) {
    case 'ACCOUNT_INFO_SUBMIT_REQUEST': {
      return {...state, isSubmittingInfo: true}
    }
    case 'ACCOUNT_INFO_SUBMIT_SUCCESS': {
      return {...state, isSubmittingInfo: false, agreementHtml: action.data}
    }
    case 'ACCOUNT_INFO_SUBMIT_FAILURE': {
      return {...state, isSubmittingInfo: false}
    }
    case 'OTP_MODAL_OPEN': {
      return {...state, showOtpModal: true}
    }
    case 'OTP_MODAL_CLOSE': {
      return {...state, showOtpModal: false}
    }
    case 'OTP_GENERATE_REQUEST': {
      return {...state}
    }
    case 'OTP_GENERATE_SUCCESS': {
      return { ...state }
    }
    case 'OTP_GENERATE_FAILURE': {
      return {...state, otpError: action.data}
    }
    case 'OTP_VERIFY_REQUEST': {
      return {...state, isSubmittingOtp: true}
    }
    case 'OTP_VERIFY_SUCCESS': {
      return { ...state, isSubmittingOtp: false, otpVerified: true, }
    }
    case 'OTP_VERIFY_FAILURE': {
      return {...state, isSubmittingOtp: false, otpError: action.data}
    }
    case 'BLACKLISTED_BANKS_SET': {
      return {...state, blacklistedBanks: action.data}
    }
    case "FEATURES_FETCH_REQUEST": {
      return {...state, isFetchingFeatures: true}
    }
    case "FEATURES_FETCH_FAILURE": {
      return {...state, isFetchingFeatures: false}
    }
    case 'FEATURES_FETCH_SUCCESS': {
      return {...state, isFetchingFeatures: false, subscriptionEnabled: action.data.subscriptionEnabled, features: action.data}
    }
    case "FEATURES_SET_REQUEST": {
      return {...state, isSettingFeatures: true}
    }
    case "FEATURES_SET_FAILURE": {
      return {...state, isSettingFeatures: false}
    }
    case 'FEATURES_SET_SUCCESS': {
      return {...state, isSettingFeatures: false}
    }
    case 'AGREEMENT_SUBMIT_REQUEST': {
      return {...state, submittingAgreement: true}
    }
    case 'AGREEMENT_SUBMIT_SUCCESS': {
      return {...state, submittingAgreement: false, agreementSubmitted: true}
    }
    case 'AGREEMENT_SUBMIT_FALSE': {
      return {...state, submittingAgreement: false}
    }
    case 'BANK_DETAILS_REQUEST': {
      return {...state, fetchingBankDetails: true}
    }
    case 'BANK_DETAILS_SUCCESS': {
      return {...state, fetchingBankDetails: false, bankDetails: action.data}
    }
    case 'BANK_DETAILS_FAILURE': {
      return {...state, fetchingBankDetails: false}
    }
    case 'GET_ADS_REQUEST': {
      return{...state, isFetchingAdsStatus: true}
    }
    case 'GET_ADS_SUCCESS': {
      const { enabled: adsEnabled } = action.data;
      return { ...state, isFetchingAdsStatus: false, adsEnabled };
    }
    case 'GET_ADS_FAILURE': {
      return { ...state, isFetchingAdsStatus: false };
    }
    default: return state;
  }
}

export default AccountInfoReducer;
