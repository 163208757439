import { UserAction, LocalStorageKeys, UserPermisssionAction } from "../app-constants/index.js";
import { ConfirmPasswordAction } from "../app-constants/index.js";

const getUserDataFromLocalStorage = () => {
  const userDetails = localStorage.getItem(LocalStorageKeys.USER_DETAILS);
  if (userDetails) {
    return JSON.parse(userDetails);
  }
  return {}
}

const loginDefaultValues = {
  isFetching: false,
  isAuthenticated: false,
  formData: {
    email: "",
    password: "",
  },
  isDoingSignup: false,
  isDoingSignout: false,
  resettingPassword: false,
  updatingPassword: false,
  user: { ...getUserDataFromLocalStorage(), containingCompleteDetails: false },
  domain: { featurePermision:[], featurePermissionLoaded: false },
  confirmPasswordLoader: false,
  forgotPassword: false,
  showSignupOTPModal: false,
  signupEmail: "",
}


function login(state = loginDefaultValues, action) {
  switch (action.type) {
    //resetting
    case UserAction.SIGN_OUT.SUCCESS: {
      return loginDefaultValues;
    }

    case UserAction.SIGNUP.REQUEST:
      return { ...state, isDoingSignup: true }
    case UserAction.SIGNUP.SUCCESS:
      return { ...state, isDoingSignup: false }
    case UserAction.SIGNUP.FAILURE:
      return { ...state, isDoingSignup: false }

    case UserAction.SIGNIN.REQUEST:
      return { ...state, isFetching: true, isAuthenticated: false };
    case UserAction.SIGNIN.FAILURE:
      return { ...state, isFetching: false, isAuthenticated: false };
    case UserAction.SIGNIN.SUCCESS: {
      const {
        firstName,
        lastName,
        profilePicture,
        coverPictureUrl,
        phoneNumber,
        facebookProfile,
        twitterProfile,
        ownWebsite,
        activeDomain,
        permissions,
        id,
        email

      } = action.userDetails;
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        user: {
          firstName,
          lastName,
          profilePicture,
          coverPictureUrl,
          phoneNumber,
          facebookProfile,
          twitterProfile,
          ownWebsite,
          activeDomain,
          permissions,
          id,
          email
        }
      }
    }

    // case UserPermisssionAction.FETCH.PREMISSION.SUCCESS: {
    //   const { userId, permissions } = action;



    //   const userListWithPermission = {};
    //   Object.values(state.userList).forEach((user) => {
    //     if (user.id == userId) {
    //       userListWithPermission[userId] = {
    //         ...user,
    //         permissions: permissions
    //       }
    //     } else {
    //       userListWithPermission[user.id] = user;
    //     }
    //   });
    //   return {
    //     ...state,
    //     loadingUserPermissions: false,
    //     userList: userListWithPermission
    //   }
    // }

    case UserAction.RESET_PASSWORD.REQUEST:
      return { ...state, resettingPassword: true };
    case UserAction.RESET_PASSWORD.FAILURE:
      return { ...state, resettingPassword: false };
    case UserAction.RESET_PASSWORD.SUCCESS:
      return { ...state, resettingPassword: false };

    case UserAction.VERIFYING_OTP.REQUEST:
      return { ...state, verifyingOtp: true };
    case UserAction.VERIFYING_OTP.SUCCESS:
      return { ...state, verifyingOtp: false };
    case UserAction.VERIFYING_OTP.FAILURE:
      return { ...state, verifyingOtp: false };

    case UserAction.UPDATE_PASSWORD.REQUEST:
      return { ...state, updatingPassword: true };
    case UserAction.UPDATE_PASSWORD.SUCCESS:
      return { ...state, updatingPassword: false };
    case UserAction.UPDATE_PASSWORD.FAILURE:
      return { ...state, updatingPassword: false };

    case UserAction.SET.FORGOT_PASSWORD:
      return { ...state, forgotPassword: action.forgotPassword };

    case UserAction.SET.FORM_DATA: {
      const { formData } = action;
      return { ...state, formData: { ...state.formData, ...formData } };
    }
    case UserAction.SET.SIGNUP_OTP_MODAL:
      return { ...state, showSignupOTPModal: action.status };
    case UserAction.SET.SIGNUP_EMAIL:
      return { ...state, signupEmail: action.email };

    case UserAction.SIGN_OUT.REQUEST:
      return { ...state, isDoingSignout: true };
    case UserAction.SIGN_OUT.SUCCESS:
      return { ...loginDefaultValues, isDoingSignout: false };
    case UserAction.SIGN_OUT.FAILURE:
      return { ...loginDefaultValues, isDoingSignout: false };
    case UserAction.USER_DATA.UPDATE: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.userDetails,
          containingCompleteDetails: true
        }
      };
    }

    case UserAction.SET.USER_DATA.SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.userDetails,
          containingCompleteDetails: true,
        },
        isAuthenticated: true,
      };
    }

    case UserAction.SET.USER_DATA.FAILURE: {
      return {
        ...state,
        isAuthenticated: false
      };
    }

    case UserAction.FETCH.DOMAINS.SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          domainMap: action.domainMap,
        },
      }
    }

    case UserAction.SET.ACTIVE_DOMAIN: {
      return {
        ...state,
        user: {
          ...state.user,
          activeDomain: action.domainId
        },
      };
    }
    case ConfirmPasswordAction.SET.CONFIRM_PASSWORD.REQUEST: {
      return { ...state, confirmPasswordLoader: true }
    }
    case ConfirmPasswordAction.SET.CONFIRM_PASSWORD.FAILURE: {
      return { ...state, confirmPasswordLoader: false }
    }
    case ConfirmPasswordAction.SET.CONFIRM_PASSWORD.SUCCESS: {
      return { ...state, confirmPasswordLoader: false }
    }
    case UserAction.DOMAIN_PERMISION.SUCCESS: {
      const { featurePermision } = action;
      return {
        ...state,
        domain: {
          ...state.domain,
          featurePermision,
          featurePermissionLoaded:true,

        }
      }
    }
    default: return state;
  }
}

export default login;
