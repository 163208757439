const feedStyle = {
  feedbar: {
    padding: '20px !important',
    fontSize: '16px',
    background: '#5D737E',
    color: 'white',
    textAlign: "center",
    fontWeight: 'bold'
  },
  instaCard: {
    width: '250px !important',
    minWidth: '250px !important'
  },
  hightlight: {
    color: '#42a5f5',
    cursor: 'pointer'
  }

}
export default feedStyle;
