import React, { PureComponent } from 'react';

class Search extends PureComponent {
  getValueFromInput = (e) => {
    try {
      this.props.setSearch(e.currentTarget.value.trim())
    }
    catch{

    }
  }
  render() {
    return (
      <div style={{ position: 'relative', marginTop: "15px", marginRight: '4.5%' }}>
        <input
          id="liveArticleSearch"
          onChange={(e) => { this.getValueFromInput(e) }}
          style={{
            width: "100%",
            height: "32px",
            backgroundColor: "#f5f5f5",
            paddingLeft: "10px",
            fontSize: "16px",
            border: '1px solid #ced3dc',
            color: '#2B2D42'
          }} />
        <div style={{ position: "absolute", right: '15px', top: '5px', cursor: "pointer" }}>
          <i className="material-icons" style={{ color: '#2b2d42' }} onClick={(e) => { this.getValueFromInput(e) }}>
            search
            </i>
        </div>
      </div>
    )
  }
}
export default Search;
