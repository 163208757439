// import {
//   primaryColor,
//   primaryBoxShadow
// } from "assets/jss/material-dashboard-pro-react.jsx";

const primaryColor = '#fdfaf5 !important';
const primaryBoxShadow = 'none !important'

const customSelectStyle = {
  select: {
    padding: "12px 20px 7px",
    // fontSize: ".75rem",
    fontWeight: "600",
    lineHeight: "1.42857",
    textDecoration: "none",
    textTransform: "lowercase",
    color: '#2b2d42',
    letterSpacing: "0.01em",
    "&:focus": {
      backgroundColor: "transparent"
    },
    "&[aria-owns] + input + svg": {
      transform: "rotate(180deg)"
    },
    "& + input + svg": {
      transition: "all 300ms linear"
    },
    border: `solid 1px #ced3dc`,
    backgroundColor: '#ffffff',
    /* color: black, */
    height: '24px',
    borderRadius: '3px',
    /* padding-top: 8px, */
    marginTop: '15px',
    color: '#5d737e',
    fontSize:'12px'
  },
  selectFormControl: {
    margin: "10px 1px 10px 0px !important",
    "& > div": {
      "&:before": {
        borderBottomWidth: "1px !important",
        borderBottomColor: "#D2D2D2 !important"
      },
      "&:after": {
        borderBottomColor: primaryColor + "!important"
      }
    }
  },
  selectLabel: {
    fontSize: "12px",
    textTransform: "uppercase",
    color: "#3C4858",
    top: "8px",
    // color: "#5d737e",
    textAlign: "center",
    fontSize: '16px',
    lineHeight: '1.25',
    fontWeight: '500',
    letterSpacing: '0.84px',
    color: '#5d737e'
  },
  selectMenu: {
    "& > div > ul": {
      border: "0",
      padding: "5px 0",
      margin: "0",
      boxShadow: "none",
      minWidth: "100%",
      borderRadius: "4px",
      boxSizing: "border-box",
      display: "block",
      fontSize: "14px",
      textAlign: "left",
      listStyle: "none",
      backgroundColor: "#fff",
      backgroundClip: "padding-box",
    },
    "& $selectPaper $selectMenuItemSelectedMultiple": {
      backgroundColor: "inherit"
    },
    "& > div + div": {
      maxHeight: "266px !important"
    }
  },
  selectMenuItem: {
    fontSize: "12px",
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "2px",
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    fontWeight: "600",
    lineHeight: "1.25",
    letterSpacing: "0.84",
    whiteSpace: "nowrap",
    color: "#5d737e",
    paddingRight: "30px",
    textTransform: 'lowercase',
    "&:hover": {
      backgroundColor: primaryColor,
      color: "#2B2D42",
      fontWeight: '600',
      ...primaryBoxShadow
    }
  },
  selectMenuItemSelected: {
    backgroundColor: primaryColor + "!important",
    color: "#2B2D42"
  },
  selectMenuItemSelectedMultiple: {
    backgroundColor: "transparent !important",
    "&:hover": {
      backgroundColor: primaryColor + "!important",
      color: "#FFFFFF",
      ...primaryBoxShadow,
      "&:after": {
        color: "#2B2D42"
      }
    },
    "&:after": {
      top: "16px",
      right: "12px",
      width: "12px",
      height: "5px",
      borderLeft: "2px solid currentColor",
      transform: "rotate(-45deg)",
      opacity: "1",
      color: "#3c4858",
      position: "absolute",
      content: "''",
      borderBottom: "2px solid currentColor",
      transition: "opacity 90ms cubic-bezier(0,0,.2,.1)"
    }
  },
  selectPaper: {
    boxSizing: "borderBox",
    borderRadius: "4px",
    padding: "0",
    minWidth: "100%",
    display: "block",
    border: "0",
    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.26)",
    backgroundClip: "padding-box",
    margin: "2px 0 0",
    fontSize: "14px",
    textAlign: "left",
    listStyle: "none",
    backgroundColor: "transparent",
    maxHeight: "266px",
    color: "#5d737e",
    textAlign: "center",
    fontSize: '16px',
    lineHeight: '1.25',
    fontWeight: '500',
    letterSpacing: '0.84px',
    border: 'solid 1px #ced3dc',
    backgroundColor: '#ffffff',
    /* color: black, */
    height: '40px',
    borderRadius: '3px',
    /* padding-top: 8px, */
    marginTop: '27px',
    boxSizing: 'unset',
  },
  icon: {
    fill: 'white',
    width: '0.8em'
  },
  iconMargin:{
    marginTop:'5px'
  }
};

export default customSelectStyle;
