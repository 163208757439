import { MeawwApi } from "../../utils/api.js";
import { setAppMessage } from "../../app-actions.js";
import { MessageTypes } from "../../app-constants/index.js";
import { FeedsAction } from "../../app-constants/index.js";
import { showNotification, askForNotificationPermission } from "../../utils/utils.js";


function getFeedType(feedType) {
  if (feedType === 'twitter') {
    return FeedsAction.LOAD.FEED.TWITTER.SUCCESS
  }
  if (feedType === 'instagram') {
    return FeedsAction.LOAD.FEED.INSTA.SUCCESS
  }
  if (feedType === 'youtube') {
    return FeedsAction.LOAD.FEED.YOUTUBE.SUCCESS
  }
}
function getFeedMoveType(feedType) {
  if (feedType === 'twitter') {
    return FeedsAction.MOVE.FEED.TWITTER
  }
  if (feedType === 'instagram') {
    return FeedsAction.MOVE.FEED.INSTA
  }
  if (feedType === 'youtube') {
    return FeedsAction.MOVE.FEED.YOUTUBE
  }
}
function getUnhighlightAction(feedType) {
  if (feedType === 'twitter') {
    return FeedsAction.MOVE.UNHIGHLIGHT.TWITTER
  }
  if (feedType === 'instagram') {
    return FeedsAction.MOVE.UNHIGHLIGHT.INSTA
  }
  if (feedType === 'youtube') {
    return FeedsAction.MOVE.UNHIGHLIGHT.YOUTUBE
  }
}
function MoveFeedAction(feedType, id) {
  return {
    type: getFeedMoveType(feedType),
    id
  }
}
function MoveUnhighlightFeedAction(feedType, id) {
  return {
    type: getUnhighlightAction(feedType),
    id
  }
}
function getNotification(feedType) {
  if (feedType === 'twitter') {
    return "New twitte coming"
  }
  if (feedType === 'instagram') {
    return "New post coming"
  }
  if (feedType === 'youtube') {
    return "New video coming"
  }
}
function getLoadFeedRequest(feedType) {
  if (feedType === 'twitter') {
    return FeedsAction.LOAD.FEED.TWITTER.REQUEST
  }
  if (feedType === 'instagram') {
    return FeedsAction.LOAD.FEED.INSTA.REQUEST
  }
  if (feedType === 'youtube') {
    return FeedsAction.LOAD.FEED.YOUTUBE.REQUEST
  }
}
function loadFeedSuccess(feedType, individual, highlights) {
  return {
    type: getFeedType(feedType),
    unheiglight: individual,
    highlight: highlights
  }
}
function resetFeedSuccess() {
  return {
    type: FeedsAction.RESET.FEED
  }
}
function loadFeedRequest(feedType) {
  return {
    type: getLoadFeedRequest(feedType)
  }
}
export default function getFeeds(feedType, updatesOnly) {

  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    dispatch(loadFeedRequest(feedType))
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/feeds/${activeDomain}/${feedType}/${updatesOnly ? '?updates=1' : ''}`,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            // askForNotificationPermission();
            // showNotification(getNotification(feedType))
            dispatch(loadFeedSuccess(feedType, data.result.unhighlights, data.result.highlights))
            return Promise.resolve(data.result);
          });
      })
  }
}

export function moveFeed(feedType, idStr, feedReducer, highlightType) {

  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    const { feeds } = getState().cms;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.put(`/feeds/${activeDomain}/${highlightType}/${feedType}`,
          feeds[feedReducer][`${highlightType === 'highlight' ? 'unheiglight' : 'highlight'}`][idStr]
          ,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            highlightType === 'highlight' ?
              dispatch(MoveFeedAction(feedType, idStr)) :
              dispatch(MoveUnhighlightFeedAction(feedType, idStr))
            dispatch(setAppMessage(data.result.message, { type: MessageTypes.SUCCESS }));
            return Promise.resolve(data.result);
          });
      })
  }
}

function requestChannels() {
  return {
    type: FeedsAction.CHANNELS.REQUEST,
  }
}
function removeChannel(channel) {
  return {
    type: FeedsAction.CHANNELS.DELETE,
    channel,
  }
}

function getChannelsSuccess(provider, channels) {
  return {
    type: FeedsAction.CHANNELS.SUCCESS,
    provider,
    channels
  }
}

function getChannelsFailure() {
  return {
    type: FeedsAction.CHANNELS.FAILURE,
  }
}

function addNewChannelRequest(provider) {
  return {
    type: FeedsAction.CHANNELS.ADDING,
    provider,
    adding: true,
  }
}
function addNewChannelDone(provider) {
  return {
    type: FeedsAction.CHANNELS.ADDING,
    provider,
    adding: false,
  }
}
function addNewChannel(channel) {
  return {
    type: FeedsAction.CHANNELS.ADD,
    channel,
  }
}

/**
 * get the list of channels for the given provider/
 * @param {*} provider 
 */
export function getChannels(provider) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    dispatch(requestChannels());
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/feeds/${activeDomain}/channels/${provider}`, (error, data) => {
          if (error) {
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
            dispatch(getChannelsFailure());
            return Promise.reject(error);
          }
          dispatch(getChannelsSuccess(provider, data.result.channels))
          return Promise.resolve(data.result);
        });
      })
  }
}

/**
 * Add new channel for given domain and user
 * @param {*} url 
 */
export function addChannel(provider, link, channelType = 'publisher') {
  return (dispatch, getState) => {
    dispatch(addNewChannelRequest(provider));
    const { activeDomain } = getState().login.user;
    if (!link.includes(provider)) {
      dispatch(addNewChannelDone(provider));
      dispatch(setAppMessage(`Please provide proper ${provider} link.`, { type: MessageTypes.ERROR }));
      return Promise.reject();
    }
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/feeds/${activeDomain}`, {
          link,
          channelType,
        }, (error, data) => {
          if (error) {
            dispatch(addNewChannelDone(provider));
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
            return Promise.reject(error);
          }
          const { channel } = data.result;
          dispatch(addNewChannelDone(provider));
          dispatch(addNewChannel(channel));
          return Promise.resolve(data.result);
        });
      })
  }
}

/**
 * Delete the channel from the given domain and user
 * @param {*} channel 
 * 
 */
export function deleteChannel(channel) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.delete(`/feeds/${activeDomain}/`, {
          channel,
        }, (error, data) => {
          if (error) {
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
            return Promise.reject(error);
          }
          dispatch(removeChannel(channel));
          return Promise.resolve(data.result);
        })
      })
  }
}

export function resetFeed() {
  return resetFeedSuccess()
}
