import React from 'react';
import {setAppMessage} from '../../../app-actions.js';
import { MessageTypes } from "../../../app-constants/index.js";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

class ErrorBoundary extends React.Component {
  state = { error: null, errorInfo: null };

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo
    })
    // You can also log error messages to an error reporting service here
  }

  redirectToStoriesPage = () => this.props.history.push('/landing');

  render() {
    if (this.state.errorInfo) {
      this.props.setAppMessage('Something went wrong. You will be redirected to the stories page.',
      { type: MessageTypes.ERROR, onOk: this.redirectToStoriesPage });

      // Error path
      return (
        <div>
          <div style={{textAlign: 'center'}}>Error with the article.</div>
        </div>
      );

    }
    // Normally, just render children
    return this.props.children;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAppMessage: (message, options) => dispatch(setAppMessage(message, options))
  }
}

export default withRouter(connect(null, mapDispatchToProps)(ErrorBoundary));
