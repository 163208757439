import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import UserPermissionDetails from "./views/user-permission-details.jsx";

class UserPermission extends PureComponent {
  render() {
    return (<div>
      <Helmet title=" User Management | PubNinja - You Publish. The rest we manage." />
      <UserPermissionDetails />
    </div>)
  }
}
export default UserPermission;