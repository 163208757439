const RegisterUserAction = {
    REGISTER: {
        NEW_USER: {
            REQUEST: "RegisterUserAction.Register.NewUser.Request",
            FAILURE: "RegisterUserAction.Register.NewUser.Failure",
            SUCCESS: "RegisterUserAction.Register.NewUser.Success"
        }
        ,
        CLEAR: {
            MSG: "RegisterUserAction.Clear.NewUser.Msg",
        }
    }
}

export default RegisterUserAction;