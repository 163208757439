import React, { PureComponent } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import AppLoader from "./app-loader.jsx";


class ModalLoader extends PureComponent {

    render() {
        const { message = '', isOpen } = this.props;
        return (
            <Dialog fullWidth={true} maxWidth={'sm'} open={isOpen}>
                <DialogContent>
                    <AppLoader message={message} />
                </DialogContent>
            </Dialog>

        )
    }
}
export default ModalLoader;