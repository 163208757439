import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import TinyMCEEditor from "../components/tinymce-editor.jsx";
import DraftjsEditor from "../components/draftjs-editor";
import ErrorBoundary from '../components/errorBoundary.js';


import { getArticleDetailsFromArticleId, getArticleContentFromArticleId } from "app-selector.js";

import {
  showBlockQuote,
  hideBlockQuote,
  showPiscoModal,
  hidePiscoModal,
  clearPiscoContent,
  updateEditorContent,
  setInitialState,
  updateApi,

  imageUploadingRequest,
  imageUploadingFailure,
  imageUploadingSuccess,

  setContentUpdatable,
  uploadImageInMCE



} from "../new-story-actions.js";

import { setAppMessage } from "app-actions.js";

import {
  setArticleId,
  loadArticleDetails
} from "../../article/article-action.js";

class StoryContent extends PureComponent {
  constructor(props) {
    super(props);
    this.tinyMCEEditor = React.createRef();
  }

  render() {
    const {
      articleId,
      shareType,
      isLoadingArticleContent,
      setContentUpdatable,
      domain,

      imageUplaoding = false,
      editorContent,
      updateEditorContent,
      setInitialState,
      updateApi,

      uploadFlipperedImages,
      uploadingImageFlipper,
      hideImageFlipper,
      showImageFlipper,
      showImageFlipperModal,

      showBlockQuote,
      hideBlockQuote,
      showBlockQuoteModal,
      appendBlockQuote,

      //image uploading
      uploadImageInMCE,
      imageUploadingRequest,
      imageUploadingSuccess,
      imageUploadingFailure,

      fetchingArticleDetails,
      showPiscoModal,
      hidePiscoModal,
      piscoContent,
      clearPiscoContent,
      activePiscoContentType,

      setAppMessage


    } = this.props;
    if (articleId === null || articleId === undefined) {
      return <div className="loader" />;
    }
    return (
      <div
        id="editor-container"
        className={imageUplaoding ? "img-uploading" : ""}
      >
        <ErrorBoundary>
          <TinyMCEEditor
            tinyMCEEditor={this.props.tinyMCEEditor}
            articleId={articleId}
            shareType={shareType}
            setContentUpdatable={setContentUpdatable}
            editorContent={editorContent}
            updateEditorContent={updateEditorContent}
            updateApi={updateApi}
            uploadFlipperedImages={uploadFlipperedImages}
            uploadingImageFlipper={uploadingImageFlipper}
            hideImageFlipper={hideImageFlipper}
            showImageFlipper={showImageFlipper}
            showImageFlipperModal={showImageFlipperModal}
            showBlockQuoteModal={showBlockQuoteModal}
            showBlockQuote={showBlockQuote}
            hideBlockQuote={hideBlockQuote}
            appendBlockQuote={appendBlockQuote}
            setInitialState={setInitialState}
            uploadImageInMCE={uploadImageInMCE}
            imageUploadingRequest={imageUploadingRequest}
            imageUploadingSuccess={imageUploadingSuccess}
            imageUploadingFailure={imageUploadingFailure}
            showPiscoModal={showPiscoModal}
            hidePiscoModal={hidePiscoModal}
            piscoContent={piscoContent}
            clearPiscoContent={clearPiscoContent}
            setAppMessage={setAppMessage}
            activePiscoContentType={activePiscoContentType}
            domain={domain}
          />
        </ErrorBoundary>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    newStory,
    articleSettings: {
      articleId,
      isLoadingArticleContent
    },
  } = state.cms;
  const {
    domain,
  } = state.login;

  const articleDetails = getArticleDetailsFromArticleId(state.cms, articleId);
  const { shareType = "meaww" } = articleDetails;
  return {
    articleId: articleId,
    shareType: shareType,
    isLoadingArticleContent: isLoadingArticleContent,
    editorContent: getArticleContentFromArticleId(state.cms, articleId),
    showBlockQuoteModal: newStory.showBlockQuoteModal,
    imageUploading: newStory.imageUploading,
    fetchingArticleDetails: newStory.articleDetails.fetchingArticleDetails,
    piscoContent: newStory.piscoContent,
    domain,
  }
}
const mapDispatchToProps = (dispatch) => ({
  setAppMessage: (msg, options) => dispatch(setAppMessage(msg, options)),

  showBlockQuote: () => dispatch(showBlockQuote()),
  hideBlockQuote: () => dispatch(hideBlockQuote()),

  showPiscoModal: () => dispatch(showPiscoModal()),
  hidePiscoModal: () => dispatch(hidePiscoModal()),
  clearPiscoContent: () => dispatch(clearPiscoContent()),

  updateEditorContent: content => dispatch(updateEditorContent(content)),

  setInitialState: () => dispatch(setInitialState()),
  updateApi: data => dispatch(updateApi(data)),
  setContentUpdatable: update => dispatch(setContentUpdatable(update)),


  imageUploadingRequest: () => dispatch(imageUploadingRequest()),
  imageUploadingSuccess: () => dispatch(imageUploadingSuccess()),
  imageUploadingFailure: () => dispatch(imageUploadingFailure()),

  setArticleId: articleId => dispatch(setArticleId(articleId)),
  loadArticleDetails: () => dispatch(loadArticleDetails()),

  //this is not properly implemented
  uploadImageInMCE: (formData, successCB, failureCB) =>
    dispatch(uploadImageInMCE(formData, successCB, failureCB))

})
export default connect(mapStateToProps, mapDispatchToProps)(StoryContent);
