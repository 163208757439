import React from 'react';

import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from "../../../components/CustomButtons/Button.jsx";
import AppLoader from "app-components/app-loader.jsx";

function Showstory(props) {
  const {story, changeStory} = props;
  return (
    <div style={{
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      zIndex: '1050',
      backgroundColor: 'black'
    }}>
      <img
        src={story.storyUrl}
        style={{objectFit: 'contain'}}
      />
      <CloseIcon
        style={{
          position: 'absolute',
          right: '20px',
          top: '20px',
          color: 'white',
          cursor: 'pointer',
        }}
        onClick={()=>{changeStory(null)}}
      />
      <div
       style={{
         position: 'absolute',
         bottom: '10px',
         display: 'flex',
       }}
      >
        <Button
          color="secondary"
          onClick={()=>{props.deleteStory(story.id)}}
        >
         DELETE
        </Button>
      </div>
    </div>
  )
}

export default Showstory;
