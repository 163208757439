import React, { PureComponent } from 'react';
import Pages from './views/pages';

class PagesHome extends PureComponent {
    render() {
        return (
            <Pages />
        )
    }
}
export default PagesHome