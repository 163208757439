import React from 'react';
import Button from "components/CustomButtons/Button.jsx";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import InformationTooltip from "../../../app-components/information-tooltip";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Articles from "../../../tool/editer-pick/views/articles";
import AppLoader from "../../../app-components/app-loader.jsx";
import Input from '@material-ui/core/Input';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import {
  URL_VALIDATION,
  FACEBOOK_VALIDATION,
  TWITTER_VALIDATION,
  INSTA_VALIDATION
} from "../../../app-constants/common.js";


const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 200,
    width: '95%'
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

const getKeyCondition = (id, val) => {
  if (id == 'fbUrl') {
    return !FACEBOOK_VALIDATION.test(val)
  }
  if (id == 'twitterUrl') {
    return !TWITTER_VALIDATION.test(val)
  }
  if (id == 'instaUrl') {
    return !INSTA_VALIDATION.test(val)
  }
  if (id == 'heroImage') {
    return !URL_VALIDATION.test(val)
  }
  return false
}

const validation = (error = {}, id, val) => {
  if (id == 'subject') {
    (val == '') ? error = { ...error, [id]: true } : error = { ...error, [id]: false }
    return { error, val }
  }
  if (id == 'preamble') {
    (val == '') ? error = { ...error, [id]: true } : error = { ...error, [id]: false }
    return { error, val }
  }
  return { error, val }
}

class AddCampagionDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSide: false,
      open: false,
      subject: null,
      preamble: null,
      article: [],
      articleTitle: [],
      isTest: false,
      isCreate: false,
      segment: this.props.segments || null,
      segmentVal :this.props.segments && this.props.segments.length > 0 && this.props.segments[0]|| '',
      error: {
        subject: false,
        preamble: false,
        segment: false,
      }
    };
    this.searchInput = React.createRef();
  }
  handleOpen = () => {
    this.setState({ ...this.state, open: true })
  }
  handleClose = () => {
    this.setState({ ...this.state, open: false })
  }
  hideSidePan = () => {
    this.setState({ ...this.state, showSide: false })
    document.getElementById('news-letter-modal').style.visibility = 'visible'
  }
  OpenshowSide = () => {
    this.setState({ ...this.state, showSide: true })
    setTimeout(() => {
      document.getElementById('news-letter-modal').style.visibility = 'hidden'
    }, 500)
  }
  handleChange = (e) => {
    const { val, error } = validation(this.state.error, e.target.id, e.target.value)
    let id = e.target.id || e.target.name
    if (id == 'segment') {
      this.setState({ ...this.state, error, segmentVal: val })
      return
    }
    this.setState({ ...this.state, [e.target.id]: val, error })
  }
  selectNewArticle = (newArticle) => {
    const { article, articleTitle } = this.state;

    if (!article.includes(newArticle.articleId)) {
      article.push(newArticle.articleId);
      articleTitle.push(newArticle.title)
    }
    this.setState({ ...this.state, article, articleTitle })
    this.hideSidePan();
  }
  ontestSubmit = () => {
    this.setState({ isTest: true, isCreate: false })
    const { article, preamble, subject,segmentVal } = this.state;
    const data = preamble ? { article, segment: [segmentVal.id], preamble, subject, trialCampaign: 1 }
      : { article, segment: [segmentVal.id], subject, trialCampaign: 1 }
    this.props.createCampaign(data);
  }
  oncreateSubmit = () => {
    this.setState({ isTest: false, isCreate: true })
    const { article, preamble, subject,segmentVal } = this.state;
    const data = preamble ? { article, segment: [segmentVal.id], preamble, subject, trialCampaign: 0 }
      : { article, segment: [segmentVal.id], subject, trialCampaign: 0 }
    this.props.createCampaign(data).then(
      ()=> {this.closeCampaignModal()}
    );
  }
  isValid = () => {
    const { subject, preamble } = this.state;
    return subject && preamble
  }
  closeCampaignModal = () => {
    this.setState({
      showSide: false,
      open: false,
      subject: null,
      preamble: null,
      article: [],
      articleTitle: [],
      isTest: false,
      isCreate: false,
      segment: this.props.segments || null,
      error: {
        subject: false,
        preamble: false,
        segment: false,
      }
    }, () => {
      this.props.closeAddModal();
    })
  }
  removeArticle = () => {
    const { article, articleTitle } = this.state;
    article.pop();
    articleTitle.pop();
    this.setState({ article, articleTitle })
  }
  render() {
    const { isOpenAddCampagionModal, isNewsLetterActionLoading,classes } = this.props;
    const { articleTitle, segment, preamble, subject, segmentVal } = this.state;

    return (
      <>
        <Dialog
          id="news-letter-modal"
          open={isOpenAddCampagionModal}
          onClose={this.closeCampaignModal}
          aria-labelledby="form-dialog-title"
        >
          {
            isNewsLetterActionLoading ?
            <div style={{width: '100%', overflow: 'hidden', padding: '20px'}}>
            <AppLoader />
            </div>
            :
            <>
              <DialogTitle id="form-dialog-title"> Create Campaign </DialogTitle>
              <DialogContent>
                <GridContainer>
                <GridItem xs={12}>
                <CustomInput
                  id="subject"
                  labelText="Subject"
                  infoText="Subject"
                  value={subject || ''}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    required: true,
                    onChange: this.handleChange,
                    multiline: true,
                    rowsMax: 15,
                  }}
                  error={this.state.error.subject}
                  success={!!this.state.subject && !this.state.error.subject}
                  helpText={this.state.error.subject ? `subject can't be empty` : ''}
                  margin="normal"
                />
                </GridItem>
                <GridItem xs={12} style={{marginTop: '-40px'}}>
                <CustomInput
                  id="preamble"
                  labelText="Preamble"
                  infoText="preamble"
                  value={preamble || ''}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    required: true,
                    onChange: this.handleChange,
                    multiline: true,
                    rowsMax: 15,
                  }}
                  error={this.state.error.preamble}
                  success={!!this.state.preamble && !this.state.error.preamble}
                  helpText={this.state.error.preamble ? `preamble can't be empty` : ''}
                  margin="normal"
                />
                </GridItem>
                <GridItem xs={12} style={{marginTop: '-40px'}}>
                <CustomInput
                  id="article"
                  labelText="Article"
                  infoText="Article"
                  value={articleTitle
                    .map((title) => title.length > 70 ? title.substr(0, 70) + '...' : title)
                    .join(' \n') || ''}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    endAdornment: (
                      <>
                        {(this.state.articleTitle.length >0 )&& <InputAdornment position="end">
                          <i  style={{ marginLeft: '5px', marginRight:'-15px',cursor: 'pointer' }} className="material-icons" onClick={this.removeArticle}>
                              clear
                          </i>
                        </InputAdornment>}
                        <InputAdornment position="end">
                          <AddCircleIcon style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={this.OpenshowSide} />
                        </InputAdornment>
                      </>
                    ),
                    onChange: this.handleChange,
                    multiline: true,
                    rowsMax: 100,
                  }}
                  error={this.state.error.article}
                  success={!!this.state.article.length && !this.state.error.article}
                  helperText={this.state.error.article ? 'Empty article not allowed' : ''}
                   margin="normal"
                />
                </GridItem>
    {/*
                <CustomInput
                  disable
                  id="segment"
                  labelText="Target Audience"
                  infoText="Target Audience"
                  value={(segment.length > 0 && segment[0].name) || ''}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: this.handleChange,
                    disabled: true,
                  }}
                   margin="normal"
                /> */}
                <GridItem xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink htmlFor="segment">
                  Target Audience
                  </InputLabel>
                  <Select
                    value={segmentVal.name}
                    renderValue={() => `${segmentVal.name}`}
                    onChange={this.handleChange}
                    inputProps={{
                      name: 'segment',
                      id: 'segment',
                    }}
                  >{
                    segment && segment.map((val, key) => <MenuItem key={key} value={val}>{val.name}</MenuItem>)
                  }

                  </Select>
               </FormControl>
               </GridItem>
               </GridContainer>
              </DialogContent>
              <DialogActions style={{margin: '0', padding: '0 24px'}}>
              <GridContainer justify="center">
              <GridItem xs={12} sm={4}>
                <div>
                  <Button onClick={this.closeCampaignModal} color="primary">
                    Cancel
                  </Button>
                </div>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                  <Button
                    disabled={!this.isValid()} color="secondary" onClick={this.ontestSubmit}>
                    TEST EMAIL
                  </Button>
                  {isNewsLetterActionLoading && this.state.isTest && <AppLoader size={20}/>}
                </div>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                  <Button disabled={!this.isValid()} color="secondary" onClick={this.oncreateSubmit}>
                    START CAMPAIGN
                  </Button>
                  {isNewsLetterActionLoading && this.state.isCreate && <AppLoader size={20}/>}
                </div>
              </GridItem>
              </GridContainer>
              </DialogActions>
            </>
          }

        </Dialog>
        <Articles
          ref={this.searchInput}
          showSide={this.state.showSide}
          hideSidePan={this.hideSidePan}
          selectNewArticle={this.selectNewArticle}
          text={'ADD'}
          title={'NewsLetter'}
          subtitle={'choose article'}
          isNewsLetter={true}
        />
      </>
    );

  }
}
export default withStyles(styles)(AddCampagionDialog);


function getLabelText(labelText, infoText) {
  return <span style={{ zIndex: 100000 }}>{labelText} <InformationTooltip text={infoText} /></span>
}
