import React, { PureComponent } from "react";

class CMSHomePage extends PureComponent {
  render() {
    return (<>
      {this.props.children}
    </>)
  }
}

export default CMSHomePage;