import React, { PureComponent } from 'react';
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import { setUserPassword } from "../confirm-password-actions.js";
import AppLoader from "../../app-components/app-loader.jsx";
import { fetchUserDetails, getUserDetails } from "../../user-settings/user-settings-actions";

class ConfirmPassword extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      cardAnimaton: "cardHidden",
      email: "",
      password: "",
      confirmPassword: "",
      error: {
        confirmPasswordError: "",
        passwordError: ""
      }
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  validatedata = () => {
    const { error: { passwordError, confirmPasswordError }, password, confirmPassword } = this.state;
    if (passwordError.length === 0 && confirmPasswordError.length === 0 && password.length !== 0 && confirmPassword.length !== 0) {
      return true;
    }
    const errorText = "Please Enter Valid Password, min length 6 and max length 20!";

    if (confirmPassword.length == 0) {
      this.setState({
        error: {
          ...this.state.error,
          confirmPasswordError: errorText
        }
      })
    }
    if (password.length == 0) {
      this.setState({
        error: {
          ...this.state.error,
          passwordError: errorText
        }
      });
    }
    if (!confirmPassword && !password) {
      this.setState({
        error: {
          ...this.state.error,
          passwordError: errorText,
          confirmPasswordError: errorText
        }
      });
    }
    return false;
  }

  setUserPassword = () => {
    const { setUserPassword, match: { params: { tokenId } }, getUserDetails, domains } = this.props;
    if (!this.validatedata()) {
      return
    }
    const { password } = this.state;
    setUserPassword({
      password: password,
      tokenId: tokenId,
    })
      .then(data => {
        getUserDetails().then((res) => {
          if (res && res.userInfo && !res.userInfo.domains.length) {
            this.props.history.push('/domain-management/addwebsite');
            return null;
          }
          this.props.history.push("/my-site");
        })
      })
      .catch(err => {});

  }

  setInfo = (field, value) => {
    this.setState({ [field]: value })
  }


  setConfirmPassword = ({ target: { value } }) => {
    this.setInfo("confirmPassword", value);
    if (value.length < 6 || value.length > 20) {
      this.setState({
        error: {
          ...this.state.error,
          confirmPasswordError: "Please Enter Valid Password, min length 6 and max length 20!"
        }
      })
    } else {
      this.setState({
        error: {
          ...this.state.error,
          confirmPasswordError: ""
        }
      })
    }
    const { password } = this.state;

    if ((value && password) && (value.length === password.length) && (value !== password)) {
      this.setState({
        error: {
          ...this.state.error,
          confirmPasswordError: "Did not match with password!"
        }
      })
    }
  }

  setPassword = ({ target: { value } }) => {
    this.setInfo("password", value);
    if (value.length < 6 || value.length > 20) {
      this.setState({
        error: {
          ...this.state.error,
          passwordError: "Please Enter Valid Password, min length 6 and max length 20!"
        }
      })
    } else {
      this.setState({
        error: {
          ...this.state.error,
          passwordError: ""
        }
      })
    }
    const { confirmPassword } = this.state;

    if ((value && confirmPassword) && (value.length === confirmPassword.length) && (value !== confirmPassword)) {
      this.setState({
        error: {
          ...this.state.error,
          passwordError: "Did not match with Confirm Password!"
        }
      })
    }
  }

  render() {
    const { classes, confirmPasswordLoader } = this.props;
    const { password, confirmPassword, error: { passwordError, confirmPasswordError } } = this.state;
    return (
      <div>
        <form>
          <GridContainer justify="center">
            <GridItem xs={10} md={10}>
              <GridContainer>
                <GridItem xs={12} style={{marginLeft: '15px'}}>
                  <div className="login-heading">
                    Set Password
                  </div>
                </GridItem>
                <GridItem xs={3} style={{marginLeft: '15px'}}>
                  <div className="text-underline"></div>
                </GridItem>
              {/* <CustomInput
                labelText="Email..."
                id="email"
                value={password}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Email className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                  onChange: this.setEmail,
                }}
                helpText={passwordError}
              /> */}
              <GridItem xs={12} style={{marginTop:'-15px'}}>
              <CustomInput
                labelText="Password"
                id="password"
                value={password}
                formControlProps={{
                  fullWidth: true
                }}
                inputType="password"
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon className={classes.inputAdornmentIcon}>
                        lock_outline
                          </Icon>
                    </InputAdornment>
                  ),
                  onChange: this.setPassword
                }}
                helpText={passwordError}
              />
              </GridItem>
              <GridItem xs={12} style={{marginTop:'-20px'}}>
              <CustomInput
                labelText="Confirm Password"
                id="ConformPassword"
                value={confirmPassword}
                formControlProps={{
                  fullWidth: true
                }}
                inputType="password"
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon className={classes.inputAdornmentIcon}>
                        lock_outline
                          </Icon>
                    </InputAdornment>
                  ),
                  onChange: this.setConfirmPassword
                }}
                helpText={confirmPasswordError}
              />
              </GridItem>
              <GridItem xs={12} style={{textAlign:'center'}}>
                <Button disabled={confirmPasswordLoader} color="secondary" onClick={this.setUserPassword}>
                    Set Password {confirmPasswordLoader && <AppLoader isSignOutLoader={true} size={12}/>}
                </Button>
              </GridItem>
              </GridContainer>
              </GridItem>
              </GridContainer>
        </form>

      </div >

    )
  }
}
const mapStateToProps = (state) => {
  const { confirmPasswordLoader } = state.login;
  const { domains } = state.login.user;
  return {
    confirmPasswordLoader,
    domains
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserDetails: () => dispatch(fetchUserDetails()),
    getUserDetails: () => dispatch(getUserDetails()),
    setUserPassword: (data) => dispatch(setUserPassword(data))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmPassword));
