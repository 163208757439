import React, { PureComponent } from 'react';
import insta from '../../../assets/icons/insta.svg';
import twitter from '../../../assets/icons/twitter.svg';
import facebook from '../../../assets/icons/facebook.svg';

class SocialLink extends PureComponent {
  render() {
    const { twitterUrl, fbUrl, instaUrl } = this.props;
    return (
      <div style={{ marginLeft: '15px', marginBottom: '20px' }}>
        <a href={fbUrl} target="_blank">
          {fbUrl && <img style={{ width: '25px', cursor: 'pointer' }} src={facebook} />}
        </a>
        <a href={twitterUrl} target="_blank">
          {twitterUrl && <img style={{ width: '25px', marginLeft: '15px', cursor: 'pointer' }} src={twitter} />}
        </a>
        <a href={instaUrl} target="_blank">
          {instaUrl && <img style={{ width: '25px', marginLeft: '15px', cursor: 'pointer' }} src={insta} />}
        </a>
      </div>
    )
  }
}
export default SocialLink;