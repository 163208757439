import { LocalStorageKeys } from "../app-constants";
import GalleryItem from "../cms/schema/gallery-item.js";
// import moment from "moment";

// export function getCookie(cname){

//   var name = cname + "=";
//   var ca = document.cookie.split(";");
//   for(var i =0; i<ca.length; i++){
//     var c = ca[i];
//     while(c.charAt(0) == " "){
//       c = c.substring(1);
//     }
//     if(c.indexOf(cname) == 0){
//       const val =  c.substring(name.length, c.length);
//       if(!val && cname == "Authorization"){
//         return sessionStorage.getItem(SessionKeys.AUTHORIZATION_KEY)
//       }
//       return val
//     }
//   }
//   return "";
// }
export function setCookie(cname, cvalue, exdays) {
  // console.log(cname + " " + cvalue);
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function eraseCookie(name) {
  setCookie(name, "", -1);
}
export function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}


export function getUniques(arr) {
  return arr.filter((value, index, self) => self.indexOf(value) === index);
}

export function isValidEmailAddress(emailAddress) {
  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,20}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  return pattern.test(emailAddress);
};

export function askForNotificationPermission() {
  if ('Notification' in window &&
    typeof window.Notification.requestPermission === 'function') {
    window.Notification.requestPermission();
  }
}

export function showNotification(title, body, image = 'https://cheesecake.articleassets.meaww.com/v3/assets/meaww_logo.svg') {
  if ('Notification' in window && window.Notification.permission === 'granted') {
    const n = new window.Notification(title, {
      body,
      image,
    });
    // Chrome does not close the notification by itself.
    setTimeout(n.close.bind(n), 4000);
  }
}

export function isValidJson(str) {
  try {
    JSON.parse(str);
    return true
  } catch (err) {
    return false;
  }
}

export function removePersistedData() {
  eraseCookie("name");
  eraseCookie("Authorization");
  eraseCookie("profile_picture");
  //saving into localStorage
  localStorage.removeItem("ProfilePicture");
  localStorage.removeItem("Authorization");
  localStorage.removeItem("User");
  localStorage.removeItem(LocalStorageKeys.USER_DETAILS);
  //saving auth key in session storage
  sessionStorage.removeItem("Authorization");
  localStorage.removeItem(LocalStorageKeys.AUTHORIZATION_KEY);
}


// const getCurrentDate = () => {
//   return be
// }

// const getDateSevenDayBack = () => {
//   const date = new Date();
//   date.setDate(date.getDate() - 7);

//   const year = date.getFullYear();
//   const month = date.getMonth();
//   const day = date.getDate();
//   return `${year}-${month + 1}-${day}`;
// }

export function uppercaseFirstLetter(string) {
  if (!string) {
    return "";
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isValidGalleryItems(items) {
  if (!items || items.length < 5) {
    return false;
  }
  let isValid = false;
  if (typeof items[0] == GalleryItem) {
    isValid = items.every(item => item.isValidItem())
  } else {
    isValid = items.every(item => new GalleryItem({ ...item }).isValidItem())
  }
  return isValid;
}

