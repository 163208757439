
const pagesAction = {
  LOAD: {
    REQUEST: 'pagesAction.Page.Load.request',
    FAILURE: 'pagesAction.Page.Load.failure',
    SUCCESS: 'pagesAction.Page.Load.success',
  },
  CREATE: {
    REQUEST: 'pagesAction.Page.Create.request',
    FAILURE: 'pagesAction.Page.Create.failure',
    SUCCESS: 'pagesAction.Page.Create.success',
  },
  UPDATE: {
    REQUEST: 'pagesAction.Page.Edit.request',
    FAILURE: 'pagesAction.Page.Edit.failure',
    SUCCESS: 'pagesAction.Page.Edit.success',
    TITLE: 'pagesAction.Page.Edit.title'
  },
  SET: {
    PageId: 'pagesAction.Page.Set.id',
    PAGE_CONTENT: 'pagesAction.Page.Set.Content',
    TINYMC_CONTENT: 'pagesAction.Page.Set.TinyMc_Content',
    PISCO_CONTENT: 'pageAction.Page.Set.Pisco.Content'
  },
  PISCO: {
    SHOW: 'pagesAction.Pisco.show',
    HIDE: 'pagesAction.Pisco.hide',
    CLEAR: 'pagesAction.Pisco.clear'
  },
  DEFAULT: {
    SET: 'pagesAction.Default.Set',
    UPDATE: 'pagesAction.Default.Update',
    SET_DEFAULT_PAGE: 'pagesAction.Default.Set.Defult.Page',
    RESET_DEFAULT_PAGE: 'pagesAction.Default.Reset.Default.Page'
  },
  ACTION: {
    REQUEST: 'pagesAction.Page.Action.request',
    FAILURE: 'pagesAction.Page.Action.failure',
    SUCCESS: 'pagesAction.Page.Action.success',
  }
}

export default pagesAction;