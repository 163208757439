import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from '@material-ui/core/Hidden';
import AddCircleIcon from "@material-ui/icons/AddCircle";
//import Button from "@material-ui/core/Button";
import Button from "components/CustomButtons/Button";
import MenuItem from '@material-ui/core/MenuItem';
import AppLoader from "../../../app-components/app-loader.jsx";
import ModalLoader from "../../../app-components/modal-loader.jsx";
import withConfirmation from "../../../app-components/confirmation-hoc";
import articlesStyle from "../../../assets/jss/material-dashboard-pro-react/views/articlesStyle.jsx";
import createNewPage, {
  getPages,
  setDataToTinyMc,
  setDefaultPage,
  addDefaultPage,
  deletePage,
  disablePage,
  publishPage
} from '../pages-action';
import DefaultPages from '../components/default-pages';

const MenuItemWithConfirm = withConfirmation(MenuItem);
class Pages extends Component {


  componentDidMount() {
    this.props.getPages()
  }
  redirectToTinyMce = (id) => {
    this.props.history.push(`/settings/website/pages/${id}`)
  }
  redirectToEditPage = (id) => {
    this.props.history.push(`/settings/website/pages/${id}/edit`)
  }
  redirectToTinyMceEditPage = (id, pageContent) => {
    this.props.setDataToTinyMc(id, this.redirectToEditPage)
  }
  render() {
    const { classes, createNewPage, pages = [], isLoading, defaultPage = [], setDefaultPage, addDefaultPage, doaminName, disablePage, isCreating, isAction } = this.props;
    return (
      <>
        <GridContainer style={{
          width: '100%',
          marginTop: '20px',
          marginBottom: '20px',
        }}>
          <div style={{ display: 'inline-block', paddingLeft: '10px', pointerEvents: `${isCreating ? "none" : "auto"}` }}>

            <span style={{ display: "flex", alignItems: 'center', color: '#2B2D42', cursor: 'pointer', marginBottom: '10px' }} onClick={() => createNewPage(this.redirectToTinyMce)} >
              <AddCircleIcon /> &nbsp;<span style={{ color: "#2B2D42", paddingTop: '2px' }}>Add new page</span>
              {isCreating && < AppLoader size={20} />}
            </span>
          </div>
        </GridContainer>

        {isLoading && <div style={{ display: "flex", justifyContent: 'center' }}><AppLoader message="loading pages ..." /></div>}
        {!isLoading && <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
          <Hidden xsDown>
            <GridContainer justify="center" style={{ width: '100%' }}>
              <GridItem xs={4} sm={4} md={4} >
                <h5 className={classes.articleTitle} style={{ paddingLeft: '16px', fontSize: '20px' }}> Title</h5>
              </GridItem>
              <GridItem xs={4} sm={4} md={4} >
                <h5 className={classes.articleTitle} style={{ paddingLeft: '16px', fontSize: '20px' }}> Page Path</h5>
              </GridItem>
              <GridItem xs={4} sm={4} md={4} >
                <h5 className={classes.articleTitle} style={{ paddingLeft: '16px', fontSize: '20px' }}> Action</h5>
              </GridItem>
            </GridContainer>
          </Hidden>
          {
            defaultPage.map((page, key) => <DefaultPages
              key={key}
              page={page}
              redirectToTinyMceEditPage={this.redirectToTinyMceEditPage}
              setDefaultPage={setDefaultPage}
              addDefaultPage={addDefaultPage}
              doaminName={doaminName}
              disablePage={disablePage}
              publishPage={this.props.publishPage}
            />)
          }

          {
            pages.map((pageContent, index) => <GridContainer key={index} justify="center" style={{ width: '100%' }} className={classes.articleContainer}>
              <GridItem xs={12} sm={4} md={4}  >
                <h5 className={classes.articleTitle} style={{ paddingLeft: '16px', wordBreak: 'break-word' }}>
                  <Hidden smUp><div>Title :</div></Hidden> {pageContent.title}<br />
                </h5>
              </GridItem>
              <GridItem xs={12} sm={4} md={4} >
                <h5 className={classes.articleTitle} style={{ paddingLeft: '16px', wordBreak: 'break-word' }}>
                  <Hidden smUp><div>pagePath :</div></Hidden>
                  {pageContent.slug ?
                    <a href={`https://${doaminName}/page/${pageContent.slug}`} target="_blank">{`/${pageContent.slug}`}</a>
                    : `Not available`
                  }
                  <br />
                </h5>
              </GridItem>
              <GridItem xs={12} sm={4} md={4} style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  color="secondary"
                  onClick={() => { this.redirectToTinyMceEditPage(pageContent.id, pageContent) }}
                  //style={{ color: '#fb4d24', padding: `14px` }}
                >
                  EDIT
              </Button>
                <MenuItemWithConfirm onClick={() => { this.props.deletePage(pageContent.id) }} msg={'Do you want to delete page?'} style={{ height: 'auto' }}>
                  <Button
                    color="primary"
                    //style={{ color: '#fb4d24', padding: `14px` }}
                  >
                    DELETE
              </Button>
                </MenuItemWithConfirm>

                {/* {pageContent.title && <Button
                  onClick={() => { this.props.publishPage(pageContent.id) }}
                  style={{ color: '#fb4d24', padding: `14px` }}
                >
                  PUBLISH
              </Button>} */}
              </GridItem>
            </GridContainer>)
          }
        </div>
        }
        {isAction && <ModalLoader message={'loading...'} isOpen={isAction} />}
      </>
    )
  }
}

const mapStateToProps = (store) => {
  const { page, defaultPage: { defaultPage } } = store.websiteManagementReducer.pages;
  const { isLoading, isCreating, isAction } = store.websiteManagementReducer.pages.pageSetting
  const { activeDomain, domainMap } = store.login.user
  const pages = Object.keys(page).reduce((a, b) => {
    a.push(page[b])
    return a
  }, []);
  return {
    pages,
    isLoading,
    defaultPage,
    doaminName: domainMap[activeDomain][0].fqdn,
    isCreating,
    isAction
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setDataToTinyMc: (id, redirect) => dispatch(setDataToTinyMc(id, redirect)),
    createNewPage: (redirectToTinyMce) => dispatch(createNewPage(redirectToTinyMce)),
    getPages: () => dispatch(getPages()),
    setDefaultPage: () => dispatch(setDefaultPage()),
    addDefaultPage: (slug) => dispatch(addDefaultPage(slug)),
    deletePage: (id) => dispatch(deletePage(id)),
    disablePage: (id) => dispatch(disablePage(id)),
    publishPage: (id) => dispatch(publishPage(id))
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(articlesStyle)(Pages)));
