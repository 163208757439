import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";

class ManageSocialHome extends PureComponent {
  render() {
    return (
      <>
        <Helmet title="Manage Social Accounts | PubNinja - You Publish. The rest we manage" />
        {this.props.children}
      </>
    )
  }
}
export default ManageSocialHome;
