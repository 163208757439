import React, { PureComponent } from "react";
import LandingPage from "./views/landing-body-page.jsx";

class LandingBodyHomePage extends PureComponent {

    render() {
        return (<LandingPage />)
    }
}

export default LandingBodyHomePage;
