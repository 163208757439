import { AccountManagementAction, MessageTypes } from "../../app-constants/index.js";
import { setAppMessage } from "../../app-actions.js";
import { MeawwApi } from "../../utils/api.js";
import { uppercaseFirstLetter } from "../../utils/utils.js";



function fetchUserListRequest() {
  return {
    type: AccountManagementAction.FETCH.USER_LIST.REQUEST
  }
}

function fetchUserListSucceess(userList) {
  return {
    type: AccountManagementAction.FETCH.USER_LIST.SUCCESS,
    userList: userList
  }
}

function fetchUserListfailure() {
  return {
    type: AccountManagementAction.FETCH.USER_LIST.FAILURE
  }
}

export function fetchUserList() {
  return (dispatch, getState) => {
    dispatch(fetchUserListRequest());
    const { login: { user: { activeDomain } } } = getState();
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/acl/domain/${activeDomain}/users`,
          (error, data) => {
            if (error) {
              dispatch(fetchUserListfailure());
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return;
            }
            dispatch(fetchUserListSucceess(data));
          });
      })
  }
}

function getDomainPermissionsRequest() {
  return {
    type: AccountManagementAction.FETCH.DOMAIN_PERMISSIONS.REQUEST
  }
}
function getDomainPermissionsFailure() {
  return {
    type: AccountManagementAction.FETCH.DOMAIN_PERMISSIONS.FAILURE
  }
}
function getDomainPermissionsSuccess(permissions) {
  return {
    type: AccountManagementAction.FETCH.DOMAIN_PERMISSIONS.SUCCESS,
    permissions: permissions
  }
}

export function getDomainPermissions() {
  return (dispatch, getState) => {
    dispatch(getDomainPermissionsRequest());
    const { login: { user: { activeDomain } } } = getState();
    dispatch(getDomainPermissionsRequest());
    return MeawwApi()
      .auth()
      .then(api => {
        //?type=simplified
        return api.get(`/acl/domain/${activeDomain}/permissions?type=simplified`,
          (error, data) => {
            if (error) {
              dispatch(getDomainPermissionsFailure());
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return;
            }
            // const permissions = {};
            // data.forEach(permission => {
            //   permissions[permission] = {
            //     name: permission,
            //     displayName: uppercaseFirstLetter(permission.toLowerCase()),
            //     description: uppercaseFirstLetter(permission.toLowerCase()) + " " + uppercaseFirstLetter(permission.toLowerCase()) + " " + uppercaseFirstLetter(permission.toLowerCase()) + " " + uppercaseFirstLetter(permission.toLowerCase())
            //   }
            // })
            dispatch(getDomainPermissionsSuccess(data));
          });
      })
  }
}


export function moveLoginUserInfoToUserList() {
  return (dispatch, getState) => {
    const { login: { user } } = getState();
    dispatch(fetchUserListSucceess([user]))
    return Promise.resolve();
  }
}



function deleteDomainUserRequest(userId) {
  return {
    type: AccountManagementAction.DELETE.DOMAIN_USER.REQUEST,
    userId: userId
  }
}
function deleteDomainUserFailure(userId) {
  return {
    type: AccountManagementAction.DELETE.DOMAIN_USER.FAILURE,
    userId: userId
  }
}

function deleteDomainUserSuccess(userId) {
  return {
    type: AccountManagementAction.DELETE.DOMAIN_USER.SUCCESS,
    userId: userId
  }
}

export function deleteDomainUser(userDetails) {
  return (dispatch, getState) => {
    const { login: { user: { activeDomain } } } = getState();
    const { id, email } = userDetails;
    dispatch(deleteDomainUserRequest(id));
    return MeawwApi()
      .auth()
      .then(api => {
        return api.delete(`/acl/domain/${activeDomain}/user/${email}`,
          (error, data) => {
            if (error) {
              dispatch(deleteDomainUserFailure(id));
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return;
            }
            dispatch(setAppMessage(data.message, {
              type: MessageTypes.SUCCESS
            }));
            dispatch(deleteDomainUserSuccess(id));
            return
          });
      })
  }
}
