import React from "react";
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { Link } from "react-router-dom";

import AppLoader from "app-components/app-loader.jsx";
import withConfirmation from "app-components/confirmation-hoc.jsx";

const MenuItemWithConfirmation = withConfirmation(MenuItem);
const UserActionOptions = ({
  handleClose,
  anchorEl,
  deleteDomainUser,
  userDetails: { id: userId, isDeleting }
}) => (
    <Popover
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
      PaperProps={{
        style: {
          width: 200,
          marginLeft: '15px',
          border: 'solid 1px #ced3dc',
          // boxShadow: "0 1px 8px 0 rgba(0, 0, 0, 0.06)",
        },
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Link to={`/settings/user-management/user-details/${userId}`}>
        <MenuItem
          style={{
            color: '#5d737e',
            fontSize: '12px'
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = '#fdfaf5';
            e.target.style.color = '#2b2d42';
            e.target.style.fontWeight = '500';
          }
          }
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = '#ffffff';
            e.target.style.color = '#5d737e';
            e.target.style.fontWeight = 'unset';
          }}
          key="edit-permissions" onClick={handleClose}>

          <span style={{ color: "black" }}>Edit Permissions</span>

        </MenuItem>
      </Link>
      <MenuItemWithConfirmation
        style={{
          color: '#5d737e',
          fontSize: '12px'
        }}
        onMouseEnter={(e) => {
          e.target.style.backgroundColor = '#fdfaf5';
          e.target.style.color = '#2b2d42';
          e.target.style.fontWeight = '500';
        }
        }
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = '#ffffff';
          e.target.style.color = '#5d737e';
          e.target.style.fontWeight = 'unset';
        }}
        key="delete" onClick={deleteDomainUser} msg="Are you sure to delete user ?">
        <div style={{ display: "flex" }} >Delete User {isDeleting && <AppLoader isSignOutLoader={true} color="inherit" size={20} />}</div>
      </MenuItemWithConfirmation>
    </Popover >
  )

export default UserActionOptions;
