import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import LandingPageContainer from "./landing-page-container.jsx";
import landingPageStyle from '../../../assets/jss/material-dashboard-pro-react/views/landingPageStyle';
import UploadProfileImage from './upload-profile-image';
import { openPisco, closePisco, openLpModal, PASTECARDID } from '../landing-page-action.js';
import CoverPicture from './showAvatorPreview.jsx';

class LandingPage extends Component {
  state = {
    value: 0,
    type: null
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };

  setLpType = (type) => {
    this.setState({ ...this.state, ['type']: type });
  }
  render() {
    return (
      <>
        <LandingPageContainer openPisco={this.props.openPisco} setLpType={this.setLpType} />
        {this.props.avator && < CoverPicture avator={this.props.avator} />}
        <UploadProfileImage PASTECARDID={this.props.PASTECARDID} openLpModal={this.props.openLpModal} setLpType={this.setLpType} showPiscoModal={this.props.showPiscoModal} lpType={this.state.type} closePisco={this.props.closePisco} />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  const { landingPageSetting: { showPiscoModal, avator } } = state.tool.landingPages;
  return { showPiscoModal, avator }
}

const mapDispatchToProps = dispatch => {
  return {
    openLpModal: () => dispatch(openLpModal()),
    openPisco: () => dispatch(openPisco()),
    closePisco: () => dispatch(closePisco()),
    PASTECARDID: () => dispatch(PASTECARDID())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(landingPageStyle)(LandingPage));
