import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { loadLiveStories, setLiveArticlesPageNumber } from "../stories-action.js";
import { connect } from 'react-redux';
import AppLoader from "../../../app-components/app-loader.jsx";
import NoDataFound from "../../../app-components/no-data-found.jsx";
import ArticleCard from "../components/article.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ArticlesPagination from "../components/article-pagination.jsx";
import { setStoryDetail } from "../../posts/posts-action";

class ArticleList extends PureComponent {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    // this.poleForNewNotification()
    if (this.props.activeDomain) {
      this.props.loadLiveStories();
    }
    if (!this.props.activeDomain) {
      return this.props.history.push('/domain-management/addWebsite');
    }
  }
  componentWillReceiveProps(nextProps) {
    if (!this.props.activeDomain) {
      return this.props.history.push('/domain-management/addWebsite');
    }
    if (this.props.activeDomain && this.props.activeDomain !== nextProps.activeDomain) {
      this.props.loadLiveStories();
    }
  }
  goToSleep = () => {
    setTimeout(() => {
      this.poleForNewNotification();
    }, 1000*60*5);
  };

  getNotification = async () => {
    var myHeaders = new Headers();
    myHeaders.append("authorization", localStorage.getItem("accessToken"));
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    return fetch(`${process.env.REACT_APP_API_SERVER}/dashboard_notification/${sessionStorage.getItem('activeDomain')}/unread_message`, requestOptions)
        .then(response => response.json())
        .then(result => result)
        .catch(error => {
          throw error;
        });
  }

  marNotificationAsRead = async (messageId) => {
    console.log("<<<<<<<<>", messageId);
    var myHeaders = new Headers();
    myHeaders.append("authorization", localStorage.getItem("accessToken"));
    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      redirect: 'follow'
    };
    return fetch(`${process.env.REACT_APP_API_SERVER}/dashboard_notification/${sessionStorage.getItem('activeDomain')}/mark_read/${messageId}`, requestOptions)
        .then(response => response.json())
        .then(result => result)
        .catch(error => {
          throw error;
        });
  }

  poleForNewNotification = async () => {
    if (localStorage.getItem("accessToken") && sessionStorage.getItem('activeDomain') && parseInt(sessionStorage.getItem('activeDomain'))) {
      try {
        const d = await this.getNotification();
        if (d && d && d.length) {
          alert(`You have new message from ${d[0].from_author_name}:\n${d[0].message}`);
          await this.marNotificationAsRead(d[0].id);
          this.goToSleep();
        } else {
          this.goToSleep();
        }
      } catch (e) {
        console.log("<<<<<<<< error in getting data", e);
        this.goToSleep();
      }
    } else {
      console.log("<<<<<<<<<<<<<<<< no token");
      this.goToSleep();
    }
  };
  componentWillUnmount() {
    // reset the page number while leaving the stories page
    this.props.setLiveArticlesPageNumber(1);
  }
  getDomainName() {
    const { domainMap, activeDomain } = this.props;
    let domainName = '';
    try {
      domainName = domainMap[activeDomain][0]['fqdn']
    }
    catch (err) {
      domainName = ''
    }
    return domainName;
  }
  render() {
    const { articles, isLoading, classes, pageNo, activeDomain, domainMap, loadLiveStories, setStoryDetail } = this.props;
    if (isLoading) {
      return <AppLoader message="Articles Loading..." />
    }

    if (!articles || articles.length === 0) {
      return (
        <Fragment>
          <p style={{textTransform:"uppercase"}}>
            Your website :  <a href={`\/\/${this.getDomainName()}`} target="_blank" style={{ color:"black", fontWeight: '500', textTransform: 'lowercase' }}>
              {this.getDomainName()}
            </a>
          </p>
          <NoDataFound dataTypeName="Media" pageNo={0}/>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <ArticlesPagination pageNo={pageNo} articlelistLength={articles.length} loadLiveStories={loadLiveStories} />
            </GridItem>
          </GridContainer>
        </Fragment>
      )
    }
    return (
      <Fragment>
        <p style={{textTransform:"uppercase"}}>
          Your website :  <a href={`\/\/${this.getDomainName()}`} target="_blank" style={{ color:"black", fontWeight: '500', textTransform: 'lowercase' }}>
            {this.getDomainName()}
          </a>
        </p>
        <GridContainer justify="center">
          {
            articles.map((article, key) => (
              <GridItem xs={12} sm={12} md={12} key={key} nomargin={"true"}>
                <ArticleCard articleDetails={article} classes={classes} activeDomain={activeDomain} setStoryDetail={setStoryDetail} />
              </GridItem>
            ))
          }
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <ArticlesPagination pageNo={pageNo} articlelistLength={articles.length} loadLiveStories={loadLiveStories} />
          </GridItem>
        </GridContainer>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const { activeDomain, domainMap } = state.login.user
  return {
    ...state.stories,
    activeDomain: activeDomain,
    domainMap
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadLiveStories: () => dispatch(loadLiveStories()),
    setLiveArticlesPageNumber: (pageNo) => dispatch(setLiveArticlesPageNumber(pageNo)),
    setStoryDetail: (detail) => dispatch(setStoryDetail(detail))
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ArticleList));
