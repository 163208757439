import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import UserActions from "../views/user-actions.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import UserDetailsMobile from "../components/usere-details-for-mobile.jsx";
import UserDetails from "../components/user-details.jsx";
import withWidth from '@material-ui/core/withWidth';

const UserDetailStyle = {
  userRowContainer: {
    marginBottom: "15px",
    borderRadius: "5px",
    background: "#ffffff",
  },
  actions: {
    paddingLeft: "0px !important"
  }

}

class UserDetailContainer extends PureComponent {
  render() {
    const { classes = {}, userDetails, width } = this.props;
    return (
      <Fragment>
        <GridContainer className={classes.userRowContainer} style={{ display: "flex", }}>

          {width === "xs" && <UserDetailsMobile userDetails={userDetails} /> || <UserDetails userDetails={userDetails} />}

          <GridItem className={width === "xs" ? classes.actions : ""} xs={1} sm={1} displayFlexType={true} alignItemCenterWithEnd={true} style={{ justifyContent: "flex-end", }}>
            <UserActions userDetails={userDetails} deleteDomainUser={this.deleteDomainUser} />
          </GridItem>
        </GridContainer >
      </Fragment>

    )
  }
}

export default withRouter(withWidth()(withStyles(UserDetailStyle)(UserDetailContainer)));

