
import { combineReducers } from "redux";
import {
  QuickContentAction,
  QuickPostAction,
  QuickStoryAction,
  MessageTypes,
  UserAction
} from "../../app-constants/index.js";

const defaultState = {
  savingToPisco: false,
  creatingContent: false,
  categories: [],
  stories: [],
  isCFMLoading: false,
  feed: [],
  isDeletingStory: false,
  currentPage: 0,
  feedPerPage: 30,
  hasMoreData: true,
  feedFilterDefaultState: 'All',
  headerTypeDefaultState: 'All'
};

const quickCms = (state = defaultState, action) => {
  switch (action.type) {
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return defaultState;
    }
    case QuickPostAction.PISCO_SAVE.REQEUST: {
      return {
        ...state,
        savingToPisco: true,
      }
    }
    case QuickPostAction.PISCO_SAVE.SUCCESS: {
      return {
        ...state,
        savingToPisco: false,
      }
    }
    case QuickPostAction.PISCO_SAVE.FAILURE: {
      return {
        ...state,
        savingToPisco: false,
      }
    }
    case QuickPostAction.CREATE_POST.REQEUST: {
      return {
        ...state,
        creatingContent: true,
      }
    }
    case QuickPostAction.CREATE_POST.SUCCESS: {
      return {
        ...state,
        creatingContent: false,
      }
    }
    case QuickPostAction.CREATE_POST.FAILURE: {
      return {
        ...state,
        creatingContent: false,
      }
    }
    case QuickPostAction.CATEGORIES.FAILURE: {
      return {
        ...state,
        categories: [],
      }
    }
    case QuickPostAction.CATEGORIES.SUCCESS: {
      return {
        ...state,
        categories: action.categories,
      }
    }
    case QuickStoryAction.LOAD.SUCCESS: {
      return {
        ...state,
        stories: action.stories,
      }
    }
    case QuickStoryAction.DELETE.REQUEST: {
      return {
        ...state,
        isDeletingStory: true,
      }
    }
    case QuickStoryAction.DELETE.SUCCESS: {
      return {
        ...state,
        isDeletingStory: false,
      }
    }
    case QuickContentAction.GET.REQUEST: {
      return {
        ...state,
        isCFMLoading: true
      }
    }
    case QuickContentAction.SEARCH.REQUEST: {
      const {feed} = action;
      return {
        ...state,
        isCFMLoading: true,
        feed: feed
      }
    }
    case QuickContentAction.GET.FAILURE: {
      return {
        ...state,
        isCFMLoading: false
      }
    }
    case QuickContentAction.GET.SUCCESS: {
      const { nextPage, feed } = action;
      let newFeed = [];
      if(nextPage === 1){
        newFeed = feed;
      } else{
        newFeed = [...state.feed, ...feed]
      }
      return {
        ...state,
        feed: newFeed,
        currentPage: nextPage,
        isCFMLoading: false,
        hasMoreData: feed.length === 30,
        searchActive: false
      }
    }
    case QuickContentAction.SEARCH.FEED: {
      const { nextPage, feed } = action;
      return {
        ...state,
        feed: feed,
        currentPage: nextPage,
        isCFMLoading: false,
        hasMoreData: feed.length === 30,
        searchActive: true
      }
    }
    case QuickContentAction.FEED.REMOVE: {
      const { id } = action;
      const newFeed = state.feed.filter((item, key) => item.articleId != id)
      return {
        ...state,
        feed: newFeed
      }
    }
    case QuickContentAction.FEED.RESET: {
      return {
        ...state,
        feed: [],
        currentPage: 0,
        isCFMLoading: false,
        hasMoreData: false
      }
    }
    case QuickContentAction.UPDATE.REQUEST: {
      return {
        ...state,
        creatingContent: true,
      }
    }
    case QuickContentAction.UPDATE.FAILURE: {
      return {
        ...state,
        creatingContent: false,
      }
    }
    case QuickContentAction.UPDATE.SUCCESS: {
      const { articleId, data, status } = action;
      const feed = state.feed.map((ele) => {
        if (ele.articleId == articleId) {
          ele = Object.assign(ele, data);
          if (data.metadata && data.metadata.thumbnail) {
            ele.headerContent = JSON.stringify(data.metadata)
          }
          if (status == 'publish') {
            ele.status = 4
          }
          ele.paidContent =  + data.premium
        }
        return ele
      })
      return {
        ...state,
        feed,
        creatingContent: false,
      }
    }
    case QuickContentAction.RESET.FEED: {
      return {
        ...state,
        feedFilterDefaultState: action.feedType
      }
    }
    case QuickContentAction.RESET.HEADER_TYPE: {
      return {
        ...state,
        headerTypeDefaultState: action.headerType
      }
    }
    case QuickContentAction.RESET.ALL: {
      return {
        ...state,
        feedFilterDefaultState: 'All',
        headerTypeDefaultState: 'All'
      }
    }
    default: return state;
  }
}


export default quickCms;
