import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import Code from "@material-ui/icons/Code";
import Group from "@material-ui/icons/Group";
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import { isValidEmailAddress } from "../../utils/utils.js";

import { onboardUser } from "../onboarding-action";

import Header from '../components/header.jsx';

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      checked: false,
      error: {
        emailError: '',
        firstnameError: '',
        lastnameError: '',
        checkboxError: '',
      }
    };
  }

  onFirstnameChange = (event) => {
    this.setState({ firstname: event.target.value });
  }

  onLastnameChange = (event) => {
    this.setState({ lastname: event.target.value });
  }

  onEmailChange = (event) => {
    this.setState({ email: event.target.value });
  }

  onPasswordChange = (event) => {
    this.setState({ password: event.target.value });
  }

  handleToggle = () => {
    this.setState((prev) => {
      return {
        checked: !prev.checked,
      }
    })
  }

  validateData = () => {
    const { email, firstname, lastname, checked } = this.state;
    let isValid = true;
    if (lastname.length == 0) {
      isValid = false;
      this.setState({
        error: {
          ...this.state.error,
          lastnameError: "Please Enter Valid Lastname!"
        }
      });
    }
    if (firstname.length == 0) {
      isValid = false;
      this.setState({
        error: {
          ...this.state.error,
          firstnameError: "Please Enter Valid Firstname!"
        }
      });
    }
    if (!isValidEmailAddress(email)) {
      isValid = false
      this.setState({
        error: {
          ...this.state.error,
          emailError: "Please Enter Valid Email!"
        }
      });
    }
    return isValid;
  }

  handleGetStarted = () => {

    if (!this.validateData()) {
      return;
    }
    const { email, firstname, lastname } = this.state;
    if (email == '' || firstname == '' || lastname == '') {
      return;
    }
    this.props.onboardNewUser(email, firstname, lastname).then((res) => {
    }).catch(err => {
    }).finally(() => {
      this.setState({
        email: '',
        firstname: '',
        lastname: '',
      })
    });
  }
  render() {
    const { classes } = this.props;
    const { error } = this.state;
    return (
      <>
        <Header />
        <div className={classes.container} style={{
          display: 'flex', justifyContent: 'center', marginTop: '100px'
        }
        }>
          <Card className={classes.cardSignup}>
            <h2 className={classes.cardTitle}>Pubninja | Get Started</h2>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={5} style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '30px'
                }}>
                  <h3 className={classes.cardTitle} style={{ fontSize: '1.3em' }}>
                    Running your content website will be free forever.
                  </h3>
                  <h3 className={classes.cardTitle} style={{ fontSize: '1.3em' }}>
                    Create content, build audience and earn through Ads & Subscription.                  </h3>
                  <h3 className={classes.cardTitle} style={{ fontSize: '1.3em' }}>
                    Once you start earning, we will charge a small percentage from your net income.
                  </h3>
                  <h2 className={classes.cardTitle} style={{ fontSize: '1.4em', marginTop: '15px', fontWeight: '500' }}> Sign up now!</h2>
                </GridItem>
                <GridItem xs={12} sm={8} md={5}>
                  <form className={classes.form}>
                    <CustomInput
                      id="email"
                      value={this.state.email}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        onChange: this.onEmailChange,
                        placeholder: "Email..."
                      }}
                      helpText={error.emailError}
                    />
                    <CustomInput
                      id="firstname"
                      value={this.state.firstname}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        onChange: this.onFirstnameChange,
                        placeholder: "First Name..."
                      }}
                      helpText={error.firstnameError}
                    />
                    <CustomInput
                      id="lastname"
                      value={this.state.lastname}
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        onChange: this.onLastnameChange,
                        placeholder: "Last Name..."
                      }}
                      helpText={error.lastnameError}
                    />


                    <FormControlLabel
                      classes={{
                        root: classes.checkboxLabelControl,
                        label: classes.checkboxLabel
                      }}
                      control={
                        <Checkbox
                          //tabIndex={-1}
                          checked={this.state.checked}
                          onChange={this.handleToggle}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                    />
                    <span>I agree to the{" "}<a href="https://pubninja.com/terms.html" target="_blank">Terms</a> and <a href="https://pubninja.com/privacy.html" target="_blank">Privacy Policy</a>.</span>

                    <div className={classes.center}>
                      <Button round color="primary" disabled={!this.state.checked} onClick={this.handleGetStarted}>
                        Get started
                          </Button>
                    </div>
                  </form>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = (state) => {
  return {

  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onboardNewUser: (email, firstname, lastname) => dispatch(onboardUser(email, firstname, lastname)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(registerPageStyle)(RegisterPage))
