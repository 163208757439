import React from "react";
import { connect } from "react-redux";
import StoryListDropdown from "../components/story-list-dropdown";
import AddNewStoryButton from "../components/add-story-button";
import PiscoStoryUpload from "../components/pisco-story-upload";
import ListSettings from "../components/list-settings";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Divider from "@material-ui/core/Divider";
import StoryList from "../components/story-list";
import {
  getStoryList,
  setActiveStoryList,
  addNewStoryList,
  deleteStoryList,
  deleteStory,
  getStories,
  addNewStory,
  updateStoryListExpiry,
  setActiveStoryListExpiry,
} from "../story-cms-actions";

class ListHomePage extends React.PureComponent {
  state = {
    showPiscoModal: false,
    stories: [
      'https://cheesecake.articleassets.meaww.com/assets/stories/st_1.jpg',
      'https://cheesecake.articleassets.meaww.com/assets/stories/st_2.jpg',
      'https://cheesecake.articleassets.meaww.com/assets/stories/st_3.jpg',
      'https://cheesecake.articleassets.meaww.com/assets/stories/st_4.jpg',
      'https://cheesecake.articleassets.meaww.com/assets/stories/st_5.jpg',
      'https://cheesecake.articleassets.meaww.com/assets/stories/st_2.jpg',
      'https://cheesecake.articleassets.meaww.com/assets/stories/st_3.jpg',
      'https://cheesecake.articleassets.meaww.com/assets/stories/st_4.jpg',
      'https://cheesecake.articleassets.meaww.com/assets/stories/st_5.jpg'
    ]
  }

  togglePiscoModal = () => {
    this.setState((prev) => {
      return { showPiscoModal: !prev.showPiscoModal, }
    });
  }

  deleteStory = (src) => {
    this.props.deleteStory(src);
  }
  render() {
    return (
      <GridContainer key="stories_container">
        <GridItem xs={8} sm={8} md={8} key='stories_dropdown'>
          <StoryListDropdown
            activeStoryList={this.props.activeStoryList}
            list={this.props.list}
            getStoryList={this.props.getStoryList}
            getStories={this.props.getStories}
            setActiveStoryList={this.props.setActiveStoryList}
            addNewStoryList={this.props.addNewStoryList}
            setActiveStoryListExpiry={this.props.setActiveStoryListExpiry}
          />
        </GridItem>
        <GridItem xs={4} sm={4} md={4} key='stories_setting' style={{ display:"flex", justifyContent:'flex-end' }}>
          <ListSettings
            deleteStoryList={this.props.deleteStoryList}
            activeStoryList={this.props.activeStoryList}
            updateStoryListExpiry={this.props.updateStoryListExpiry}
            activeStoryListExpiry={this.props.activeStoryListExpiry}
          />
        </GridItem>
        <GridItem xs={12} key='stories_new_Story' style={{ marginTop:'20px'}}>
          <PiscoStoryUpload
            addNewStory={this.props.addNewStory}
            showPiscoModal={this.state.showPiscoModal}
            hidePiscoModal={this.togglePiscoModal}
            newStoryLoading={this.props.newStoryLoading}
          />
        </GridItem>
        <GridItem xs={12} key='stories_today' style={{ marginTop:'20px'}}>
          <h3 key="stories_today_head">Today</h3>
          <Divider/>
          <div>
            <StoryList
              stories={this.props.stories[0] || []}
              deleteStory={this.props.deleteStory}
              getStories={this.props.getStories}
            />
          </div>
          <Divider/>
        </GridItem>
        <GridItem xs={12} key='stories_yesterday' style={{ marginTop:'20px'}}>
          <h3 key="stories_yesterday_head">Yesterday</h3>
          <Divider/>
          <div key="stories_yesterday_images">
            <StoryList
              stories={this.props.stories[1] || []}
              deleteStory={this.props.deleteStory}
              getStories={this.props.getStories}
            />
          </div>
          <Divider/>
        </GridItem>
      </GridContainer>
    )
  }
}

const mapStateToProps = (store) => {
    const {
      storyCms: {
        list,
        activeStoryList,
        activeStoryListExpiry,
        stories,
        newStoryLoading,
      }
    } = store.cms;
  return {
    list,
    activeStoryList,
    activeStoryListExpiry,
    stories,
    newStoryLoading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getStoryList: () => dispatch(getStoryList()),
    setActiveStoryList: (listName) => dispatch(setActiveStoryList(listName)),
    addNewStoryList: (name) => dispatch(addNewStoryList(name)),
    deleteStoryList: (listId) => dispatch(deleteStoryList(listId)),
    deleteStory: (storyId) => dispatch(deleteStory(storyId)),
    getStories: (day) => dispatch(getStories(day)),
    addNewStory: (content, croppedData, heading, actionLink, cb) => dispatch(addNewStory(content, croppedData, heading, actionLink, cb)),
    updateStoryListExpiry: (expiryTime) => dispatch(updateStoryListExpiry(expiryTime)),
    setActiveStoryListExpiry: (expiryTime) => dispatch(setActiveStoryListExpiry(expiryTime)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ListHomePage)

const styles = {
  storyList: {
    marginLeft:'-30px',
    overflow: 'auto',
    whiteSpace: "nowrap"
  },
  storyListItem: {
    display: 'inline-block',
  },
  imageContainer: {
    margin:'5px 0px 5px 20px',
    maxWidth: '180px',
    height: '320px'
  }
}
