import React from "react";
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AppLoader from '../../../app-components/app-loader.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class ChannelsList extends React.Component {
  state = {
    showChannelTypeDailog: false,
    channelType: 'individual',
    changed: [],
  }
  componentDidMount() {
    this.props.getChannels(this.props.provider);
  }
  handleChange = (data, changed, changeIndex) => {
    const { provider } = this.props;
    if (provider === 'twitter') {
      this.setState({ changed });
      this.setState({ showChannelTypeDailog: true });
    } else {
      const { name } = changed[0];
      this.props.addChannel(provider, name);
    }

  }
  handleChannelTypeChange = (event) => {
    this.setState({ channelType: event.target.value });
  }
  setContentAndAddChannel = () => {
    const { provider } = this.props;
    this.toggleChannelTypeDialog();
    const { name } = this.state.changed[0];
    const { channelType } = this.state;
    this.setState({ changed: [], channelType: 'individual' });
    this.props.addChannel(provider, name, channelType);
  }
  handleDelete = (tag) => {
    this.props.deleteChannel(tag);
  }
  toggleChannelTypeDialog = () => {
    this.setState((previousState) => {
      return { showChannelTypeDailog: !previousState.showChannelTypeDailog }
    })
  }
  render() {
    const { provider, channels } = this.props;
    const tagProps = {
      handleDelete: this.handleDelete,
    }
    if (channels.loadingChannel) {
      return (<AppLoader message={`Loading ${provider} Channels`} />)
    }
    //console.log("channels.addingChannel[provider]", channels.addingChannel[provider]);
    return (
      <div style={style.tagDiv}>

        {
          channels.addingChannel[provider] || false ? <span style={style.provider}>Adding Channel.....<CircularProgress size={20} style={{ margin: "5px"}} /> </span>: <><span style={style.provider}>{`${this.props.provider} Channels `}</span>
          <span style={style.helperText}>{`(${this.props.helperText})`}</span></>
        }
        <TagsInput
          value={channels[provider] || []}
          tagDisplayProp="name"
          onChange={this.handleChange}
          renderTag={defaultRenderTag}
          inputProps={{ placeholder: 'Paste link to add channel'}}
          tagProps={tagProps}
          addOnBlur={true}
          removeKeys={[]}
        />
        <Dialog
        open={this.state.showChannelTypeDailog}
        fullWidth={true}
        onClose={this.hide}
        maxWidth="sm"
        aria-labelledby="customized-dialog-title">
        <DialogTitle>Select The Type Of Channel</DialogTitle>
          <DialogContent>
            <p>Accounts that belong to people such as celebrities,famous artist etc should be marked as individual and
            accounts that belong to organizations such as media houses etc should be marked as publisher.</p>
          <RadioGroup
            aria-label="contentType"
            name="contentType"
            value={this.state.channelType}
            onChange={this.handleChannelTypeChange}
            style={{ alignItems:'baseline', paddingLeft: 'inherit'}}
          >
            <FormControlLabel
            value="individual"
            control={<Radio color="primary" />}
            label="Individual"
            labelPlacement="end"
            />
            <FormControlLabel
            value="publisher"
            control={<Radio color="primary" />}
            label="Publisher"
            labelPlacement="end"
            />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.setContentAndAddChannel} color="primary">
              Add Channel
          </Button>
          <Button onClick={this.toggleChannelTypeDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      </div>
    )
  }
}

const style = {
  tagDiv: {
    width: "100%",
    marginTop: "10px"
  },
  chip: {
    margin: "2px",
    padding: "2px",
    colorPrimary:"red",
  },
  provider: {
    textTransform: "capitalize",
    fontSize: "1.625em",
    lineHeight:"1.8em",
    margin: "20px 5px 10px",
  },
  helperText: {
    fontSize: "1em",
    margin: "100px 0"
  }
}

function defaultRenderTag (props) {
  let { tag, key, getTagDisplayValue, handleDelete } = props;
  if (tag.name || tag.property) {
    tag.name = tag.name || tag.property;
  } else {
    return null;
  }
  return (
    <Chip
      color='secondary'
      key={key}
      label={getTagDisplayValue(tag)}
      onDelete={handleDelete.bind(null, tag)}
      style={style.chip}
      variant="outlined"
    />
  )
}
