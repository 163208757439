import React, { PureComponent } from "react";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import moment from "moment";
import CATEGORY_PARAMS from "../../../utils/category-config.js";
import withStyles from "@material-ui/core/styles/withStyles";
import landingPageStyle from "../../../assets/jss/material-dashboard-pro-react/views/landingPageStyle";

class NewsRoomCard extends PureComponent {
  state = { showMenu: true, showModal: false };

  handleOpen = () => {
    this.setState({ ...this.state, showModal: true });
    this.props.openNewsRoomModal(this.props.id);
  }
  handleClose = () => {
    this.setState({ ...this.state, showModal: false });
    this.props.closeNewsRoomModal();
  }

  render() {
    const {
      landingPage, categoryName, searchText,
      item: {
        meta: {
          pricing: {
            amount,
            formatted
          }
        },
        item: {
          altids:{
            itemid
          },
          headline, description, type, firstcreated,
          renditions:{
            nitf,
            caption_nitf
          }
        },
      },
      classes,
      handleClick
    } = this.props;
    return (
      <div
        onClick = {handleClick}
        style={{
          height: "120px",
          overflow: "auto",
          cursor: 'pointer',
          border: '1px solid #CED3DC',
          margin: '10px 0'
        }}
      >
        <div className={classes.lpCont}>
          <div style={{ display: "flex" }}>
            <div>
              <h3 className={classes.lpDisplayName} style= {{fontSize: '1.1em'}}>{headline}</h3>
              <p className={classes.description} style={{padding: '0', marginBottom: '0', color: 'black'}}>{moment(firstcreated).format('MMM DD, YYYY h:mmA')}</p>
              <p className={classes.description} style={{padding: '0', marginBottom: '5px', textTransform: 'capitalize'}}>{type} / {(nitf) && nitf.words}{(caption_nitf) && caption_nitf.words} words</p>
              {(amount > 0) && <p className={classes.description} style={{padding: '0', marginBottom: '5px', textTransform: 'capitalize'}}>{formatted}</p>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(landingPageStyle)(NewsRoomCard);
