import { createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import { createLogger } from "redux-logger";
import reducers from "./app-reducers.js";
import { composeWithDevTools } from 'redux-devtools-extension';
// import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';


const logger = createLogger({ diff: true });
let middleware = [reduxThunk];//add logger for development

let composeEnhancers;

composeEnhancers = composeWithDevTools({}); //add params
//
// const store = createStore(reducers, /* preloadedState, */ composeEnhancers(
//   applyMiddleware(...middleware),
// ));

//update this block
// const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
// const store = createStoreWithMiddleware(reducers);

let store;
// console.log("process.env.NODE_ENV===", process.env.NODE_ENV);
if (process.env.NODE_ENV === "development") {
  store = createStore(reducers, /* preloadedState, */ composeEnhancers(
    applyMiddleware(...middleware),
  ));
} else {
  store = createStore(reducers, applyMiddleware(...middleware));
}
// window.store = store;
// console.log("store in app-store", store);
export default store;
