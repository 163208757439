import React, { Component } from "react";
import { Prompt } from "react-router-dom";
import { MessageTypes } from "app-constants/index.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import AppLoader from "app-components/app-loader.jsx";
import { Typography, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';


const CustomDialogTitle = withStyles(theme => ({
  root: {
    // borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, hideBlockQuote } = props;
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="subheading">
        <div style={{ display: "flex", justifyContent: "center", fontWeight: "bold" }}>
          {children}
        </div>
      </Typography>
      <IconButton aria-label="Close" className={classes.closeButton} onClick={hideBlockQuote}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
});


class EmbedModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: ""
    };
    this.emdebForm = React.createRef();
    this.embedCode = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setEmbededText = this.setEmbededText.bind(this);
  }
  setEmbededText(evnt) {
    this.setState({
      text: evnt.target.value
    });
  }

  formatContent(content) {
    const { hideBlockQuote, setAppMessage } = this.props;
    if (content.trim().indexOf("<") !== 0) {
      // 
      setAppMessage("The Embed Seems incorrect. Please check the embed code", {
        type: MessageTypes.ERROR
      });
      return "";
    }
    if (content.indexOf("twitter-tweet") > 0) {
      let scriptIndex = content.indexOf("<script");
      content = content.substring(0, scriptIndex);
    }

    return `<hr class="hidden-hr" />
            ${content}
            <hr class="hidden-hr" />`;
  }

  handleSubmit(evnt) {
    evnt.preventDefault();
    const { appendBlockQuote, hideBlockQuote } = this.props;
    const { text } = this.state;
    this.setState({ text: "" });
    const formattedContent = this.formatContent(text);
    if (formattedContent && formattedContent.length > 0) {
      appendBlockQuote(formattedContent);
    }
    hideBlockQuote();
  }

  render() {
    const { showBlockQuoteModal, showBlockQuote, hideBlockQuote } = this.props;
    const { text } = this.state;
    //if we follow the ant design update this design as docs
    return (
      <Dialog
        open={showBlockQuoteModal}
        onClose={hideBlockQuote}
        fullWidth={true}
        style={{ zIndex: 99999 }}

      >
        <CustomDialogTitle
          id="customized-dialog-title"
          hideBlockQuote={hideBlockQuote}
        >
          Enter Any(Twitter/Instagram) Embed Code
        </CustomDialogTitle>

        <DialogContent
        >
          <form ref={this.embedForm} onSubmit={this.handleSubmit}>
            <Prompt when={false} message="Are you sure?" />
            <TextField
              id="standard-name"
              multiline={true}
              margin="normal"
              fullWidth
              onChange={this.setEmbededText}
              rows={5}
              rowsMax={7}
            >
            </TextField>
          </form>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={this.handleSubmit} color="primary">
            OK
          </Button>
          <Button onClick={hideBlockQuote}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EmbedModal;
