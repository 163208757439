import React from 'react';
import { connect } from 'react-redux';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button';

class AddDomain extends React.Component {

  render() {
    return (
      <div>
          <Grid item xs={12}>
          <Paper style={{ height: "100px", marginTop: "30px" }}>
          <TextField
          id="standard-full-width"
          style={{ margin: 8, padding: '25px', width:"75%" }}
          placeholder="Enter Domain to connect"
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
            />
            <Button variant="contained" color="primary" style={{ margin: '30px', marginLeft:"50px" }}>
                Connect Domain
            </Button>
          </Paper>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {

  }
}

const mapDispatchToProps = () => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDomain);
