
const profileDetailStyle = {
  userName: {
    textAlign: "center",
    textTransform: "capitalize",
    fontWeight: "bold"
  },
  phoneNumber: {
    textAlign: "center",
    fontSize: '1em',
    fontWeight: '600'
  },
  description: {
    textAlign: "center",
    wordBreak: "break-word"
  },
  socialLink: {
    textAlign: "center",
    fontWeight: "bold",
    borderTop: "1px solid #e8e8e8",
    paddingTop: "15px"
  },
  avator: {
    width: '150px',
    height: '150px',
    margin: "auto",
    marginTop: "-60px"
  },
  buttonStyle: {
    float: "right",
    background: '#d6d6d6'
  },
  imagePadding: {
    paddingTop: "10px",
    height: "auto",
    maxWidth: '600px',
    maxHeight:'300px',
    margin: "0 auto",
    display: "block"

  }
}
export default profileDetailStyle;