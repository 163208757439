import React, { Component } from "react";
import { connect } from "react-redux";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import "../../../assets/scss/material-dashboard-pro-react/plugins/_pisco-pane.scss";
import { cropedAvator, resetAvator } from '../landing-page-action.js';

const Configs = {
  NEW_ARTICLE: {
    COVER_PICTURE: {
      COVER_PICTURE_MAX_SIZE: 2097152,
      COVER_IMAGE_MIN_WIDTH: 1200,
      COVER_IMAGE_MIN_HEIGHT: 630,
      COVER_SCALE: {
        "scale": 1,
        "angle": 0,
        "x": 0,
        "y": 0,
        "w": 1200,
        "h": 630,
      }
    }
  },
  NEW_NEWS: {
    COVER_PICTURE: {
      COVER_PICTURE_MAX_SIZE: 2097152,
      COVER_IMAGE_MIN_WIDTH: 1200,
      COVER_IMAGE_MIN_HEIGHT: 630,
      COVER_SCALE: {
        "scale": 1,
        "angle": 0,
        "x": 0,
        "y": 0,
        "w": 1200,
        "h": 630,
      }
    }
  },
}

const { NEW_ARTICLE: { COVER_PICTURE: CoverPictureConfigs } } = Configs;
const DEFAULT_COVER_PICTURE = "https://s3.amazonaws.com/cdn.meaww/assets/img/Cover.png";


const styles = {
  coverPictureForm: {
    display: "flex",
    flexDirection: "column",
    marginTop: "8px",
    alignItems: "flex-end",
    top: "0px",
    right: "10px",
    position: "absolute",
    float: "right",
  },
  inputImageWrapper: {
    width: "50px",
    height: "50px",
    overflow: "hidden",
    position: "relative",
  },
  coverImage: {
    fontSize: "50px",
    position: "absolute",
    bottom: 0,
    right: 0,
    opacity: 0,
    height: "50px",
    lineHeight: 1,
    cursor: "pointer",
  }
}


class CoverPicture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    }

    // this.changeCoverForm = React.createRef();
    // this.coverPicture = React.createRef();
    // this.cropPicture = React.createRef();

    this.changeAvatorImage = this.changeAvatorImage.bind(this);

    this.setZoomOut = this.setZoomOut.bind(this);
    this.setFit = this.setFit.bind(this);
    this.setZoomIn = this.setZoomIn.bind(this);

    this.handleSave = this.handleSave.bind(this);
    // this.chooseFile = this.chooseFile.bind(this);

    // //Modal
    this.handleClose = this.handleClose.bind(this);
  }

  // chooseFile(event) {
  //   this.props.onPiscoOpen(PiscoContentTypes.COVER_IMAGE);
  //   event.preventDefault();
  // }

  setZoomOut() {
    window.$("#crop_picture").guillotine("zoomOut");
  }

  setZoomIn() {
    window.$("#crop_picture").guillotine("zoomIn");
  }

  setFit() {
    window.$("#crop_picture").guillotine("fit");
  }

  changeAvatorImage(evnt) {
    let file, fileType, fileSize, coverPictureKlass = this;
    file = this.props.avator;
    fileType = 'URL';
    const ref = this;
    let img;
    if (file) {
      //load image
      const cropImageModal = this;
      img = new Image();
      img.src = file;
      // this.props.setCoverPictureImageSource(file);

      //on image load
      img.onload = function () {


        //check dimention
        if ((this.width < CoverPictureConfigs.COVER_IMAGE_MIN_WIDTH) || (this.height < CoverPictureConfigs.COVER_IMAGE_MIN_HEIGHT)) {
          alert("Cover Image Resolution must be atleast " + CoverPictureConfigs.COVER_IMAGE_MIN_WIDTH + "x" + CoverPictureConfigs.COVER_IMAGE_MIN_HEIGHT);
          coverPictureKlass.coverPicture.current.value = "";
          cropImageModal.setState({ visible: false })
          return;
        } else {


          const handleModal = () => {
            cropImageModal.setState({ visible: true });
            var picture = window.$('#crop_picture');  // Must be already loaded or cached!
            picture.guillotine({ width: 1200, height: 630 });
            picture.on("load", function (root) {


              //guillotine initialization
              picture.guillotine({
                width: CoverPictureConfigs.COVER_IMAGE_MIN_WIDTH,
                height: CoverPictureConfigs.COVER_IMAGE_MIN_HEIGHT,
                eventOnChange: "guillotinechange"
              });
              ref.setFit();
            });
            picture.on("guillotinechange", function (ev, data, action) {
              data.scale = parseFloat(data.scale.toFixed(4));
              coverPictureKlass.props.setCoverPictureCropData(JSON.stringify(data));
            })
            //Make sure the "load" event is triggered at least once( for cache image)
            //try to understand the logic
            if (picture.prop("complete")) {
              picture.trigger("load");
            }
          }
          setTimeout(handleModal, 1000);
        }
      }
      img.onerror = function () {
        alert("Something went wrong, Please try again!");
        coverPictureKlass.coverPicture.current.value = "";
      }
    }
  }

  handleClose() {
    this.props.resetAvator();
    this.setState({
      visible: false,
    });
    // this.coverPicture.current.value = "";
    // this.props.setPictureUrl(null);
  }

  handleSave(data) {
    let {
      avator
    } = this.props;

    const proto = window.location.protocol;
    if (avator && avator.indexOf('http') === -1) {
      avator = proto + avator;
    }

    this.props.cropedAvator({ "source": avator, "cropData": window.$("#crop_picture").guillotine("getData") });
    this.setState({
      visible: false,
    });
    window.$("#crop_picture").guillotine("remove");
    this.props.resetAvator();
  }

  componentDidMount() {
    if (this.props.avator) {
      this.changeAvatorImage(new CustomEvent('piscoimage', { url: this.props.avator }));
    }
  }
  render() {
    const { visible } = this.state;
    return (
      <>
        <Dialog
          open={visible}
          fullWidth={true}
          maxWidth="sm"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Upload Avator Image</DialogTitle>
          <DialogContent>
            <img ref={this.cropPicture} id="crop_picture" src={this.props.avator} />
            <div style={{ display: "flex", justifyContent: "center", paddingTop: "20px" }}>
              <Button type="primary" style={{ paddingLeft: "10px", paddingRight: "10px" }} onClick={this.setZoomOut}>ZoomOut</Button>
              <Button type="primary" style={{ paddingLeft: "10px", paddingRight: "10px" }} onClick={this.setFit}>Fit</Button>
              <Button type="primary" style={{ paddingLeft: "10px", paddingRight: "10px" }} onClick={this.setZoomIn}>ZoomIn</Button>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
              </Button>
            <Button onClick={this.handleSave} color="primary">
              Crop
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

const mapStateToProps = state => {

  return {

  }
}

const mapDispatchToProps = dispatch => {
  return {
    cropedAvator: (avator) => dispatch(cropedAvator(avator)),
    resetAvator: () => dispatch(resetAvator())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CoverPicture);