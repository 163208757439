const StoriesAction = {
    LOAD: {
        REQUEST: "Stories.Load.Request",
        SUCCESS: "Stories.Load.Success",
        FAILURE: "Stories.Load.Failure",
    },
    SET: {
        PAGE_NUMBER: "Stories.Set.PageNumber"
    }
}

export default StoriesAction;