import React, { PureComponent } from "react";

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

function withComponentWrapper(WrapperedComponent) {
  class WithSubscription extends PureComponent {
    constructor(props) {
      super(props);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
      // console.log(this.constructor.displayName, snapshot);
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
      const snapShot = {};
      const prevSnap = {};
      Object.entries(this.props).forEach(([key, value]) => {
        if (prevProps[key] !== value) {
          snapShot[key] = this.props[key];
          prevSnap[key] = prevProps[key];
        }
      });
      return snapShot;
    }

    render() {
      // console.log("rendering: ", this.constructor.displayName);
      return (
        <WrapperedComponent {...this.props} />
      )
    }
  }
  WithSubscription.displayName = `WithSubscription(${getDisplayName(WrapperedComponent)})`;
  return WithSubscription;
}

export default withComponentWrapper;