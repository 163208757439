import React, { PureComponent, Fragment } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from 'react-router-dom';
import { setArticleId } from "../../article/article-action.js";
import { connect, } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { ArticleTypes } from "app-constants/index.js";


const articleTypeStyles = (theme) => {
  return {
    dialogPaper: {
      minWidth: "400px"
    },
    root: {
      "color": "#2B2D42"
    }
  }
}


class CreateNewArticleContainer extends PureComponent {

  createNewArticle = () => {
    this.props.setArticleId(undefined);
  }
  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <GridContainer className={classes.root} >
          <Link
            style={{ color: "#2B2D42" }}
            to={{
              pathname: this.props.location.pathname + "/new-story",
              state: { contentType: ArticleTypes.NORMAL }
            }}>
            <GridItem>
              <div id="cmsaddnewarticle" onClick={this.createNewArticle} style={{ display: "flex", alignItems: "center", cursor: "pointer", margin: "20px 0 20px" }}>
                <AddCircleIcon />&nbsp;&nbsp;<span style={{ color: "#2B2D42" }}>New Article</span>
              </div>
            </GridItem>
          </Link>
        </GridContainer>
      </Fragment>
    )
  }
}
const mapStateToPropsArticleType = (state) => {
  return {}

}
const mapDispatchToProps = (dispatch) => {
  return {
    setArticleId: (articleId) => dispatch(setArticleId(articleId))
  }
}

export default compose(
  withRouter,
  connect(mapStateToPropsArticleType, mapDispatchToProps),
)(withStyles(articleTypeStyles)(CreateNewArticleContainer));
