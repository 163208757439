import React, { Component } from 'react';
import List from "@material-ui/core/List";
import cx from "classnames";

import { NavLink } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

var ps;
function hasPermission(requiredPermissions, userPermissions, completePermissions) {
  let userCompletePermissions = 0;
  if (completePermissions) {
    for (let i = 0; userPermissions && (i < userPermissions.length); i++) {
      if (completePermissions.indexOf(userPermissions[i]) > -1) {
        userCompletePermissions++;
      }
    }
    if (userCompletePermissions !== 2)
      return false;
  }
  if (!requiredPermissions || requiredPermissions.length === 0) {
    return true;
  }
  for (let i = 0; userPermissions && (i < userPermissions.length); i++) {
    if (requiredPermissions.indexOf(userPermissions[i]) > -1) {
      return true;
    }
  }
  return false;
}

function hasFeaturePermision(requiredFeaturePermision, featurePermision = []) {
    if (!requiredFeaturePermision || requiredFeaturePermision.length === 0) {
      return true
    }
    if (featurePermision.includes(requiredFeaturePermision)) {
      return true
    }
    return false
}

export default class Submenu extends Component{
    activeRoute(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
    }
    render(){
        if(!this.props.location.handler){
            window.location.href = "/my-site";
        }
        const views = this.props.location.handler.views;
        const {
            classes,
            color,
            rtlActive,
            user: userDetails = {},
            featurePermision,
          } = this.props.location.handler.props;
        const collapseItemText =
          classes.collapseItemText +
          " " +
          cx({
            [classes.collapseItemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextRTL]: rtlActive
          });
        const viewsHTML = (<List className={classes.list + " " + classes.collapseList}>
                    {
                      views.map((prop, key) => {
                        if (!hasFeaturePermision(prop.requiredFeaturePermision, featurePermision)) {
                          return null
                        }
                        if (prop.dontShowForTrail && featurePermision.indexOf('TRIAL') > -1) {
                          return null;
                        }
                        if (prop.dontShowInSideBar) {
                          return null
                        }

                        if (prop.redirect) {
                          return null;
                        }
                        if (!hasPermission(prop.requiredPermissions, userDetails.permissions, prop.completePermissions)) {
                          return null;
                        }
                        const navLinkClasses =
                          classes.collapseItemLink +
                          " " +
                          cx({
                            [" " + classes[color] + " " + classes.selectedTab]: this.activeRoute(prop.path)
                          });
                        const collapseItemMini =
                          classes.collapseItemMini +
                          " " +
                          cx({
                            [classes.collapseItemMiniRTL]: rtlActive
                          });
                        return (
                          <ListItem key={key} className={classes.collapseItem} >
                            <NavLink to={prop.path} className={navLinkClasses + " submenu-navlink"} id={prop.name.toLowerCase().replace(/\s/g, '')} >
                              <span className={collapseItemMini}>
                                {prop.mini}
                              </span>
                              <ListItemText

                                primary={prop.name}
                                disableTypography={true}
                                className={collapseItemText}
                              />
                            </NavLink>
                          </ListItem>
                        );
                      })}
                  </List>)
        return <div>{viewsHTML}</div>
    }
}