import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import AppLoader from "../../app-components/app-loader.jsx";
import { fetchUserDomains, setActiveDomain } from "../../user-settings/user-settings-actions.js";
import { fetchFeaturePermision } from "../../login/login-actions";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import { withStyles } from '@material-ui/core';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { withRouter } from 'react-router-dom';
import { setAppMessage } from "../../app-actions.js";
import { MessageTypes } from "../../app-constants/index.js";
import { uppercaseFirstLetter } from "../../utils/utils.js";


class DomianList extends PureComponent {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const { activeDomain, fetchUserDomains, fetchFeaturePermision, domainMap } = this.props;
    if (activeDomain) {
      fetchUserDomains();
      fetchFeaturePermision();
    }
  }

  handleDomainChange = (e) => {
    const { setActiveDomain, activeDomain, setAppMessage, domainMap } = this.props;
    const selectedDomain = e.target.value;
    if (selectedDomain === 'domain_new') {
      this.addNewDomain();
      return;
    }
    if (activeDomain !== selectedDomain) {
      setActiveDomain(selectedDomain);
      this.props.fetchFeaturePermision();
      this.props.history.push('/landing');
    } else {
      const { domainName } = domainMap[activeDomain][0];
      setAppMessage(`Domain is Already selected.`, { type: MessageTypes.INFO });
    }
  }
  getSortedDomain = () => {
    const { domainMap } = this.props;
    if (domainMap) return Object.values(domainMap).sort((a, b) => a[0]["fqdn"].localeCompare(b[0]["fqdn"]));
    return [];
  }

  addNewDomain = () => {
    this.props.history.push('/domain-management/addwebsite');
  }

  render() {
    const pathsToHideDomain = ["/user-settings/profile", "/user-settings/edit-profile"];
    const { domainMap, activeDomain, labelColor, classes } = this.props;
    if (pathsToHideDomain.indexOf(this.props.history.location.pathname || "/") > -1) {
      return null;
    }
    // if (!domainMap || Object.keys(domainMap).length === 0) {
    //   return (<AppLoader></AppLoader>)
    // }
    return (
      <div style={{ minWidth: "180px" }}>
        <FormControl
          fullWidth
          className={classes.selectLabel}
        >
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
            style={{ color: `${labelColor}` }}
          >
            Change Domain
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={activeDomain || 'domain_new'}
            onChange={this.handleDomainChange}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select",
              classes: {
                icon: labelColor && classes.icon,
                icon: classes.iconMargin
              },
            }}
          >
            {this.getSortedDomain().map(([{ fqdn, domainId }]) => {
              return (
                <MenuItem
                  key={domainId}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value={domainId}
                >
                  {fqdn}
                </MenuItem>
              )
            })
            }
            <MenuItem
              key='domain_new'
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value='domain_new'
              style={{ display: 'flex', color: '#2b2d42', textTransform: 'uppercase' }}
            >
              <AddCircleIcon style={{ color: '#2b2d42',paddingRight:'5px', fontWeight: '600' }} size="tiny" /> ADD NEW WEBSITE
            </MenuItem>
          </Select>
        </FormControl>
      </div >
    )
  }
}
const mapStateToProps = (state) => {
  return {
    domainMap: state.login.user.domainMap,
    activeDomain: state.login.user.activeDomain,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserDomains: () => dispatch(fetchUserDomains()),
    fetchFeaturePermision: () => dispatch(fetchFeaturePermision()),
    setActiveDomain: (domainId) => dispatch(setActiveDomain(domainId)),
    setAppMessage: (msg, params) => dispatch(setAppMessage(msg, params))
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(customSelectStyle)(DomianList)));
