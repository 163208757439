import React from 'react';
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.jsx";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import KebabCase from "lodash.kebabcase";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

class SubCategoryAddModal extends React.Component {
  state = {
    categoryVal: '',
    title: '',
    description: '',
    category: '',
    id: null
  }

  handleChange = (event, field) => {
    if (event.target.id === 'categoryVal') {
      this.setState({ categoryVal: event.target.value, category: KebabCase(event.target.value) });
    } else {
      this.setState({ [event.target.id]: event.target.value });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <GridContainer>
          <GridItem xs={12} md={12}>
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.modal
              }}
              open={this.props.showSubCategoryAddModal}
              onClose={() => this.props.toggleAddSubCategoryModal(false)}
              aria-labelledby="modal-slide-title"
              aria-describedby="modal-slide-description"
              style={{ overflow: 'scroll' }}
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}>
                <Button
                  justIcon
                  color="secondary"
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="transparent"
                  onClick={() => this.props.toggleAddSubCategoryModal(false)}
                >
                  <Close className={classes.modalClose} />
                </Button>
                <h4 className={classes.modalTitle}>Add SubCategory</h4>
              </DialogTitle>
              <DialogContent
                id="modal-slide-description"
                className={classes.modalBody}
              >
                {/* <CustomInput id="category"
                  labelText="Subcategory (used in url, no spaces)"
                  inputProps={{
                    onChange: (event) => { this.handleChange(event, 'category') }
                  }}
                  formControlProps={{ fullWidth: true }}
                  value={this.state.category}
                />
                */}
                <CustomInput id="categoryVal"
                  labelText="Enter Subcategory Value (Name)"
                  inputProps={{
                    onChange: (event) => { this.handleChange(event, 'categoryVal') }
                  }}
                  formControlProps={{ fullWidth: true }}
                  value={this.state.categoryVal}
                />
                <CustomInput id="title"
                  labelText="Title"
                  inputProps={{
                    required: true,
                    onChange: (event) => { this.handleChange(event, 'title') }
                  }}
                  formControlProps={{ fullWidth: true }}
                  value={this.state.title}
                />
                <CustomInput id="description"
                  labelText="Description"
                  inputProps={{
                    onChange: (event) => { this.handleChange(event, 'description') }
                  }}
                  formControlProps={{ fullWidth: true }}
                  value={this.state.description}
                />
              </DialogContent>
              <DialogActions
                className={classes.modalFooter + " " + classes.modalFooterCenter}>
                <Button
                  onClick={() => { this.props.submitSubCategory(this.props.category, this.state) }}
                  color="primary">
                  Submit
                </Button>
                <Button
                  color="secondary"
                  onClick={() => this.props.toggleAddSubCategoryModal(false)}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

export default withStyles(modalStyle)(SubCategoryAddModal);
