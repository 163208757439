
import React, { PureComponent } from "react"
import throttle from "lodash/throttle";
import TextField from '@material-ui/core/TextField';
import CustomInput from "components/CustomInput/CustomInput.jsx";


class ArticleSeoTitle extends PureComponent {
  constructor(props) {
    super(props);
    this.setChangeValue = throttle(this._setChangeValue, 100);
    this.textArea = React.createRef();
    this.state = { length: 0, seoTitle: props.seoTitle || "", title: props.title || '' };
  }

  componentDidMount() {
    const { defaultValue } = this.props;
    let length = 0;
    if (defaultValue) {
      length = defaultValue.length;
    }
    this.setState({ length });
  }
  _setChangeValue = (e) => {
    this.props.setSEOTitle(e.target.value, e.type);
  }
  onChange = (e) => {
    e.persist();
    this.setState({ length: e.target.value.length, seoTitle: e.target.value.length === 151 ? this.state.seoTitle : e.target.value });
    this.setChangeValue(e);
  }
  render() {
    const { seoTitle } = this.state;

    return (
      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <CustomInput
          id="standard-name"
          labelText="Google Headline"
          infoText="Enter the SEO title for the article. The length of the SEO title should be between 10-100"
          value={seoTitle || this.state.title}
          inputProps={{
            disableUnderline: true,
            maxLength: 300,
            onChange: this.onChange,
            onBlur: this.onChange
          }}
          formControlProps={{
            fullWidth: true,
            //multiline: true
          }}
          error={false}
          success={!!this.state.seoTitle && this.state.length > 9}
          margin="normal"
        />
        <p style={{ margin: "25px 5px" }}>{`${seoTitle && seoTitle.length || 0}/100`}</p>
      </div>
    )
  }
}

export default ArticleSeoTitle;
