import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TinyMCEEditor from "../../../cms/new-story/components/tinymce-editor.jsx";
import { updateEditorContent, setPageContent, setEditorContent, openPisco, closePisco, clearPiscoContent, reSetDefaultPage, publishPage } from "../pages-action";
import { setAppMessage } from "app-actions.js";
import Button from "@material-ui/core/Button";
import throttle from "lodash/throttle";
import ModalLoader from "../../../app-components/modal-loader.jsx";

import PageTitle from "./page-title";
import PagePiscoContent from "./pisco-content";

class TinyMCE extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowBlockQuote: false,
      content: {
        title: this.props.title,
        html: this.props.html,
      }
    }
    this.setChangeValue = throttle(this._setChangeValue, 5000);
  }
  componentDidMount() {
    const pageView = document.getElementById("pagePreviewDom");
    pageView && window.twttr && window.twttr.widgets.load(document.getElementById("pagePreviewDom"));
    if ("instgrm" in window) {
      // window.instgrm.Embeds.process();
    }
  }
  updateApi = (a) => {

  }
  setContentUpdatable = (b) => {

  }
  showBlockQuote = () => {
    this.setState({ isShowBlockQuote: true })
  }
  hideBlockQuote = () => {
    this.setState({ isShowBlockQuote: false })
  }
  appendBlockQuote = (j) => {
  }
  showPiscoModal = (e) => {

  }
  hidePiscoModal = (q) => {

  }
  piscoContent = (w) => {

  }

  setPageTitle = (title) => {
    const { setPageContent } = this.props;
    const content = {
      title,
      html: this.state.content.html
    }
    this.setState({ ...this.state, content }, () => {
      setPageContent(this.state.content)
      this.setChangeValue()
    })
  }
  _setChangeValue = async () => {
    const { setEditorContent } = this.props;
    return await setEditorContent();
  }

  updateEditorContent = (html) => {
    const { setPageContent, setEditorContent } = this.props;
    const content = {
      title: this.state.content.title,
      html
    }
    this.setState({ ...this.state, content }, () => {
      setPageContent(this.state.content)
      this.setChangeValue()
    })
  }
  redirectTominPage = () => {
    this.props.history.push('/settings/website')
  }
  publishPage = () => {
    const { id } = this.props;
    try {
      this.setChangeValue.flush().then((data) => {
        this.props.publishPage(id, this.redirectTominPage);
      }).catch((e) => {
      })
    }
    catch{
      this.props.publishPage(id, this.redirectTominPage);
    }
  }
  render() {
    const { setInitialState = () => ({ title: null, html: null }),
      id,
      domain,
      setAppMessage,
      title,
      html,
      openPisco,
      closePisco,
      clearPiscoContent,
      piscoContent = {},
      isDefaultPage,
      isAction
    } = this.props;
    const { isShowBlockQuote } = this.state;
    return (
      <>
        <PageTitle
          setPageTitle={this.setPageTitle}
          title={title}
          defaultPage={isDefaultPage}
        />
        <div id="pagePreviewDom">
          <TinyMCEEditor
            articleId={id}
            editorContent={html}
            setInitialState={setInitialState}
            setContentUpdatable={this.setContentUpdatable}
            updateApi={this.updateApi}
            updateEditorContent={this.updateEditorContent}
            showBlockQuote={this.showBlockQuote}
            showBlockQuoteModal={isShowBlockQuote}
            hideBlockQuote={this.hideBlockQuote}
            appendBlockQuote={this.appendBlockQuote}
            setAppMessage={setAppMessage}
            showPiscoModal={openPisco}
            hidePiscoModal={closePisco}
            piscoContent={piscoContent}
            clearPiscoContent={clearPiscoContent}
            activePiscoContentType={"Content"}
            domain={domain}
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            color="default"
            onClick={this.publishPage}
            type="primary"
            style={{ marginTop: "15px" }}
          >
            Publish and Go Back
        </Button>
        </div>
        <PagePiscoContent />
        {isAction && <ModalLoader message={'loading...'} isOpen={isAction} />}
      </>
    )
  }
}
const mapStateToProps = (store) => {
  const { id, html = null, title = null, piscoContent, isDefaultPage, isAction } = store.websiteManagementReducer.pages.pageSetting
  const { domain } = store.login
  return {
    id,
    domain,
    title,
    html,
    piscoContent,
    isDefaultPage,
    isAction
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setAppMessage: (msg, options) => dispatch(setAppMessage(msg, options)),
    setEditorContent: () => dispatch(setEditorContent()),
    setPageContent: content => dispatch(setPageContent(content)),
    openPisco: () => dispatch(openPisco()),
    closePisco: () => dispatch(closePisco()),
    clearPiscoContent: () => dispatch(clearPiscoContent()),
    reSetDefaultPage: () => dispatch(reSetDefaultPage()),
    publishPage: (id, redirectTomainPage) => dispatch(publishPage(id, redirectTomainPage))
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TinyMCE));
