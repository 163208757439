import { MeawwApi } from "../utils/api.js";
import { MessageTypes } from "../app-constants";
import { setAppMessage } from "../app-actions.js";
import { setLogoOnBoard } from "../website-management/website-management-actions";
import { addNewStoryList } from "../cms/story-cms/story-cms-actions";



function reqisterDomainSuccess() {
  return {
    type: 'resiterDomain.success'
  }
}
function reqisterDomainRequest() {
  return {
    type: 'resiterDomain.request'
  }
}
function reqisterDomainFailure() {
  return {
    type: 'resiterDomain.failure'
  }
}
export function registerDomain({ domain, onOk, description, name,title }) {
  return (dispatch, getState) => {
    // const { RegisterLogo } = getState().app;
    // dispatch(setUserName(name))
    dispatch(reqisterDomainRequest())
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/domain/create`, {
          "subdomain": domain && domain.toLowerCase(),
        }, async (error, data) => {
          if (error) {
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
            dispatch(reqisterDomainFailure());
            return Promise.reject(error);
          }
          await dispatch(setOtherDomainDetail(data.data.domainId, description,title))
          dispatch(reqisterDomainSuccess())
          //dispatch(setAppMessage(data.message, { type: MessageTypes.SUCCESS, onOk: onOk.bind(null, data.data && data.data.domainId) }));
          await dispatch(addNewStoryList('default-quick-cms-stories', data.data.domainId, true, 99999));
          return Promise.resolve(data);
        })
      });
  }
}

export function ShowSuccessMessage(msg) {
  return (dispatch, getState) => {
    dispatch(setAppMessage(msg, { type: MessageTypes.SUCCESS, }));
  }
}

function setUserName(name) {
  return (dispatch, getState) => {
    const { firstName, lastName } = name;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.put(`/user`, {
          firstName,
          lastName
        }, (error, data) => {
          if (error) {
            // dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
            return Promise.reject(error);
          }
          // dispatch(setLogoOnBoard(null))
          localStorage.setItem("firstName", `${firstName} `)
          localStorage.setItem("lastName", `${lastName} `)
          return Promise.resolve(data);
        })
      });
  }
}
export function setOtherDomainDetail(domainId, description,title) {
  return (dispatch, getState) => {
    return MeawwApi()
      .auth()
      .then(api => {
        return api.put(`/domain/${domainId}`, {
          description,
          title
        }, (error, data) => {
          if (error) {
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
            return Promise.reject(error);
          }
          dispatch(setLogoOnBoard(null))
          return Promise.resolve(data);
        })
      });
  }
}
