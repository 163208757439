import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import CarouselWrapper from '../components/carousel.jsx';
import PostsView from './postView.jsx';
import StorysView from './storyView.jsx';
import domtoimage from 'dom-to-image';
import Button from "../../../components/CustomButtons/Button.jsx";
import loadStoryPost, {componentUnMount} from "../posts-action";
import { withRouter } from "react-router-dom";
import AppLoader from "../../../app-components/app-loader.jsx";

class Posts extends PureComponent {
  state = {
    isStory: false,
    isPost: true,
    viewNumber: 0
  }
  componentDidMount() {
    this.props.loadStoryPost();
  }
  componentWillUnmount(){
      this.props.componentUnMount()
  }

  setPost = () => {
    this.setState({
      isStory: false,
      isPost: true
    })
  }
  setStory = () => {
    this.setState({
      isStory: true,
      isPost: false
    })
  }
  SetViewNumber = (n) => {
    this.setState({ ...this.state, ['viewNumber']: n })
  }
  downloadImage = () => {
    domtoimage.toJpeg(document.getElementById(`${this.state.isPost ? 'post' : 'story'}mainView${this.state.viewNumber}`), { quality: 0.95 })
      .then(function (dataUrl) {
        let link = document.createElement('a');
        link.download = 'image.jpeg';
        link.href = dataUrl;
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  }
  render() {
    const { isStory, isPost } = this.state;
    const { storypost = {}, storyDetail = {}, isLoadingTemplate } = this.props;
    if(isLoadingTemplate) {
      return (
        <AppLoader message={`Loading Template...`} />
      )
    }
    if (Object.keys(storyDetail).length == 0 && !isLoadingTemplate) {
      return <div>
        {this.props.history.push("/landing")}
      </div >
    }
    return (
      <div id="story-post-coursel">
        {isPost && (storypost && storypost.view && Object.keys(storypost.view).length > 0) && < CarouselWrapper View={PostsView({ result: storypost.view.post, storyDetail: storyDetail })} SetViewNumber={this.SetViewNumber} />}
        {isStory && (storypost && storypost.view && Object.keys(storypost.view).length > 0) && <CarouselWrapper View={StorysView({ result: storypost.view.story, storyDetail: storyDetail })} SetViewNumber={this.SetViewNumber} />}
        <div style={{ position: 'fixed', bottom: '80px' }}>
          <div id="story-post-backbtn" onClick={()=>this.props.history.push("/landing")}>
          <Button justIcon round>
            <i class="material-icons">
              keyboard_arrow_left
            </i>
          </Button>
          </div>
          <Button color="primary" style={{ height: '41px' }} onClick={() => this.downloadImage()}>
            <i className="material-icons">
              cloud_download
            </i>
          </Button>
          <Button color="primary" style={{ background: `${isPost ? '' : 'rgb(214, 214, 214)'}`, color: `${isPost ? '' : 'black'}` }} onClick={this.setPost}>post</Button>
          <Button color="primary" style={{ background: `${isStory ? '' : 'rgb(214, 214, 214)'}`, color: `${isStory ? '' : 'black'}` }} onClick={this.setStory}>story</Button>
        </div>
      </div >
    )
  }
}
const mapStateToProps = (state) => {
  const { cms } = state
  return {
    storypost: cms.StoryPost,
    storyDetail: cms.StoryPost.storyDetail,
    isLoadingTemplate: cms.StoryPost.isLoadingTemplate
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadStoryPost: () => dispatch(loadStoryPost()),
    componentUnMount: () => dispatch(componentUnMount())
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Posts));
