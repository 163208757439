import React from 'react';
import { connect } from 'react-redux';

import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import DomainIcon from "@material-ui/icons/Domain";
import InputAdornment from "@material-ui/core/InputAdornment";
import AppLoader from "../../app-components/app-loader.jsx";

import SidePane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Pisco from "pisco-ui";
import "pisco-ui/lib/styles.css";


import {
  setFaviconLogo, fetchFaviconLogo, setLogoOnBoard, updateSiteInfo
} from '../website-management-actions.js';

class FaviconLogo extends React.Component {
  state = {
    isPaneOpen: false,
    isUploadingLogo: false,
    isUploadingFavicon: false,
    title: this.props.title,
    description: this.props.description,
  }
  componentDidMount() {
    const { activeDomain, fetchFaviconLogo, isLogo = true } = this.props;
    activeDomain && isLogo && fetchFaviconLogo();
  }
  componentDidUpdate(prevProps) {
    const { title, description } = this.props;
    if ((this.props.title !== prevProps.title) || (this.props.description !== prevProps.description)) {
      this.setState({ ...this.state, title, description })
    }
  }
  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  }

  updateSiteData = () => {
    const { title, description } = this.state
    this.props.updateSiteInfo(title, description)
  }
  render() {
    const { isLoadingFaviconLogo, favicon, logo, isFavicon = true, isLogo = true, RegisterLogo } = this.props;
    const { title, description } = this.state;
    if (isLoadingFaviconLogo) {
      return <AppLoader message={"Loading Images"} />
    }
    return (
      <>
        <GridContainer style={!isLogo ? { display: 'flex', justifyContent: 'center' } : {}}>
          {isFavicon && <GridItem xs={12} md={6}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img style={{ height: '150px', width: '150px', objectFit: 'cover' }} src={this.props.favicon || 'https://cdn.meaww.com/assets/img/Cover.png'} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                color="primary"
                onClick={() => { this.setState({ isPaneOpen: true, isUploadingFavicon: true }) }}
              >
                Select Favicon
            </Button>
            </div>
          </GridItem>}
          <GridItem xs={12} md={6}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img style={{ height: '150px' }} src={
                !isLogo ? RegisterLogo || 'https://cdn.meaww.com/assets/img/Cover.png'
                  : this.props.logo || 'https://cdn.meaww.com/assets/img/Cover.png'
              } />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                color="primary"
                onClick={() => { this.setState({ isPaneOpen: true, isUploadingLogo: true }) }}
              >
                Select Website Logo
            </Button>
            </div>
          </GridItem>
          {isFavicon && <div style={{ width: '100%' }}>
            {/* <GridItem xs={12} sm={12} md={6} style={styles.FormtagStyle}>
              <h5 >Brand Name</h5>
            </GridItem> */}
            <GridItem xs={12} sm={12} md={6} style={styles.FormtagStyle}>
              <CustomInput
                labelText="Enter Brand name"
                id="title"
                value={title || ''}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <DomainIcon />
                    </InputAdornment>
                  ),
                  onChange: this.handleChange,
                }}
              // helpText={error.titleError}
              />
            </GridItem>
            {/* <GridItem xs={12} sm={12} md={6} style={styles.FormtagStyle}>
              <h5 >About the website</h5>
            </GridItem> */}
            <GridItem xs={12} sm={12} md={6} style={styles.FormtagStyle}>
              <CustomInput
                labelText="About the website"
                id="description"
                value={description || ''}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <DomainIcon />
                    </InputAdornment>
                  ),
                  onChange: this.handleChange,
                }}
              />
            </GridItem>
            <Button onClick={this.updateSiteData} color="secondary" style={{ margin: 'auto', display: 'block' }}> Submit</Button>
          </div>
          }
        </GridContainer>
        <SidePane
          overlayClassName='pisco-pane'
          isOpen={this.state.isPaneOpen}
          title='PISCO'
          subtitle='Media Search'
          from='right'
          width='80%'
          onRequestClose={() => {
            this.setState({ isPaneOpen: false, isUploadingLogo: false, isUploadingFavicon: false });
          }}
        >
          <Pisco
            headers={{
              authorization: localStorage.getItem('accessToken')
            }
            }
            endpoint={process.env.REACT_APP_API_SERVER}
            injectQuery={{ domainId: this.props.activeDomain }}
            disableGetty={this.props.activeDomain !== 15}
            type={'Both'}
            mode="embed"
            onEmbed={content => {
              !isLogo && this.props.setLogoOnBoard(this.state.isUploadingLogo ? content.s3Url : null)
              isLogo && this.props.setFaviconLogo(this.state.isUploadingFavicon ? content.s3Url : null, this.state.isUploadingLogo ? content.s3Url : null);
              this.setState({ isPaneOpen: false, isUploadingLogo: false, isUploadingFavicon: false });
            }} />
        </SidePane>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    websiteManagementReducer: {
      faviconLogoReducer: {
        favicon, logo, isLoadingFaviconLogo
      },
      siteInfo: {
        title,
        description
      }
    }
  } = state;
  const { app: { RegisterLogo }, login: { user: { activeDomain } } } = state;
  return { activeDomain, favicon, logo, isLoadingFaviconLogo, RegisterLogo, title, description };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFaviconLogo: () => dispatch(fetchFaviconLogo()),
    setFaviconLogo: (favicon, logo) => dispatch(setFaviconLogo(favicon, logo)),
    setLogoOnBoard: (logo) => dispatch(setLogoOnBoard(logo)),
    updateSiteInfo: (title, description) => dispatch(updateSiteInfo(title, description))
  }
}
const styles = {
  domainForms: {
    width: '60%',
    margin: "auto"
  },
  FormtagStyle: {
    margin: 'auto'
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FaviconLogo);
