import React, { PureComponent, Fragment} from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import AddNewUser from "../components/add-new-user.jsx";
import UserListHeader from "../components/user-list-header.jsx";
import Hidden from "@material-ui/core/Hidden";
import GridItem from "components/Grid/GridItem.jsx";
import RoundIcon from "../../../components/RoundIcon/roundicon.jsx"


class UserList extends PureComponent {
  componentDidMount() {

  }
  render() {
    return (
      <div >
        <AddNewUser />
        <Hidden xsDown implementation="css">
        </Hidden>
        <Fragment>
          <Grid container spacing={24}>
            <Paper style={{width:"100%",margin:"10px", padding:"10px 50px", marginTop:"50px",}}>
              <Grid item xs={8} sm={8} md={8}>
                <h4><a href="">/about-us</a></h4>
              </Grid>
            </Paper>
        </Grid>
        <Grid container spacing={24}>
            <Paper style={{width:"100%",margin:"10px", padding:"10px 50px", marginTop:"20px",}}>
              <Grid item xs={8} sm={8} md={8}>
                <h4><a href="">/contact-us</a></h4>
              </Grid>
            </Paper>
        </Grid>
        <Grid container spacing={24}>
            <Paper style={{width:"100%",margin:"10px", padding:"10px 50px", marginTop:"20px",}}>
              <Grid item xs={8} sm={8} md={8}>
                <h4><a href="">/advertise</a></h4>
              </Grid>
            </Paper>
        </Grid>
        </Fragment>
        {/* <RenderUserList userList={[]} /> */}
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {}
}
const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
