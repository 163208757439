import React, { PureComponent } from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { withStyles } from '@material-ui/core';
import MenuItem from "@material-ui/core/MenuItem";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";


class CategorySelect extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      categoryValue: '',
      subcategory: (this.props.categories[0] && this.props.categories[0]['yCategory']) || [],
      subCategoryValue: '',
    }
  }
  componentWillReceiveProps(props) {
    this.setState({ ...this.state, categoryValue: props.categoryValue, subCategoryValue: props.subCategoryName });
    if (this.props.subCategoryName !== props.subCategoryName) this.props.getLandingPage();
  }
  handleChange = (e) => {
    const { yCategory } = e.target.value;
    this.setState({ ...this.state, categoryValue: e.target.value.category, ['subcategory']: yCategory }, () => {
      this.props.activeCategorie(e.target.value.id, e.target.value.category);
      try {
        const { id, category } = yCategory[0];
        this.props.activeSubCategorie(id, category);
      }
      catch (e) {

      }
    });
  }
  handleSubChange = (e) => {
    const { category, id } = e.target.value;
    this.setState({ ...this.state, ['subCategoryValue']: category }, () => {
      this.props.activeSubCategorie(id, category);
      this.props.getLandingPage();
    });
  }
  render() {
    const { categories, classes } = this.props;
    const { subcategory = [] } = this.state;
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ width: "180px", marginLeft: '2.5%' }}>
          <FormControl
            fullWidth
            className={classes.selectLabel}
          >
            <InputLabel
              htmlFor="simple-select"
              className={classes.selectLabel}
            >
              CATEGORY
           </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={`${this.state.categoryValue}`}
              renderValue={() => `${this.state.categoryValue}`}
              onChange={this.handleChange}
              inputProps={{
                name: "simpleSelect",
                id: "simple-select",
                classes: {
                  icon: classes.iconMargin
                },
              }}
            >
              {
                categories.map((category, id) => (
                  <MenuItem key={id} value={category}>
                    {category.category}
                  </MenuItem>))
              }

            </Select>
          </FormControl>
        </div >
        <div style={{ width: "180px", marginLeft: '2.5%' }}>
          <FormControl
            fullWidth
            className={classes.selectLabel}
          >
            <InputLabel
              htmlFor="simple-select"
              className={classes.selectLabel}
            >
              SUBCATEGORY
           </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={`${this.state.subCategoryValue}`}
              renderValue={() => `${this.state.subCategoryValue}`}
              onChange={this.handleSubChange}
              inputProps={{
                name: "simpleSelect",
                id: "simple-select",
                classes: {
                  icon: classes.iconMargin
                },
              }}
            >
              {
                subcategory.map((category, id) => (
                  <MenuItem key={id} value={category}>
                    {category.category}
                  </MenuItem>))
              }

            </Select>
          </FormControl>
        </div>
      </div>
    )
  }
}

export default withStyles(customSelectStyle)(CategorySelect);
