import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppLoader from "../../../app-components/app-loader.jsx";
import Button from "@material-ui/core/Button";

class LoaderModal extends React.Component {

  render(){
    const { showModal, message, showLoader, showCloseBtn, closeLoaderModal } = this.props;
      return(
        <Dialog
          open={showModal}
          style={{
            maxWidth: '100%',
            backgroundColor: 'transparent',
            overflow: 'visible',
            margin: 'auto'
          }}>
          <DialogContent>
            {showLoader && <AppLoader message={message}/>}
            <b>{!showLoader && message}</b>
          </DialogContent>
          <DialogActions>
          {
            showCloseBtn
            &&
            <Button style={{color: '#fb4d24'}} onClick={closeLoaderModal}>
              Close
            </Button>
          }
          </DialogActions>
        </Dialog>
      );
  }
}

export default LoaderModal;
