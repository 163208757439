// landing page

import { combineReducers } from "redux";
import {
  LandingPageAction, UserAction
} from "../../app-constants/index.js";


const landingPageDefault = {}
const landingPage = (state = landingPageDefault, action) => {
  switch (action.type) {
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return landingPageDefault
    }
    case (LandingPageAction.LAOD.REQUEST): {
      return { ...state, isLoading: true }
    }

    case (LandingPageAction.LAOD.FAILURE): {
      return { ...state, isLoading: false }
    }

    case (LandingPageAction.LAOD.SUCCESS): {
      const { data } = action;
      let landingPageData = {};
      Object.values(data).forEach(({ id }, val) => {
        landingPageData[id] = data[val]
      })
      return { ...state, isLoading: false, landingPageData }
    }

    case (LandingPageAction.DELETE.PAGE): {
      const { cardId } = action;
      const { landingPageData } = state;
      delete landingPageData[cardId];
      return { ...state }
    }

    case LandingPageAction.UPDATE.SUCCESS: {
      const { formData, cardId } = action;
      const { landingPageData } = state;
      landingPageData[cardId] = { ...landingPageData[cardId], ...formData };
      return { ...state }
    }

    case LandingPageAction.ADDNEWLP.SUCCESS: {
      const { data } = action;
      const templandingPageData = {}
      templandingPageData[data.id] = data;
      return { ...state, ['landingPageData']: { ...templandingPageData, ...state.landingPageData } }
    }
    default: return state;
  }
}

const landingPageSetttingDefault = {
  categoryId: null,
  subCategoryId: null,
  subCategoryName: '',
  categoryName: '',
  isOpenAddLpModal: false,
  showPiscoModal: false
}
const landingPageSetting = (state = landingPageSetttingDefault, action) => {
  switch (action.type) {
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return landingPageSetting
    }
    case LandingPageAction.SETTING.SET: {
      const { cardId } = action;
      return { ...state, cardId }
    }
    case LandingPageAction.SETTING.REMOVE: {
      return { ...state, cardId: null }
    }
    case LandingPageAction.DELETE.PAGE: {
      const { cardId } = action;
      if (state.cardId == cardId) state.cardId = null
      return { ...state }
    }
    case LandingPageAction.LPMODAL.SET: {
      const { cardId } = state;
      const lpModalId = cardId;
      if (state.cardId == cardId) state.cardId = null
      return { ...state, lpModalId }
    }
    case LandingPageAction.LPMODAL.REMOVE: {
      return { ...state, lpModalId: null }
    }
    case LandingPageAction.CATEGORY.SETACTIVE: {
      const { categoryId, categoryName } = action;
      return { ...state, categoryId, categoryName }
    }
    case LandingPageAction.ADDLPMODAL.OPEN: {
      return { ...state, isOpenAddLpModal: true }
    }
    case LandingPageAction.ADDLPMODAL.CLOSE: {
      return { ...state, isOpenAddLpModal: false }
    }
    case LandingPageAction.PISCO.OPEN: {
      return { ...state, showPiscoModal: true }
    }
    case LandingPageAction.PISCO.CLOSE: {
      return { ...state, showPiscoModal: false }
    }
    case LandingPageAction.PISCO.COPYCARDID: {
      const CopylpModalId = state.lpModalId;
      return { ...state, CopylpModalId }
    }
    case LandingPageAction.PISCO.PASTECARDID: {
      const lpModalId = state.CopylpModalId;
      return { ...state, lpModalId }
    }
    case LandingPageAction.AVATOR.SET: {
      const { avator } = action;
      return { ...state, avator }
    }
    case LandingPageAction.AVATOR.CROPEDURL: {
      const { cropedUrl } = action;
      return { ...state, cropedUrl }
    }
    case LandingPageAction.RESETCROPEDURL.RESET: {
      state.cropedUrl = null;
      return { ...state }
    }
    case LandingPageAction.RESETCROPEDURL.RESETAVATOR: {
      state.avator = null;
      return { ...state }
    }
    case 'landingPage.set.subCategory': {
      const { subCategoryId, subCategoryName, defaultSubCategory } = action
      return { ...state, subCategoryId, subCategoryName, defaultSubCategory }
    }
    default: return state;
  }
}

const landingPages = combineReducers({
  landingPageSetting,
  landingPage
});

export default landingPages;
