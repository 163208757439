import { MeawwApi } from "../../utils/api.js";
import { MessageTypes } from "../../app-constants";
import { setAppMessage } from "../../app-actions.js";
import { UserAction, LocalStorageKeys, UserPermisssionAction } from "../../app-constants/index.js";

function submitAccountInfoRequest(data) {
  return {
    type: 'ACCOUNT_INFO_SUBMIT_REQUEST',
    data
  }
}
function submitAccountInfoFailure() {
  return {
    type: 'ACCOUNT_INFO_SUBMIT_FAILURE',
  }
}
function submitAccountInfoSuccess(data) {
  return {
    type: 'ACCOUNT_INFO_SUBMIT_SUCCESS',
    data
  }
}

export function submitAccountInfo(data) {
  return (dispatch, getState) => {
    dispatch(submitAccountInfoRequest());
    const { activeDomain } = getState().login.user;
    let payload = {
      date: data.date,
      company: {
        name: data.beneficiaryName,
        typeOfBusiness: data.typeOfBusiness,
        address: data.beneficiaryAddress,
      },
      bank: {
        name: data.bankName,
        ac_holder_name: data.accountName,
        ac_number: data.accountNumber,
        bank_address: data.bankAddress,
        pin_code: data.banPinCode,
        swift: data.routingDetails,
        iban: data.branchCode,
        tin: data.identificationNumber
      },
      personal: {
        name: data.yourName,
        contact: data.phoneNumber,
        email: data.emailId
      }
    }
      return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/bank/${activeDomain}`, {
          ...payload
        }, (error, data) => {
          //console.log(error, data);
          if (error) {
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
            dispatch(submitAccountInfoFailure());
            return Promise.reject(error);
          }
          //console.log(data);
        dispatch(submitAccountInfoSuccess(data));
          return Promise.resolve(data);
        });
        //return Promise.resolve();
      });
  }
}

//-------------------OTP
function generateOtpRequest() {
  return {
    type: 'OTP_GENERATE_REQUEST',
  }
}
function generateOtpFailure(data) {
  return {
    type: 'OTP_GENERATE_FAILURE',
    data
  }
}
function generateOtpSuccess() {
  return {
    type: 'OTP_GENERATE_SUCCESS',
  }
}
export function generateOtp(phoneNumber) {
  return (dispatch, getState) => {
    dispatch(generateOtpRequest());
    const { activeDomain } = getState().login.user;
      return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/bank/${activeDomain}/generate_otp`, {
          mobileNumber: phoneNumber,
        }, (error, data) => {
          if (error) {
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
            dispatch(generateOtpFailure(error.message));
            return Promise.reject(error);
          }
        dispatch(openOtpModal());
        dispatch(generateOtpSuccess());
          return Promise.resolve(data);
        });
      });
  }
}

export function getAdsStatusRequest() {
  return {
    type:'GET_ADS_REQUEST',
  }
}

export function getAdsStatusSuccess(data) {
  return {
    type: 'GET_ADS_SUCCESS',
    data,
  }
}

export function getAdsStatusFailure() {
  return {
    type: 'GET_ADS_FAILURE',
  }
}

export function getAdsStatus() {
  return (dispatch, getState) => {
    dispatch(getAdsStatusRequest());
    const {
      activeDomain
    } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/domain/${activeDomain}/configure_ads`,
          (error, data) => {
            if (error) {
              console.log(error.message);
              dispatch(getAdsStatusFailure());
              return Promise.reject(error);
            }
            dispatch(getAdsStatusSuccess(data));
            return Promise.resolve(data);
          })
      });
  }
}


export function enableAds(enabled) {
  return (dispatch, getState) => {
    dispatch(getAdsStatusRequest());
    const {
      activeDomain
    } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/domain/${activeDomain}/configure_ads`, {
            "enabled": enabled
          },
          (error, data) => {
            if (error) {
              dispatch(getAdsStatusFailure());
              console.log(error.message)
              return Promise.reject(error);
            }
            dispatch(getAdsStatusSuccess({enabled}));
            return Promise.resolve(data);
          })
      });
  }
}

export function openOtpModal() {
  return {
    type: 'OTP_MODAL_OPEN',
  }
}
export function closeOtpModal() {
  return {
    type: 'OTP_MODAL_CLOSE',
  }
}
function submitOtpRequest(data) {
  return {
    type: 'OTP_VERIFY_REQUEST',
    data
  }
}
function submitOtpFailure(data) {
  return {
    type: 'OTP_VERIFY_FAILURE',
    data
  }
}
function submitOtpSuccess() {
  return {
    type: 'OTP_VERIFY_SUCCESS',
  }
}

export function submitOtp(otp, phoneNumber) {
  return (dispatch, getState) => {
    dispatch(submitOtpRequest());
    const { activeDomain } = getState().login.user;
      return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/bank/${activeDomain}/verify_otp`, {
          mobileNumber: phoneNumber,
          otp: otp,
        }, (error, data) => {
          if (error) {
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
            dispatch(submitOtpFailure(error.message));
            return Promise.reject(error);
          }
        dispatch(submitOtpSuccess());
          return Promise.resolve(data);
        });
      });
  }
}


export function getBlacklistedBanks() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
      return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/bank/${activeDomain}/blacklisted_banks`,
          (error, data) => {
          if (error) {
            return Promise.reject(error);
          }
        dispatch(setBlacklistedBanks(data));
          return Promise.resolve(data);
        });
      });
  }
}

function setBlacklistedBanks(data) {
  return {
    type: 'BLACKLISTED_BANKS_SET',
    data
  }
}


// Subscriber Features ------------
export function fetchFeatures() {
  return (dispatch, getState) => {
    dispatch(fetchFeaturesRequest());
    const { activeDomain } = getState().login.user;
      return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/subscription/${activeDomain}/package`,
          (error, data) => {
          if (error) {
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
            dispatch(fetchFeaturesFailure());
            return Promise.reject(error);
          }
        dispatch(fetchFeaturesSuccess(data.result));
          return Promise.resolve(data);
        });
      });
  }
}

function fetchFeaturesRequest() {
  return {
    type: 'FEATURES_FETCH_REQUEST'
  }
}
function fetchFeaturesSuccess(data) {
  return {
    type: 'FEATURES_FETCH_SUCCESS',
    data
  }
}
function fetchFeaturesFailure() {
  return {
    type: 'FEATURES_FETCH_FAILURE'
  }
}
export function showErrorMessage(msg) {
  return (dispatch, getState) => {
    dispatch(setAppMessage(msg || 'Unable to complete operation', {
      type: MessageTypes.ERROR
    }));
    return Promise.reject();
  }
}

export function setFeatures(data, silent) {
  return (dispatch, getState) => {
    dispatch(setFeaturesRequest());
    const { activeDomain } = getState().login.user;
      return MeawwApi()
      .auth()
      .then(api => {
        const offerings = data.features.filter(item => item.checked === true).map(item => item.title);
        const payload = {
          currency: data.currencyType,
          currencySymbol: data.currencySymbol,
          subscriptionEnabled: data.subscriptionEnabled,
          monthly: {
            price: Number(data.monthlyPriceFloat),
            offerings,
          },
          annually: {
            price: Number(data.annualPriceFloat),
            offerings,
          }
        }
        return api.post(`/subscription/${activeDomain}/package`,
          payload,
          (error, data) => {
          if (error) {
            if(!silent) dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
            dispatch(setFeaturesFailure());
            return Promise.reject(error);
          }
            dispatch(setFeaturesSuccess());
            dispatch(fetchUserDomains());
          if(!silent)dispatch(setAppMessage(data.message || 'Subscription updated successfully', { type: MessageTypes.SUCCESS }));
          dispatch(fetchFeatures());
          return Promise.resolve(data);
        });
      });
  }
}

function setFeaturesRequest() {
  return {
    type: 'FEATURES_SET_REQUEST'
  }
}
function setFeaturesSuccess() {
  return {
    type: 'FEATURES_SET_SUCCESS'
  }
}
function setFeaturesFailure() {
  return {
    type: 'FEATURES_SET_FAILURE'
  }
}

function setUserDomainsSuccess(domainMap) {
  return {
    type: UserAction.FETCH.DOMAINS.SUCCESS,
    domainMap: domainMap
  }
}

export function fetchUserDomains() {
  return (dispatch) => {
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get("/acl/domains", (err, data) => {
          if (err) {
            return Promise.reject();
          }
          dispatch(setUserDomainsSuccess(data));
          return Promise.resolve(data);
        });
      });
  }
}

// Agreement -----------------------
function submitAgreementRequest() {
  return {
    type: 'AGREEMENT_SUBMIT_REQUEST',
  }
}
function submitAgreementFailure() {
  return {
    type: 'AGREEMENT_SUBMIT_FAILURE',
  }
}
function submitAgreementSuccess() {
  return {
    type: 'AGREEMENT_SUBMIT_SUCCESS',
  }
}
export function submitAgreement() {
  return (dispatch, getState) => {
    dispatch(submitAgreementRequest());
    const { activeDomain } = getState().login.user;
      return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/bank/${activeDomain}/agree`, {
        }, (error, data) => {
          if (error) {
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
            dispatch(submitAgreementFailure(error.message));
            return Promise.reject(error);
          }
        dispatch(submitAgreementSuccess());
          return Promise.resolve(data);
        });
      });
  }
}

// Fetch Bank details
function getBankDetailsRequest() {
  return {
    type: 'BANK_DETAILS_REQUEST'
  }
}
function getBankDetailsSuccess(data) {
  return {
    type: 'BANK_DETAILS_SUCCESS',
    data
  }
}
function getBankDetailsFailure() {
  return {
    type: 'BANK_DETAILS_FAILURE'
  }
}

export function getBankDetails() {
  return (dispatch, getState) => {
    dispatch(getBankDetailsRequest());
    const { activeDomain } = getState().login.user;
      return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/bank/${activeDomain}/`,
          (error, data) => {
          if (error) {
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
            return Promise.reject(error);
          }
        dispatch(getBankDetailsSuccess(data));
          return Promise.resolve(data);
        });
      });
  }
}
