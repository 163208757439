import React, { Component } from "react";
// import Weekend from "@material-ui/icons/Weekend";
import Paper from '@material-ui/core/Paper';
// core components
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import withStyles from "@material-ui/core/styles/withStyles";
import { LocalStorageKeys } from "../../../app-constants/index.js";
import SettingsIcon from '@material-ui/icons/Settings';
import FeedContainer from "./feedContainer.jsx";
import CustomFeedContainer from "./customFeedContainer.jsx";
import FeedSettings from "./settings";
import articleHomeStyle from '../../../assets/jss/material-dashboard-pro-react/views/articlehomeStyle';


function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 2 }}>
      {props.children}
    </Typography>
  );
}

const feedMessages = {
  twitter: {
    highlight: "Highlight Tweet",
    unhighlight: "Publisher Tweet"
  },
  insta: {
    highlight: "Highlight Post",
    unhighlight: "Instagram post"
  },
  youtube: {
    highlight: "Highlight Video",
    unhighlight: "Trending Video"
  }
}


class FeedList extends Component {
  state = {
    value: 0,
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    return (
      <Paper xs={12} sm={12} md={12} className={classes.root} >
        <Tabs
          fullWidth
          value={value}
          onChange={this.handleChange}
          scrollable
          scrollButtons="on"
          textColor="inherit"
          classes={{
            root: classes.tabsRoot
          }}
        >
          {/*<Tab label="Twitter" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
          <Tab label="Instagram" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
          <Tab label="Youtube " classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />*/}
          <Tab label="Sitemap " classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
          <Tab label="Top Site " classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
          {/*<Tab icon={<SettingsIcon />} classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />*/}
        </Tabs>
        {/*{value === 0 && <TabContainer><FeedContainer feedMessage={feedMessages.twitter} feedType="twitter" handlePlaceholder={this.handleChange}/></TabContainer>}
        {value === 1 && <TabContainer><FeedContainer feedMessage={feedMessages.insta} feedType="instagram" handlePlaceholder={this.handleChange}/></TabContainer>}
        {value === 2 && <TabContainer><FeedContainer feedMessage={feedMessages.youtube} feedType="youtube" handlePlaceholder={this.handleChange}/></TabContainer>}*/}
        {value === 0 && <TabContainer><CustomFeedContainer feedMessage={feedMessages.youtube} feedType="custom" isTopsite={0} handlePlaceholder={this.handleChange}/></TabContainer>}
        {value === 1 && <TabContainer><CustomFeedContainer feedMessage={feedMessages.youtube} feedType="custom" isTopsite={1} handlePlaceholder={this.handleChange}/></TabContainer>}
        {/*{value === 4 && <TabContainer><FeedSettings/></TabContainer>}*/}

      </Paper>
    );
  }
}
export default withStyles(articleHomeStyle)(FeedList);
