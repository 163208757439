import { setAppMessage } from "../../app-actions.js";
import { MessageTypes } from "../../app-constants/index.js";
import { MeawwApi } from "../../utils/api.js";
import moment from 'moment';

export function setDateRangesInterval(startDate, endDate){
  return {
    type: 'STATISTICS_DATES_SET',
    startDate,
    endDate
  }
};

export function getStatistics(startDate, endDate) {
  return (dispatch, getState) => {
    dispatch(getStatisticsRequest());
    const {
      login: {
        user: { activeDomain }
      },
      analysis: {
        statistics: {
          startDate: startDatefromState, endDate: endDatefromState
        }
      }
    } = getState();
    let query = `/insight/${activeDomain}/revenue`;
    if(typeof startDate!='undefined')
      query = `/insight/${activeDomain}/revenue?startDate=${startDate.format("YYYY-MM-DD")}&endDate=${endDate.format("YYYY-MM-DD")}`;
    else
      query = `/insight/${activeDomain}/revenue?startDate=${startDatefromState.format("YYYY-MM-DD")}&endDate=${endDatefromState.format("YYYY-MM-DD")}`;

    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(query,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              dispatch(getStatisticsFailure());
              return;
            }
            dispatch(getStatisticsSuccess(data));
            //dispatch(getStatisticsSuccess([]));
            return;
          });
      })
  }
}

function getStatisticsRequest() {
  return {
    type: 'STATISTICS_GET_REQUEST',
  }
}
function getStatisticsFailure() {
  return {
    type: 'STATISTICS_GET_FAILURE',
  }
}
function getStatisticsSuccess(data) {
  return {
    type: 'STATISTICS_GET_SUCCESS',
    data
  }
}

export function setDateRangeInterval(startDate, endDate) {
  return (dispatch) => {
    dispatch(setDateRangesInterval(startDate, endDate));
    dispatch(getStatistics(startDate, endDate));
    dispatch(getPageviews(startDate, endDate));
  }
}

export function getPageviews(startDate, endDate, pageNum, order='date', orderBy='desc', limit='20') {
  return (dispatch, getState) => {
    //dispatch(getStatisticsRequest());
    const {
      login: {
        user: { activeDomain }
      },
      analysis: {
        statistics: {
          startDate: startDatefromState, endDate: endDatefromState
        }
      }
    } = getState();
    let query = `/insight/${activeDomain}/pageviews?order=${order}&orderBy=${orderBy}&limit=${limit}`;
    if(typeof startDate!='undefined' && startDate !== null) {
      query = `${query}&startDate=${startDate.format("YYYY-MM-DD")}&endDate=${endDate.format("YYYY-MM-DD")}`;
    }
    else
      query = `${query}&startDate=${startDatefromState.format("YYYY-MM-DD")}&endDate=${endDatefromState.format("YYYY-MM-DD")}`;
    if(typeof pageNum!='undefined')
      query = `${query}&page=${pageNum}`;

    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(query,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              //dispatch(getPageviewsFailure());
              return;
            }
            //dispatch(getPageviewsSuccess(mockData));
            //return mockData;
            return data;
          });
      })
  }
}

function getPageviewsRequest() {
  return {
    type: 'PAGEVIEWS_GET_REQUEST',
  }
}
function getPageviewsFailure() {
  return {
    type: 'PAGEVIEWS_GET_FAILURE',
  }
}
function getPageviewsSuccess(data) {
  return {
    type: 'PAGEVIEWS_GET_SUCCESS',
    data
  }
}
