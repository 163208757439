import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Assignment from "@material-ui/icons/Assignment";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import AppLoader from "../../app-components/app-loader.jsx";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import clipboardIcon from '../../assets/icons/clipboard-icon.png';
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";

import EntriesModal from './components/entries-modal.jsx';
import LoaderModal from './components/loader-modal.jsx';

import {
  domainCreation,
  dnsCheck,
  fetchMapDomainStatus,
  mapping,
  getdnsrecords,
  addDnsEntry,
  setEntriesModalData,
  toggleentriesmodal,
  removeDnsEntry,
} from "../website-management-actions.js";

import Radio from '@material-ui/core/Radio';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Copy from "@material-ui/icons/FileCopy";

import { connect } from 'react-redux';

class MapDomain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: null,
      data: {
        domain: '',
        selectedEnabled: "a"
      },
      hostname: '',
      ip: '',
      submit: false,
      success: '',
      error: '',
      helpText: {},
      final: ''
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  onChange(event, type) {
    this.setState({ data: { ...this.state.data, ...{ [type]: event.target.value } } });

  }
  componentWillReceiveProps(nextProps) {
    //console.log('next', nextProps);
    this.setState({
      status: nextProps.mappingResponse.status || nextProps.status,
      data: { ...this.state.data, domain: (nextProps.mappingResponse.nameServer && nextProps.mappingResponse.map_domain) || '' }
    });
  }
  componentDidMount() {
    this.props.fetchMapDomainStatus();
    this.props.getdnsrecords();
  }
  editIp = () => {
    this.setState({ status: 'Invite', success: '', error: '' });
  }
  onSelect() {
    if (!(/.+\..+/.test(this.state.data.domain)) && (/\s/g.test(this.state.data.domain))) {
      this.setState({ error: "Please enter a valid domain" });
      return false;
    }
    this.setState({ error: "" });
    var hostname = (new URL('http://' + this.state.data.domain)).hostname;
    this.setState({ hostname: hostname });
    let ip = "";
    let ip1 = process.env.NODE_ENV === 'production' ? '3.208.100.135' : '54.226.56.155';
    let ip2 = process.env.NODE_ENV === 'production' ? '3.217.134.66' : '18.212.235.103';
    let ip3 = process.env.NODE_ENV === 'production' ? '3.221.27.104' : '18.212.235.103';
    switch (this.state.data.selectedEnabled) {
      case 'a':
        ip = ip1;
        break;
      case 'b':
        ip = ip2;
        break;
      case 'c':
        ip = ip3;
        break;
    }
    this.setState({ ip: ip });
    this.setState({ submit: true });
    this.props.mapping(this.state.data.domain);
  }

  onSubmit() {
    var domDetails = {
      hostname: this.state.hostname,
      name: this.state.data.selectedEnabled
    };
    const { domain } = this.state.data;
    this.props.mapping(domain).then(res => {
      this.setState({ error: '' });
      this.setState({ success: '' });
    }, _ => {
      this.setState({ error: "Mapping failed.Please contact administrator" });
    });
  }

  copyToClipboard = (item) => {
    const copyText = document.getElementById(item);
    copyText.select();
    document.execCommand("copy");
  }

  render() {
    const { isLoading, status, isMapping, mappingResponse, dnsrecords, deletingDnsEntry, hostName } = this.props;
    if (isLoading) {
      return <AppLoader />
    }
    return (
      <>
        <GridContainer justify="flex-start">
          <GridItem xs={12} sm={12} md={12}>
            {
              (this.state.status === 'Invite')
              &&
              <>
                <GridItem xs={12} sm={12} md={6} style={{marginLeft: '-15px'}}>
                  <h3 style={{ 'color': '#DB6565' }}>{this.state.error || this.state.success}</h3>
                  <CustomInput
                    labelText="Enter Domain Name"
                    id="message"
                    inputProps={{
                      onChange: event => {
                        this.onChange(event, "domain")
                      },
                      type: "text",
                      value: this.state.data.domain
                    }}
                    error={this.state.error.domain}
                    helpText={this.state.helpText.domain}
                    formControlProps={{ fullWidth: true }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} style={{marginLeft: '-15px'}} style={{marginLeft: '-15px'}}>
                  <div style={{ display: 'flex' }}>
                    <Button
                      color="primary"
                      type="submit"
                      onClick={this.onSelect}
                      disabled={isMapping}
                    >
                      Next
                      </Button>
                    {isMapping && <AppLoader size={20} />}
                  </div>
                </GridItem>
              </>
            }
            {
              (this.state.status === "pending")
              &&
              <>
                <h3 style={{ 'color': '#DB6565' }}>{this.state.error || this.state.success}</h3>
                <GridItem xs={12} sm={12} md={12} style={{marginLeft: '-15px'}}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h5>{mappingResponse.map_domain} is <span style={{ fontWeight: "bold" }}>pending</span></h5>
                    {/* <Button color="primary" style={{cursor: 'pointer'}} onClick={this.editIp}>
                          <span>Edit Domain Name</span>
                        </Button> */}
                  </div>

                  <h5>Remove these name servers:</h5>
                  <div style={{
                    backgroundColor: '#e6e9ed',
                    padding: '10px 20px',
                  }}
                  >
                    {
                      (mappingResponse && mappingResponse.nameServer && mappingResponse.nameServer.original_name_servers) &&
                      mappingResponse.nameServer.original_name_servers.map((item, index) => {
                        return <div key={index}>{item}</div>
                      })
                    }
                  </div>
                  <h5>And replace with:</h5>
                  {
                    (mappingResponse && mappingResponse.nameServer && mappingResponse.nameServer.name_servers) &&
                    mappingResponse.nameServer.name_servers.map((item, index) => {
                      return <div
                        key={index}
                        style={{
                          backgroundColor: '#e6e9ed',
                          padding: '5px 20px',
                          margin: '5px 0',
                        }}
                      >
                        <input id={item} value={item} readOnly style={{
                          backgroundColor: 'inherit',
                          color: 'inherit',
                          border: 'none',
                          fontFamily: 'inherit',
                        }} />
                        <span style={{
                          position: 'absolute',
                          right: '50px',
                          color: '#fb4d24',
                          cursor: 'pointer',
                        }}
                          onClick={() => this.copyToClipboard(item)}
                        >
                          <img src={clipboardIcon} style={{ width: '13px', height: '13px' }} />
                        </span>
                      </div>
                    })
                  }
                </GridItem>
                <GridItem xs={12} sm={12} md={12} style={{marginLeft: '-15px'}}>
                  <h5 style={{ fontSize: '13px', fontWeight: '600' }}>Note: Please click verify once the above entries have been updated</h5>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} style={{marginLeft: '-15px'}}>
                  <Button color="primary" type="submit" onClick={this.onSubmit}>Verify</Button>
                </GridItem>
              </>
            }
            {
              (
                this.state.status === 'active' &&
                dnsrecords
              ) &&
              <div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {/* <Button color="primary" style={{cursor: 'pointer'}} onClick={this.editIp}>
                        <AddCircleIcon />
                        <span>Edit Domain Name</span>
                      </Button> */}
                </div>
                <h5>{hostName && <>{hostName} is <span style={{ fontWeight: 'bold' }}>active</span></>}</h5>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h4><span>DNS Records</span></h4>
                  <Button color="secondary" style={{ cursor: 'pointer' }} onClick={() => this.props.toggleentriesmodal(true)}>
                    <AddCircleIcon style={{}} />
                    <span>Add Entry</span>
                  </Button>
                </div>
                <GridContainer justify="flex-start">
                  <GridItem xs={1}>
                    Type
                      </GridItem>
                  <GridItem xs={3}>
                    Name
                      </GridItem>
                  <GridItem xs={2}>
                    Content
                      </GridItem>
                  <GridItem xs={1}>
                    TTL
                      </GridItem>
                  <GridItem xs={2}>
                    Proxy Status
                      </GridItem>
                  <GridItem xs={12}>
                    <hr />
                  </GridItem>
                </GridContainer>
                {
                  (dnsrecords) && dnsrecords.map((item, index) => {
                    return (
                      <GridContainer key={index}>
                        <GridItem xs={1}>
                          {item.type}
                        </GridItem>
                        <GridItem xs={3}>
                          {item.name}
                        </GridItem>
                        <GridItem xs={2}>
                          {item.content}
                        </GridItem>
                        <GridItem xs={1}>
                          {item.ttl}
                        </GridItem>
                        <GridItem xs={2}>
                          {item.proxied.toString()}
                        </GridItem>
                        {
                          (typeof item.editable !== 'undefined' && item.editable === true) &&
                          <>
                            <GridItem xs={1}>
                              <div
                                style={{ display: "flex", cursor: 'pointer' }}
                                onClick={() => { this.props.setEntriesModalData(item) }}
                              >
                                <span style={{ marginTop: '2px' }}>{`Edit`}</span>
                                &nbsp;&nbsp;
                                    <Edit style={{ color: '#2b2d42', }} />
                              </div>
                            </GridItem>
                            <GridItem xs={1}>
                              <div
                                style={{ display: "flex", cursor: 'pointer' }}
                                onClick={() => { this.props.removeDnsEntry(item.id) }}
                              >
                                <span style={{ marginTop: '2px' }}>{`Remove`}</span>
                                &nbsp;
                                    <Close style={{ color: '#2b2d42' }} />
                              </div>
                            </GridItem>
                          </>
                        }
                        <GridItem xs={12}>
                          <hr />
                        </GridItem>
                      </GridContainer>
                    )
                  })
                }
              </div>
            }
          </GridItem>
        </GridContainer>
        <EntriesModal
          showEntriesModal={this.props.showEntriesModal}
          toggleentriesmodal={this.props.toggleentriesmodal}
          addDnsEntry={this.props.addDnsEntry}
          addingDnsEntry={this.props.addingDnsEntry}
          entriesModalData={this.props.entriesModalData}
          hostName={this.props.hostName}
        />
        <LoaderModal
          showModal={deletingDnsEntry}
          showLoader={deletingDnsEntry}
        />
      </>
    );
  }
}

const mapStateToProps = (store) => {
  const {
    websiteManagementReducer: {
      MapDomain: {
        isLoading,
        status,
        isMapping,
        mappingResponse,
        dnsrecords,
        addingDnsEntry,
        entriesModalData,
        showEntriesModal,
        deletingDnsEntry,
        hostName,
      }
    }
  } = store;
  return {
    isLoading,
    status,
    isMapping,
    mappingResponse,
    dnsrecords,
    addingDnsEntry,
    entriesModalData,
    showEntriesModal,
    deletingDnsEntry,
    hostName,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    domainCreation: (data) => dispatch(domainCreation(data)),
    fetchMapDomainStatus: () => dispatch(fetchMapDomainStatus()),
    mapping: (data) => dispatch(mapping(data)),
    getdnsrecords: () => dispatch(getdnsrecords()),
    addDnsEntry: (data) => dispatch(addDnsEntry(data)),
    setEntriesModalData: (data) => dispatch(setEntriesModalData(data)),
    toggleentriesmodal: (flag) => dispatch(toggleentriesmodal(flag)),
    removeDnsEntry: (id) => dispatch(removeDnsEntry(id)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(MapDomain);
