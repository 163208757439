import React, { Component } from 'react';
import { connect } from 'react-redux';
import GetSocialAccount, { SetSocialAccount } from "./social-account-action";
import SocialAccountForm from "./social-account-form";
import AppLoader from "../../app-components/app-loader.jsx";


class SocialAccount extends Component {
  componentDidMount() {
    this.props.GetSocialAccount()
  }
  render() {
    const { socialLink: { isLoading }, socialLink, SetSocialAccount } = this.props;
    return (
      <>
        {isLoading && <AppLoader message="Loading..." />}
        {!isLoading && <SocialAccountForm
          socialLink={socialLink}
          SetSocialAccount={SetSocialAccount} />}
      </>
    )
  }
}
const mapStateToProps = (state) => {
  const {
    websiteManagementReducer: {
      socialLink
    }
  } = state;
  return {
    socialLink
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    GetSocialAccount: () => dispatch(GetSocialAccount()),
    SetSocialAccount: (social) => dispatch(SetSocialAccount(social))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialAccount);