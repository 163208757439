import React, { PureComponent } from "react";
import $ from "jquery";
import throttle from "lodash.throttle";

class ArticleMetaTags extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { tags: [], filteredTags: [], sub_tag: [] };
    this.articleTagsDropDown = React.createRef();
  }

  componentDidMount() {
    this.props.fetchMetaTags()
      .then(data => {
        this.props.getTags(data);
        let subTagArr = [];
        const filteredTag = data.filter((obj) => this.props.tags && this.props.tags.length && obj.tag === this.props.tags[0].tag);
        if (filteredTag.length && filteredTag[0].sub_tag && filteredTag[0].sub_tag.length) {
          subTagArr = filteredTag[0].sub_tag;
        }
        this.setState({
          tagList: data,
          sub_tag: subTagArr,
          filteredTags: data
              .slice(0, 100)
              .map(({ tag, sub_tag }) => ({ value: tag, label: tag, sub_tag: sub_tag}))
        });
      })
      .catch(err => {});
  }

  componentDidUpdate() {
    this.scrollToarticleTagsDropDown();
  }
  handleChange = (selectedTag) => {
    const chosenTag = this.state.filteredTags.filter((obj) => obj.label === selectedTag.target.value);
    let oldState = this.state;
    if (chosenTag.length && chosenTag[0].sub_tag && chosenTag[0].sub_tag.length) {
      oldState.sub_tag = chosenTag[0].sub_tag;
    } else {
      oldState.sub_tag = [];
    }
    this.setState(oldState);
    this.props.setMetaTags(selectedTag.target.value);
  }
  handleSubTagChange = (selectedSubTag) => {
    let parsedValue = JSON.parse(selectedSubTag.target.value);
    this.props.setMetaSubTags(parsedValue);
  }
  scrollToarticleTagsDropDown = () => {
    this.articleTagsDropDown.current.scrollIntoView({behavior: 'smooth'});
  }

  render() {
    const { filteredTags } = this.state;
    const { tags = [] } = this.props;
    return (
      <div ref={this.articleTagsDropDown} style={{marginTop:'30px'}} onClick={this.scrollToarticleTagsDropDown}>
      {filteredTags && !!filteredTags.length && <select onChange={this.handleChange}>
        <option value={tags.length ? tags[0].tag : ''}>{tags.length ? tags[0].tag : '**Choose Tag**'}</option>
        {
          filteredTags.map((obj, i) => {
            return <option key={i} value={obj.value}>{obj.value}</option>;
          })
        }
      </select>}
      {this.state.sub_tag && !!this.state.sub_tag.length && <div style={{marginTop: '5px'}}>
        <select onChange={this.handleSubTagChange}>
          <option value={tags[0].subTagId ? JSON.stringify(tags[0]) : '{}'}>
            {tags[0].subTag ? tags[0].subTag : `****Choose Sub Tag ****`}
          </option>
          {this.state.sub_tag.map((obj) => <option value={JSON.stringify({subTagId: obj.id, subTag: obj.sub_tag})}>{obj.sub_tag}</option>)}
        </select>
      </div>}
      </div>
    )
  }
}

export default ArticleMetaTags;
