
import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Accordian from "./accordian.jsx";

class ShowCampaign extends Component {

  render() {
    const { date, campVal } = this.props;
    return (
      <Paper style={{ paddingBottom: '10px', border: '1px solid rgb(206, 211, 220)' }}>
        <h3 style={{
          fontSize: '16px',
          padding: '10px',
          fontWeight: 'bolder',
          paddingLeft: '25px',
        }}>{date}</h3>
        {
          Object.keys(campVal).map((ele, key) => {
            return <div key={key} style={{ margin: '10px', border: '1px solid rgb(206, 211, 220)' }}>
              <Accordian key={ele} campValue={campVal[ele]} />
            </div>
          })
        }
      </Paper>
    )
  }
}
export default ShowCampaign;
