import { MeawwApi } from "../utils/api.js";
import { setAppMessage } from "../app-actions.js";
import { MessageTypes } from "../app-constants";

export function fetchFbRequest(){
    return {
      type: 'fetchFbRequest'
    }
}
export function fetchFbSuccess(data){
    return {
      type: 'fetchFbSuccess',
      data
    }
}
export function fetchFbFailure(){
    return {
      type: 'fetchFbFailure'
    }
}

export function getFacebookPage() {
  return (dispatch, getState) => {
    dispatch(fetchFbRequest());
    const { activeDomain } = getState().login.user;
    const path = `/schedule-manage/${activeDomain}/page`;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(path,
          (error, data) => {
            if (error) {
              dispatch(fetchFbFailure());
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR   }));
              return Promise.reject(error);
            }
            dispatch(fetchFbSuccess(data));
            return Promise.resolve(data);
          });
      });
  }
}

export function deleteFacebookPage(page) {
  return (dispatch, getState) => {
    dispatch(deleteItemRequest());
    const { activeDomain } = getState().login.user;
    const data = {page}
    return MeawwApi()
      .auth()
      .then(api => {
        return api.delete(`/schedule-manage/${activeDomain}/token`, data,
          (error, data) => {
            if (error) {
              dispatch(deleteItemFailure());
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR   }));
              return Promise.reject(error);
            }
            dispatch(deleteItemSuccess());
            dispatch(setAppMessage(data.message || JSON.stringify(data), { type: MessageTypes.SUCCESS   }));
            dispatch(getFacebookPage());
            return Promise.resolve(data);
          });
      });
  }
}

export function storeFacebookPage(arrPages) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/schedule-manage/${activeDomain}/page`, arrPages,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}


//-----------Twitter
export function fetchTwitterRequest(){
    return {
      type: 'fetchTwitterRequest'
    }
}
export function fetchTwitterSuccess(data){
    return {
      type: 'fetchTwitterSuccess',
      data
    }
}
export function fetchTwitterFailure(){
    return {
      type: 'fetchTwitterFailure'
    }
}

export function deleteItemRequest(){
    return {
      type: 'deleteItemRequest'
    }
}
export function deleteItemSuccess(){
    return {
      type: 'deleteItemSuccess'
    }
}
export function deleteItemFailure(){
    return {
      type: 'deleteItemFailure'
    }
}

export function getTwitterPage() {
  return (dispatch, getState) => {
    dispatch(fetchTwitterRequest());
    const { activeDomain } = getState().login.user;
    const path = `/schedule/get/accounts?domain_id=${activeDomain}`;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(path,
          (error, data) => {
            if (error) {
              dispatch(fetchTwitterFailure());
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR   }));
              return Promise.reject(error);
            }
            dispatch(fetchTwitterSuccess(data));
            return Promise.resolve(data);
          });
      });
  }
}

export function deleteTwitterPage(data) {
  return (dispatch, getState) => {
    dispatch(deleteItemRequest());
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/schedule-manage/remove/accounts?id=${data.id}`,
          (error, data) => {
            if (error) {
              dispatch(deleteItemFailure());
              dispatch(setAppMessage(error, { type: MessageTypes.ERROR   }));
              return Promise.reject(error);
            }
            dispatch(deleteItemSuccess());
            dispatch(setAppMessage(data.message || JSON.stringify(data), { type: MessageTypes.SUCCESS   }));
            dispatch(getTwitterPage());
            return Promise.resolve(data);
          });
      });
  }
}

export function updateUser(userdata) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    userdata.domain_id = activeDomain;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/schedule/update/user`, userdata,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            //dispatch(setAppMessage('Account added successfully', { onOk: dispatch(getTwitterPage())  }));
            return Promise.resolve(data);
          });
      });
  }
}
