import React from 'react';
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.jsx";
import { withRouter } from 'react-router-dom';
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import AppLoader from "../../../app-components/app-loader.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { instanceOf } from 'prop-types';

class DltModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      websiteName: '',
      error : false ,
      errorMsg : '',
      isLoader : false
    }
    this.inputRef = React.createRef();
  }
  

  changeWebsiteName = (event) => {
    let { error } = this.state;
    if (this.props.domainName != event.target.value.trim() ) {
      error = true
    }
    else {
      error = false
    }
    this.setState({ websiteName: event.target.value,error,errorMsg:''});
  }

  disablePaste = (e) => {
    e.preventDefault();
    return false;
  }
  onConfirm = ()=>{
    if (this.state.websiteName.trim().length=== 0 ) {
      this.setState({ error : true,errorMsg:''});
    }
    if (this.props.domainName == this.state.websiteName.trim() ) {
      this.setState({isLoader :true});
      this.props.dltMyWebsite().then((dltdata)=>{
        this.props.fetchUserDomains().then((data) => {
          let domainId = Object.keys(data);
          let activeDomain;
          if (typeof parseInt(domainId[0]) == "number") {
            activeDomain = domainId[0];
          }
          else {
            this.props.history.push('/domain-management/addwebsite');
            this.props.history.go(1);
            this.setState({ isLoader: false });
            window.location.reload();
            return;
          }
          this.props.setActiveDomain(+activeDomain);
          this.props.fetchFeaturePermision();
          
          this.props.showMessage(dltdata)
          this.setState({isLoader :false});
        }).catch((data) => {
          let domainId = Object.keys(data);
          if (domainId.includes("message")) {
            this.props.showMessage(dltdata);
            window.location.reload();
            this.setState({ isLoader: false });
            return;
          }
          this.setState({ isLoader: false });
          window.location.reload();
        })
      }).catch((error)=>{
        this.setState({isLoader :false,errorMsg:error.message || ''});
      })
    }
  }
  render() {
    const { classes, toggleModal } = this.props;
    const {isLoader} = this.state ;
    return (
      <>
        <GridContainer>
          <GridItem xs={12} md={12}>
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.modal
              }}
              open={this.props.openDltModal}
              aria-labelledby="modal-slide-title"
              aria-describedby="modal-slide-description">
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}>
                <h4 className={classes.modalTitle}>Delete Website</h4>
                <p style={{ marginBottom:'0px'}}>
                Your website will be deleted permanently and this action can not be reverted. We will not be able to restore your website.
                </p>
                <p style={{ marginBottom:'-45px'}}>
                For deleting the website please type the exact domain name into the input box.(without https://)
                </p>
              </DialogTitle>
              <DialogContent
                className={classes.modalBody}
              >
                <CustomInput
                  ref={this.inputRef}
                  id="websitedltinput"
                  labelText="website name"
                  inputProps={{
                    onChange: this.changeWebsiteName,
                    autoComplete: 'off',
                    onPaste : this.disablePaste
                  }}
                  formControlProps={{
                    fullWidth: true, 
                  }}
                  value = {this.state.websiteName}
                  error = {this.state.error}
                  helpText = {this.state.error ?`Please type your exact website name` :''}
                />
                {this.state.errorMsg && <p style = {{marginTop:'-27px'}}>{this.state.errorMsg.toString()}</p>}
              </DialogContent>
              <DialogActions
                className={classes.modalFooter + " " + classes.modalFooterCenter}>
                <Button disabled = {isLoader} color="danger" style={{ color: 'white', background: 'red', border:'1px solid red'}} onClick={this.onConfirm}>Confirm</Button>
                <Button disabled = {isLoader} onClick={toggleModal}>close</Button>
                {isLoader && <AppLoader size={20}/>}
              </DialogActions>
            </Dialog>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

export default withRouter(withStyles(modalStyle)(DltModal));
