import React from 'react';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Card from "components/Card/Card.jsx";

function PlaceholderBtn(props){
  const message = props.message ? props.message : 'Click here to get started.'
  return (
    <div
      style={{width: '100%', display: 'flex', justifyContent: 'center', padding: '50px'}}
    >
      <Card style={{alignItems: 'center', width: '250px'}}>
          <div
            style={{width: '250px', padding: '50px', fontSize: '1.3em', textAlign: 'center', cursor: 'pointer'}}
            onClick={props.handlePlaceholder}
          >
            <p>{message}</p>
            <AddCircleIcon style={{color: '#fb4d24'}}/>
          </div>
      </Card>
    </div>
  );
}

export default PlaceholderBtn;
