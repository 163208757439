import React, { PureComponent, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchUserPermissions } from "../user-permission-action.js";
import { getUserDetails } from "../../../user-settings/user-settings-actions.js";
import { getDomainPermissions, moveLoginUserInfoToUserList } from "../../account-management/account-management-actions.js";
// import MeawwLoader from "../../../app-components/meaww-loader.jsx";
import AppLoader from "../../../app-components/app-loader.jsx";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { fetchUserList } from "../../account-management/account-management-actions.js";
import { updateUserDomainPermission, showSuccessMsg } from "../user-permission-action.js";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import UserDetailsPreview from "../components/user-details-preview.jsx";
import { clearMsg } from "../../register-user/register-user-actions";

class UserPermissions extends PureComponent {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const { match: { params: { id } },
      fetchUserPermissions,
      userDetails,
      getDomainPermissions,
      domainPermissions,
      fetchUserList
    } = this.props;
    if (id === null || id === undefined) {
      this.props.history.push("/settings/user-management/account-management");
    } else {

      fetchUserList()
        .then(() => {
          fetchUserPermissions(id);
          getDomainPermissions();

          // if (userDetails && Object.keys(userDetails).length > 0) {
          //   fetchUserPermissions(id);
          // }

          // //confirm this
          // if (domainPermissions && Object.keys(domainPermissions).length === 0) {
          //   getDomainPermissions();
          // }
        })

    }
  }

  handlePermission = permissionName => event => {
    const { match: { params: { id } }, updateUserDomainPermission, deleteDomainUserPermission } = this.props;
    updateUserDomainPermission(id, permissionName, event.target.checked);
  }
  redirectToUserPremissions = () => {
    const { msg } = this.props;
    msg && this.props.showSuccessMsg(msg);
    this.props.clearMsg();
    this.props.history.push("/settings/user-management/account-management");
  }

  render() {
    const { domainPermissions, isFetchingUserList, userDetails, loadingUserPermissions, isFetchingDomainPermissions } = this.props;
    if (isFetchingUserList || isFetchingDomainPermissions || loadingUserPermissions) {
      return <AppLoader message="Loading permissions..." />
    }
    const { permissions = [] } = userDetails;
  
    const permObj = {
      create: [],
      share: [],
      earn: [],
      notification: [],
      setting: [],
      
    }
    
    Object.keys(domainPermissions).map((perm) => {
      if (perm.indexOf('NOTIFICATION')>-1) {
        permObj.notification.push(domainPermissions[perm])
      } else if (perm.indexOf('CREATE')>-1){
        permObj.create.push(domainPermissions[perm])
      } else if (perm.indexOf('EARN')>-1) {
        permObj.earn.push(domainPermissions[perm])
      } else if (perm.indexOf('SHARE')>-1) {
        permObj.share.push(domainPermissions[perm])
      } else if (perm.indexOf('SETTING')>-1) {
        permObj.setting.push(domainPermissions[perm])
      }
    });
    return (
      <React.Fragment>
        {
          Object.keys(permObj).map(permissionItem => {
            if(permObj[permissionItem].length > 0){            
              return (
                <div className="permission-category-container">
                  <div className="permission-category-heading">{permissionItem}</div>                
                  {permObj[permissionItem].map(permission => {
                    return (
                      <div key={permission.perm} style={{ backgroundColor: "white", borderRadius: "5px" }}>
                        <GridContainer>
                          <GridItem style={{ width: "100%"}}>
                            <Fragment >
                              <div key={permission.perm} style={{ padding: "10px 0px 0px 15px" }}>
                                <h5 style={{ fontWeight: "bold", marginBottom: "0px" }}>{permission.title}</h5>
                                <FormControlLabel
                                  className="contolled-label"
                                  control={
                                    <Switch
                                      disabled={permission.isPermissionUpdating}
                                      checked={permissions.indexOf(permission.perm) > -1}
                                      onChange={this.handlePermission(permission.perm)}
                                      value={permission.perm}
                                      color="primary"
                                    />
                                  }
                                  label={<div style={{ display: "flex" }}>{permission.isPermissionUpdating && <AppLoader isMarginZero={true} size={20} />}{permission.desc}</div>}
                                />
                              </div>
                            </Fragment>
                          </GridItem>
                        </GridContainer>
      
                      </div>
                    )
                  })}
                </div>
              )          
            }
          })
        }
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button color="secondary" onClick={this.redirectToUserPremissions}>
            Done
          </Button>
        </div>

      </React.Fragment>
    )
  }
}


class UserDetails extends PureComponent {
  render() {
    const { userDetails } = this.props;
    return (
      <React.Fragment>
        <UserDetailsPreview userDetails={userDetails} />
        <UserPermissions {...this.props} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match: { params: { id: userId } } } = ownProps;
  const { msg } = state.userManagement.registerUser;
  const userDetails = state.userManagement.accountManagement.userList[userId] || {};
  const { domainPermissions, isFetchingDomainPermissions, loadingUserPermissions, isFetchingUserList } = state.userManagement.accountManagement;
  return {
    userDetails: userDetails,
    domainPermissions: domainPermissions,
    isFetchingDomainPermissions: isFetchingDomainPermissions,
    loadingUserPermissions: loadingUserPermissions,
    isFetchingUserList: isFetchingUserList,
    msg
  }
}
const mapDispatchToProps = dispatch => {
  return {
    fetchUserPermissions: (userId) => dispatch(fetchUserPermissions(userId)),
    getDomainPermissions: () => dispatch(getDomainPermissions()),
    getUserDetails: () => dispatch(getUserDetails()),
    moveLoginUserInfoToUserList: () => dispatch(moveLoginUserInfoToUserList()),
    fetchUserList: () => dispatch(fetchUserList()),
    showSuccessMsg: (msg) => dispatch(showSuccessMsg(msg)),
    clearMsg: () => dispatch(clearMsg()),
    updateUserDomainPermission: (userId, userPermission, status) => dispatch(updateUserDomainPermission(userId, userPermission, status)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDetails))
