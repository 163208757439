import React from "react";
import { connect } from "react-redux";
import debounce from "lodash.debounce";
import ContentFeed from "../components/content-feed";
import MenuDropDown from "../components/menu-dropdown.jsx";
import {
  deleteFeed,
  EditPost,
  resetFeed,
  resetFilterFeed,
  resetHeaderType,
  updateArticleContent,
  saveMediaToPisco,
  changeCoverPicture,
  fetchArticleDetails,
  getFeed,
  searchFeed
} from "../quick-cms-actions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import RoundButton from '../../../components/RoundButton/roundbutton';
import FeedFilter from "../components/filter.jsx";
import HeaderFilter from "../components/header-filter.jsx";
import AppLoader from "../../../app-components/app-loader.jsx";
import AddPostForm from "../components/add-post-form";
import AddArticleForm from "../components/add-article-form";

class ContentFeedView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditForm: false,
      showArticleEdit: false,
      anchorEl: null,
      feedId: null,
      feedContent: null,
      topButtonShow: false,
      currentFeedType: 'draft&live',
      currentHeaderType: ''
    }
    this.loadFeedOnScroll = debounce(this.loadFeedOnScroll, 200);
    this.loadButton = debounce(this.loadButton, 0);
  }
  setAnchorEl = (e) => {
    this.setState({ ...this.state, anchorEl: e });
  }
  setId = (id, feedContent) => {
    this.setState({ feedId: id, feedContent })
  }
  removeIdForMenuDropDown = () => {
    this.setState({ ...this.state, feedId: null, feedContent: null });
  }
  setFeedType = (value) => {
    this.props.resetFeed()
    this.setState({ currentFeedType: `${value == 'All' ? 'draft&live' : value.toLowerCase()}` }, () => {
      this.props.getFeed(`${value == 'All' ? 'draft&live' : value.toLowerCase()}`, `${this.props.headerTypeDefaultState == 'All' ? '' : this.props.headerTypeDefaultState.toLowerCase()}`)
    })
  }
  setHeaderType = (value) => {
    this.props.resetFeed();
    this.setState({ currentHeaderType: `${value == 'All' ? '' : value.toLowerCase()}` }, () => {
      this.props.getFeed(`${this.props.feedFilterDefaultState == 'All' ? 'draft&live' : this.props.feedFilterDefaultState.toLowerCase()}`, `${value == 'All' ? '' : value.toLowerCase()}`)
    })
  }

  togglePostForm = () => {
    this.setState((prev) => {
      return {
        showEditForm: !prev.showEditForm,
        feedId: null,
      }
    })
  }

  toggleArticleForm = () => {
    this.setState((prev) => {
      return {
        showArticleEdit: !prev.showArticleEdit,
        feedId: null,
      }
    })
  }
  // scroll
  loadFeedOnScroll = () => {
    this.props.getFeed(this.state.currentFeedType, this.state.currentHeaderType)
  }
  loadButton = () => {
    if(this.props.searchActive){
      return;
    }
    const scrolledTop = document.getElementById("mainPanel").scrollTop
    if (scrolledTop > 5) {
      if (!this.state.topButtonShow) {
        this.setState({ topButtonShow: true })
      }
    }
    else {
      if (this.state.topButtonShow) {
        this.setState({ topButtonShow: false })
      }
    }
  }

  fetchFeed = () => {
    if(this.props.searchActive){
      return;
    }
    const listHeight = document.getElementById("feed-list-container").offsetHeight;
    const windowHeight = window.innerHeight;
    const scrolledTop = document.getElementById("mainPanel").scrollTop;
    if ((listHeight - windowHeight - scrolledTop) < 200) {
      this.props.hasMoreData && this.loadFeedOnScroll();
    }
  }

  componentDidMount() {
    this.props.getFeed(`draft&live`)
    document.getElementById("mainPanel").addEventListener('scroll', this.fetchFeed);
    document.getElementById("mainPanel").addEventListener('scroll', this.loadButton);
  }

  componentWillUnmount() {
    document.getElementById("mainPanel").removeEventListener('scroll', this.fetchFeed);
    document.getElementById("mainPanel").removeEventListener('scroll', this.loadButton);;
  }

  topFunction = () => {
    document.getElementById("mainPanel").scroll({ top: 0, behavior: 'smooth' });
  }

  handleKeyUp = (e) => {
    const search = document.querySelector('#liveArticleSearch');
    const searchKeyword = search && search.value;
    if(!searchKeyword){
      this.props.getFeed(`draft&live`, '', 0);
    }
    if(e.key === 'Enter'){
      this.handleSearch(e);
    }
  }

  handleSearch = (e) => {
    const search = document.querySelector('#liveArticleSearch');
    const searchKeyword = search && search.value;
    if(searchKeyword){
      this.props.searchFeed(searchKeyword);
    } else{
      this.props.getFeed(`draft&live`, '', 0);
    }    
  }

  clearSearch = () => {
    const search = document.querySelector('#liveArticleSearch');
    search.value = '';
    this.props.getFeed(`draft&live`, '', 0);
  }

  render() {
    const { anchorEl, feedId, feedContent, showEditForm, showArticleEdit } = this.state;
    const { feed, deleteFeed, isCFMLoading, headerTypeDefaultState, feedFilterDefaultState, resetFilterFeed, resetHeaderType } = this.props;
    const { domainMap, activeDomain } = this.props;
    const subscriptionEnabled = domainMap && activeDomain && domainMap[activeDomain] && domainMap[activeDomain][0] &&  domainMap[activeDomain][0]['subscription_enabled'];
    return (
      <>

        {showEditForm &&
          <AddPostForm
            onClose={this.togglePostForm}
            showPostForm={showEditForm}
            postHandler={this.props.EditPost}
            mode="edit"
            categories={this.props.categories}
            creatingContent={this.props.creatingContent}
            feedContent={feedContent}
            subscriptionEnabled={subscriptionEnabled}
            user={this.props.user}
            saveMediaToPisco={this.props.saveMediaToPisco}
          />
        }
        {showArticleEdit &&
          <AddArticleForm
          creatingContent={this.props.creatingContent}
          changeCoverPicture={this.props.changeCoverPicture}
          onClose={this.toggleArticleForm}
          getFeed={this.props.getFeed}
          resetFeed={this.props.resetFeed}
          showArticleForm={showArticleEdit}
          saveMediaToPisco={this.props.saveMediaToPisco}
          updatePost={this.props.EditPost}
          fetchArticleDetails={this.props.fetchArticleDetails}
          mode="edit"
          categories={this.props.categories}
          feedContent={feedContent}
          updateArticleContent={this.props.updateArticleContent}
          subscriptionEnabled={subscriptionEnabled}
          user={this.props.user}
          />

        }
        <>
          {this.state.topButtonShow &&
            <RoundButton
              onclick={this.topFunction}
              type="arrow_upward"
              buttonbackground="#ced3dc"
              location={{
                right: '30px',
                bottom: "95px",
                border: '0',
                position: 'fixed',
                background: '#ced3dc',
                cursor: "pointer",
                zIndex: 9999
              }}
              iconcolor="#2b2d42"
            />}
          <div id="feed-list-container">
            <GridContainer>
              <GridItem sm={6} xs={2} style={{display: 'flex', alignItems: 'center'}}>
              <h5 style={{ fontWeight: 'bold', paddingTop: '25px' }}>FEED</h5>
              </GridItem>
              <GridItem sm={3} xs={5}>
                <FeedFilter setFeedType={this.setFeedType} feedFilterDefaultState={feedFilterDefaultState} resetFilterFeed={resetFilterFeed} />
              </GridItem>
              <GridItem sm={3} xs={5} style={{ paddingRight: '15px' }}>
                <HeaderFilter setHeaderType={this.setHeaderType} headerTypeDefaultState={headerTypeDefaultState} resetHeaderType={resetHeaderType} />
              </GridItem>
              <GridItem xs={12}>
                <div style={{ color: 'gray', fontSize: '0.8em', marginTop: '20px'}}>
                  Click on the action buttons above to add your content. Published or drafted content will appear below. Keep creating!
                </div>
              </GridItem>
            </GridContainer>
            <div style={{ position: 'relative', marginTop: "15px", minWidth: '300px' }}>
              <input 
                type="text" 
                id="liveArticleSearch"
                placeholder="Search Live articles..."
                onKeyUp={this.handleKeyUp} 
                style={{
                  width: "100%",
                  height: "46px",
                  borderRadius: "5px",
                  border: "none",
                  backgroundColor: "#f5f5f5",
                  paddingLeft: "10px",
                  paddingRight: "75px",
                  fontSize: "16px",
                  border: '1px solid #e7e7e7',
                  boxShadow: " 0 1px 8px 0 rgba(0, 0, 0, 0.06)"
                }}
              />
              <div style={{ position: "absolute", right: '15px', top: '12px', cursor: "pointer" }}>
                <i className="material-icons" color="#2B2D42" style={{paddingRight: "10px"}} onClick={this.clearSearch}>
                  close
                </i>
                <i className="material-icons" color="#2B2D42" onClick={this.handleSearch}>
                  search
                </i>
              </div>
            </div>
            <GridItem md={12} xs={12} style={{ marginLeft: '0px', marginRight: '0px' }}>
              {/* <ShowAudio/> */}
              {
                (isCFMLoading && feed.length == 0) ? <AppLoader message="loading feed ...." />
                  : <div style={{ marginTop: '20px', marginLeft: '-15px', marginRight: '-15px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    {
                      feed.length ?
                        feed.map((content, key) => {
                          return <ContentFeed
                            key={key}
                            content={content}
                            title={content.title}
                            thumbnail = {content && JSON.parse(content.headerContent) && JSON.parse(content.headerContent).thumbnail}
                            setAnchorEl={this.setAnchorEl}
                            setId={this.setId}
                          />
                        })

                        :
                        <h5>No Media Found</h5>
                    }
                    {(isCFMLoading && feed.length > 0) ? < AppLoader message="Loading feed..." /> : null}
                  </div>
              }
              <MenuDropDown
                feedId={feedId}
                anchorEl={anchorEl}
                deleteFeed={deleteFeed}
                feedContent={feedContent}
                togglePostForm={this.togglePostForm}
                toggleArticleForm={this.toggleArticleForm}
                removeIdForMenuDropDown={this.removeIdForMenuDropDown} />
            </GridItem>
          </div>
        </>
      </>
    )
  }
}


const mapStateToProps = (state) => {
  const { feed, isCFMLoading, creatingContent, categories, hasMoreData, feedFilterDefaultState, headerTypeDefaultState } = state.cms.quickCms;
  const {
    login: {
      user: {
        domainMap,
        activeDomain,
      }
    }
  } = state;
  const { id: activeUser } = state.login.user;
  return {
    user: state.userManagement.accountManagement.userList[activeUser],
    feed,
    isCFMLoading,
    creatingContent,
    categories,
    hasMoreData,
    feedFilterDefaultState,
    headerTypeDefaultState,
    domainMap,
    activeDomain,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFeed: (feedType, headerType, currentPage) => dispatch(getFeed(feedType, headerType, currentPage)),
    deleteFeed: (id, feedContent) => dispatch(deleteFeed(id, feedContent)),
    EditPost: (articleId, data, status) => dispatch(EditPost(articleId, data, status)),
    resetFeed: () => dispatch(resetFeed()),
    resetFilterFeed: (feedType) => dispatch(resetFilterFeed(feedType)),
    saveMediaToPisco: (file, cb) => dispatch(saveMediaToPisco(file, cb)),
    resetHeaderType: (headerType) => dispatch(resetHeaderType(headerType)),
    fetchArticleDetails: (articleId) => dispatch(fetchArticleDetails(articleId)),
    changeCoverPicture: (coverImageForm, articleId) => dispatch(changeCoverPicture(coverImageForm, articleId)),
    updateArticleContent: (htmlContent, mapObject, articleId) => dispatch(updateArticleContent(htmlContent, mapObject, articleId)),
    searchFeed: (searchKeyword) => dispatch(searchFeed(searchKeyword)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentFeedView);
