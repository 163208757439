import React from "react";
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.jsx";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";

const AddNewUser = () => (
    <div style={{ marginBottom: "10px", marginLeft: "-15px" }}>
        <Link to={"/user-management/register-user"}>
            <Button color="primary" >
                <AddCircleOutline /> Add New Page
         </Button>
        </Link>
    </div>
);

export default AddNewUser;


