import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import { connect } from 'react-redux';
import moment from "moment";
import Schedule from "./schedule.jsx";
import {
  schedulePost,
  getFacbookPage,
  removeRefreshPages,
  closeLoaderModal
} from "../../schedule-action.js";

import {setAppMessage} from '../../../app-actions.js';
import { MessageTypes } from "../../../app-constants/index.js";
import AppLoader from "../../../app-components/app-loader.jsx";

class PostOnPage extends React.Component {
  constructor(props) {
    super(props);
    this.getSumittedData = this.getSumittedData.bind(this);
    this.getAllPages = this.getAllPages.bind(this);
    this.state = {
      success: '',
      error: '',
      pages: [],
    };
    this.getAllPages();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.removeRefreshPages) {
      this.props.removeRefreshPages();
      this.getAllPages();
    }
  }

  getSumittedData(data) {
    //console.log(`data`, data);
    let type = 'feed';
    // Temporary solution will fix later [START]
    if (data.media && Object.values(data.media).length && Object.values(data.media)[0] && Object.values(data.media)[0].type) {
      type = Object.values(data.media)[0].type && typeof Object.values(data.media)[0].type === 'string' ? Object.values(data.media)[0].type.toLowerCase() : type;
    }
    if (data.link === '' && data.message !== '') {
      data.link = data.message;
    }
    // Temporary solution will fix later [END]
    let formattedData = {
      "page": {
        "name": data.pageName
      },
      "data": {
        "type": type,
        "url": data.link || '',
        "caption": data.caption || '',
        "time": moment(data.time).unix(),
        "media": data.media || {},
      },
    };
    formattedData = data.message === '' ? formattedData : { ...formattedData, data: { ...formattedData.data, custommessage: data.message } };
    //console.log(`formatted data`, formattedData);
    //this.props.schedulePost(formattedData);

    this.props.schedulePost(formattedData).then(res => {

    }, _ => {
      if (_.reason && _.reason.err && _.reason.err.length) {
        this.setState({ error: _.reason.err });
      } else {
        this.setState({ error: _.err });
      }
    });
  }

  getAllPages() {
    this.props.getFacbookPage().then(res => {
      this.setState({ pages: res });
    }, _ => {
      //console.log('Error in getting Fb page from Db', _);
      this.setState({ error: 'Error in getting Fb page from Db' });
    });
  }

  render() {
    if(this.props.isLoadingFacebook) {
      return <AppLoader />
    }
    return (
      <GridContainer justify="flex-start">
        <h4 style={{ color: '#00FF00' }}>{this.state.error}</h4>
        <Schedule
          formData={this.getSumittedData}
          pages={this.state.pages}
          user={this.props.user}
          scheduleInProgress={this.props.scheduleInProgress}
          showLoader={this.props.showLoader}
          loaderMessage={this.props.loaderMessage}
          showCloseBtn={this.props.showCloseBtn}
          closeLoaderModal={this.props.closeLoaderModal}
          setAppMessage={this.props.setAppMessage}
        />
      </GridContainer>
    );
  }
}
const mapStateToProps = (store) => {
  const { login: { user } } = store;
  const {
    scheduleReducers: {
      scheduleReducer: {
        scheduleInProgress,
        refreshPages,
        showLoader,
        loaderMessage,
        showCloseBtn
      } } } = store;
  return { user, scheduleInProgress, refreshPages, showLoader, loaderMessage, showCloseBtn };
}
const mapDispatchToProps = (dispatch) => {
  return {
    getFacbookPage: () => dispatch(getFacbookPage()),
    schedulePost: (data) => dispatch(schedulePost(data)),
    removeRefreshPages: () => dispatch(removeRefreshPages()),
    closeLoaderModal: () => dispatch(closeLoaderModal()),
    setAppMessage: (message, obj) => dispatch(setAppMessage(message, obj)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PostOnPage);
