import React from "react";
//pages
import Apps from "@material-ui/icons/Apps";
import RegisterUserPage from "./register-user/register-user-page.jsx";
import AccountManagementPage from "./account-management/accout-management-page.jsx";
import UserPermission from "./user-permission/user-permission-page.jsx";
import { default as Icons } from "../components/Icon/App.jsx";
import managementsvg from "../assets/icons/management.svg";

const Icon = () => <Icons src={managementsvg} />;

var userManagementRoutes = [
  // {
  //   collapse: true,
  //   path: "/user-management",
  //   name: "User Management",
  //   state: "userManagement",
  //   icon: Apps,
  //   component: UserManagementPage,
  //   views: [
  {
    path: "/settings/user-management/register-user",
    name: "Register New User",
    mini: "RU",
    icon: Apps,
    component: RegisterUserPage,
    dontShowInSideBar: true,
    requiredPermissions: ["SETTING:USER_MANAGEMENT"],
    isRequiredAuth: true
  },
  {
    path: "/settings/user-management/account-management",
    name: "User Management",
    mini: "UM",
    icon: Icon,
    component: AccountManagementPage,
    requiredPermissions: ["SETTING:USER_MANAGEMENT"],
    isRequiredAuth: true
  },
  {
    path: "/settings/user-management/user-details/:id",
    name: "Edit Permission",
    mini: "EP",
    icon: Apps,
    dontShowInSideBar: true,
    component: UserPermission,
    requiredPermissions: ["SETTING:USER_MANAGEMENT"],
    isRequiredAuth: true
  }
  // ]
  // }
];

export default userManagementRoutes;
