import { UserAction } from "../app-constants/index.js";


const userSettingsDefault = {
    isUpdatingUserDetails: false,
    isFetchingUserDetails: false,
    featurePermision: []
}
const userSettings = (state = userSettingsDefault, action) => {
    switch (action.type) {
        case UserAction.USER.UPDATE_PROFILE.REQUEST: {
            return { ...state, isUpdatingUserDetails: true }
        }
        case UserAction.USER.UPDATE_PROFILE.SUCCESS: {
            return { ...state, isUpdatingUserDetails: false }
        }
        case UserAction.USER.UPDATE_PROFILE.FAILURE: {
            return { ...state, isUpdatingUserDetails: false }
        }

        //getting user details
        case UserAction.SET.USER_DATA.REQUEST: {
            return { ...state, isFetchingUserDetails: true }
        }
        case UserAction.SET.USER_DATA.FAILURE: {
            return { ...state, isFetchingUserDetails: false }
        }
        case UserAction.SET.USER_DATA.SUCCESS: {
            return { ...state, isFetchingUserDetails: false }
        }
        default: return state;
    }

}



export default userSettings;