// ##############################
// // // RegisterPage view styles
// #############################

import {
  container,
  cardTitle
} from "assets/jss/material-dashboard-pro-react.jsx";

import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

const registerPageStyle = {
  ...customCheckboxRadioSwitch,
  cardTitle: {
    ...cardTitle,
    textAlign: "center"
  },
  container: {
    ...container,
    position: "relative",
    zIndex: "3"
    // paddingTop: "23vh"
  },
  cardSignup: {
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    marginBottom: "100px",
    padding: "40px 0px",
    // marginTop: "35vh"
  },
  center: {
    textAlign: "center"
  },
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  margin_top: {
    marginTop: '5%',
  },
  form: {
    padding: "0 20px",
    position: "relative"
  },
  socialTitle: {
    fontSize: "18px"
  },
  inputAdornment: {
    marginRight: "18px",
    position: "relative"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  customFormControlClasses: {
    margin: "0 12px"
  },
  checkboxLabelControl: {
    margin: "0"
  },
  checkboxLabel: {
    marginLeft: "6px",
    color: "rgba(0, 0, 0, 0.26)"
  },
  formCardWidth: {
    width: '60%',
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  createWebSiteButton: {
    padding: '15px',
    borderRadius: '5px',
    fontSize: '16px',
    textTransform: 'uppercase',
    cursor: 'pointer',
    letterSpacing: '1px',
    fontWeight: '600',
    color: '#3C4858',
    border: '1px solid #3C4858',
    // boxShadow: '0 4px 19px 2px rgba(0, 0, 0, 0.14), 0 6px 15px 10px rgba(0, 0, 0, 0.12), 0 1px 5px -1px rgba(0, 0, 0, 0.2)',
    "&:hover": {
      background: '#fb4d24',
      color: 'white',
      border: '1px solid white',
    }
  }
};

export default registerPageStyle;
