import React from "react";
import { connect } from "react-redux";
import getFeedsList, {
  getLatestArticles,
  getFeedArticles,
  updateFeedPicks,
  showErrorMessage,
  deleteCustomFeeds,
  createNewFeed,
} from "../editer-pick-action.js";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import FeedCard from "../components/feed-card";
//import Button from "@material-ui/core/Button";
import Button from "components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from '@material-ui/core/TextField';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { camelCase } from "lodash";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import AppLoader from "../../../app-components/app-loader.jsx";

class EditorPicksList extends React.Component {

  state = {
    showNewFeedDailog: false,
    feedTitle: null,
  }
  componentDidMount() {
    this.props.getFeedsList();
  }

  toggleFeedNameModal = () => {
    this.setState(prev => { return { showNewFeedDailog: !prev.showNewFeedDailog } });
  }

  handleFeedNameInput = (event) => {
    this.setState({ feedTitle: event.target.value });
  }
  deleteFeeds = (feedName) => {
    return this.props.deleteCustomFeeds(feedName).then(res => {
      this.props.getFeedsList();
    }).catch(err => console.log);
  }

  addNewCustomFeed = () => {
    let { feedTitle } = this.state;
    const { feedsList } = this.props;
    // const already = feedsList.filter(item => item.pickName == camelCase(feedName));
    // if (already.length > 0) {
    //   this.props.showErrorMessage('Feed Already Exists.');
    //   this.toggleFeedNameModal();
    //   return;
    // }
    this.props.createNewFeed(feedTitle, [], false).then(res => {
      this.setState({ feedTitle: null });
      this.props.getFeedsList();
    }).catch(res => console.log).finally(() => {
      this.toggleFeedNameModal();
    });
  }
  render() {
    const {
      feedsList,
      isEditerPickLoading,
      updateFeedPicks,
      getFeedArticles,
      getLatestArticles,
    } = this.props;
    return (
      <div>
        <GridContainer style={{ color: "red" }}>
          <GridItem>
            <div onClick={this.toggleFeedNameModal} style={{ display: "flex", alignItems: "center", cursor: "pointer", margin: "20px 0 20px" }}>
              <AddCircleIcon style={{color: "#2b2d42"}} />&nbsp;&nbsp;<span style={{ color: "black" }}>New Feed</span>
            </div>
          </GridItem>
        </GridContainer>
        {/* <Button variant="contained" color="secondary" style={{ marginBottom: "10px"}} onClick={this.toggleFeedNameModal}>
          New Feed
        </Button> */}
        {isEditerPickLoading && <AppLoader size={25} />}
        {feedsList.map((item) => {
          return (
            <FeedCard
              feed={item}
              key={item.pickName}
              getFeedArticles={getFeedArticles}
              getLatestArticles={getLatestArticles}
              updateFeedPicks={updateFeedPicks}
              deleteCustomFeeds={this.deleteFeeds.bind(this, item.pickName)}
            />
          )
        })}
        <Dialog
          open={this.state.showNewFeedDailog}
          fullWidth={true}
          onClose={this.toggleFeedNameModal}
          maxWidth="sm"
          aria-labelledby="customized-dialog-title">
          <DialogTitle>Add New Feed</DialogTitle>
          <DialogContent>
            <CustomInput
              autoFocus
              margin="normal"
              id="name"
              infoText="Enter a valid Feed name."
              labelText="Feed Name"
              type="text"
              value={this.state.feedTitle || ''}
              inputProps={{
                onChange: this.handleFeedNameInput
              }}
              formControlProps={{
                fullWidth: true
              }}
            />
          </DialogContent>
          <DialogActions>

            <Button color="secondary" onClick={this.addNewCustomFeed}>
              Add Feed
          </Button>
            <Button color="primary" onClick={this.toggleFeedNameModal}>
              Close
          </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    editerPick: {
      feedsList,
      isEditerPickLoading,
    }
  } = state.tool;

  return {
    feedsList,
    isEditerPickLoading,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFeedsList: (feedsList) => dispatch(getFeedsList(feedsList)),
    getFeedArticles: (feedName) => dispatch(getFeedArticles(feedName)),
    getLatestArticles: () => dispatch(getLatestArticles()),
    updateFeedPicks: (feedName, picks, alreadyExist) => dispatch(updateFeedPicks(feedName, picks, alreadyExist)),
    deleteCustomFeeds: (feedName) => dispatch(deleteCustomFeeds(feedName)),
    createNewFeed: (feedTitle) => dispatch(createNewFeed(feedTitle)),

    showErrorMessage: (msg) => dispatch(showErrorMessage(msg))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditorPicksList)
