import React from 'react';
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.jsx";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

class DisclaimerModal extends React.Component {
  state= {
    id: null,
    title: '',
    description: ''
  }
  componentWillReceiveProps(nextProps){
      if(nextProps.disclaimerModalData){
        this.setState(nextProps.disclaimerModalData);
      }
  }

  handleChange = (event, field) => {
    this.setState({[field]: event.target.value});
  }

  changeTitle = (event) => {
    this.setState({title: event.target.value});
  }
  changeDescription = (event) => {
    this.setState({description: event.target.value});
  }
  render(){
      const { classes, disclaimerModalData } = this.props;
      return (
        <>
          <GridContainer>
            <GridItem xs={12} md={12}>
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.modal
              }}
              open={this.props.showDisclaimerModal}
              aria-labelledby="modal-slide-title"
              aria-describedby="modal-slide-description">
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}>
                <Button
                  justIcon
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="transparent"
                  onClick={() => this.props.toggleDisclaimerModal(false)}
                >
                  <Close className={classes.modalClose} />
                </Button>
                <h4 className={classes.modalTitle}>Add/Edit Disclaimer</h4>
              </DialogTitle>
              <DialogContent
                id="modal-slide-description"
                className={classes.modalBody}
              >
                <CustomInput
                  id="title"
                  labelText="Add Title"
                  inputProps={{
                    onChange: (event)=>{this.handleChange(event, 'title')}
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                  value={this.state.title}
                />
                <CustomInput
                  id="description"
                  labelText="Add Description"
                  inputProps={{
                    onChange: (event)=>{this.handleChange(event, 'description')},
                    multiline: true,
                    rowsMax: 15
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                  value={this.state.description}
                />
              </DialogContent>
              <DialogActions
                className={classes.modalFooter +" " +classes.modalFooterCenter}>
                <Button
                  onClick={() => this.props.toggleDisclaimerModal(false)}
                  >
                  Close
                </Button>
                <Button
                  onClick={() => {this.props.submitDisclaimer(this.state)}}
                  style={{backgroundColor: '#fb4d24'}}>
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
            </GridItem>
          </GridContainer>
        </>
      );
    }
  }

export default withStyles(modalStyle)(DisclaimerModal);
