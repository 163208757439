import React, { PureComponent } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Stepper from "@material-ui/core/Stepper";
import StepLabel from "@material-ui/core/StepLabel";
import Step from "@material-ui/core/Step";
import { withStyles, Typography, IconButton } from "@material-ui/core";
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';

const CustomDialogTitle = withStyles(theme => ({
  root: {
    // borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, toggleProgressModal } = props;
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="subheading">
        <div style={{ display: "flex", justifyContent: "center", fontWeight: "bold" }}>
          {children}
        </div>
      </Typography>
      <IconButton aria-label="Close" className={classes.closeButton} onClick={() => {
        toggleProgressModal(false)
      }}>
        <CloseIcon />
      </IconButton>

    </DialogTitle>
  );
});

class ProgressModal extends PureComponent {
  render() {
    const {
      showDraftProgressModal,
      toggleProgressModal,
      progressModalData
    } = this.props;
    let failureStatus = false;
    const enableCloseButton = (!failureStatus && progressModalData && progressModalData[progressModalData.length-1] && (progressModalData[progressModalData.length-1][0] === 'Moved to draft'));
    for( let item of progressModalData){
      failureStatus = !item[2] ? true : failureStatus;
    }

    return (
      <Dialog
        id="publishArticleContainer"
        open={showDraftProgressModal}
        fullWidth={true}
        onClose={this.hide}
        maxWidth="sm"
        aria-labelledby="customized-dialog-title">
        <CustomDialogTitle progressModalData={progressModalData} failureStatus={failureStatus} toggleProgressModal={toggleProgressModal}>Article Draft Progress</CustomDialogTitle>
        <DialogContent>
          <Stepper orientation="vertical" activeStep={progressModalData.length}>
            {progressModalData.map((item, index) => {
              const labelProps = {};
              labelProps.children = <CustomDesc item={item}/>
              if (item[2] === 0) {
                labelProps.StepIconProps = {
                  icon: <CloseIcon style={{ ...styles.error, marginBottom: "5px" }} />
                }
              }
              if (item[2] === 1) {
                labelProps.StepIconProps = {
                  icon: <DoneIcon style={{...styles.done, marginBottom:"5px"}}/>
                }
              }
              if (item[2] === 2) {
                labelProps.StepIconProps = {
                  icon: <WarningIcon style={{...styles.todo, marginBottom:"5px"}}/>
                }
              }
              return (
                <Step key={index}>
                  <StepLabel {...labelProps}></StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </DialogContent>
        <DialogActions>
        {
          enableCloseButton
          &&
          <Button style={{color: '#fb4d24'}} onClick={() => toggleProgressModal(false)}>
            Close
          </Button>
        }
        {
          !enableCloseButton
          &&
          <Button style={{color: 'gray'}}>
            Close
          </Button>
        }
        </DialogActions>
      </Dialog>
    )
  }
}

function CustomDesc(props) {
  const { item } = props;
  return (<span style={styles[item[2]]}>{item[0]} {item[1]}</span>)
}

const styles = {
  done: {
    color: "#008000",
  },
  error: {
    color: "#DC143C",
  },
  todo: {
    color: "#A9A9A9",
  }
}

export default ProgressModal;
