import React, { PureComponent } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from '@material-ui/core/Tooltip';
import feedStyle from "../../../assets/jss/material-dashboard-pro-react/components/feedStyle.jsx";

class InstaCard extends PureComponent {
  componentDidMount() {
    if ('instgrm' in window) {
      // window.instgrm.Embeds.process();
    }
  }
  handleMove() {
    const { moveFeed, idStr } = this.props;
    moveFeed(idStr, "instaFeeds", "highlight")
  }
  hidehighlight() {
    const { idStr, moveFeed } = this.props;
    moveFeed(idStr, "instaFeeds", "unhighlight")
  }
  render() {
    const { shortcode, highlight, idStr, classes } = this.props;
    return (
      <div id={`${highlight ? 'highlight' + idStr : 'unhighlight' + idStr}`} style={{ display:'inline-block',marginRight:'2%',textAlign:'justify',height: '300px', overflowY: 'scroll', marginTop: '16px' }}>
        <Tooltip title={`Click to ${highlight ? 'unhighlight' : 'highlight'}`} placement="right">
          <i className={`material-icons ${classes.hightlight}`} onClick={_ => {
            highlight ? this.hidehighlight() : this.handleMove();
          }}>
        {highlight ? 'bookmark' : 'bookmark_border'}
        </i></Tooltip>
        <blockquote className={classes.instaCard + " " + 'instagram-media'}
          id="coustom-istagram-card"
          data-instgrm-captioned
          data-instgrm-permalink={`https://www.instagram.com/p/${shortcode}/`}
          data-instgrm-version='9' />
      </div>
    )
  }
}
export default withStyles(feedStyle)(InstaCard);
