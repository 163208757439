
const articlePreviewStyle = theme => {
  return {
    palette: {
      buttonSecondColor: "blue"
    },
    dialogPaper: {
      maxWidth: 'none',
      "@media (max-width: 400px)": {
        width: "auto",
        margin: '0px'
      },
    },
    dialogPaperLG: {
      margin: 'auto',
      width: '750px',
      "@media (max-width: 480px)": {
        width: "auto",
      },
    },
    dialogPaperSM: {
      margin: 'auto',
      width: '500px',
      "@media (max-width: 480px)": {
        width: "auto",
      },
    },
    styleLG: {
      fontSize: '38px',
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.31,
      letterSpacing: 'normal',
      textAlign: 'left',
      color: '#140f2d',

    },
    styleSM: {
      fontSize: '38px',
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.31,
      letterSpacing: 'normal',
      textAlign: 'left',
      color: '#140f2d',
      wordBreak: "break-word"
    },
    summaryStyleSM: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      color: "#767676",
      fontSize: '16px',
      fontStyle: 'italic',
      fontWeight: 400,
      lineHeight: 1.4,
      marginBottom: '15px'
    },
    summaryStyleLG: {
      fontFamily: 'IBM Plex Sans, sans-serif',
      color: "#767676",
      fontSize: '16px',
      fontStyle: 'italic',
      fontWeight: 400,
      lineHeight: 1.4,
      marginBottom: '15px'
    },
    embedStyleSM: {
      fontSize: "19px",
      fontWeight: 400,
      color: '#222',
      marginBottom: '15px',
      lineHeight: 1.6,
      letterSpacing: 0,
      '& .blockquote-container iframe , p iframe': {
        width: '100vw!important',
        maxWidth: '100% !important'
      }


    },
    embedStyleLG: {
      fontSize: "19px",
      fontWeight: 400,
      color: '#222',
      marginBottom: '15px',
      lineHeight: 1.6,
      letterSpacing: 0,
      "iFrame": {
        maxWidth: "100%"
      },
      "img": {
        maxWidth: "100%"
      }
    },
  };
}

export default articlePreviewStyle;
