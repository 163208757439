import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import { getPost, deletePost, reschedulePost, hasReceivedNewScheduleData, changeSortMethod } from "../../schedule-action.js";
import { connect } from 'react-redux';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Assignment from "@material-ui/icons/Assignment";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Modal from "components/Modal/modal.jsx";
import PostOnPage from '../component/schedule';
import moment from "moment";
import DateTime from './time.jsx';
import Button from "components/CustomButtons/Button.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import AppLoader from "../../../app-components/app-loader.jsx";

import {setAppMessage} from '../../../app-actions.js';

class ListPost extends React.Component {
  constructor(props) {
    super(props);
    this.getPageData = this.getPageData.bind(this);
    this.getDeleteComponent = this.getDeleteComponent.bind(this);
    this.callDeleteApi = this.callDeleteApi.bind(this);
    this.getTimeCoponent = this.getTimeCoponent.bind(this);
    this.onUpdateFieldChange = this.onUpdateFieldChange.bind(this);
    this.getUpdateModal = this.getUpdateModal.bind(this);
    this.callReScheduleApi = this.callReScheduleApi.bind(this);
    this.state = {
      tableData: [],
      testData: [],
      time: '',
      message: '',
      reschedule: {
        link: '',
        time: '',
        postId: 0,
      },
      imgSrcObj: {},
      a: '',
      sortArr: [
        'Time',
        'Title',
        'Caption',
        'Page Name'
      ],
      sortOrder: '(descending)',
      dropDownMessage: 'Time(descending)'
    };
  }
  componentDidMount() {
    this.getPageData();
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.scheduleDataUpdated === true){
      this.getPageData();
    }
  }
  callDeleteApi(postId, type) {
      this.props.deletePost({postId}).then(data => {
        this.getPageData();
      }, _ => {
        if (_.message) {
          // incompete error handling
          var t = <Modal bodyText={_.message} onCancelClick={()=>{}} onSuccessClick={()=>{}}/>;
          var Modal = React.createElement("Modal", {bodyText:_.message, onCancelClick: ()=>{}, onSuccessClick: ()=>{}});
        }
      });
  }
  callReScheduleApi() {
      moment(this.state.reschedule.time).unix()
      this.props.reschedulePost(this.state.reschedule).then(data => {
        this.getPageData();
      }, _ => {
        //Handle Error
      });
  }
  onUpdateFieldChange(postId, link, time) {
    let reschedule = this.state.reschedule;
    reschedule.postId = postId;
    reschedule.link = link;
    reschedule.time = moment(time).unix();
    this.setState({reschedule: {...reschedule}});
  }
  getUpdateModal (postId, link) {
    return <Modal bodyText={this.getTimeCoponent(postId, link)} modalTitle='Reschedule' buttonTitle = 'Re-Schedule' onCancelClick={()=>{}} onSuccessClick={this.callReScheduleApi}/>;
  }
  getTimeCoponent(postId, link) {
    return <DateTime time={this.onUpdateFieldChange.bind(null, postId, link)} setAppMessage={this.props.setAppMessage}/>
  }
  getDeleteComponent(postId, type) {
    const d = <p>Delete Post from Page</p>;
    return <Modal bodyText={d} modalTitle='Delete' buttonTitle = 'Delete' onCancelClick={()=>{}} onSuccessClick={this.callDeleteApi.bind(null, postId, 'Delete')}/>;
  }

  getPageData(sortMethod=this.props.sortMethod) {
    let sortOrder = '';
    if(sortMethod===this.props.sortMethod)
      sortOrder = this.state.sortOrder==='(ascending)'?'(descending)':'(ascending)';
    else sortOrder = this.state.sortOrder;
    let dropDownMessage = `${sortMethod}${sortOrder}`;
    this.props.changeSortMethod(sortMethod);
    this.setState({...this.state, dropDownMessage, sortOrder: sortOrder});
    this.props.getPost().then(data => {
      //console.log(`data`, data);
      switch(sortMethod){
        case 'Time': {
          if(sortOrder==='(ascending)')
            data = data.sort((a,b) => (a.time > b.time) ? 1 : -1);
          else
            data = data.sort((a,b) => (a.time < b.time) ? 1 : -1);
          break;
        }
        case 'Title': {
          if(sortOrder==='(ascending)')
            data = data.sort((a,b) => (a.title > b.title) ? 1 : -1);
          else
            data = data.sort((a,b) => (a.title < b.title) ? 1 : -1);
          break;
        }
        case 'Caption': {
          if(sortOrder==='(ascending)')
            data = data.sort((a,b) => (a.post_caption > b.post_caption) ? 1 : -1);
          else
            data = data.sort((a,b) => (a.post_caption < b.post_caption) ? 1 : -1);
          break;
        }
        case 'Page Name': {
          if(sortOrder==='(ascending)')
            data = data.sort((a,b) => (a.page_name > b.page_name) ? 1 : -1);
          else
            data = data.sort((a,b) => (a.page_name < b.page_name) ? 1 : -1);
          break;
        }
        default: {
          if(sortOrder==='(ascending)')
            data = data.sort((a,b) => (a.time > b.time) ? 1 : -1);
          else
            data = data.sort((a,b) => (a.time < b.time) ? 1 : -1);
          break;
        }
      }
      let formateData = data.map(obj => {
        let imgLinkTag = '';
        if (obj.post_type === 'image' && obj.multiple) {
          imgLinkTag = <img src={obj.asset[0].url} width="100%"/>;
        } else if (obj.post_type === 'image' && obj.multiple === 0 ) {
          imgLinkTag = <img src={obj.link} width="100%"/>;
        } else if (obj.post_type === 'video') {
          const vidSrc = `//players.brightcove.net/6015698090001/gUY2vwlJSg_default/index.html?videoId=${obj.asset.brightcove_video_id}`;
          imgLinkTag = <div style={{position: 'relative', display: 'block', maxWidth: '960px'}}>
            <div style={{paddingTop: '56.25%'}}>
              <iframe src={vidSrc} allowFullScreen webkitallowfullscreen="true" mozallowfullscreen="true" allow="encrypted-media"
                style={{position: 'absolute', top: '0px', right: '0px', bottom: '0px', left: '0px', width: '100%', height: '100%'}}>
              </iframe>
            </div>
           </div>
        }
        else {
          imgLinkTag = obj.metadata && obj.metadata.image && obj.metadata.image.url && obj.metadata.image.url ? <img src={obj.metadata.image.url} width="100%"/> : '';
        }
        const dateTime = moment(new Date(obj.time * 1000)).format("MMM DD, YYYY h:mmA");
        return [
          <GridContainer justify="flex-start">
            <GridItem xs={3} sm={3} md={3}>
              {imgLinkTag}
            </GridItem>
            <GridItem xs={9} sm={9} md={9}>
            {obj.title}
            </GridItem>
          </GridContainer>,
          obj.post_caption,
          obj.page_name,
          dateTime,
          <CustomDropdown
            buttonText="Select Action"
            dropdownList={[ this.getDeleteComponent(obj.post_id, 'delete'), this.getUpdateModal(obj.post_id, obj.link)]}
          />
        ];
      });
      this.setState({ tableData: formateData });
    }, _ => {
      // Show error Message to the user, I will be adding it later
    });
    this.props.hasReceivedNewScheduleData();
  }

  changeSortMethod = (sortMethod) => {
    this.getPageData(sortMethod);
  }

  render() {
    const { classes } = this.props;
    if(this.props.isLoadingFacebook) {
      return <></>
    }
    return (
      <GridContainer justify="flex-start">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 style={{'color': '#000'}}>All Scheduled Posts</h4>
              <h4 style={{'color': '#000'}}>{this.state.message}</h4>
              {
                this.state.tableData.length > 0 && <div style={{float: 'right'}}>
                <CustomDropdown
                  buttonText="SORT BY"
                  dropdownList={this.state.sortArr}
                  onClick={this.changeSortMethod.bind(null)}
                />
                </div>
              }
            </CardHeader>
            <CardBody>
          {  this.state.tableData.length > 0 && <p>
              Sorting by <b>{this.state.dropDownMessage}</b>
            </p>
          }
            {this.state.tableData.length > 0 ? (
              <Table
                tableHead={[
                  `Content`,
                  "Caption",
                  "Page",
                  "Scheduled Time (IST)",
                  "Action"
                ]}
                tableData={this.state.tableData}
              />
            ) : ''}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
const mapStateToProps = (store) => {
  let {scheduleReducers: {scheduleReducer: {scheduleDataUpdated, sortMethod, sortOrder}}} = store;
  return {scheduleDataUpdated, sortMethod, sortOrder};
}
const mapDispatchToProps = (dispatch) => {
  return {
    getPost: () => dispatch(getPost()),
    deletePost: (data) => dispatch(deletePost(data)),
    reschedulePost: (data) => dispatch(reschedulePost(data)),
    hasReceivedNewScheduleData: () => dispatch(hasReceivedNewScheduleData()),
    changeSortMethod: (sortMethod) => dispatch(changeSortMethod(sortMethod)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ListPost);
