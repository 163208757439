import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import moment from "moment";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import DailyECPMGraph from "./daily-ecpm-graph.jsx";
import DailyRevenueGraph from "./daily-revenue-graph.jsx";
import RevenueStats from "./revenue-stats.jsx";
import { setRevenuesDateRangesInterval } from "../revenue-actions.js";

import RevenueCard from "../components/revenue-card.jsx";
import RevenueDateRangePicker from "../components/revenue-date-ranges.jsx";
import SelectedDateRange from "../components/selected-date-range.jsx";
import { setAppMessage } from "../../../app-actions.js";

class VariableTimeSpecificRevenues extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showCalendar: false,
      dateRange: {
        selection: {
          startDate: props.startDate.toDate(),
          endDate: props.endDate.toDate(),
          key: 'selection',
        },
      }
    }
  }

  setDateRanges = (payload) => {
    const { setDateRangeInterval } = this.props;
    this.setState({
      dateRange: {
        ...this.state["dateRange"],
        ...payload,
      },
    });

    const { selection: { startDate, endDate } } = payload;
    if (startDate.getTime() !== endDate.getTime()) {
      this.setState({ showCalendar: false });
      setDateRangeInterval(moment(startDate), moment(endDate));
    }
  }

  openCalendar = () => {
    this.setState({
      showCalendar: !this.state.showCalendar
    })
  }
  render() {
    const { startDate, endDate, isLoadingRevenues } = this.props;
    const { showCalendar, dateRange } = this.state;
    return (
      <div style={{
        boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
        background: "white",
        padding: "10px",
        borderRadius: "5px"
      }}>
        <GridContainer justify="flex-start">
          <GridItem xs={12} sm={12} md={12}>
            <div style={{ position: "relative", paddingBottom: "25px", marginTop: "2px" }}>
              <div style={{ position: "absolute" }} >
                <SelectedDateRange startDate={startDate} endDate={endDate} onClick={this.openCalendar} />
                <RevenueDateRangePicker
                  style={{ position: "absolute", right: "0px", marginTop:'60px' }}
                  showCalendar={showCalendar}
                  dateRange={dateRange}
                  setDateRanges={this.setDateRanges}
                  style={{ zIndex: "200" }}
                  startDate={startDate}
                  endDate={endDate}
                />
              </div>
            </div>
          </GridItem >
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <DailyECPMGraph renderGraph={(props) => <RevenueCard {...props} startDate={startDate} endDate={endDate} isLoadingRevenues={isLoadingRevenues} />} />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <DailyRevenueGraph renderGraph={(props) => <RevenueCard {...props} startDate={startDate} endDate={endDate} isLoadingRevenues={isLoadingRevenues} />} />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <RevenueStats renderTable={(props) => <RevenueCard {...props} startDate={startDate} endDate={endDate} isLoadingRevenues={isLoadingRevenues} />} />
          </GridItem>
        </GridContainer>
      </div >)
  }
}

const mapStateToProps = (state) => {
  const { startDate, endDate, isLoadingRevenues } = state.analysis.revenue.variableTimeSpecificRevenues.variableRevenuesSettings
  return {
    startDate,
    endDate,
    isLoadingRevenues,
    activeDomain: state.login.user.activeDomain
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setDateRangeInterval: (startDate, endDate) => dispatch(setRevenuesDateRangesInterval(startDate, endDate)),
    setAppMessage: (msg, params) => dispatch(setAppMessage(msg, params))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(VariableTimeSpecificRevenues);
