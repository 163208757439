import React from 'react';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import withStyles from "@material-ui/core/styles/withStyles";

import TokenUpdate from "./access-token.jsx";
import TokenDelete from "./access-token-delete.jsx";

import articleHomeStyle from '../../assets/jss/material-dashboard-pro-react/views/articlehomeStyle';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 2 }}>
      {props.children}
    </Typography>
  );
}

class TokenHome extends React.Component{
  state = {
    value: 0,
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };

  render(){
    const { classes } = this.props;
    const { value } = this.state;
    return (
      <Paper xs={12} sm={12} md={12} className={classes.root} >
        <Tabs
          fullWidth
          value={value}
          onChange={this.handleChange}
          scrollable
          scrollButtons="on"
          textColor="inherit"
          classes={{
            root: classes.tabsRoot
          }}
        >
          <Tab label="Add Facebook Token" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
          <Tab label="Delete Facebook Token" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
        </Tabs>
        {value === 0 && <TabContainer><TokenUpdate/></TabContainer>}
        {value === 1 && <TabContainer><TokenDelete/></TabContainer>}
      </Paper>
    );
  }
}

export default withStyles(articleHomeStyle)(TokenHome);
