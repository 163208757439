import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Button from "components/CustomButtons/Button.jsx";
import { DEFULT_PROFILE_PICTURE_URL } from "../../app-constants/image-url.js";

let imageStyle = {
  width: 'auto',
  height: '150px'
}

const style = {
  imageContainer: {
    position: "relative"
  },
  imageTitle: {
    position: "absolute",
    marginTop: "5px",
    right: "5px",
    color: "white",
    fontWeight: "bold",
    mixBlendMode: "difference"
  }
}


class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.defaultStoreImage
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    const uploadType = file && file.type && file.type.split("/")[0];
    const extension = file && file.type && file.type.split("/")[1];
    if (uploadType == 'image' && (extension == 'webp' || extension =='svg+xml' || extension == 'gif') ) {
      this.props.InvalidFileError(`${extension} images are not allowed.`);
      return;
    }

    let fileSize = (file) && (file.size / 1024 / 1024);
    reader.readAsDataURL(file);
    if (fileSize > 2) {
      alert('Image size exceeds 2 MB');
      return
    }
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      });
      this.fileValue();
    };

  }
  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  handleRemove() {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.defaultStoreImage
    });
    this.refs.fileInput.value = null;
  }
  fileValue() {
    this.props.onChangeFileValue(this.state.file);
  }

  render() {
    var {
      avatar,
      addButtonProps,
      changeButtonProps,
      removeButtonProps,
      header,
      imageTagName,
      classes
    } = this.props;
    return (
      <div className={`fileinput text-center ${classes.imageContainer}`}  >
        <h5 className={classes.imageTitle}>{header}</h5>
        <input type="file" accept="image/*" onChange={this.handleImageChange} ref="fileInput" />
        <div style={imageStyle} className={"thumbnail" + (avatar ? " img-circle" : "")}>
          <img style={imageStyle} src={this.state.imagePreviewUrl} alt="..." />
        </div>
        <div>
          {this.state.file === null ? (
            <div>
              <Button  {...addButtonProps} onClick={() => this.handleClick()}>
                {avatar ? "Add Photo" : `${imageTagName}`}
              </Button>
              <span>
                {/* this.state.imagePreviewUrl == DEFULT_PROFILE_PICTURE_URL ? <h6>profile image is required</h6> : null */}
              </span>
            </div>
          ) : (
              <span>
                <Button {...changeButtonProps} onClick={() => this.handleClick()}>
                  Change
                </Button>
                {avatar ? <br /> : null}
                {/* <Button
                    {...removeButtonProps}
                    onClick={() => this.handleRemove()}
                  >
                    <i className="fas fa-times" /> Remove
              </Button> */}
              </span>
            )}
        </div>
      </div>
    );
  }
}

ImageUpload.propTypes = {
  classes: PropTypes.object.isRequired,
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
};

export default withStyles(style)(ImageUpload);
