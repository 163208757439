
// this is base  card for news letter
import React, { PureComponent } from 'react';
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Papa from "papaparse";
import CSVPreview from "./csvPreview";
import CSVFakePreview from "./case-fake-preview";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';

const validFirstName = ['first name', 'firstname', 'first_name', 'first  name'];
const validLastName = ['last name', 'lastname', 'last_name', 'last  name'];

const fakeData = [{
  0: 'jhon.doe@gmail.com',
  1: 'Jhon',
  2: 'Doe'
}]

class SubBaseComponent extends PureComponent {
  state = {
    uploadCSVPreview: null,
    openCSVModal: false,
    formData: null,
    openinfoModal: false
  }
  _handleClick = () => {
    this.refs.fileField.click();
  }
  read = (file, callback) => {
    var reader = new FileReader();

    reader.onload = function () {
      callback(Papa.parse(reader.result, { skipEmptyLines: true }));
    }

    reader.readAsText(file);
  }
  _onUpload = (event) => {
    let formData = new FormData(); // Currently empty
    formData.append('file', event.target.files[0])
    if (event.target.files[0].name.split('.').pop() != 'csv') {
      this.props.showInfoMessage('File format should be csv');
      event.target.value = null;
      return;
    }
    this.read(event.target.files[0], (data) => {
      if (data.data.length == 0) {
        this.props.showInfoMessage(`csv can't be empty`);
        return;
      }
      else {
        const keyArray = data.data[0];
        if (Array.isArray(keyArray)) {
          const refactorArray = keyArray.map((ele) => ele && ele.trim().toLowerCase())
          if (!refactorArray.includes('email')) {
            this.props.showInfoMessage(`email is required`);
            return;
          }
          const emailIndex = refactorArray.indexOf('email');
          refactorArray.splice(emailIndex, 1);
          let exitOnUpload = false;
          refactorArray.map((ele) => {
            if (!(validFirstName.indexOf(ele) >= 0 || validLastName.indexOf(ele) >= 0)) {
              this.props.showInfoMessage(`key should be firstName or lastName`);
              exitOnUpload = true;
              return;
            }
          })
          if (exitOnUpload) {
            exitOnUpload = false;
            return;
          }
        }
      }
      this.setState({ uploadCSVPreview: data.data, openCSVModal: true, formData })
    })

    event.target.value = null
  }

  openModal = () => {
    this.setState({ openCSVModal: true })
  }
  closeModal = () => {
    this.setState({ openCSVModal: false, uploadCSVPreview: null, formData: null })
  }
  opnInfoModal = () => {
    this.setState({ openinfoModal: true })
  }
  closeInfoModal = () => {
    this.setState({ openinfoModal: false })
  }
  confirmUpload = () => {
    const csvFile = this.state.formData;
    this.props.importNl(csvFile);
  }
  downloading = () => {
    fetch(`${process.env.REACT_APP_API_SERVER + '/newsletter/' + this.props.activeDomain + '/export'}`,
      {
        headers :{authorization:localStorage.getItem('accessToken')}
       }
    )
  .then(resp => resp.blob())
  .then(blob => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'subscribers.json';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  })
  .catch(() => alert('Error in downloading'));
  }
  render() {
    const { count, exportNl, activeDomain, isNewsLetterActionLoading } = this.props;
    return (
      <>
        <div style={{ border:'1px solid #ced3dc'}} >
          <div style={{ marginLeft: '10px' }}>
            <h2 style={{ margin: '30px' }}>Total Subscribers : {count}</h2>
            <GridContainer>
            <GridItem xs={12} sm={6}>
            <input type="file" ref="fileField" accept=".csv" onChange={this._onUpload} id="file-upload" style={{ display: 'none' }} />
            <h5 style={{ margin: '30px' }} >Import Existing Subscribers :
              <span>
                <i className="material-icons" style={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => { this.opnInfoModal() }}>
                  info
                </i>
              </span>
             </h5>
             </GridItem>
             <GridItem xs={12} sm={6}>
              <Button color="primary" type="file" onClick={this._handleClick} style={{
                padding: '7px',
                fontSize: '10px',
                marginLeft: '30px',
                marginTop: isWidthDown('xs', this.props.width) ? '-25px' : '32px',
              }} > upload csv</Button>
              </GridItem>
              </GridContainer>
            <div style={{ display: 'flex', margin: '15px 30px 30px' }}>
            <GridContainer>
            <GridItem xs={12} sm={6}>
              <h5>Export my subscribers :</h5>
            </GridItem>
            <GridItem xs={12} sm={6}>
             
                <Button color="primary" onClick={this.downloading} style={{
                  padding: '7px',
                  fontSize: '10px',
                }}>Download csv</Button>
              
              </GridItem>
              </GridContainer>
            </div>
          </div>
        </div >
        {
          (this.state.openCSVModal &&
            this.state.uploadCSVPreview) &&
          < CSVPreview
            openCSVModal={this.state.openCSVModal}
            closeModal={this.closeModal}
            uploadCSVPreview={this.state.uploadCSVPreview}
            confirmUpload={this.confirmUpload}
            isNewsLetterActionLoading={isNewsLetterActionLoading}
          />
        }
        {
          this.state.openinfoModal &&
          <CSVFakePreview
            closeInfoModal={this.closeInfoModal}
            openinfoModal={this.state.openinfoModal}
            renderData={fakeData}
          />
        }
      </>
    )
  }
}
export default withWidth()(SubBaseComponent);
