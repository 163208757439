import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Switch, Route, Redirect } from "react-router-dom";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
// import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import Navbar from "components/Navbar/Navbar.jsx";

import dashRoutes from "./dashboard-routes.js";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/appStyle.jsx";

import { PUBNINJA_LOGO } from "../src/app-constants/image-url.js";
import { removeAppNotification } from "./app-actions.js";
import { signOutUser } from "./login/login-actions.js";
import PrivateRoute from "./utils/required-auth.js";

import withComponentWrapper from "./component-wrapper.jsx";
import { setAppMessage } from "./app-actions.js";
import { MessageTypes } from "./app-constants/index.js";

const getRoutes = (routes, user, isAuthenticated, setAppMessage, featurePermision) => {
  return routes.map((prop, key) => {
    if (prop.redirect)
      return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
    if (prop.collapse) {
      return (
        <Route key={key} path={prop.path} render={props =>
          <prop.component {...props} >
            <Switch>
              {
                prop.views && prop.views[0] &&
                <Redirect exact from={`${prop.path}`} to={`${prop.views[0].path}`} key={key} user={user} requiredPermissions={prop.requiredPermissions || []} featurePermision={featurePermision} completePermissions={prop.completePermissions || []} requiredFeaturePermision={prop.requiredFeaturePermision || ''} />
              }
              {
                prop.views.map((prop, key) => {
                  // if (prop.isRequiredAuth) {
                  return <PrivateRoute exact requiredPermissions={prop.requiredPermissions || []} completePermissions={prop.completePermissions || []} user={user} path={prop.path} component={prop.component} key={key} isAuthenticated={isAuthenticated} setAppMessage={setAppMessage} featurePermision={featurePermision} requiredFeaturePermision={prop.requiredFeaturePermision || ''} />
                  // }
                  //   return <Route exact path={prop.path} component={prop.component} key={key} />;
                })
              }
            </Switch>
          </prop.component>
        } />
      )
    }
    if (prop.isRequiredAuth) {
      return <PrivateRoute exact user={user} requiredPermissions={prop.requiredPermissions || []} completePermissions={prop.completePermissions || []} path={prop.path} component={prop.component} key={key} isAuthenticated={isAuthenticated} setAppMessage={setAppMessage} featurePermision={featurePermision} requiredFeaturePermision={prop.requiredFeaturePermision || ''} />
    }
    return <Route exact path={prop.path} component={prop.component} key={key} />;
  })
};

var ps;

class AppLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", this.resizeFunction);
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    window.removeEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      if(this.refs.mainPanel) this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  componentDidCatch(error, info) {
    this.props.setAppMessage(info, { type: MessageTypes.ERROR });
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/maps/full-screen-maps";
  }
  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  render() {
    const { classes, isAuthenticated, notifications, removeNotification, messages, user, featurePermision, isDoingSignout, setAppMessage, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });
    return (
      <React.Fragment>
        <div className={classes.wrapper}>
          <Sidebar
            routes={dashRoutes}
            logo={PUBNINJA_LOGO}
            handleDrawerToggle={this.handleDrawerToggle}
            open={this.state.mobileOpen}
            bgColor="white"
            miniActive={this.state.miniActive}
            user={user}
            featurePermision={featurePermision}
            isDoingSignout={isDoingSignout}
            {...rest}
          />
          <Navbar
            routes={dashRoutes}
            logo={PUBNINJA_LOGO}
            handleDrawerToggle={this.handleDrawerToggle}
            open={this.state.mobileOpen}
            bgColor="white"
            miniActive={this.state.miniActive}
            user={user}
            featurePermision={featurePermision}
            isDoingSignout={isDoingSignout}
            {...rest}
          />
          <div id="mainPanel" className={mainPanel} ref="mainPanel">
            <Header
              sidebarMinimize={this.sidebarMinimize.bind(this)}
              miniActive={this.state.miniActive}
              routes={[...dashRoutes]}
              handleDrawerToggle={this.handleDrawerToggle}
              {...rest}
            />
            <div className={classes.content}>
              <div className={classes.container} >
                <Switch>
                  {getRoutes(dashRoutes, user, isAuthenticated, setAppMessage, featurePermision)}
                </Switch>
              </div>
            </div>
            {/* {this.getRoute() ? <Footer fluid /> : null} */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

AppLayout.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  const { id: activeUser } = state.login.user;
  const { featurePermision } = state.login.domain;
  return {
    user: state.userManagement.accountManagement.userList[activeUser],
    isDoingSignout: state.login.isDoingSignout,
    isAuthenticated: state.login.isAuthenticated,
    featurePermision,
    ...state.app
  }
}
const mapDisaptchToProps = (dispatch) => {
  return {
    removeNotification: id => dispatch(removeAppNotification(id)),
    signOutUser: () => dispatch(signOutUser()),
    setAppMessage: (message, options) => dispatch(setAppMessage(message, options))
  }
}

export default connect(mapStateToProps, mapDisaptchToProps)(withStyles(appStyle)(withComponentWrapper(AppLayout)));
