import React from 'react';
import moment from 'moment';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import AppLoader from "app-components/app-loader.jsx";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.jsx';

import OtpDialog from './otp-dialog.jsx';

class DetailsForm extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  state = {
    date: '',
    beneficiaryName: '',
    beneficiaryAddress: '',
    typeOfBusiness: '',
    accountName: '',
    accountNumber: '',
    bankName: '',
    bankAddress: '',
    bankPinCode: '',
    routingDetails: '',
    branchCode: '',
    identificationNumber: '',
    yourName: '',
    phoneNumber: '+',
    emailId: '',
    totalFieldsFilled: 0,

    blacklistedBank: null,
    agreementCheck: false,
  }

  componentDidMount(){
    this.props.getBankDetails();
    this.props.getBlacklistedBanks();
    if(this.props.emailId){
      this.setState({ emailId: this.props.emailId });
    }
    this.setState({ date: moment.utc().format() });
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.agreementHtml){
      this.myRef && this.myRef.current && this.myRef.current.scrollIntoView();
    }
  }

  handleChange = (event, field) => {
    let value = event.target.value;
    let totalFieldsFilled = this.state.totalFieldsFilled+1;
    if(field === 'bankName') {
      if(this.props.blacklistedBanks.indexOf(value) > -1){
        this.setState({ blacklistedBank: value, bankName: '', totalFieldsFilled });
        return;
      }
    }
    if(field === 'phoneNumber') {
      let phoneNumber = event.target.value;
      if(!phoneNumber.startsWith('+')){
        phoneNumber = '+' + phoneNumber;
      }
      this.setState({ [field]: value, blacklistedBank: null, totalFieldsFilled, phoneNumber });
      return;
    }
    this.setState({ [field]: value, blacklistedBank: null, totalFieldsFilled });
  }

  handleDropdown = (value, field) => {
    let totalFieldsFilled = this.state.totalFieldsFilled+1;
    this.setState({ [field]: value, totalFieldsFilled });
  }

  hasNotFilled = () => {
    const { beneficiaryName, beneficiaryAddress, typeOfBusiness, accountName, accountNumber, bankName, bankAddress , bankPinCode, routingDetails, branchCode, identificationNumber, yourName, phoneNumber } = this.state;
    let checkList = [beneficiaryName, beneficiaryAddress, typeOfBusiness, accountName, accountNumber, bankName , routingDetails, yourName];
    // if(phoneNumber == '+')
    //   return true;
    for( let item of checkList)
      if(item.trim()==='')
        return true;
    return false;
  }

  handleSubmit = () => {
    this.props.submitAccountInfo(this.state);
  }

  submitOtp = (otp) => {
    this.props.submitOtp(otp, this.state.phoneNumber);
  }

  generateOtp = () => {
    this.props.generateOtp(this.state.phoneNumber);
  }

  closeOtpModal = () => {
    this.props.closeOtpModal();
  }

  handleAgreementChange = (event, field) => {
    let value = (event.target.value === 'false');
    this.setState({ [field]: value });
  }

  submitAgreement = () => {
    this.props.submitAgreement();
  }

  render() {
    const {
      otpVerified,
      agreementHtml,
      submittingAgreement,
      agreementSubmitted,
      fetchingBankDetails,
      bankDetails,
    } = this.props;

    if(fetchingBankDetails) {
      return <AppLoader />
    }
    if(!fetchingBankDetails && bankDetails && bankDetails['bank'] && Object.keys(bankDetails['bank']).length>0) {
      return (
        <div>
          <GridContainer>
            <GridItem xs={12} md={3} style={{fontWeight: 'bold', fontSize: '1.1em'}}>
              Person / Company Details
            </GridItem>
            <GridItem xs={12} md={7}>
              {bankDetails['company'].typeOfBusiness}
              <br/>
              {bankDetails['company'].name}
              <br/>
              {bankDetails['company'].address}
            </GridItem>
            <GridItem xs={false} md={2}>
            </GridItem>
            <GridItem xs={12}>
              <hr/>
            </GridItem>
            <GridItem xs={12} md={3} style={{fontWeight: 'bold', fontSize: '1.1em'}}>
              Bank Account Details
            </GridItem>
            <GridItem xs={12} md={7}>
              <GridContainer>
                <GridItem xs={4} style={{fontWeight: 'bold',}}>
                  Bank Name:
                </GridItem>
                <GridItem xs={8}>
                {bankDetails['bank'].name}
                </GridItem>
                <GridItem xs={4} style={{fontWeight: 'bold',}}>
                  Account Holder Name:
                </GridItem>
                <GridItem xs={8}>
                {bankDetails['bank'].ac_holder_name}
                </GridItem>
                <GridItem xs={4} style={{fontWeight: 'bold',}}>
                  Account Number:
                </GridItem>
                <GridItem xs={8}>
                {bankDetails['bank'].ac_number}
                </GridItem>
                <GridItem xs={4} style={{fontWeight: 'bold',}}>
                  Address:
                </GridItem>
                <GridItem xs={8}>
                  {bankDetails['bank'].bank_address}
                  {bankDetails['bank'].pin_code}
                </GridItem>
                <GridItem xs={4} style={{fontWeight: 'bold',}}>
                  Routing Details:
                </GridItem>
                <GridItem xs={8}>
                {bankDetails['bank'].swift}
                </GridItem>
                <GridItem xs={4} style={{fontWeight: 'bold',}}>
                  IBAN / IFSC:
                </GridItem>
                <GridItem xs={8}>
                {bankDetails['bank'].iban}
                </GridItem>
                <GridItem xs={4} style={{fontWeight: 'bold',}}>
                  TIN/PAN
                </GridItem>
                <GridItem xs={8}>
                {bankDetails['bank'].tin}
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={false} md={2}>
            </GridItem>
            <GridItem xs={12}>
              <hr/>
            </GridItem>
            <GridItem xs={12} md={3} style={{fontWeight: 'bold', fontSize: '1.1em'}}>
              Your Details
            </GridItem>
            <GridItem xs={12} md={7}>
              {bankDetails['personal'].name}
              <br/>
              {bankDetails['personal'].contact}
              <br/>
              {bankDetails['personal'].email}
            </GridItem>
          </GridContainer>
        </div>
      )
    }
    if( agreementSubmitted ) {
      return (
        <div>
          Agreement Submitted successfully.
        </div>
      )
    }
    return (
      <div ref={this.myRef} >
        {
          (agreementHtml && !agreementSubmitted)
          ?
          <>
            <div ref={this.myRef} style={{height: '300px', overflow: 'scroll'}} contentEditable='false' dangerouslySetInnerHTML={{
              __html: `${agreementHtml}`
            }} />
            <div style={{padding: '5px 0', fontSize: '1.2em'}}>
              <input type="checkbox" name="AgreementCheck" value={this.state.agreementCheck} onChange={(event)=>this.handleAgreementChange(event, `agreementCheck`)} checked={this.state.agreementCheck} />
              <span style={{marginLeft: '5px'}}>I Agree to the Terms.</span>
            </div>
            <div style={{display: 'flex'}}>
              <Button color="secondary" disabled={!this.state.agreementCheck || submittingAgreement} onClick={this.submitAgreement}>
                Submit
              </Button>
              { submittingAgreement && <AppLoader size={20} /> }
            </div>
            </>
          :
            <div>
              <form>
                <GridContainer>
                  <GridItem xs={12} md={10} lg={6}>
                    <GridContainer>
                      <GridItem xs={12} style={{marginTop: '20px'}}>
                        <div style={{fontWeight: 'bold'}}>
                          Add Business Details
                        </div>
                      </GridItem>
                      <GridItem xs={12}>
                        <CustomDropdown
                          buttonText="Select Type of Business"
                          dropdownList={["Individual, sole proprietor, or single-member LLC", "Company"]}
                          onClick={(event)=>this.handleDropdown(event, 'typeOfBusiness')}
                        />
                      </GridItem>
                      <GridItem xs={12} style={{marginTop: '-20px'}}>
                        <CustomInput
                          id="beneficiaryName"
                          labelText="Name of the Business"
                          inputProps={{
                            onChange: (event)=>{this.handleChange(event, 'beneficiaryName')}
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                          value={this.state.beneficiaryName}
                        />
                      </GridItem>
                      <GridItem xs={12} style={{marginBottom: '25px', marginTop: '-20px'}}>
                        <CustomInput
                          id="beneficiaryAddress"
                          labelText="Address of the Business"
                          inputProps={{
                            onChange: (event)=>{this.handleChange(event, 'beneficiaryAddress')}
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                          value={this.state.beneficiaryAddress}
                        />
                      </GridItem>
                      <GridItem xs={12} style={{marginBottom: '-25px'}}>
                        <div style={{fontWeight: 'bold'}}>
                          Add Bank Account Details
                        </div>
                      </GridItem>
                      <GridItem xs={12} style={{marginBottom: '-25px'}}>
                        <CustomInput
                          id="accountName"
                          labelText="Name of the Bank Account Holder"
                          inputProps={{
                            onChange: (event)=>{this.handleChange(event, 'accountName')}
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                          value={this.state.accountName}
                        />
                      </GridItem>
                      <GridItem xs={12} style={{marginBottom: '-25px'}}>
                        <CustomInput
                          id="accountNumber"
                          labelText="Bank Account Number"
                          inputProps={{
                            onChange: (event)=>{this.handleChange(event, 'accountNumber')}
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                          value={this.state.accountNumber}
                        />
                      </GridItem>
                      <GridItem xs={12} style={{marginBottom: '-25px'}}>
                        <CustomInput
                          id="bankName"
                          labelText="Name of the Bank"
                          inputProps={{
                            onChange: (event)=>{this.handleChange(event, 'bankName')}
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                          value={this.state.bankName}
                        />
                      </GridItem>
                      {
                        (this.state.blacklistedBank) &&
                        <GridItem xs={12}>
                          <div style={{marginTop: '8px', fontWeight: 'bold'}}>
                            {this.state.blacklistedBank} is not allowed. Please try a different Bank.
                          </div>
                        </GridItem>
                      }
                      <GridItem xs={12} style={{marginBottom: '-25px'}}>
                        <CustomInput
                          id="bankAddress"
                          labelText="Address of the Bank (optional)"
                          inputProps={{
                            onChange: (event)=>{this.handleChange(event, 'bankAddress')},
                            multiline: true,
                            rowsMax: 8,
                            rows: 4,
                            className: 'multilineFormFix'
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                          value={this.state.bankAddress}
                        />
                      </GridItem>
                      <GridItem xs={12}  style={{marginBottom: '-25px'}}>
                        <CustomInput
                          id="bankPinCode"
                          labelText="PINCODE of the Bank Address (optional)"
                          inputProps={{
                            onChange: (event)=>{this.handleChange(event, 'bankPinCode')}
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                          value={this.state.bankPinCode}
                        />
                      </GridItem>
                      <GridItem xs={12}  style={{marginBottom: '-25px'}}>
                        <CustomInput
                          id="routingDetails"
                          labelText="Swift code / Routing details / ABA / Sort code"
                          inputProps={{
                            onChange: (event)=>{this.handleChange(event, 'routingDetails')}
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                          value={this.state.routingDetails}
                        />
                      </GridItem>
                      <GridItem xs={12}  style={{marginBottom: '-25px'}}>
                        <CustomInput
                          id="branchCode"
                          labelText="IBAN / IFSC (optional)"
                          inputProps={{
                            onChange: (event)=>{this.handleChange(event, 'branchCode')}
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                          value={this.state.branchCode}
                        />
                      </GridItem>
                      <GridItem xs={12} >
                        <CustomInput
                          id="identificationNumber"
                          labelText="TIN/PAN (optional)"
                          inputProps={{
                            onChange: (event)=>{this.handleChange(event, 'identificationNumber')}
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                          value={this.state.identificationNumber}
                        />
                      </GridItem>
                      <GridItem xs={12}>
                        <div style={{fontWeight: 'bold'}}>
                          Your Details
                        </div>
                      </GridItem>
                      <GridItem xs={12}  style={{marginBottom: '-25px'}}>
                        <CustomInput
                          id="personName"
                          labelText="Your Name"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: (event)=>{this.handleChange(event, 'yourName')}
                          }}
                          value={this.state.yourName}
                        />
                      </GridItem>
                      <GridItem xs={12}  style={{marginBottom: '-25px'}}>
                        <CustomInput
                          id="emailId"
                          labelText="Your Email ID"
                          inputProps={{
                            disabled: true
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                          value={this.state.emailId}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} >
                        <CustomInput
                          id="phoneNumber"
                          labelText="Your Phone Number"
                          inputProps={{
                            onChange: (event)=>{this.handleChange(event, 'phoneNumber')},
                            disabled: otpVerified
                          }}
                          formControlProps={{
                            fullWidth: true
                          }}
                          value={this.state.phoneNumber}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} style={{marginTop: '35px'}}>
                        {!otpVerified &&
                          <Button disabled={this.state.phoneNumber=='+'} color="primary" onClick={this.generateOtp}>
                           Verify with OTP
                          </Button>
                        }
                      </GridItem>
                      {
                        !otpVerified &&
                        <GridItem xs={6}>
                        <div>
                          Ex: +1352xxx1492
                        </div>
                        </GridItem>
                      }

                      <GridItem xs={12}  style={{marginBottom: '-10px', marginTop: '20px'}}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start'}}>
                          <Button
                            color="secondary"
                            onClick={this.handleSubmit}
                            disabled={this.props.isSubmittingInfo || !this.props.otpVerified || this.hasNotFilled()}
                          >
                            Submit
                          </Button>
                          { this.props.isSubmittingInfo && <AppLoader size={20} /> }
                        </div>
                        <div style={{fontSize: '0.9em', color: 'gray'}}> You can submit only once all details have been filled.</div>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={false} md={2} lg={6}>
                  </GridItem>
                </GridContainer>
              </form>
              <OtpDialog
                showOtpModal={this.props.showOtpModal}
                closeOtpModal={this.props.closeOtpModal}
                submitOtp={this.submitOtp}
                otpError={this.props.otpError}
                isSubmittingOtp={this.props.isSubmittingOtp}
                otpVerified={otpVerified}
                generateOtp={this.generateOtp}
              />
            </div>
        }
      </div>
    )
  }
}

export default DetailsForm;
