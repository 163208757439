//library apis
import React, { PureComponent } from 'react';
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { MessageTypes } from "../app-constants/index.js";
import { withRouter } from 'react-router-dom';
import axios from 'axios';

//actions creators
import { fetchUserDetailsForAuth } from "../user-settings/user-settings-actions.js";
//components
import AppLoader from "../app-components/app-loader.jsx";

const redirectRoute = ["/cms/articles"];
const redirectPermissions = ['WRITE'];

function containCompleteUserData(userDetails) {
  const {
    firstName,
    profilePicture,
    phoneNumber,
    fbProfile,
    twitterProfile,
    description
  } = userDetails;
  if (
    firstName &&
    profilePicture &&
    phoneNumber &&
    fbProfile &&
    twitterProfile &&
    description
  ) {
    return true;
  }
  return false;
}

function hasPermission(requiredPermissions, userPermissions, completePermissions) {
  let userCompletePermissions = 0;
  if (!requiredPermissions || requiredPermissions.length === 0) {
    return true;
  }
  for (let i = 0; i < userPermissions.length; i++) {
    if (requiredPermissions.indexOf(userPermissions[i]) > -1) {
      return true
    }
  }
  return false;
}
function hasFeaturePermision(requiredFeaturePermision, featurePermision = []) {
  if (!requiredFeaturePermision || requiredFeaturePermision.length === 0) {
    return true
  }
  if (featurePermision.includes(requiredFeaturePermision)) {
    return true
  }
  return false
}
function validateRedirectRoute(route, user) {
  const { permissions } = user;

  //const isUserDataCompleted = containCompleteUserData(user);

  //Don't check if user has filled form to post articles

  const isUserDataCompleted = true;
  if (redirectRoute.includes(route.pathname) &&
    (permissions && hasPermission(redirectPermissions, permissions)) &&
    !isUserDataCompleted) {
    return true
  }
  return false
}

export class PrivateRoute extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      isSuccess: null,
    };
  }

  componentDidMount() {
    const { isAuthenticated } = this.props;
    if (!isAuthenticated) {
      this.props.fetchUserDetails()
        .then((data) => {
          if (!localStorage.getItem('accessToken')) {
            this.props.history.push("/login-home/login");
            return 
          }
          if (data && data.noActiveDomain) return this.props.history.push('/domain-management/addwebsite');
          this.setState({ isFetching: false, isSuccess: true });
        })
        .catch(() => {
          this.setState((prev) => ({ isFetching: false, isSuccess: false }));
        });
    } else {
      this.callAfterEveryMin();
    }
  }

  callAfterEveryMin = () => {
    const userAgentString = navigator.userAgent;
    const device = (/Mobi|Android/i.test(userAgentString)) ? "mobile" : (/iPad/i.test(userAgentString) ? "iPad" : "desktop");
    const payload = {
      user_id: (this.props.user || {}).id,
      email: (this.props.user || {}).email,
      url: (window.location || {}).href,
      user_agent: navigator.userAgent,
      device: device,
    };
    axios.post(
          'https://analytics.pubninja.com/page_entry_tracking',
          payload,
          {headers: {
            'Content-Type': 'application/json',
            'Origin': 'https://app.pubninja.com',
            'Referer': 'https://app.pubninja.com/',
            'sec-fetch-mode': 'cors'
          }})
      .then(response => {
        console.log('Data sent successfully:', response.data);
      })
      .catch(error => {
        console.error('Error sending data:', error);
      });
    setTimeout(this.callAfterEveryMin, 3 * 60 * 1000);
  };

  redirectToEditPage = () => this.props.history.push('/user-settings/edit-profile');

  redirectEditPage = () => {
    const { setAppMessage } = this.props;
    setAppMessage("Please update profile details to continue...", {
      type: MessageTypes.INFO, onOk: this.redirectToEditPage
    });
  }

  render() {
    const { isFetching, isSuccess } = this.state;
    const { isAuthenticated, component: Component, requiredPermissions, completePermissions, requiredFeaturePermision, featurePermision, user, ...rest } = this.props;
    return (
      <Route
        exact
        {...rest}
        render={props => {
          const success = (<Component {...props} user={user} />);
          const error = (<Redirect
            to={{
              pathname: "/login-home/login",
              state: { from: props.location }
            }}
          />);
          if (isAuthenticated) {
            if (hasFeaturePermision(requiredFeaturePermision, featurePermision) && hasPermission(requiredPermissions, user ? user.permissions : [], completePermissions)) {
              if (user && validateRedirectRoute(props.location, user)) {
                this.redirectEditPage()
                return null;
              }
              return success;
            } else {
              //return null
              return <Redirect
                to={{
                  pathname: "/landing",
                  state: { from: props.location }
                }}
              />;
            }
          } else {
            if (isFetching) {
              return <AppLoader message="Loading..." />;
            }
            if (isSuccess) {
              if (user && validateRedirectRoute(props.location, user)) {
                this.redirectEditPage()
                return null;
              }
              return success;
            }
            else {
              return error;
            }
          }
        }
        }
      />
    );
  }
}

const mapDisaptchToProps = (dispatch) => {
  return {
    fetchUserDetails: () => dispatch(fetchUserDetailsForAuth()),
  }
}
export default withRouter(connect(null, mapDisaptchToProps)(PrivateRoute));
