import React, { Component, PureComponent } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'sweetalert-react';
import 'sweetalert-react/node_modules/sweetalert/dist/sweetalert.css';
import { Editor } from '@tinymce/tinymce-react';
import throttle from 'lodash.throttle';
import RoundButton from "components/RoundButton/roundbutton";
import EmbedModal from "./blockquote-modal.jsx";

import {showPiscoModal} from "../new-story-actions.js";
import { PiscoContentTypes } from "app-constants/index.js";
import { getCountFromContent } from "utils/word-counts";
import $ from "jquery";

export default class TinyMCEEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fullScreenState: false,
      showImageFlipperModal: false,
      showBlockQuoteModal: false,
      alertDetails: {
        showCancleButton: false,
        showAlert: false,
        title: "",
        text: ""
      },
      blockQuote: 0,
    };
    // this.tinyMCEEditor = this.props.tinyMCEEditor;
    this.handleEditorChange = throttle(
      this.handleEditorChange.bind(this),
      5 * 1000
    );
    this.imagesUploadHandler = this.imagesUploadHandler.bind(this);
    this.appendBlockQuoteIncontentArea = this.appendBlockQuoteIncontentArea.bind(
      this
    );
    this.appendImageFlipperInContentArea = this.appendImageFlipperInContentArea.bind(
      this
    );
    this.onEditorContentChange = this.onEditorContentChange.bind(this);
    this._appendIamgeFlipper = this._appendIamgeFlipper.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.filePickerCallback = this.filePickerCallback.bind(this);
    // this.imageUploadFailureCB = this.imageUploadFailureCB.bind(this);
  }

  handleEditorChange(content) {
    const { updateEditorContent, updateApi, domain } = this.props;
    const { featurePermision } = domain;
    if (featurePermision && featurePermision.indexOf('LAZYLOAD_ARTICLES') > -1) {
      content = content.replace(/<img\s+(?:id="([^"]*)"\s+)?(?:contenteditable="([^"]*)"\s+)?(?:data-src="((?!["\s])[^"]{1,})"\s+)(?:alt="([^"]*)"\s+)?(?:data-mce-nsrc="([^"]*)"\s+)?(?:data-mce-src="([^"]*)"\s+)?[^>]*>/g, function (match, id, contenteditable, src, alt, dataMceNsrc, dataMceSrc) {
        return `<img id="figure-image" class="lazyload" contenteditable="false" data-src=${src} alt="${alt ? alt.trim() : ''}" data-mce-nsrc="${dataMceNsrc}" />`;
      });
    }
    updateApi(content);
  }

  componentDidMount() {
    const { setInitialState, domain } = this.props;
    setInitialState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activePiscoContentType === PiscoContentTypes.CONTENT) {
      const contentExists = !!this.props.piscoContent;
      const contentIsValid = Object.keys(this.props.piscoContent).length > 0;
      const contentIsNew =
        this.props.piscoContent.cdn !== prevProps.piscoContent.cdn;
      if (contentExists && contentIsValid && contentIsNew) {
        let content = '';
        switch (this.props.piscoContent.type) {
          case "Image": {
            if (this.props.piscoContent.comp_base_str || this.props.piscoContent.base_64) {
              content = `
                  <figure class="image" contenteditable="false" data-mce-selected="1">
                    <img id="figure-image" contenteditable="false" src="${this.props.piscoContent.cdn}"  alt="${this.props.piscoContent.caption}" data-mce-nsrc="data:image/webp;base64, ${this.props.piscoContent.comp_base_str || this.props.piscoContent.base_64}" />
                    <figcaption contenteditable="true" data-gramm="false">
                      ${this.props.piscoContent.caption}
                    </figcaption>
                  </figure>
                <p></p>
              `;
            } else {
              content = `
                  <figure class="image" contenteditable="false" data-mce-selected="1">
                    <img id="figure-image" contenteditable="false" src="${this.props.piscoContent.cdn}"  alt="${this.props.piscoContent.caption}" />
                    <figcaption contenteditable="true" data-gramm="false">
                      ${this.props.piscoContent.caption}
                    </figcaption>
                  </figure>
                <p></p>
              `;
            }
            break;
          }
          case "Video": {
            content = `
            <div class='blockquote-container' contenteditable='false'>
            <iframe
            width="560"
            height="315"
            frameborder="0"
            align="middle"
            src='https://players.brightcove.net/6015698090001/gUY2vwlJSg_default/index.html?videoId=${this.props.piscoContent.brightcove_video_id}'
            allowfullscreen
            webkitallowfullscreen
            mozallowfullscreen>
            </iframe>
            <p class="end-of-block-quote"></p></div>
            `;
            break;
          }
        }
        this.props.tinyMCEEditor.current.editor.execCommand(
          "insertHTML",
          false,
          content
        );
        // this.props.tinyMCEEditor.current.editor.fire("event", {name: "click"});
        const contents = this.props.tinyMCEEditor.current.editor.getContent();
        this.props.tinyMCEEditor.current.editor.setContent(contents);
        this.props.hidePiscoModal();
        this.props.clearPiscoContent();
      }
      if ("instgrm" in window) {
        // window.instgrm.Embeds.process();
      }
    }
  }

  componentWillUnmount() {
    this.handleEditorChange.flush();
    this.handleEditorChange.cancel();
  }

  appendBlockQuoteIncontentArea(content) {
    const { setAppMessage } = this.props;
    const embededContent = content.trim();
    const blockquoteText = `<div class='blockquote-container' contenteditable='false'> ${embededContent} <p class="end-of-block-quote"></p></div>`;
    this.props.tinyMCEEditor.current.editor.execCommand(
      "insertHTML",
      false,
      blockquoteText
    );
  }

  _appendIamgeFlipper(content) {
    this.props.tinyMCEEditor.current.editor.execCommand(
      "insertHTML",
      false,
      content.trim()
    );
  }
  appendImageFlipperInContentArea(data) {
    const { uploadFlipperedImages } = this.props;
    uploadFlipperedImages(data, this._appendIamgeFlipper);
  }

  hideAlert() {
    this.setState({
      alertDetails: {
        showAlert: false,
        title: "",
        text: ""
      }
    });
  }

  imagesUploadHandler(blobInfo, success, failure) {
    // let formData = new FormData();
    // formData.append('file', blobInfo.blob(), blobInfo.filename());
    // MeawwApi()
    //   .auth()
    //   .then(api =>{
    //     return api.post(
    //       "/imageUpload/10717",
    //       formData,
    //       // {
    //       //   file: blobInfo.base64()
    //       // },
    //       (error, data)=>{
    //
    //         if(error){
    //
    //           failure('Invalid JSON: ');
    //         }
    //         // success(json.location);
    //       }
    //   )
    // });
    const {
      imageUploadingRequest,
      imageUploadingSuccess,
      imageUploadingFailure,
      articleId,
      uploadImageInMCE
    } = this.props;

    const tinyMCEEditorKlass = this;
    //checking size
    if (blobInfo.blob().size > 2097152) {
      tinyMCEEditorKlass.setState({
        alertDetails: {
          showCancleButton: false,
          showAlert: true,
          title: "Error!",
          text: String("Image Files too big, Max Size Allowed is 2MB")
        }
      });
      success();
      return;
    }

    let formData = new FormData();
    formData.append("file", blobInfo.blob(), blobInfo.filename());
    function imageUploadFailureCB(error) {
      tinyMCEEditorKlass.tinyMCEEditor.current.editor.execCommand("delete");
      tinyMCEEditorKlass.setState({
        alertDetails: {
          showCancleButton: false,
          showAlert: true,
          title: "Error!",
          text: String(error)
        }
      });
    }
    function imageUplaodSuccessCB(location) {
      success(location);
    }
    uploadImageInMCE(formData, imageUplaodSuccessCB, imageUploadFailureCB);
  }

  filePickerCallback(cb, value, meta) {
    const tinyMCEEditorKlass = this;
    const tinyMCEContext = this.props.tinyMCEEditor.current.editor;
    const { setContentUpdatable } = this.props;

    var input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.onchange = function () {
      let file = this.files[0];
      //check file size
      if (file.size > 2097152) {
        //2MB
        tinyMCEEditorKlass.setState({
          alertDetails: {
            showCancleButton: false,
            showAlert: true,
            title: "Error!",
            text: String("Image Files too big, Max Size Allowed is 2MB")
          }
        });
        return false;
      }
      setContentUpdatable(false);
      let reader = new FileReader();

      reader.onload = function () {
        const id = "blobid" + new Date().getTime();
        const blobCache = tinyMCEContext.editorUpload.blobCache;
        const base64 = reader.result.split(",")[1];
        const blobInfo = blobCache.create(id, file, base64);
        blobCache.add(blobInfo);
        cb(blobInfo.blobUri(), { title: file.name });
      };
      reader.readAsDataURL(file);
    };
    input.click();
  }

  onEditorContentChange(content) {
    this.handleEditorChange(content);
    this.props.updateEditorContent(content);
  }
  render() {
    const {
      shareType,
      editorContent,
      uploadingImageFlipper,
      uploadFlipperedImages,
      appendBlockQuote,

      hideImageFlipper,
      showImageFlipper,
      showBlockQuote,
      hideBlockQuote,

      showImageFlipperModal,
      showBlockQuoteModal,
      updateApi,
      updateEditorContent,
      showPiscoModal,

      setAppMessage
    } = this.props;
    window.shareType = shareType;
    const {
      alertDetails: {
        showCancleButton = false,
        showAlert = false,
        title = "",
        text = ""
      }
    } = this.state;

    const EVENTS = [
      // {
      //   text: "imgFlipper",
      //   onClick: showImageFlipper
      // }
      // ,
      {
        text: "Embed",
        onClick: showBlockQuote
      },
      {
        text: "Pisco",
        onClick: showPiscoModal
      }
    ];
    const { fullScreenState } = this.state;
    let containerClass = "tinymce-editor-container ".concat(
      fullScreenState ? " tinymce-editor-container-fullscreen" : ""
    );
    const fullScreenIcon = fullScreenState ? (
      <RoundButton
        type='fullscreen_exit'
        className="fullscreen"
        location={{
          float: "right",
          position: "absolute",
          right: "-1px",
          top: "-5px",
          cursor: "pointer",
          zIndex: "99998"
        }}
        buttonbackground="white"
        iconcolor="#2b2d42"
        onclick={() => {
          this.setState({ fullScreenState: false });
        }}
      />
    ) : (
        <RoundButton
          type='fullscreen'
          className="fullscreen"
          location={{
            border:"0",
            float: "right",
            position: "absolute",
            right: "-1px",
            bottom: "1px",
          }}
          buttonbackground="white"
          iconcolor="#2b2d42"
          onclick={() => {
            this.setState({ fullScreenState: true });
          }}
        />
      );
    let editorDefaultContent = editorContent;
    //console.log("editorDefaultContent", editorDefaultContent);
    if (this.props.tinyMCEEditor.current && !editorContent) {
      // if(this.props.tinyMCEEditor.current && this.props.tinyMCEEditor.current.element && this.props.tinyMCEEditor.current.element.innerHTML === '<p><br data-mce-bogus="1"></p>'){
      containerClass = containerClass.concat(" empty-container");
      // }
    } else {
      // containerClass = containerClass.concat(" written-container");
    }

    // const totalWords = editorContent.split(' ').length;
    const totalWords = getCountFromContent(editorContent);


    const self = this;

    return (
      <div id="article-contents">
        <div
          className={containerClass}
          style={{ border: "2px solid #dddbdb", borderRadius: "4px" }}
        >
          <span
            style={{
              position: "absolute",
              right: fullScreenState? "43px": "3px",
              top: "9px",
              color: "black",
              fontWeight: "bold"
            }}
          >
            {totalWords} words
        </span>{" "}
          {fullScreenIcon}
          <Editor
            contenteditable="true"
            ref={this.props.tinyMCEEditor}
            apiKey="nnqf35khd0owkobaobxk84b0n5xf0w5uzcv4kaepewkyfk5u"
            inline
            value={editorDefaultContent}
            // plugins= 'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table contextmenu paste'
            init={{
              placeholder: "Start writing........",
              // selection_toolbar: 'undo redo bold italic link underline strikethrough alignleft aligncenter alignright alignjustify image cut copy paste fontselect removeformat subscript superscript insert',
              selection_toolbar: "bold italic link h2 h3 underline",
              plugins: "image paste autosave media link autoresize fullscreen table",
              menubar: "tools",
              toolbar: "image table",
              // insert_toolbar: "styleselect | blockquote | bold italic | link image media | pagebreak | Twitter/Instagram | imgFlipper ",
              insert_toolbar:
                "h2 | h3 | blockquote | pagebreak | Embed | imgFlipper | fullscreen | Pisco | table",
              theme: "inlite",
              external_plugins: {
                "link": "https://cdn.meaww.com/writer/js/link.min.js"
              },
              pagebreak_separator: "<pagebreak>",
              min_height: 800,
              height: 800,

              //autosave
              autosave_interval: "5s",

              //media
              media_live_embeds: true,

              //styleselect
              style_formats: [
                {
                  title: "Heading",
                  classes: "split",
                  block: "h2"
                },
                {
                    title: "Heading 3",
                    classes: "split3",
                    block: "h3"
                }
              ],
              //custom formats
              formats: {
                h2: {
                  block: "h2",
                  classes: "split"
                },
                h3: {
                    block: "h3",
                    classes: "split3"
                },
                blockquote: {
                  block: "blockquote",
                  classes: "meaww-blockquote"
                }
              },

              //tinymcespellchecker
              spellchecker_language: "en",
              //autoresize
              autoresize_bottom_margin: 50,
              autoresize_max_height: 1000,
              autoresize_min_height: 500,
              autoresize_overflow_padding: 50,

              //paste
              paste_as_text: true,
              paste_data_images: false,
              //
              entity_encoding: "raw",
              branding: false,

              //image
              image_caption: true,
              image_dimensions: false,
              image_description: true, //default
              image_title: false, //default

              color_picker_callback: function (callback, value) {
                callback("#FF00FF");
              },
              init_instance_callback: function (editor) {
                // console.log("Editor: " + editor.id + " is now initialized.");
              },
              media_live_embeds: true,
              //drag and drop image within editor
              automatic_uploads: true,
              //image file_picker
              file_picker_types: "image",
              file_picker_callback: this.filePickerCallback,
              images_upload_handler: this.imagesUploadHandler,

              setup: function (editor) {
                editor.addButton('H+', {
                  text: 'H+',
                  onClick: function () {
                    const content = editor.selection.getContent({format: 'text'});
                    const id = content.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
                    const className = 'quickLinks';
                    editor.insertContent(`<h2 id="${id}" class="${className}">${content}</h2>`);
                  }
                });
                EVENTS.forEach(function (event, index) {
                  editor.addButton(event.text, {
                    text: event.text,
                    tooltip: event.text,
                    onClick: function () {
                      event.onClick();
                    }
                  });
                  editor.on("ExecCommand", function (e) {
                    if (e.command === "mceBlockQuote") {
                      let blockQuote = $("[aria-label=Blockquote]")[0];
                      blockQuote.getAttribute("aria-pressed");

                      if (self.state.blockQuote + 1 === 2) {
                        if ($("[aria-label='Embed']").css("display") === "inline-block") {
                          $("[aria-label='Embed']").hide();
                          $("[aria-label='Insert/edit media']").hide();
                          $("[aria-label='Picture']").hide();
                          $("[aria-label='Heading 2']").hide();
                        } else {
                          $("[aria-label='Embed']").show();
                          $("[aria-label='Insert/edit media']").show();
                          $("[aria-label='Picture']").show();
                          $("[aria-label='Heading 2']").show();
                        }
                      }
                      self.setState({ blockQuote: (self.state.blockQuote + 1) % 2 })
                    } else {
                       $("[aria-label='Insert/edit media']").show();
                       $("[aria-label='Picture']").show();
                       $("[aria-label='Embed']").show();
                       $("[aria-label='Heading 2']").show();
                    }
                  })
                });
                editor.on("init", function (args, editor) {
                  // console.log("arguments===", args);
                  // console.log("editor==", editor);
                });
                editor.on("PastePreProcess", function (e) {
                  e.content = e.content.replace(/<\/p><p>/g, '<br>');
                  e.content = e.content.replace(/<p>/g, '');
                  e.content = e.content.replace(/<\/p>/g, '');
                });
                editor.on("NodeChange", function (e) {
                  const {
                    element,
                    parents: [target = {}, parent = {}]
                  } = e;

                  // set nofollow for anchor tag
                  if (element.nodeName.toUpperCase() === "A") {
                    const url = element.getAttribute("href");
                    // TODO:  add valid url testing
                    if (
                      !url.match(window.shareType ? window.shareType : "meaww")
                    ) {
                      // element.setAttribute("rel", "nofollow");
                      element.setAttribute("target", "_blank");
                    } else {
                      // element.setAttribute("rel", "");
                      element.setAttribute("target", "");
                    }
                  } else if (
                    element.nodeName === "FIGCAPTION" &&
                    parent.nodeName === "FIGURE"
                  ) {
                    // make fig caption editable
                    parent.setAttribute("contenteditable", true);
                  } else {
                    const childNodes = this.targetElm.childNodes;
                    for (var i = 0; i < childNodes.length; i++) {
                      let node = childNodes[i];
                      if (node.nodeName.toUpperCase() === "FIGURE") {
                        node.setAttribute("contenteditable", false);
                      }
                    }
                  }
                });
              }
            }}
            onEditorChange={this.onEditorContentChange}
          />
          <EmbedModal
            showBlockQuoteModal={showBlockQuoteModal}
            showBlockQuote={showBlockQuote}
            hideBlockQuote={hideBlockQuote}
            appendBlockQuote={this.appendBlockQuoteIncontentArea}
            setAppMessage={setAppMessage}
          />
          <SweetAlert
            show={showAlert}
            title={title}
            text={text}
            onConfirm={this.hideAlert}
          />
        </div>
      </div>
    );
  }
}
