import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import LoginForm from "./views/login-form.jsx";
import { Helmet } from "react-helmet";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import Header from '../app-components/header.jsx';
import Footer from '../app-components/footer.jsx';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      redirectToReferrer: false,
    };
  }

  render() {
    const { classes } = this.props;
    const { redirectToReferrer } = this.state;
    let { from } = this.props.location.state || { from: { pathname: "/" } };
    if (redirectToReferrer) return <Redirect to={from} />;

    return (
      <>
        <Helmet title="Login | PubNinja - You Publish. The rest we manage." />
        <Header />
        <div className={classes.container} style={{marginTop: '150px'}}>
          <GridContainer justify="center" alignItems="flex-start" >
            <GridItem xs={12} sm={12} md={12}>
              <LoginForm  {...this.props} />
            </GridItem>
          </GridContainer>
        </div>
        <Footer/>
      </>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(LoginPage);
