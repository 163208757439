import React, { Component } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import articlesStyle from "../../../assets/jss/material-dashboard-pro-react/views/articlesStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Divider from "@material-ui/core/Divider";
import ShowTable from "./show-table";
import Paper from '@material-ui/core/Paper';

class Accordian extends Component {
  state = {
    showCampaign: false
  }
  handleChange = (field) => {
    this.setState((prev) => ({
      [field]: !prev[field],
    }));
  }
  render() {
    const { classes, campValue } = this.props;
    return (
      <ExpansionPanel expanded={this.state.showCampaign} onChange={() => { this.handleChange(`showCampaign`) }} className={classes.expansionContainerForNewsLetter}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.articleTitle}>{campValue['subject']}</Typography>
        </ExpansionPanelSummary>
        <Divider style={{ height: "1px", backgroundColor: "#80808091" }} />
        {
          this.state.showCampaign
          &&
          <ExpansionPanelDetails>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12} >
                <ShowTable tableData={campValue} />
              </GridItem>
            </GridContainer>
          </ExpansionPanelDetails>
        }
      </ExpansionPanel>
    )
  }
}
export default withStyles(articlesStyle)(Accordian);