const LandingPageAction = {
  LAOD: {
    REQUEST: "LandingPage.Load.Request",
    SUCCESS: "LandingPage.Load.Success",
    FAILURE: "LandingPage.Load.Failure",
  },
  SETTING: {
    SET: 'Set.menuIdForLanding',
    REMOVE: 'Remove.menuIdForLanding'
  },
  DELETE: {
    PAGE: 'LandingPage.Delete.Page'
  },
  LPMODAL: {
    SET: 'Set.openLpModal',
    REMOVE: 'Remove.openLpModal'
  },
  UPDATE: {
    REQUEST: "LandingPage.Update.Request",
    SUCCESS: "LandingPage.Update.Success",
    FAILURE: "LandingPage.Update.Failure",
  },
  CATEGORY: {
    SETACTIVE: "Set.ActiveCategory"
  },
  ADDLPMODAL: {
    OPEN: 'Open.AddLpModal',
    CLOSE: 'Close.AddLpModal'
  },
  ADDNEWLP: {
    REQUEST: "LandingPage.AddNewLp.Request",
    SUCCESS: "LandingPage.AddNewLp.Success",
    FAILURE: "LandingPage.AddNewLp.Failure",
  },
  PISCO: {
    OPEN: 'Pisco.Open.UploadAvator',
    CLOSE: 'Pisco.Close.UploadAvator',
    COPYCARDID: 'Pisco.copy.CardID',
    PASTECARDID: 'Pisco.past.CardID'
  },
  AVATOR: {
    SET: 'LandingPage.Avator.Set',
    CROPEDURL: 'LandingPage.Set.CropedUrl'
  },
  RESETCROPEDURL: {
    RESET: 'LandingPage.Avator.Reset',
    RESETAVATOR: 'LandingPage.ResetAvator'
  }
}
export default LandingPageAction;
