import React from 'react';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import AppLoader from "app-components/app-loader.jsx";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';

class AddSocialMedia extends React.Component {
  state = {
    otp: '',
  }

  handleChange = (event, field) => {
    this.setState({[field]: event.target.value});
  }

  handleSubmit = () => {
    this.props.submitOtp(this.state.otp);
  }

  render(){
    return (
      <>
        <Dialog
          open={this.props.showOtpModal}
          fullWidth={true}
          maxWidth="sm"
          aria-labelledby="customized-dialog-title"
        >
          <DialogTitle>Enter One Time Password</DialogTitle>
          {
            (this.props.isSubmittingOtp) &&
            <DialogContent>
              <AppLoader />
              </DialogContent>
          }
          {
            (this.props.otpVerified && !this.props.isSubmittingOtp) &&
            <>
              <DialogContent>
                <div style={{fontWeight: 'bold'}}>
                  Mobile Number successfully verified.
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  onClick={this.props.closeOtpModal}
                >
                  Close
                </Button>
              </DialogActions>
            </>
          }
          {
            (!this.props.otpVerified && !this.props.isSubmittingOtp) &&
            <>
              <DialogContent>
                <div>
                  <GridContainer justify="center">
                    <GridItem xs={8} md={4}>
                      <CustomInput
                        id="OTP"
                        labelText="One Time Password"
                        inputProps={{
                          onChange: (event)=>{this.handleChange(event, 'otp')}
                        }}
                        formControlProps={{
                          fullWidth: true
                        }}
                        value={this.state.otp}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center">
                    <GridItem xs={4}>
                      <div
                        style={{color: '#2b2d42', fontWeight: 'bold', textAlign: 'center', marginTop: '25px', cursor: 'pointer'}}
                        onClick={this.props.generateOtp}
                      >
                        RESEND OTP
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="secondary"
                  onClick={this.handleSubmit}
                  disabled={!this.state.otp.trim()}
                >
                  Verify
                </Button>
                <Button
                  color="primary"
                  onClick={this.props.closeOtpModal}
                >
                  Cancel
                </Button>
              </DialogActions>
            </>
          }

        </Dialog >
      </>
    );
  }
}

export default withWidth()(AddSocialMedia);
