import React, { PureComponent } from "react"
import throttle from "lodash/throttle";
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';


class ArticleTitle extends PureComponent {
  constructor(props) {
    super(props);
    this.setChangeValue = throttle(this._setChangeValue, 100);
    this.textArea = React.createRef();
    this.state = { length: 0, title: props.title || "" };
  }

  componentDidMount() {
    const { defaultValue } = this.props;
    let length = 0;
    if (defaultValue) {
      length = defaultValue.length;
    }
    // if (this.props.title === '' || this.props.title === null) {
    //   this.setState({ length: this.props.ogTitle.length, title: this.props.ogTitle });
    //   this.props.setTitle(this.props.ogTitle, 'change');
    // }
    this.setState({ length });
  }
  _setChangeValue = (e) => {
    this.props.setTitle(e.target.value, e.type);
  }
  onChange = (e) => {
    e.persist();
    this.setState({ length: e.target.value.length, title: e.target.value });
    this.setChangeValue(e);
  }
  render() {
    const { title } = this.state;

    return (
      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <TextField
          id="standard-name"
          label="Website Headline"
          value={title}
          onChange={this.onChange}
          onBlur={this.onChange}
          InputProps={{
            endAdornment: <InputAdornment position="start" style={{whiteSpace:"nowrap"}}>{`${title && title.length || 0}/100`}</InputAdornment>,
            style: {
              border:"1px solid #ced3dc",
              width: "100%",
            }
          }}
          inputProps={{ maxLength: 300 }}
          multiline={true}
          fullWidth
          margin="normal"
        />
      </div>
    )
  }
}

export default ArticleTitle;
