
// story post constant

const StoryPostAction = {
    LOAD: {
        REQUEST: "StoryPostAction.Load.Request",
        SUCCESS: "StoryPostAction.Load.Success",
        FAILURE: "StoryPostAction.Load.Failure",
        TEMPLATE: {
          REQUEST: "StoryPostAction.Load.Template.Request",
          SUCCESS: "StoryPostAction.Load.Template.Success",
          FAILURE: "StoryPostAction.Load.Template.Failure",
          UNMOUNT: 'StoryPostAction.Load.Template.Unmount'
        }
    },
    SET: {
        STORY_DETAIL: 'StoryPostAction.Set.StoryDetail'
    }
}

export default StoryPostAction;
