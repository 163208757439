import React, { Component } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import CategoryCard from './category-card';
import SubCategoryCard from './subcategory-card';
import SubCategoryAddModal from './addSubCategoryModal';
import Hidden from '@material-ui/core/Hidden';

class CategoriesList extends Component {
  state = {
    yCategory: (this.props.categories && this.props.categories.length > 0) ? this.props.categories[0].yCategory : '',
    selected: 0,
    showSubCategoryAddModal: false,
    selectedCategory: (this.props.categories && this.props.categories.length > 0) ? this.props.categories[0] : ''
  }

  setYCategory = (category, activeIndex) => {
    this.setState({ ['yCategory']: category.yCategory, ['selected']: activeIndex, ['selectedCategory']: category })
  }
  toggleAddSubCategoryModal = (val) => {
    this.setState({ ...this.state, ['showSubCategoryAddModal']: val })
  }
  render() {
    const { categories = [], setCategoryModalData, submitSubCategory } = this.props;
    const { yCategory, selectedCategory } = this.state;
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>

              <CardBody style={{ paddingTop: '0px' }}>
                <div style={{ display: 'flex' }}>
                <GridContainer>
            {/* Categories */}
                  <GridItem xs={12} md={6}>
                    <GridContainer>
                      <GridItem xs={12}>
                        <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>Category</h4>
                      </GridItem>
                      <GridItem xs={12}>
                        {
                          categories && categories.map((item, key) => {
                            return <CategoryCard category={item} key={item.id} activeIndex={key} selectedIndex={this.state.selected} setCategoryModalData={setCategoryModalData} setYCategory={this.setYCategory} />
                          })
                        }
                      </GridItem>
                      <GridItem xs={12}>
                        <Button
                          color='primary'
                          onClick={() => { this.props.toggleCategoryModal(true) }}
                        >
                          <span style={{ display: "flex", cursor: 'pointer' }} >
                            <AddCircleIcon /> &nbsp;<span style={{ paddingTop: '2px' }}>{`Add Category`}</span>
                          </span>
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <Hidden smUp>
                    <GridItem xs={12}>
                     <hr/>
                    </GridItem>
                  </Hidden>
            {/* SubCategories */}
                  <GridItem xs={12} md={6}>
                    <GridContainer>
                      <GridItem xs={12}>
                        <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>Subcategory</h4>
                      </GridItem>
                      <GridItem xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                          <span style={{ fontStyle: 'italic', textTransform: 'capitalize' }}> Subcategory of {selectedCategory.category} </span>
                        </div>
                        {yCategory[0] ? yCategory.map((item) => {
                          return <SubCategoryCard yCategory={item} activeSubCat={item} key={item.id} submitSubCategory={submitSubCategory} selectedCategory={selectedCategory} />
                        }) : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}><h5>No Subcategory</h5></div>}

                      </GridItem>
                      <GridItem xs={12}>
                        <Button
                          color='primary'
                          onClick={() => { this.toggleAddSubCategoryModal(true) }}
                        >
                          <span style={{ display: "flex", cursor: 'pointer' }} >
                            <AddCircleIcon /> &nbsp;<span style={{  paddingTop: '2px' }}>{`Add Subcategory`}</span>
                          </span>
                        </Button>

                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                </div>
              </CardBody>
          </GridItem>
        </GridContainer>
        {this.state.showSubCategoryAddModal && < SubCategoryAddModal submitSubCategory={submitSubCategory} showSubCategoryAddModal={this.state.showSubCategoryAddModal} toggleAddSubCategoryModal={this.toggleAddSubCategoryModal} category={this.state.selectedCategory} />}
      </>
    )
  }

}

export default CategoriesList;
