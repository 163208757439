import "whatwg-fetch";
import { UserAction, MessageTypes, SessionKeys } from "../app-constants";
import * as axios from "axios";
import { MeawwApi, callbackWrapper } from "../utils/api.js";
import { setAppMessage } from "../app-actions.js";
import { fetchMyPermission } from "../user-management/user-permission/user-permission-action.js";


export function editUserProfileRequest() {
  return {
    type: UserAction.USER.UPDATE_PROFILE.REQUEST,
  }
}
export function editUserProfileSuccess() {
  return {
    type: UserAction.USER.UPDATE_PROFILE.SUCCESS,
  }
}
export function editUserProfileFailure() {
  return {
    type: UserAction.USER.UPDATE_PROFILE.FAILURE,
  }
}

// export function editUserProfile(formData, redirectToProfilePage) {
//   return (dispatch, ) => {
//     dispatch(editUserProfileRequest());
//     return MeawwApi()
//       .auth()
//       .then((api) => {
//         return api.put("/user", {
//           isFormData: true,
//           data: formData,
//         }, (err, data) => {
//           if (err) {
//             dispatch(editUserProfileFailure());
//             dispatch(setAppMessage(err.message.toString(), { type: MessageTypes.ERROR }));
//             return Promise.reject(err);
//           }
//           dispatch(editUserProfileSuccess());
//           dispatch(setAppMessage(data.message, {
//             type: MessageTypes.SUCCESS,
//             onOk: () => {
//               if (redirectToProfilePage) {
//                 redirectToProfilePage();
//               }
//             }
//           }));
//           return Promise.resolve();
//         })
//       });
//   }
// }

export function editUserProfile(formData, redirectToProfilePage) {
  return async (dispatch,getState) => {
    try {
      const { login: { user: { activeDomain } } } = getState();
      dispatch(editUserProfileRequest());
      const updatedUserData = await axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_USER_SERVICE_API}/users/${activeDomain}/profile`,
        data: formData,
        headers: {
          //'Content-Type': 'application/json',
          accept: 'application/json',
          "authorization": localStorage.getItem('accessToken'),
        },
      });
      dispatch(editUserProfileSuccess());
      dispatch(setAppMessage(updatedUserData.message, {
        type: MessageTypes.SUCCESS,
        onOk: () => {
          if (redirectToProfilePage) {
            redirectToProfilePage();
          }
        }
      }));
      return Promise.resolve();
    }
    catch (err) {
      dispatch(editUserProfileFailure());
      dispatch(setAppMessage(err.message.toString(), { type: MessageTypes.ERROR }));
      return Promise.reject(err);
    }

  }
}

function updateUserData(userDetails) {
  return {
    type: UserAction.USER_DATA.UPDATE,
    userDetails: userDetails
  }
}

// export function getUserDetails() {
//   return (dispatch, getState) => {
//     return MeawwApi()
//       .auth()
//       .then(api => {
//         return api.get("/user",
//           (err, data) => {
//             if (err) {
//               dispatch(setAppMessage(err.message.toString(), { type: MessageTypes.ERROR }));
//               return Promise.reject();
//             }
//             dispatch(updateUserData(data.userInfo));
//             return Promise.resolve(data);
//           });
//       });
//   }
// }

export function getUserDetails() {
  return async (dispatch, getState) => {
    try {
      const { login: { user: { activeDomain } } } = getState();
      const updatedUserData = await fetch(`${process.env.REACT_APP_USER_SERVICE_API}/users/${activeDomain}/profile`, {
        method:'GET',
        headers: {
          "authorization": localStorage.getItem('accessToken'),
        },
       
      }).then(res => res.json());
      dispatch(updateUserData(updatedUserData));
      return Promise.resolve(updatedUserData);
    }
    catch (err) {
      console.log("err",err)
    }
  }
}

// export function getUserDetails() {
//   return (dispatch, getState) => {
//     return MeawwApi()
//       .auth()
//       .then(api => {
//         return api.get("/author/getDetails",
//           callbackWrapper(dispatch, (err, data) => {
//             if (err) {
//               dispatch(setAppMessage(err.message.toString(), { type: MessageTypes.ERROR }));
//               return Promise.reject();
//             }
//             let userDetails = {};
//             userDetails["firstName"] = data.first_name;
//             userDetails["lastName"] = data.last_name;
//             userDetails["profilePicture"] = data.profile_picture_url;
//             userDetails["coverPicture"] = data.cover_picture_url;
//             userDetails["phoneNumber"] = data.phone_number;
//             userDetails["fbProfile"] = data.fb_profile;
//             userDetails["twitterProfile"] = data.twitter_profile;

//             dispatch(updateUserData({ ...data, ...userDetails }));
//             return Promise.resolve(data);
//           }));
//       });
//   }
// }

export function setUserDataSuccess(userDetails) {
  return {
    type: UserAction.SET.USER_DATA.SUCCESS,
    userDetails: userDetails
  }
}
export function setUserDataRequest() {
  return {
    type: UserAction.SET.USER_DATA.REQUEST,
  }
}

export function setUserDataFailure() {
  return {
    type: UserAction.SET.USER_DATA.FAILURE,
  }
}

export function fetchUserDetails() {
  return async (dispatch, getState) => {
    try {
      dispatch(setUserDataRequest())
      const { login: { user: { activeDomain } } } = getState();
      const updatedUserData = await fetch(`${process.env.REACT_APP_USER_SERVICE_API}/${activeDomain}/profile`, {
        method:'GET',
        headers: {
          "authorization": localStorage.getItem('accessToken'),
        },
       
      }).then(res => res.json());
      dispatch(setUserDataSuccess({ ...updatedUserData, activeDomain }));
      return Promise.resolve(updatedUserData)
    }
    catch (err) {
      dispatch(setUserDataFailure())
      console.log("err",err)
    }
  }
}

export function fetchUserDetailsForAuth(defaultActiveDomain) {
  let tempActiveDomain = defaultActiveDomain;
  return async (dispatch,getState) => { 
    try {
      // dispatch(setUserDataRequest())
      const updatedUserData = await fetch(`${process.env.REACT_APP_USER_SERVICE_API}/users`, {
        method:'GET',
        headers: {
          "authorization": localStorage.getItem('accessToken'),
        },
       
      }).then(res => res.json());
      
      let { domains: [activeDomain] } = updatedUserData;
         
          if (!activeDomain) {
            return Promise.resolve({...updatedUserData, noActiveDomain:true});
          }
          const { domains = [] } = updatedUserData;
        
          if (!domains.includes(parseInt(sessionStorage.getItem(SessionKeys.ACTIVE_DOMAIN)))) {
            sessionStorage.removeItem(SessionKeys.ACTIVE_DOMAIN)
          }
          activeDomain = parseInt(sessionStorage.getItem(SessionKeys.ACTIVE_DOMAIN)) || activeDomain;
          if (tempActiveDomain) {
            activeDomain = tempActiveDomain
          }
          const profileData = await fetch(`${process.env.REACT_APP_USER_SERVICE_API}/users/${activeDomain}/profile`, {
            method:'GET',
            headers: {
              "authorization": localStorage.getItem('accessToken'),
            },
           
          }).then(res => res.json());
          window.Intercom && window.Intercom('update', { email: updatedUserData.email, user_id: updatedUserData.userUuid, name: updatedUserData.email });
          sessionStorage.setItem(SessionKeys.ACTIVE_DOMAIN, activeDomain);
          return dispatch(fetchMyPermission(activeDomain))
            .then(function (permissions) {
              return dispatch(setUserDataSuccess({ ...updatedUserData,...profileData, activeDomain, permissions: permissions }));
            })
            .catch(err => Promise.reject(err));
    }
    catch (err) {
      console.log("err", err)
      dispatch(setUserDataFailure())
    }
  }
}

function setUserDomainsSuccess(domainMap) {
  return {
    type: UserAction.FETCH.DOMAINS.SUCCESS,
    domainMap: domainMap
  }
}

export function fetchUserDomains() {
  return (dispatch) => {
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get("/acl/domains?t=0", (err, data) => {
          if (err) {
            return Promise.reject(err);
          }
          dispatch(setUserDomainsSuccess(data));
          return Promise.resolve(data);
        });
      });
  }
}

export function setActiveDomain(domainId) {
  return (dispatch, getState) => {
    dispatch({
      type: UserAction.SET.ACTIVE_DOMAIN,
      domainId: domainId
    });
    dispatch(fetchUserDetailsForAuth(domainId))
  }
}
