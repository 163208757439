import React from "react";
import ListHomePage from "./view/list-page";

class StoryCmsHomePage extends React.PureComponent {
  render() {
    return (
      <ListHomePage/>
    );
  }
}

export default StoryCmsHomePage;
