import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";

class WebsiteManagementHomePage extends PureComponent {
  render() {
    return (<div>
      <Helmet title="Website | PubNinja - You Publish. The rest we manage" />
      {this.props.children}
    </div>)
  }
}
export default WebsiteManagementHomePage;
