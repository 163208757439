import React, { Component } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import articlesStyle from "../../../assets/jss/material-dashboard-pro-react/views/articlesStyle.jsx";
import Divider from "@material-ui/core/Divider";
import { ActionOnArticle, ArticleAction, PreviewModalAction } from '../../../app-constants/index.js';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toggleConfirmationBox } from "../articles-actions";
import withConfirmation from "../../../app-components/confirmation-hoc";
import {
  setArticleId,
  // setArticleIdPreivewList,
  submitArticle,
  toggleArticleMsnGallery,
  deleteArticle,
  approveArticle,
  sendFeedback,
  publishArticle,
  unpublishArticle,
  setFeedbackModal,
  setArticleIdForPreviewArticle,
  setDisplayPreviewAction,
  LockAndEditArticle,
  clearArticleCache,
  shareArticleDebug,
  finishEditingArticle,
  shareLiveArticle,
  toggleAdsForArticle,
  scheduleLiveArticle,
  exploitLiveArticle,
  scheduleTwitterLiveArticle,
  sendBrowserNotification,
  removeArticleIdForMenuList,
  setSchedularModal,
  setExploiterModal,
  deleteScheduleArticle,
  deleteScheduleAsExploitArticle,
} from "../../article/article-action.js";
import AppLoader from '../../../app-components/app-loader.jsx';


const WithConfirmMenuItem = withConfirmation(MenuItem);

class MenuDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    }
  }
  setPreviewArticleIdWithAction = (action, flag) => {
    const { menuListArticleId, setArticleIdForPreviewArticle, setDisplayPreviewAction } = this.props;
    setArticleIdForPreviewArticle(menuListArticleId, action);
    flag && setDisplayPreviewAction();
  }

  removeArticleIdForMenu = () => {
    const { removeArticleIdForMenuList } = this.props;
    removeArticleIdForMenuList();
  }

  handleActions = (option) => {
    console.log("menu option", option);
    const { menuListArticleId } = this.props;
    switch (option.action) {
      case ActionOnArticle.EDIT: {
        this.props.history.push(`/cms/articles/edit-story/${menuListArticleId}`);
        return;
      }
      case ActionOnArticle.REVIEW_AND_EDIT: {
        this.props.LockAndEditArticle(false)
          .then((response) => {
            this.props.history.push(`/cms/articles/edit-story/${menuListArticleId}`);
          });
        return;
      }
      case ActionOnArticle.FORCE_EDIT: {
        this.props.LockAndEditArticle(true)
          .then((response) => {
            this.props.history.push(`/cms/articles/edit-story/${menuListArticleId}`);
          })
        return;
      }
      case ActionOnArticle.SUBMIT: {
        this.setPreviewArticleIdWithAction({ requiredAction: ActionOnArticle.SUBMIT });
        // this.props.setArticleIdPreivewList(menuListArticleId);
        // this.props.submitArticle();
        return;
      }
      case ActionOnArticle.DELETE: {
        this.props.deleteArticle();
        return;
      }
      case ActionOnArticle.TOGGLE_MSN_GALLERY: {
        const { articlesByIds, menuListArticleId } = this.props;
        const articleDetails = articlesByIds[menuListArticleId];
        let enable = true;
        if (typeof articleDetails.enabledMsnGallery === 'boolean' && articleDetails.enabledMsnGallery === true) {
          enable = false;
        }
        this.props.toggleArticleMsnGallery(enable);
        return;
      }
      case ActionOnArticle.APPROVE: {
        this.setPreviewArticleIdWithAction({ requiredAction: ActionOnArticle.APPROVE });
        // this.props.approveArticle();
        return;
      }

      case ActionOnArticle.SEND_FEEDBACK: {
        this.props.setFeedbackModal(true);
        return;
      }
      case ActionOnArticle.PUBLISH: {
        this.setPreviewArticleIdWithAction({ requiredAction: ActionOnArticle.PUBLISH });
        return;
      }
      case ActionOnArticle.VIEW_ON_WEBSITE: {
        const { articlesByIds, menuListArticleId } = this.props;
        const articleDetails = articlesByIds[menuListArticleId];
        const { url = "" } = articleDetails;
        window.open(url, '_blank');
        window.focus();
        return
      }
      case ActionOnArticle.SHARE: {
        const { articlesByIds, menuListArticleId } = this.props;
        const articleDetails = articlesByIds[menuListArticleId];
        this.props.shareLiveArticle(articleDetails);
        return;
      }
      case ActionOnArticle.CLEAR_CACHE: {
        this.props.clearArticleCache();
        return;
      }
      case ActionOnArticle.SHARE_DEBUG: {
        this.props.shareArticleDebug();
        return;
      }
      case ActionOnArticle.UNPUBLISH: {
        this.props.unpublishArticle();
        return;
      }
      case ActionOnArticle.FINISH_EDIT: {
        this.props.finishEditingArticle();
        return;
      }
      case ActionOnArticle.PREVIEW: {
        this.setPreviewArticleIdWithAction(undefined, true);
        return;
      }
      case ActionOnArticle.TOGGLE_ADS: {
        const { articlesByIds, menuListArticleId } = this.props;
        const articleDetails = articlesByIds[menuListArticleId];
        const { noAds } = articleDetails;
        this.props.toggleAdsForArticle(noAds ? 0 : 1);
        return;
      }
      case ActionOnArticle.FACEBOOK_SCHEDULE: {
        const { articlesByIds, menuListArticleId } = this.props;
        const articleDetails = articlesByIds[menuListArticleId];
        this.props.scheduleLiveArticle(articleDetails);
        return;
      }
      case ActionOnArticle.EXPLOIT_SCHEDULE: {
        const { articlesByIds, menuListArticleId } = this.props;
        const articleDetails = articlesByIds[menuListArticleId];
        this.props.exploitLiveArticle(articleDetails);
        return;
      }
      case ActionOnArticle.SEND_NOTIFICATION: {
        const { menuListArticleId } = this.props;
        this.props.sendBrowserNotification(menuListArticleId);
        return;
      }

      case ActionOnArticle.TWITTER_SCHEDULE: {
        const { articlesByIds, menuListArticleId } = this.props;
        const articleDetails = articlesByIds[menuListArticleId];
        this.props.scheduleTwitterLiveArticle(articleDetails);
        return;
      }

      case ActionOnArticle.SCHEDULE_ARTICLE: {
        this.props.setSchedularModal(true);
        return;
      }

      case ActionOnArticle.CANCEL_SCHEDULED_ARTICLE: {
        this.props.deleteScheduleArticle(true);
        return;
      }

      case ActionOnArticle.EXPLOIT_ARTICLE: {
        this.props.setExploiterModal(true);
        return;
      }

      case ActionOnArticle.CANCEL_SCHEDULE_EXPLOIT_ARTICLE: {
        this.props.deleteScheduleAsExploitArticle(true);
        return;
      }

      default: return;

    }
  }

  // this.setPreviewArticleIdWithAction();

  render() {
    const { options, menuListArticleId, anchorEl, articlesByIds, user, articleSettings, domain, classes, scheduleStatus } = this.props;
    const open = Boolean(menuListArticleId);
    const articleDetails = articlesByIds[menuListArticleId];
    if (!anchorEl) {
      return null;
    }
    return (
      <Popover
        id="menu-popover"
        anchorEl={anchorEl}
        open={open}
        onClose={this.removeArticleIdForMenu}
        PaperProps={{
          style: {
            width: 250,
            marginLeft: '-15px',
            border: 'solid 1px #ced3dc',
            background: '#ffffff',
            boxShadow: 'none'
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {
          options.map((option, key) => {
            if (option.key === "separator") {
              return <Divider style={{ background: "#ced3dc" }} key={key} />
            }
            const { featurePermision } = domain;
            const { requiredFeaturePermission } = option;
            if (requiredFeaturePermission && featurePermision.indexOf(requiredFeaturePermission) < 0) return null;

            function getText(option) {
              let hasPermission = false;
              option.requiredPermissions.forEach(permission => {
                if (user.permissions && user.permissions.indexOf(permission) > -1) {
                  hasPermission = true;
                }
              })
              if (!hasPermission) {
                return null;
              }

              let optionText;
              if (typeof option.text === "function") {
                optionText = option.text(articleDetails, user)
              } else {
                optionText = option.text;
              }
              if (scheduleStatus == 0) {
                optionText = "Resedule Article"
              }
              const { loaderName = null } = option;
              if (option.loaderName && articleSettings[loaderName]) {
                return <span style={{ display: "flex" }}>{optionText}{" "}<AppLoader isSignOutLoader={true} color="inherit" size={20} /></span>
              } else {
                return optionText;
              }
            }
            const labelText = articleDetails ? getText(option) : null;

            if (labelText === null) {
              return
            }
            const { requireConfirm, confirmMsg } = option;
            if (requireConfirm) {
              return (<WithConfirmMenuItem style={{
                color: '#5d737e',
                fontSize: '12px'
              }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = '#fdfaf5';
                  e.target.style.color = '#2b2d42';
                  e.target.style.fontWeight = '500';
                  // e.target.style.marginLeft = '5px';
                  // e.target.style.marginRight = '5px';
                }
                }
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = '#ffffff';
                  e.target.style.color = '#5d737e';
                  e.target.style.fontWeight = 'unset';
                  // e.target.style.marginLeft = '0px';
                  // e.target.style.marginRight = '0px';
                }}
                key={key} onClick={() => this.handleActions(option)} msg={confirmMsg}>
                {labelText}
              </WithConfirmMenuItem>)
            }
            return (
              <MenuItem
                style={{
                  color: '#5d737e',
                  fontSize: '12px',
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = '#fdfaf5';
                  e.target.style.color = '#2b2d42';
                  e.target.style.fontWeight = '500';
                }
                }
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = '#ffffff';
                  e.target.style.color = '#5d737e';
                  e.target.style.fontWeight = 'unset';
                }}
                key={key} onClick={() => this.handleActions(option)}>
                {labelText}
              </MenuItem>
            )
          }
          )
        }
      </Popover >
    )
  }
}

const mapStateToProps = state => {
  const { menuListArticleId } = state.cms.articleSettings
  const { articlesByIds } = state.cms.articles;
  let scheduleStatus = null;
  try {
    const currentArticleDetail = articlesByIds[menuListArticleId];
    scheduleStatus = menuListArticleId && currentArticleDetail;
  }
  catch{

  }
  return {
    articleSettings: state.cms.articleSettings,
    scheduleStatus
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setArticleId: (articleId) => dispatch(setArticleId(articleId)),
    // setArticleIdPreivewList: (articleId) => dispatch(setArticleIdPreivewList(articleId)),
    // submitArticle: () => dispatch(submitArticle()),
    toggleArticleMsnGallery: (enable) => dispatch(toggleArticleMsnGallery(enable)),
    deleteArticle: () => dispatch(deleteArticle()),
    // approveArticle: () => dispatch(approveArticle()),
    sendFeedback: () => dispatch(sendFeedback()),
    // publishArticle: () => dispatch(publishArticle()),
    shareLiveArticle: (articleDetails) => dispatch(shareLiveArticle(articleDetails)),
    scheduleLiveArticle: (articleDetails) => dispatch(scheduleLiveArticle(articleDetails)),
    exploitLiveArticle: (articleDetails) => dispatch(exploitLiveArticle(articleDetails)),
    scheduleTwitterLiveArticle: (articleDetails) => dispatch(scheduleTwitterLiveArticle(articleDetails)),
    unpublishArticle: () => dispatch(unpublishArticle()),
    LockAndEditArticle: (forced) => dispatch(LockAndEditArticle(forced)),
    finishEditingArticle: () => dispatch(finishEditingArticle()),
    clearArticleCache: () => dispatch(clearArticleCache()),
    shareArticleDebug: () => dispatch(shareArticleDebug()),
    setFeedbackModal: (status) => dispatch(setFeedbackModal(status)),
    setDisplayPreviewAction: () => dispatch(setDisplayPreviewAction()),
    setArticleIdForPreviewArticle: (articleId, action) => dispatch(setArticleIdForPreviewArticle(articleId, action)),
    toggleAdsForArticle: (disable) => dispatch(toggleAdsForArticle(disable)),
    toggleConfirmationBox: (show, message, onConfirm) => dispatch(toggleConfirmationBox(show, message, onConfirm)),
    sendBrowserNotification: (articleId) => dispatch(sendBrowserNotification(articleId)),
    setSchedularModal: (status) => dispatch(setSchedularModal(status)),
    setExploiterModal: (status) => dispatch(setExploiterModal(status)),
    deleteScheduleArticle: () => dispatch(deleteScheduleArticle()),
    deleteScheduleAsExploitArticle: () => dispatch(deleteScheduleAsExploitArticle()),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(articlesStyle)(MenuDropDown)));
