const NewsRoomAction = {
  LOAD: {
    REQUEST: "NewsRoom.Load.Request",
    SUCCESS: "NewsRoom.Load.Success",
    FAILURE: "NewsRoom.Load.Failure"
  },
  SETTING: {
    SETID: 'Set.CardId',
    RESETPAGE: 'Set.ResetPage'
  },
  CATEGORIES: {
    SUCCESS: "NewsRoom.Categories.Load.Success"
  },
  CATEGORY: {
    SETACTIVE: "Set.ActiveCategorie",
    REMOVE: "Remove.ActiveCategorie"
  },
  NEWSROOMMODAL: {
    REQUEST: "NewsRoom.RequestModal",
    OPEN: "NewsRoom.OpenModal",
    CLOSE: "NewsRoom.CloseModal",
    REMOVE: "NewsRoom.RemoveModal"
  },
  SET: {
    PAGE_NUMBER: "NewsRoom.SetPageNumber"
  },
  TOKEN: {
    SAVE: "NewsRoom.SaveToken"
  },
  DRAFT: {
    PROGRESS: "NewsRoom.Draft.Progress",
    SUCCESS: "NewsRoom.Draft.Success",
    FAILURE: "NewsRoom.Draft.Failure",
    TOGGLEMODAL: "NewsRoom.Draft.ToggleModal",
    ADDDATA: "NewsRoom.Draft.AddData"
  },
  SEARCH: {
    START: "NewsRoom.Search.Start",
    SUCCESS: "NewsRoom.Search.Success",
    FAILURE: "NewsRoom.Search.Failure"
  },
  UNMOUNT: {
    SETTING: {
      SUCCESS: "NewsRoom.Unmount.Setting.Success"
    },
    REDUCER: {
      SUCCESS: "NewsRoom.Unmount.Reducer.Success"
    }
  },
  REFRESH: "NewsRoom.Refresh"
};

export default NewsRoomAction;
