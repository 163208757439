import React, { Component } from "react";
import UpdateUserProfile from "./view/update-profile.jsx";
import AppLoader from "../app-components/app-loader.jsx";
import { Helmet } from "react-helmet";

class EditUserProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdatedProfileDetails: true
    }
    this.loadProfileDetails = this.loadProfileDetails.bind(this);
  }
  loadProfileDetails(status = true) {
    this.setState({ isUpdatedProfileDetails: status });
  }
  render() {
    return (
      <div className="">
        <Helmet title="Edit User Profile | PubNinja - You Publish. The rest we manage." />
        {this.state.isUpdatedProfileDetails ? <UpdateUserProfile /> : <AppLoader message="Updating User Details..." />}
      </div>
    )
  }
}



export default EditUserProfilePage;
