import { RevenueAction, VariableTimeSpecificRevenueTypes, UserAction } from "../../app-constants/index.js";
import { combineReducers } from "redux";
import moment from "moment";


const fixedTimeSpecificRevenuesDefaultState = {
  isLoadingRevenues: false,
  fixedTimeSpecificRevenues: {},
}
const fixedTimeSpecificRevenues = (state = fixedTimeSpecificRevenuesDefaultState, action) => {
  switch (action.type) {
    //resetting
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return fixedTimeSpecificRevenuesDefaultState;
    }

    case RevenueAction.LOAD.FIXED_TIME_SPECIFIC_REVENUES.REQUEST: {
      return {
        ...state,
        isLoadingRevenues: true
      }
    }
    case RevenueAction.LOAD.FIXED_TIME_SPECIFIC_REVENUES.FAILURE: {
      return {
        ...state,
        fixedTimeSpecificRevenues: {},
        isLoadingRevenues: false
      }
    }
    case RevenueAction.LOAD.FIXED_TIME_SPECIFIC_REVENUES.SUCCESS: {
      const { revenues } = action.data;
      return {
        ...state,
        fixedTimeSpecificRevenues: revenues,
        isLoadingRevenues: false
      }
    }
    default: return state;
  }
}

const variableTimeSpecificRevenuesDefaultValue = {
  revenues: [],
}

const variableTimeSpecificRevenueWithNamedType = (variableTimeRevenueType) => (state = variableTimeSpecificRevenuesDefaultValue, action) => {

  //resetting
  if (action.type === UserAction.SET.ACTIVE_DOMAIN
    || action.type === UserAction.SIGN_OUT.SUCCESS) {
    return variableTimeSpecificRevenuesDefaultValue;
  }

  const { revenueType } = action;
  if (variableTimeRevenueType !== revenueType) {
    return state;
  }
  switch (action.type) {


    case RevenueAction.LOAD.VARIABLE_TIME_SPECIFIC_REVENUES.REQUEST: {
      return {
        ...state,
        revenues: []
      }
    }
    case RevenueAction.LOAD.VARIABLE_TIME_SPECIFIC_REVENUES.FAILURE: {
      return {
        ...state,
        revenues: [],
      }
    }
    case RevenueAction.LOAD.VARIABLE_TIME_SPECIFIC_REVENUES.SUCCESS: {
      const { revenues } = action.data;
      return {
        ...state,
        revenues: revenues,
      }
    }
    default: return state;
  }
}

const variableSettingsDefaultValues = {
  isLoadingRevenues: false,
  endDate: moment(),
  startDate: moment().subtract(7, "days")
}

const variableRevenuesSettings = (state = variableSettingsDefaultValues, action) => {
  switch (action.type) {
    //resetting
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return variableSettingsDefaultValues;
    }

    case RevenueAction.LOAD.VARIABLE_TIME_SPECIFIC_REVENUES.SUCCESS: {
      return {
        ...state,
        isLoadingRevenues: false
      }
    }

    case RevenueAction.LOAD.VARIABLE_TIME_SPECIFIC_REVENUES.REQUEST: {
      return {
        ...state,
        isLoadingRevenues: true
      }
    }

    case RevenueAction.LOAD.VARIABLE_TIME_SPECIFIC_REVENUES.FAILURE: {
      return {
        ...state,
        isLoadingRevenues: false
      }
    }

    case RevenueAction.SET.VARIABLE_TIME_SPECIFIC_REVENUES.DATE_RANGE: {
      const { startDate, endDate } = action;
      return {
        ...state,
        startDate: startDate,
        endDate: endDate
      }
    }

    default: return state;
  }
}

const variableTimeSpecificRevenues = combineReducers({
  variableRevenuesSettings,
  dailyECPMRevenues: variableTimeSpecificRevenueWithNamedType(VariableTimeSpecificRevenueTypes.DAILY_ECPM),
  dailyRevenues: variableTimeSpecificRevenueWithNamedType(VariableTimeSpecificRevenueTypes.DAILY_REVENUE),
  revenueStats: variableTimeSpecificRevenueWithNamedType(VariableTimeSpecificRevenueTypes.REVENUE_STATS)
});

const revenue = combineReducers({
  fixedTimeSpecificRevenues: fixedTimeSpecificRevenues,
  variableTimeSpecificRevenues: variableTimeSpecificRevenues
});


export default revenue;
