//pages
import React from 'react';
import Submenu from "./Submenu.jsx";

var subRoutes = [
  {
    collapse: true,
    state: "submenu",
    path: "/submenu/share",
    name: "Share",
    component: Submenu,
    requiredPermissions: [],
    views: []
  },
  {
    collapse: true,
    state: "submenu",
    path: "/submenu/earn",
    name: "Earn",
    component: Submenu,
    requiredPermissions: [],
    views: []
  },
  {
    collapse: true,
    state: "submenu",
    path: "/submenu/settings",
    name: "Settings",
    component: Submenu,
    requiredPermissions: [],
    views: []
  },
  {
    collapse: true,
    state: "submenu",
    path: "/submenu/tools",
    name: "tools",
    component: Submenu,
    requiredPermissions: [],
    views: []
  },
  {
    collapse: true,
    state: "submenu",
    path: "/submenu/content",
    name: "content",
    component: Submenu,
    requiredPermissions: [],
    views: []
  },
  {
    collapse: true,
    state: "submenu",
    path: "/submenu/admin",
    name: "admin",
    component: Submenu,
    requiredPermissions: [],
    views: []
  }

];


export default subRoutes;
