import React from 'react';

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "@material-ui/core/Button";

function DisclaimerCard(props){
  const {disclaimer} = props;
  return (
    <Card>
      <CardHeader style={{fontWeight: 'bold', fontSize: '1.2em'}}>
        {disclaimer.title}
      </CardHeader>
      <CardBody>
        {disclaimer.description}
      </CardBody>
      <CardFooter>
        <Button
          onClick={()=>{props.setDisclaimerModalData(disclaimer)}}
          style={{color:"#fb4d24"}}
        >
          Edit
        </Button>
      </CardFooter>
    </Card>
  );
}

export default DisclaimerCard;
