
// articles style
const articlesStyle = {
  paper: {
    marginRight: "130px"
  },
  articleContainer: {
    marginBottom: "20px",
    // boxShadow: "0 1px 8px 0 rgba(0, 0, 0, 0.06)",
    background: "#ffffff",
    border: 'solid 1px #ced3dc'

  },
  expansionContainer: {
    marginBottom: "20px",
    border: 'solid 1px #ced3dc',
    background: "#ffffff",
    width:"100%",
  },
  articleLetter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  articleImgContainer: {
    padding: '0px !important'
  },
  articleLetterWrapper: {
    //width: '252px',
    //height: '143px'
  },
  articleLetterStyle: {
    width: "42px",
    height: "42px",
    backgroundColor: "#d3dff7",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  articleCenter: {
    display: "flex",
    alignItems: "center"
  },
  letterColor: {
    color: "#2865da"
  },
  articleTitle: {
    fontFamily: 'Source Sans Pro, sans-serif',
    fontWeight: "600",
    color: '#2b2d42',
    fontSize: "1.1em",
    // paddingTop: "15px",
    // paddingBottom: "15px",
    padding:"15px 0"
  },
  articleEnd: {
    display: "flex",
    justifyContent: "flex-end"
  }
  ,
  buttonMobile: {
    "@media (max-width: 960px)": {
      paddingRight: '0px !important'
    }
  },
  menuHover: {
    padding: '5px',
    backgroundColor: '#fdfaf5 !important',
    color:'black'
  }
}
export default articlesStyle;
