import React from 'react';
import arrowSvg from "../../../assets/icons/arrow.svg";

function Post1(props) {
  const { storyData: { coverImage, title, description, action }, style, isStory } = props;

  const trimmedTitle = title.length > 118 ? title.substring(0,118) + '...' : title;
  const trimmedDescription = description.length > 118 ? description.substring(0,118) + '...': description;

  return (
    <div style={style}>
      <div>
        <img
          src={coverImage}
          alt="thumbnail"
          style={style.coverImage} />
      </div>
      <div style={style.body}>
        <div style={style.body.title}>
          {trimmedTitle}
        </div>
        <div style={style.body.description}>
          {trimmedDescription}
        </div>
        {isStory && <><div style={style.footer}>
        </div>
          <div style={style.swipeUpButtonWrapper}>
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '-10px' }}>
              <img src={arrowSvg} />
              <img src={arrowSvg} style={{ marginTop: '-31px', opacity: '0.5' }} />
            </div>
            <div style={style.swipeUpButton}>{action}</div>
          </div>
        </>
        }
      </div>
    </div>
  );
}

export default Post1;
