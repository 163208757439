import React,  { PureComponent, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, Typography, IconButton } from "@material-ui/core";
import FileCopy from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';

const CustomDialogTitle = withStyles(theme => ({
  root: {
    // borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, toggleShareArticleModal } = props;
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="subheading">
        <div style={{ display: "flex", justifyContent: "center", fontWeight: "bold" }}>
          {children}
        </div>
      </Typography>
      <IconButton aria-label="Close" className={classes.closeButton} onClick={() => {
        toggleShareArticleModal(false);
      }}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
});

class ShareLiveArticleModal extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      showToolTip:false,
    }
  }

  copyToClipboard = (shareArticleData) => {
    navigator.clipboard.writeText(shareArticleData && shareArticleData.url || "url not present.")
      .then(() => {
        this.setState({ showToolTip: true });
        setTimeout(() => {
          this.setState({ showToolTip: false });
        }, 1500);
    })
    .catch(err => {
      console.error('Could not copy text: ', err);
    });
  };
  render() {
    const {
      showShareArticleModal,
      toggleShareArticleModal,
      shareArticleData,
    } = this.props;
    return (
      <Dialog
        id="shareContainer"
        open={showShareArticleModal}
        //fullWidth={true}
        onClose={this.hide}
        fullWidth
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"> 
        <CustomDialogTitle toggleShareArticleModal={toggleShareArticleModal}>Share Article</CustomDialogTitle>
        <DialogContent style={{display:"flex"}}>
          <TextField
            disabled
            style={{width:'100%'}}
            id=""
            defaultValue={shareArticleData && shareArticleData.url || ""}
            type="url"
            margin="normal"
            variant="filled"
          />
          <Tooltip
            title="copied !"
            open = {this.state.showToolTip}
          >
            <div style={{ alignSelf: "center", paddingTop: "16px", paddingLeft: "16px", cursor: "pointer" }} onClick={this.copyToClipboard.bind(this, shareArticleData)}>
            <FileCopy></FileCopy>
          </div>
          </Tooltip>
        </DialogContent>
      </Dialog>
    )
  }
}

const styles = {
  textField: {
   width:'100%'
  }
};

export default ShareLiveArticleModal;

