import React, { PureComponent } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";

class CategorySelect extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      categoryValue: ""
    };
  }
  componentWillReceiveProps(props) {
    this.setState({ ...this.state, categoryValue: props.categoryValue });
    //console.log(`cat ${props.categoryValue}`)
    if (this.props.categoryValue !== props.categoryValue) this.props.loadNewsRoomArticles();
  }
  handleChange = e => {
    this.setState({ ...this.state, categoryValue: e.target.value.name });
    if(e.target.value.name !== this.state.categoryValue)
      this.props.activeCategorie(e.target.value.id, e.target.value.name);
  };
  render() {
    const { categories, classes } = this.props;
    return (
      <div
        style={{ width: "300px", paddingBottom: "20px" }}
      >
        <FormControl fullWidth className={classes.selectLabel}>
          <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
            Category
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={`${this.state.categoryValue}`}
            renderValue={() => `${this.state.categoryValue}`}
            onChange={this.handleChange}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select"
            }}
            // style = {{textAlign: 'left', paddingLeft: '10px'}}
          >
            {categories.map((category, id) => (
              <MenuItem key={id} value={category}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
}

export default withStyles(customSelectStyle)(CategorySelect);
