
import React, { PureComponent } from "react"
import throttle from "lodash/throttle";
import TextField from '@material-ui/core/TextField';

class ArticlePostCaption extends PureComponent {
  constructor(props) {
    super(props);
    this.setChangeValue = throttle(this._setChangeValue, 100);
    this.textArea = React.createRef();
    this.state = { length: 0, postCaption: props.postCaption || "" };
  }

  componentDidMount() {
    const { defaultValue } = this.props;
    let length = 0;
    if (defaultValue) {
      length = defaultValue.length;
    }
    this.setState({ length });
  }
  _setChangeValue = (e) => {
    this.props.setPostCaption(e.target.value, e.type);
  }
  onChange = (e) => {
    e.persist();
    this.setState({ length: e.target.value.length, postCaption: e.target.value });
    this.setChangeValue(e);
  }
  render() {
    const { postCaption } = this.state;

    return (
      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <TextField
          id="standard-name"
          label="ENTER POST CAPTION"
          value={postCaption}
          onChange={this.onChange}
          onBlur={this.onChange}
          InputProps={{
            style: {
              border: '1px solid #ced3dc',
              width:'99%'
              // lineHeight: "1.2",
              // fontSize: "24px",
              // fontWeight: "bold",
              // width: "100%",

            },
          }}
          inputProps={{ maxLength: 150 }}

          multiline={true}
          fullWidth
          margin="normal"
        />
        <p>{`${postCaption && postCaption.length || 0}/150`}</p>
      </div>
    )
  }
}

export default ArticlePostCaption;
