import React, { PureComponent } from "react"
import throttle from "lodash/throttle";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  textField: {
    // marginLeft: theme.spacing.unit,
    // marginRight: theme.spacing.unit,
    // width: 200,
    '&:before': {
      border:'unset'
    }
  },
});
class ArticleCoverCaption extends PureComponent {
  constructor(props) {
    super(props);
    this.setChangeValue = throttle(this._setChangeValue, 100);
    this.textArea = React.createRef();
    this.state = { length: 0, summary: props.piscoSummary || props.summary || "" };
  }

  componentDidMount() {
    const { defaultValue } = this.props;
    let length = 0;
    if (defaultValue) {
      length = defaultValue.length;
    }
    this.setState({ length });
  }
  componentWillReceiveProps(nextProps) {
    const { piscoSummary } = nextProps;
    if (piscoSummary && piscoSummary != '') {
      this.setState({ summary: piscoSummary });
    }
  }
  _setChangeValue = (e) => {
    this.props.setArticleCoverCaption(e.target.value, e.type);
  }
  onChange = (e) => {
    e.persist();
    this.setState({ length: e.target.value.length, summary: e.target.value });
    this.setChangeValue(e);
  }
  render() {
    const { summary } = this.state;
    const { classes } = this.props;

    return (
      <div className="labelPadding" style={{ display: "flex", alignItems: "flex-end" }}>
        <TextField
          id="standard-name"
          label="COVER PICTURE CAPTION"
          value={summary}
          onChange={this.onChange}
          onBlur={this.onChange}
          className={classes.textField}
          InputProps={{
            disableUnderline: true,
            endAdornment: <InputAdornment position="start" style={{whiteSpace:"nowrap"}}>{`${summary && summary.length || 0} words`}</InputAdornment>,
            style: {
              border:'none',
              lineHeight: "1.5",
              fontSize: "16px",
              fontWeight: "normal",
              border: '1px solid #ced3dc',
              padding: '5px 10px',
            },
          }}
          inputProps={{maxLength: 300,border:'unset' }}

          multiline={true}
          fullWidth
          margin="normal"
        />
      </div>
    )
  }
}

export default withStyles(styles)(ArticleCoverCaption);
