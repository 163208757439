import React, { PureComponent } from 'react';
import { DateRange } from 'react-date-range';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import moment from 'moment';

function formatDateDisplay(startDate, endDate, defaultText) {
  let date = "";
  if (!startDate) {
    date = defaultText;
  } else {
    date = startDate.format("Do[, ]MMM[ -- ]")
  }
  if (!endDate) {
    date += defaultText;
  } else {
    date += endDate.format("Do[, ]MMM")
  }
  return date;
}

class DateFilter extends PureComponent {
  state = {
    formatDate: '',
    select: {
      endDate: null,
      startDate: null,
      key: 'selection',
    }
  }

  reSetDate = () => {
    this.setState({
      ...this.state,
      ["select"]: {
        endDate: null,
        startDate: null,
        key: 'selection',
      },
      ['formatDate']: ''
    }, () => {
      this.props.getAllDomainCampaigns()
    });
  }
  handleSelect = (range) => {
    const formatDate = formatDateDisplay(moment(range.selection.startDate), moment(range.selection.endDate));
    this.setState({ ...this.state, ["select"]: range.selection, formatDate }, () => {
      const { startDate, endDate } = this.state.select;
      this.props.getAllDomainCampaignsDate(moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"));
    });
  }
  render() {
    return (
      <div style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: '10px'
      }}>
        <FormControl style={{ width: '100%' }}>
          <InputLabel htmlFor="demo-controlled-open-select">Filter By Date</InputLabel>
          <Select
            value={`${this.state.formatDate}`}
            renderValue={() => `${this.state.formatDate}`}
          >
            <DateRange
              ranges={[this.state.select]}
              onChange={this.handleSelect}
              maxDate={moment().toDate()}
              minDate={moment("20180401", "YYYYMMDD").toDate()}
            />
          </Select>
        </FormControl>
        <div style={{ alignSelf: 'flex-end', marginLeft: '10px' }}>
          <i className="material-icons" style={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => { this.reSetDate() }}>
            clear
        </i>
        </div>
      </div >

    )
  }
}

export default DateFilter;
