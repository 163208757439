
//  feed reducer 

import { combineReducers } from "redux";
import {
  FeedsAction, UserAction
} from "../../app-constants/index.js";


// load Feed
function loadNewFeed(state, action, feedType, loadingFeed) {
  const { highlight = {}, unheiglight = {} } = action;
  const highlightTwiiterFeed = {};
  const unheiglightTwitterFeed = {};
  const highlightnewFeed = { ...state.highlight };
  const unheiglightnewFeed = { ...state.unheiglight };
  highlight.forEach(feed => {
    highlightTwiiterFeed[feedType === "youtube" ?
      feed.videoId :
      feed.idStr] = { ...feed, ...highlightnewFeed[feedType === "youtube" ? feed.videoId : feed.idStr] }
  });
  unheiglight.forEach(feed => {
    unheiglightTwitterFeed[feedType === "youtube" ?
      feed.videoId : feed.idStr] = { ...feed, ...unheiglightnewFeed[feedType === "youtube" ? feed.videoId : feed.idStr] }
  });

  return {
    "unheiglight": { ...unheiglightTwitterFeed, ...unheiglightnewFeed },
    "highlight": { ...highlightTwiiterFeed, ...highlightnewFeed },
    ...loadingFeed
  }
}
// Move feed from unhighlight to highlight
function moveFeed(state, action) {
  const { unheiglight = {}, highlight = {} } = state;
  const { id } = action;
  const tempFeed = {}
  tempFeed[id] = unheiglight[id]
  delete unheiglight[id]
  return {
    "unheiglight": unheiglight,
    "highlight": { ...tempFeed, ...highlight }
  }
}

function moveUnhighlightFeed(state, action) {
  const { unheiglight = {}, highlight = {} } = state;
  const { id } = action;
  delete highlight[id]
  return {
    "unheiglight": unheiglight,
    "highlight": highlight
  }
}
// twitter Feed
const defaultTwitterFeeds = {};
const twitterFeeds = (state = defaultTwitterFeeds, action) => {
  const twitterLoader = { isLoadingTwitterFeed: true }
  const twitterLoaderSuccess = { isLoadingTwitterFeed: false }
  switch (action.type) {
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return defaultTwitterFeeds
    }
    case FeedsAction.LOAD.FEED.TWITTER.REQUEST: {
      return { ...state, ...twitterLoader }
    }
    case FeedsAction.LOAD.FEED.TWITTER.SUCCESS: {
      return loadNewFeed(state, action, null, twitterLoaderSuccess)
    }
    case FeedsAction.MOVE.FEED.TWITTER: {
      return moveFeed(state, action)
    }
    case FeedsAction.MOVE.UNHIGHLIGHT.TWITTER: {
      return moveUnhighlightFeed(state, action)
    }
    case FeedsAction.RESET.FEED: {
      return {}
    }
    default: return state
  }
}

// insta Feed
const defaultInstaFeeds = {};
const instaFeeds = (state = defaultInstaFeeds, action) => {
  const instaLoader = { isLoadingInstaFeed: true }
  const instaLoaderSuccess = { isLoadingInstaFeed: false }
  switch (action.type) {
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return defaultInstaFeeds
    }
    case FeedsAction.LOAD.FEED.INSTA.REQUEST: {
      return { ...state, ...instaLoader }
    }
    case FeedsAction.LOAD.FEED.INSTA.SUCCESS: {
      return loadNewFeed(state, action, null, instaLoaderSuccess)
    }
    case FeedsAction.MOVE.FEED.INSTA: {
      return moveFeed(state, action)
    }
    case FeedsAction.MOVE.UNHIGHLIGHT.INSTA: {
      return moveUnhighlightFeed(state, action)
    }
    case FeedsAction.RESET.FEED: {
      return {}
    }
    default: return state
  }
}

// Youtube Feed
const defaultYoutubeFeeds = {};
const youtubeFeeds = (state = defaultYoutubeFeeds, action) => {
  const youtubeLoader = { isLoadingYouTubeFeed: true }
  const youtubeLoaderSuccess = { isLoadingYouTubeFeed: false }
  switch (action.type) {
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return defaultYoutubeFeeds
    }
    case FeedsAction.LOAD.FEED.YOUTUBE.REQUEST: {
      return { ...state, ...youtubeLoader }
    }
    case FeedsAction.LOAD.FEED.YOUTUBE.SUCCESS: {
      return loadNewFeed(state, action, "youtube", youtubeLoaderSuccess)
    }
    case FeedsAction.MOVE.FEED.YOUTUBE: {
      return moveFeed(state, action, "youtube")
    }
    case FeedsAction.MOVE.UNHIGHLIGHT.YOUTUBE: {
      return moveUnhighlightFeed(state, action)
    }
    case FeedsAction.RESET.FEED: {
      return {}
    }
    default: return state
  }
}

const defaultFeedChannels = {
  loadingChannel: false,
  addingChannel: {
    instagram: false,
    youtube: false,
    twitter: false,
  },
  instagram: [],
  youtube: [],
  twitter: [],
};

const feedChannels = (state = defaultFeedChannels, action) => {
  switch (action.type) {
    case FeedsAction.CHANNELS.REQUEST: {
      return { ...state, loadingChannel: true };
    }
    case FeedsAction.CHANNELS.SUCCESS: {
      const { provider, channels } = action;
      let channelsList = {};
      channelsList[provider] = channels;
      return { ...state, loadingChannel: false, ...channelsList };
    }
    case FeedsAction.CHANNELS.FAILURE: {
      return { ...state, loadingChannel: false, };
    }
    case FeedsAction.CHANNELS.ADDING: {
      const { provider, adding } = action;
      const { addingChannel } = state;
      addingChannel[provider] = adding;
      state.addingChannel = addingChannel;
      return { ...state };
    }
    case FeedsAction.CHANNELS.ADD: {
      const { channel } = action;
      state[channel.source].push(channel);
      return { ...state };
    }
    case FeedsAction.CHANNELS.DELETE: {
      const { source } = action.channel;
      let channelsList = {};
      channelsList[source] = state[source].filter((item, index) => action.channel.property != item.property);
      return { ...state, ...channelsList };
    }
    default: return state
  }
}

const feeds = combineReducers({
  twitterFeeds,
  instaFeeds,
  youtubeFeeds,
  feedChannels
});

export default feeds;
