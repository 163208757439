import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink } from "react-router-dom";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import { PUBNINJA_FAVICON, HOME_LINK } from "../../app-constants/image-url";
import AppLoader from "../../app-components/app-loader.jsx";

// core components
import HeaderLinks from "components/Header/HeaderLinks.jsx";

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";
import DomianList from "../Header/DomainList.jsx";
// import DomainList from "../header/DomainList.jsx";

var ps;
function hasPermission(requiredPermissions, userPermissions, completePermissions) {
  let userCompletePermissions = 0;
  if (completePermissions) {
    for (let i = 0; userPermissions && (i < userPermissions.length); i++) {
      if (completePermissions.indexOf(userPermissions[i]) > -1) {
        userCompletePermissions++;
      }
    }
    if (userCompletePermissions !== 2)
      return false;
  }
  if (!requiredPermissions || requiredPermissions.length === 0) {
    return true;
  }
  for (let i = 0; userPermissions && (i < userPermissions.length); i++) {
    if (requiredPermissions.indexOf(userPermissions[i]) > -1) {
      return true;
    }
  }
  return false;
}

function hasFeaturePermision(requiredFeaturePermision, featurePermision = []) {
  if (!requiredFeaturePermision || requiredFeaturePermision.length === 0) {
    return true
  }
  if (featurePermision.includes(requiredFeaturePermision)) {
    return true
  }
  return false
}

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      openComponents: this.activeRoute("/components"),
      openForms: this.activeRoute("/forms"),
      openTables: this.activeRoute("/tables"),
      openMaps: this.activeRoute("/maps"),
      openPages: this.activeRoute("-page"),
      openCms: this.activeRoute("-cms"),
      userManagement: this.activeRoute("-userManagement"),
      openAnalytics: this.activeRoute("analytics"),
      openScheduler: this.activeRoute("scheduler"),
      openAdmin: this.activeRoute('/admin'),
      openTools: this.activeRoute('/tools'),
      miniActive: true
    };
    this.activeRoute.bind(this);
  }
  signOutUser = (e) => {
    const { signOutUser } = this.props;
    e.preventDefault()
    signOutUser()
      .then((data) => {
        localStorage.removeItem('firstName');
        localStorage.removeItem('lastName');
        localStorage.removeItem('profilePicture');
        this.props.history.push("/login-home/login");
      }, (err) => {
        this.props.history.push("/login-home/login");
      });
  }
  showParentBar(prop, featurePermision, userDetails) {
    const result = prop.views.map((prop) => {
      if (!hasFeaturePermision(prop.requiredFeaturePermision, featurePermision)) {
        return null
      }
      if (prop.dontShowForTrail && featurePermision.indexOf('TRIAL') > -1) {
        return null;
      }
      if (prop.dontShowInSideBar) {
        return null
      }
      if (prop.redirect) {
        return null;
      }
      if (!hasPermission(prop.requiredPermissions, userDetails.permissions, prop.completePermissions)) {
        return null;
      }
    })
    if (result.filter((n) => n !== null).length == 0) {
      return false
    }
    return true
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  getFirstLastLetter = () => {
    let { user: { firstName, lastName } = {} } = this.props;
    if (!firstName) {
      firstName = localStorage.getItem('firstName') || '';
    }
    if (!lastName) {
      lastName = localStorage.getItem('lastName') || '';
    }
    return firstName.charAt(0) + lastName.charAt(0)
  }
  getName = () => {

    let { user: { firstName = localStorage.getItem("firstName"), lastName = localStorage.getItem("lastName") } = {} } = this.props;
    if (!firstName) firstName = "Your Profile";
    return `${firstName} ${lastName}`;
  };

  render() {
    const {
      classes,
      color,
      logo,
      image,
      routes,
      bgColor,
      rtlActive,
      isDoingSignout,
      user: userDetails = {},
      featurePermision,
    } = this.props;
    let { profilePicture = localStorage.getItem('profilePicture') } = userDetails;
    profilePicture = profilePicture === 'null' ? null : profilePicture;
    const itemText =
      classes.itemText +
      " " +
      cx({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
        [classes.itemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.itemTextRTL]: rtlActive
      });
    const collapseItemText =
      classes.collapseItemText +
      " " +
      cx({
        [classes.collapseItemTextMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextRTL]: rtlActive
      });
    const userWrapperClass =
      classes.user +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white"
      });
    const caret =
      classes.caret +
      " " +
      cx({
        [classes.caretRTL]: rtlActive
      });
    const collapseItemMini =
      classes.collapseItemMini +
      " " +
      cx({
        [classes.collapseItemMiniRTL]: rtlActive
      });
    const photo =
      classes.photo +
      " " +
      cx({
        [classes.photoRTL]: rtlActive
      });

    var links = (
      <div className={classes.list + " navbar-container"}>
        {routes.map((prop, key) => {
          if (!hasFeaturePermision(prop.requiredFeaturePermision, featurePermision)) {
            return null
          }
          if (prop.dontShowInSideBar) {
            return null
          }
          if (prop && prop.dontShowForTrail && (featurePermision.indexOf('TRIAL') > -1)) {
            return null;
          }
          if (prop && prop.onlyQuickCms && (featurePermision.indexOf('TRIAL') > -1)) {
            return null;
          }
          if (prop.redirect) {
            return null;
          }
          if (!hasPermission(prop.requiredPermissions, userDetails.permissions, prop.completePermissions)) {
            return null;
          }
          if (prop.collapse) {
            const navLinkClasses =
              classes.itemLink +
              " " +
              cx({
                [" " + classes.collapseActive]: this.activeRoute(prop.path)
              });
            const itemText =
              classes.itemText +
              " " +
              cx({
                [classes.itemTextMini]:
                  this.props.miniActive && this.state.miniActive,
                [classes.itemTextMiniRTL]:
                  rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.itemTextRTL]: rtlActive
              });
            const collapseItemText =
              classes.collapseItemText +
              " " +
              cx({
                [classes.collapseItemTextMini]:
                  this.props.miniActive && this.state.miniActive,
                [classes.collapseItemTextMiniRTL]:
                  rtlActive && this.props.miniActive && this.state.miniActive,
                [classes.collapseItemTextRTL]: rtlActive
              });
            const itemIcon =
              classes.itemIcon +
              " " +
              cx({
                [classes.itemIconRTL]: rtlActive
              });
            const caret =
              classes.caret +
              " " +
              cx({
                [classes.caretRTL]: rtlActive
              });
            if (!this.showParentBar(prop, featurePermision, userDetails)) {
              return null
            }
            return (
              <div key={key} className={classes.item + " navbar-list"}>
                <NavLink
                  to={{ pathname: `/submenu/${prop.name.toLowerCase()}`, handler: { views: prop.views, props: this.props  } }}
                  className={navLinkClasses + " navbar-list-item"}
                  onClick={() => this.openCollapse(prop.state)}
                  id={prop.name.toLowerCase().replace(/\s/g, '')}
                  views={prop.views}
                >
                  <ListItemIcon className={itemIcon}>
                    {typeof prop.icon === "string" ? (
                      <Icon>{prop.icon}</Icon>
                    ) : (
                        <prop.icon />
                      )}
                  </ListItemIcon>
                  <ListItemText

                    primary={prop.name}
                    disableTypography={true}
                    className={itemText + " navbar-item-text"}
                  />
                </NavLink>
              </div>
            );
          }
          const navLinkClasses =
            classes.itemLink +
            " " +
            cx({
              [" " + classes[color] + " " + classes.tabLogo]: this.activeRoute(prop.path)
            });
          const itemText =
            classes.itemText +
            " " +
            cx({
              [classes.itemTextMini]:
                this.props.miniActive && this.state.miniActive,
              [classes.itemTextMiniRTL]:
                rtlActive && this.props.miniActive && this.state.miniActive,
              [classes.itemTextRTL]: rtlActive
            });
          const itemIcon =
            classes.itemIcon +
            " " +
            cx({
              [classes.itemIconRTL]: rtlActive
            });
          return (
            <div key={key} className={classes.item + " navbar-list-item"}>
              <NavLink to={prop.path} className={navLinkClasses + " navbar-list-item"} id={prop.name.toLowerCase().replace(/\s/g, '')}>
                <ListItemIcon className={itemIcon}>
                  {typeof prop.icon === "string" ? (
                    <Icon>{prop.icon}</Icon>
                  ) : (
                      <prop.icon />
                    )}
                </ListItemIcon>
                <ListItemText
                  primary={prop.name}
                  disableTypography={true}
                  className={itemText + " navbar-item-text"}
                />
              </NavLink>
            </div>
          );
        })}
      </div>
    );
    
    return (
      <div>
          {links}
      </div>
    );
  }
}

Navbar.defaultProps = {
  bgColor: "blue"
};

Navbar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose"
  ]),
  logo: PropTypes.string,
  image: PropTypes.string,
  user: PropTypes.object,
  isDoingSignout: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object)
};

export default withStyles(sidebarStyle)(Navbar);
