import React, { PureComponent } from "react";
import LandingPage from "./views/landing-page.jsx";

class LandingHomePage extends PureComponent {

    render() {
        return (<LandingPage />)
    }
}

export default LandingHomePage;
