import React from "react";
import Facebook from "../fb/facebook";
import Twitter from "../twitter/twitter";

class Scheduler extends React.PureComponent {

  render () {
    let content = this.props.location.pathname === "/schedule/twitter" ?
      (
        <Twitter/>
      ) : (
        <Facebook/>
      );
    return (
      content
    );
  }
}

export default Scheduler;
