
import { MessageTypes, NewsLetter } from "../../app-constants";
import { MeawwApi } from "../../utils/api.js";
import { setAppMessage } from "../../app-actions.js";

function newsLetterSuccess(data) {
  return {
    type: NewsLetter.GET.SUCCESS,
    data
  }
}
function newsLetterRequest() {
  return {
    type: NewsLetter.GET.REQUEST,
  }
}
function newsLetterFailure() {
  return {
    type: NewsLetter.GET.FAILURE,
  }
}

function groupByDateNewsLetter(newsLetterList = []) {
  return newsLetterList.reduce((a, b) => {
    a[b.date] = Object.assign(a[b.date] || {}, Object.create({}, {
      [b.id]: {
        writable: true,
        configurable: true,
        enumerable: true,
        value: b
      }
    }))
    return a
  }, {})
}

export default function getNewsletter(isOnBoard = false, domainId) {
  
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    dispatch(newsLetterRequest());
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/newsletter/${activeDomain || domainId}`,
          (error, data) => {
            if (error) {
              dispatch(newsLetterFailure());
              !isOnBoard && dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            dispatch(newsLetterSuccess(data));
            return Promise.resolve(data);
          });
      });
  }
}
export function postNewsletter(silent) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    const { website_visible } = getState().manageSocialReducer;
    dispatch({ type: NewsLetter.ACTION.REQUEST })
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/newsletter/${activeDomain}`,
          {
            enable: +!!!website_visible
          },
          (error, data) => {
            if (error) {
              !silent &&  dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              dispatch({ type: NewsLetter.ACTION.FAILURE })
              return Promise.reject(error);
            }
            dispatch(newsLetterSuccess(data));
            !silent && dispatch(setAppMessage("Updated !", {
              type: MessageTypes.SUCCESS
            }));
            dispatch({ type: NewsLetter.ACTION.SUCCESS })
            return Promise.resolve(data);
          });
      });
  }
}

export function exportNl() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/newsletter/${activeDomain}/export`,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            // dispatch(newsLetterSuccess(data));
            dispatch(setAppMessage("Export Successfully", { type: MessageTypes.SUCCESS }));
            return Promise.resolve(data);
          });
      });
  }
}
export function importNl(formData) {
  return (dispatch, getState) => {

    const { activeDomain } = getState().login.user;
    dispatch({ type: NewsLetter.ACTION.REQUEST })
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/newsletter/${activeDomain}/bulkSubscribe`,
          {
            isFormData: true,
            data: formData,
          },
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              dispatch({ type: NewsLetter.ACTION.FAILURE })
              return Promise.reject(error);
            }
            dispatch({ type: NewsLetter.ACTION.SUCCESS })
            dispatch(getNewsletter());
            dispatch(setAppMessage("Import Successfully", { type: MessageTypes.SUCCESS }));
            return Promise.resolve(data);
          });
      });
  }
}

export function getAllDomainCampaigns() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    dispatch({ type: NewsLetter.GET_CAMPAIGN.REQUEST })
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/newsletter/${activeDomain}/campaigns?orderBy=datecreated&order=desc`,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              dispatch({ type: NewsLetter.GET_CAMPAIGN.FAILURE })
              return Promise.reject(error);
            }
            dispatch({ type: NewsLetter.GET_CAMPAIGN.SUCCESS, data: groupByDateNewsLetter(data.result) })
            return Promise.resolve(data);
          });
      });
  }
}
export function getAllDomainCampaignsDate(startDate, endDate) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    dispatch({ type: NewsLetter.GET_CAMPAIGN.REQUEST })
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/newsletter/${activeDomain}/campaigns?startDate=${startDate}&endDate=${endDate}&orderBy=datecreated&order=desc`,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              dispatch({ type: NewsLetter.GET_CAMPAIGN.FAILURE })
              return Promise.reject(error);
            }
            dispatch({ type: NewsLetter.GET_CAMPAIGN.SUCCESS, data: groupByDateNewsLetter(data.result) })
            return Promise.resolve(data);
          });
      });
  }
}

export function createCampaign(data) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    dispatch({ type: NewsLetter.ACTION.REQUEST })
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/newsletter/${activeDomain}/campaigns/create`, {
          ...data
        },
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              dispatch({ type: NewsLetter.ACTION.FAILURE });
              // dispatch({ type: NewsLetter.GET_CAMPAIGN.FAILURE })
              return Promise.reject(error);
            }
            dispatch(setAppMessage(data.message, { type: MessageTypes.SUCCESS }));
            dispatch({ type: NewsLetter.ACTION.SUCCESS });
            // dispatch({ type: NewsLetter.GET_CAMPAIGN.SUCCESS, data })
            return Promise.resolve(data);
          });
      });
  }
}

export function getFullDetail(id) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    dispatch({ type: NewsLetter.FUll_DETAIL.REQUEST })
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/newsletter/${activeDomain}/campaigns?campaignId=${id}`,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              dispatch({ type: NewsLetter.FUll_DETAIL.FAILURE })
              return Promise.reject(error);
            }
            dispatch({ type: NewsLetter.FUll_DETAIL.SUCCESS, data: data })
            return Promise.resolve(data);
          });
      });
  }
}
export function showInfoMessage(msg) {
  return (dispatch, getState) => {
    dispatch(setAppMessage(msg, { type: MessageTypes.INFO }));
  }
}
