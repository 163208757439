import React, { PureComponent } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from '@material-ui/core/Tooltip';
import feedStyle from "../../../assets/jss/material-dashboard-pro-react/components/feedStyle.jsx";


class YoutubeCard extends PureComponent {
  state = {showiframeVideo:false}

  changeiframeState = () => {
    this.setState({ showiframeVideo: true });
  }

  handleMove() {
    const { moveFeed, idStr } = this.props;
    moveFeed(idStr, "youtubeFeeds", "highlight");
  }
  hidehighlight() {
    const { idStr, moveFeed } = this.props;
    moveFeed(idStr, "youtubeFeeds", "unhighlight");
  }

  render() {
    const { highlight, idStr, channel, title, classes} = this.props;

    return (
      <div style={{ overflowY: 'scroll', marginTop: '16px' }}>
        <Tooltip style={{fontSize:"20px"}} title={`Click to ${highlight ? 'unhighlight' : 'highlight'}`} placement="right">
        <i className={`material-icons ${classes.hightlight}`} style={{display: "block", width: '25px', cursor: 'pointer'}} onClick={_ => {
            highlight ? this.hidehighlight() : this.handleMove();
          }} >
        {highlight ? 'bookmark' : 'bookmark_border'}
        </i>
        </Tooltip>
        {this.state.showiframeVideo ? <IframeContainer idStr={idStr}/> : <IframeImg idStr={idStr} changeiframeState={this.changeiframeState}/>}
        <div>
          <h3>
            {channel}
          </h3>
          <div style={{ wordWrap: 'break-word', width: '250px' }}>
            {title}
          </div>
        </div>
      </div>
    )
  }
}
export default withStyles(feedStyle)(YoutubeCard);

const IframeContainer = (props) => {
  return(
    <iframe width='250'
      src = {`https://www.youtube-nocookie.com/embed/${props.idStr}?autoplay=1`}
      frameBorder = '0'
      allow = 'encrypted-media'
      allowFullScreen
      allow ='autoplay'
    />
  );
}
const IframeImg = (props) => {
  return(
    <img src = {`https://i.ytimg.com/vi/${props.idStr}/mqdefault.jpg`}
      width = "250"
      style = {{cursor: 'pointer'}}
      onClick = {props.changeiframeState}
    />
  );
}
