import React, { PureComponent } from 'react';
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Email from "@material-ui/icons/Email";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import AppLoader from "../../app-components/app-loader.jsx";
import Icon from "@material-ui/core/Icon";

import { resetPassword,verifyEmail ,resetPasswordWithToken} from "../login-actions.js";
import { isValidEmailAddress } from "../../utils/utils.js";

class ForgotPasswordForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      cardAnimaton: "cardHidden",
      redirectToReferrer: false,
      showOTP :false,
      email: "",
      otp: "", 
      requestTokenVal :'',
      error: {
        emailError: "",
      },
      password: "",
      confirmPassword: "",
      error: {
        confirmPasswordError: "",
        passwordError: ""
      },
      confirmPasswordLoader :false,
    };
  }

  validateData = () => {
    const { email } = this.state;
    let isValid = true;
    if (!isValidEmailAddress(email)) {
      isValid = false
      this.setState({
        error: {
          ...this.state.error,
          emailError: "Please Enter Valid Email!"
        }
      })
    }
    return isValid;
  }

  resetPassword = () => {
    if (!this.validateData()) {
      return;
    }

    this.props.resetPassword(this.state.email)
      .then(e => {
        this.setState({
          showOTP :true
        })
      })
      .catch(e => {
        this.setState({
          showOTP :false
        })
      });
  };
  verifyOTP = () => {
    if (!this.state.otp) {
      return;
    }
    const body = {
      email: this.state.email,
      role: "creator",
      otp: this.state.otp
    }

    this.props.verifyEmail(body).then((data) => {
      const {requestToken} = data;
      this.setState({requestTokenVal:requestToken})
    })
    .catch(() => {
      this.setState({ requestTokenVal:''})
    })
  }

  setEmail = ({ target: { value } }) => {
    this.setState("email", value);
    if (!isValidEmailAddress(value)) {
      this.setState({
        error: {
          ...this.state.error,
          emailError: "Please Enter Valid Email!"
        }
      })
    } else {

      this.setState({
        error: {
          ...this.state.error,
          emailError: ""
        }
      })
    }
  }
  setOTP = ({ target: { value } }) => {
    this.setState({ "otp": value });
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  setLoginInfo = (field, value) => {
    this.setState({ [field]: value })
  }
  validatedata = () => {
    const { error: { passwordError, confirmPasswordError }, password, confirmPassword } = this.state;
    if (passwordError.length === 0 && confirmPasswordError.length === 0 && password.length !== 0 && confirmPassword.length !== 0) {
      return true;
    }
    const errorText = "Please Enter Valid Password, min length 6 and max length 20!";

    if (confirmPassword.length == 0) {
      this.setState({
        error: {
          ...this.state.error,
          confirmPasswordError: errorText
        }
      })
    }
    if (password.length == 0) {
      this.setState({
        error: {
          ...this.state.error,
          passwordError: errorText
        }
      });
    }
    if (!confirmPassword && !password) {
      this.setState({
        error: {
          ...this.state.error,
          passwordError: errorText,
          confirmPasswordError: errorText
        }
      });
    }
    return false;
  }


  setEmail = ({ target: { value } }) => {
    this.setLoginInfo("email", value);
    if (!isValidEmailAddress(value)) {
      this.setState({
        error: {
          ...this.state.error,
          emailError: "Please Enter Valid Email!"
        }
      })
    } else {
      this.setState({
        error: {
          ...this.state.error,
          emailError: ""
        }
      })
    }
  }
  setConfirmPassword = ({ target: { value } }) => {
    this.setInfo("confirmPassword", value);
    if (value.length < 6 || value.length > 20) {
      this.setState({
        error: {
          ...this.state.error,
          confirmPasswordError: "Please Enter Valid Password, min length 6 and max length 20!"
        }
      })
    } else {
      this.setState({
        error: {
          ...this.state.error,
          confirmPasswordError: ""
        }
      })
    }
    const { password } = this.state;

    if ((value && password) && (value.length === password.length) && (value !== password)) {
      this.setState({
        error: {
          ...this.state.error,
          confirmPasswordError: "Did not match with password!"
        }
      })
    }
  }

  setPassword = ({ target: { value } }) => {
    this.setInfo("password", value);
    if (value.length < 6 || value.length > 20) {
      this.setState({
        error: {
          ...this.state.error,
          passwordError: "Please Enter Valid Password, min length 6 and max length 20!"
        }
      })
    } else {
      this.setState({
        error: {
          ...this.state.error,
          passwordError: ""
        }
      })
    }
    const { confirmPassword } = this.state;

    if ((value && confirmPassword) && (value.length === confirmPassword.length) && (value !== confirmPassword)) {
      this.setState({
        error: {
          ...this.state.error,
          passwordError: "Did not match with Confirm Password!"
        }
      })
    }
  }
  setInfo = (field, value) => {
    this.setState({ [field]: value })
  }
  setUserPassword = () => {
    this.setState({confirmPasswordLoader:true})
    this.props.resetPasswordWithToken({
      password : this.state.password
    },this.state.requestTokenVal).then(() => {
      this.setState({ confirmPasswordLoader: false })
      this.props.history.push("/login-home/login");
    }).catch(() => {
      this.setState({confirmPasswordLoader:false})
    })
  }
  render() {
    const { classes } = this.props;
    const { email, error: { emailError } } = this.state;
    const { password, confirmPassword, error: { passwordError, confirmPasswordError } } = this.state;
    return (
      <>
        <form>
          <GridContainer>
            <GridItem xs={12} md={12}>
              <GridContainer>
                <GridItem xs={12}>
                  <div className="login-heading" style={{fontFamily: 'Source Sans Pro, sans-serif', color: '#2a2d41'}}>
                    Reset Password
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} md={6}>
              { !this.state.requestTokenVal && 
                <GridContainer>
                  <GridItem xs={12} md={12}>
                    <CustomInput
                      labelText="Email..."
                      id="email"
                      value={email}
                      formControlProps={{
                        fullWidth: true
                      }}
                    
                      inputProps={{
                        onChange: this.setEmail,
                        disabled: this.state.showOTP
                      }}
                      helpText={emailError}
                    />
                  </GridItem>
                  {this.state.showOTP &&
                    <GridItem xs={12} md={12}>
                      <CustomInput
                        labelText="OTP..."
                        id="OTP"
                        value={this.state.otp}
                        formControlProps={{
                          fullWidth: true
                        }}
                    
                        inputProps={{
                          onChange: this.setOTP,
                        }}
                    
                      />
                    </GridItem>
                  }
                  <GridItem xs={8}>
                    {
                      this.state.showOTP ?
                        <Button color="secondary" block onClick={this.verifyOTP}>
                          verify
                    </Button>
                        :
                        <Button color="secondary" block onClick={this.resetPassword}>
                          Reset Password
                    </Button>
                    }
                  </GridItem>
                </GridContainer>
              }
              {
                this.state.requestTokenVal &&
                <GridContainer justify="center">
                <GridItem xs={10} md={10}>
                  <GridContainer>
                    <GridItem xs={12} style={{marginLeft: '15px'}}>
                      <div className="login-heading">
                        Set Password
                      </div>
                    </GridItem>
                    <GridItem xs={3} style={{marginLeft: '15px'}}>
                      <div className="text-underline"></div>
                    </GridItem>
                  
                  <GridItem xs={12} style={{marginTop:'-15px'}}>
                  <CustomInput
                    labelText="Password"
                    id="password"
                    value={password}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputType="password"
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                              </Icon>
                        </InputAdornment>
                      ),
                      onChange: this.setPassword
                    }}
                    helpText={passwordError}
                  />
                  </GridItem>
                  <GridItem xs={12} style={{marginTop:'-20px'}}>
                  <CustomInput
                    labelText="Confirm Password"
                    id="ConformPassword"
                    value={confirmPassword}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputType="password"
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                              </Icon>
                        </InputAdornment>
                      ),
                      onChange: this.setConfirmPassword
                    }}
                    helpText={confirmPasswordError}
                  />
                  </GridItem>
                  <GridItem xs={12} style={{textAlign:'center'}}>
                    <Button disabled={this.state.confirmPasswordLoader} color="secondary" onClick={this.setUserPassword}>
                        Set Password {this.state.confirmPasswordLoader && <AppLoader isSignOutLoader={true} size={12}/>}
                    </Button>
                  </GridItem>
                  </GridContainer>
                  </GridItem>
                  </GridContainer>
              }
            </GridItem>
          </GridContainer>
        </form>
      </ >
    )
  }
}
const mapStateToProps = (state) => {
  return {

  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: email => dispatch(resetPassword(email)),
    verifyEmail: payload => dispatch(verifyEmail(payload)),
    resetPasswordWithToken : (payload ,token)=> dispatch(resetPasswordWithToken(payload,token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);
