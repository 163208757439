import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";

import Feed from "./views/feed.jsx";

class NewsRoomHomePage extends PureComponent {

    render() {
        return (
          <>
            <Helmet title="News Room | PubNinja - You Publish. The rest we manage." />
            <Feed />
          </>
        )
    }
}

export default NewsRoomHomePage;
