import React,  { PureComponent, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles, Typography, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import FbSchedule from '../../../manage-social/fb/component/schedule';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";

import { connect } from 'react-redux';
import moment from "moment";
import { schedulePost, getFacbookPage } from "../../../manage-social/schedule-action.js";
import { setAppMessage } from "../../../app-actions.js";

const CustomDialogTitle = withStyles(theme => ({
  root: {
    // borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, toggleScheduleArticleModal } = props;
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="subheading">
        <div style={{ justifyContent: "center", fontWeight: "bold" }}>
          {children}
        </div>
      </Typography>
      <IconButton aria-label="Close" className={classes.closeButton} onClick={() => {
        toggleScheduleArticleModal(false);
      }}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
});

class ScheduleLiveArticleModal extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      showToolTip:false,
      articleCoverPicture: '',
      rawCoverPictureUrl: '',
      error: '',
    };
    this.getAllPages();
    this.getSumittedData = this.getSumittedData.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.scheduleArticleData.coverPicture !== this.state.rawCoverPictureUrl) {
      if (typeof nextProps.scheduleArticleData.coverPicture !== 'undefined') {
        this.setState({
          articleCoverPicture: `${nextProps.scheduleArticleData.coverPicture}_450_250${nextProps.scheduleArticleData.imgExtension}`,
          rawCoverPictureUrl: nextProps.scheduleArticleData.coverPicture,
        });
      }
    }
  }

  copyToClipboard = (scheduleArticleData) => {
    navigator.clipboard.writeText(scheduleArticleData && scheduleArticleData.url || "url not present.")
      .then(() => {
        this.setState({ showToolTip: true });
        setTimeout(() => {
          this.setState({ showToolTip: false });
        }, 1500);
    })
    .catch(err => {
      console.error('Could not copy text: ', err);
    });
  };
  getSumittedData(data) {
    let formattedData = {
        "page": {
          "name": data.pageName,
        },
        "data": {
          "message": data.message,
          "url": data.link,
          "time": moment(data.time).unix(),
      },
    };
    formattedData = data.message === '' ? formattedData : { ...formattedData, data: { ...formattedData.data, custommessage: data.message } };
    //console.log(`fo@@@@@@r`, formattedData);
    this.props.schedulePost(formattedData).then(res => {
      this.props.toggleScheduleArticleModal(false);
    }, _ => {
      this.setState({error: _.err});
    });
  }
  getAllPages() {
    if (this.props.user && this.props.user.permissions.length && this.props.user.permissions.indexOf('SHARE:SOCIAL_SCHEDULE') > -1) {
      this.props.getFacbookPage().then(res => {
        this.setState({pages: res});
      }, _ => {
        //removed console log
      });
    }
  }
  render() {
    const {
      showScheduleArticleModal,
      toggleScheduleArticleModal,
    } = this.props;
    const postCaption = [`Post Caption`, `${this.props.scheduleArticleData.postCaption}`];
    const ogTitle = [`Og Title`, `${this.props.scheduleArticleData.ogTitle}`];
    const data = [postCaption, ogTitle];
    return (
      <Dialog
        id="scheduleContainer"
        open={showScheduleArticleModal}
        //fullWidth={true}
        onClose={this.hide}
        fullWidth
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
        style={{margin: '-35px', zIndex: '10001'}}
      >
        <CustomDialogTitle toggleScheduleArticleModal={toggleScheduleArticleModal}>Schedule Article</CustomDialogTitle>
        <DialogContent style={{display:"flex"}}>
          <GridContainer>
            <GridItem xs={12}>
              {this.state.articleCoverPicture && this.state.articleCoverPicture.length ? <img style={{width: '100%'}} src={this.state.articleCoverPicture} /> : '' }
              <Table
                tableHeaderColor="primary"
                tableData={data}
                coloredColls={[3]}
                colorsColls={["primary"]}
              />
              <FbSchedule link={this.props.scheduleArticleData.url} formData={this.getSumittedData} pages={this.state.pages} setAppMessage={this.props.setAppMessage}/>
              <div style={{'color': 'red'}}>{this.state.error}</div>
           </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => {
  const { id: activeUser } = state.login.user;
  return {
    user: state.userManagement.accountManagement.userList[activeUser],
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getFacbookPage: () => dispatch(getFacbookPage()),
    schedulePost: (data) => dispatch(schedulePost(data)),
    setAppMessage: (msg, params) => dispatch(setAppMessage(msg, params)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ScheduleLiveArticleModal);
