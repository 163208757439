
import { StoryPostAction, UserAction } from "../../app-constants/index.js";

const StoryPostState = {
  view: {},
  storyDetail: {},
  isLoading: false,
  isLoadingTemplate: false
}
const StoryPost = (state = StoryPostState, action) => {

  switch (action.type) {
    //resetting
    case UserAction.SIGN_OUT.SUCCESS:
    case UserAction.SET.ACTIVE_DOMAIN: {
      return StoryPostState;
    }

    case StoryPostAction.LOAD.REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case StoryPostAction.LOAD.SUCCESS: {
      return {
        ...state,
        view: action.data,
        isLoading: false
      };
    }
    case StoryPostAction.LOAD.FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case StoryPostAction.SET.STORY_DETAIL: {
      return {
        ...state,
        storyDetail: action.storyDetail
      }
    }
    case StoryPostAction.LOAD.TEMPLATE.UNMOUNT: {
      return {state: StoryPostState}
    }
    case StoryPostAction.LOAD.TEMPLATE.REQUEST: {
      return {...state, isLoadingTemplate: true}
    }
    case StoryPostAction.LOAD.TEMPLATE.FAILURE: {
      return {...state, isLoadingTemplate: false}
    }
    case StoryPostAction.LOAD.TEMPLATE.SUCCESS: {
      return {...state, isLoadingTemplate: false}
    }
    default: return state;
  }
}
export default StoryPost;
