import React, { PureComponent, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SidePane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Pisco from "pisco-ui";
import "pisco-ui/lib/styles.css";
import { closePisco, setPiscoContent } from "../pages-action";

class PagePiscoContent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isPaneOpen: false,
      isPaneOpenLeft: false
    }
  }
  _hidePiscoModal() {
    this.setState({
      activePiscoContent: undefined
    });
    this.props.hidePiscoModal();
  }

  goToArticles = () => {
    this.props.setLastLocation('edit-page');
    this.props.history.push("/cms/articles");
  }
  render() {
    return (
      <Fragment>
        <SidePane
          overlayClassName='pisco-pane'
          isOpen={this.props.isShowPiscoModal}
          title='PISCO'
          subtitle='Media Search'
          from='right'
          width='80%'
          onRequestClose={this.props.closePisco}
        >
          <Pisco
            endpoint={process.env.REACT_APP_API_SERVER}
            headers={{
              authorization: localStorage.getItem('accessToken')
            }
            }
            injectQuery={{ domainId: this.props.activeDomain }}
            disableGetty={this.props.domain !== 'meaww'}
            type="Image"
            mode="embed"
            onEmbed={content => {
              this.props.setPiscoContent(content);
              this.props.closePisco();
            }} />
        </SidePane>
      </Fragment>

    );
  }
}
const mapStateToProps = (store) => {
  const { id, html = null, title = null, isShowPiscoModal } = store.websiteManagementReducer.pages.pageSetting
  const { activeDomain } = store.login.user;
  return {
    activeDomain,
    isShowPiscoModal
  }
}

const mapDispatchToProps = dispatch => ({
  closePisco: () => dispatch(closePisco()),
  setPiscoContent: (content) => dispatch(setPiscoContent(content)),
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PagePiscoContent));
