import React from 'react';
import { connect } from 'react-redux';

import AppLoader from "../../app-components/app-loader.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";

import AddSocialMedia from '../components/add-social-media';
import ShowSocialMedia from '../components/show-social-media';

import {setAppMessage} from '../../app-actions.js';
import { MessageTypes } from "../../app-constants/index.js";

import {
  getFacebookPage,
  deleteFacebookPage,
  getTwitterPage,
  deleteTwitterPage,
  storeFacebookPage,
  fetchFbFailure,
  fetchFbRequest,
  fetchFbSuccess,
  updateUser
} from '../manage-social-actions';

class ManageSocialAccounts extends React.Component {

  componentDidMount() {
    this.props.getFacebookPage();
    this.props.getTwitterPage();
  }

  render(){
    return (
      <>
        <GridContainer>
          <GridItem md={1}></GridItem>
          <GridItem xs={12} md={10}>
            <div style={{marginTop:'20px',padding: '40px 40px', border:'1px solid #ced3dc'}}>
              <div style={{ marginBottom: '20px',}}>
                Integrate your Facebook pages and Twitter accounts with the Pubninja dashboard. This will allow you to share, schedule and manage your posts on social media. Click on the Add Account to start.
              </div>
              <AddSocialMedia {...this.props} />
              <ShowSocialMedia {...this.props} />
            </div>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { manageSocialReducer: {isLoadingFacebook, isLoadingTwitter, facebookPages, twitterPages, isDeleting}} = state;
  return {
    isLoadingFacebook, isLoadingTwitter, facebookPages, twitterPages, isDeleting
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getFacebookPage: () => dispatch(getFacebookPage()),
    getTwitterPage: () => dispatch(getTwitterPage()),
    deleteFacebookPage: (page) => dispatch(deleteFacebookPage(page)),
    deleteTwitterPage: (data) => dispatch(deleteTwitterPage(data)),
    storeFacebookPage: (data) => dispatch(storeFacebookPage(data)),
    fetchFbFailure: () => dispatch(fetchFbFailure()),
    fetchFbRequest: () => dispatch(fetchFbRequest()),
    fetchFbSuccess: () => dispatch(fetchFbSuccess()),
    setAppMessage: (message, obj) => dispatch(setAppMessage(message, obj)),
    updateUser: (userdata) => dispatch(updateUser(userdata)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageSocialAccounts);
