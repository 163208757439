import React from 'react';
import Statistics from './views/statistics.jsx';

class StatisticsHome extends React.Component {
  render() {
    return (
      <>
        <Statistics />
      </>
    )
  }
}

export default StatisticsHome;
