import React from 'react';

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "@material-ui/core/Button";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SubCategoryEditModal from './editSubCategory';

class SubCategoryCard extends React.Component {
  state = {
    showForm: false,
    title: '',
    description: '',
    categoryVal: '',
    subcategory: '',
    showEditModal: false
  }

  handleChange = (event, field) => {
    this.setState({ [field]: event.target.value });
  }
  ShowsubCategoryEditModal = (val) => {
    this.setState({ ...this.state, ['showEditModal']: val })
  }
  render() {
    const { yCategory, activeSubCat, selectedCategory, submitSubCategory } = this.props;
    return (
      <>
        <div style={{border:"1px solid #ced3dc", marginBottom:'10px'}}>
          <div style={{ display: 'flex', alignItems: 'center' }} onClick={this.setSubCategory}>
            <p title="category" style={{
              textTransform: 'capitalize',
              flexGrow: '10',
              fontSize: '16px',
              paddingLeft: '10px',
              fontWeight: '500',
              margin: '0px',
            paddingTop: '15px',
            paddingBottom: '15px'
            }}>{yCategory.categoryVal}</p>
            <div style={{}}>
              <Button
                onClick={(e) => { this.ShowsubCategoryEditModal(true) }}
                style={{ color: "#2b2d42" }}
              >
                <i className="material-icons" style={{ fontSize: '25px', paddingRight: '5px' }}>
                  edit
          </i>
              </Button>
            </div>

          </div>
        </div>
        {this.state.showEditModal && < SubCategoryEditModal showEditModal={this.state.showEditModal} yCategory={activeSubCat} category={selectedCategory} submitSubCategory={submitSubCategory} ShowsubCategoryEditModal={this.ShowsubCategoryEditModal} />}
      </>
    );
  }
}

export default SubCategoryCard;
