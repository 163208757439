import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import DisclaimerCard from './disclaimer-card';
function DisclaimersList(props){
  const { disclaimers, setDisclaimerModalData } = props;
  return (
    <>
      <GridContainer>
      <GridItem xs={12} md={6}>
        <div style={{ display: "flex", alignItems: "center", cursor: "pointer", margin: "20px 0 20px", marginLeft: '4.5%' }}>
          <span style={{ display: "flex", color: '#fb4d24' }} onClick={()=>{props.toggleDisclaimerModal(true)}}>
            <AddCircleIcon /> &nbsp;<span style={{ color: "#232323", paddingTop: '2px' }}>{`Add Disclaimer`}</span>
          </span>
        </div>
      </GridItem>
        <GridItem xs={12} sm={12} md={12}>
        {
          disclaimers && disclaimers.map((item) => {
            return <DisclaimerCard disclaimer={item} key={item.id} setDisclaimerModalData={setDisclaimerModalData}/>
        })
      }
        </GridItem>
      </GridContainer>
    </>
  );
}

export default DisclaimersList;
