const ArticlesAction = {
  LOAD: {
    REQUEST: "ArticlesAction.Load.Request",
    FAILURE: "ArticlesAction.Load.Failure",
    SUCCESS: "ArticlesAction.Load.Success",
  },
  SET: {
    MENU_LIST_ARTICLE_ID: "ArticlesAction.Set.ArticleIdForMenuList",
    PREVIEW_ARICLE_ID: "ArticlesAction.Set.previewArticleId"
  },
  UPDATE: {
    REQUEST: "ArticlesAction.Update.Request",
    FAILURE: "ArticlesAction.Update.Failure",
    SUCCESS: "ArticlesAction.Update.Success",
  },
  REMOVE: {
    MENU_LIST_ARTICLE_ID: "ArticlesAction.Remove.ArticleIdForMenuList",
    PREVIEW_ARICLE_ID: "ArticlesAction.Remove.previewArticleId"
  },
  RESET: {
    CURRENT_PAGE_ID: "ArticlesAction.Reset.CurrentPageId"
  },
  SEARCH: {
    RESET: "ArticlesAction.Search.Load.Reset",
    SHOW_LIVE_TAB: 'ArticlesAction.Search.ShowLiveTab',
    HIDE_LIVE_TAB: 'ArticlesAction.Search.HideLiveTab'
  },
  CONFIRMATION_BOX: {
    TOGGLE: "ArticlesAction.Confirmation.toggle",
  },
  LATEST: {
    REQUEST: "ArticlesAction.Latest.Request",
    SUCCESS: "ArticlesAction.Latest.Success",
  },
  SCHEDULE: {
    SHOW: "ArticlesAction.Schedule.isShow",
    REQUEST: "ArticlesAction.Schedule.Request",
    FAILURE: "ArticlesAction.Schedule.Failure",
    SUCCESS: "ArticlesAction.Schedule.Success",
  },
  EXPLOIT: {
    SHOW: "ArticlesAction.Exploit.isShow",
    REQUEST: "ArticlesAction.Exploit.Request",
    FAILURE: "ArticlesAction.Exploit.Failure",
    SUCCESS: "ArticlesAction.Exploit.Success",
  }
}

export default ArticlesAction;
