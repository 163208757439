import React from 'react';
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import CustomInput from "components/CustomInput/CustomInput.jsx";

import SidePane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Pisco from "pisco-ui";
import "pisco-ui/lib/styles.css";
import AppLoader from '../../../app-components/app-loader.jsx';
import DeleteButton from '../../../app-components/delete-button.jsx';
import LoaderModal from './loader-modal.jsx';

import { MessageTypes } from "../../../app-constants/index.js";
import Datetime from 'react-datetime';

class Schedule extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.state = {
      data: {
        pageName: '',
        link: props.link || '',
        time: '',
        caption: '',
        message: '',
      },
      error: {
        message: false,
        link: false,
      },
      success: {
        message: '',
        link: '',
      },
      helpText: {
        message: '',
        link: '',
      },
      media: false,
      url: true,
      mediaAttachment: {},
      pageArr: [],
      isPaneOpen: false,
      isPaneOpenLeft: false,
    };
    this.initialState = this.state;
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.pages !== this.props.pages) {
      this.appendPagesIndropdown(nextProps.pages);
    }
  }
  appendPagesIndropdown(arrPages) {
    this.setState({ pageArr: arrPages.length ? arrPages.map(obj => obj.name) : [] });
  }
  componentDidMount() {
    this.appendPagesIndropdown(this.props.pages);
  }
  onChange(event, type) {
    if(type === 'time'){
      let curTime = moment().toDate();
      let newTime = moment(event.target.value).toDate();
      var diff = moment(newTime).diff(curTime, 'days')
      //console.log(`cur, sch`, diff);
    }
    if(diff < 0 || diff > 150){
      this.props.setAppMessage('Date has to be between today and 5 months from now.', { type: MessageTypes.ERROR });
    }
    else {
      let temp = event === 'pageName' ? { [event]: type } : { [type]: event.target.value };
      this.setState({ data: { ...this.state.data, ...temp } });
    }
  }
  onSubmit() {
    if (this.state.data.pageName === '')
      this.setState({ error: { message: 'Page name is a required field' } });
    else if (this.state.data.time === '')
      this.setState({ error: { message: 'Date/Time is a required field' } });
    else {
      let temp = this.state.data;
      temp.media = this.state.mediaAttachment || {};
      this.setState({ data: temp, }, this.props.formData.bind(null, this.state.data));
    }
  }
  resetForm = () => {
    this.setState({ ...this.initialState, data: { ...this.initialState.data, pageName: this.state.data.pageName } });
  }

  mediaDelete = (link) => {
    var mediaAttachment = this.state.mediaAttachment;
    var newAttachment = Object.values(mediaAttachment).filter(item => item != link);
    this.setState({ mediaAttachment: newAttachment, data: { ...this.state.data, caption: '' }, url: true });
  }

  changeDateTime = (value) => {
    let curTime = moment().toDate();
    let newTime = moment(value).toDate();
    var diff = moment(newTime).diff(curTime, 'days')
    if(diff < 0 || diff > 150){
      this.props.setAppMessage('Date has to be between today and 5 months from now.', { type: MessageTypes.ERROR });
      return;
    }
    this.setState({data: {...this.state.data, time: moment(newTime).format(`YYYY-MM-DDTHH:mm`)}});
  }

  render() {
    return <GridContainer justify="flex-start">
      <GridItem xs={12} sm={12} md={12}>
        <div style={{border:'1px solid #ced3dc'}}>
            <CardHeader color="primary" icon>
              <CardIcon >
                <Assignment color="secondary"/>
              </CardIcon>
              <h4 style={{ 'color': '#000' }}>Schedule On Page</h4>
            </CardHeader>
            <CardBody>
              <div style={{ color: 'red' }}>{this.state.error.message}</div>
              <CustomDropdown
                buttonText="Select Page"
                dropdownList={this.state.pageArr}
                onClick={this.onChange.bind(null, 'pageName')}
              />
              {this.state.url && <CustomInput
                infoText="Enter a message."
                labelText="Message"
                id="message"
                inputProps={{
                  onChange: event => {
                    this.onChange(event, "message")
                  },
                  type: "text",
                  value: this.state.data.message
                }}
                error={this.state.error.message}
                helpText={this.state.helpText.message}
                formControlProps={{ fullWidth: true }}
              />}
              {this.state.url && <CustomInput
                infoText="Enter a valid url."
                labelText="URL/Message"
                id="message"
                inputProps={{
                  onChange: event => {
                    this.onChange(event, "link")
                  },
                  type: "text",
                  value: this.state.data.link
                }}
                error={this.state.error.link}
                helpText={this.state.helpText.link}
                formControlProps={{ fullWidth: true }}
              />}
              {!this.state.url && <CustomInput
                labelText="Media Caption"
                id="caption"
                inputProps={{
                  onChange: event => {
                    this.onChange(event, "caption")
                  },
                  type: "text",
                  value: this.state.data.caption
                }}
                error={this.state.error.caption}
                helpText={this.state.helpText.caption}
                formControlProps={{ fullWidth: true }}
              />}
              {/* <div >
                <Datetime
                  inputProps={{
                    placeholder: "Select Date and Time",
                    value: this.state.data.time==''?'Select Date':moment(this.state.data.time).format("DD/MM/YYYY, hh:mm A") || 'Select Date',
                    style: {minWidth: '200px', maxWidth: '250px', margin: '25px 0'}
                  }}
                  onChange={(event)=>this.changeDateTime(event)}
                />
              </div> */}
              <div style={{marginTop: '-20px'}}>
                <CustomInput
                  labelText="Select Date"
                  id="time"
                  inputProps={{
                    onChange: event =>{
                      this.onChange(event, "time")
                    },
                    type: "datetime-local",
                    value: this.state.data.time,
                    style: {minWidth: '200px', maxWidth: '300px'}
                  }}
                />
              </div>
              {typeof this.props.user !== 'undefined' && <Button type="submit" onClick={() => {
                let data = this.state.data;
                this.setState({ isPaneOpen: true, url: false, data })
              }
              }>
                Upload Media
                  </Button>}
              <LoaderModal
                showModal={this.props.scheduleInProgress || false}
                showLoader={this.props.showLoader}
                message={this.props.loaderMessage}
                showCloseBtn={this.props.showCloseBtn}
                closeLoaderModal={() => {
                  this.props.closeLoaderModal();
                  this.resetForm();
                }}
              />
              {typeof this.props.user !== 'undefined' && <SidePane
                overlayClassName='pisco-pane'
                isOpen={this.state.isPaneOpen}
                title='PISCO'
                subtitle='Media Search'
                from='right'
                width='80%'
                onRequestClose={() => {
                  this.setState({ isPaneOpen: false, url: true });
                }}
              >
                <Pisco
                endpoint={process.env.REACT_APP_API_SERVER}
                headers={{
                  authorization: localStorage.getItem('accessToken')
                }
                }
                  injectQuery={{ domainId: this.props.user.activeDomain }}
                  disableGetty={this.props.user.activeDomain !== 15}
                  type={'Both'}
                  mode="embed"
                  onEmbed={content => {
                    //console.log('!!!!!!@@@@@@@@@@', content);
                    this.setState({ isPaneOpen: false });

                    let mediaAttachment = this.state.mediaAttachment;
                    mediaAttachment = {
                      ...mediaAttachment,
                      [content.uuid]: {
                        "fileName": content.filename,
                        "cdn": content.cdn,
                        "s3Url": content.s3Url,
                        "type": content.type,
                        "uuid": content.uuid,
                        "dimensions": content.dimensions || {},
                        "brightcove_video_id": content.brightcove_video_id || ''
                      }
                    }
                    this.setState({ mediaAttachment });
                  }} />
              </SidePane>}
              <GridContainer>
                {Object.values(this.state.mediaAttachment).length > 0 && Object.values(this.state.mediaAttachment).map((link, i) => {
                  let vidSrc = `//players.brightcove.net/6015698090001/gUY2vwlJSg_default/index.html?videoId=${link.brightcove_video_id}`;
                  //console.log("-----", vidSrc);
                  return (
                    <GridItem xs={3} sm={3} md={3} key={i}>
                      <Card style={{ position: 'relative' }}>

                        {
                          (link.type === 'Image')
                            ?
                            <img src={link.s3Url} width="100%" />
                            :
                            <div style={{ position: 'relative', display: 'block', maxWidth: '960px' }}>
                              <div style={{ paddingTop: '56.25%' }}>
                                <iframe src={vidSrc} allowFullScreen webkitallowfullscreen="true" mozallowfullscreen="true" allow="encrypted-media"
                                  style={{ position: 'absolute', top: '0px', right: '0px', bottom: '0px', left: '0px', width: '100%', height: '100%' }}>
                                </iframe>
                              </div>
                            </div>
                        }
                        <div
                          style={{
                            position: 'absolute',
                            top: '4px',
                            right: '10px',
                            color: 'white',
                            cursor: 'pointer',
                            fontSize: '2em',
                            fontWeight: 'bold'
                          }}
                          onClick={() => { this.mediaDelete(link) }}
                        >
                          <DeleteButton />
                        </div>
                      </Card>
                    </GridItem>
                  )
                })
                }
              </GridContainer>
              <Button type="submit" onClick={this.onSubmit}>SUBMIT</Button>
            </CardBody>
          </div>
      </GridItem>
    </GridContainer>
  }
}
Schedule.propTypes = {
  link: PropTypes.string,
  media: PropTypes.any,
};
export default withRouter(Schedule);
