import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from "@material-ui/core/styles/withStyles";
import RoundIconStyle from "../../../src/assets/jss/material-dashboard-pro-react/views/roundIcon.jsx";

class RoundIcon extends PureComponent {
  render() {
    const { color, background, letter, title, paddingTop, classes } = this.props;
    return (
      <Tooltip disableFocusListener title={`${title}`}>
        <div className={classes.wrapper} style={{ background: `${background}`, paddingTop: `${paddingTop}` }}>
          <h5 className={classes.iconText} style={{ color: `${color}` }}>{letter}</h5>
        </div>
      </Tooltip>
    )
  }
}

RoundIcon.propTypes = {
  color: PropTypes.string,
  background: PropTypes.string,
  letter: PropTypes.string,
  title: PropTypes.string
}
export default withStyles(RoundIconStyle)(RoundIcon);
