const storyCmsActions = {
  STORY_LIST: {
    SUCCESS: 'StoryCMSActions.storyList.success',
    FAILURE: 'StoryCMSActions.storyList.failure',
  },
  ADD_STORY: {
    SUCCESS: 'StoryCMSActions.AddStory.success',
    FAILURE: 'StoryCMSActions.AddStory.failure',
    LOADING: 'StoryCMSActions.AddStory.Loading',
  },
  SET: {
    ACTIVE_STORY: 'StoryCMSActions.Set.ActiveStory',
    ACTIVE_STORY_EXPIRY_TIME: 'StoryCMSActions.Set.ActiveStoryExpiryTime',

  },
  STORIES: {
    SUCCESS: 'StoryCMSActions.Stories.success',
    FAILURE: 'StoryCMSActions.Stories.failure',
  }
}

export default storyCmsActions;
