import React from 'react';
import ReactTable from "react-table";
import moment from "moment";

class PageViewsTable extends React.Component {
  render() {
    const {data} = this.props;
    return (
      <>
        <ReactTable
          manual
          data={this.state && this.state.data || []}
          pages={this.state && this.state.pages || 1}
          noDataText="No Data"
          defaultPageSize={20}
          pageSizeOptions={[10, 20, 50]}
          onFetchData={(state, instance) => {
            // show the loading overlay
            this.setState({loading: true});
            //console.log(state);
            // fetch your data
            this.props.getPageviews(
              null,
              null,
              state.page+1,
              state.sorted[0] ? state.sorted[0].id : null,
              state.sorted[0] ? state.sorted[0].desc?'desc':'asc' : null,
              state.pageSize
            )
            .then((res) => {
              //console.log(res.result);
            //   Update react-table
                this.setState({
                  data: res.result,
                  pages: res['result_info'].total_pages,
                  loading: false
                })
              })
          }}
          columns={[
            {
              Header: "URL",
              headerStyle: { textAlign: "left", fontWeight: "bold" },
              accessor: d => (
                <a
                  href={d.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={d.url}
                  style={{color: '#2b2d42', textDecoration: 'underline'}}
                >
                  {d.url}
                </a>
              ),
              id: 'url'
            },
            {
              Header: "Views",
              headerStyle: { textAlign: "left", fontWeight: "bold" },
              accessor: 'count'
            }
          ]}
        />
      </>
    )
  }
}

export default PageViewsTable;
