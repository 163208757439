import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AddDomain from "./views/add-domain";


class WordpressImportPage extends PureComponent {
  render() {
    const {} = this.props;
    return (<div>
      <Helmet title="Connect Domain | PubNinja - You Publish. The rest we manage." />
      <AddDomain/>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WordpressImportPage);
