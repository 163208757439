import React, { PureComponent, Fragment } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import ReactGA from 'react-ga';
import createBrowserHistory from "history/createBrowserHistory";
import appRoutes from "./app-routes.js";
import AppNotifications from "./app-notifications.jsx";
import AppMessages from "./app-messages.jsx";
import PrivateRoute from "./utils/required-auth.js";
import withComponentWrapper from "./component-wrapper.jsx";
import * as Sentry from '@sentry/browser';
const hist = createBrowserHistory();

ReactGA.initialize('UA-134518490-2');

const GaPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
  return null;
}

// Sentry.init({
//  dsn: "https://213514f531844dc99c1f87ff8bc6b077@sentry.io/1441792"
// });

//maintian routes from here

// const getRoutes = (routes) => {
//   return routes.map((prop, key) => {
//     if (prop.redirect)
//       return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
//     if (prop.collapse) {
//       return (
//         <Route path={prop.path} render={props => {
//           return (<prop.component {...props} >
//             <Switch>
//               {/* {
//                 prop.views && prop.views[0] &&
//                 <Redirect exact from={`${prop.path}`} to={`${prop.views[0].path}`} />

//               } */}
//               {
//                 prop.views.map((childProps, key) => {
//                   // return <Route path={prop.path} component={prop.component} key={key} />
//                   if (childProps.isRequiredAuth) {
//                     // return <Route path={prop.path} render={(props) => requiredAuth(store, prop.component, props)} key={key} />
//                     return <PrivateRoute path={childProps.path} component={childProps.component} key={key} />
//                   }
//                   return <Route path={childProps.path} component={childProps.component} key={key} />;
//                 })
//               }
//             </Switch>
//           </prop.component>)
//         }
//         } />
//       )

//     }
//     if (prop.isRequiredAuth) {
//       // return <Route path={prop.path} render={(props) => requiredAuth(store, prop.component, props)} key={key} />
//       return <PrivateRoute path={prop.path} component={prop.component} key={key} />
//     }
//     return <Route path={prop.path} component={prop.component} key={key} />;
//   })
// };

class App extends PureComponent {
  render() {
    const { isAuthenticated } = this.props;
    return (
      <Fragment>
        <Router history={hist}>
          <>
            <Route path="/" component={GaPageView} />
            <Switch>
              {
                appRoutes.map((prop, key) => {
                  if (prop.isRequiredAuth) {
                    return <PrivateRoute
                      path={prop.path}
                      component={prop.component}
                      key={key}
                      isAuthenticated={isAuthenticated}
                    />
                  }
                  return <Route path={prop.path} component={prop.component} key={key} />;
                })
              }
              {/* {
              getRoutes(appRoutes)
            } */}
            </Switch>
          </>
        </Router>
        <AppMessages />
        <AppNotifications />
      </Fragment >
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.login.isAuthenticated
  }
}
//pass options params with pure
export default connect(mapStateToProps, null)(withComponentWrapper(App));
