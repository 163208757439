import React, { Component } from 'react';
import { connect } from "react-redux";
import SubBaseComponent from "../component/subscribe-component";
import { exportNl, importNl, showInfoMessage } from "../news-letter-action";

class Subscribe extends Component {
    render() {
        const { 
            subscribers,
            exportNl,
            importNl, 
            activeDomain, 
            isNewsLetterActionLoading,
            showInfoMessage } = this.props;
        return (
            <SubBaseComponent
                count={subscribers}
                exportNl={exportNl}
                importNl={importNl}
                activeDomain={activeDomain}
                isNewsLetterActionLoading={isNewsLetterActionLoading}
                showInfoMessage = {showInfoMessage} />
        )
    }
}

const mapStateToProps = (state) => {
    const { subscribers, isNewsLetterActionLoading } = state.manageSocialReducer;
    const { activeDomain } = state.login.user;
    return {
        subscribers,
        activeDomain,
        isNewsLetterActionLoading
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        exportNl: () => dispatch(exportNl()),
        importNl: (file) => dispatch(importNl(file)),
        showInfoMessage :(msg)=> dispatch(showInfoMessage(msg))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscribe);