import React, { PureComponent } from 'react';
import { connect } from "react-redux";
//core comopnents
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//actions
import { fetchUserDetailsForAuth } from "../user-settings-actions.js";
//custom components
import ProfileDetailsView from "../components/profile-details-view.jsx";
import AppLoader from "../../app-components/app-loader";


class ProfileDetails extends PureComponent {
  componentDidMount() {
    if (!localStorage.getItem('accessToken')) {
      this.props.history.push("/login-home/login");
      return 
    }
    this.props.fetchUserDetailsForAuth();
  }
  render() {
    const { userDetails = {}, isFetchingUserDetails } = this.props;
    const { containingCompleteDetails = false } = userDetails;
    if (!containingCompleteDetails || isFetchingUserDetails) {
      return <AppLoader message="Fetching User Details..." />
    }
    return (
      <GridContainer justify="center" >
        <GridItem xs={12} sm={12} md={5} lg={5} style={{ background: "white", boxShadow: "0 3px 10px 0 rgba(72, 84, 97, 0.1)", borderRadius: "5px" }}>
          <ProfileDetailsView userDetails={userDetails} />
        </GridItem>
      </GridContainer>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    userDetails: state.login.user,
    containingCompleteDetails: state.login.containingCompleteDetails,
    isFetchingUserDetails: state.userSettings.isFetchingUserDetails
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserDetailsForAuth: () => dispatch(fetchUserDetailsForAuth())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetails);