import React from 'react';
import Post1 from '../template/template1.jsx';
import Post2 from '../template/template2.jsx';
import Post3 from '../template/template3.jsx';

function StorysView(props) {
  const { title = '', summary = '', coverPicture = '', imgExtension = '.jpeg' } = props.storyDetail;
  const viewData = {
    title,
    description: summary,
    action: `Swipe Up`,
    coverImage: coverPicture
  }
  return (
    props.result.map((item, key) => {
      return <div id={`storymainView${key}`} item={key} style={{ display: 'flex', justifyContent: 'center' }} key={key} >
        {item.name == "story1" &&
          < Post1 storyData={viewData} style={JSON.parse(item.styles)} isStory={true} />}
        {item.name == "story2" &&
          < Post2 storyData={viewData} style={JSON.parse(item.styles)} isStory={true} removeField={'description'} />
        }
        {item.name == "story3" &&
          < Post3 storyData={viewData} style={JSON.parse(item.styles)} isStory={true} removeField={'description'} />
        }
        {item.name == "story4" &&
          < Post2 storyData={viewData} style={JSON.parse(item.styles)} isStory={true} removeField={'description'} />
        }
      </div >
    })
  )
}
export default StorysView;
