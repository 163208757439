import { pagesAction, MessageTypes, NotificationTypes, } from "../../app-constants/index";
import { MeawwApi } from "../../utils/api";
import { setAppMessage, addAppNotification } from "../../app-actions.js";


export default function createNewPage(redirectToTinyMce) {

  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi().auth().then(api => {
      dispatch({ type: pagesAction.CREATE.REQUEST });
      return api.post(`/page/${activeDomain}/`,
        (error, data) => {
          if (error) {
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
            dispatch({ type: pagesAction.CREATE.FAILURE });
            return Promise.reject(error);
          }
          const id = data.result.pageId
          dispatch({ type: pagesAction.CREATE.SUCCESS, id });
          dispatch({ type: pagesAction.SET.PageId, id })
          dispatch(setAppMessage(data.result.message, {
            type: MessageTypes.SUCCESS,
            onOk: () => {
              redirectToTinyMce(id)
              dispatch({
                type: pagesAction.SET.TINYMC_CONTENT,
                id,
                content: {
                  id,
                  title: null,
                  html: null
                }
              });
            }
          }));
          return Promise.resolve();
        });
    });
  }
}

export function setEditorContent() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    const { id, content = {}, isDefaultPage } = getState().websiteManagementReducer.pages.pageSetting;
    const { title, ...otherContent } = content;
    return MeawwApi().auth().then(api => {
      return api.put(`/page/${activeDomain}/page/${id}`, isDefaultPage ? { ...otherContent } : { ...content },
        (error, data) => {
          if (error) {
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
            return Promise.reject(error);
          }
          dispatch(addAppNotification(data.result.message, { type: NotificationTypes.SUCCESS }))
          !isDefaultPage && dispatch({ type: pagesAction.UPDATE.TITLE, content, id })
          return Promise.resolve(data);
        });
    });
  }
}
export function getPages() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    dispatch({ type: pagesAction.LOAD.REQUEST });
    return MeawwApi().auth().then(api => {
      return api.get(`/page/${activeDomain}/`,
        (error, data) => {
          if (error) {
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
            dispatch({ type: pagesAction.LOAD.FAILURE });
            return Promise.reject(error);
          }
          const content = data.result;
          dispatch({ type: pagesAction.LOAD.SUCCESS, content: content.custom });
          dispatch({ type: pagesAction.DEFAULT.SET, content: content.defaults })
          return Promise.resolve();
        });
    });
  }
}

export function setPageContent(content) {
  return {
    type: pagesAction.SET.PAGE_CONTENT,
    content
  }
}

export function setDataToTinyMc(id, redirect) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi().auth().then(api => {
      dispatch({ type: pagesAction.ACTION.REQUEST })
      return api.get(`/page/${activeDomain}/page/${id}`,
        (error, data) => {
          if (error) {
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
            dispatch({ type: pagesAction.ACTION.FAILURE })
            return Promise.reject(error);
          }
          const content = data.result;
          dispatch({
            type: pagesAction.SET.TINYMC_CONTENT,
            id,
            content
          });
          redirect(id);
          dispatch({ type: pagesAction.ACTION.SUCCESS })
          return Promise.resolve();
        });
    });
  }
}

export function openPisco() {
  return {
    type: pagesAction.PISCO.SHOW
  }
}
export function closePisco() {
  return {
    type: pagesAction.PISCO.HIDE
  }
}
export function setPiscoContent(content) {
  return {
    type: pagesAction.SET.PISCO_CONTENT,
    content
  }
}

export function clearPiscoContent() {
  return {
    type: pagesAction.PISCO.CLEAR
  }
}

export function addDefaultPage(slug) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi().auth().then(api => {
      dispatch({ type: pagesAction.ACTION.REQUEST })
      return api.post(`/page/${activeDomain}/${slug}`,
        (error, data) => {
          if (error) {
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
            dispatch({ type: pagesAction.ACTION.FAILURE })
            return Promise.reject(error);
          }
          dispatch(getPages())
          dispatch(setAppMessage(data.result.message, { type: MessageTypes.SUCCESS }))
          dispatch({ type: pagesAction.ACTION.SUCCESS })
          return Promise.resolve();
        });
    });
  }
}

export function setDefaultPage() {
  return {
    type: pagesAction.DEFAULT.SET_DEFAULT_PAGE
  }
}
export function reSetDefaultPage() {
  return {
    type: pagesAction.DEFAULT.RESET_DEFAULT_PAGE
  }
}

export function publishPage(id, redirectTomainPage) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi().auth().then(api => {
      dispatch({ type: pagesAction.ACTION.REQUEST })
      return api.post(`/page/${activeDomain}/page/${id}/publish`,
        (error, data) => {
          if (error) {
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
            dispatch({ type: pagesAction.ACTION.FAILURE })
            return Promise.reject(error);
          }
          dispatch(getPages())
          dispatch({ type: pagesAction.ACTION.SUCCESS })
          dispatch(setAppMessage(data.result.message, {
            type: MessageTypes.SUCCESS, onOk: () => {
              redirectTomainPage();
              dispatch(reSetDefaultPage());
            }
          }))
          return Promise.resolve();
        });
    });
  }
}

export function deletePage(id) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi().auth().then(api => {
      dispatch({ type: pagesAction.ACTION.REQUEST })
      return api.delete(`/page/${activeDomain}/page/${id}`,
        (error, data) => {
          if (error) {
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
            dispatch({ type: pagesAction.ACTION.FAILURE })
            return Promise.reject(error);
          }
          dispatch(getPages())
          dispatch(setAppMessage(data.result.message, { type: MessageTypes.SUCCESS }))
          dispatch({ type: pagesAction.ACTION.SUCCESS })
          return Promise.resolve();
        });
    });
  }
}

export function disablePage(id) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi().auth().then(api => {
      dispatch({ type: pagesAction.ACTION.REQUEST })
      return api.delete(`/page/${activeDomain}/page/${id}`,
        (error, data) => {
          if (error) {
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
            dispatch({ type: pagesAction.ACTION.FAILURE })
            return Promise.reject(error);
          }
          dispatch(getPages())
          dispatch(setAppMessage('page disabled successfully', { type: MessageTypes.SUCCESS }))
          dispatch({ type: pagesAction.ACTION.SUCCESS })
          return Promise.resolve();
        });
    });
  }
}