import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Assignment from "@material-ui/icons/Assignment";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { postAccessToken } from "../schedule-action.js";
import { connect } from 'react-redux';

class Scheduler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        accessToken: '',
        pageName: '',
        pageId: 0,
      },
      success: '',
      error: '',
      helpText: {},
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  onChange(event, type) {
    this.setState({data: {...this.state.data, ...{[type]: event.target.value}}});
  }
  onSubmit() {
    if(isNaN(this.state.data.pageId)){
      this.setState({...this.state, error: 'Page ID must be a number.'})
    }
    else{
      this.props.postAccessToken(this.state.data).then(res => {
        this.setState({success: 'Successfully stored the token',error: '',data:{
          accessToken: '',
          pageName: '',
          pageId: 0,
        }});
      }, _ => {
        this.setState({error: _.message});
      });
    }
  }
  render () {
    return (
      <GridContainer justify="flex-start">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 style={{'color': '#000'}}>Add Access Facebook Token</h4>
            </CardHeader>
            <CardBody>
              <h3 style={{'color': '#DB6565'}}>{this.state.error || this.state.success}</h3>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Access Token"
                  id="Access Token"
                  inputProps={{
                    onChange: event =>{
                      this.onChange(event, "accessToken")
                    },
                    type: "text",
                    value: this.state.data.accessToken
                  }}
                  error={this.state.error.accessToken}
                  helpText={this.state.helpText.accessToken}
                  formControlProps={{fullWidth: true, required: true }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Page Name"
                  id="Page Name"
                  inputProps={{
                    onChange: event =>{
                      this.onChange(event, "pageName")
                    },
                    type: "text",
                    value: this.state.data.pageName
                  }}
                  error={this.state.error.pageName}
                  helpText={this.state.helpText.pageName}
                  formControlProps={{fullWidth: true, required: true }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Page Id"
                  id="Page Id"
                  inputProps={{
                    onChange: event =>{
                      this.onChange(event, "pageId")
                    },
                    type: "text",
                    value: this.state.data.pageId || ''
                  }}
                  error={this.state.error.pageId}
                  helpText={this.state.helpText.pageId}
                  formControlProps={{fullWidth: true, required: true }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button type="submit"
                  disabled={!(this.state.data.pageName && this.state.data.pageId && this.state.data.accessToken)}
                  onClick={this.onSubmit}
                  style={{backgroundColor: '#fb4d24'}}
                >
                  SAVE
                </Button>
              </GridItem>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (store) => {return {};};

const mapDispatchToProps = (dispatch) => {
  return {
    postAccessToken: (data) => dispatch(postAccessToken(data)),
  }
};

export default  connect(mapStateToProps, mapDispatchToProps)(Scheduler);;
