import { isAfter } from "date-fns";

const landingPageStyle = theme => ({
  root: {
    backgroundColor: "#eeeeee",
    boxShadow: 'none',
  },
  tabsRoot: {
    // overFlow: 'none'
  },
  tabRoot: {
    color: 'black',
    textTransform: "capitalize",
    fontSize: "16px",
    flexGrow: "unset"
  },
  tabSelected: {
    color: '#fb4d24'
  },
  tabsIndicator: {
    backgroundColor: '#fb4d24',
  },
  lpContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginLeft: '15px',
    marginRight: '15px',
    "&::after": {
      // content: `''`,
      // marginLeft: 'auto'
    },
    "@media (max-width: 425px)": {
      justifyContent: 'center'
    }
  },
  description: {
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    color: '#8d8d8d',
    textAlign: 'left',
    paddingLeft: '15px',
    paddingRight: '15px',
    maxHeight: '4.5em',
    wordBreak: 'break-word'
  },
  lpMainContainer: {
    border:'1px solid #CED3DC',
    marginBottom: '15px',
    width: '23%',
    background: '#ffffff',
    margin: '10px 20px',
    "@media (max-width: 960px)": {
      width: "300px",
      margin: '10px 0',
    },
  },
  lpCont: {
    display: 'flex',
    paddingLeft: '15px',
    paddingTop: '16px'
  },
  lpHeroImage: {
    borderRadius: '50px',
    width: '33px',
    height: '33px',
    objectFit: 'cover'
  },
  lpDisplayName: {
    margin: '0px',
    fontSize: '13px',
    fontWeight: '600',
    color: '#000000',
    textAlign: 'left',
    wordBreak: 'break-word'
  },
  lpIcon: {
    width: '9px',
    borderRadius: '50px',
    height: '9px',
    fontSize: '10px',
    margin: 'auto'
  },
  lpDate: {
    color: '#7f8c94',
    margin: '0 auto',
    paddingLeft: '5px'
  }
});
export default landingPageStyle;
