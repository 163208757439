import cmsRoutes, { storiesRoutes } from "./cms/cms-routes.js";
// import { notificationRoutes } from "./notifation/nofication-routes.js";
import analyticsRoutes from "./analytics/analytics-routes.js";
// import notificationRoutes from "./notification/notification-routes.js";
import userManagement from "./user-management/user-management-routes.js";
import userSettings from "./user-settings/user-settings-routes.js";
import schedulerRoutes from "./scheduler/scheduler-routes";
import manageSocialRoutes from "./manage-social/manage-social-routes";
import adminRoutes from "./admin/admin-routes";
import staticPagesRoutes from "./static-pages/static-pages-router";
// import DomainManagement from "./domain-management/domain-management-routes";
import settingRoutes from "./settings/setting-routes";
import toolRoutes from "./tool/tool-route";
import subRoutes from "./components/Navbar/sub-routes.js";

const dashbaordRoutes = [
    ...userSettings,
    ...storiesRoutes,
    ...cmsRoutes,
    ...manageSocialRoutes,
    // ...analyticsRoutes,
    // ...notificationRoutes,
    //...schedulerRoutes,
    // ...staticPagesRoutes,
    ...adminRoutes,
    // ...DomainManagement,
    ...toolRoutes,
    ...settingRoutes,
    ...subRoutes,
    { redirect: true, path: "/", pathTo: "/landing", name: "Dashboard", },
];

export default dashbaordRoutes;
