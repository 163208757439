
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ShowAudio from "./show-audio";

/**  this compoent take two required props
  @param {string} contentType
  @param {string/object} content
  @param {object}  renderContentStyle (optional)
  @return rendered content
*/


class Video extends PureComponent {
  render() {
    return (
      <video
        key = {this.props.thumbnail}
        controls
        width="100%"
        style={this.props.renderContentStyle}
        src={this.props.src}
        poster={this.props.thumbnail}
      />
    );
  }
}
class RenderContent extends PureComponent {

  render() {
    const {
      contentType,
      content,
      renderContentStyle = {},
      Video,
      articleData,
      isMobile,
      thumbnail
    } = this.props;

    return <GetRenderContent
      contentType={contentType}
      content={content}
      renderContentStyle={renderContentStyle}
      Video={Video}
      isMobile = {isMobile}
      articleData={articleData}
      thumbnail ={thumbnail}
    />
  }
}

// RenderContent.PropTypes = {
//   contentType: PropTypes.string.isRequired,
//   content: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.object
//   ]).isRequired,
//   renderContentStyle: PropTypes.object
// }

export default RenderContent;



// redner Content function
function GetRenderContent({ contentType = "", content, thumbnail ="",renderContentStyle, articleData ,isMobile}) {
  if (contentType.match(/image\/.*/)) {
    return <img src={((JSON.parse(content)) &&JSON.parse(content)).cdn} style={renderContentStyle} width="100%" height="100%" />
  }
  if (contentType.match(/audio\/.*/)) {
    return <ShowAudio src={(JSON.parse(content)) && (JSON.parse(content)).cdn} thumbnail={thumbnail || "https://cdn.pubninja.com/assets/new-feed/audio_thumbnail.png"} timer={JSON.parse(content) && JSON.parse(content).duration} isMobile={isMobile}/>
  }
  if (contentType.match(/video\/.*/)) {
    return <Video thumbnail={thumbnail} src={((JSON.parse(content)) &&JSON.parse(content)).cdn}  contentType={contentType} renderContentStyle={renderContentStyle} />
  }
  if (contentType.match(/text\/.*/)) {
    const { coverPicture, imgExtension } = articleData;
    return coverPicture && <img src={`https:${coverPicture}_450_250${imgExtension}`} style={renderContentStyle} width="100%" height="100%" />
  }
  return `${content}`
}
