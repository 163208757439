import React, { PureComponent } from "react";

import { connect } from "react-redux";

import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Helmet } from "react-helmet";

import registerUserPageStyle from "assets/jss/material-dashboard-pro-react/views/registerUsePageStyle.jsx";

import RegisterUserForm from "./views/register-user-form.jsx";


class RegisterUserPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      domains: []
    }
  }

  registerUser = () => {
    const { email, firstName, lastName } = this.state;
  }
  setRegisterInfo = (field, value) => {
    this.setState({ [field]: value })
  }
  setEmail = (e) => {
    this.setRegisterInfo("email", e.target.value)
  }
  setFirstName = (e) => {
    this.setRegisterInfo("firstName", e.target.value);
  }
  setLastName = (e) => {
    this.setRegisterInfo("lastName", e.target.value);
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Helmet title="Register User | PubNinja - You Publish. The rest we manage." />
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <RegisterUserForm {...this.props} />
          </GridItem>
        </GridContainer >
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {

  }
}
const mapDispatchToProps = dispatch => {
  return {

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(registerUserPageStyle)(RegisterUserPage));