import React from "react";
import AppLoader from "app-components/app-loader.jsx";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

class LandingPage extends React.Component {
  
  componentDidUpdate() {
    const { featurePermision, featurePermissionLoaded, permissions } = this.props;
    if (!localStorage.getItem('accessToken')) {
      this.props.history.push("/login-home/login");
      return 
  }
    if(permissions && permissions.includes('CREATE:WRITE')){
      if (featurePermissionLoaded && featurePermision && featurePermision.indexOf('QUICK_CMS') > -1){
        this.props.history.push('/my-website')
      } else if (featurePermissionLoaded){
        this.props.history.push('/my-site')
      } 
    } else{
      this.props.history.push('/my-site')
    } 
  }
  render() {
    return (
        <div style={{ width:'100%',minHeight:'80vh',display:'flex', justifyContent:'center',alignItems:'center'}}>
          <AppLoader message="Your workspace is getting ready." size={35} />
        </div>
    )
  }
}


const mapStateToProps = (store) => {
  const { login: { domain: { featurePermision, featurePermissionLoaded }, user: {permissions}} } = store;
  return {
    featurePermision,
    featurePermissionLoaded,
    permissions
  }
}

const mapDispatchToProps = (dispatch) => {

  return {
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LandingPage));