import { MeawwApi } from "../utils/api.js";

import {
  NewStoryAction
} from "../app-constants";

export function hidePiscoModal() {
  return { type: NewStoryAction.PISCO.CLOSE }
}

export function storeFacbookPage(arrPages) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/schedule/${activeDomain}/page`, arrPages,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function getFacbookPage() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    const path = `/schedule/${activeDomain}/page`;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(path,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}
export function fetchFbRequest(){
    return {
      type: 'fetchFbRequest'
    }
}
export function fetchFbSuccess(){
    return {
      type: 'fetchFbSuccess'
    }
}
export function fetchFbFailure(){
    return {
      type: 'fetchFbFailure'
    }
}
export function closeFbModal(){
  return {
    type: 'closeFbModal'
  }
}

export function changeSortMethod(sortMethod){
  return {
    type: 'changeSortMethod',
    sortMethod
  }
}

export function scheduleRequest(){
    return {
      type: 'ScheduleRequest'
    }
}

export function scheduleSuccess(){
    return {
      type: 'ScheduleSuccess'
    }
}

export function scheduleFailure(){
    return {
      type: 'ScheduleFailure'
    }
}

export function closeLoaderModal(){
  return {
    type: 'ScheduleClose'
  }
}

export function hasReceivedNewScheduleData(){
    return {
      type: 'hasReceivedNewScheduleData'
    }
}

export function setRefreshPages(){
  return {
    type: 'setRefreshPages'
  }
}

export function removeRefreshPages(){
  return {
    type: 'removeRefreshPages'
  }
}

export function postAccessToken(data) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/schedule/${activeDomain}/token`, data,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function deleteAccessToken(data) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.delete(`/schedule/${activeDomain}/token`, data,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function schedulePost(scheduleData) {
  return (dispatch, getState) => {
    dispatch(scheduleRequest());
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/schedule/${activeDomain}`, scheduleData,
        //return api.post(`/schedule/${activeDomain}/page`,
          (error, data) => {
            if (error) {
              dispatch(scheduleFailure());
              return Promise.reject(error);
            }
            dispatch(scheduleSuccess());
            return Promise.resolve(data);
          });
      });
  }
}

export function scheduleTweet(scheduleData) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    scheduleData.domain_id = activeDomain;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/schedule/post/tweet`, scheduleData,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function getPost() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/schedule/${activeDomain}`,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function getTweets() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
    .auth()
      .then(api => {
        return api.get(`/schedule/get/tweets?domain_id=${activeDomain}`,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function verifyUser() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/schedule/verify/user`,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function getAccount() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/schedule/get/accounts?domain_id=${activeDomain}`,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function deleteTweet(scheduleData) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/schedule/delete/tweet`, {id:scheduleData.tweetId},
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function deletePost(scheduleData) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.delete(`/schedule/${activeDomain}`, scheduleData,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function reschedulePost(rescheduleData) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.put(`/schedule/${activeDomain}`, rescheduleData,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function rescheduleTweet(rescheduleData) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/schedule/update/tweet`, rescheduleData,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function updateUser(userdata) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    userdata.domain_id = activeDomain;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/schedule/update/user`, userdata,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function removeUser(data) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/schedule/remove/user`, {id: data},
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}
