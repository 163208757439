
const createPostActions = {
  CREATE_POST: {
    REQEUST: 'quickCms.post.Request',
    SUCCESS: 'quickCms.post.Success',
    FAILURE: 'quickCms.post.Failure'
  },
  CATEGORIES: {
    SUCCESS: 'quickCms.categories.Success',
    FAILURE: 'quickCms.categories.Failure'
  },
  PISCO_SAVE: {
    REQEUST: 'quickCms.piscoSave.Request',
    SUCCESS: 'quickCms.piscoSave.Success',
    FAILURE: 'quickCms.piscoSave.Failure',
  }
}

export default createPostActions;
