import { ConfirmPasswordAction, MessageTypes, NotificationTypes, LocalStorageKeys } from "../app-constants/index.js";
import { MeawwApi } from "../utils/api.js";
import { setCookie, isValidEmailAddress, eraseCookie, removePersistedData } from "../utils/utils.js";
import { setAppMessage, addAppNotification } from "../app-actions.js";

function setConfirmPasswordRequest() {
  return {
    type: ConfirmPasswordAction.SET.CONFIRM_PASSWORD.REQUEST
  }
}
function setConfirmPasswordFailure() {
  return {
    type: ConfirmPasswordAction.SET.CONFIRM_PASSWORD.FAILURE
  }
}

function setConfirmPasswordSuccess() {
  return {
    type: ConfirmPasswordAction.SET.CONFIRM_PASSWORD.SUCCESS
  }
}

export function setUserPassword({ password, tokenId }) {
  return (dispatch) => {
    dispatch(setConfirmPasswordRequest());
    return MeawwApi()
      .auth()
      .then(api => {
        return api.put(`/user/password/reset/${tokenId}`, {
          "password": password
        }, (error, data) => {
          if (error) {
            dispatch(setConfirmPasswordFailure());
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
            return Promise.reject(error);
          }
          dispatch(setAppMessage('Password Updated Successfully.', { type: MessageTypes.SUCCESS }));
          dispatch((setConfirmPasswordSuccess()));
          return Promise.resolve({ success: true });
        })
      });
  }
}
