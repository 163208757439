import React from "react";
import ArticlesPv from "./view/article-pv.jsx";

class ArticlePvPage extends React.PureComponent {
  render() {
    return (
      <ArticlesPv />
    );
  }
}

export default ArticlePvPage;
