import React, { Component } from 'react';
import { connect } from "react-redux";
import Button from "components/CustomButtons/Button.jsx";
import AddCampagionDialog from "../component/campaign-modal";
import { getAllDomainCampaigns, createCampaign, getAllDomainCampaignsDate, getFullDetail } from "../news-letter-action";
import ShowCampaign from "../component/show-campaign";
import DateFilter from "../component/date-filter";
import AppLoader from "../../../app-components/app-loader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

export const FullDetailLoaderContext = React.createContext(false);
export const FullDetailContentContext = React.createContext({});
export const GetFullDetailContext = React.createContext(() => { });

class Campaigns extends Component {
  state = {
    isOpenAddCampagionModal: false,
  }
  closeAddModal = () => {
    this.setState({ isOpenAddCampagionModal: false })
  }
  openCampagionModal = () => {
    this.setState({ isOpenAddCampagionModal: true })
  }
  componentDidMount() {
    this.props.getAllDomainCampaigns()
  }
  render() {
    const { isOpenAddCampagionModal } = this.state;
    const { viewCamp,
      isNewsLetterCampagionLoading,
      getAllDomainCampaignsDate,
      getAllDomainCampaigns,
      getFullDetail,
      isFullDetailLoading,
      fullDetail,
      isNewsLetterActionLoading
    } = this.props;
    return (
      <div style={{ marginTop: '20px' }}>
        <GridContainer justify="space-between">
          <GridItem xs={12} sm={4} md={3}>
          <Button color="primary" onClick={this.openCampagionModal}>Create campaign </Button>
          </GridItem>
          <GridItem xs={12} sm={4} md={3}>
          <DateFilter
            getAllDomainCampaignsDate={getAllDomainCampaignsDate}
            getAllDomainCampaigns={getAllDomainCampaigns}
          />
          </GridItem>
          </GridContainer>
        {
          isNewsLetterCampagionLoading && <AppLoader message="loading..." />
        }
        {!isNewsLetterCampagionLoading && Object.keys(viewCamp).length == 0 &&
          <h3 style={{ textAlign: 'center', marginTop: '100px' }}>No data found</h3>
        }
        {
          Object.keys(viewCamp).length > 0 && Object.keys(viewCamp).map((ele, id) => {
            return <div style={{ paddingTop: '20px', paddingBottom: '5px' }} key={id}>
              <FullDetailLoaderContext.Provider value={isFullDetailLoading} >
                <FullDetailContentContext.Provider value={fullDetail}>
                  <GetFullDetailContext.Provider value={getFullDetail}>
                    <ShowCampaign date={ele} campVal={viewCamp[ele]} />
                  </GetFullDetailContext.Provider>
                </FullDetailContentContext.Provider>
              </FullDetailLoaderContext.Provider>
            </div>
          })
        }
        <AddCampagionDialog
          isOpenAddCampagionModal={isOpenAddCampagionModal}
          closeAddModal={this.closeAddModal}
          createCampaign={this.props.createCampaign}
          segments={this.props.segments}
          isNewsLetterActionLoading={isNewsLetterActionLoading}
        />

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { subscribers, segments = [{ id: null }], viewCamp = {},
    isNewsLetterCampagionLoading,
    isFullDetailLoading,
    fullDetail,
    isNewsLetterActionLoading } = state.manageSocialReducer;
  const { articles } = state.tool.editerPick;
  return {
    subscribers,
    articles,
    segments,
    viewCamp,
    isNewsLetterCampagionLoading,
    isNewsLetterActionLoading,
    isFullDetailLoading,
    fullDetail
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getAllDomainCampaigns: () => dispatch(getAllDomainCampaigns()),
    createCampaign: (data) => dispatch(createCampaign(data)),
    getFullDetail: (id) => dispatch(getFullDetail(id)),
    getAllDomainCampaignsDate: (startDate, endDate) => dispatch(getAllDomainCampaignsDate(startDate, endDate))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Campaigns);
