import React, { PureComponent } from "react";
import Button from "components/CustomButtons/Button.jsx";
import { Icon } from "antd";
import { Link } from 'react-router-dom';
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import moment from "moment";


class ArticleCard extends PureComponent {
  constructor(props) {
    super(props);
  }
  setPost = () => {
    if (Object.keys(this.props.articleDetails).length > 0) {
      return this.props.setStoryDetail(this.props.articleDetails)
    }
  }
  render() {
    const { articleDetails: { liveDate, title, url, coverPicture, imgExtension = ".jpeg", articleId }, classes, activeDomain } = this.props;
    return (
      <div style={{
        //boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
        background: "white",
        padding: "0",
        margin: "10px 0px",
        border:'1px solid #CED3DC',
        // borderRadius: "0px 5px 5px 0px"
      }}>
        <GridContainer justify="center">
          <GridItem xs={12} md={3} >
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
              <img src={`${coverPicture}_450_250${imgExtension}`} alt="cover image" style={{ width: "100%" }} />
            </div>
          </GridItem>
          <GridItem xs={12} md={6} lg={7}>
            <div style={{
              display: "flex", flexWrap: "wrap", justifyContent: "space-between", flexDirection: "column", padding: "10px"
            }}>
              <h4 style={{ color: "black", width: "100%", wordBreak: "break-word" }}> <a href={url} target="_blank" style={{ color: "black" }} >{title}</a></h4>
              <div>{moment(liveDate).local().format("Do[, ]MMMM")}</div>
            </div>
          </GridItem>
          <GridItem xs={12} md={3} lg={2} displayFlexType={true} >
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center", padding: "10px" }}>
              <Button color="primary" size={"sm"}>
                <a href={`https://www.facebook.com/sharer.php?u=${url}`} target="_blank" style={{color:"black", fontWeight: "bold" }} >
                  <Icon type="facebook" style={{ marginRight: "10px", position: "relative", top: "0px" }} />
                  <span style={{verticalAlign: 'middle'}}>Share</span>
               </a>
              </Button>
              <Link to={`/posts`}>
                <Button color="primary" size={"sm"} onClick={this.setPost}>
                  <Icon type="instagram" style={{ marginRight: "10px", position: "relative", top: "0px" }} />
                  <span  style={{color:"black", fontWeight: "bold", padding: '2px 0' }}>Story</span>
                 </Button>
              </Link>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

export default ArticleCard;
