import React, { PureComponent } from "react";
//import EditerPick from "./views/editer-pick.jsx";
import FeedsList from "./views/feeds-list.jsx";

class EditerPickHomePage extends PureComponent {

    render() {
        return (<FeedsList />)
    }
}

export default EditerPickHomePage;
