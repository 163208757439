import Fingerprint from "@material-ui/icons/Fingerprint";
import ConfirmPasswordPage from "./confirm-password-page.jsx";

const confirmPasswordRoutes = [{
    path: "/auth/password/reset/:tokenId",
    name: "Confirm password",
    short: "ResetPassword",
    mini: "RP",
    icon: Fingerprint,
    component: ConfirmPasswordPage
}, {
    path: "/auth/password/reset/:tokenId",
    name: "Reset password",
    short: "ResetPassword",
    mini: "RP",
    icon: Fingerprint,
    component: ConfirmPasswordPage
}]

export default confirmPasswordRoutes;