import React, { PureComponent } from "react";

import { Helmet } from "react-helmet";

import FixedTimeSpecificRevenues from "./views/fixed-time-specific-revenues.jsx";
import VariableTimeSpecificRevenues from "./views/variable-time-specific-revenues.jsx";

class RevenueAnalysisHomePage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showDateRanges: false
    }
  }

  showDateRanges = () => {
    this.setState({
      showDateRanges: !this.state.showDateRanges
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Helmet title="Revenue Analysis | PubNinja - You Publish. The rest we manage." />
        <FixedTimeSpecificRevenues />
        <VariableTimeSpecificRevenues classes={classes} />
      </div>
    )
  }
}
export default RevenueAnalysisHomePage;
