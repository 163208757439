import { combineReducers } from "redux";
import { NewsRoomAction } from "../../app-constants/action-types/index.js";

const newsRoomDefault = {
  isLoading: true,
  newsRoomData: [],
  articlesData: [],
  categoryId: null,
  categoryName: "",
  pageNo: 1,
  searchState: false,
  searchQuery: null,
  invalidSearch: false,
  showDraftProgressModal: false,
  progressModalData: [],
};
const newsRoomReducer = (state = newsRoomDefault, action) => {
  switch (action.type) {
    case NewsRoomAction.LOAD.REQUEST: {
      return { ...state, isLoading: true };
    }
    case NewsRoomAction.LOAD.FAILURE: {
      return { ...state, isLoading: false };
    }
    case NewsRoomAction.LOAD.SUCCESS: {
      let newsRoomData = action.items;
      let { invalidSearch } = state;
      if(action.items && action.items.length > 0){
        newsRoomData = newsRoomData.filter(
          item => item && item.meta && item.meta.pricing && item.meta.pricing.apusecode !== 860
        );
      }
      let articlesData = action.articlesData;
      if(articlesData.length){
        articlesData = [...articlesData, ...newsRoomData];
      }
      else{
        articlesData = newsRoomData;
        if(newsRoomData && newsRoomData.length === 0){
          invalidSearch = true;
        }
      }
      return { ...state, isLoading: false, articlesData, invalidSearch };
    }
    case NewsRoomAction.CATEGORIES.SUCCESS: {
      const categories = action.data;
      return { ...state, categories };
    }
    case NewsRoomAction.CATEGORY.SETACTIVE: {
      const { categoryId, categoryName } = action;
      return { ...state, categoryId, categoryName, pageNo: 1, articlesData: [], searchState: false, searchQuery: null, invalidSearch: false };
    }
    case NewsRoomAction.SET.PAGE_NUMBER: {
      return {...state, pageNo: action.pageNo};
    }
    case NewsRoomAction.DRAFT.SUCCESS: {
      return {...state}
    }
    case NewsRoomAction.SEARCH.START: {
      return {...state, articlesData: [], searchState: true, searchQuery: action.searchQuery, categoryId: null, categoryName: "" }
    }
    case NewsRoomAction.UNMOUNT.REDUCER.SUCCESS: {
      return {...state, ...action.data}
    }
    case NewsRoomAction.REFRESH: {
      return {...state, articlesData: [], pageNo: 1}
    }
    case NewsRoomAction.SETTING.RESETPAGE: {
      return {...state, pageNo: 1}
    }
    case NewsRoomAction.DRAFT.TOGGLEMODAL: {
      let {showDraftProgressModal, progressModalData} = state;
      if(state.showDraftProgressModal === true){
        progressModalData = [];
      }
      return {...state, showDraftProgressModal:!showDraftProgressModal, progressModalData}
    }
    case NewsRoomAction.DRAFT.ADDDATA: {
      return {...state, progressModalData: [...state.progressModalData, action.item]}
    }
    default:
      return state;
  }
};

const newsRoomSettingDefault = {
  token: null,
  showModal: false,
  modalData: null,
  draftButtonMessage: null,
};

const newsRoomSetting = (state = newsRoomSettingDefault, action) => {
  switch (action.type) {
    case NewsRoomAction.NEWSROOMMODAL.REQUEST: {
      return { ...state, showModal: true  }
    }
    case NewsRoomAction.NEWSROOMMODAL.OPEN: {
      const modalData = action.modalData;
      return { ...state, modalData };
    }
    case NewsRoomAction.NEWSROOMMODAL.CLOSE: {
      return { ...state, showModal: false, modalData: null, draftButtonMessage: null };
    }
    case NewsRoomAction.TOKEN.SAVE: {
      return {...state, token: action.token};
    }
    case NewsRoomAction.DRAFT.PROGRESS: {
      return {...state, draftButtonMessage: action.message}
    }
    case NewsRoomAction.DRAFT.SUCCESS: {
      return {...state, draftButtonMessage: action.message}
    }
    case NewsRoomAction.UNMOUNT.SETTING.SUCCESS: {
      return {...state, ...action.data}
    }
    default:
      return state;
  }
};
const NewsRoom = combineReducers({
  newsRoomReducer,
  newsRoomSetting
});

export default NewsRoom;
