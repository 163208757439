import React, { Component, PureComponent } from "react";
import { connect } from "react-redux";
import EditerPickCard from "../components/editer-pick-card.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import {
  getArticles,
  toggleArticlesSidePane,
  getLatestArticles
} from "../editer-pick-action.js";
import SidePane from "react-sliding-pane";
import { DateRange } from 'react-date-range';
import moment from 'moment';
import loadArticleCategories from '../../../cms/article/article-action.js';
import AppLoader from "../../../app-components/app-loader.jsx";
import searchStyle from "../../../assets/jss/material-dashboard-pro-react/views/searchStyle.jsx";
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';

function formatDateDisplay(startDate, endDate, defaultText) {
  let date = "";
  if (!startDate) {
    date = defaultText;
  } else {
    date = startDate.format("Do[, ]MMM[ -- ]")
  }
  if (!endDate) {
    date += defaultText;
  } else {
    date += endDate.format("Do[, ]MMM")
  }
  return date;
}

class Articles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showText: 'Latest Article',
      formatDate: '',
      categoryValue: [],
      subCategoryValue: [],
      checkedSubCategory: [],
      author: '',
      select: {
        endDate: null,
        startDate: null,
        key: 'selection',
      }
    };
    this.liveSearchFocus = React.createRef();
    this.setAutoFocus = this.setAutoFocus.bind(this);
  }
  componentDidMount() {
    this.props.loadArticleCategories();
    this.props.isNewsLetter && this.props.getLatestArticles();
    // this.liveArticleFocus.current.focus();
  }
  setAutoFocus() {
    console.log('final testing', this.liveSearchFocus.current)
    this.liveSearchFocus.current && this.liveSearchFocus.current.focus();
  }
  resetState = () => {
    this.setState({
      showText: 'Latest Article',
      formatDate: '',
      categoryValue: [],
      subCategoryValue: [],
      checkedSubCategory: [],
      author: '',
      select: {
        endDate: null,
        startDate: null,
        key: 'selection',
      }
    }, () => {
      this.props.hideSidePan()
    })
  }
  getValueFromInput = (e) => {
    const search = document.querySelector('#liveArticleSearch');
    const category = this.state.categoryValue.map((val) => val.id)
    const subcategory = this.state.checkedSubCategory.map((val) => val.id)
    const author = this.state.author;
    const searchKeyword = search && search.value;
    const { startDate, endDate } = this.state.select;
    let liveDate = {
      "min": moment(startDate).format("YYYY-MM-DD"),
      "max": moment(endDate).format("YYYY-MM-DD")
    }
    if (!startDate && !endDate) {
      liveDate = undefined
    }
    if (!searchKeyword.trim() && !category.length && !author.trim() && !this.state.formatDate) {
      this.setState({ ...this.state, ['showText']: `Latest Article` })
      this.props.getLatestArticles()
      return
    }
    this.setState({ ...this.state, ['showText']: `search result ${searchKeyword ? `for ${searchKeyword}` : ''}` }, () => {
      this.props.getArticles(searchKeyword.trim(), category, subcategory, author.trim(), liveDate);
    })
  }
  _handleKeyPress = (e) => {

    if (e.key === 'Enter') {
      this.getValueFromInput(e)
    }
  }
  handleChange = (e) => {
    const subCategory = e.target.value.reduce((a, b) => {
      const { yCategory } = b
      a = a.concat(yCategory)
      return a
    }, [])
    this.setState({ ...this.state, categoryValue: e.target.value, ['subCategoryValue']: subCategory, ['checkedSubCategory']: subCategory });
  }
  handleSubChange = (e) => {
    this.setState({ ...this.state, ['checkedSubCategory']: e.target.value });
  }
  authorChange = (e) => {
    this.setState({ ...this.state, author: e.target.value });
  }
  handleSelect = (range) => {
    const formatDate = formatDateDisplay(moment(range.selection.startDate), moment(range.selection.endDate));
    this.setState({ ...this.state, ["select"]: range.selection, formatDate });
  }
  reSetDate = () => {
    this.setState({
      ...this.state,
      ["select"]: {
        endDate: null,
        startDate: null,
        key: 'selection',
      },
      ['formatDate']: ''
    });
  }
  render() {
    const { articles,
      showSide,
      categories = [],
      isArticlesLoading,
      selectNewArticle,
      hideSidePan,
      picks,
      text = 'SET',
      title = 'Custom Feeds',
      subtitle = `Choose Articles for Feeds`,
      classes } = this.props;
    const { subCategoryValue, checkedSubCategory } = this.state;
    return (
      <>
        <SidePane
          isOpen={showSide}
          title={title}
          subtitle={subtitle}
          from='right'
          width={isWidthDown('sm', this.props.width)?"100%":"80%"}
          onRequestClose={() => {
            // triggered on "<" on left top click or on outside click
            this.resetState()
          }}
        >

          <div style={{ display: "flex", justifyContent: 'flex-end', paddingRight: '10%', width: '100%' }}>
            <div style={{ display: "flex", justifyContent: 'flex-end' }}>
              <GridContainer justify="space-between">
                <GridItem xs={5} sm={2}>
                  <TextField
                    style={{ marginTop: '0px', width: '120px' }}
                    label="Author"
                    type="search"
                    margin="normal"
                    onChange={this.authorChange}
                    autoFocus= {true}
                  />
                </GridItem>
                <GridItem xs={5} sm={2} style={{display: 'flex'}}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="demo-controlled-open-select">Calendar</InputLabel>
                    <Select
                      value={`${this.state.formatDate}`}
                      renderValue={() => `${this.state.formatDate}`}
                    >
                      <DateRange
                        ranges={[this.state.select]}
                        onChange={this.handleSelect}
                        maxDate={moment().toDate()}
                        minDate={moment("20180401", "YYYYMMDD").toDate()}
                      />
                    </Select>
                  </FormControl>
                  <div style={{ alignSelf: 'flex-end', marginBottom: '7px' }}>
                    <i className="material-icons" style={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => { this.reSetDate() }}>
                      clear
                    </i>
                  </div>
                </GridItem>
                <GridItem xs={5} sm={2}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="demo-controlled-open-select">Category</InputLabel>
                  <Select
                    multiple
                    value={this.state.categoryValue}
                    renderValue={selected => selected.map(({ category }) => category).join(', ')}
                    onChange={this.handleChange}
                  >
                    {
                      categories.map((category, id) => (
                        <MenuItem key={id} value={category}>
                          <Checkbox
                            style={{ color: '#2b2d42' }}
                            checked={this.state.categoryValue.indexOf(category) > -1} />
                          <ListItemText primary={category.category} />
                        </MenuItem>))
                    }
                  </Select>
                </FormControl>
                </GridItem>
                <GridItem xs={5} sm={2}>
                  <FormControl className={classes.formControl} style={{width: '100%', overflow: 'scroll'}}>
                    <InputLabel htmlFor="demo-controlled-open-select">Subcategory</InputLabel>
                    <Select
                      multiple
                      value={checkedSubCategory}
                      renderValue={selected => selected.map(({ category }) => category).join(', ')}
                      onChange={this.handleSubChange}
                    >
                      {
                        subCategoryValue.map((category, id) => (
                          <MenuItem key={category.id} value={category}>
                            <Checkbox
                              style={{ color: '#2b2d42' }}
                              checked={checkedSubCategory.indexOf(category) > -1}
                            />
                            <ListItemText primary={category.category} />
                          </MenuItem>))
                      }
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </div>
          </div>
          <div style={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center", flexDirection: 'column' }}>
            <div style={{ position: 'relative', marginTop: "15px", width: '80%', minWidth: '300px' }}>
              <input
                id="liveArticleSearch"
                tabIndex='+1'
                ref={(ele) => this.liveSearchFocus = ele}
                placeholder="Search article title, keywords..."
                onKeyPress={(e) => { this._handleKeyPress(e) }}
                style={{
                  width: "100%",
                  height: "46px",
                  borderRadius: "5px",
                  border: "none",
                  backgroundColor: "#f5f5f5",
                  paddingLeft: "10px",
                  fontSize: "16px",
                  border: '1px solid #e7e7e7',
                  boxShadow: " 0 1px 8px 0 rgba(0, 0, 0, 0.06)"
                }} />
              <div style={{ position: "absolute", right: '15px', top: '15px', cursor: "pointer" }}>
                <i className="material-icons" color="#2B2D42" onClick={(e) => { this.getValueFromInput(e) }}>
                  search
                 </i>
              </div>
            </div>
            <div style={{ width: '80%', marginTop: '20px' }}>
              <em>{this.state.showText}</em>
            </div>
            {isArticlesLoading && <AppLoader message="Loading..." />}
            <div style={{ overflowY: 'scroll', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', width: '90%', padding: '5px' }}>
              {articles.length === 0 && <div>No Article Found</div>}
              {
                articles.map((article) =>
                  <EditerPickCard
                    key={article.articleId}
                    article={article}
                    type={`${text}`}
                    //closePisco={toggleArticlesSidePane}
                    selectNewArticle={selectNewArticle}
                    picks={picks}
                  />

                )
              }
            </div>
          </div>
        </SidePane >
      </>
    )
  }
}
const mapStateToProps = (store) => {
  const { articles, isOpenArticleSidePane: isOpenArticle, isArticlesLoading } = store.tool.editerPick;
  const { categories } = store.cms.articleSettings;
  return {
    articles,
    isOpenArticle,
    isArticlesLoading,
    categories
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLatestArticles: () => dispatch(getLatestArticles()),
    loadArticleCategories: () => dispatch(loadArticleCategories()),
    getArticles: (searchKeyword, category, subcategory, author, liveDate) => dispatch(getArticles(searchKeyword, category, subcategory, author, liveDate)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(searchStyle)(withWidth()(Articles)));
