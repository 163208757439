import { RegisterUserAction, UserAction } from "../../app-constants/index.js";

const registerUserDefault = {
  registeringNewUser: false,
  msg: '',
}
const registerUser = (state = registerUserDefault, action) => {
  switch (action.type) {
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return registerUserDefault;
    }

    case RegisterUserAction.REGISTER.NEW_USER.REQUEST: {
      return { ...state, registeringNewUser: true }
    }
    case RegisterUserAction.REGISTER.NEW_USER.FAILURE: {
      return { ...state, registeringNewUser: false }
    }
    case RegisterUserAction.REGISTER.NEW_USER.SUCCESS: {
      const { msg } = action;
      return { ...state, registeringNewUser: false, msg }
    }
    case RegisterUserAction.REGISTER.CLEAR: {
      return { ...state, msg: '' }
    }
    default: return state;
  }
  // return state
}
export default registerUser;