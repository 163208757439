  import { MeawwApi } from "../utils/api.js";
import { MessageTypes } from "../app-constants";
import { setAppMessage } from "../app-actions.js";

//----------------------Disclaimers

function fetchDisclaimersRequest() {
  return {
    type: 'fetchDisclaimersRequest'
  }
}
function fetchDisclaimersFailure() {
  return {
    type: 'fetchDisclaimersFailure'
  }
}
function fetchDisclaimersSuccess(data) {
  return {
    type: 'fetchDisclaimersSuccess',
    disclaimers: data
  }
}

export function fetchDisclaimers() {
  return (dispatch, getState) => {
    dispatch(fetchDisclaimersRequest());
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/disclaimer/${activeDomain}`,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              dispatch(fetchDisclaimersFailure());
              return Promise.reject(error);
            }
            dispatch(fetchDisclaimersSuccess(data.result));
            return Promise.resolve(data);
          });
      });
  }
}

export function submitDisclaimer(disclaimer) {
  return (dispatch, getState) => {
    // dispatch(fetchDisclaimersRequest());
    const { activeDomain } = getState().login.user;
    if (disclaimer.id === null) {
      return MeawwApi()
        .auth()
        .then(api => {
          return api.post(`/disclaimer/${activeDomain}`, {
            title: disclaimer.title,
            description: disclaimer.description
          }, (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            //dispatch(fetchDisclaimersSuccess(data.result));
            dispatch(addDisclaimerSuccess({ ...disclaimer, id: data.result.id }));
            dispatch(toggleDisclaimerModal(false));
            dispatch(fetchDisclaimers());
            return Promise.resolve(data);
          });
        });
    }
    else {
      return MeawwApi()
        .auth()
        .then(api => {
          return api.put(`/disclaimer/${activeDomain}/${disclaimer.id}`, {
            title: disclaimer.title,
            description: disclaimer.description
          }, (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR })); return Promise.reject(error);
            }
            //dispatch(fetchDisclaimersSuccess(data.result));
            dispatch(addDisclaimerSuccess({ ...disclaimer }));
            dispatch(toggleDisclaimerModal(false));
            dispatch(fetchDisclaimers());
            return Promise.resolve(data);
          });
        });
    }
  }
}

function addDisclaimerSuccess(disclaimer) {
  return {
    type: 'addDisclaimerSuccess',
    disclaimer
  }
}

export function toggleDisclaimerModal(flag) {
  return {
    type: 'toggleDisclaimerModal',
    flag
  }
}

export function setDisclaimerModalData(disclaimer) {
  return {
    type: 'setDisclaimerModalData',
    disclaimer,
  }
}

// -----------------Categories

function fetchCategoriesRequest() {
  return {
    type: 'fetchCategoriesRequest'
  }
}
function fetchCategoriesFailure() {
  return {
    type: 'fetchCategoriesFailure'
  }
}
function fetchCategoriesSuccess(data) {
  return {
    type: 'fetchCategoriesSuccess',
    categories: data
  }
}
export function fetchCategories() {
  return (dispatch, getState) => {
    dispatch(fetchCategoriesRequest());
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/category/${activeDomain}?type=all`,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              dispatch(fetchCategoriesFailure());
              return Promise.reject(error);
            }
            dispatch(fetchCategoriesSuccess(data.result));
            return Promise.resolve(data);
          });
      });
  }
}

export function submitCategory(category) {
  return (dispatch, getState) => {
    // dispatch(fetchDisclaimersRequest());
    const { activeDomain } = getState().login.user;
    if (category.id === null) {
      return MeawwApi()
        .auth()
        .then(api => {
          return api.post(`/category/${activeDomain}`, {
            ...category
          }, (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            //dispatch(fetchDisclaimersSuccess(data.result));
            dispatch(addCategorySuccess({ ...category, id: data.result.id }));
            dispatch(toggleCategoryModal(false));
            dispatch(fetchCategories());
            return Promise.resolve(data);
          });
        });
    }
    else {
      return MeawwApi()
        .auth()
        .then(api => {
          return api.put(`/category/${activeDomain}/cat/${category.id}`, {
            ...category
          }, (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR })); return Promise.reject(error);
            }
            //dispatch(fetchDisclaimersSuccess(data.result));
            dispatch(addCategorySuccess(category));
            dispatch(toggleCategoryModal(false));
            dispatch(fetchCategories());
            return Promise.resolve(data);
          });
        });
    }
  }
}

function addCategorySuccess(category) {
  return {
    type: 'addCategorySuccess',
    category
  }
}

export function toggleCategoryModal(flag) {
  return {
    type: 'toggleCategoryModal',
    flag
  }
}

export function setCategoryModalData(category) {
  return {
    type: 'setCategoryModalData',
    category,
  }
}

export function submitSubCategory(category, subcategory) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    if (!subcategory.id || subcategory.id === null) {
      return MeawwApi()
        .auth()
        .then(api => {
          return api.post(`/category/${activeDomain}/y/${category.id}`, {
            ...subcategory
          }, (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            dispatch(addCategorySuccess({ ...category, id: data.result.id }));
            dispatch(toggleCategoryModal(false));
            dispatch(fetchCategories());
            return Promise.resolve(data);
          });
        });
    }
    else {
      return MeawwApi()
        .auth()
        .then(api => {
          return api.put(`/category/${activeDomain}/ycat/${subcategory.id}`, {
            ...subcategory
          }, (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR })); return Promise.reject(error);
            }
            dispatch(addCategorySuccess(category));
            dispatch(toggleCategoryModal(false));
            dispatch(fetchCategories());
            return Promise.resolve(data);
          });
        });
    }
  }
}


//------------------Analytics
function fetchAnalyticsRequest() {
  return {
    type: 'fetchAnalyticsRequest'
  }
}
function fetchAnalyticsFailure() {
  return {
    type: 'fetchAnalyticsFailure'
  }
}
function fetchAnalyticsSuccess(ga_id) {
  return {
    type: 'fetchAnalyticsSuccess',
    gaID: ga_id
  }
}

export function fetchAnalytics() {
  return (dispatch, getState) => {
    dispatch(fetchAnalyticsRequest());
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/domain/${activeDomain}`,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.reason.error, { type: MessageTypes.ERROR }));
              dispatch(fetchAnalyticsFailure());
              return Promise.reject(error);
            }
            dispatch(fetchAnalyticsSuccess(data.ga_id));
            return Promise.resolve(data);
          });
      });
  }
}

export function setAnalytics(gaID) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.put(`/domain/${activeDomain}`,
          { ga_id: gaID },
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.reason.error, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            dispatch(fetchAnalytics());
            return Promise.resolve(data);
          });
      });
  }
}

//------------------------Favicon and Logo
function fetchFaviconLogoRequest() {
  return {
    type: 'fetchFaviconLogoRequest'
  }
}
function fetchFaviconLogoFailure() {
  return {
    type: 'fetchFaviconLogoFailure'
  }
}
function fetchFaviconLogoSuccess(activeDomain, favicon, logo) {
  return {
    type: 'fetchFaviconLogoSuccess',
    activeDomain,
    favicon,
    logo
  }
}

export function fetchFaviconLogo() {
  return (dispatch, getState) => {
    dispatch(fetchFaviconLogoRequest());
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/domain/${activeDomain}`,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.reason.eror, { type: MessageTypes.ERROR }));
              dispatch(fetchFaviconLogoFailure());
              return Promise.reject(error);
            }
            dispatch(fetchFaviconLogoSuccess(activeDomain, data.favicon_url, data.logo));
            dispatch(setSiteInfo(data.title, data.description));
            return Promise.resolve(data);
          });
      });
  }
}

export function setFaviconLogo(favicon, logo, silent) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    let payload = {};
    if (favicon !== null)
      payload = { ...payload, favicon_url: favicon }
    if (logo !== null)
      payload = { ...payload, logo: logo }
    return MeawwApi()
      .auth()
      .then(api => {
        return api.put(`/domain/${activeDomain}`,
          payload,
          (error, data) => {
            if (error) {
              if(!silent) dispatch(setAppMessage(error.reason.error, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            dispatch(fetchFaviconLogo());
            return Promise.resolve(data);
          });
      });
  }
}

export function updateSiteInfo(title, description) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.put(`/domain/${activeDomain}`,
          { title, description },
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.reason.error, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            dispatch(setSiteInfo(title, description));
            dispatch(setAppMessage(data.message, { type: MessageTypes.SUCCESS }));
            return Promise.resolve(data);
          });
      });
  }
}

function fetchMapDomainRequest() {
    return {
      type: 'fetchMapDomainRequest'
    }
}

function fetchMapDomainFailure() {
    return {
      type: 'fetchMapDomainFailure'
    }
}

function fetchMapDomainSuccess(data) {
    return {
      type: 'fetchMapDomainSuccess',
      data
    }
}

export function setEntriesModalData(data) {
  return {
    type: 'setEntriesModalData',
    data,
  }
}

export function toggleentriesmodal(flag) {
  return {
    type: 'toggleentriesmodal',
    flag,
  }
}

export function fetchMapDomainStatus() {
  return (dispatch, getState) => {
    dispatch(fetchMapDomainRequest());
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/domain/${activeDomain}/mapping`,
          (error, data) => {
            if (error) {
              dispatch(fetchMapDomainFailure());
              return Promise.reject(error);
            }
            dispatch(fetchMapDomainSuccess(data));
            return Promise.resolve(data);
          });
      });
  }
}

function mappingRequest() {
    return {
      type: 'mappingRequest'
    }
}

function mappingFailure() {
    return {
      type: 'mappingFailure'
    }
}

function mappingSuccess(data) {
    return {
      type: 'mappingSuccess',
      data
    }
}

export function mapping(url) {
  return (dispatch, getState) => {
    dispatch(mappingRequest());
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/domain/${activeDomain}/mapping`,
          { "url": url && url.toLowerCase()},
          (error, data) => {
            if (error) {
              dispatch(mappingFailure());
              try {
                const err = JSON.parse(error.message);
                error.message = (err && err[0] && err[0].message) || 'Unknown Error Occured.';
              } catch (e) {
                error.message = error.message || 'Unknown Error Occured.';
              }
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            if(data.status == 'active'){
              dispatch(setAppMessage('Domain verified successfully. Your page will now be refreshed.', { type: MessageTypes.SUCCESS, onOk: ()=>{window.location.reload()} }));
            }
            else {
              dispatch(mappingSuccess(data));
            }
            return Promise.resolve(data);
          });
      });
  }
}

// function setDomainAndRefresh(){
//   sessionStorage.setItem(SessionKeys.ACTIVE_DOMAIN, activeDomain);
// }

export function getdnsrecords(){
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/domain/${activeDomain}/dns_records`,
          (error, data) => {
            if (error) {
              //dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            dispatch(dnsrecordssuccess(data));
            return Promise.resolve(data);
          });
      });
  }
}

function toggleAddingDnsEntry(){
    return {
      type: 'toggleAddingDnsEntry',
    }
}

function toggleDeletingDnsEntry(){
    return {
      type: 'toggleDeletingDnsEntry',
    }
}

export function addDnsEntry(data){
  return (dispatch, getState) => {
    dispatch(toggleAddingDnsEntry());
    const { activeDomain } = getState().login.user;

    if(data.id === null){
      //Add new Entry
      const payload = [{...data, proxied: Boolean(data.proxied)}];
      return MeawwApi()
        .auth()
        .then(api => {
          return api.post(`/domain/${activeDomain}/dns_records`,
            payload,
            (error, data) => {
              if (error) {
                dispatch(toggleAddingDnsEntry());
                dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
                return Promise.reject(error);
              }
              dispatch(toggleAddingDnsEntry());
              dispatch(toggleentriesmodal(false));
              dispatch(setAppMessage(data.message || 'Successfully added.', { type: MessageTypes.SUCCESS }));
              dispatch(getdnsrecords());
              return Promise.resolve(data);
            });
        });
    }
    else {
      //Edit an existing Entry
      const payload = {...data, proxied: Boolean(data.proxied)};
      return MeawwApi()
        .auth()
        .then(api => {
          return api.put(`/domain/${activeDomain}/dns_records`,
            payload,
            (error, data) => {
              if (error) {
                dispatch(toggleAddingDnsEntry());
                dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
                return Promise.reject(error);
              }
              dispatch(toggleAddingDnsEntry());
              dispatch(toggleentriesmodal(false));
              dispatch(setAppMessage(data.message || 'Successfully edited entry.', { type: MessageTypes.SUCCESS }));
              dispatch(getdnsrecords());
              return Promise.resolve(data);
            });
        });
    }
  }
}

export function removeDnsEntry(id){
  return (dispatch, getState) => {
    dispatch(toggleDeletingDnsEntry());
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.delete(`/domain/${activeDomain}/dns_records`,
          {"id": id},
          (error, data) => {
            if (error) {
              dispatch(toggleDeletingDnsEntry());
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            dispatch(toggleDeletingDnsEntry());
            dispatch(getdnsrecords());
            dispatch(setAppMessage(data.message || 'Success', { type: MessageTypes.SUCCESS }));
            return Promise.resolve(data);
          });
      });
  }
}

function dnsrecordssuccess(data) {
  return {
    type: 'dnsrecordssuccess',
    data
  }
}
export function domainCreation(data) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    data.type = "own_domain";
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/domain/${activeDomain}/mapping?hostname=${data.hostname}&name=${data.name}`,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function dnsCheck(data) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/domain/check?domain=${data}`,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function setLogoOnBoard(logo) {
  return {
    type: 'register.newuser.logo',
    RegisterLogo: logo
  }
}

export function setSiteInfo(title, description) {
  return {
    type: 'websiteManagement.Set.SiteInfo',
    title,
    description
  }
}

export function fetchSiteInfo(title, description) {
  return {
    type: 'websiteManagement.fetch.SiteInfo',
    title,
    description
  }
}

export function dltMyWebsite() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/domain/${activeDomain}/delete_my_domain`,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function showMessage(data,fun) {
  return (dispatch) => {
    dispatch(setAppMessage(data.message || 'Successfully deleted domain.', { type: MessageTypes.SUCCESS,onOk:fun }))
  }
}