import React, { PureComponent } from "react";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import { withStyles } from '@material-ui/core';
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

class HeaderFilter extends PureComponent {
  state = {
    current: this.props.headerTypeDefaultState
  }
  constructor(props) {
    super(props);
  }
  handleSubCategoryChange = (e) => {
    this.props.resetHeaderType(e.target.value)
    // this.props.setHeaderType(e.target.value);
  }
  componentDidUpdate(prevProps) {
    if (this.props.headerTypeDefaultState !== prevProps.headerTypeDefaultState) {
      this.setState({ current: this.props.headerTypeDefaultState }, () => {
        this.props.setHeaderType(this.props.headerTypeDefaultState);
      })
    }
  }
  render() {
    const { classes, } = this.props;
    return (
      <div style={{ marginBottom: "20px" }}>
        <FormControl
          fullWidth
          className={classes.selectLabel}
        >
          <InputLabel
            htmlFor="feed"
            className={classes.selectLabel}
          >
            Type
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={this.state.current}
            onChange={this.handleSubCategoryChange}
            inputProps={{
              name: "feed-type",
              id: "feed-type",
              classes: {
                icon: classes.iconMargin
              },
            }}
          >
            {
              ["All", "Image", "Video", "Audio", "text"].map((item, id) => {
                return (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    key={id}
                    value={item}
                  >
                    {`${item == 'text' ? 'Article':item}`}
                  </MenuItem>)
              })
            }
          </Select>
        </FormControl>
      </div>
    )
  }
}
export default withStyles(customSelectStyle)(HeaderFilter);
