import React, { Component } from "react";
import { connect } from "react-redux";
import {getMyArticlesPv, getAggregatedStats} from '../articles-actions';
// import Weekend from "@material-ui/icons/Weekend";
import Paper from '@material-ui/core/Paper';
import Table from "components/Table/Table.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import moment from 'moment';
import Button from "components/CustomButtons/Button.jsx";
import Typography from '@material-ui/core/Typography';

class ArticlesPv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      page: 1,
      stats: {
        today: 0,
        today: 0,
        last_7_days: 0,
      },
    }
  }

  loadData(typeType) {
    let page = this.state.page
    if (typeType === 'prev') {
      page = page - 1;
    } else {
      page = page + 1;
    }
    this.setState({...this.state, page: page});
    this.getArticleData(page);
  }

  getTableData(data) {
    const tableData = [];
    data.forEach((obj, i) => {
      tableData.push(
        [
          obj.title,
          moment(obj.live_date).format('YYYY-MM-DD hh:mm:ss'),
          obj.pageviews,
        ]
      );
    });
    return tableData;
  }

  getArticleData = (page) => {
    this.props.getMyArticlesPv(page).then((d) => {
      const formattedData = this.getTableData(d);
      this.setState({...this.state, articles: formattedData});
    }).catch((e) => {
      alert(e.message);
    });
  }

  componentDidMount() {
    this.getArticleData(this.state.page);
    this.props.getAggregatedStats()
        .then((d) => {
          this.setState({...this.state, stats: d});
        }).catch((e) => {
          alert(e.message);
        })
  }

  render() {
    return (
      <Paper xs={12} sm={12} md={12} >
        <GridContainer>
          <GridItem xs={12} md={12} style={{marginTop: '40px'}}>
            <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={3} lg={3}>
              <Paper elevation={1} style={{textAlign: 'center', border: '0.5px solid #666666', padding: '20px 40px'}}>
                <Typography  variant="title" component="h4">
                  <span style={{color: '#434343'}}>Today</span>
                </Typography>
                <Typography  variant="title" component="h5">
                  <span style={{color: '#666666'}}>{this.state.stats.today}</span>
                </Typography>
              </Paper>
            </GridItem>
            <GridItem xs={12} sm={6} md={3} lg={3}>
              <Paper elevation={1} style={{textAlign: 'center', border: '0.5px solid #666666', padding: '20px 40px'}}>
                <Typography  variant="title" component="h4">
                  <span style={{color: '#434343'}}>Yesterday</span>
                </Typography>
                <Typography  variant="title" component="h5">
                  <span style={{color: '#666666'}}>{this.state.stats.yesterday}</span>
                </Typography>
              </Paper>
            </GridItem>
            <GridItem xs={12} sm={6} md={3} lg={3}>
              <Paper elevation={1} style={{textAlign: 'center', border: '0.5px solid #666666', padding: '20px 40px'}}>
                <Typography  variant="title" component="h4">
                  <span style={{color: '#434343'}}>Last 7 days</span>
                </Typography>
                <Typography  variant="title" component="h5">
                  <span style={{color: '#666666'}}>{this.state.stats.last_7_days}</span>
                </Typography>
              </Paper>
            </GridItem>
          </GridContainer>
          </GridItem>
          <GridItem xs={12} md={12} style={{marginTop: '40px'}}>
            {this.state.page > 1 && <Button color="secondary" size="sm" onClick={this.loadData.bind(this, 'prev')}>Prev Page</Button>}
            {this.state.articles && !!this.state.articles.length && this.state.articles.length === 20 && <Button color="secondary" size="sm" onClick={this.loadData.bind(this, 'next')}>Next Page</Button>}
          </GridItem>
          <GridItem xs={12} md={12}>
            { this.state.articles && !!this.state.articles.length &&
              <Table
                tableHead={[
                  "Title",
                  "Published Time",
                  "Pageviews"
                ]}
                tableData={this.state.articles}
              />
            }
            { this.state.articles && !this.state.articles.length &&
              <h3>You have not send any notification yet.</h3>
            }
          </GridItem>
          <GridItem xs={12} md={12}>
            {this.state.page > 1 && <Button color="secondary" size="sm" onClick={this.loadData.bind(this, 'prev')}>Prev Page</Button>}
            {this.state.articles && !!this.state.articles.length && this.state.articles.length === 20 && <Button color="secondary" size="sm" onClick={this.loadData.bind(this, 'next')}>Next Page</Button>}
          </GridItem>
        </GridContainer>
      </Paper >
    );
  }
}
const mapStateToProps = (store) => {
  return {}
}
const mapDispatchToProps = (dispatch) => {
  return {
    getMyArticlesPv: (page) => dispatch(getMyArticlesPv(page)),
    getAggregatedStats: () => dispatch(getAggregatedStats())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ArticlesPv);
