import React from 'react';
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.jsx";

import kebabCase from "lodash.kebabcase";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

class CategoryModal extends React.Component {
  state = {
    categoryVal: '',
    title: '',
    description: '',
    category: '',
    order: '',
    header: 1,
    listing: 1,
    id: null,
    writer: 1
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.categoryModalData) {
      this.setState(nextProps.categoryModalData);
      this.setState({ header: 1, listing: 1, writer: 1 });
    }
    if (!nextProps.editMode) {
      this.setState({
        categoryVal: '',
        title: '',
        description: '',
        category: '',
        order: '',
        header: 1,
        listing: 1,
        id: null,
        writer: 1
      })
    }
  }

  handleChange = (event) => {
    if (!this.props.editMode) {
      if (event.target.id === 'categoryVal') {
        this.setState({ categoryVal: event.target.value, category: kebabCase(event.target.value) });
      } else {
        this.setState({ [event.target.id]: event.target.value });
      }
    } else {
      this.setState({ [event.target.id]: event.target.value });
    }

  }
  handleHiddenChange = (key) => {
    this.setState({ ...this.state, [key]: (this.state[key] == 0 ? 1 : 0) });
  }
  render() {
    const { classes, editMode } = this.props;
    const { writer, header, listing } = this.state;
    return (
      <>
        <GridContainer>
          <GridItem xs={12} md={12}>
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.modal
              }}
              fullWidth
              maxWidth="md"
              open={this.props.showCategoryModal}
              aria-labelledby="modal-slide-title"
              aria-describedby="modal-slide-description"
              style={{ overflow: 'scroll' }}
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}>
                <Button
                  justIcon
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="secondary"
                  onClick={() => this.props.toggleCategoryModal(false)}
                >
                  <Close className={classes.modalClose} />
                </Button>
                <h4 className={classes.modalTitle}>{editMode ? 'Edit Category' : 'Add Category'}</h4>
              </DialogTitle>
              <DialogContent
                id="modal-slide-description"
                className={classes.modalBody}
              >
                {/* {editMode && <CustomInput
                  id='category'
                  labelText="Category (used in url, no spaces)"
                  inputProps={{
                    onChange: this.handleChange,
                    disabled:true
                  }}
                  formControlProps={{ fullWidth: true, }}
                  value={this.state.category}
                />} */}
                <CustomInput
                  id='categoryVal'
                  labelText="Enter Category Name"
                  inputProps={{
                    onChange: this.handleChange
                  }}
                  formControlProps={{ fullWidth: true, }}
                  value={this.state.categoryVal || ''}
                />
                {/* <CustomInput
                  id='title'
                  labelText="Title"
                  inputProps={{
                    onChange: this.handleChange
                  }}
                  formControlProps={{ fullWidth: true }}
                  value={this.state.title || ''}
                /> */}
                <CustomInput
                  id='description'
                  labelText="Describe your category"
                  inputProps={{
                    onChange: this.handleChange
                  }}
                  formControlProps={{ fullWidth: true, }}
                  value={this.state.description || ''}
                />
              </DialogContent>
              <DialogActions
                className={classes.modalFooter + " " + classes.modalFooterCenter}>
                <Button
                  onClick={() => { this.props.submitCategory(this.state) }}
                  color="primary">
                  Submit
                </Button>
                <Button
                  color="secondary"
                  onClick={() => this.props.toggleCategoryModal(false)}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
export default withStyles(modalStyle)(CategoryModal);