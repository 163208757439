import React, { Fragment } from "react";
import { DEFULT_PROFILE_PICTURE_URL } from "../../../app-constants/image-url.js";
import GridItem from "components/Grid/GridItem.jsx";
import RoundIcon from "../../../components/RoundIcon/roundicon.jsx"
import { uppercaseFirstLetter } from "../../../utils/utils.js";

const UserDetails = ({ userDetails, userDetails: { firstName, lastName, email, joinDate, profilePicture, scope } }) => {
  return (
    <Fragment>
      <GridItem sm={4}>
        <img src={profilePicture || DEFULT_PROFILE_PICTURE_URL} style={{ width: "40px", height: "40px", marginTop: "8px", marginBottom: "8px", borderRadius: "50%", boxShadow: "0px 0px 0px 4px rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12)" }} alt="" />
        <h5 style={{ display: "inline", paddingLeft: "10px" }}>{`${uppercaseFirstLetter(firstName)} ${uppercaseFirstLetter(lastName)}`}</h5>
      </GridItem>
      <GridItem sm={2} alignItemCenter={true} displayFlexType={true}>
        <h5>{joinDate && joinDate.split("T")[0]}</h5>
      </GridItem>
      <GridItem sm={4} alignItemCenter={true} displayFlexType={true}>
        <h5>{email}</h5>
      </GridItem>
      <GridItem sm={1} alignItemCenter={true} displayFlexType={true}>
        <RoundIcon color="white" letter={`${scope.charAt(0).toUpperCase() || "?"}`} background="#2b2d42" title={`${scope} USER`} paddingTop="5px" />
      </GridItem>
    </Fragment>
  )
}
export default UserDetails;
