import React, { PureComponent } from "react";

class ToolHomePage extends PureComponent {
  render() {
    return (<>
            {this.props.children}
    </>)
  }
}

export default ToolHomePage;