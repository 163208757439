import { combineReducers } from "redux";
import { ArticlesAction, NewStoryAction, UserAction, ArticlesState, ArticleAction, ArticleTypes } from "../../app-constants/index.js";
import { getUniques, isValidGalleryItems } from "../../utils/utils.js";
import GalleryItem from "../schema/gallery-item.js";
import { Table } from "@material-ui/core";

function isContentSubmittable(contentType, content) {
  let allowSubmission = false;
  if (contentType === ArticleTypes.GALLERY) {
    let parsedContent = content;
    if (content !== "") {
      parsedContent = JSON.parse(content);
    }
    allowSubmission = isValidGalleryItems(parsedContent);
  } else {
    const results = content.replace(/<\/?[^>]+(>|$)/g, "");
    const lines = results.split("\n");
    const contentLength = results.split(" ").length + (lines.length - 1);
    if (contentLength > 25) {
      allowSubmission = true;
    }
  }
  return allowSubmission;
}

const updateArticleIds = (state, articles) => {
  state = state.map((article_id) => {
    if (typeof article_id === 'number') return `${article_id}`;
    else return article_id;
  });
  const allIds = [...state, ...articles.map(article => article.articleId)];
  return [...getUniques(allIds)];
}

const articleDefaultState = [];
const articleIdsWithNamedType = (articleTypeName) => (state = [], action) => {
  const { articleState } = action;
  if (articleState !== articleTypeName) {
    return state
  }
  switch (action.type) {
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return articleDefaultState;
    }

    case ArticleAction.CREATE.ARTICLE: {
      return [action.articleid, ...state];
    }
    case ArticlesAction.SEARCH.RESET: {
      return []
    }
    case ArticlesAction.LOAD.SUCCESS: {
      if (action.nextPage == 1) {
        state = []
      }
      const datas = [...updateArticleIds(state, action.articles)];
      return datas;
    }
    case ArticlesAction.LATEST.SUCCESS: {
      return [...updateArticleIds([], action.articles)];
    }
    case ArticleAction.UNPUBLISH.SUCCESS: {
      const { articleId } = action;
      if (articleState === ArticlesState.LIVE_ARTICLES) {
        return state.filter(id => id !== articleId);
      }
    }
    case ArticleAction.SEND_FEEDBACK.SUCCESS: {
      const { articleId, isUpdateDraft } = action;
      if (articleState === ArticlesState.SUBMITTED_ARTICLES || articleState === ArticlesState.APPROVED_ARTICLES) {
        return state.filter(id => id !== articleId);
      }

      if (isUpdateDraft && articleState === ArticlesState.DRAFT_ARTICLES) {
        return [articleId, ...state];
      }
    }
    //submitting article start
    case ArticleAction.SUBMIT.SUCCESS: {
      const { articleId } = action;

      if (articleState === ArticlesState.DRAFT_ARTICLES) {
        return state.filter(id => id !== articleId);
      }

      if (articleState === ArticlesState.SUBMITTED_ARTICLES) {
        return [articleId, ...state];
      }
    }
    //submitting article end


    //deleting article  start
    case ArticleAction.DELETE.SUCCESS: {
      return state.filter(id => id !== action.articleId);
    }
    //deleting article end

    //approving start
    case ArticleAction.APPROVE.SUCCESS: {
      const { articleId } = action;
      if (articleState === ArticlesState.SUBMITTED_ARTICLES) {
        return state.filter(id => id !== articleId);
      }
      if (articleState === ArticlesState.APPROVED_ARTICLES) {
        return [articleId, ...state];
      }
    }
    //approving end

    //approving start
    case ArticleAction.PUBLISH.SUCCESS: {
      const { articleId } = action;
      if (articleState === ArticlesState.APPROVED_ARTICLES) {
        return state.filter(id => id !== articleId);
      }
      if (articleState === ArticlesState.LIVE_ARTICLES) {
        return [articleId, ...state];
      }
    }
    //approving end

    default: return state;
  }
}

const DefaultArticlesSettings = {
  currentPage: 0,
  loadingArticles: false,
  articlesPerPage: 30,
  hasMoreData: true,
}

const articleSettingsWithNamedType = (articleTypeName) => (state = DefaultArticlesSettings, action) => {
  const { articleState } = action;
  if (articleTypeName !== articleState) {
    return state;
  }
  switch (action.type) {
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return DefaultArticlesSettings;
    }

    case ArticlesAction.LOAD.REQUEST:
      return { ...state, loadingArticles: true }
    case ArticlesAction.LOAD.FAILURE:
      return { ...state, loadingArticles: false }

    case ArticlesAction.LOAD.SUCCESS:
    case ArticlesAction.LATEST.SUCCESS:
      const { nextPage, articles } = action;
      return {
        ...state,
        loadingArticles: false,
        currentPage: nextPage,
        hasMoreData: articles.length === 30
      };
    case ArticlesAction.RESET.CURRENT_PAGE_ID: {
      const { currentPage } = action;
      return {
        ...state,
        loadingArticles: false,
        currentPage: currentPage,
        hasMoreData: true
      };
    }
    default: return state;
  }
}


const articlesSettingsDefault = {
  isShowLiveTab: true,
  confirmationBox: {
    showConfirmationModal: false,
    confirmationMessage: null,
    onConfirm: null,
  }
};
const articlesSettings = (state = articlesSettingsDefault, action) => {
  switch (action.type) {
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return articlesSettingsDefault;
    }
    case ArticlesAction.SEARCH.SHOW_LIVE_TAB: {
      return { ...state, isShowLiveTab: true }
    }
    case ArticlesAction.SEARCH.HIDE_LIVE_TAB: {
      return { ...state, isShowLiveTab: false }
    }
    case ArticlesAction.CONFIRMATION_BOX.TOGGLE: {
      return {
        ...state, confirmationBox: {
          showConfirmationModal: action.showConfirmationModal,
          confirmationMessage: action.confirmationMessage,
          onConfirm: action.onConfirm,
        }
      }
    }
    default: return state;
  }
}


const getArticleMetaData = ({ contentType, tags } = {}, action, ) => {
  switch (action.type) {
    case ArticleAction.SET.VIDEO_HEADER:
      return { "videoHeader": action.videoHeader };
    // case ArticleAction.UPLOAD.COVER_PICTURE.SUCCESS:
    //   return { ...action.coverPictureDetails }

    case ArticleAction.SET.ARTICLE_TITLE:
      return { "title": action.title };

    case ArticleAction.SET.ARTICLE_SUMMARY:
      return { "summary": action.summary };
    // case ArticleAction.SET.SHARE_TYPE:
    //   return { share_type: action.share_type };
    case ArticleAction.SET.CATEGORY_TYPE:
      return { categoryId: action.categoryId };
    case ArticleAction.SET.SUB_CATEGORY_TYPE:
      return { yCategoryId: action.yCategoryId };

    // case ArticleAction.SET.STORY_TYPE:
    //   return { y_category_id: action.storyType };
    // case ArticleAction.SET.DISCLAIMER_TYPE:
    //   return { disclaimer_id: action.disclaimerType };
    case ArticleAction.SET.SEO_TITLE:
      return { "seoTitle": action.seoTitle };
    case ArticleAction.SET.OG_TITLE:
      return { "ogTitle": action.ogTitle };
    case ArticleAction.SET.STORY_SOURCE:
      return { "storySource": action.storySource };
    case ArticleAction.SET.POST_CAPTION:
      return { "postCaption": action.postCaption };
    case ArticleAction.SET.CUSTOM_URL:
      return { "customUrl": action.customUrl };

    // case ArticleAction.SET.ARTICLE_TYPE:
    //   return { "el_type": action.articleType };
    // case ArticleAction.SET.TIME_SPECIFIC:
    //   return { "time_specific": action.timeSpecific };
    // case ArticleAction.SET.AUTHOR_NAME: {
    //   const { authorName } = action;
    //   if (authorName && authorName.length > 0) {
    //     return { "alias": authorName };
    //   }
    //   return {};
    // }
    case ArticleAction.SET.META_TAGS:
      if (action.tag === '') return { 'tags': [] }
      return { "tags": action.tag.split(",").map(tag => ({tag: tag, id: null, subTag: null, subTagId: null})) };

    case ArticleAction.SET.META_SUB_TAGS:
      if (action.subTag === '') {
        return {
          "tags": [{...tags[0], subTag: null, subTagId: null}]
        }
      }
      return { "tags": [{...tags[0],  subTagId: action.subTag.subTagId, subTag: action.subTag.subTag}]};

    case ArticleAction.SET.CONTENTS: {
      const { contents = "" } = action;
      const allowSubmission = isContentSubmittable(contentType, contents);


      if (contents === "") {
        // return { "contents": "Start Writing..." };
      }
      return {
        "contents": action.contents,
        allowSubmission: allowSubmission
      };
    }
    case ArticleAction.SET.ARTICLE_LOCK_DATA: {
      return { 'inReviewBy': action.inReviewBy };
    }
    case ArticleAction.SET.ARTICLE_ADS_DISABLE: {
      const { disable } = action;
      return { noAds: disable };
    }
    // case ArticleAction.APPEND.NEW_ITEM: {
    //   const { newItem } = action;
    //   let { contents = [] } = articleDetails;
    //   const isContentsAlreadyExist = isValidJson(contents);

    //   if (contents === "") {
    //     contents = [];
    //   }
    //   return { "contents": [...contents, newItem] };
    // }

    // case ArticleAction.LOAD.ARTICLE_CATEGORIES.SUCCESS: {
    //   const { resetCategryType = false } = action;
    //   if (resetCategryType) {
    //     return { category_id: 0 }
    //   }
    //   return {}
    // }
    // case ArticleAction.LOAD.ARTICLE_STORY_TYPE.SUCCESS: {
    //   const { resetStoryType = false } = action;
    //   if (resetStoryType) {
    //     return { y_category_id: 0 };
    //   }
    //   return {};
    // }
    // case ArticleAction.SUBMIT.SUCCESS: {
    //   return { isSubmitted: true };
    // }
    // case ArticleAction
    default: return {}
  }
}
const articlesByIdsDefault = {};
const articlesByIds = (state = articlesByIdsDefault, action) => {
  switch (action.type) {
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return articlesByIdsDefault
    }

    case ArticleAction.CREATE.ARTICLE: {
      const { articleId } = action;
      return {
        ...state,
        [articleId]: {
          articleId: articleId
        }
      }
    }

    case ArticlesAction.LATEST.SUCCESS:
    case ArticlesAction.LOAD.SUCCESS: {
      const newState = { ...state };
      const { articles = [] } = action;
      articles.forEach(article => {
        newState[article.articleId] = { ...newState[article.articleId], ...article }
      })
      return newState;
    }
    case ArticlesAction.UPDATE.REQUEST: {
      const { articleId } = action;
      state[articleId] = { ...state[articleId], isLoadingPreviewArticle: true, containedDataForPreview: false }
      return { ...state }
    }
    case ArticlesAction.UPDATE.FAILURE: {
      const { articleId } = action;
      state[articleId] = { ...state[articleId], isLoadingPreviewArticle: false, containedDataForPreview: false }
      return { ...state }
    }
    case ArticlesAction.UPDATE.SUCCESS: {
      const { articleId, result } = action;
      state[articleId] = { ...state[articleId], ...result, isLoadingPreviewArticle: false, containedDataForPreview: true }
      return { ...state }
    }

    //article's action
    case ArticleAction.LOAD.ARTICLE_DETAILS.SUCCESS: {
      const { articleId, articleDetails } = action;
      return {
        ...state,
        [articleId]: {
          ...state[articleId],
          ...articleDetails,
          containsFullInfo: true
        }
      }
    }

    case ArticleAction.LOAD.ARTICLE_CONTENTS.SUCCESS: {
      let { articleId, content } = action;
      const articleDetails = state[articleId] || {};
      const { contentType } = articleDetails;

      //check if submittable

      //move this function in utils
      const allowSubmission = isContentSubmittable(contentType, content);

      //convert Gallery item
      if (contentType === ArticleTypes.GALLERY) {
        if (content === "") {
          content = [];
        } else {
          content = JSON.parse(content);
        }
        content = content.map((item, order) => new GalleryItem({ ...item, order: order }));
      }

      return {
        ...state,
        [articleId]: {
          ...state[articleId],
          content: content,
          allowSubmission: allowSubmission
        }
      }


    }

    case ArticleAction.UPLOAD.COVER_PICTURE.REQUEST: {
      return {
        ...state,
        [action.articleId]: {
          ...state[action.articleId],
          uploadingCoverPicture: true
        }
      }
    }
    case ArticleAction.UPLOAD.COVER_PICTURE.FAILURE: {
      return {
        ...state,
        [action.articleId]: {
          ...state[action.articleId],
          uploadingCoverPicture: false
        }
      }
    }
    case ArticleAction.UPLOAD.COVER_PICTURE.SUCCESS: {
      const { coverPicture, coverPictureSmall, imgExtension } = action.coverPictureDetails.result;
      return {
        ...state,
        [action.articleId]: {
          ...state[action.articleId],
          coverPicture: coverPicture,
          coverPictureSmall: coverPictureSmall,
          imgExtension: imgExtension,
          uploadingCoverPicture: false
        }
      }
    }
    case ArticleAction.SET.VIDEO_HEADER:
    case ArticleAction.SET.ARTICLE_TITLE:
    case ArticleAction.SET.ARTICLE_SUMMARY:
    // case ArticleAction.SET.SHARE_TYPE:
    case ArticleAction.SET.CATEGORY_TYPE:
    case ArticleAction.SET.SUB_CATEGORY_TYPE:
    // case ArticleAction.SET.STORY_TYPE:
    // case ArticleAction.SET.DISCLAIMER_TYPE:
    case ArticleAction.SET.SEO_TITLE:
    case ArticleAction.SET.OG_TITLE:
    case ArticleAction.SET.POST_CAPTION:
    case ArticleAction.SET.CUSTOM_URL:
    // case ArticleAction.SET.ARTICLE_TYPE:
    // case ArticleAction.SET.TIME_SPECIFIC:
    // case ArticleAction.SET.AUTHOR_NAME:
    case ArticleAction.SET.META_TAGS:
    case ArticleAction.SET.META_SUB_TAGS:
    case ArticleAction.SET.CONTENTS:
    case ArticleAction.SET.ARTICLE_LOCK_DATA:
    case ArticleAction.SET.ARTICLE_ADS_DISABLE:
      // case ArticleAction.APPEND.NEW_ITEM:
      // case ArticleAction.SUBMIT.SUCCESS:

      // case ArticleAction.LOAD.ARTICLE_STORY_TYPE.SUCCESS:
      // case ArticleAction.LOAD.ARTICLE_CATEGORIES.SUCCESS:
      {
        const { articleId } = action;
        return {
          ...state,
          [articleId]: {
            ...state[articleId],
            ...getArticleMetaData(state[articleId], action),
          }
        }
      }

    //start new story actions
    // may not need this, we can solve this by
    //ArticleAction.LOAD.ARTICLE_CONTENTS.SUCCESS
    case NewStoryAction.SET.EDITOR.INITIAL_STATE: {
      const { editorContent, articleId } = action;
      return {
        ...state,
        [articleId]: {
          ...state[articleId],
          contents: editorContent, //rename editorContents to contents
        }
      }
    }
    //end new story actions

    //deleting article end
    case ArticleAction.DELETE.SUCCESS: {
      const newState = {};
      Object.keys(state).forEach(id => {
        if (id !== action.articleId) {
          newState[id] = state[id];
        }
      })
      return newState;
    }
    //deleting article end


    //clearing article inReview
    case ArticleAction.APPROVE.SUCCESS:
    case ArticleAction.PUBLISH.SUCCESS: {
      const { articleId } = action;
      const newState = { ...state };
      newState[articleId] = { ...newState[articleId], inReviewBy: null }
      return newState;
    }
    default: return state;
  }
}

const getArticleReducersByState = (articleState) => combineReducers({
  articlesIds: articleIdsWithNamedType(articleState),
  articlesSettings: articleSettingsWithNamedType(articleState)
});

const articles = combineReducers({
  articlesSettings,
  articlesByIds,
  draftArticles: getArticleReducersByState(ArticlesState.DRAFT_ARTICLES),
  submittedArticles: getArticleReducersByState(ArticlesState.SUBMITTED_ARTICLES),
  approvedArticles: getArticleReducersByState(ArticlesState.APPROVED_ARTICLES),
  queuedArticles: getArticleReducersByState(ArticlesState.QUEUED_ARTICLES),
  liveArticles: getArticleReducersByState(ArticlesState.LIVE_ARTICLES)
});

export default articles;
