import React, { PureComponent } from 'react';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import withStyles from "@material-ui/core/styles/withStyles";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from "@material-ui/icons/Edit";
import Button from '@material-ui/core/Button';
import CustomButton from "components/CustomButtons/Button";
import articlesStyle from "../../../assets/jss/material-dashboard-pro-react/views/articlesStyle.jsx";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Articles from "../views/articles";
import AppLoader from "../../../app-components/app-loader.jsx";
import Divider from "@material-ui/core/Divider";
import ConfirmationBox from "../../../app-components/confirmation-box";


class FeedCard extends React.Component {

  state = {
    show: false,
    articles: [],
    showSide:false,
    editArticle: null,
    type: 'edit',
    showLoader: false,
    deleteConfirmation: false
  }

  deleteArticle = (article) => {
    this.setState({ showLoader: true });
    const pickedArticles = this.state.articles.filter(item => item.articleId != article.articleId);
    let new_picks = pickedArticles.map(article => {
      return {
        articleId: article.articleId,
        articleName: article.articleName,
        customHeadline: article.customHeadline || '',
      }
    });
    this.props.updateFeedPicks(this.props.feed.pickName, new_picks, false).then(res => {
      this.setState({ articles: pickedArticles });
    }).finally(() => { this.setState({showLoader:false})})
  }

  changeArticle = (article) => {
    this.setState({ editArticle: article, showLoader: true });
    this.props.getLatestArticles();
    this.setState((prev) => {
      return {
        showSide: !prev.showSide,
        type:'edit'
      }
    });
  }

  hideSidePan = () => {
    this.setState({ showSide: false, showLoader: false });
  }
  addNewArticle = () => {
    this.setState({showLoader: true});
    this.props.getLatestArticles();
    this.setState((prev) => {
      return {
        showSide: !prev.showSide,
        type: 'add'
      }
    });
  }

  selectNewArticle = (newArticle, articleHeadline='') => {
    try {
      let { articles, editArticle, type } = this.state;
      if (articleHeadline) newArticle.customHeadline = articleHeadline.trim();
      let new_picks = articles.map(article => {
        return {
          articleId: article.articleId,
          articleName: article.articleName,
          customHeadline: article.customHeadline || '',
        }
      });
      let picks = articles.map(item => item.articleId);
      let alreadyExist = false;

      if (!alreadyExist) {
        if (type == 'edit') {
          articles = articles.map(item => {
            if (item.articleId == editArticle.articleId) item = newArticle;
            return item;
          });
        }
        if (type == 'add') {
          articles.unshift(newArticle);
        }
        
        new_picks = articles.map((item) => {
          let temp = {
            articleId: item.articleId,
            articleName: item.articleName,
          };
          if (item.customHeadline) temp.customHeadline = item.customHeadline;
          return temp;
        });
        picks = articles.map(item => item.articleId);
      }
      this.props.updateFeedPicks(this.props.feed.pickName, new_picks, alreadyExist).then(res => {
        this.setState((prev) => {
          return {
            editArticle: null,
            showSide: !prev.showSide,
            articles,
          }
        });
      }).catch(err => console.log).finally(() => {
        this.setState({ showLoader: false });
      });
    } catch (e) {
      this.setState((prev) => {
        return {
          editArticle: null,
          showSide: !prev.showSide,
        }
      });
    }
  }

  showDeleteConfirmationBox = () => {
    this.toggleConfirmationBox();
  }

  deleteCustomFeed = () => {
    this.props.deleteCustomFeeds(this.props.feed.pickName).finally(() => {
      this.toggleConfirmationBox();
    });
  }

  toggleConfirmationBox = () => {
    this.setState(prev => {
      return {
        deleteConfirmation: !prev.deleteConfirmation
      }
    })
  }

  handleChange = () => {
    if (this.state.show) {
      this.setState((prev) => { return { show: !prev.show } });
      return null
    };
    this.setState((prev) => {
      return {
        show: !prev.show,
        showLoader: true
      }
    });
    this.props.getFeedArticles(this.props.feed.pickName).then(articles => {
      this.setState({ articles });
    }).catch(error => console.log).finally(() => {
      this.setState({ showLoader: false });
    });
  }

  componentWillUnmount() {
    this.setState({ articles: [] });
  }
  render() {
    const { feed, classes, key } = this.props;
    const { articles, showLoader } = this.state;
    return (
      <>
      <ExpansionPanel expanded={this.state.show} onChange={this.handleChange} className={classes.expansionContainer} style={{boxShadow:'none'}}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.articleTitle}>{feed.title || feed.pickName}</Typography>
        </ExpansionPanelSummary>
        <Divider
          style={{
            height: "1px",
            backgroundColor: '#ced3dc',
            margin: '0 auto',
            width: '95%',
          }} />
          {this.state.show && <ExpansionPanelDetails>
            <div style={{ width: "100%", justifyItems: "center" }}>
              <GridContainer style={{ color: "#2b2d42" }}>
                <GridItem>
                  <div onClick={this.addNewArticle} style={{ display: "flex", alignItems: "center", cursor: "pointer", margin: "20px 0 20px" }}>
                    <AddCircleIcon style={{ color: "#2b2d42" }}/>&nbsp;&nbsp;<span style={{ color: "#2b2d42" }}>Add Article</span>
                  </div>
                </GridItem>
              </GridContainer>
              <div style={{ maxHeight: `${articles.length ? '300px' :'0px'}`, overflow: "auto",  }}>
                {showLoader && <AppLoader size={25} />}
                {
                  articles.map((item, index) => {
                    return (
                      <GridContainer justify="center" key={index} style={{width:"98%", margin:'20px auto'}} className={classes.articleContainer}>
                        <GridItem xs={8} sm={10} md={10}>
                          <h5 className={classes.articleTitle}>
                            {item.title}
                          </h5>
                          {item.customHeadline && <span>
                            {item.customHeadline}
                          </span>}
                        </GridItem>
                        <GridItem xs={4} sm={2} md={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Button style={{ color: '#2b2d42' }} onClick={this.changeArticle.bind(this, item)}>
                            <EditIcon />
                          </Button>
                          <Button style={{ color: '#2b2d42' }} onClick={this.deleteArticle.bind(this, item)}>
                            <DeleteIcon />
                          </Button>
                        </GridItem>
                      </GridContainer>
                    );
                  })
                }
              </div>
              <Articles showSide={this.state.showSide} hideSidePan={this.hideSidePan} selectNewArticle={this.selectNewArticle} />
            </div>
          </ExpansionPanelDetails>}
        <Divider  style={{ height:"1px", backgroundColor:"#80808091"}} />
        <ExpansionPanelActions style={{ justifyContent:"flex-start"}}>
          <CustomButton color="secondary" onClick={this.showDeleteConfirmationBox}>Delete Feed</CustomButton>
          </ExpansionPanelActions>
        </ExpansionPanel>
        <ConfirmationBox
          showConfirmationModal={this.state.deleteConfirmation}
          toggleConfirmationBox={this.toggleConfirmationBox}
          confirmationMessage="Are you sure, you want to delete the feed."
          onConfirm={this.deleteCustomFeed}
        />
        </>
    )
  }
}

export default withStyles(articlesStyle)(FeedCard);
