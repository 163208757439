
// pages reducer
import { combineReducers } from "redux";
import {
  pagesAction, UserAction
} from "../../app-constants/index.js";


const defaultPageState = {

};
const defPage = [];
const defaultPagesSetting = {
  id: null,
  isShowPiscoModal: false,
  isLoading: false,
  isDefaultPage: false,
  isCreating: false,
  isAction: false
}
const page = (state = defaultPageState, action) => {

  switch (action.type) {
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return defaultPageState
    }

    case pagesAction.LOAD.SUCCESS: {
      const { content } = action;
      const newObj = content.reduce((a, b) => {
        a[b.id] = b;
        return a;
      }, {})
      return { ...state, ...newObj }

    }
    case pagesAction.LOAD.REQUEST: {
      return {}
    }

    case pagesAction.CREATE.SUCCESS: {
      const { id } = action;
      const newPage = {
        [id]: {
          title: null,
          pageContent: null,
          id,
          slug: null,
          status: 0
        }
      };
      return { ...state, ...newPage }
    }

    case pagesAction.UPDATE.TITLE: {
      const { id, content } = action;
      try {
        state[id].title = content.title
      }
      catch (e) {
      }
      return { ...state }
    }

    default: return state
  }
}

const pageSetting = (state = defaultPagesSetting, action) => {
  switch (action.type) {
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return defaultPagesSetting
    }
    case pagesAction.SET.PageId: {
      const { id } = action;
      return { ...state, id: id }
    }
    case pagesAction.SET.PAGE_CONTENT: {
      const { content } = action
      return { ...state, content }
    }
    case pagesAction.CREATE.REQUEST: {
      return { ...state, isCreating: true }
    }
    case pagesAction.CREATE.FAILURE: {
      return { ...state, isCreating: false }
    }
    case pagesAction.CREATE.SUCCESS: {
      return { ...state, isCreating: false }
    }
    case pagesAction.SET.TINYMC_CONTENT: {
      const { id, content } = action
      return { ...state, id, ...content }
    }
    case pagesAction.PISCO.SHOW: {
      return { ...state, isShowPiscoModal: true }
    }
    case pagesAction.PISCO.HIDE: {
      return { ...state, isShowPiscoModal: false }
    }
    case pagesAction.SET.PISCO_CONTENT: {
      const { content } = action
      return { ...state, ['piscoContent']: content }
    }
    case pagesAction.PISCO.CLEAR: {
      return { ...state, ['piscoContent']: {} }
    }
    case pagesAction.LOAD.REQUEST: {
      return { ...state, isLoading: true }
    }
    case pagesAction.LOAD.FAILURE: {
      return { ...state, isLoading: false }
    }
    case pagesAction.LOAD.SUCCESS: {
      return { ...state, isLoading: false }

    }
    case pagesAction.DEFAULT.SET_DEFAULT_PAGE: {
      return { ...state, isDefaultPage: true }
    }
    case pagesAction.DEFAULT.RESET_DEFAULT_PAGE: {
      return { ...state, isDefaultPage: false }
    }
    case pagesAction.ACTION.REQUEST: {
      return { ...state, isAction: true }
    }
    case pagesAction.ACTION.FAILURE: {
      return { ...state, isAction: false }
    }
    case pagesAction.ACTION.SUCCESS: {
      return { ...state, isAction: false }
    }
    default: return state
  }
}
const defaultPage = (state = defPage, action) => {
  switch (action.type) {
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return defPage
    }
    case pagesAction.DEFAULT.SET: {
      return { ...state, defaultPage: action.content }
    }
    default: return state
  }
}
const pages = combineReducers({
  page,
  pageSetting,
  defaultPage
})
export default pages;
