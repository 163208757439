import React from "react";
import { connect } from "react-redux";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Divider from '@material-ui/core/Divider';

import ManageStories from '../components/manage-stories.jsx';

import {
  saveMediaToPisco,
  addNewStory,
  getStories,
  deleteStory,
  InvalidFileError,
} from '../quick-cms-actions.js';

class StoriesView extends React.Component {

  componentDidMount(){
      //this.props.getStoryList();
      this.props.getStories('initial');
  }

  render() {
    const { stories } = this.props;
    return (
      <>
        <GridContainer>
          <GridItem  md={12} xs={12}>
            <h5 style={{fontWeight: 'bold'}}>STORIES</h5>
          </GridItem>
        </GridContainer>
        <ManageStories
          creatingStory={this.props.creatingContent}
          saveMediaToPisco={this.props.saveMediaToPisco}
          storiesList={stories}
          addNewStory={this.props.addNewStory}
          deleteStory={this.props.deleteStory}
          isDeletingStory={this.props.isDeletingStory}
          InvalidFileError={this.props.InvalidFileError}
        />
      </>
    )
  }
}


const mapStateToProps = (state) => {
  const {
    quickCms: {
      creatingContent,
      stories,
      isDeletingStory,
    }
  } = state.cms;
  return {
    creatingContent,
    stories,
    isDeletingStory,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getStories: (param) => dispatch(getStories(param)),
    saveMediaToPisco: (file, cb) => dispatch(saveMediaToPisco(file, cb)),
    addNewStory: (content, cropData, heading, linkAction, cb) => dispatch(addNewStory(content, cropData, heading, linkAction, cb)),
    deleteStory: (id) => dispatch(deleteStory(id)),
    InvalidFileError: (msg) => dispatch(InvalidFileError(msg)),
 }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoriesView);
