const UserPermisssionAction = {
  FETCH: {
    PREMISSION: {
      REQUEST: "UserPermisssion.Fetch.Permission.Request",
      FAILURE: "UserPermisssion.Fetch.Permission.Failure",
      SUCCESS: "UserPermisssion.Fetch.Permission.Success",
    }
  },
  UPDATE: {
    PREMISSION: {
      REQUEST: "UserPermisssion.Update.Permission.Request",
      FAILURE: "UserPermisssion.Update.Permission.Failure",
      SUCCESS: "UserPermisssion.Update.Permission.Success",
    }
  }
}
export default UserPermisssionAction;