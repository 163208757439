import React from "react";
/**
 *
 * @param {*} component
 */

export default function withFileUpload(MainComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
    }
    _handleClick = () => {
      this.refs.fileField.click();
    }
    _onUpload = (event) => {
      this.props.onChange(event.target, this.props.type);
      event.target.value = null
    }
    render() {
      return (
        <>
          <span onClick={this._handleClick} className='custom-file-upload'>
            <MainComponent {...this.props} />
            <input ref="fileField" accept={this.props.type || 'image/*'} onChange={this._onUpload} id="file-upload" type="file" style={{  display:'none' }} />
          </span>
        </>
      )
    }
  }
}

