import React from "react";
const NoDataFound = ({ dataTypeName = "", pageNo = 1, fontSize = '2rem' }) => {
  let addMoreText = "";
  if (pageNo >= 1) {
    addMoreText = "more"
  }
  return (
    <div style={{ textAlign: "center", marginTop: '200px' }}>
      <h3 style={{ fontSize: `${fontSize}` }}>{`No ${addMoreText} ${dataTypeName} found`}</h3>
    </div>
  )
}
export default NoDataFound;
