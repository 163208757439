
const RoundIconStyle = {
    wrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "24px",
        height: "24px",
        borderRadius: "50px",
        cursor: "default",

    },
    iconText: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "12px",
        fontWeight: "500",

    }
}
export default RoundIconStyle;