import React from 'react';
import {connect} from 'react-redux';

import withStyles from "@material-ui/core/styles/withStyles";
import articlesStyle from "../../../assets/jss/material-dashboard-pro-react/views/articlesStyle.jsx";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from "@material-ui/core/Divider";
import Switch from "@material-ui/core/Switch";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import AppLoader from "app-components/app-loader.jsx";

import FeatureSelection from '../components/feature-selection.jsx';
import DetailsForm from '../components/details-form.jsx';
import {
  submitAccountInfo,
  submitOtp,
  openOtpModal,
  closeOtpModal,
  generateOtp,
  getBlacklistedBanks,
  fetchFeatures,
  setFeatures,
  submitAgreement,
  getBankDetails,
  getAdsStatus,
  enableAds,
} from '../account-info-actions.js';
import { fetchFaviconLogo } from '../../../website-management/website-management-actions.js';

class AccountInfo extends React.Component {
  state = {
    bankInfo: false
  }

  componentDidMount() {
    this.props.fetchFeatures();
    this.props.getAdsStatus();
    this.props.fetchFaviconLogo();
  }

  handleChange = (field) => {
    this.setState((prev) => ({
      [field]: !prev[field],
    }));
  }

  handleAdChange = (event) => {
    this.props.enableAds(event.target.checked);
  }
  getDomainName = () => {
    const { domainMap, activeDomain, siteInfo } = this.props;
    if (siteInfo && siteInfo.title) {
      return siteInfo.title;
    }
    let domainName = '', subDomain = '', domain = '', fqdn = '';
    try {
      subDomain = domainMap[activeDomain][0]['subDomain'];
      domain = domainMap[activeDomain]['domainName'];
      fqdn = domainMap[activeDomain]['fqdn'];
    }
    catch (err) {
      subDomain = '';
      domain = '';
      fqdn = '';
    }
    if (domain === 'ayushgarg' || 'pubninja')
      domainName = subDomain;
    else
      domainName = domain;
    return domainName;
  }
  render() {
    const {classes} = this.props;
    if(this.props.isFetchingFeatures || this.props.isLoadingFaviconLogo || !this.getDomainName()) {
      return <AppLoader />
    }
    return (
      <div>
        <GridContainer style={{ marginBottom:'1em'}}>
          <GridItem xs={12} md={4}>
            <div style={{ fontWeight: 'bold', fontSize: '1.2em', marginTop: '12px' }}>Enable Ads for Website</div>
            <p
              style={{
                textAlign: 'left',
                fontSize: '14px',
                marginBottom: '0px',
                color: 'rgb(153, 163, 180)'
              }}
            >
              This will start to show ads to website visitors. (Eg: Google Ads)
            </p>
          </GridItem>
          <GridItem xs={12} md={3}>
            {!!this.props.isFetchingAdsStatus ? <AppLoader size={20}/> : <><span >No</span>
              <Switch
                checked={!!this.props.adsEnabled}
                onChange={this.handleAdChange}
                color='primary' />
              <span >Yes</span></>}
          </GridItem>
        </GridContainer> 
        <FeatureSelection
          fetchFeatures={this.props.fetchFeatures}
          features={this.props.features}
          isFetchingFeatures={this.props.isFetchingFeatures}
          isSettingFeatures={this.props.isSettingFeatures}
          setFeatures={this.props.setFeatures}
          subscriptionEnabled={this.props.subscriptionEnabled}
          domainName={this.getDomainName()}
        />
        <hr/>
        <ExpansionPanel expanded={this.state.bankInfo} onChange={() => { this.handleChange('bankInfo') }} className={classes.expansionContainer}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.column}>
              <Typography className={classes.articleTitle} style={{ textTransform:"uppercase", paddingLeft:'0px'}}>
              Bank Account Information
              </Typography>
              <Typography className={classes.secondaryHeading}>
                Add/View your Bank Account Information here.
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <Divider
            style={{
              height: "1px",
              backgroundColor: '#ced3dc',
              margin: '0 auto',
              width: '95%',
            }} />
          {
            this.state.bankInfo
            &&
            <ExpansionPanelDetails>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12} >
                  <DetailsForm
                    emailId={this.props.email}
                    submitAccountInfo={this.props.submitAccountInfo}
                    isSubmittingInfo={this.props.isSubmittingInfo}
                    submitOtp={this.props.submitOtp}
                    isSubmittingOtp={this.props.isSubmittingOtp}
                    showOtpModal={this.props.showOtpModal}
                    openOtpModal={this.props.openOtpModal}
                    closeOtpModal={this.props.closeOtpModal}
                    generateOtp={this.props.generateOtp}
                    otpError={this.props.otpError}
                    otpVerified={this.props.otpVerified}
                    getBlacklistedBanks={this.props.getBlacklistedBanks}
                    blacklistedBanks={this.props.blacklistedBanks}
                    agreementHtml={this.props.agreementHtml}
                    submittingAgreement={this.props.submittingAgreement}
                    submitAgreement={this.props.submitAgreement}
                    agreementSubmitted={this.props.agreementSubmitted}
                    getBankDetails={this.props.getBankDetails}
                    fetchingBankDetails={this.props.fetchingBankDetails}
                    bankDetails={this.props.bankDetails}
                  />
                </GridItem>
              </GridContainer>
            </ExpansionPanelDetails>
          }
        </ExpansionPanel>

      </div>
    );
  }

}

const mapStateToProps = (state) => {
  const {
    login: {
      user: {
        email,
        activeDomain,
        domainMap,
      }
    },
    analysis: {
      accountInfo: {
        isSubmittingInfo,
        isSubmittingOtp,
        showOtpModal,
        otpError,
        otpVerified,
        blacklistedBanks,
        features,
        isFetchingFeatures,
        isSettingFeatures,
        agreementHtml,
        submittingAgreement,
        agreementSubmitted,
        bankDetails,
        fetchingBankDetails,
        subscriptionEnabled,
        adsEnabled,
        isFetchingAdsStatus
      }
    },
    websiteManagementReducer: {
      siteInfo,
      faviconLogoReducer: {
        isLoadingFaviconLogo
      },
    },
  } = state;
  return {
    email,
    activeDomain,
    domainMap,
    siteInfo,
    isLoadingFaviconLogo,
    isSubmittingInfo,
    isSubmittingOtp,
    showOtpModal,
    otpError,
    otpVerified,
    blacklistedBanks,
    features,
    isFetchingFeatures,
    isSettingFeatures,
    agreementHtml,
    submittingAgreement,
    agreementSubmitted,
    bankDetails,
    fetchingBankDetails,
    subscriptionEnabled,
    adsEnabled,
    isFetchingAdsStatus,
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
      submitAccountInfo: (data) => dispatch(submitAccountInfo(data)),
      submitOtp: (otp, phoneNumber) => dispatch(submitOtp(otp, phoneNumber)),
      openOtpModal: () => dispatch(openOtpModal()),
      closeOtpModal: () => dispatch(closeOtpModal()),
      generateOtp: (phoneNumber) => dispatch(generateOtp(phoneNumber)),
      submitOtp: (otp, phoneNumber) => dispatch(submitOtp(otp, phoneNumber)),
      getBlacklistedBanks: () => dispatch(getBlacklistedBanks()),
      //FeatureSelection
      fetchFeatures: () => dispatch(fetchFeatures()),
      setFeatures: (data) => dispatch(setFeatures(data)),
      submitAgreement: () => dispatch(submitAgreement()),
      getBankDetails: () => dispatch(getBankDetails()),
      getAdsStatus: () => dispatch(getAdsStatus()),
      enableAds: (value) => dispatch(enableAds(value)),
      fetchFaviconLogo: () => dispatch(fetchFaviconLogo()),
    }
}

export default withStyles(articlesStyle)(connect(mapStateToProps, mapDispatchToProps)(AccountInfo));
