
const EditerPickAction = {
  LIST: {
    REQUEST: "CustomFeed.List.Request",
    SUCCESS: "CustomFeed.List.Success",
    FAILURE: "CustomFeed.List.Failure"
  },
  LOAD: {
    REQUEST: "EditerPickAction.Load.Request",
    SUCCESS: "EditerPickAction.Load.Success",
    FAILURE: "EditerPickAction.Load.Failure",
  },
  LOADARTICLE: {
    REQUEST: "EditerPickAction.LoadArticle.Request",
    SUCCESS: "EditerPickAction.LoadArticle.Success",
    FAILURE: "EditerPickAction.LoadArticle.Failure",
  },
  UPDATE: {
    SETID: "EditerPickAction.Update.SetId",
    EDITERPICK: "EditerPickAction.Update.EditerPick"
  },
  ARTICLESIDEPANE: {
    TOGGLE: 'EditerPickAction.ShowSidePane',
  }
}
export default EditerPickAction;
