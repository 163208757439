import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";


class WordpressImportPage extends PureComponent {
  render() {
    const {} = this.props;
    return (<div>
      <Helmet title="WordPress Import | PubNinja - You Publish. The rest we manage." />
      <Grid item xs={12}>
          <Paper style={{ height: "100px", marginTop: "30px" }}>
          <TextField
          type="file"
          id="standard-full-width"
          //label="Add New Domain"
          style={{ margin: 8, padding: '25px', width:"75%" }}
          placeholder=""
          //helperText="Full width!"
          //fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
            />
            <Button variant="contained" color="primary" style={{ margin: '30px', marginLeft:"50px" }}>
                Upload File
            </Button>
          </Paper>
        </Grid>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WordpressImportPage);
