import React, { PureComponent } from "react"
import throttle from "lodash/throttle";
import TextField from '@material-ui/core/TextField';


class ArticleOgTitle extends PureComponent {
  constructor(props) {
    super(props);
    this.setChangeValue = throttle(this._setChangeValue, 100);
    this.textArea = React.createRef();
    this.state = { length: 0, ogTitle: props.ogTitle || "" };
  }

  componentDidMount() {
    const { defaultValue } = this.props;
    let length = 0;
    if (defaultValue) {
      length = defaultValue.length;
    }
    this.setState({ length });
  }
  _setChangeValue = (e) => {
    this.props.setOgTitle(e.target.value, e.type);
  }
  onChange = (e) => {
    e.persist();
    this.setState({ length: e.target.value.length, ogTitle: e.target.value });
    this.setChangeValue(e);
  }
  render() {
    const { ogTitle } = this.state;

    return (
      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <TextField
          id="standard-name"
          label="Facebook Post Caption"
          value={ogTitle}
          onChange={this.onChange}
          onBlur={this.onChange}
          InputProps={{
            style: {
              border:"1px solid #ced3dc",
              width: "93%",
            }
          }}
          inputProps={{ maxLength: 300 }}
          multiline={true}
          fullWidth
          margin="normal"
        /><p>{`${ogTitle && ogTitle.length || 0}/100`}</p>
      </div>
    )
  }
}

export default ArticleOgTitle;
