const RevenueAction = {
    LOAD: {
        FIXED_TIME_SPECIFIC_REVENUES: {
            REQUEST: "RevenueAction.Load.FiexdTimeSpecificRevenues.Request",
            FAILURE: "RevenueAction.Load.FiexdTimeSpecificRevenues.Failure",
            SUCCESS: "RevenueAction.Load.FiexdTimeSpecificRevenues.Success",
        },
        VARIABLE_TIME_SPECIFIC_REVENUES: {
            REQUEST: "RevenueAction.Load.VariableTimeSpecificRevenues.Request",
            FAILURE: "RevenueAction.Load.VariableTimeSpecificRevenues.Failure",
            SUCCESS: "RevenueAction.Load.VariableTimeSpecificRevenues.Success",
        }
    },
    SET: {
        VARIABLE_TIME_SPECIFIC_REVENUES: {
            DATE_RANGE: "RevenueAction.Set.VariableTimeSpecificRevenues.DaateRange"
        }
    }
}

export default RevenueAction;