
import { MeawwApi } from "../../utils/api.js";
import { setAppMessage } from "../../app-actions.js";
import { MessageTypes } from "../../app-constants/index.js";
import { EditerPickAction } from "../../app-constants/index.js";


function EditerPickActionRequest() {
  return {
    type: EditerPickAction.LOAD.REQUEST
  }
}
function EditerPickActionFailure() {
  return {
    type: EditerPickAction.LOAD.FAILURE
  }
}
function EditerPickActionSuccess(editerPick) {
  return {
    type: EditerPickAction.LOAD.SUCCESS,
    editerPick
  }
}
function EditerPickActionArticleRequest() {
  return {
    type: EditerPickAction.LOADARTICLE.REQUEST
  }
}
function EditerPickActionArticleFailure() {
  return {
    type: EditerPickAction.LOADARTICLE.FAILURE
  }
}
function EditerPickActionArticleSuccess(articles) {
  return {
    type: EditerPickAction.LOADARTICLE.SUCCESS,
    articles
  }
}

function feedsListSuccess(feedsList) {
  return {
    type: EditerPickAction.LIST.SUCCESS,
    feedsList
  }
}

export default function getFeedsList() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    dispatch(EditerPickActionRequest())
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/article/${activeDomain}/picks`,
          (error, data) => {
            if (error) {
              dispatch(EditerPickActionFailure())
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            dispatch(feedsListSuccess(data.result))
            return Promise.resolve(data.result);
          });
      })
  }
}

export function getFeedArticles(feedName) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/article/${activeDomain}/picks/${feedName}`, (error, data) => {
          if (error) return Promise.reject(error);
          return Promise.resolve(data.result);
        })
      })
  }
}

export function updateFeedPicks(feedName, picks, alreadyExist = false) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    if (alreadyExist) {
      dispatch(setAppMessage('Article already in feed', { type: MessageTypes.ERROR }));
      return Promise.resolve('Article already in feed');
    }
    return MeawwApi()
      .auth()
      .then(api => {
        return api.put(`/article/${activeDomain}/picks/${feedName}`,
          {
            picks,
          }, (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            setTimeout(() => {
              return Promise.resolve(data.result);
            }, 2000)

          })
      });
  }
}

export function getArticles(searchKeyword, category, subcategory, author, liveDate) {
  return (dispatch, getState) => {
    const { login: { user: { activeDomain } } } = getState();
    dispatch(EditerPickActionArticleRequest())
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/article/${activeDomain}/search`, {
          "searchQuery": searchKeyword,
          category,
          subcategory,
          author,
          liveDate,
          "page": 1,
          "perPage": 100
        },
          (error, data) => {
            if (error) {
              dispatch(EditerPickActionArticleFailure())
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }))
              return Promise.reject(error);
            }
            dispatch(EditerPickActionArticleSuccess(data.result))
            return Promise.resolve();
          });
      });
  }
}
export function createNewFeed(feedTitle) {
  return (dispatch, getState) => {
    const { tool, login: { user: { activeDomain } } } = getState();
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/article/${activeDomain}/picks/`, { title: feedTitle }, (error, data) => {
          if (error) {
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
            return Promise.reject(error);
          }
          dispatch(setAppMessage('Feed Created Successfully', { type: MessageTypes.SUCCESS }));
          return Promise.resolve(data.result);
        })
      });
  }
}
export function getLatestArticles() {
  return (dispatch, getState) => {
    const { tool, login: { user: { activeDomain } } } = getState();
    dispatch(EditerPickActionArticleRequest())
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/article/${activeDomain}?live&page=1&perPage=100`,
          (error, data) => {
            if (error) {
              dispatch(EditerPickActionArticleFailure())
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }))
              return Promise.reject(error);
            }
            dispatch(EditerPickActionArticleSuccess(data.result))
            return Promise.resolve();
          });
      });
  }
}

export function deleteCustomFeeds(feedName) {
  return (dispatch, getState) => {
    const { tool, login: { user: { activeDomain } } } = getState();
    return MeawwApi()
      .auth()
      .then(api => {
        return api.delete(`/article/${activeDomain}/picks/${feedName}`, (error, data) => {
          if (error) {
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
            return Promise.reject(error);
          }
          dispatch(setAppMessage('Feed Deleted Successfully', { type: MessageTypes.SUCCESS }));
          return Promise.resolve(data.result);
        })
      });
  }
}

export function showErrorMessage(msg) {
  return (dispatch, getState) => {
    dispatch(setAppMessage(msg, { type: MessageTypes.ERROR }));
    return Promise.resolve(msg);
  }
}
