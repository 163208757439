import React from 'react';

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Tooltip from '@material-ui/core/Tooltip';

class CategoryCard extends React.Component {
  state = {
    showForm: false,
    title: '',
    description: '',
    categoryVal: '',
    subcategory: '',
  }

  handleChange = (event, field) => {
    this.setState({ [field]: event.target.value });
  }
  setSubCategory = () => {
    const { category, activeIndex } = this.props;
    this.props.setYCategory(category, activeIndex);
  }

  render() {
    const { category, activeIndex, selectedIndex } = this.props;
    return (
      <div style={{border:"1px solid #ced3dc", marginBottom:'10px'}}>
        <div style={{ display: 'flex', alignItems: 'center' }}  >
          <p title="category" onClick={this.setSubCategory} style={{
            textTransform: 'capitalize',
            flexGrow: '10',
            fontSize: '16px',
            paddingLeft: '10px',
            fontWeight: '500',
            cursor: 'pointer',
            margin: '0px',
            paddingTop: '15px',
            paddingBottom: '15px',
            border: `${(selectedIndex === activeIndex) ? '1px solid #2b2d42' : 'none'}`
          }}>{category.categoryVal}</p>
          {/**style={{ background: `${(selectedIndex === activeIndex) ? '#fb4d24' : 'white'}` }} */}
          <div>
            <Button
              color="primary"
              onClick={() => { this.props.setCategoryModalData(category) }}
              style={{  padding: `14px`, border:'none' }}
            >
              <i className="material-icons" style={{ fontSize: '25px', paddingRight: '5px' }}>
                edit
          </i>
            </Button>
          </div>

        </div>
      </div>
    );
  }
}

export default CategoryCard;
