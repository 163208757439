import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import SidePane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Pisco from "pisco-ui";
import "pisco-ui/lib/styles.css";
import { setAvator, openAddLpModal } from '../landing-page-action.js';

class UploadProfileImage extends PureComponent {
  toggleModal = () => {
    this.props.lpType === 'ADD' ?
      this.props.openAddLpModal() :
      this.props.PASTECARDID()
    this.props.closePisco()
    this.props.setLpType(null)
  }
  render() {
    return (
      <Fragment>
        <SidePane
          overlayClassName='pisco-pane'
          isOpen={this.props.showPiscoModal}
          title='PISCO'
          subtitle='Media Search'
          from='right'
          width='80%'
          onRequestClose={this.toggleModal}
        >
          <Pisco
            endpoint={process.env.REACT_APP_API_SERVER}
            headers={{
              authorization: localStorage.getItem('accessToken')
            }
            }
            injectQuery={{ domainId: this.props.activeDomain }}
            disableGetty={this.props.domain !== 'meaww'}
            type={"Image"}
            mode="embed"
            onEmbed={content => {
              this.props.setAvator(content.cdn)
              this.toggleModal()
            }} />
        </SidePane>
      </Fragment>

    );
  }
}
const mapStateToProps = (state) => {
  const {
    login: { user },
    tool: { landingPages: { landingPageSetting: { type } } }
  } = state;
  return {
    activeDomain: user.activeDomain,
    type
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setAvator: (avator) => dispatch(setAvator(avator)),
    openAddLpModal: () => dispatch(openAddLpModal()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UploadProfileImage);
