import React, { Component } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//import Button from "@material-ui/core/Button";
import Button from "components/CustomButtons/Button";
import Hidden from '@material-ui/core/Hidden';
import MenuItem from '@material-ui/core/MenuItem';
import withConfirmation from "../../../app-components/confirmation-hoc";
import articlesStyle from "../../../assets/jss/material-dashboard-pro-react/views/articlesStyle.jsx";
const MenuItemWithConfirm = withConfirmation(MenuItem);

class DefaultPages extends Component {
  render() {
    const { page, redirectToTinyMceEditPage, setDefaultPage, addDefaultPage, doaminName, classes } = this.props;
    return (
      <GridContainer justify="center" style={{ width: '100%' }} className={classes.articleContainer}>
        <GridItem xs={12} sm={4} md={4}  >
          <h5 className={classes.articleTitle} style={{ paddingLeft: '16px', wordBreak: 'break-word' }}>
            <Hidden smUp><div>Title :</div></Hidden>
            {page.title}<br />
          </h5>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} >
          <h5 className={classes.articleTitle} style={{ paddingLeft: '16px', wordBreak: 'break-word' }}>
            <Hidden smUp><div>PagePath :</div></Hidden>
            <a href={`https://${doaminName}/page/${page.slug}`} target="_blank">{`/${page.slug}`}</a>
            <br />
          </h5>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} style={{ display: 'flex', alignItems: 'center' }}>
          {
            page.hasOwnProperty('status') ? <Button
              color="secondary"
              onClick={() => { setDefaultPage(); redirectToTinyMceEditPage(page.id, page); }}
            >
              EDIT
            {/* <i className="material-icons" style={{ fontSize: '25px', paddingRight: '5px' }}>
              edit
                  </i> */}
            </Button>
              : <Button
                color="secondary"
                onClick={() => { addDefaultPage(page.slug) }}
              >
                ADD
              {/* <i className="material-icons" style={{ fontSize: '25px', paddingRight: '5px' }}>
                add
                             </i> */}
              </Button>
          }
          {
            page.hasOwnProperty('status') &&
            <MenuItemWithConfirm onClick={() => { this.props.disablePage(page.id) }} msg={'Do you want to disable page?'} style={{ height: 'auto' }}>
              <Button
                color="primary"
              >
                DISABLE
              </Button>
            </MenuItemWithConfirm>
            // : <Button
            //   disabled
            //   style={{ color: '#fb4d24', padding: `14px` }}
            // >
            //   DISABLED
            // </Button>
          }
          {/* {page.hasOwnProperty('status') && <Button
            onClick={() => { this.props.publishPage(page.id) }}
            style={{ color: '#fb4d24', padding: `14px` }}
          >
            PUBLISH
          </Button>} */}
        </GridItem>
      </GridContainer >
    )
  }
}

export default withStyles(articlesStyle)(DefaultPages);
