import React from 'react';
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.jsx";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import AppLoader from "../../../app-components/app-loader.jsx";

class EntriesModal extends React.Component {
  state= {
    id: null,
    type: '',
    name: '',
    content: '',
    ttl: '',
    proxied: '',
    dummyName: '',
  }
  componentWillReceiveProps(nextProps){
      if(nextProps.entriesModalData && nextProps.entriesModalData.id !== this.state.id){
        this.setState(nextProps.entriesModalData);
      }
  }

  handleChange = (event, field) => {
    if(field !== 'name'){
      this.setState({[field]: event.target.value});
    }
    else {
      this.setState({ dummyName: event.target.value });
      let name = event.target.value;
      let hostName = this.props.hostName;

      let pattern1 = hostName;
      let pattern2 = '@';
      let pattern3 = new RegExp(hostName, "g");

      const condition = Array.isArray(name.match(new RegExp(pattern1))) || Array.isArray(name.match(new RegExp(pattern2)));
      //If value contains @ or hostname
      if(condition){
        //If value is just hostname or @, make value hostname.
        if(name === '@' || name === hostName){
          name = hostName;
        }
        else {
          //Append a DOT and hostname after rest of string.
          name = name.replace(/@/g, `${hostName}`);
          name = name.replace(pattern3, `.${hostName}`);
        }
      }
      //If value doesn't contain @ or hostname, and atleast one character is entered.
      else if(name.length > 0){
        name = name + '.' +hostName;
      }
      //Finally, set name to calculated value
      this.setState({ name });
    }
  }

  addDnsEntry = () => {
    this.props.addDnsEntry(this.state);
  }


  render(){
      const { classes, entriesmodalData, addingDnsEntry } = this.props;
      return (
        <>
          <GridContainer>
            <GridItem xs={12} md={12}>
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.modal
              }}
              open={this.props.showEntriesModal}
              aria-labelledby="modal-slide-title"
              aria-describedby="modal-slide-description">
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}>
                <Button
                  justIcon
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="transparent"
                  onClick={() => this.props.toggleentriesmodal(false)}
                >
                  <Close className={classes.modalClose} />
                </Button>
                <h4 className={classes.modalTitle}>Add DNS Entry</h4>
              </DialogTitle>
              <DialogContent
                id="modal-slide-description"
                className={classes.modalBody}
              >
                <div>{(this.state.name) && <>{this.state.name} will be the name of the entry.</>}</div>
                <CustomInput
                  id="name"
                  labelText="Name"
                  inputProps={{
                    onChange: (event)=>{this.handleChange(event, 'name')},
                  }}
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                  }}
                  value={this.state.dummyName}
                />
                <FormControl>
                  <InputLabel htmlFor="type-simple">Type</InputLabel>
                  <Select
                    value={this.state.type || 'Select'}
                    onChange= {(event)=>{this.handleChange(event, 'type')}}
                    inputProps={{
                      name: 'type',
                      id: 'type-simple',
                    }}
                  >
                    <MenuItem value="A">A</MenuItem>
                    <MenuItem value="AAAA">AAAA</MenuItem>
                    <MenuItem value="CNAME">CNAME</MenuItem>
                    <MenuItem value="TXT">TXT</MenuItem>
                    <MenuItem value="NS">NS</MenuItem>
                    <MenuItem value="SPF">SPF</MenuItem>
                    <MenuItem value="PTR">PTR</MenuItem>
                  </Select>
                </FormControl>
                <CustomInput
                  id="content"
                  labelText="Value"
                  inputProps={{
                    onChange: (event)=>{this.handleChange(event, 'content')}
                  }}
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                  }}
                  value={this.state.content}
                />
                <CustomInput
                  id="ttl"
                  labelText="TTL"
                  inputProps={{
                    onChange: (event)=>{this.handleChange(event, 'ttl')}
                  }}
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                  }}
                  value={this.state.ttl}
                />
                <FormControl component="fieldset">
                <FormLabel component="legend">Proxied</FormLabel>
                  <RadioGroup
                    aria-label="proxied"
                    name="Proxied"
                    value={this.state.proxied.toString()}
                    onChange={(event)=>{this.handleChange(event, 'proxied')}}
                    row
                  >
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </DialogContent>
              <DialogActions
                className={classes.modalFooter +" " +classes.modalFooterCenter}>
                <div style={{display: 'flex'}}>
                  <Button
                    onClick={this.addDnsEntry}
                    color='primary'
                    disabled={addingDnsEntry || (this.state.type && this.state.content && this.state.ttl && this.state.proxies)}
                  >
                    Submit
                  </Button>
                  <Button
                    color="secondary"
                  onClick={() => this.props.toggleentriesmodal(false)}
                  >
                  Close
                </Button>
                  {addingDnsEntry && <AppLoader size={20} /> }
                </div>
              </DialogActions>
            </Dialog>
            </GridItem>
          </GridContainer>
        </>
      );
    }
  }

export default withStyles(modalStyle)(EntriesModal);
