import "whatwg-fetch";
import { LocalStorageKeys, MessageTypes } from "../app-constants";
import { ServerError, APIError, NetworkError, NoAccessError } from "./error";
import { setAppMessage } from "../app-actions.js";
import { removePersistedData } from "../utils/utils.js";
import { build_version } from "../../package.json";

//console.log("API_SERVER=", process.env.REACT_APP_API_SERVER, process.env, process.env.REACT_APP_APP_VERSION);

let baseUrl = process.env.REACT_APP_API_SERVER || "https://dev1.pubninja.com";

//console.log("build_version", build_version);
// DEBUG: CONFIG
const traceFetch = false;

function objectStringify(obj) {
  if (!(typeof a === "function")) {
    return JSON.stringify(obj, null, " ");
  }
  return obj
}

function fetchImpl(path, config, fetchCb) {
  if (traceFetch) {
    //console.log(`FETCH: fetchImpl(${Object.keys(arguments).map(arg => objectStringify(arg)).join()})`)
  }
  return fetch(path, config).then((response) => {
    if (traceFetch) {
      //console.log("FETCH: response");
      //console.log(response);
    }
    const contentType = response.headers.get("Content-Type");
    if (contentType && contentType.indexOf("application/json") >= 0) {
      return response.json().then(data => {
        return { data: data, response: response };
      });
    } else {
      return response.text().then(data => {
        return { data: data, response: response };
      });
      // response.text().then(data => ({ data: data, response: response }));
      //add others type of content type
      //console.log("Does not match content type");
    }
    throw new NetworkError("Please connect to internet and try again.", path);

  }, (error) => {
    if (traceFetch) {
      //console.log("FETCH: error:");
      //console.log(error);
    }
    throw new NetworkError(`${error.message}, Please connect to internet and try again.`, path);
  }).then(({ data, response }) => {
    if (response.ok) {
      if (traceFetch) {
        //console.log(`FETCH: OK response (${response.status}), data:`);
        //console.log(data);
      }
      if (fetchCb) {
        return fetchCb(null, data);
      }
      return data;
    }

    let error;
    if (traceFetch) {
      //console.log(`FETCH: BAD response (${response.status})`);
    }

    //clearly identify what is the exact error code
    //and put this error into application arror  with setting optional params critical

    if (response.status === 403 || response.status === 401 || response.status === 404) {
      //throw to .catch below
      error = new NoAccessError(data.message || "You don't have access to this item", response.status, path);
    } else if (typeof data === "string") {
      // throw to .catch below
      error = new ServerError(response.statusText, response.status, path);
    } else {
      error = new APIError(data, response.status, path);
    }
    // if (response.status === 403) {
    //   window.location = "/landing";
    // }
    if (fetchCb) {
      //Build a response in the same format as API errors.
      return fetchCb(error, {
        // non_field_errors: [error.message.toString()]
      });
    }
    throw error;
  })
  // .catch((error) => {
  //   //Handle all the errors thrown above as well as bugs in above
  //   console.log(`FETCH: ${error}`);
  //   if (traceFetch) {
  //     console.error(error);
  //   }
  //   if (fetchCb) {
  //     //Build a response in the same format as API errors.
  //     return fetchCb(error, {
  //       // non_field_errors: [error && error.message.toString()]
  //     });
  //   }
  //   throw error;
  // });
}

class _MeawwApi {
  constructor() {
    this.config = {
      method: "UNKNOWN",
      headers: {
        // "Accept": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        "build-version": build_version,
        "authorization" : localStorage.getItem('accessToken'),
        
        // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Z-Key',
        // "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE"
        //"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
      }
    };
    this.config.credentials = "include";
    // this.config.mode = "no-cors";
  }

  callRestApi(method, endPoint, paramsOrCb, cb) {
    const config = this.config;
    const params = (paramsOrCb && !(typeof paramsOrCb === "function"))
      ? paramsOrCb
      : null; //make call callback func later
    const callback = (paramsOrCb && (typeof paramsOrCb === "function"))
      ? paramsOrCb
      : cb;

    config.method = method;
    if (params) {
      if (params.isFormData) {
        config.body = params.data;
      } else {
        config.body = JSON.stringify(params);
        config.headers["Content-Type"] = "application/json; charset=UTF-8";
        //config.body = params;
      }

      // config.body = params;
    }
    return fetchImpl(baseUrl + endPoint, config, callback);
  }

  auth(authErrorCb, authenticationKey) {
    // const authKey = authenticationKey || sessionStorage.getItem("Authorization") || DEV_AUTH_KEY;

    const authKey = authenticationKey || localStorage.getItem(LocalStorageKeys.AUTHORIZATION_KEY);

    if (authKey) {
      this.config.headers = Object.assign({}, this.config.headers, { Authorization: authKey });
    }
    if (authErrorCb) {
      return authErrorCb().then(() => Promise.resolve(this), error => Promise.reject(this))
    }
    return Promise.resolve(this);
  }

  post(endPoint, paramsOrCb, cb) {
    return this.callRestApi("POST", endPoint, paramsOrCb, cb);
  }

  get(endPoint, paramsOrCb, cb) {
    return this.callRestApi("GET", endPoint, paramsOrCb, cb);

  }

  delete(endPoint, paramsOrCb, cb) {
    return this.callRestApi("DELETE", endPoint, paramsOrCb, cb);
  }
  put(endPoint, paramsOrCb, cb) {
    return this.callRestApi('PUT', endPoint, paramsOrCb, cb);
  }
}

export function MeawwApi() {
  return new _MeawwApi();
}

export function callbackWrapper(dispatch, cb) {
  return (error, data) => {
    if (error && (error.statusCode === 403 || error.statusCode === 401)) {
      dispatch(setAppMessage(error.message, {
        type: MessageTypes.ERROR,
        onOk: function () {
          removePersistedData();
          window.location.href = "/";
        }
      }));
      return;
    }
    return cb(error, data);
  }
}
