import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import ConfirmPasswordForm from "./views/comfirm-password.jsx";
import confirmPasswordPageStyle from "assets/jss/material-dashboard-pro-react/views/confirmPasswordPageStyle.jsx";
import { Helmet } from "react-helmet";

import Header from '../app-components/header.jsx';
import Footer from '../app-components/footer.jsx';

class ConfirmPassword extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      redirectToReferrer: false,
    };
  }

  render() {
    const { classes } = this.props;
    // const { redirectToReferrer } = this.state;
    // let { from } = this.props.location.state || { from: { pathname: "/" } };
    // if (redirectToReferrer) return <Redirect to={from} />;

    return (
      <div className={classes.container}>
        <Header />
        <Helmet title="Confirm Password | PubNinja - You Publish. The rest we manage." />
        <GridContainer justify="center" style={{marginTop: '50px'}}>
          <GridItem xs={12} sm={6} md={4}>
            <ConfirmPasswordForm  {...this.props} />
          </GridItem>
        </GridContainer>
        <Footer />
      </div>
    );
  }
}

ConfirmPassword.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(confirmPasswordPageStyle)(ConfirmPassword);
