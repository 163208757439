import React, { PureComponent } from "react";
import TextField from '@material-ui/core/TextField';

class ArticleMetaSource extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {storySource: props.storySource || ''};
    this.articleTagsDropDown = React.createRef();
  }

  componentDidMount() {
  }

  onChange = (e) => {
    console.log("<<<<<<<<<<<<<", e.target.value);
    e.persist();
    this.setState({...this.state, storySource: e.target.value });
    this.props.setStorySource(e.target.value).then((d) => {
      console.log("<<<<<<<<< set", d);
    }).catch((e) => {
      console.log("<<<<<<<<<<e", e);
    });
  }

  onBlur = (e) => {
    e.persist();
    this.props.setStorySource(this.state.storySource).then((d) => {
      console.log("<<<<<<<<< set", d);
    }).catch((e) => {
      console.log("<<<<<<<<<<e", e);
    });
  }

  render() {
    return (
      <div style={{marginTop: '30px'}}>
        <select name="ChooseSource" onChange={this.onChange}>
          {!this.state.storySource && <option value="">*** Choose Source ***</option>}
          {this.state.storySource && <option value={this.state.storySource}>{this.state.storySource}</option>}
          <option value="Original (Exclusives, Social, Opinion)">Original (Exclusives, Social, Opinion)</option>
          <option value="Media Sources (Other Publishers)">Media Sources (Other Publishers)</option>
        </select>
      </div>
    )
  }
}

export default ArticleMetaSource;
