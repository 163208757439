import React from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";

class AddListButton extends React.PureComponent {
  render() {
    return (
      <div onClick={this.props.onClickHandler} style={{ display: "flex", alignItems: "center", cursor: "pointer", margin: "20px 0 20px" }}>
        <AddCircleIcon style={{ color:'#2B2D42' }} />&nbsp;&nbsp;<span style={{ color: "#2B2D42" }}>Upload Story</span>
      </div>
    )
  }
}

export default AddListButton
