import moment from "moment";

const defaultStatisticsState = {
  endDate: moment(),
  startDate: moment().subtract(10, "days"),
  isLoadingStatistics: false,
  isLoadingPageViews: false,
  statistics: null,
  pageviews: [],
}

const statisticsReducer = (state=defaultStatisticsState, action) => {
  switch (action.type) {
    case 'STATISTICS_DATES_SET': {
      return {...state, startDate: action.startDate, endDate: action.endDate}
    }
    case 'STATISTICS_GET_REQUEST': {
      return {...state, isLoadingStatistics: true}
    }
    case 'STATISTICS_GET_FAILURE': {
      return {...state, isLoadingStatistics: false}
    }
    case 'STATISTICS_GET_SUCCESS': {
      return {...state, statistics: action.data, isLoadingStatistics: false}
    }
    case 'PAGEVIEWS_GET_REQUEST': {
      return {...state, isLoadingPageviews: true}
    }
    case 'PAGEVIEWS_GET_FAILURE': {
      return {...state, isLoadingPageviews: false}
    }
    case 'PAGEVIEWS_GET_SUCCESS': {
      return {...state, pageviews: action.data, isLoadingPageviews: false}
    }
    default: return state;
  }
}
export default statisticsReducer;
