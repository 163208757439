import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Assignment from "@material-ui/icons/Assignment";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { postAccessToken, getFacbookPage, deleteAccessToken } from "../schedule-action.js";
import { connect } from 'react-redux';

class TokenDelete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        page: {},
      },
      success: '',
      pagesArr: [],
      error: '',
      helpText: {},
      buttonText: 'Select Page'
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getAllPages = this.getAllPages.bind(this);
    this.getAllPages();
  }
  onChange(selectedPageName) {
    const temp = this.state.data;
    temp.page.name = selectedPageName;
    this.setState({data: temp});
  }
  onSubmit() {
    this.props.deleteAccessToken(this.state.data).then(res => {
      this.setState({...this.state, success: 'Successfully deleted the token', buttonText: 'Select Page'},this.getAllPages());
    }, _ => {
      this.setState({error: _.message});
    });
  }
  getAllPages() {
    this.props.getFacbookPage().then(res => {
      this.setState({pagesArr: res});
    }, _ => {
      this.setState({error: 'Error in getting Fb page from Db'});
    });
  }
  render () {
    return (
      <GridContainer justify="flex-start">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 style={{'color': '#000'}}>Delete Access Facebook Token</h4>
            </CardHeader>
            <CardBody>
              <h3 style={{'color': '#DB6565'}}>{this.state.error || this.state.success}</h3>
              <CustomDropdown
                buttonText={this.state.buttonText}
                dropdownList={this.state.pagesArr.map(obj=>obj.name)}
                onClick={this.onChange.bind(null)}
              />
              <GridItem xs={12} sm={12} md={12}>
                <Button type="submit" onClick={this.onSubmit}>DELETE</Button>
              </GridItem>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (store) => {return {};};

const mapDispatchToProps = (dispatch) => {
  return {
    postAccessToken: (data) => dispatch(postAccessToken(data)),
    getFacbookPage: () => dispatch(getFacbookPage()),
    deleteAccessToken: (data) => dispatch(deleteAccessToken(data)),
  }
};

export default  connect(mapStateToProps, mapDispatchToProps)(TokenDelete);;
