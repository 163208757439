import React from 'react';
import { getPageDetails } from "../schedule-action";
import PostOnPage from './component/schedule-on-page';
import ListPost from './component/show-schedule-post';
import FbPageManage from './component/manage-social-app';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { connect } from 'react-redux';
import AppLoader from "../../app-components/app-loader.jsx";

import {
  storeFacbookPage,
  setRefreshPages,
  removeRefreshPages,
  fetchFbRequest,
  fetchFbFailure,
  fetchFbSuccess,
  closeFbModal
} from "../schedule-action.js";
import LoaderModal from './component/loader-modal.jsx';

class Facebook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getAllFbPages = this.getAllFbPages.bind(this);
  }
  getAllFbPages(res) {
    this.props.fetchFbRequest();
    if (res.data && res.data.length) {
      //Add a loader while post api is called
      this.props.storeFacbookPage(res.data)
          .then((data) => {
            this.props.setRefreshPages();
            this.props.fetchFbSuccess();
          })
          .catch((data)=>{
            this.props.fetchFbFailure();
          });

    }
  }
  render() {
    if(this.props.facebookpagenotfound){
      return(

          <div style={{fontSize: '1.3em', textAlign:'center', margin:'20px', lineHeight:"2", border:'1px solid #ced3dc', padding:'10px auto'}}>
            There are no active Facebook accounts.<br/>You  can add a Facebook account by going to the <span style={{color: '#2b2d42', fontWeight: 'bold'}}>Settings > Manage Social Accounts</span> Section
          </div>
      )
    }
    return (
      <GridContainer>
        <GridItem xs={12} md={12}>
          <PostOnPage/>
          <ListPost />
        </GridItem>
        {<LoaderModal
          showModal={this.props.showModal}
          showLoader={this.props.showLoader}
          message={this.props.loaderMessage}
          showCloseBtn={this.props.showCloseBtn}
          closeLoaderModal={this.props.closeFbModal}
        />}
      </GridContainer>
    );
  }
}

const mapStateToProps = (store) => {
  const {scheduleReducers: {
    scheduleReducer: {refreshPages, facebookpagenotfound, isLoadingFacebook},
    FBReducer: {showModal, showLoader, loaderMessage, showCloseBtn}
  }} = store;
  return {refreshPages, showModal, showLoader, loaderMessage, showCloseBtn, facebookpagenotfound, isLoadingFacebook};
}
const mapDispatchToProps = (dispatch) => {
  return {
    storeFacbookPage: (data) => dispatch(storeFacbookPage(data)),
    setRefreshPages: () => dispatch(setRefreshPages()),
    fetchFbRequest: () => dispatch(fetchFbRequest()),
    fetchFbSuccess: () => dispatch(fetchFbSuccess()),
    fetchFbFailure: () => dispatch(fetchFbFailure()),
    closeFbModal: () => dispatch(closeFbModal())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Facebook);
