import React, { Component } from "react";
import Paper from '@material-ui/core/Paper';
// core components
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import withStyles from "@material-ui/core/styles/withStyles";
import { LocalStorageKeys } from "../../../app-constants/index.js";
import SettingsIcon from '@material-ui/icons/Settings';
import FeedContainer from "./feedContainer.jsx";
import articleHomeStyle from '../../../assets/jss/material-dashboard-pro-react/views/articlehomeStyle';
import ErrorBoundary from "../components/errorBoundary.js";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 2 }}>
      {props.children}
    </Typography>
  );
}



class FeedList extends Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <Paper xs={12} sm={12} md={12} className={classes.root} >
        <Tabs
          fullWidth
          value={value}
          onChange={this.handleChange}
          scrollable
          scrollButtons="on"
          textColor="inherit"
          classes={{
            root: classes.tabsRoot
          }}
        >
          <Tab label="Associated Press" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
        </Tabs>
        {value === 0 && <TabContainer>
          <ErrorBoundary>
            <FeedContainer feedType="associated-press" />
          </ErrorBoundary>
        </TabContainer>}

      </Paper>
    );
  }
}


export default (withStyles(articleHomeStyle)(FeedList));
