
import Apps from "@material-ui/icons/Apps";
//pages
import React from 'react';
import ArticleHomePage from "./articles/articles-home-page.jsx";
import ArticlePvPage from "./articles/articles-pv-page.jsx";
import ContentDiscoveryHomePage from "./content-discovery/content-discovery-home-page";
import VideoUploadHomePage from "./video-upload/video-home-page";
import CMSHomePage from "./cms-home.jsx";
import StoriesHomePage from "./stories/stories-home-page.jsx";
import QuickCmsHomepage from "./quick-cms/quick-cms-homepage.jsx";
import PostsHomePage from "./posts/posts-home-page.jsx";
import NewsRoomHomePage from "./news-room/news-room-home-page";
import PiscoHomePage from "./pisco/pisco-home-page";
import NewStoryPage from "./new-story/new-story-page.jsx";
import EditStoryPage from "./new-story/edit-story-page.jsx";
import StoryCmsPage from "./story-cms/story-cms-home-page";
import DefaultLandingPage from "./landing-page";
import { default as Icons } from "../components/Icon/App.jsx";
import contentIcon from "../assets/icons/content-icon.png";
import cmsicon from '../assets/icons/cms-icon.png'
import mysiteIcon from '../assets/icons/mysite-icon.png'
import { isEmptyStroyPage } from "../login/views/login-form";
const Icon = () => <Icons src={contentIcon} />;
const CMSIcon = () => <Icons src={cmsicon} />;
const mysiteIconvar = () => <Icons src={mysiteIcon} />;
var cmsRoutes = [
  {
    collapse: true,
    path: "/cms",
    name: "Content",
    state: "openComponents",
    icon: Icon,
    component: CMSHomePage,
    requiredPermissions: ["CREATE:PUBLISH", "CREATE:WRITE", "CREATE:EDIT"],
    views: [
      {
        path: "/cms/articles",
        name: "Articles",
        mini: "A",
        isRequiredAuth: true,
        component: ArticleHomePage,
        requiredPermissions: ["CREATE:PUBLISH", "CREATE:WRITE", "CREATE:EDIT"],
        dontShowForTrail:true,
      },
      {
        path: "/cms/stories",
        name: "Stories",
        mini: "S",
        isRequiredAuth: true,
        component: StoryCmsPage,
        requiredPermissions: ["CREATE:WRITE"],
        requiredFeaturePermision: 'STORY_CMS',
        dontShowForTrail:true,
      },
      {
        path: "/cms/articles/new-story",
        name: "New Story",
        isRequiredAuth: true,
        component: NewStoryPage,
        requiredPermissions: ["CREATE:WRITE"],
        dontShowInSideBar: true,
      },
      {
        path: "/cms/articles/edit-story/:articleId",
        name: "Edit Story",
        isRequiredAuth: true,
        component: EditStoryPage,
        requiredPermissions: ["CREATE:WRITE", "CREATE:EDIT"],
        dontShowInSideBar: true,
      },
      {
        path: "/cms/news-room",
        name: "News Room",
        mini: "NR",
        isRequiredAuth: true,
        component: NewsRoomHomePage,
        requiredPermissions: ["CREATE:PUBLISH"],
        completePermissions: ["CREATE:PUBLISH", "CREATE:WRITE"],
        requiredFeaturePermision: 'NEWSROOM',
      },
      {
        path: "/cms/content-discovery",
        name: "Discovery",
        mini: "DS",
        isRequiredAuth: true,
        component: ContentDiscoveryHomePage,
        requiredPermissions: ["CREATE:WRITE"],
        requiredFeaturePermision: 'CONTENT_DISCOVERY',
        dontShowForTrail: true,
      },
      {
        path: '/cms/video-upload',
        name: `Video Upload`,
        mini: "V",
        isRequiredAuth: true,
        component: VideoUploadHomePage,
        requiredPermissions: ["CREATE:WRITE"],
        requiredFeaturePermision: 'DEMO_FEATURES'
      },
      {
        path: '/cms/pisco',
        name: `Pisco Media Library`,
        mini: "P",
        isRequiredAuth: true,
        component: PiscoHomePage,
        requiredPermissions: ["CREATE:WRITE"],
        dontShowForTrail:true,
      },
      {
        path: "/cms/articles-pageviews",
        name: "My Articles Pageviews",
        mini: "MAP",
        isRequiredAuth: true,
        component: ArticlePvPage,
        requiredPermissions: ["CREATE:WRITE"],
        dontShowForTrail:true,
      }
    ]
  },
];

export const storiesRoutes = [
  {
    path: "/landing",
    name: "Pubninja",
    mini: "PN",
    isRequiredAuth: true,
    icon: mysiteIconvar,
    component: DefaultLandingPage,
    dontShowInSideBar: true,
  },
  {
    path: "/my-site",
    name: "My Site",
    mini: "RA",
    isRequiredAuth: true,
    icon: mysiteIconvar,
    component: StoriesHomePage,
    dontShowInSideBar: isEmptyStroyPage(),
    onlyQuickCms: true,
    dontShowForTrail: true,
  },
  {
    path: "/my-website",
    name: "Create",
    mini: "QC",
    isRequiredAuth: true,
    icon: CMSIcon,
    component: QuickCmsHomepage,
    requiredPermissions: ["CREATE:WRITE"],
    requiredFeaturePermision:'QUICK_CMS',
    dontShowInSideBar: isEmptyStroyPage()
  },
  {
    path: "/posts",
    name: "Posts",
    isRequiredAuth: true,
    component: PostsHomePage,
    dontShowInSideBar: true,
  }
]
export default cmsRoutes;
