
import React, { Component } from 'react';
import VolumeUp from "@material-ui/icons/VolumeUp";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeOff from "@material-ui/icons/VolumeOff";
// import SkipPrevious from "@material-ui/icons/SkipPrevious";
// import SkipNext from "@material-ui/icons/SkipNext";
import SkipPrevious from "@material-ui/icons/Replay30";
import SkipNext from "@material-ui/icons/Forward30";
import Replay from "@material-ui/icons/Loop";
import PlayCircleOutline from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutline from "@material-ui/icons/PauseCircleOutline";
import AudioBar from "./audio-bar";
import Audiotrack from "@material-ui/icons/Audiotrack";

class ShowAudio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mute: false,
      pause: false,
      length: 0,
      currentTime: 0.00,
      currentRealTime: 0,
      buffer: 0,
      vloume: 0.5, // default volume
      isLoop: false,
      toggleLoop: false,
      showVolumBar: false
    }
    this.audioNode = React.createRef();
  }
  toggleMute = () => {
    const prevMute = this.state.mute;
    this.setState({ mute: !prevMute }, () => {
      this.audioNode.current.muted = this.state.mute;
    });
  }
  togglePause = () => {
   
    const prevPause = this.state.pause;
    this.setState({ pause: !prevPause }, () => {
      try {
        !this.state.pause ?
        this.audioNode.current.pause()
        : this.audioNode.current.play()
       }
      catch (e) {
        
      }
        
    });
  }
  forwardAudio = () => {
    const time = this.audioNode.current.duration;
    const forwardTime = this.audioNode.current.currentTime + 30.0;
    if (forwardTime < time) {
      this.audioNode.current.currentTime = forwardTime
    }
  }
  backwordAudio = () => {
    const backwardTime = this.audioNode.current.currentTime - 30.0;
    if (backwardTime > 0) {
      this.audioNode.current.currentTime = backwardTime
    }
  }
  replay = () => {
    const { toggleLoop } = this.state;
    this.setState({ toggleLoop: !toggleLoop }, () => {
      this.audioNode.current.loop = this.state.toggleLoop;
    });
  }
  componentDidMount() {
    const { timer = null } = this.props;
    this.audioNode && this.audioNode.current && this.audioNode.current.addEventListener('loadeddata', () => {
      let time = timer || this.audioNode && this.audioNode.current && this.audioNode.current.duration;
      if (time == 'Infinity') {
        const { timer = 0 } = this.props;
        time = timer
      }
      this.setState({ length: time });
    })
    this.audioNode.current.ontimeupdate = () => {
      try {
        const player = this.audioNode.current;
        this.setState({ currentRealTime: player ? player.currentTime : 0, buffer: player && player.buffered.end(0) })
      }
      catch (e) {
        console.log(e)
      }
    }
    this.audioNode.current.addEventListener('ended', () => {
      this.setState({ pause: false })
    })
  }
  setAudioBarVloume = () => {
    this.setState({ showVolumBar: true })
  }
  removeAudioBarVloume = () => {
    this.setState({ showVolumBar: false })
  }
  getValue = (e) => {
    this.audioNode.current.volume = e.target.value;
    if (e.target.value == 0) {
      this.setState({ vloume: e.target.value, mute: true })
      return
    }
    if (e.target.value == 1) {
      this.setState({ vloume: e.target.value, mute: false })
      return
    }
    this.setState({ vloume: e.target.value, mute: false })
  }
  render() {
    const {isMobile =false,thumbnail="https://cdn.pubninja.com/assets/new-feed/audio_thumbnail.png" } = this.props;
    return (
      <div style={{ position:'relative',height:'100%'}}>
      <div key={thumbnail} style={{ height:'100%',backgroundRepeat:'no-repeat',backgroundPosition:'center',backgroundImage:`url(${thumbnail})`,backgroundSize:'cover',display: 'block', width: '100%', border: '1px solid #ced3dc', padding: '10px' }}>
        <audio
          ref={this.audioNode}
          id="meaAudioPlayer"
          controls
          style={{ display: 'none' }}
          src={this.props.src}
        >
          Your browser does not support the
        <code>audio</code> element.
        </audio>
        <div style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          marginTop: '2.5rem',
          position: 'relative'
        }}>
          {/* <div style={{
            display: 'inline-block',
            height: '5.25rem'
          }}> */}
          <div
            onMouseEnter={this.setAudioBarVloume}
            // onBlur={this.removeAudioBarVloume}
            style={{ position: 'relative', height: '100%',zIndex:'999' }} id="audiobarSliderVolume">
            {this.state.showVolumBar && <div>
              <input type="range" min="0" max="1" step="any" value={this.state.vloume} onChange={this.getValue} style={{
                width: '5rem',
                transform: 'rotate(-90deg)',
                transformOrigin: '0',
                position: 'absolute',
                left: '50%',
                bottom: '25%',
                paddingTop: '20px',
                paddingBottom: '20px',
                zIndex:'999'
              }}
                onMouseLeave={this.removeAudioBarVloume}
              />
            </div>
            }
            {/* </div> */}
            {
              !this.state.mute ? (this.state.vloume >= 0.5 ? <VolumeUp style={{ cursor: 'pointer', fontSize:`${isMobile?'1rem':'1.5rem'}`, color: 'white' }} onClick={this.toggleMute} /> :
                <VolumeDown style={{ cursor: 'pointer',zIndex:'999',fontSize:`${isMobile?'1rem':'1.5rem'}`, color: 'white' }} onClick={this.toggleMute} />
              )
                : <VolumeOff style={{ cursor: 'pointer',zIndex:'999', fontSize:`${isMobile?'1rem':'1.5rem'}`, color: 'white' }} onClick={this.toggleMute} />
            }
          </div>
          <SkipPrevious style={{ cursor: 'pointer',zIndex:'999', fontSize: `${isMobile?'1.5rem':'2rem'}`, color: 'white' }} onClick={this.backwordAudio} />
          {
            this.state.pause ? <PauseCircleOutline style={{ zIndex:'999',cursor: 'pointer', fontSize: `${isMobile ?'3rem':'4rem'}`, color: 'white' }} onClick={this.togglePause} />
              : <PlayCircleOutline style={{ cursor: 'pointer', zIndex:'999',fontSize: `${isMobile ?'3rem':'4rem'}`, color: 'white' }} onClick={this.togglePause} />
          }
          <SkipNext style={{ cursor: 'pointer', zIndex:'999',fontSize: `${isMobile?'1.5rem':'2rem'}`, color: 'white' }} onClick={this.forwardAudio} />
          <Replay title="loop" style={{ zIndex:'999',cursor: 'pointer', fontSize:`${isMobile?'1rem':'1.5rem'}`, color: `${this.state.toggleLoop ? 'white' : '#CED3DC'}` }} onClick={this.replay} />
        </div>
        <AudioBar
          length={this.state.length}
          currentTime={this.state.currentRealTime}
          buffer={this.state.buffer} />
        </div>
        <div style={{position:'absolute',top:'0',height:'100%',display: 'block', width: '100%', border: '1px solid #ced3dc',background:'black',opacity:'0.5'}}></div>
        </div>
    )
  }
}

export default ShowAudio;


