import React from 'react';
import PropTypes from "prop-types";
import moment from 'moment';

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Assignment from "@material-ui/icons/Assignment";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import CustomInput from "components/CustomInput/CustomInput.jsx";

import { MentionsInput, Mention } from 'react-mentions';
import Datetime from 'react-datetime';

const styling  = {
  control: {
    backgroundColor: '#fff',
    marginTop: '25px',
    fontSize: 14,
    fontWeight: 'normal',
  },

  highlighter: {
    overflow: 'hidden',
  },

  input: {
    margin: 0,
  },

  '&singleLine': {
    control: {
      display: 'inline-block',

      width: 130,
    },

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },

    input: {
      padding: 1,

      border: '2px inset',
    },
  },

  '&multiLine': {
    control: {
      fontFamily: 'monospace',

      border: '1px solid silver',
    },

    highlighter: {
      padding: 9,
    },

    input: {
      padding: 9,
      minHeight: 150,
      outline: 0,
      border: 0,
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
    },

    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',

      '&focused': {
        backgroundColor: '#f1cec3',
      },
    },
  },
};

class Schedule extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.state = {
      users: props.users,
      data: {
        value: '',
        pageName: '',
        link: props.link || '',
        time: '',
        content: '',
      },
      error: {
        message: false,
        link: false,
      },
      success: {
        message: '',
        link: '',
      },
      helpText: {
        message: '',
        link: '',
      },
    };
  }
  onChange(event, type) {
    if (event === 'pageName') {
      this.setState({
        data: { ...this.state.data,
          ...{
            [event]: type
          }
        }
      });
    } else {
      this.setState({
        data: { ...this.state.data,
          ...{
            [type]: event.target.value
          }
        }
      });
    }
  }

  fetchUsers(type, query, callback) {
    if (!query) return
    let getType = type === 0 ? `hashtags` : `users`;
    let url =  `${process.env.REACT_APP_API_SERVER}/schedule/twitter/data?type=${getType}&query=${query}`;
    fetch(url, {json: true})
      .then(res => res.json())
      .then(res => {
        if(type === 0) {
          return res.hashtags.map(user => {return {display: `${user.hashtag}`, id: user.hashtag}})
        }else{
          return res.users.map(user => ({display: `${user.name}`, id: `@${user.screen_name}`}))
        }

      })
      .then(res=>{
        if (res.length){
          return callback(res);
        }
      })
  }
  changeDateTime = (value) => {
    let curTime = moment().toDate();
    let newTime = moment(value).toDate();
    var diff = moment(newTime).diff(curTime, 'days')
    this.setState({data: {...this.state.data, time: moment(newTime).format(`YYYY-MM-DDTHH:mm`)}});
  }
  onSubmit() {
    let temp = this.state.data;
    this.setState({ data: temp }, () => {
      this.props.formData(this.state.data);
    });
  }
  render() {
    return <GridContainer justify="flex-start">
          <GridItem xs={12} sm={12} md={12}>
            <CardBody>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <Assignment />
                  </CardIcon>
                  <h4 style={{'color': '#000'}}>Schedule Tweets</h4>
                </CardHeader>
                <CardBody>
                  <div>{this.state.success.message}</div>
                  <div>{this.state.error.message}</div>
                    <CustomDropdown
                      buttonText="Select Account"
                      dropdownList={this.props.users.map(obj=>obj.screen_name)}
                      onClick={this.onChange.bind(null, 'pageName')}
                    />
                  <MentionsInput
                    onChange={(e)=>{
                      const data = this.state.data;
                      data.content = e.target.value.replace(/[()]/g,'');
                      this.setState(data)
                    }}
                    value={this.state.data.content}
                    placeholder="Mention any twitter user by typing `@` followed by at least one char and `#` for hashtags "
                    style={styling}
                  >
                    <Mention
                      trigger="#"
                      data={this.fetchUsers.bind(null, 0)}
                      markup="(__id__)"
                    />
                    <Mention
                      trigger="@"
                      data={this.fetchUsers.bind(null, 1)}
                      markup="(__id__)"
                    />
                  </MentionsInput>
                    {this.props.disableLink ? '' : <CustomInput
                      rows={4}
                      labelText="Link"
                      id="message"
                      inputProps={{
                        onChange: event =>{
                          this.onChange(event, "link")
                        },
                        type: "textarea",
                        value: this.state.data.link
                      }}
                      error={this.state.error.link}
                      helpText={this.state.helpText.link}
                      formControlProps={{fullWidth: true }}
                    />}
                    <div style={{marginTop: '-30px', width: '50%', minWidth: '200px', maxWidth: '300px'}}>
                    <CustomInput
                        labelText="Select Date"
                        id="time"
                        inputProps={{
                          onChange: event =>{
                            this.onChange(event, "time")
                          },
                          type: "datetime-local",
                          value: this.state.data.time
                        }}
                        formControlProps={{fullWidth: true }}
                      />
                      </div>
                    {/* <div >
                      <Datetime
                        inputProps={{
                          placeholder: "Select Date and Time",
                          value: this.state.data.time==''?'Select Date':moment(this.state.data.time).format("DD/MM/YYYY, hh:mm A") || 'Select Date',
                          style: {minWidth: '200px', maxWidth: '250px', margin: '25px 0'}
                        }}
                        onChange={(event)=>this.changeDateTime(event)}
                      />
                    </div> */}
                   <Button color="primary" type="submit" onClick={this.onSubmit}>SUBMIT</Button>
                </CardBody>
              </Card>
            </CardBody>
        </GridItem>
      </GridContainer>
  }
}
Schedule.propTypes = {
  link: PropTypes.string,
  disableLink: PropTypes.bool,
};
export default Schedule;
