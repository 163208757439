import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { fetchUserList } from "../account-management-actions.js";
import AppLoader from "app-components/app-loader.jsx";
import UserDetailsContainer from "./user-details-container.jsx";
import AddNewUser from "../components/add-new-user.jsx";
import UserListHeader from "../components/user-list-header.jsx";
import Hidden from "@material-ui/core/Hidden";

const RenderUserList = ({ userList }) => (
  <React.Fragment>
    {
      Object.values(userList).map((user) => {
        return <UserDetailsContainer key={user.id} userDetails={user} />
      })
    }
  </React.Fragment >
)


class UserList extends PureComponent {
  componentDidMount() {
    const { fetchUserList } = this.props;
    fetchUserList();
  }
  render() {
    const { isFetchingUserList, userList } = this.props;
    if (isFetchingUserList) {
      return <AppLoader message="Fetching users..." />
    }
    if (Object.keys(userList).length === 0) {
      return <div> No user found</div>
    }
    return (
      <div >
        <AddNewUser />
        <Hidden xsDown implementation="css">
          <UserListHeader />
        </Hidden>

        <RenderUserList userList={userList} />
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  const { userList, isFetchingUserList } = state.userManagement.accountManagement;
  return {
    userList: userList,
    isFetchingUserList: isFetchingUserList
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserList: () => dispatch(fetchUserList())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
