const AccountManagementAction = {
  FETCH: {
    USER_LIST: {
      REQUEST: "AccountManagement.Fetch.UserList.Request",
      FAILURE: "AccountManagement.Fetch.UserList.Failure",
      SUCCESS: "AccountManagement.Fetch.UserList.Success",
    },
    DOMAIN_PERMISSIONS: {
      REQUEST: "AccountManagement.Fetch.DomainPermissions.Request",
      FAILURE: "AccountManagement.Fetch.DomainPermissions.Failure",
      SUCCESS: "AccountManagement.Fetch.DomainPermissions.Success",
    }
  },
  DELETE: {
    DOMAIN_USER: {
      REQUEST: "AccountManagement.Delete.DomainUser.Request",
      FAILURE: "AccountManagement.Delete.DomainUser.Failure",
      SUCCESS: "AccountManagement.Delete.DomainUser.Success",
    }
  }
}
export default AccountManagementAction;