import React from "react";
import ChannelList from "../components/channelLists";
import { connect } from "react-redux";
import { getChannels, addChannel, deleteChannel } from "../feeds-actions";

class FeedSettings extends React.Component {

  render() {
    const { addChannel, deleteChannel, getChannels, channels } = this.props;
    return (
      <>
        <ChannelList
          provider="instagram"
          helperText="Channel should be public Ex.  https://www.instagram.com/snarkpark/?hl=en "
          getChannels={getChannels}
          addChannel={addChannel}
          deleteChannel={deleteChannel}
          channels={channels}
        />
        <ChannelList
          provider="twitter"
          helperText="Channel should be public Ex.  https://twitter.com/DailyMailCeleb "
          getChannels={getChannels}
          addChannel={addChannel}
          deleteChannel={deleteChannel}
          channels={channels}
        />
        <ChannelList
          provider="youtube"
          helperText="Ex.  https://www.youtube.com/channel/UCq-Fj5jknLsUf-MWSy4_brA, https://www.youtube.com/user/MARVEL"
          getChannels={getChannels}
          addChannel={addChannel}
          deleteChannel={deleteChannel}
          channels={channels}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const { feedChannels } = state.cms.feeds;
  return {
    channels: feedChannels,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getChannels: (provider) => dispatch(getChannels(provider)),
    addChannel: (provider, url, channelType) => dispatch(addChannel(provider, url, channelType)),
    deleteChannel: (channel) => dispatch(deleteChannel(channel)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedSettings);
