import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import OwnSideBar from './own-sidebar';

const styles = {
  root: {
    flexGrow: 1,
    zIndex :'999'
  },
  slider: {
    padding: '22px 0px',
    zIndex :'999'
  },
  dashedStyle: {
    display: 'none',
    zIndex :'999'
  },
  container: {
    background: 'rgb(206, 211, 220)',
    zIndex :'999'
  }
};

class AudioBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      completed: 0,
      buffer: 0,
      value: 50,
      currentTime: 0
    };
    this.audioBarNode = React.createRef();
  }

  progress = () => {
    const { completed } = this.state;
    const { width, left } = this.audioBarNode.current.getBoundingClientRect();
    const oneSecPixel = (width / this.props.length);
    if (completed > oneSecPixel * width) {
      this.setState({ completed: 0, buffer: 10 });
    } else {
      const diff = this.props.currentTime - completed;
      // const diff2 = this.props.buffer;
      this.setState((prev, props) => ({ completed: props.currentTime / props.length * 100, buffer: props.buffer / props.length * 100 }));
    }
  };

  handleChange = (x) => {
    const { width, left } = this.audioBarNode.current.getBoundingClientRect()
    const oneSecPixel = (width / this.props.length);
    const clickLocation = x.clientX - left;
    const clickCurrentTime = (1 / oneSecPixel) * clickLocation;

  };
  componentDidUpdate(prevProps) {
    if (prevProps.currentTime != this.props.currentTime) {
      this.progress()
    }
  }
  realTime(second) {
    if (isNaN(second)) return;
    let minutes = 0;
    let hour = 0;
    if (second >= 60) {
      minutes = second / 60;
      minutes = parseInt(minutes, 10).toFixed(0);
      second = Math.abs(minutes * 60 - second).toFixed(0);
      if (minutes >= 60) {
        hour = minutes / 60;
        hour = parseInt(hour, 10).toFixed(2);
        minutes = Math.abs(hour * 60 - minutes).toFixed(0);
      }
      if (hour) {
        return `${hour}:${minutes}:${second}`
      }
      return `${minutes}:${second}`
    }
    return `${minutes}:${second.toFixed(0)}`
  }
  render() {
    const { classes, length, currentTime } = this.props;
    return (
      <>
        <div id="audioBarContainer" ref={this.audioBarNode} className={classes.root} onClick={(e) => this.handleChange(e)} >
          {/* <LinearProgress variant="determinate" value={this.state.completed} /> */}
          <br />
          <LinearProgress
            classes={
              {
                dashed: classes.dashedStyle,
                root: classes.container
              }
            }
            color="primary" variant="buffer" value={this.state.completed} valueBuffer={this.state.buffer} />
          {/* <input type="range" max="100" width="100%" /> */}
          {/* <OwnSideBar /> */}
        </div>
        <p
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '5px',
            marginBottom: '0px',
            zIndex :'999',
          }}
        >
          <span style={{ color: '#bfbfbf' ,zIndex :'999'}}> {this.realTime(currentTime)} </span>
          <span style={{ color: '#bfbfbf',zIndex :'999' }}> {length ? this.realTime(length):0}</span>
        </p>
        {/* <OwnSideBar /> */}
      </>
    );
  }
}

AudioBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AudioBar);