import React from "react";
import Paper from "@material-ui/core/Paper";
import StoryListItem from "../components/story-list-item";
export default class StoryList extends React.PureComponent {

  state = {
    loading: false,
    showDelete:false,
  }

  deleteStory = (item) => {
    this.props.deleteStory(item.id);
    this.props.getStories(0);
    this.props.getStories(1);
  }
  toggleDeleteButton = () => {
    this.setState((prev) => {
      return {
        showDelete: !prev.showDelete,
      }
    })
  }
  render() {
    if (!this.props.stories.length) {
      return (
        <span style={{ textAlign:'center'}}><h4>No Stories</h4></span>
      )
    }
    return (
      <div key="stories_today_images">
      <ul style={styles.storyList} key="stories_today_list">
          {this.props.stories.map((item, index) => {
            return (
              <StoryListItem deleteStory={this.deleteStory} item={item} index={index} key={index}/>
            )
          })}
        </ul>
      </div>
    )
  }
}
const styles = {
  storyList: {
    marginLeft:'-30px',
    overflow: 'auto',
    whiteSpace: "nowrap"
  },
  storyListItem: {
    display: 'inline-block',
  },
  imageContainer: {
    margin:'5px 0px 5px 0px',
    maxWidth: '200px',
    height: '320px',
  }
}
