import {
  primaryColor,
  dangerColor,
  successColor,
  defaultFont
} from "assets/jss/material-dashboard-pro-react.jsx";

const customInputStyle = {
  disabled: {
    "&:before": {
      borderColor: "transparent !important"
    }
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#D2D2D2 !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      //borderColor: primaryColor
    }
  },
  underlineError: {
    "&:after": {
      //borderColor: dangerColor
    }
  },
  // underlineSuccess: {
  //   "&:after": {
  //     borderColor: successColor
  //   }
  // },
  labelRoot: {
    ...defaultFont,
    color: "#AAAAAA !important",
    zIndex: "1199",
    fontWeight: "normal",
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: "12px",
    lineHeight: "1.25",
    paddingLeft: '5px',
    textTransform: 'uppercase',
    top: "28px",
    "& + $underline": {
      marginTop: "5px"
    },
    "&:after": {
      //
    }
  },
  labelRootError: {
    color: dangerColor + " !important"
  },
  labelRootSuccess: {
    color: successColor + " !important"
  },
  formControl: {
    margin: "0 0 17px 0",
    paddingTop: "27px",
    position: "relative",
    verticalAlign: "unset",
    "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
      color: "#495057"
    }
  },
  whiteUnderline: {
    "&:hover:not($disabled):before,&:before": {
      backgroundColor: "#FFFFFF"
    },
    "&:after": {
      backgroundColor: "#FFFFFF"
    }
  },
  input: {
    color: "#495057",
    border: '1px solid #ced3dc',
    padding: '7px',
    lineHeight: 'normal',
    "&,&::placeholder": {
      ...defaultFont,
      padding :'7px',
      fontSize: "12px",
      fontWeight: "400",
      border: '1px solid #ced3dc',
      borderStyle: 'solid',
      borderColor: '#ced3dc',
      borderWidth: '1px',
      opacity: "1",
      verticalAlign:'middle'
    },
    "&::placeholder": {
      color: "#AAAAAA",
      border: 'none',
    }
  },
  whiteInput: {
    "&,&::placeholder": {
      color: "#FFFFFF",
      opacity: "1"
    }
  }
};

export default customInputStyle;
