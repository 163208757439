import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import { getTweets, deleteTweet, rescheduleTweet } from "../../schedule-action.js";
import { connect } from 'react-redux';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Assignment from "@material-ui/icons/Assignment";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Modal from "components/Modal/modal.jsx";
import PostOnPage from '../component/schedule';
import moment from "moment";
import DateTime from './time.jsx';

class ListPost extends React.Component {
  constructor(props) {
    super(props);
    // this.getPageData = this.getPageData.bind(this);
    this.getDeleteComponent = this.getDeleteComponent.bind(this);
    this.callDeleteApi = this.callDeleteApi.bind(this);
    this.getTimeCoponent = this.getTimeCoponent.bind(this);
    this.onUpdateFieldChange = this.onUpdateFieldChange.bind(this);
    this.getUpdateModal = this.getUpdateModal.bind(this);
    this.callReScheduleApi = this.callReScheduleApi.bind(this);
    this.state = {
      tableData: [],
      time: '',
      message: '',
      reschedule: {
        link: '',
        time: '',
        tweetId: 0,
      },
    };
  }

  componentDidMount() {
    this.getPageData();
  }
  // callDeleteApi(tweetId, type) {
  //   if (type === 'Delete' && tweetId.length >= 0) {
  //     this.props.deletePost({tweetId}).then(data => {
  //       this.getPageData();
  //     }, _ => {
  //     });
  //   }
  // }
  callDeleteApi(tweetId, type) {
      this.props.deleteTweet({tweetId}).then(data => {
        this.getPageData();
      }, _ => {
        //Handle Error
      });
  }
  callReScheduleApi() {
      this.state.reschedule.time = +moment(this.state.reschedule.time);
      this.props.rescheduleTweet(this.state.reschedule).then(data => {
        this.getPageData();
      }, _ => {
        //Handle Error
      });
  }
  onUpdateFieldChange(tweetId, link, time) {
    let reschedule = this.state.reschedule;
    reschedule.id = tweetId;
    reschedule.message = link;
    reschedule.date = +moment(time);
    this.setState({reschedule: {...reschedule}});
  }
  getUpdateModal (tweetId, link) {
    return <Modal bodyText={this.getTimeCoponent(tweetId, link)} modalTitle='Reschedule' buttonTitle = 'Re-Schedule' onCancelClick={()=>{}} onSuccessClick={this.callReScheduleApi}/>;
  }
  getTimeCoponent(tweetId, link) {
    return <DateTime time={this.onUpdateFieldChange.bind(null, tweetId, link)} />
  }
  getDeleteComponent(tweetId, type) {
    const d = <p>Delete Post from Page</p>;
    return <Modal bodyText={d} modalTitle='Delete' buttonTitle = 'Delete' onCancelClick={()=>{}} onSuccessClick={this.callDeleteApi.bind(null, tweetId, 'Delete')}/>;
  }
  getPageData() {
    this.props.getTweets().then(data => {
      let formateData = data.map(obj => {
        const dateTime = moment(parseInt(obj.date)).format("dddd, MMMM Do YYYY, h:mm:ss a");
        return [
          obj.user,
          obj.message,
          dateTime,
          <CustomDropdown
            buttonText="Select Action"
            dropdownList={[ this.getDeleteComponent(obj.id, 'delete'), this.getUpdateModal(obj.id, obj.message)]}
          />
        ];
      });
      this.setState({ tableData: formateData });
    }, _ => {
      alert(_);
      // Show error Message to the user, I will be adding it later
    });

  }
  render() {
    return (
      <GridContainer justify="flex-start">
        <GridItem xs={12} sm={12} md={12}>
          <CardBody>
            <Card>
              <CardHeader color="rose" icon>
                  <h4 style={{'color': '#000'}}>All Scheduled Tweets</h4>
                  <h4 style={{'color': '#000'}}>{this.state.message}</h4>
                </CardHeader>
              <CardBody>
                {this.state.tableData.length > 0 ? (
                  <Table
                    tableHead={[
                      "Name",
                      "Message",
                      "Date",
                      "Action"
                    ]}
                    tableData={this.state.tableData}
                  />
                ) : ''}
              </CardBody>
            </Card>
          </CardBody>
        </GridItem>
      </GridContainer>
    );
  }
}
const mapStateToProps = (store) => {
  return {};
}
const mapDispatchToProps = (dispatch) => {
  return {
    getTweets: () => dispatch(getTweets()),
    deleteTweet: (data) => dispatch(deleteTweet(data)),
    rescheduleTweet: (data) => dispatch(rescheduleTweet(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListPost);
