import UserProfileDetailsPage from "./user-profile-page.jsx";
import EditUserProfilePage from "./edit-profile-page.jsx";

const userSettingsRoutes = [
  {
    path: "/user-settings/profile",
    name: "User Profile",
    component: UserProfileDetailsPage,
    dontShowInSideBar: true,
  },
  {
    path: "/user-settings/edit-profile",
    name: "Edit User Profile",
    component: EditUserProfilePage,
    dontShowInSideBar: true,
  }
];

export default userSettingsRoutes; 