import React,  { PureComponent, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import { withStyles, Typography, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import AddCircleIcon from "@material-ui/icons/AddCircle";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { connect } from 'react-redux';
import moment from "moment";
import { setAppMessage } from "../../../app-actions.js";
import { MessageTypes } from "../../../app-constants/index.js";

import {exploitArticle} from "../../article/article-action.js";

const CustomDialogTitle = withStyles(theme => ({
  root: {
    // borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, toggleExploitArticleModal } = props;
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="subheading">
        <div style={{ justifyContent: "center", fontWeight: "bold" }}>
          {children}
        </div>
      </Typography>
      <IconButton aria-label="Close" className={classes.closeButton} onClick={() => {
        toggleExploitArticleModal(false);
      }}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
});

class ExploitLiveArticleModal extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      showToolTip:false,
      articleCoverPicture: '',
      rawCoverPictureUrl: '',
      error: '',
      data: {
        time: this.getInitialDateTime(),
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.exploitArticleData.coverPicture !== this.state.rawCoverPictureUrl) {
      if (typeof nextProps.exploitArticleData.coverPicture !== 'undefined') {
        this.setState({
          articleCoverPicture: `${nextProps.exploitArticleData.coverPicture}_450_250${nextProps.exploitArticleData.imgExtension}`,
          rawCoverPictureUrl: nextProps.exploitArticleData.coverPicture,
        });
      }
    }
  }

  onChange(event, type) {
    var diff;
    if(type === 'time'){
      let curTime = moment().toDate();
      let newTime = moment(event.target.value).toDate();
      diff = moment(newTime).diff(curTime, 'minutes')
      console.log(`cur, sch`, diff);
    }
    if(diff <= 9){
      this.props.setAppMessage('Exploit schedule time should be minimum 10minutes', { type: MessageTypes.ERROR });
    } else {
      this.setState({data: {...this.state.data, ...{[type]: event.target.value}}});
    }
  }

  getInitialDateTime = () => {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 10); // Add 10 minutes to the current time

    // Get components of the local date and time
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    // Return the formatted local date and time as 'YYYY-MM-DDTHH:MM'
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  render() {
    const {
      showExploitArticleModal,
      toggleExploitArticleModal,
    } = this.props;
    const title = [`Website Headline: `, `${this.props.exploitArticleData.title}`];
    const ogTitle = [`Facebook Headline: `, `${this.props.exploitArticleData.ogTitle}`];
    const postCaption = [`Google Headline: `, `${this.props.exploitArticleData.seoTitle}`];
    const data = [title, ogTitle, postCaption];
    return (
      <Dialog
        id="exploitContainer"
        open={showExploitArticleModal}
        //fullWidth={true}
        onClose={this.hide}
        fullWidth
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
        style={{margin: '-35px', zIndex: '10001'}}
      >
        <CustomDialogTitle toggleExploitArticleModal={toggleExploitArticleModal}>Exploit Article</CustomDialogTitle>
        <DialogContent style={{display:"flex"}}>
          <GridContainer>
            <GridItem xs={12}>
              {this.state.articleCoverPicture && this.state.articleCoverPicture.length ? <img style={{width: '100%'}} src={this.state.articleCoverPicture} /> : '' }
              <Table
                tableHeaderColor="primary"
                tableData={data}
                coloredColls={[3]}
                colorsColls={["primary"]}
              />
              <div style={{marginTop: '-20px'}}>
                <CustomInput
                  labelText="Select Date"
                  id="time"
                  inputProps={{
                    onChange: event =>{
                      this.onChange(event, "time")
                    },
                    type: "datetime-local",
                    value: this.state.data.time,
                    style: {minWidth: '200px', maxWidth: '300px'},
                    min: this.getInitialDateTime()
                  }}
                />
              </div>
              <div>
                <Button type="submit" onClick={() => {
                  const dateTimeUtc = new Date(this.state.data.time).toISOString()
                  this.props.exploitArticle(dateTimeUtc).then((d) => {
                    alert(d && d.message || `Exploit Scheduled for ${this.state.data.time}`);
                    toggleExploitArticleModal(false);
                  }).catch((e) => {
                    alert(`Error ${e.message}`);
                    toggleExploitArticleModal(false);
                  });
                }}>
                  <span style={{ display: "flex", color: '#2b2d42' }}>Schedule</span>
                </Button>
              </div>
              <div style={{'color': 'red'}}>{this.state.error}</div>
           </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = (state) => {
  const { id: activeUser } = state.login.user;
  return {
    user: state.userManagement.accountManagement.userList[activeUser],
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setAppMessage: (msg, params) => dispatch(setAppMessage(msg, params)),
    exploitArticle: (data) => dispatch(exploitArticle(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ExploitLiveArticleModal);
