
const searchStyle = {
    layoutStyle: {
        zIndex: '999999 !important'
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        minWidth: 120,
        maxWidth: 300,
        marginLeft: '10px',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
}
export default searchStyle;
