import React from 'react';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AppLoader from "app-components/app-loader.jsx";

class FeatureSelection extends React.PureComponent {
  state = {
    features: [],
    defaultFeatures: [
      {title: 'Member Only posts and Story', checked: false },
      {title: 'Ad Free Experience', checked: false },
      {title: 'Early Access to Public Posts', checked: false },
    ],
    totalChecks: 0,
    showFeatureForm: false,
    newFeature: '',
    monthlyPrice: '',
    annualPrice: '',
    monthlyPriceFloat: '',
    annualPriceFloat: '',
    subscriptionEnabled: 0,
    currencyType: '',
    currencySymbol: '',
    annualPriceError: '',
    monthlyPriceError: '',

  }

  componentDidMount() {
    const { subscriptionEnabled } = this.props;
    if(subscriptionEnabled == 0) {
      this.setState({ features: this.state.defaultFeatures, subscriptionEnabled: 0, currencyType: 'USD' });
    }
    if (subscriptionEnabled == 1) {
      const features = this.state.defaultFeatures;
      const currencyType = this.props.features && this.props.features.currency;
      const currencySymbol = this.props.features && this.props.features.currencySymbol;
      const monthlyPrice = (this.props.features.monthly && this.props.features.monthly.price) || null;
      const annualPrice = (this.props.features.annually && this.props.features.annually.price) || null;
      const monthlyPriceFloat = (monthlyPrice && monthlyPrice.toFixed(2)) || null;
      const annualPriceFloat = (annualPrice && annualPrice.toFixed(2)) || null;
      let totalChecks = 0;
      this.setState({
        features,
        totalChecks,
        monthlyPrice,
        annualPrice,
        monthlyPriceFloat,
        annualPriceFloat,
        subscriptionEnabled: 1,
        currencyType,
        currencySymbol
      });
    }
  }
  handleChange = (event, field) => {
    let { features, totalChecks } = this.state;
    let value = (event.target.value === 'false');
    for (let item of features) {
      if(item.title === field) {
        item.checked = value;
      }
    }
    if(value === true)
      totalChecks++;
    else totalChecks--;
    this.setState({ features, totalChecks });
    this.submitFeatures();
  }

  submitFeatures = () => {
    let { features } = this.state;
    let payload = this.state;
    if(this.props.subscriptionEnabled === 0) {
      payload.annualPriceFloat = 1;
      payload.monthlyPriceFloat = 1;
    }
    this.props.updateSubscriptionInfo(payload, this.state.annualPriceError);
  }

  handleChangeNewFeature = (event, field) => {
    this.setState({ [field]: event.target.value });
  }

  addNewFeature = () => {
    const { features } = this.state;
    features.push({ title: this.state.newFeature, checked: false });
    this.setState({ features, newFeature: '', showFeatureForm: false });
  }

  handleAmountChange = (event, field) => {
    let value = event.target.value;
    if(!isNaN(value) || value==''){
      if(value != ''){
        let n = Math.floor(Number(value));
        let condition = value=='' || (n !== Infinity && String(n) === value && n >= 0);
        //value = parseInt(value);
      }
      const previewField = field == 'monthlyPrice' ? 'monthlyPriceFloat' : 'annualPriceFloat';
      if (field == 'monthlyPrice') {
        this.setState({
          'annualPrice': (value * 12),
          'annualPriceFloat': Number(value*12).toFixed(2),
          'annualPriceError':''
        })
      }
      this.setState({ [field]: value, [previewField]: Number(value).toFixed(2) });
      if (field == 'annualPrice') {
        if (field == 'annualPrice') {
          if (+value < +this.state.monthlyPrice) {
            this.setState({
              annualPriceError: 'Annual Price cannot be less then montly price'
            });
            this.props.updateErrorStatus('Annual Price cannot be less then montly price');
          } else if (+value > +(this.state.monthlyPrice * 12)) {
            this.setState({
              annualPriceError:'Annual Price cannot be more than 12 time monthly price.',
            });
            this.props.updateErrorStatus('Annual Price cannot be more than 12 time monthly price.');
          } else {
            this.setState({
              annualPriceError: ''
            });
            this.props.updateErrorStatus('');
          }
        }
      }
      this.submitFeatures();
    }
  }

  changeCurrency = (currency) => {
    //console.log(currency);
    let currencySymbol = this.state.currencyType==='usd'?'$':'र';
    this.setState({ currencyType: currency, currencySymbol })
  }

  toggleSubscription = (value) => {
    this.setState({ subscriptionEnabled: parseInt(value) });
  }
  isEnableSub = () => {
    
  }
  render() {
    const {isOnboard = false } = this.props;
    return (
      <>
        {
          this.props.subscriptionEnabled==1 &&
          <>
            <GridContainer>
            {/*  <GridItem xs={12}>
                <div style={{fontWeight: 'bold', marginTop: '20px', margin: '20px 0 10px'}}>Enter the Currency type for the subscription charges</div>
              </GridItem>
              <GridItem xs={12} md={6}>
                <div style={{display: 'flex'}}>
                  <input type="radio" name="currencyType" value="usd" onChange={()=>this.changeCurrency('USD')} checked={this.state.currencyType==='USD'}/>
                  <span style={{margin: '-5px 20px 0 5px'}}>US DOLLAR</span>
                  <input type="radio" name="currencyType" value="inr" onChange={()=>this.changeCurrency('INR')} checked={this.state.currencyType!=='USD'}/>
                  <span style={{margin: '-5px 20px 0 5px'}}>INDIAN RUPEE</span>
                </div>
              </GridItem>  */}
              <GridItem xs={12}>
                <div style={{fontWeight: 'bold', marginTop: '10px'}}>Enter the Subscription Prices for your website</div>
              </GridItem>
              <GridItem xs={6} md={6} style={{marginTop: '-25px'}}>
                <CustomInput
                  id="monthlyprice"
                  labelText="Enter Monthly Price"
                  inputProps={{
                    onChange: (event)=>{this.handleAmountChange(event, 'monthlyPrice')}
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                  value={this.state.monthlyPrice || ''}
                  helpText={this.state.monthlyPriceError}
                />
                <div style={{fontSize: '0.9em', color: 'gray', marginTop: '-15px'}}>Monthly Plan: {this.state.currencySymbol || '$'}{this.state.monthlyPriceFloat}/ Month</div>
              </GridItem>
              <GridItem xs={6} md={6} style={{marginTop: '-25px'}}>
                <CustomInput
                  id="annualprice"
                  labelText="Enter Annual Price"
                  inputProps={{
                    onChange: (event)=>{this.handleAmountChange(event, 'annualPrice')}
                  }}
                  formControlProps={{
                    fullWidth: true
                  }}
                  helpText={this.state.annualPriceError}
                  value={this.state.annualPrice || ''}
                />
                <div style={{fontSize: '0.9em', color: 'gray', marginTop: '-15px'}}>Annual Plan: {this.state.currencySymbol || '$'}{this.state.annualPriceFloat}/ Year</div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} md={12} style={{marginTop: '50px'}}>
                <div style={{fontWeight: 'bold', marginBottom: '20px'}}>What features would you offer for Subscribers?</div>
                <div style={{fontSize: '0.9em', color: 'gray'}}> You can select atleast 1 item, and atmost 3 items.</div>
              </GridItem>
              {
                this.state.features && this.state.features.length>0 &&
                this.state.features.map((item, index) => {
                  return(
                    <GridItem xs={12} md={12} key={index}>
                      <div style={{padding: '0', fontSize: '1.2em'}}>
                        <input type="checkbox" name={item.title} value={item.checked} onChange={(event)=>this.handleChange(event, `${item.title}`)} checked={item.checked} />
                        <span style={{marginLeft: '5px'}}>{item.title}</span>
                      </div>
                    </GridItem>
                  )
                })
              }
              <GridItem xs={12} sm={4}>
              {
                 !(this.state.showFeatureForm) ?
                 <div onClick={()=>{this.setState({ showFeatureForm: true })}} style={{ display: "flex", alignItems: "center", cursor: "pointer", margin: "20px 0 20px" }}>
                  <AddCircleIcon style={{ color:'#2B2D42' }} />&nbsp;&nbsp;<span style={{ color: "#2B2D42" }}>Add Custom Item</span>
                </div>
                :
                <>
                  <CustomInput
                    id="new Feature"
                    labelText="Enter Text"
                    inputProps={{
                      onChange: (event)=>{this.handleChangeNewFeature(event, 'newFeature')}
                    }}
                    formControlProps={{
                      fullWidth: true
                    }}
                    value={this.state.newFeature}
                  />
                  <Button color="primary" onClick={this.addNewFeature} disabled={this.state.newFeature.trim()===''}>
                    Add
                  </Button>
                </>
              }
              </GridItem>
              <GridItem xs={false} sm={8}>
              </GridItem>
            </GridContainer>
          </>
        }
      </>
    )
  }
}

export default FeatureSelection;
