import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from "components/CustomButtons/Button.jsx";
import withFileUpload from "./with-file-upload";
const FileUploaderAudio = withFileUpload(Button);

const styles = theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  group: {
    margin: `15px 0`,
  },
});


class AudioModalConfirm extends PureComponent {
  state = {
    value: 'fromfile',
  };

  handleChange = event => {
    this.setState({ value: event.target.value });
  };
  setType = () => {
    this.props.setAudioChooseType(this.state.value)
    // this.props.closeAudioModal();
  }
  render() {
    const { classes, showAudioModal, closeAudioModal } = this.props;
    return (
      <Dialog
        open={showAudioModal}
        size="xs"
        style={{
          maxWidth: '100%',
          backgroundColor: 'transparent',
          overflow: 'visible',
          margin: '20px'
        }}
      >
        <DialogContent>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Select Type</FormLabel>
            <RadioGroup
              aria-label="Type"
              name="audiotype"
              className={classes.group}
              value={this.state.value}
              onChange={this.handleChange}
            >
              <FormControlLabel
                value="fromfile"
                control={<Radio color="primary" />}
                label="File"
                labelPlacement="start"
              />
              <FormControlLabel
                value="fromrecording"
                control={<Radio color="primary" />}
                label="Record"
                labelPlacement="start"
              />
            </RadioGroup>
          </FormControl >
        </DialogContent>
        <DialogActions style={{display:'flex',flexWrap:'wrap',justifyContent:'center'}}>
          <Button color="primary" onClick={closeAudioModal} style={{marginLeft:'10px',margin:'auto'}}>
            Close
          </Button>

          {this.state.value == 'fromfile' ? <FileUploaderAudio style={{ marginRight: '10px' ,margin:'auto'}} type="audio/*" color="secondary"  onClick={this.setType}  onChange={(e)=>this.props.handleFileUpload(e,'audio/*')}>
            Continue
          </FileUploaderAudio> :
            <Button color="secondary" style={{ marginRight: '10px',margin:'auto' }} onClick={this.setType}>
              Continue
          </Button>}
        </DialogActions>
      </Dialog>

    )
  }
}

export default withStyles(styles)(AudioModalConfirm);