import React from 'react';
import Button from "components/CustomButtons/Button.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AppLoader from "../../../app-components/app-loader.jsx";


class CSVPreview extends React.Component {

  render() {
    const { openCSVModal, closeModal, uploadCSVPreview = [], confirmUpload, isNewsLetterActionLoading } = this.props;
    const renderData = uploadCSVPreview.slice(1, 50);
    return (
      <>
        <Dialog
          fullWidth
          maxWidth='md'
          id="news-letter-modal"
          open={openCSVModal}
          onClose={closeModal}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title"> CSV Preview <p style={{
            fontSize: '12px',
            fontWeight: '100'
          }}>( preview of first 50 rows )</p> </DialogTitle>
          <DialogContent>
            <Table style={{ minWidth: '650px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>{uploadCSVPreview[0][0]}</TableCell>
                  <TableCell align="right">{uploadCSVPreview[0][1]}</TableCell>
                  <TableCell align="right">{uploadCSVPreview[0][2]}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  renderData.map((ele, key) => {
                    return (
                      <TableRow key={key}>
                        <TableCell align="right">{ele[0]}</TableCell>
                        <TableCell align="right">{ele[1]}</TableCell>
                        <TableCell align="right">{ele[2]}</TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} color="primary">
              Cancel
            </Button>
            <Button disabled={isNewsLetterActionLoading} onClick={confirmUpload} color="primary">
              Confirm
            </Button>
            {isNewsLetterActionLoading && <AppLoader />}
          </DialogActions>
        </Dialog>
      </>
    );

  }
}
export default CSVPreview;

