import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import MoreInfo from './moreInfoModal';
import { GetFullDetailContext } from "../view/campaigns";


class ShowTable extends Component {
  state = {
    openModal: false
  }
  openModal = (getFullDetail) => {
    const { id } = this.props.tableData;
    this.setState({ openModal: true }, () => {
      getFullDetail(id)
    })
  }
  closeModal = () => {
    this.setState({ openModal: false })
  }

  render() {
    const { tableData } = this.props;
    const { id, totalAudience, opened, clicked, unsubscribe } = tableData;
    return (
      <div style={{
        display: 'flex',
        border: '0.5px solid rgba(224, 224, 224, 1)',
        padding: '10px',
        overflow: 'auto'
      }}>
        <Table style={{ minWidth: '650px' }}>
          <TableHead>
            <TableRow>
              <TableCell>Total Audience</TableCell>
              <TableCell align="right">Opened</TableCell>
              <TableCell align="right">Clicked</TableCell>
              <TableCell align="right">Unsubscribe</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            <TableRow >
              <TableCell component="th" scope="row">
                {totalAudience}
              </TableCell>
              <TableCell align="right">{opened}</TableCell>
              <TableCell align="right">{clicked}</TableCell>
              <TableCell align="right">{unsubscribe}</TableCell>
            </TableRow>

          </TableBody>
        </Table>
        <GetFullDetailContext.Consumer>
          {(getFullDetail) => (
            <Button
              color='primary'
              onClick={() => this.openModal(getFullDetail)}
              style={{
                height: '100%',
                alignSelf: 'center'
              }}>
              More details
        </Button>
          )}
        </GetFullDetailContext.Consumer>

        {this.state.openModal && <MoreInfo openViewModal={this.state.openModal} closeModal={this.closeModal} />}
      </div >
    )
  }
}

export default ShowTable;