import React from "react";
import Paper from "@material-ui/core/Paper";
import CloseIcon from "@material-ui/icons/Delete";

class StoryListItem extends React.Component {
  state = {
    showDeleteButton:false,
  }

  showDeleteButtonHandler = () => {
    this.setState({ showDeleteButton: true });
  }

  hideDeleteButtonHandler = () => {
    this.setState({ showDeleteButton: false });
  }
  render() {
    const { item, index } = this.props;
    return(
      <li style={styles.storyListItem} key={`list_today_item_${index}`} onMouseEnter={this.showDeleteButtonHandler} onMouseLeave={this.hideDeleteButtonHandler} >
        <Paper style={{ marginRight:'30px', borderRadius:'5px',}}>
          <div key={`list_item_today_wrapper_${index}`} style={styles.imageContainer}>
            <span onClick={this.props.deleteStory.bind(this, item)} style={{position:'relative', zIndex:100000}}>
              { this.state.showDeleteButton && <CloseIcon style={{ position: 'absolute', left: '5px', marginTop:'5px',borderRadius:'7px', backgroundColor: 'white', cursor: 'pointer', color:'red', fontSize:'25px' }} />}
            </span>
            <img key={`list_item_today_image_${index}`} style={{  borderRadius:'5px' }}  width="100%" height="100%" src={item.storyUrl} />
          </div>
        </Paper>
      </li>
    )
  }
}


const styles = {
  storyList: {
    marginLeft:'-30px',
    overflow: 'auto',
    whiteSpace: "nowrap"
  },
  storyListItem: {
    display: 'inline-block',
  },
  imageContainer: {
    margin:'5px 0px 5px 0px',
    maxWidth: '200px',
    height: '350px',
  }
}

export default StoryListItem;
