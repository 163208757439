const ArticleAction = {
  CREATE: {
    ARTICLE: "ArticleAction.Create.Article"
  },
  LOAD: {
    ARTICLE_DETAILS: {
      REQUEST: "ArticleAction.Load.ArticleDetails.Request",
      SUCCESS: "ArticleAction.Load.ArticleDetails.Success",
      FAILURE: "ArticleAction.Load.ArticleDetails.Failure",
    },
    ARTICLE_CONTENTS: {
      REQUEST: "ArticleAction.Load.ArticleContents.Request",
      SUCCESS: "ArticleAction.Load.ArticleContents.Success",
      FAILURE: "ArticleAction.Load.ArticleContents.Failure",
    },
    ARTICLE_SHARE_TYPE: {
      REQUEST: "ArticleAction.Load.ArticleShareType.Request",
      SUCCESS: "ArticleAction.Load.ArticleShareType.Success",
      FAILURE: "ArticleAction.Load.ArticleShareType.Failure",
    },
    ARTICLE_CATEGORIES: {
      REQUEST: "ArticleAction.Load.ArticleCategories.Request",
      SUCCESS: "ArticleAction.Load.ArticleCategories.Success",
      FAILURE: "ArticleAction.Load.ArticleCategories.Failure",
    },
    ARTICLE_STORY_TYPE: {
      REQUEST: "ArticleAction.Load.ArticleStoryTypes.Request",
      SUCCESS: "ArticleAction.Load.ArticleStoryTypes.Success",
      FAILURE: "ArticleAction.Load.ArticleStoryTypes.Failure",
    },
    ARTICLE_DISCLAIMER: {
      REQUEST: "ArticleAction.Load.ArticleDisclaimer.Request",
      SUCCESS: "ArticleAction.Load.ArticleDisclaimer.Success",
      FAILURE: "ArticleAction.Load.ArticleDisclaimer.Failure",
    }
  },
  SET: {
    ARTICLE_ID: "ArticleAction.Set.ArticleId",
    CONTENTS: "ArticleAction.Set.Contents",
    VIDEO_HEADER: "ArticleAction.Set.VideoHeader",
    ARTICLE_TITLE: "ArticleAction.Set.ArticleTitle",
    ARTICLE_SUMMARY: "ArticleAction.Set.ArticleSummary",
    ARTICLE_COVER_CAPTION: "ArticleAction.Set.CoverCaption",
    CLEAR_ARTICLE_COVER_CAPTION: "ArticleAction.Set.ClearCoverCaption",
    SHARE_TYPE: "ArticleAction.Set.ShareType",
    CATEGORY_TYPE: "ActicleAction.Set.CategoryType",
    SUB_CATEGORY_TYPE: "ActicleAction.Set.SubCategoryType",
    STORY_TYPE: "ArticleAction.Set.StoryType",
    SEO_TITLE: "ArticleAction.Set.SeoTitle",
    OG_TITLE: "ArticleAction.Set.OgTitle",
    STORY_SOURCE: "ArticleAction.Set.storySource",
    POST_CAPTION: "ArticleAction.Set.PostCaption",
    CUSTOM_URL: "ArticleAction.Set.CustomUrl",
    DISCLAIMER_TYPE: "ArticleAction.Set.DisclaimerType",
    ARTICLE_LOCK_DATA: "ArticleAction.Set.ArtcileLockData",
    ARTICLE_TYPE: "ArticleAction.Set.ArticleType",
    DISPLAY_PREVIEW_ACTION: "ArticleAction.Set.DisplayPreviewAction",
    TIME_SPECIFIC: "ArticleAction.Set.TimeSpecific",
    AUTHOR_NAME: "ArticleAction.Set.AuthorName",
    META_TAGS: "ArticleAction.Set.MetaTags",
    META_SUB_TAGS: "ArticleAction.Set.MetaSubTags",
    ARTICLE_ADS_DISABLE: "ArticleAction.Set.ArticleAdsDisable",
    ARTICLE_MSNGALLERY_DISABLE: "ArticleAction.Set.ArticleMsnGalleryDisable",
    COVER_PICTURE: {
      IMAGE: "ArticleAction.Set.coverPicture.Image",
      BLOB: "ArticleAction.Set.coverPicture.Blob",
      CROP_DATA: "ArticleAction.Set.coverPicture.CropData",
      IMAGE_SOURCE: "ArticleAction.Set.coverPicture.ImageSource",
    },

    META_DATA_MODAL: "ArticleAction.Set.MetaDataModal",
    DISCLAIMER_MODAL: "ArticleAction.Set.DisclaimerModal",
    PROGRESS_MODAL: "ArticleAction.Set.ProgressModal",
    SHARE_ARTICLE_MODAL: "ArticleAction.Set.ShareAticleModal",
    SCHEDULE_ARTICLE_MODAL: "ArticleAction.Set.ScheduleAticleModal",
    EXPLOIT_ARTICLE_MODAL: "ArticleAction.Set.ExploitAticleModal",
    SCHEDULE_TWITTER_ARTICLE_MODAL: "ArticleAction.Set.ScheduleTwitterAticleModal",
    PROGRESS_MODAL_DATA: "ArticleAction.Set.ProgressModalData",
    ALLOW_AUTHOR_NAME: "ArticleAction.Set.AllowAuthorName",
    FEEDBACK_MODAL: "ArticleAction.Set.FeedbackModal",
    PREVIEW_MODAL_ACTION: "ArticleAction.Set.PreviewModalAction"
  },
  APPEND: {
    NEW_ITEM: "ArticleAction.Append.NewItem"
  },
  UPLOAD: {
    COVER_PICTURE: {
      REQUEST: "ArticleAction.Upload.CoverPicture.Request",
      FAILURE: "ArticleAction.Upload.CoverPicture.Failure",
      SUCCESS: "ArticleAction.Upload.CoverPicture.Success",
    }
  },
  SUBMIT: {
    REQUEST: "ArticleAction.Submit.Request",
    FAILURE: "ArticleAction.Submit.Failure",
    SUCCESS: "ArticleAction.Submit.Success",
  },
  DELETE_SCHEDULE: {
    REQUEST: "ArticleAction.DeleteReschedule.Request",
    FAILURE: "ArticleAction.DeleteReschedule.Failure",
    SUCCESS: "ArticleAction.DeleteReschedule.Success",
  },
  DELETE_SCHEDULE_AS_EXPLOIT: {
    REQUEST: "ArticleAction.DeleteScheduleAsExploit.Request",
    FAILURE: "ArticleAction.DeleteScheduleAsExploit.Failure",
    SUCCESS: "ArticleAction.DeleteScheduleAsExploit.Success",
  },
  APPROVE: {
    REQUEST: "ArticleAction.Approve.Request",
    FAILURE: "ArticleAction.Approve.Failure",
    SUCCESS: "ArticleAction.Approve.Success",
  },
  PUBLISH: {
    REQUEST: "ArticleAction.Publish.Request",
    FAILURE: "ArticleAction.Publish.Failure",
    SUCCESS: "ArticleAction.Publish.Success",
  },
  UNPUBLISH: {
    REQUEST: "ArticleAction.Unpublish.Request",
    FAILURE: "ArticleAction.Unpublish.Failure",
    SUCCESS: "ArticleAction.Unpublish.Success",
  },
  SEND_FEEDBACK: {
    REQUEST: "ArticleAction.SendFeedback.Request",
    FAILURE: "ArticleAction.SendFeedback.Failure",
    SUCCESS: "ArticleAction.SendFeedback.Success",
  },
  DELETE: {
    REQUEST: "ArticleAction.Delete.Request",
    FAILURE: "ArticleAction.Delete.Failure",
    SUCCESS: "ArticleAction.Delete.Success",
  },
  MSN_MODIFY: {
    REQUEST: "ArticleAction.MSN_MODIFY.Request",
    FAILURE: "ArticleAction.MSN_MODIFY.Failure",
    SUCCESS: "ArticleAction.MSN_MODIFY.Success",
  },
  MOVE_STATE: {
    ACTION: "ArticleAction.MoveState.Action"
  },
  LOCK: {
    REQUEST: 'ArticleAction.Lock.Request',
    FAILURE: 'ArticleAction.Lock.Failure',
    SUCCESS: 'ArticleAction.Lock.Success',
  },
  FINISH_EDIT: {
    REQUEST: 'ArticleAction.FinishEdit.Request',
    FAILURE: 'ArticleAction.FinishEdit.Failure',
    SUCCESS: 'ArticleAction.FinishEdit.Success',
  },
  CLEAR_CACHE: {
    REQUEST: 'ArticleAction.ClearCache.Request',
    FAILURE: 'ArticleAction.ClearCache.Failure',
    SUCCESS: 'ArticleAction.ClearCache.Success',
  },
  REMOVE: {
    DISPLAY_PREVIEW_ACTION: "ArticleAction.Remove.DisplayPreviewAction"
  },
  LAST_LOCATION: {
    SET: "ArticleAction.lastLocation.Set",
  },
  CURRENT_HEIGHT: {
    SET: "ArticleAction.CurrentHeight.Set",
  },
  TOGGLE_ADS: {
    REQUEST: 'ArticleAction.ToggleAds.Request',
    FAILURE: 'ArticleAction.ToggleAds.Failure',
    SUCCESS: 'ArticleAction.ToggleAds.Success',
  },
  NOTIFICATION: {
    REQUEST: 'ArticleAction.Notification.Request',
    FAILURE: 'ArticleAction.Notification.Failure',
    SUCCESS: 'ArticleAction.Notification.Success',
  }
  // SET_STATE: {
  //   CURRENT_STATE: "ArticleAction.Set.CurrentState",
  //   NEXT_STATE: "ArticleAction.Set.NextState"
  // }
}

export default ArticleAction;
