import { combineReducers } from 'redux';
// import { default as AppAction } from "./app-constants/index.js";
import { AppAction } from "./app-constants/index.js";
// import newStory from "./new-story/new-story-reducers.js";
// import articles from "./articles/articles-reducers.js";
// import articleSettings from "./article/article-reducers.js";
import login from "./login/login-reducers.js";
import userManagement from "./user-management/user-management-reducers.js";
// import newsConfigs from "./news-settings/news-settings-reducers.js";
// import newsSettings from "./news/news-reducers.js";
// import tasksPitches from "./tasks-pitches/tasks-pitches-reducers.js";
import userSettings from "./user-settings/user-settings-reducers.js";

import cms from "./cms/cms-reducers.js";
import stories from "./cms/stories/stories-reducer.js";
import scheduleReducers from './scheduler/schedule-reducer.js';
import websiteManagementReducer from './website-management/website-management-reducers.js';
import manageSocialReducer from './manage-social/manage-social-reducers.js';

//rename analytics to analyis
import analysis from "./analytics/analysis-reducers.js";
import toolReducers from "./tool/tool-reducer";



const app = (state = { messages: [], notifications: [], RegisterLogo: null, createType: {} }, action) => {
  const newMessages = Object.assign([], state.messages);
  const existingMessageIdx = newMessages.findIndex(message => (message.message === action.message)
    && (action.params ? message.type === action.params.type : true));

  const newNotifications = [...state.notifications];
  const newNotificationsIdx = newNotifications.findIndex(noti => (noti.message === action.message)
    && (action.params ? noti.type === action.params.type : true));

  switch (action.type) {
    case AppAction.SET.MESSAGE: {
      if (existingMessageIdx === -1) {
        newMessages.push({
          key: Date.now(),
          id: state.messages.length,
          message: action.message,
          type: action.params && action.params.type,
          onOk: action.params.onOk,
          onCancel: action.params.onCancel || null
        })
        return { ...state, messages: newMessages }
      }
      return state;
    }
    case AppAction.CLEAR.MESSAGE: {
      if (newMessages && newMessages.length > 0) {
        newMessages.splice(action.id, 1);
        return { ...state, messages: newMessages };
      }
      return state;
    }

    case AppAction.CLEAR.ALL_MESSAGES: {
      return { ...state, messages: [] };
    }

    case AppAction.ADD.NOTIFICATION: {
      if (newNotificationsIdx === -1) {
        newNotifications.push({
          key: Date.now(),
          id: state.notifications.length,
          message: action.message,
          type: action.params && action.params.type
        });
        return { ...state, notifications: newNotifications };
      }
      return state;

    }
    case AppAction.REMOVE.NOTIFICATION: {
      if (newNotifications && newNotifications.length > 0) {
        newNotifications.splice(action.id, 1);
        return { ...state, notifications: newNotifications }
      }
      return state;
    }
    case AppAction.REMOVE.ALL_NOTIFICATIONS: {
      return { ...state, notifications: [] };
    }
    case 'register.newuser.logo': {
      const { RegisterLogo = null } = action;
      return { ...state, RegisterLogo }
    }
    case 'resiterDomain.request': {
      return { ...state, isDomainLoading: true }
    }
    case 'resiterDomain.success': {
      return { ...state, isDomainLoading: false }
    }
    case 'resiterDomain.failure': {
      return { ...state, isDomainLoading: false }
    }
    case AppAction.SETWEBSITE_INFO.SUCCESS: {
      let { createType } = action;
      createType = Object.freeze(createType)
      return { ...state, createType }
    }
    default: return state;
  }
}

const reducers = combineReducers({
  app: app,
  // newStory: newStory,
  cms: cms,
  // articleSettings: articleSettings,
  login: login,
  stories: stories,
  scheduleReducers: scheduleReducers,
  userManagement: userManagement,
  // newsConfigs: newsConfigs,
  // newsSettings: newsSettings,
  // tasksPitches: tasksPitches,
  userSettings: userSettings,
  // articles: articles,
  analysis: analysis,
  websiteManagementReducer: websiteManagementReducer,
  manageSocialReducer: manageSocialReducer,
  tool: toolReducers,
})

export default reducers;
