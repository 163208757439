class GalleryItem {
  constructor({ order = 1, title = "", sumamry = "", image = "" } = {}) {
    this.title = title;
    this.sumamry = sumamry;
    this.image = image;
    this.order = order;
  }
  getOrder() {
    return this.order;
  }
  getTitle() {
    return this.title;
  }
  getSummary() {
    return this.sumamry;
  }
  getImage() {
    return this.image;
  }
  setTitle(title) {
    this.title = title;
    return this;
  }
  setSummary(summary) {
    this.summary = summary;
    return this;
  }
  setImage(image) {
    this.image = image;
    return this;
  }

  isValidTitle() {
    return this.title.length >= 50 && this.title.length <= 150;
  }
  isValidSummary() {
    return this.sumamry.length >= 100 && this.sumamry.length <= 800;
  }
  isValidImage() {
    return !!this.image;
  }
  isValidItem() {
    return this.isValidTitle() && this.isValidSummary() && this.isValidImage()
  }
}
export default GalleryItem;