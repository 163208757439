import React from 'react';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import hamburger from '../assets/icons/hamburger.svg';
import logo from '../assets/img/pubninja-logo.png';
import './header.scss';

function toggleNav() {
  //document.getElementById('header-nav').classList.toggle('hidden');
  let navItems = document.getElementsByClassName('mobile-nav');
  for (let navItem of navItems) {
    navItem.classList.toggle('hidden');
  }
}

function Header(props) {
  const { hideSignIn = true } = props;
  return (
    <div className="website-header">
      <div className="website-header-inner">
        <GridContainer>
          <GridItem xs={3}>
            {/*
            <div className="hamburger-container">
              <img src={hamburger} />
            </div>
            */}
          </GridItem>
          <GridItem xs={6}>
            <div className="navbar-mob-top">
              <div className="navbar-logo">
                  <img className="logo" src={logo} />
              </div>
            </div>
          </GridItem>
          <GridItem md={3}>

          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

export default Header;
