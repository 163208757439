import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import AddDomain from "./domains/views/add-domain";


class connectDomainPage extends PureComponent {
  render() {
    const {} = this.props;
    return (<div>
      <Helmet title="Connect Domain | PubNinja - You Publish. The rest we manage." />
      {this.props.children}
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(connectDomainPage);
