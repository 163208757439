import React, { Component } from 'react';
import Hidden from '@material-ui/core/Hidden';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
//import getFeeds, { moveFeed, resetFeed } from "../feeds-actions";
import AssociatedPressContainer from "../views/AssociatedPressCard.jsx";
import Button from "components/CustomButtons/Button.jsx";
import AppLoader from "../../../app-components/app-loader.jsx";
import feedStyle from "../../../assets/jss/material-dashboard-pro-react/components/feedStyle.jsx";


class FeedContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRefreshButton: false,
    }
  }
  // buttonShow() {
  //   const ref = this;
  //   setTimeout(() => {
  //     ref.setState({ showRefreshButton: true })
  //   }, 45 * 1000)
  // }
  // componentDidMount() {
  //   // this.props.resetFeed()
  //   // this.props.getFeeds();
  //   const ref = this;
  //   setTimeout(() => {
  //     ref.setState({ showRefreshButton: true })
  //   }, 45 * 1000)
  // }
  // updateFeed = () => {
  //   this.setState({ showRefreshButton: false });
  //   this.props.getFeeds(true);
  //   this.forceUpdate();
  //   this.buttonShow();
  // }

  render() {
    //const feedType = 'associated-press';
    const { twitterFeeds, instaFeeds, youtubeFeeds, moveFeed, feedType, feedMessage, classes } = this.props;
    // const { isLoadingTwitterFeed } = twitterFeeds;
    // const { isLoadingInstaFeed } = instaFeeds;
    // const { isLoadingYouTubeFeed } = youtubeFeeds;
    return (
      <>
        <GridContainer>
          {
            feedType === 'associated-press' && <GridItem id="unheiglightContainer" sm={12} md={12} style={{ textAlign: 'center' }}>

                <AssociatedPressContainer/>

            </GridItem>
          }
        </GridContainer>
      </>
    )
  }
}

export default (withStyles(feedStyle)(FeedContainer));
