import React from 'react';

import Apps from "../components/Icon/App.jsx";
import scheduleIcon from "../assets/icons/distribution-icon.png";
import ManageSocialHome from "./manage-social-home.jsx";
import ManageSocialAccounts from "./views/manage-social-accounts.jsx";
import Facebook from './fb/facebook';
import Twitter from './twitter/twitter';
import NewsLetter from "./news-letter/news-letter-view";
import SelectType from "./news-letter/view/select-type";

const Icon = () => <Apps src={scheduleIcon} />;

var manageSocialRoutes = [
  {
    collapse: true,
    path: "/manage-social",
    name: "Share",
    state: "openManageSocial",
    icon: Icon,
    isRequiredAuth: true,
    component: ManageSocialHome,
    requiredPermissions: ["SHARE:SOCIAL_SCHEDULE", "SHARE:MANAGE_ACCOUNTS", "SHARE:NEWSLETTER"],
    views: [
      {
        path: "/manage-social/facebook",
        name: "Facebook Scheduler",
        mini: "FB",
        isRequiredAuth: true,
        component: Facebook,
        requiredPermissions: ["SHARE:SOCIAL_SCHEDULE"],
        requiredFeaturePermision: 'SOCIAL_SCHEDULE',
      },
      {
        path: "/manage-social/twitter",
        name: "Twitter Scheduler",
        mini: "TS",
        isRequiredAuth: true,
        component: Twitter,
        requiredFeaturePermision: 'SOCIAL_SCHEDULE',
        requiredPermissions: ["SHARE:SOCIAL_SCHEDULE"]
      },
      {
        path: "/manage-social/manage",
        name: "Manage Social Accounts",
        mini: "MN",
        isRequiredAuth: true,
        dontShowInSideBar: true,
        component: ManageSocialAccounts,
        requiredFeaturePermision: 'SOCIAL_SCHEDULE',
        requiredPermissions: ["SHARE:MANAGE_ACCOUNTS"]
      },
      // {
      //   path: "/manage-social/news-letter",
      //   name: "News Letter",
      //   mini: "NL",
      //   isRequiredAuth: true,
      //   component: NewsLetter,
      //   // requiredFeaturePermision: 'SOCIAL_SCHEDULE',
      //   // requiredPermissions: ["SOCIAL_MANAGE"]
      // },
      {
        path: "/manage-social/news-letter",
        name: "Newsletter",
        mini: "NL",
        isRequiredAuth: true,
        component: SelectType,
        requiredFeaturePermision: 'NEWSLETTER',
        requiredPermissions: ["SHARE:NEWSLETTER"]
      },
      // {
      //   path: "/distribution/news-letter",
      //   name: "Manage News Letter",
      //   mini: "NL",
      //   isRequiredAuth: true,
      //   component: NewsLetter,
      //   requiredFeaturePermision: 'NEWSLETTER',
      //   requiredPermissions: ["NEWSLETTER"]
      // }
    ]
  },
];


export default manageSocialRoutes;
