import React from 'react';
import { Modal } from 'antd';
import Button from "components/CustomButtons/Button.jsx";
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import 'antd/dist/antd.css';

class MyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      buttonText: '',
    };
    this.showModal = this.showModal.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  };
  showModal() {
    this.setState({
      visible: true,
    });
  }
  handleOk(e) {
    this.setState({
      visible: false,
    });
    this.props.onSuccessClick();
  }
  handleCancel(e) {
    this.setState({
      visible: false,
    });
    this.props.onCancelClick();
  }
  render() {
    return (
      <div style={{ display: 'inline' }}>
        <Button color="primary" onClick={this.showModal}>
          {this.props.buttonTitle}
        </Button>
        <Modal
          title={this.props.modalTitle}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          {this.props.bodyText}
        </Modal>
      </div>
    );
  }
}

MyModal.propTypes = {
  buttonSuccessText: PropTypes.node,
  buttonCancelText: PropTypes.node,
  onCancelClick: PropTypes.func,
  onSuccessClick: PropTypes.func,
  bodyText: PropTypes.node,
  modalTitle: PropTypes.node,
  buttonTitle: PropTypes.node,
};

export default MyModal;
