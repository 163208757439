

// Editer pick reducer
// import { combineReducers } from "redux";
import {
  EditerPickAction, UserAction
} from "../../app-constants/index.js";

const defaultEditerPick = {
  editerPick: [],
  articles: [],
  updateEditerPickID: null,
  isEditerPickLoading: false,
  isArticlesLoading: false,
  feedsList: []
}
function editerPick(state = defaultEditerPick, action) {
  switch (action.type) {
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return defaultEditerPick
    }
    case EditerPickAction.LOAD.REQUEST: {
      return { ...state, isEditerPickLoading: true }
    }
    case EditerPickAction.LOAD.FAILURE: {
      return { ...state, isEditerPickLoading: false }
    }
    case EditerPickAction.LOAD.SUCCESS: {
      const { editerPick } = action;
      return {
        ...state,
        editerPick,
        isEditerPickLoading: false
      }
    }
    case EditerPickAction.LOADARTICLE.REQUEST: {
      return { ...state, isArticlesLoading: true }
    }
    case EditerPickAction.LOADARTICLE.FAILURE: {
      return { ...state, isArticlesLoading: false }
    }
    case EditerPickAction.LOADARTICLE.SUCCESS: {
      const { articles } = action;
      return { ...state, articles, isArticlesLoading: false }
    }
    case EditerPickAction.UPDATE.SETID: {
      const { updateEditerPickID } = action;
      return { ...state, updateEditerPickID }
    }
    case EditerPickAction.LIST.SUCCESS: {
      const { feedsList } = action;
      return { ...state, feedsList, isEditerPickLoading: false }
    }
    //call on add
    case EditerPickAction.UPDATE.EDITERPICK: {
      const { article } = action;
      const { editerPick, updateEditerPickID } = state;
      editerPick.forEach(({ articleId }, key) => {
        if (updateEditerPickID == articleId) {
          editerPick[key] = article;
        }
      })
      return { ...state, ['editerPick']: editerPick.concat() }
    }
    default: return state;
  }
}

export default editerPick;
