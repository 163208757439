import React from 'react';
import DisclaimersContainer from './views/disclaimers-container';

function Disclaimers() {
  return (
    <DisclaimersContainer/>
  );
}

export default Disclaimers;
