import React from 'react';

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Assignment from "@material-ui/icons/Assignment";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Datetime from 'react-datetime';
import moment from 'moment';

class DateTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: '',
    };
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    this.setState({ time: e.target.value });
    this.props.time(e.target.value);
  }
  changeDateTime = (value) => {
    let curTime = moment().toDate();
    let newTime = moment(value).toDate();
    var diff = moment(newTime).diff(curTime, 'days')
    this.setState({time: moment(newTime).format(`YYYY-MM-DDTHH:mm`)});
  }

  render() {
    return (<CardBody>
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon>
            <Assignment  color="secondary"/>
          </CardIcon>
          <h4 style={{'color': '#000'}}>Schedule On Page</h4>
        </CardHeader>
        <CardBody>
        <div >
          <Datetime
            inputProps={{
              placeholder: "Select Date and Time",
              value: this.state.time==''?'Select Date':moment(this.state.time).format("DD/MM/YYYY, hh:mm A") || 'Select Date',
              style: {minWidth: '200px', maxWidth: '250px', margin: '25px 0'}
            }}
            onChange={(event)=>this.changeDateTime(event)}
          />
        </div>
        </CardBody>
      </Card>
    </CardBody>);
  }
}

export default DateTime;
