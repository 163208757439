import { MeawwApi } from "../utils/api.js";
import { setAppMessage } from "../app-actions.js";
import { MessageTypes } from "../app-constants/index.js";

import {
  NewStoryAction
} from "../app-constants";

export function hidePiscoModal() {
  return { type: NewStoryAction.PISCO.CLOSE }
}

export function storeFacbookPage(arrPages) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/schedule-manage/${activeDomain}/page`, arrPages,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function getFacbookPage() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    const path = `/schedule-manage/${activeDomain}/page`;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(path,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message || JSON.stringify(error), { type: MessageTypes.ERROR }))
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}
export function fetchFbRequest() {
  return {
    type: 'fetchFbRequest'
  }
}
export function fetchFbSuccess() {
  return {
    type: 'fetchFbSuccess'
  }
}
export function fetchFbFailure() {
  return {
    type: 'fetchFbFailure'
  }
}
export function closeFbModal() {
  return {
    type: 'closeFbModal'
  }
}

export function changeSortMethod(sortMethod) {
  return {
    type: 'changeSortMethod',
    sortMethod
  }
}

export function scheduleRequest() {
  return {
    type: 'ScheduleRequest'
  }
}

export function scheduleSuccess(message) {
  return {
    type: 'ScheduleSuccess',
    message
  }
}

export function scheduleFailure(message) {
  return {
    type: 'ScheduleFailure',
    message
  }
}

export function deleteRequest() {
  return {
    type: 'DeleteRequest'
  }
}

export function deleteSuccess(message) {
  return {
    type: 'DeleteSuccess',
    message
  }
}

export function deleteFailure(message) {
  return {
    type: 'DeleteFailure',
    message
  }
}

export function invalidateSuccess(message) {
  return {
    type: 'InvalidateSuccess',
    message
  }
}

export function invalidateFailure(message) {
  return {
    type: 'InvalidateFailure',
    message
  }
}

export function rescheduleRequest() {
  return {
    type: 'RescheduleRequest'
  }
}

export function rescheduleSuccess(message) {
  return {
    type: 'RescheduleSuccess',
    message
  }
}

export function rescheduleFailure(message) {
  return {
    type: 'RescheduleFailure',
    message
  }
}

export function closeLoaderModal() {
  return {
    type: 'ScheduleClose'
  }
}

export function hasReceivedNewScheduleData() {
  return {
    type: 'hasReceivedNewScheduleData'
  }
}

export function setRefreshPages() {
  return {
    type: 'setRefreshPages'
  }
}

export function removeRefreshPages() {
  return {
    type: 'removeRefreshPages'
  }
}

export function postAccessToken(data) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/schedule-manage/${activeDomain}/token`, data,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function deleteAccessToken(data) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.delete(`/schedule-manage/${activeDomain}/token`, data,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }))
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function schedulePost(scheduleData) {
  return (dispatch, getState) => {
    dispatch(scheduleRequest());
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/schedule-manage/${activeDomain}`, scheduleData,
          //return api.post(`/schedule/${activeDomain}/page`,
          (error, data) => {
            if (error) {
              dispatch(scheduleFailure(error.message));
              return Promise.reject(error);
            }
            dispatch(scheduleSuccess(data.message));
            return Promise.resolve(data);
          });
      });
  }
}

export function scheduleTweet(scheduleData) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    scheduleData.domain_id = activeDomain;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/schedule/post/tweet`, scheduleData,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}
function facebookpagenotfound(){
  return {
    type: 'facebookpagenotfound',
  }
}

function startLoadingFacebook(){
  return {
    type: 'startLoadingFacebook'
  }
}
export function getPost() {
  return (dispatch, getState) => {
    dispatch({type: 'startLoadingFacebook'});
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        let requestUrl = `/schedule-manage/${activeDomain}`;
        return api.get(requestUrl,
          (error, data) => {
            if (error) {
              //console.log(`errrr`);
              dispatch(facebookpagenotfound());
              //dispatch(setAppMessage(error.message || JSON.stringify(error), { type: MessageTypes.ERROR }))
              return Promise.reject(error);
            }
            dispatch({type: 'facebookpagefound'});
            return Promise.resolve(data);
          });
      });
  }
}

export function getPostNew(pageName, direction, key) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        let requestUrl = `/schedule-manage/${activeDomain}?page=${pageName}&${direction}=${key}`;
        return api.get(requestUrl,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message || JSON.stringify(error), { type: MessageTypes.ERROR }))
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function getTweets() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/schedule/get/tweets?domain_id=${activeDomain}`,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function verifyUser() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/schedule/verify/user`,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function getAccount() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/schedule/get/accounts?domain_id=${activeDomain}`,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function deleteTweet(scheduleData) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/schedule/delete/tweet`, { id: scheduleData.tweetId },
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function deletePost(scheduleData) {
  return (dispatch, getState) => {
    dispatch(deleteRequest());
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.delete(`/schedule-manage/${activeDomain}`, scheduleData,
          (error, data) => {
            if (error) {
              dispatch(deleteFailure(error.message));
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }))
              return Promise.reject(error);
            }
            dispatch(deleteSuccess(data.message));
            dispatch(setAppMessage(data.message, { type: MessageTypes.SUCCESS }))
            return Promise.resolve(data);
          });
      });
  }
}

export function shareDebugPost(shareDebugPostObj) {
  return (dispatch, getState) => {
    // dispatch(deleteRequest());
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/schedule-manage/${activeDomain}/invalidate-post`, shareDebugPostObj,
          (error, data) => {
            if (error) {
              dispatch(invalidateFailure(error.message));
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }))
              return Promise.reject(error);
            }
            dispatch(invalidateSuccess(data.message));
            dispatch(setAppMessage(data.message, { type: MessageTypes.SUCCESS }))
            return Promise.resolve(data);
          });
      });
  }
}

export function reschedulePost(rescheduleData) {
  return (dispatch, getState) => {
    dispatch(rescheduleRequest());
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.put(`/schedule-manage/${activeDomain}`, rescheduleData,
          (error, data) => {
            if (error) {
              dispatch(rescheduleFailure(error.message));
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }))
              return Promise.reject(error);
            }
            dispatch(rescheduleSuccess(data.message));
            dispatch(setAppMessage(data.message, { type: MessageTypes.SUCCESS }))
            return Promise.resolve(data);
          });
      });
  }
}

export function rescheduleTweet(rescheduleData) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/schedule/update/tweet`, rescheduleData,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function updateUser(userdata) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    userdata.domain_id = activeDomain;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/schedule/update/user`, userdata,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}

export function removeUser(data) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/schedule/remove/user`, { id: data },
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      });
  }
}
