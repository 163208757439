import { combineReducers } from "redux";
import revenue from "./revenue-analysis/revenue-reducers.js";
import statistics from './statistics/statistics-reducers.js';
import accountInfo from './account-info/account-info-reducers.js';

const analysis = combineReducers({
    revenue,
    accountInfo,
    statistics
});
export default analysis;
