import React from 'react';
import { convertToHTML } from 'draft-convert';


export const styleToHTML = (style) => {
  //console.log("style", style);
  switch (style) {
    default:
      return null;
  }
};

export const blockToHTML = (block) => {
  const blockType = block.type;
  const blockClass = blockType.toLowerCase();
  switch (blockType) {
    case 'atomic:image': {
      const imgData = block.data;
      const text = block.text;
      return {
        start: `<figure class="image"><img id="figure-image" src="${imgData.src}" alt="${block.text}" /><figcaption>`,
        end: '</figcaption></figure>',
      };
    }
    case "atomic": {
      return {
        start: '',
        end: '',
      };
    }
    default: return null;
  }
};

export const entityToHTML = (entity, originalText) => {

  switch (entity.type) {
    case 'LINK':
      const { innerText } = entity.data;
      return `<a href="${entity.data.url}" target="${entity.data.newTab ? '_blank' : '_self'}">${originalText || innerText}</a>`;
    case 'EMBED':
      return (
        <blockquote className="embedly-card blockquote-container"><a className="embedly-card" href={entity.data.src} >{entity.data.src}</a></blockquote>
      );
    case 'MEDIA_UPLOAD': {
      const cap = entity.data.isLink ? `<a href=${entity.data.caption}>${entity.data.caption}</a>` : entity.data.caption;
      return `<figure class="image"><img src=${entity.data.src} /><figcaption>${cap == undefined ? '' : cap}</figcaption></figure>`
    }
    default:
      return null;
  }
};

export const options = {
  styleToHTML,
  blockToHTML,
  entityToHTML,
};

export default (contentState, htmlOptions = options) => convertToHTML(htmlOptions)(contentState);