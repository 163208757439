import React from "react";
//pages
import Apps from "@material-ui/icons/Apps";
import AdminPage from "./admin-page";
import ConnectDomainPage from "./domains/connect-domain-page";
import WordpressImportPage from "./wordpress-import/wordpress-import-page";
import GdprCompliance from "./gdpr-compliance/gdpr-compliance-page";
import { default as Icons } from "../components/Icon/App.jsx";
import adminPng from "../assets/icons/admin.svg";

const Icon = () => <Icons src={adminPng} />;

var connectDomainsRoutes = [
  {
    collapse: true,
    path: "/admin",
    name: "Admin",
    state: "openAdmin",
    icon: Icon,
    component: AdminPage,
    requiredPermissions: ["CREATE:WRITE"],
    requiredFeaturePermision:'DEMO_FEATURES',
    views: [
      {
        path: "/admin/connect-domain",
        name: "Connect Domain",
        mini: "CD",
        isRequiredAuth: true,
        component: ConnectDomainPage,
        requiredPermissions: ["CREATE:WRITE"],
        requiredFeaturePermision: 'DEMO_FEATURES'
      },
      {
        path: "/admin/wordpresss-import",
        name: "Wordpress Import",
        mini: "WI",
        isRequiredAuth: true,
        component: WordpressImportPage,
        requiredPermissions: ["CREATE:WRITE"],
        requiredFeaturePermision: 'DEMO_FEATURES'
      },
      {
        path: "/admin/gdpr-compliance",
        name: "GDPR Compliance",
        mini: "GC",
        isRequiredAuth: true,
        component: GdprCompliance,
        requiredPermissions: ["CREATE:WRITE"],
        requiredFeaturePermision: 'DEMO_FEATURES'
      },
    ]
  }
];

export default connectDomainsRoutes;
