import React, { PureComponent } from 'react';
import ArticleContainer from './article-container.jsx';

class ArticlListContainer extends PureComponent {
  render() {
    const { articlesByIds, articleLetter, setArticleIdForMenuList, articlesIds, setAnchorElForMenu } = this.props;
    return (
      <div ref={this.props.innerRef}>
        {articlesIds && (articlesIds.length > 0) && articlesIds.map((articleId, key) => {
          const article = articlesByIds[articleId];
          return (
            article && <ArticleContainer setAnchorElForMenu={setAnchorElForMenu} key={articleId} setArticleIdForMenuList={setArticleIdForMenuList} article={article} articleLetter={articleLetter} articleId={articleId} />
          )
        })
        }
      </div>
    )
  }
}
export default ArticlListContainer;
