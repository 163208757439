const UserAction = {
  SIGNIN: {
    REQUEST: "UserAction.SignIn.Request",
    SUCCESS: "UserAction.SignIn.Success",
    FAILURE: "UserAction.SignIn.Failure"
  },
  SET: {
    FORGOT_PASSWORD: "UserAction.Set.ForgotPassword",
    FORM_DATA: "UserAction.Set.FormData",
    SIGNUP_OTP_MODAL: "UserAction.Set.SignupOtpModal",
    SIGNUP_EMAIL: "UserAction.Set.SignupEmail",

    USER_DATA: {
      REQUEST: "UserAction.Set.UserData.Request",
      FAILURE: "UserAction.Set.UserData.Failure",
      SUCCESS: "UserAction.Set.UserData.Success",
    },
    ACTIVE_DOMAIN: "UserAction.set.ActiveDomain"
  },
  SIGNUP: {
    REQUEST: "UserAction.Signup.Request",
    FAILURE: "UserAction.Signup.Failure",
    SUCCESS: "UserAction.Signup.Success",
    USER_DATA: {
      REQUEST: "UserAction.Signup.UserData.Request",
      FAILURE: "UserAction.Signup.UserData.Failure",
      SUCCESS: "UserAction.Signup.UserData.Success",
    }
  },
  RESET_PASSWORD: {
    REQUEST: "UserAction.ResetPassword.Request",
    FAILURE: "UserAction.ResetPassword.Failure",
    SUCCESS: "UserAction.ResetPassword.Success",
  },
  VERIFYING_OTP: {
    REQUEST: "UserAction.VerifyingOtp.Request",
    FAILURE: "UserAction.VerifyingOtp.Failure",
    SUCCESS: "UserAction.VerifyingOtp.Success"
  },
  UPDATE_PASSWORD: {
    REQUEST: "UserAction.UpdatePassword.Request",
    FAILURE: "UserAction.UpdatePassword.Failure",
    SUCCESS: "UserAction.UpdatePassword.Success",
  },
  SIGN_OUT: {
    REQUEST: "UserAction.SignOut.Request",
    SUCCESS: "UserAction.SignOut.Success",
    FAILURE: "UserAction.SignOut.Failure"
  },
  USER: {
    UPDATE_PROFILE: {
      REQUEST: "UserAction.User.UpdateProfile.Request",
      SUCCESS: "UserAction.User.UpdateProfile.Success",
      FAILURE: "UserAction.User.UpdateProfile.Failure"
    },

  },
  USER_DATA: {
    UPDATE: "UserAction.UserData.Update"
  },
  FETCH: {
    DOMAINS: {
      REQUEST: "UserAction.Fetch.DomainList.Request",
      SUCCESS: "UserAction.Fetch.DomainList.Success",
      FAILURE: "UserAction.Fetch.DomainList.Failure",
    }
  }
  ,
  DOMAIN_PERMISION: {
    SUCCESS: "UserAction.DomainPermision.Success",
  }
}
export default UserAction;
