import { MeawwApi } from "../../utils/api.js";
import { setAppMessage } from "../../app-actions.js";
import { MessageTypes } from "../../app-constants/index.js";
import { ArticlesAction, ArticlesState, ArticlesAPIState } from "../../app-constants/index.js";
import { setArticleIdForMenuList } from "../article/article-action.js";

function getArticlesRequest(articleState) {
  return {
    type: ArticlesAction.LOAD.REQUEST,
    articleState: articleState
  }
}
function getArticlesFailure(articleState) {
  return {
    type: ArticlesAction.LOAD.FAILURE,
    articleState
  }
}
function resetCurrentPageId(articleState) {
  return {
    type: ArticlesAction.RESET.CURRENT_PAGE_ID,
    currentPage: 0,
    articleState
  }
}
export function loadArticlesSuccess(articles, nextPage, articleState) {
  return {
    type: ArticlesAction.LOAD.SUCCESS,
    articles,
    nextPage,
    articleState,
  }
}

export function newArticlesSuccess(articles, articleState) {
  return {
    type: ArticlesAction.LATEST.SUCCESS,
    nextPage: 2,
    articles,
    articleState,
  }
}

function searchArticleReset() {
  return {
    type: ArticlesAction.SEARCH.RESET,
    articleState: ArticlesState.LIVE_ARTICLES
  }
}

export function toggleConfirmationBox(showConfirmationModal, confirmationMessage, onConfirm) {

  return {
    type: ArticlesAction.CONFIRMATION_BOX.TOGGLE,
    showConfirmationModal,
    confirmationMessage,
    onConfirm,
  }
}

function getArticleParams(articleState) {
  if (articleState === ArticlesState.SUBMITTED_ARTICLES) {
    return ArticlesAPIState.SUBMITTED_ARTICLES + "&" + ArticlesAPIState.IN_REVIEW;
  }
  else if (articleState == ArticlesState.APPROVED_ARTICLES) {
    return ArticlesAPIState.APPROVED_ARTICLES;
  }
  else if (articleState == ArticlesState.QUEUED_ARTICLES) {
    return ArticlesAPIState.QUEUED_ARTICLES;
  }
  else if (articleState == ArticlesState.LIVE_ARTICLES) {
    return ArticlesAPIState.LIVE_ARTICLES;
  }
  else if (articleState == ArticlesState.DRAFT_ARTICLES) {
    return ArticlesAPIState.DRAFT_ARTICLES;
  }
}

export function getArticles(articleState) {
  let paramArticleType = getArticleParams(articleState);
  return (dispatch, getState) => {
    const { cms, login: { user: { activeDomain } } } = getState();

    const { articlesSettings: { currentPage, articlesPerPage, hasMoreData } } = cms.articles[articleState.charAt(0).toLowerCase() + articleState.substr(1)];
    if (!hasMoreData) {
      return Promise.resolve();
    }

    dispatch(getArticlesRequest(articleState));
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/article/${activeDomain}?${paramArticleType}&page=${currentPage + 1}&perPage=${articlesPerPage}`,
          (error, data) => {
            if (error) {
              dispatch(getArticlesFailure(articleState));
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }))
              return Promise.reject(error);
            }
            dispatch(loadArticlesSuccess(data.result, currentPage + 1, articleState));
            return;
          });
      });
  }
}

export function getNewArticles(articleState) {

  let paramArticleType = getArticleParams(articleState);
  return (dispatch, getState) => {
    const { login: { user: { activeDomain } } } = getState();
    dispatch(getArticlesRequest(articleState));
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/article/${activeDomain}?${paramArticleType}&page=${1}&perPage=${30}`,
          (error, data) => {
            if (error) {
              //dispatch(getArticlesFailure(articleState));
              //dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }))
              return Promise.reject(error);
            }
            dispatch(newArticlesSuccess(data.result, articleState));
            return;
          });
      });
  }
}

export function showLiveTab(articleState) {
  return {
    type: ArticlesAction.SEARCH.SHOW_LIVE_TAB,
    articleState
  }
}
export function hideLiveTab(articleState) {
  return {
    type: ArticlesAction.SEARCH.HIDE_LIVE_TAB,
    articleState
  }
}
export function resetCurrentPage(articleState) {
  return resetCurrentPageId(articleState)
}
export function resetSearchArticle() {
  return searchArticleReset();
}
export function liveSearch(searchKeyword, category, subcategory, author, liveDate) {
  return (dispatch, getState) => {
    const { cms, login: { user: { activeDomain } } } = getState();
    const { articlesSettings: { currentPage, articlesPerPage, hasMoreData } } = cms.articles[ArticlesState.LIVE_ARTICLES.charAt(0).toLowerCase() + ArticlesState.LIVE_ARTICLES.substr(1)];
    // search article reset
    dispatch(searchArticleReset())
    dispatch(resetCurrentPage(ArticlesState.LIVE_ARTICLES))
    dispatch(getArticlesRequest(ArticlesState.LIVE_ARTICLES));
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/article/${activeDomain}/search`, {
          "searchQuery": searchKeyword,
          category,
          subcategory,
          author,
          liveDate,
          "page": 1,
          "perPage": 100
        },
          (error, data) => {
            if (error) {
              dispatch(getArticlesFailure(ArticlesState.LIVE_ARTICLES));
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }))
              return Promise.reject(error);
            }
            dispatch(loadArticlesSuccess(data.result, currentPage + 1, ArticlesState.LIVE_ARTICLES));
            return;
          });
      });
  }
}

export function getMyArticlesPv(page=1) {
  let queryParam = `?page=${page}`;
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/article_pv/${activeDomain}/my_articles_pv${queryParam}`,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      })
  }
}

export function getAggregatedStats() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/article_pv/${activeDomain}/aggregated`,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data);
          });
      })
  }
}



