import React from 'react';
import ReactTable from "react-table";
import moment from "moment";

class AdsRevenueTable extends React.Component {
  add(data, prop) {
    var total = 0
    for (var i = 0, _len = data.length; i < _len; i++) {
      total += data[i][prop]
    }
    return total
  }

  calTableTotal = (data) => {
    const adsRev = this.add(data, "adsRev").toFixed(2);

    return { adsRev }
  }
  render() {
    const { data } = this.props;
    let { adsRev  } = data.length > 0 && this.calTableTotal(data);

    return (
      <>
        <ReactTable
          data={data}
          noDataText="No Data"
          defaultPageSize={10}
          pageSizeOptions={[5, 10, 25, 50]}
          columns={[
            {
              Header: "Date",
              headerStyle: { textAlign: "left", fontWeight: "bold" },
              accessor: 'date',
            },
            {
              Header: "Revenue",
              headerStyle: { textAlign: "left", fontWeight: "bold" },
              accessor: d => d.adsRev.toFixed(2),
              id: 'Revenue',
              Footer: (
                adsRev && <span>
                  <strong>Total:</strong>{" "}
                  {adsRev}
                </span>
              )
            }
          ]}
        />
      </>
    )
  }
}

export default AdsRevenueTable;
