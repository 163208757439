import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import Posts from "./views/posts.jsx";

class PostsHomePage extends PureComponent {
  render() {
    return (
      <div>
        <Helmet title="Stories and Posts | PubNinja - You Publish. The rest we manage." />
        <Posts />
      </div>)
  }
}

export default PostsHomePage;
