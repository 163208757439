import { AccountManagementAction, UserPermisssionAction, UserAction } from "../../app-constants/index.js";

const accountManagementDefault = {
  isFetchingUserList: false,
  userList: {},
  domainPermissions: {},
  isFetchingDomainPermissions: false,
};

const updateDomainPermissionsStatus = (domainPermissions, permissionName, status) => {
  const updatedPermissions = {};
  Object.entries(domainPermissions).forEach(([permimssionKey, permission]) => {
    if (permimssionKey !== permissionName) {
      updatedPermissions[permimssionKey] = permission;
    } else {
      updatedPermissions[permimssionKey] = {
        ...permission,
        isPermissionUpdating: status
      }
    }
  })
  return updatedPermissions;
}

function accountManagement(state = accountManagementDefault, action) {
  switch (action.type) {
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return accountManagementDefault;
    }

    case AccountManagementAction.FETCH.USER_LIST.REQUEST: {
      return { ...state, isFetchingUserList: true }
    }

    case AccountManagementAction.FETCH.USER_LIST.FAILURE: {
      return { ...state, isFetchingUserList: false }
    }

    case AccountManagementAction.FETCH.USER_LIST.SUCCESS: {
      const { userList = [] } = action;
      const userListMap = { ...state.userList };

      userList.forEach(user => {
        userListMap[user.id] = { ...userListMap[user.id], ...user }
      });

      return {
        ...state,
        isFetchingUserList: false,
        userList: userListMap
      }
    }
    case UserAction.SET.USER_DATA.SUCCESS:
    case UserAction.SIGNIN.SUCCESS: {
      const { userDetails } = action;
      return {
        ...state,
        userList: {
          [userDetails.id]: {
            ...state.userList[userDetails.id],
            ...userDetails
          }
        }
      }
    }


    case UserPermisssionAction.FETCH.PREMISSION.REQUEST: {
      return {
        ...state,
        loadingUserPermissions: true,
      }
    }
    case UserPermisssionAction.FETCH.PREMISSION.FAILURE: {
      return {
        ...state, loadingUserPermissions: false,
      }
    }

    case UserPermisssionAction.FETCH.PREMISSION.SUCCESS: {
      const { userId, permissions } = action;
      const userListWithPermission = {};
      Object.values(state.userList).forEach((user) => {
        if (user.id == userId) {
          userListWithPermission[userId] = {
            ...user,
            permissions: permissions
          }
        } else {
          userListWithPermission[user.id] = user;
        }
      });
      return {
        ...state,
        loadingUserPermissions: false,
        userList: userListWithPermission,
      }
    }
    case AccountManagementAction.FETCH.DOMAIN_PERMISSIONS.REQUEST: {
      return { ...state, isFetchingDomainPermissions: true };
    }
    case AccountManagementAction.FETCH.DOMAIN_PERMISSIONS.FAILURE: {
      return { ...state, isFetchingDomainPermissions: false };
    }
    case AccountManagementAction.FETCH.DOMAIN_PERMISSIONS.SUCCESS: {
      let domainPermissions = {};

      action.permissions.forEach(permission => {
        domainPermissions[permission.perm] = permission;
      });

      return {
        ...state,
        isFetchingDomainPermissions: false,
        domainPermissions: domainPermissions
      };
    }

    case UserPermisssionAction.UPDATE.PREMISSION.REQUEST: {
      return {
        ...state,
        domainPermissions: updateDomainPermissionsStatus(state.domainPermissions, action.userPermission, true)
      }
    }
    case UserPermisssionAction.UPDATE.PREMISSION.FAILURE: {
      return {
        ...state,
        domainPermissions: updateDomainPermissionsStatus(state.domainPermissions, action.userPermission, false)
      }
    }
    case UserPermisssionAction.UPDATE.PREMISSION.SUCCESS: {
      const { userList } = state;
      const updatedUserList = {};
      const { status, userId, userPermission } = action;
      Object.entries(userList).map(([uId, userDetails]) => {
        if (uId === userId) {
          if (status) {
            updatedUserList[uId] = {
              ...userDetails,
              permissions: [...userDetails.permissions, userPermission]
            }
          } else {
            const permissionIndex = userDetails.permissions.indexOf(userPermission);
            updatedUserList[uId] = {
              ...userDetails,
              permissions: [...userDetails.permissions.slice(0, permissionIndex), ...userDetails.permissions.slice(permissionIndex + 1)]
            }
          }
        } else {
          updatedUserList[uId] = userDetails;
        }
      });
      return {
        ...state,
        userList: updatedUserList,
        domainPermissions: updateDomainPermissionsStatus(state.domainPermissions, action.userPermission, false)
      }
    }


    //delete user
    case AccountManagementAction.DELETE.DOMAIN_USER.REQUEST: {
      return {
        ...state,
        userList: {
          ...state.userList,
          [action.userId]: { ...state.userList[action.userId], isDeleting: true }
        }
      }
    }
    case AccountManagementAction.DELETE.DOMAIN_USER.FAILURE: {
      return {
        ...state,
        userList: {
          ...state.userList,
          [action.userId]: { ...state.userList[action.userId], isDeleting: false }
        }
      }
    }

    case AccountManagementAction.DELETE.DOMAIN_USER.SUCCESS: {
      const newUserMap = {};
      Object.values(state.userList).forEach(user => {
        if (user.id !== action.userId) {
          newUserMap[user.id] = user
        }
      });
      return {
        ...state,
        userList: newUserMap
      }
    }

    default: return state;
  }
}

export default accountManagement;