

import { MeawwApi } from "../../utils/api.js";
import { MessageTypes, SOCIAL_LINK } from "../../app-constants";
import { setAppMessage } from "../../app-actions.js";

function getSocialLinkSuccess(data) {
  return {
    type: SOCIAL_LINK.GET.SUCCESS,
    data
  }
}
function requestSocialLink() {
  return {
    type: SOCIAL_LINK.GET.REQUEST
  }
}
export default function GetSocialAccount() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    dispatch(requestSocialLink())
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/domain/${activeDomain}`,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.reason.eror, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }

            dispatch(getSocialLinkSuccess(data))
            return Promise.resolve(data);
          });
      });
  }
}

export function SetSocialAccount(social, msg) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    // dispatch(requestSocialLink())
    return MeawwApi()
      .auth()
      .then(api => {
        return api.put(`/domain/${activeDomain}`,
          {
            ...social
          },
          (error, data) => {
            if (error) {
              if (error.reason  && typeof error.reason.error == 'string') dispatch(setAppMessage(error.reason.error, { type: MessageTypes.ERROR }));
              else dispatch(setAppMessage('Something Went Wrong', { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            // dispatch(setAppMessage(msg || data.message, { type: MessageTypes.SUCCESS }), { onOk: dispatch(GetSocialAccount(data)) });
            return Promise.resolve(data);
          });
      });
  }
}
