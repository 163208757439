import React from 'react';
import { connect } from 'react-redux';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import AppLoader from "../../../app-components/app-loader.jsx";

import DisclaimersList from '../components/disclaimers-list';
import DisclaimerModal from '../components/disclaimer-modal';
import {
  fetchDisclaimers,
  toggleDisclaimerModal,
  submitDisclaimer,
  setDisclaimerModalData
} from '../../website-management-actions.js';

class DisclaimersContainer extends React.Component {
  componentDidMount(){
      this.props.fetchDisclaimers();
  }

  render(){
    const {
      isLoadingDisclaimers,
      disclaimers,
      showDisclaimerModal,
      toggleDisclaimerModal,
      submitDisclaimer,
      disclaimerModalData,
      setDisclaimerModalData
    } = this.props;
      if(isLoadingDisclaimers)
        return <AppLoader message="Loading Disclaimers"/>
    return (
      <>
        <DisclaimersList disclaimers={disclaimers} toggleDisclaimerModal={toggleDisclaimerModal} setDisclaimerModalData={setDisclaimerModalData}/>
        <DisclaimerModal
        showDisclaimerModal={showDisclaimerModal}
        toggleDisclaimerModal={toggleDisclaimerModal}
        submitDisclaimer = {submitDisclaimer}
        disclaimerModalData = {disclaimerModalData}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    websiteManagementReducer: {
      disclaimerReducer: {
        isLoadingDisclaimers,
        disclaimers,
        showDisclaimerModal,
        disclaimerModalData
      }}} = state;
  return {
    isLoadingDisclaimers,
    disclaimers,
    showDisclaimerModal,
    disclaimerModalData
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchDisclaimers: () => dispatch(fetchDisclaimers()),
    toggleDisclaimerModal: (flag, disclaimerId) => dispatch(toggleDisclaimerModal(flag, disclaimerId)),
    submitDisclaimer: (disclaimer) => dispatch(submitDisclaimer(disclaimer)),
    setDisclaimerModalData: (disclaimer) => dispatch(setDisclaimerModalData(disclaimer))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisclaimersContainer);
