import React, { Component } from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import Button from "../../../components/CustomButtons/Button.jsx";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";
import AppLoader from "../../../app-components/app-loader.jsx";
import Hidden from '@material-ui/core/Hidden';
import { DEFAULT_COVER_PICTURE } from '../../../app-constants/image-url.js';
import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
import { ArticleState, ActionOnArticle } from '../../../app-constants/index.js';
import { withRouter } from "react-router-dom";
import articlePreviewStyle from "../../../assets/jss/material-dashboard-pro-react/components/articlePreview.jsx";
import { removeDisplayPreviewAction, setLastLocation } from "../article-action.js";

const makeStyles = (theme) => {
  return {
    ...articlePreviewStyle(theme),
    ...notificationsStyle(theme)
  }
}

class ArticleModal extends Component {
  state = {
    open: true,
    type: 'SM',
    selectedDektop: false,
    selectedMobile: true
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  componentDidUpdate() {
    const { articleDetail: { isLoadingPreviewArticle }, previewArticleId } = this.props;
    const articlePreivewDom = document.getElementById("articlePreviewDom");
    articlePreivewDom && !!previewArticleId && window.twttr && window.twttr.widgets.load(document.getElementById("articlePreviewDom"));
    if (("instgrm" in window) && this.props.isLoadingPreviewArticle) {
      if (typeof this.props.articleDetail === 'undefined' || typeof this.props.articleDetail.content === 'undefined') {
        window.instgrm.Embeds.process();
        setTimeout(()=>{
          window.instgrm.Embeds.process();
        }, 3e3);
      }
    }
    
    if (!isLoadingPreviewArticle) {
      const domContainer = document.querySelectorAll('#demo figure img,#demo p img');
      const ref = this;
      domContainer && domContainer.forEach((element) => {
        element.addEventListener("load", function () {
          if (element.offsetHeight > element.offsetWidth) {
            element.style.maxWidth = ref.state.type == 'SM' ? element.offsetWidth : (element.offsetWidth - 20);
            element.setAttribute('style', 'max-height:510px;width:auto;max-height:auto;')
            let width = element.offsetWidth;
            let height = element.offsetHeight;
            let parentElem = element.parentElement;
            if (parentElem.tagName == 'FIGURE') {
              let wrapper = document.createElement('div');
              wrapper.style.cssText = 'margin: 0 auto; display:block;text-align:center;';
              parentElem.parentElement.replaceChild(wrapper, parentElem);
              wrapper.appendChild(parentElem);
              element.parentElement.setAttribute('style', `margin :0 auto 20px`)
            }
            else {
              const parent = element.parentElement;
              let wrapper = document.createElement('div');
              wrapper.style.cssText = 'margin: 0 auto; display:block;text-align:center;';
              parent.replaceChild(wrapper, element);
              wrapper.appendChild(element);
              element.parentElement.setAttribute('style', `margin :0 auto 20px`)
            }
          }
        })
      })
    }
  }

  handleClose = (state) => {
    const { removeArticleIdForPreviewArticle, removeDisplayPreviewAction } = this.props;
    removeArticleIdForPreviewArticle();
    removeDisplayPreviewAction();
    this.setState({ ...this.state, type: 'SM', selectedMobile: true, selectedDektop: false })
  };
  getDeviceSize() {
    return this.state.width
  }
  setDeviceSize = (width, type) => {
    type == 'SM' && this.setState({
      ...this.state, selectedMobile: true, selectedDektop: false, type: type
    })
    type == 'LG' && this.setState({
      ...this.state, selectedMobile: false, selectedDektop: true, type: type
    })
  }
  getClassName = (type) => {
    const { classes } = this.props;
    if (type == "SM") {
      return classes.styleSM
    }
    if (type == "LG") {
      return classes.styleLG
    }
  }
  getClassContainer = (type) => {
    const { classes } = this.props;
    if (type == "SM") {
      return classes.dialogPaperSM
    }
    if (type == "LG") {
      return classes.dialogPaperLG
    }
  }
  getSummaryStyle = (type) => {
    const { classes } = this.props;
    if (type == "SM") {
      return classes.summaryStyleSM
    }
    if (type == "LG") {
      return classes.summaryStyleLG
    }
  }
  getEmbedStyle = (type) => {
    const { classes } = this.props;
    if (type == "SM") {
      return classes.embedStyleSM
    }
    if (type == "LG") {
      return classes.embedStyleLG
    }
  }
  //actions
  redirectToArticlesPage = () => {
    this.props.history.push("/cms/articles");
  }
  _submitArticle = () => {
    const { submitArticle } = this.props;
    submitArticle()
      .then(data => {
        this.redirectToArticlesPage();
      })
      .catch(err => {});
  }

  handleActions = (e) => {
    const { articleSettings: { previewModalAction }, articleDetail: { status = 0 } } = this.props;


    // switch (previewModalAction) {

    //   case ActionOnArticle.SUBMIT: {
    //     const isSubmitRequired = this.isSubmitRequired();
    //     if (isSubmitRequired) {
    //       this._submitArticle();

    //     } else {
    //       this.props.saveAricle();
    //       return this.props.history.push("/cms/articles");
    //     }

    //     return;
    //   }
    //   case ActionOnArticle.APPROVE: {
    //     this.props.approveArticle()
    ;
    //     return
    //   }
    //   case ActionOnArticle.PUBLISH: {
    //     this.props.publishArticle();
    //     return;
    //   }
    //   default: {
    //     console.log("action did not matched")
    //   };
    // }

    switch (status) {
      case 0: {
        this._submitArticle();
        return;
      }
      case 1: {
        return this.props.approveArticle()
          .then(() => {
            return this.props.history.push("/cms/articles");
          });
      }
      case 3: {
        const { userDetails: { permissions = [] } } = this.props;
        if (permissions.includes("CREATE:PUBLISH")) {
          return this.props.publishArticle()
            .then(() => {
              return this.props.history.push("/cms/articles");
            });
        }
        return this.props.saveAricle()
          .then(() => {
            return this.props.history.push("/cms/articles");
          });
      }

      case 4: {
        return this.props.saveAricle()
          .then(() => {
            this.props.setLastLocation('edit-page');
            return this.props.history.push({
              pathname: "/cms/articles",
              state: { last: 'edit-page' }
            });
          }
          );
      }
      default: return;
    }




  }

  isSubmitRequired = () => {
    const { articleDetail: { status = 0 } } = this.props;
    switch (status) {
      case 4: return "Live";
      case 3: return "Approve";
      case 2: return "Submitte";
      case 0: return "Submit";
      default: return "Save";
    }
  }
  getArticleStatue = () => {
    const { articleDetail: { status = 0 }, userDetails } = this.props;
    switch (status) {
      case 4: return "Save";
      case 3: {
        const { userDetails: { permissions = [] } } = this.props;
        if (permissions.includes("CREATE:PUBLISH")) {
          return "Publish";
        }
        return "Save"

      }
      // case 2: return "Approve";
      case 1: return "Approve";
      case 0: return "Submit";
      default: return "Save";
    }
  }
  setButtonText = () => {

    const {
      articleSettings: {
        isSubmitting,
        isApproving,
        isPublishing,
      }
    } = this.props;

    const articleStatus = this.getArticleStatue();
    if (isSubmitting) {
      return <span style={{ display: "flex" }}>{articleStatus}{" "}<AppLoader isSignOutLoader={true} color="inherit" size={20} /></span>
    }
    if (isApproving) {
      return <span style={{ display: "flex" }}>{articleStatus}{" "}<AppLoader isSignOutLoader={true} color="inherit" size={20} /></span>
    }

    if (isPublishing) {
      return <span style={{ display: "flex" }}>{articleStatus}{" "}<AppLoader isSignOutLoader={true} color="inherit" size={20} /></span>
    }
    return articleStatus;
  }

  render() {
    const {
      classes,
      previewArticleId,
      articleDetail: { isLoadingPreviewArticle },
      articleDetail,
      articleSettings: {
        previewModalAction,
        isDisplayPreviewAction
      }
    } = this.props;
    const { isSubmitting, isApproving, isPublishing } = this.props.articleSettings;
    const open = !!previewArticleId;
    return (
      <Dialog
        id="previewContainer"
        open={open}
        scroll="paper"
        onClose={this.handClose}
        fullWidth={true}
        classes={{ paper: classes.dialogPaper }}
        className={this.getClassContainer(this.state.type)}
      >
        <DialogTitle>
          <div style={{ display: "flex", borderBottom: "1px solid #e0e0e0" }}>
            <h4 style={{ float: "left", margin: '0px' }}><strong>Preview</strong></h4>
            <div style={{ display: 'flex', justifyContent: "center", width: '100%' }}>
              <Hidden xsDown>
                <Button
                  color={`${this.state.selectedDektop ? "primary" : "buttonSecondColor"}`}
                  onClick={() => this.setDeviceSize('lg', 'LG')}
                >
                  <i className="material-icons">
                    desktop_windows
                    </i>
                </Button>
              </Hidden>

              <Hidden xsDown>
                <Button
                  selected
                  color={`${this.state.selectedMobile ? "primary" : "buttonSecondColor"}`}
                  onClick={() => this.setDeviceSize('sm', 'SM')}
                >
                  <i className="material-icons">
                    mobile_screen_share
                    </i>
                </Button>
              </Hidden>
            </div>
            <Button
              style={{ float: "right", margin: '0px' }}
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => this.handleClose()}
            >
              <Close className={classes.modalClose} />
            </Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <div id="articlePreviewDom" className={classes.root} style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingLeft: "15px", paddingRight: "15px" }}>
            <div style={{ maxWidth: "100%" }}>
              {isLoadingPreviewArticle && <AppLoader message="Loading Article Details ..." />}
              {!isLoadingPreviewArticle &&
                <div id="read-page-style">
                  < h1 className={this.getClassName(this.state.type) + " " + "titleclass"} style={{ display: "flex", flexDirection: "column" }} >{articleDetail.title}</h1>
                  <p className={this.getSummaryStyle(this.state.type) + " " + "read-summary"} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }} >{articleDetail.summary}</p>
                  <img className={classes.dialogPaper} style={{ maxWidth: "100%", marginBottom: "15px" }} src={articleDetail.coverPicture ? `${articleDetail.coverPicture}_1200_630${articleDetail.imgExtension}` : DEFAULT_COVER_PICTURE} />
                  <p className={this.getSummaryStyle(this.state.type) + " " + "read-summary"} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >{articleDetail.coverCaption}</p>
                  <div className="css-article__previewer" >
                    <div className="css-article__main-card" id="scrollPosId">
                      <div className="css-description__box">
                        <div id="demo" className={this.getEmbedStyle(this.state.type)} dangerouslySetInnerHTML={{
                          __html: articleDetail.html
                        }}></div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </DialogContent>
        <DialogActions
          className={
            classes.modalFooter +
            " " +
            classes.modalFooterCenter
          }
          style={{ borderTop: "1px solid #e0e0e0" }}
        >
          {
            isDisplayPreviewAction &&
            !!previewModalAction &&
            <Button
              color="primary"
              onClick={this.handleActions}
              style={{ pointerEvents: `${(isSubmitting || isApproving || isPublishing) ? "none" : "auto"}` }}
            // className={
            //   classes.modalSmallFooterFirstButton +
            //   " " +
            //   classes.modalSmallFooterSecondButton
            // }
            >
              {this.setButtonText()}
            </Button>
          }

          <Button
            color="secondary"
            onClick={() => this.handleClose()}
          // className={
          //   classes.modalSmallFooterFirstButton +
          //   " " +
          //   classes.modalSmallFooterSecondButton
          // }
          >
            Close
            </Button>
        </DialogActions>
      </Dialog >
    )
  }
}

const mapStateToProps = (state) => {
  const { id: activeUser } = state.login.user;
  return {
    userDetails: state.userManagement.accountManagement.userList[activeUser]
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    removeDisplayPreviewAction: () => {
      dispatch(removeDisplayPreviewAction())
    },
    setLastLocation: (location) => dispatch(setLastLocation(location))
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(makeStyles)(ArticleModal)));
