//import Pages from "layouts/Pages.jsx";
//import RTL from "layouts/RTL.jsx";
// import CmsHomePage from "./cms/cms-home-page.jsx";

import LoginHomePage from "./login/login-home-page";
import ForgotPasswordPage from "./login/forgot-password-page.jsx";
import OnboardingPage from "./onboard/onboarding-home-page";
import Addwebsite from "./onboard/views/website";
import AppLayout from "./app-layout.jsx";

import confirmPasswordRoutes from "./confirm-password/confirm-password-routes.js";
// import loginRoutes from "./login/login-routes.js";


var indexRoutes = [
  // ...loginRoutes,
  // move login routes to login routes files
  {
    path: "/login-home/login",
    name: "Login",
    component: LoginHomePage
  }, {
    path: "/login-home/forgot-password",
    name: "Forgot Password",
    component: ForgotPasswordPage
  },
  {
    path: "/onboard/signup",
    name: "Onboard User",
    component: OnboardingPage,
  },
  {
    path: "/domain-management/addwebsite",
    name: "Addwebsite",
    component: Addwebsite
  },
  // ...confirmPasswordRoutes,
  // {
  //   path: "/rtl",
  //   name: "RTL",
  //   component: RTL
  // },
  // //hide this
  // {
  //   path: "/pages",
  //   name: "Pages",
  //   component: Pages
  // },
  {
    path: "/",
    name: "Home",
    isRequiredAuth: true,
    component: AppLayout,
  }
];

export default indexRoutes;
