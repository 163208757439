import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import { keyBy } from "lodash";

class StoryListDropdown extends React.PureComponent {

  state = {
    showNewListDialog: false,
    listName:'',
  }

  toggleNewListDialog = () => {
    this.setState((prev) => {
      return {
        showNewListDialog: !prev.showNewListDialog,
      }
    })
  }
  componentDidMount() {
    this.props.getStoryList();
  }

  newListHandler = () => {
    this.props.addNewStoryList(this.state.listName);
    this.toggleNewListDialog();
  }

  listNameHandler = (event) => {
    this.setState({ listName: event.target.value });
  }
  handleListChange = (event) => {
    if (event.target.value == "new_list") {
      this.toggleNewListDialog();
      return;
    }
    const hashesList = keyBy(this.props.list, 'id');
    this.props.setActiveStoryList(event.target.value);
    this.props.setActiveStoryListExpiry(hashesList[event.target.value]['expiryTime'] || 24);
    this.props.getStories(0);
    this.props.getStories(1);
  }
  render() {
    const { classes, activeStoryList, labelColor } = this.props;
    return (
      <>
      <div style={{ paddingRight: "15px", position: "relative", top: "-12px", width:"250px" }}>
        <FormControl
          fullWidth
          className={classes.selectLabel}
        >
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
            style={{ color: `${labelColor}`}}
          >
            Change Story List
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={activeStoryList}
            onChange={this.handleListChange}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select",
              classes: {
                icon: labelColor && classes.icon
              },
            }}
          >
            {this.props.list.map((item) => {
                return (
                  <MenuItem
                    key={item.id}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value={item.id}
                  >
                    {item.name}
                  </MenuItem>
                )
              })
            }
            <MenuItem
              key='new_list'
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value='new_list'
            >
              + Add new List
            </MenuItem>
          </Select>
        </FormControl>
        </div>
        <Dialog
          open={this.state.showNewListDialog}
          onClose={this.toggleNewListDialog}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle id="form-dialog-title">Add List</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter list name here.
            </DialogContentText>
            <TextField
              autoFocus
              onChange={this.listNameHandler}
              id="name"
              label="List Name"
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleNewListDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={this.newListHandler} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>
        </>
    )
  }
}


export default withStyles(customSelectStyle)(StoryListDropdown);
