import React from "react";
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';


const CustomTooltip = withStyles(color => ({
  tooltip: {
    backgroundColor: 'grey',
    color: 'white',
    fontSize: '1em',
  },
}))(Tooltip);


export default class InformationTooltip extends React.PureComponent {
  render() {
    return (
      <CustomTooltip title={this.props.text}>
        <Icon style={{ fontSize: this.props.size || 16, color:this.props.color || '#AAAAAA' }}>
          info_outline
        </Icon>
      </CustomTooltip>
    )
  }
}
