import { MeawwApi } from "../../utils/api.js";
import { setAppMessage } from "../../app-actions.js";
import { MessageTypes } from "../../app-constants/index.js";
import { FeedsAction } from "../../app-constants/index.js";
import { showNotification, askForNotificationPermission } from "../../utils/utils.js";

export function getCustomFeeds(discoveryName='', state='', isTopsite='', start) {
  let queryParam = '?z=1';
  if (discoveryName) queryParam += `&discoveryName=${discoveryName}`;
  if (isTopsite) queryParam += `&isTopsite=${isTopsite}`;
  if (state) queryParam += `&state=${state}`;
  if (start) queryParam += `&start=${start}`;
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/feeds/${activeDomain}/custom_feed${queryParam}`,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data.result);
          });
      })
  }
}

export function getAllCustomDiscovery(isTopsite) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/feeds/${activeDomain}/discovery_name${isTopsite ? `?isTopsite=${isTopsite}`: ''}`,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data.result);
          });
      })
  }
}

export function getAllDiscoveryState(isTopsite) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/feeds/${activeDomain}/discovery_state${isTopsite ? `?isTopsite=${isTopsite}`: ''}`,
          (error, data) => {
            if (error) {
              return Promise.reject(error);
            }
            return Promise.resolve(data.result);
          });
      })
  }
}