import React from 'react';
import CategoriesContainer from './views/categories-container';

function Categories() {
  return (
    <CategoriesContainer/>
  );
}

export default Categories;
