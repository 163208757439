import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

const UserListHeader = () => (
  <GridContainer style={{ padding: "10px" }}>
    <GridItem sm={4}>
      <div style={{ fontWeight: "bold", textAlign: "center" }}> User Name</div>
    </GridItem>
    <GridItem sm={3} alignItemCenter={true} displayFlexType={true}>
      <div style={{ fontWeight: "bold", textAlign: "center" }}> Joining Date</div>
    </GridItem>
    <GridItem sm={4} alignItemCenter={true} displayFlexType={true}>
      <div style={{ fontWeight: "bold", textAlign: "center" }}> Email</div>
    </GridItem>
    <GridItem sm={1} displayFlexType={true} alignItemCenterWithEnd={true}>
      <div style={{ fontWeight: "bold", textAlign: "center" }}> Action</div>
    </GridItem>
  </GridContainer >
)

export default UserListHeader;