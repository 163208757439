
import { ArticlesAction, UserAction, LocalStorageKeys, SessionKeys, PreviewModalAction } from "../../app-constants/index.js";
import { ArticleAction } from "../../app-constants/action-types/cms/index.js";
import { fetchDisclaimers } from "../new-story/new-story-actions.js";
//const DEFAULT_COVER_PICTURE = "https://cdn.meaww.com/assets/img/Blur.png";
const DEFAULT_COVER_PICTURE = "https://cheesecake.articleassets.meaww.com/assets/new_default_cover_pic_1200x630.png";

const Configs = {
  NEW_ARTICLE: {
    COVER_PICTURE: {
      COVER_PICTURE_MAX_SIZE: 2097152,
      COVER_IMAGE_MIN_WIDTH: 1200,
      COVER_IMAGE_MIN_HEIGHT: 630,
      COVER_SCALE: {
        "scale": 1,
        "angle": 0,
        "x": 0,
        "y": 0,
        "w": 1200,
        "h": 630,
      }
    }
  },
  NEW_NEWS: {
    COVER_PICTURE: {
      COVER_PICTURE_MAX_SIZE: 2097152,
      COVER_IMAGE_MIN_WIDTH: 1200,
      COVER_IMAGE_MIN_HEIGHT: 630,
      COVER_SCALE: {
        "scale": 1,
        "angle": 0,
        "x": 0,
        "y": 0,
        "w": 1200,
        "h": 630,
      }
    }
  },
}

const { NEW_ARTICLE: { COVER_PICTURE: CoverPictureConfigs } } = Configs;
const getArticleDefaultValue = () => {
  return parseInt(sessionStorage.getItem(SessionKeys.ARTICLE_ID)) || window.articleId;
}
const articlesSettingsDefault = {
  menuListArticleId: undefined,
  previewArticleId: undefined,
  showPublishProgressModal: false,
  progressModalData: [],
  showShareArticleModal: false,
  shareArticleData: [],

  showScheduleArticleModal: false,
  scheduleArticleData: [],

  showExploitArticleModal: false,
  exploitArticleData: [],

  showScheduleTwitterArticleModal: false,
  scheduleTwitterArticleData: [],

  //set default value
  articleId: getArticleDefaultValue(),
  isLoadingArticleDetails: false,
  isLoadingArticleContent: false,
  isLoadingCategories: false,


  coverPicture: {
    coverImageAsBlob: null,
    cropData: JSON.stringify(CoverPictureConfigs.COVER_SCALE),
    image: DEFAULT_COVER_PICTURE,
    uploadingCoverPicture: false,
    imageSource: "",
  },

  // currentState: null,
  // nextState: null,

  // previewActions: {},

  previewModalAction: undefined,
  showFeedbackModal: false,
  showArticleSchedular: false,

  showArticleExploiter: false,

  isSubmitting: false,
  isDeleting: false,
  isApproving: false,
  isPublishing: false,
  isDeletingCache: false,
  isFinishingEdit: false,
  isDisplayPreviewAction: true,
  isSendingNotifications: false,
  lastLocation: 'none',
  currentLiveHeight: 0,
}

const articleSettings = (state = articlesSettingsDefault, action) => {
  switch (action.type) {
    //reset on domain change
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return articlesSettingsDefault
    }
    case ArticlesAction.SET.PREVIEW_ARICLE_ID: {
      const { previewArticleId } = action;
      return {
        ...state,
        previewArticleId
      }
    }

    // case ArticleAction.SET_STATE.CURRENT_STATE: {
    //   return { ...state, currentState: action.state };
    // }
    // case ArticleAction.SET_STATE.NEXT_STATE: {
    //   return { ...state, nextState: action.state };
    // }

    // case ArticleAction.MOVE_STATE.ACTION: {
    //   return { ...state, previewActions: action.previewActions }
    // }

    case ArticleAction.SET.PREVIEW_MODAL_ACTION: {
      return { ...state, previewModalAction: action.previewModalAction }
    }
    case ArticleAction.SET.DISPLAY_PREVIEW_ACTION: {
      return { ...state, isDisplayPreviewAction: false }
    }
    case ArticleAction.REMOVE.DISPLAY_PREVIEW_ACTION: {
      return { ...state, isDisplayPreviewAction: true }
    }
    case ArticlesAction.SET.MENU_LIST_ARTICLE_ID: {
      const { menuListArticleId } = action;
      return {
        ...state,
        menuListArticleId
      }
    }
    case ArticleAction.SET.PROGRESS_MODAL: {
      const { visible } = action;
      return {
        ...state,
        showPublishProgressModal: visible,
        progressModalData: [],
      };
    }
    case ArticleAction.SET.SHARE_ARTICLE_MODAL: {
      const { visible, shareArticleData } = action;
      return {
        ...state,
        showShareArticleModal: visible,
        shareArticleData,
      }
    }
    case ArticleAction.SET.SCHEDULE_ARTICLE_MODAL: {
      const { visible, scheduleArticleData } = action;
      return {
        ...state,
        showScheduleArticleModal: visible,
        scheduleArticleData,
      }
    }
  case ArticleAction.SET.EXPLOIT_ARTICLE_MODAL: {
      const { visible, exploitArticleData } = action;
      return {
        ...state,
        showExploitArticleModal: visible,
        exploitArticleData,
      }
    }
    case ArticleAction.SET.SCHEDULE_TWITTER_ARTICLE_MODAL: {
      const { visible, scheduleTwitterArticleData } = action;
      return {
        ...state,
        showScheduleTwitterArticleModal: visible,
        scheduleTwitterArticleData,
      }
    }
    case ArticleAction.SET.PROGRESS_MODAL_DATA: {
      const { progressModalData } = action;
      return {
        ...state,
        progressModalData: progressModalData,
      };
    }
    case ArticlesAction.REMOVE.MENU_LIST_ARTICLE_ID: {
      const { menuListArticleId } = action;
      return {
        ...state,
        menuListArticleId
      }
    }
    case ArticlesAction.REMOVE.PREVIEW_ARICLE_ID: {
      const { previewArticleId } = action;
      return {
        ...state,
        previewArticleId,
      }
    }
    ///////////////////////////////////////////////////////
    case ArticleAction.SET.ARTICLE_ID: {
      return {
        ...state,
        articleId: action.articleId
      }
    }
    case ArticleAction.LOAD.ARTICLE_DETAILS.REQUEST: {
      return {
        ...state,
        isLoadingArticleDetails: true,
        articleId: action.articleId
      }
    }
    case ArticleAction.LOAD.ARTICLE_DETAILS.FAILURE: {
      return {
        ...state,
        isLoadingArticleDetails: false,
      }
    }
    case ArticleAction.LOAD.ARTICLE_DETAILS.FAILURE:
    case ArticleAction.LOAD.ARTICLE_DETAILS.SUCCESS: {
      return {
        ...state,
        isLoadingArticleDetails: false,
      }
    }


    //article content
    case ArticleAction.LOAD.ARTICLE_CONTENTS.REQUEST: {
      return {
        ...state,
        isLoadingArticleContent: true
      }
    }
    case ArticleAction.LOAD.ARTICLE_CONTENTS.FAILURE: {
      return {
        ...state,
        isLoadingArticleContent: false
      }
    }

    case ArticleAction.LOAD.ARTICLE_CONTENTS.SUCCESS: {
      return {
        ...state,
        isLoadingArticleContent: false
      }
    }
    //domain categories
    case ArticleAction.LOAD.ARTICLE_CATEGORIES.REQUEST: {
      return {
        ...state,
        isLoadingCategories: true,
      }
    }
    case ArticleAction.LOAD.ARTICLE_CATEGORIES.FAILURE: {
      return {
        ...state,
        isLoadingCategories: false,
      }
    }
    case ArticleAction.LOAD.ARTICLE_CATEGORIES.SUCCESS: {
      return {
        ...state,
        isLoadingCategories: false,
        categories: action.categories
      }
    }

    //feedback
    case ArticleAction.SET.FEEDBACK_MODAL: {
      return { ...state, showFeedbackModal: action.status }
    }
    // schedular
    case ArticlesAction.SCHEDULE.SHOW: {
      return { ...state, showArticleSchedular: action.status }
    }
  case ArticlesAction.EXPLOIT.SHOW: {
      return { ...state, showArticleExploiter: action.status }
    }
    //submitting start
    case ArticleAction.SUBMIT.REQUEST: {
      return { ...state, isSubmitting: true };
    }
    case ArticleAction.SUBMIT.FAILURE: {
      return { ...state, isSubmitting: false };
    }
    case ArticleAction.SUBMIT.SUCCESS: {
      return { ...state, isSubmitting: false };
    }
    //submitting end

    //deleting article start
    case ArticleAction.DELETE.REQUEST: {
      return { ...state, isDeleting: true }
    }
    case ArticleAction.DELETE.FAILURE: {
      return { ...state, isDeleting: false }
    }
    case ArticleAction.DELETE.SUCCESS: {
      return { ...state, isDeleting: false }
    }
    //delelting article end

    //approving start
    case ArticleAction.APPROVE.REQUEST: {
      return { ...state, isApproving: true }
    }

    case ArticleAction.APPROVE.FAILURE: {
      return { ...state, isApproving: false }
    }
    case ArticleAction.APPROVE.SUCCESS: {
      return { ...state, isApproving: false }
    }
    //approving end
    //publishing start
    case ArticleAction.PUBLISH.REQUEST: {
      return { ...state, isPublishing: true }
    }

    case ArticleAction.PUBLISH.FAILURE: {
      return { ...state, isPublishing: false }
    }
    case ArticleAction.PUBLISH.SUCCESS: {
      return { ...state, isPublishing: false }
    }
    //publishing end

    //notifications start
    case ArticleAction.NOTIFICATION.REQUEST: {
      return { ...state, isSendingNotifications: true };
    }
    case ArticleAction.NOTIFICATION.FAILURE: {
      return { ...state, isSendingNotifications: false };
    }
    case ArticleAction.NOTIFICATION.SUCCESS: {
      return { ...state, isSendingNotifications: false };
    }
    //notifications end

    case ArticleAction.CLEAR_CACHE.REQUEST: {
      return { ...state, isDeletingCache: true };
    }
    case ArticleAction.CLEAR_CACHE.SUCCESS:
    case ArticleAction.CLEAR_CACHE.FAILURE: {
      return { ...state, isDeletingCache: false }
    }

    case ArticleAction.FINISH_EDIT.REQUEST: {
      return { ...state, isFinishingEdit: true }
    }
    case ArticleAction.FINISH_EDIT.FAILURE:
    case ArticleAction.FINISH_EDIT.SUCCESS: {
      return { ...state, isFinishingEdit: false }
    }
    case ArticleAction.LAST_LOCATION.SET: {
      return { ...state, lastLocation: action.location }
    }
    case ArticleAction.CURRENT_HEIGHT.SET: {
      return { ...state, currentLiveHeight: action.height }
    }
    default: return state;
  }
}

export default articleSettings;
