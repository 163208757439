
import React, { Component } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import editProfilePageStyle from "assets/jss/material-dashboard-pro-react/views/editProfilePageStyle.jsx";
import { editUserProfile, getUserDetails } from "../user-settings-actions.js";
import { InvalidFileError } from '../../cms/quick-cms/quick-cms-actions.js';
import EditUserProfile from '../components/edit-profile.jsx';
import { withRouter } from "react-router-dom";
import AppLoader from "../../app-components/app-loader.jsx";


class UpdateUserProfile extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        if (!localStorage.getItem('accessToken')) {
            this.props.history.push("/login-home/login");
            return 
        }
        this.props.getUserDetails();
    }
    redirectToProfilePage = () => {
        this.props.history.push("/user-settings/profile");
    }
    updateFormData = (formData) => {
        const { editUserProfile } = this.props;
        editUserProfile(formData, this.redirectToProfilePage)
        // .then(() => {
        //     this.redirectToProfilePage();
        // })
        // .catch(err => {
        //     console.log(err);
        //     return
        // });
    }
    render() {
        const { isUpdatingUserDetails, userDetails } = this.props;
        const { containingCompleteDetails = false } = userDetails;
        if (!containingCompleteDetails) {
            return <AppLoader message="Fetching User Details..." />
        }
        return (
            <EditUserProfile
              updateFormData={this.updateFormData}
              userDetails={userDetails}
              isUpdatingUserDetails={isUpdatingUserDetails}
              InvalidFileError={this.props.InvalidFileError}
            />
        )
    }
}
const mapStateToProps = (state) => {
    const { login: { user: userDetails }, userSettings: { isUpdatingUserDetails } } = state;
    return {
        userDetails,
        isUpdatingUserDetails
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getUserDetails: () => dispatch(getUserDetails()),
        editUserProfile: (formData, redirectCB) =>
            dispatch(editUserProfile(formData, redirectCB)),
        updatedProfileDetails: () => {

        },
        InvalidFileError: (msg) => dispatch(InvalidFileError(msg)),
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(editProfilePageStyle)(UpdateUserProfile)));
