import { combineReducers } from "redux";

const defaultScheduleState = {
  scheduleInProgress: false,
  showLoader: false,
  loaderMessage: '',
  scheduleDataUpdated: false,
  refreshPages: false,
  showCloseBtn: false,
  sortMethod: 'Time',
  facebookpagenotfound: false,
  isLoadingFacebook: true,
}

const scheduleReducer = (state = defaultScheduleState, action) => {
  switch( action.type ) {
    case 'ScheduleRequest': {
      return {...state, scheduleInProgress: true, showLoader: true, loaderMessage: 'Scheduling...'}
    }
    case 'ScheduleSuccess': {
      return {...state, showLoader: false, loaderMessage: action.message, showCloseBtn: true}
    }
    case 'ScheduleClose': {
      return {...state, scheduleDataUpdated: true, scheduleInProgress: false, showCloseBtn: false}
    }
    case 'ScheduleFailure': {
      return {...state, showLoader: false, loaderMessage: action.message, showCloseBtn: true}
    }
    case 'hasReceivedNewScheduleData': {
      return {...state, scheduleDataUpdated: false}
    }
    case 'setRefreshPages': {
      return {...state, refreshPages: true}
    }
    case 'removeRefreshPages': {
      return {...state, refreshPages: false}
    }
    case 'changeSortMethod': {
      return {...state, sortMethod: action.sortMethod}
    }
    case 'DeleteRequest': {
      return {...state, scheduleInProgress: true, showLoader: true, loaderMessage: 'Deleting...'}
    }
    case 'DeleteSuccess': {
      return {...state, scheduleInProgress: false, loaderMessage: action.message, showCloseBtn: true}
    }
    case 'DeleteFailure': {
      return {...state, showLoader: false, loaderMessage: action.message, showCloseBtn: true}
    }
    case 'RescheduleRequest': {
      return {...state, scheduleInProgress: true, showLoader: true, loaderMessage: 'Rescheduling...'}
    }
    case 'RescheduleSuccess': {
      return {...state, scheduleInProgress: false, loaderMessage: action.message, showCloseBtn: true}
    }
    case 'RescheduleFailure': {
      return {...state, showLoader: false, loaderMessage: action.message, showCloseBtn: true}
    }
    case 'startLoadingFacebook': {
      return {...state, isLoadingFacebook: true}
    }
    case 'facebookpagenotfound': {
      return {...state, facebookpagenotfound: true, isLoadingFacebook: false}
    }
    case 'facebookpagefound': {
      return {...state, facebookpagenotfound: false, isLoadingFacebook: false}
    }
    default: return state;
  }
}

const defaultFBState = {
  showModal: false,
  showLoader: false,
  loaderMessage: '',
  showCloseBtn: false,
}
const FBReducer = (state=defaultFBState, action) => {
  switch(action.type){
    case 'fetchFbRequest': {
      return {...state, showModal:true, showLoader: true, loaderMessage: 'Fetching...'}
    }
    case 'fetchFbSuccess': {
      return {...state, showModal: false, showLoader: false, showCloseBtn: false, loaderMessage: ''}
    }
    case 'fetchFbFailure': {
      return {...state, showModal: false, showLoader: false, showCloseBtn: true, loaderMessage: ''}
    }
    case 'closeFbModal': {
      return {...state, showModal: false, loaderMessage: '', loaderMessage: '', showCloseBtn: false}
    }
    default: return state;
  }
}
const scheduleReducers = combineReducers({
  scheduleReducer,
  FBReducer
});

export default scheduleReducers;
