import React, { PureComponent } from 'react';
import Button from '../../components/CustomButtons/Button.jsx';
import TwitterLogin from "react-twitter-auth";
import AppLoader from "../../app-components/app-loader.jsx";
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import GridItem from "components/Grid/GridItem.jsx";
import { MessageTypes } from "../../app-constants/index.js";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
const loginUrl = `${process.env.REACT_APP_API_SERVER}/schedule/auth/twitter`;
const mandatoryPermissions = ['manage_pages', 'publish_pages'];

class SocialMedia extends PureComponent {
  state = {
    showLoader: false,
    success: false,
    twitter_user: null,
    facebook_user: null,
    enable_newsletter: false,
  }
  componentDidMount() {
    this.setState({ enable_newsletter: !!this.props.website_visible });
  }
  componentDidUpdate() {
    this.setState({ enable_newsletter: !!this.props.website_visible });
  }
  
  onSuccess = (response) => {
    response.json().then(user => {
      if (user) {
        // this.setState({ isAuthenticated: true, verify: true, render: true });
        this.props.updateUser(user);
        //this.getAcount();
        this.setState({ showLoader: false, success: true });
        try {
          const twitterUrl = `https://twitter.com/${user.screen_name}`;
          this.setState({ twitter_user: user.screen_name });
        }
        catch (e) {
          console.log(e)
        }
      } else {
        ///
      }
    });
    // }
  };
  getDebugTokenDB = (obj) => {
    return new Promise((res, rej) => {
      window.FB.api(`/debug_token?input_token=${obj.access_token}`, r => {
        if (r.error) return res(obj);
        obj.debug = r;
        return res(obj);
      });
    });
  }
  onFailed = (error) => {
    // this.props.setAppMessage(error.toString(), { type: MessageTypes.ERROR });
    this.setState({ showLoader: false });
  };
  fbLogin = () => {
    this.setState({ error: '' });
    return new Promise((resolve, reject) => {
      window.FB.login((res) => {
        if (res.status === 'connected') {
          return resolve(res.authResponse);
        }
        return reject(`Login error, pls login with facebook`);
      }, { scope: mandatoryPermissions.join(',') });
    });
  }

  connectFB = () => {
    if (window.FB) {
      this.setState({ showLoader: true });
      this.fbLogin()
        .then((auth) => {
          return this.checkMandatoryPermissions(auth)
        }).then((auth) => {
          return this.getAllAccounts(auth)
        }).then((obj) => {
          return this.debugToken(obj)
        }).then(data => {
          this.storeAllFbPages(data);
        }).catch((e) => {
          this.setState({ showLoader: false });
          this.props.setAppMessage((e.error || {}).message || e.message || JSON.stringify(e), { type: MessageTypes.ERROR });
          //this.setState({error: e});
        });
    } else {
      this.props.setAppMessage('Facebook script loading error, pls turn off your blocker and retry.', { type: MessageTypes.ERROR });
    }
  }

  // connectFB = () => {
  //   const obj = `{"data":[{"access_token":"EAAH0E3oGatQBAExHSIW3t4n9adKnYTZCJRNp6E9Odf9bZBUZBdOqZBBogFCBuqN3SBZAzjXDWTuvV2jXfeSn5iHiroqDlPu2mOiEtIOdBzWG85cchljZBkSeCvwjTN1YNsfkRHQ2c21KtA7EiFIuHInzxd1bUBNObgaZC6RdXvVwyh4iJVQYxVckvzPhAMZA3HWafRrhAHSoNGdL2BZBUSdfZB","category":"Community","category_list":[{"id":"2612","name":"Community"}],"name":"Playground","id":"164384180433948","tasks":["ANALYZE","ADVERTISE","MODERATE","CREATE_CONTENT","MANAGE"]}],"paging":{"cursors":{"before":"MTY0Mzg0MTgwNDMzOTQ4","after":"MTY0Mzg0MTgwNDMzOTQ4"}}}`;
  //   if (window.FB) {
  //       this.debugToken(JSON.parse(obj))
  //       .then(data => {
  //         this.storeAllFbPages(data);
  //       }).catch((e) => {
  //         this.setState({ showLoader: false });
  //         this.props.setAppMessage((e.error || {}).message || e.message || JSON.stringify(e), { type: MessageTypes.ERROR });
  //         //this.setState({error: e});
  //       });
  //   } else {
  //     this.props.setAppMessage('Facebook script loading error, pls turn off your blocker and retry.', { type: MessageTypes.ERROR });
  //   }
  // }

  debugToken = (objD) => {
    return new Promise((res, rej) => {
      Promise.all(objD.data.map((obj) => this.getDebugTokenDB(obj))).then((d) => res({ pagesArr: d, userObj: objD.auth })).catch(e => rej(e));
    });
  }

  storeAllFbPages = (res) => {
    //this.props.fetchFbRequest();
    if (res.pagesArr && res.pagesArr.length) {
      let fbUsers = '';
      try {
        fbUsers = res.pagesArr.map((res) => res.name).join();
      } catch (e) {
      }
      //Add a loader while post api is called
      this.props.storeFacebookPage(res)
        .then((data) => {
          //this.props.fetchFbSuccess();
          //this.props.getFacebookpage();
          this.setState({ showLoader: false, showModal: false, facebook_user: fbUsers });
          this.props.setAppMessage("Successfully added page.", { type: MessageTypes.SUCCESS, onOk: this.props.getFacebookPage });
        })
        .catch((err) => {
          //this.props.fetchFbFailure();
          this.setState({ showLoader: false });
          this.props.setAppMessage('Unable to add facebook page', { type: MessageTypes.ERROR });
        });

    } else {
      this.setState({ showLoader: false });
      this.props.setAppMessage(`No page data`, { type: MessageTypes.ERROR });
    }
  }

  postNewsletter = (event) => {
    this.props.postNewsletter(true);
    this.setState({ enable_newsletter: event.target.checked });
  }
  moveNext = () => {
    this.setState({ showLoader: true });
    this.props.fetchFeatures().finally(res => {
      this.setState({ showLoader: false }, () => {
        this.props.increaseState();
      });
    });
  }
  getAllAccounts = (auth) => {
    return new Promise((res, rej) => {
      window.FB.api('/me/accounts', (r) => {
        if (r.error) {
          return rej(r.error.message || r.error);
        } else if (r.data && r.data.length) {
          return res({ data: r.data, auth });
        }
        return rej(`Error occured while getting your Facebook Page.`);
      });
    });
  }

  checkMandatoryPermissions = (auth) => {
    return new Promise((res, rej) => {
      window.FB.api('/me/permissions', (r) => {
        if (r.error) {
          return rej(r.error.message || r.error);
        } else if (r.data && r.data.length) {
          const grantedPermissions = [];
          Object.keys(r.data).forEach((index) => {
            const obj = r.data[index];
            if (obj.status === 'granted') {
              grantedPermissions.push(obj.permission);
            }
          });
          const permDiff = mandatoryPermissions.filter((perm) => grantedPermissions.indexOf(perm) === -1);
          if (permDiff.length === 0) {
            return res(auth);
          } else {
            return rej(`Please allow mandatory permissions ${permDiff.join(', ')}`);
          }
        } else {
          return rej(`Error while getting my permissions from Facebook.`);
        }
      });
    });
  }
  
  render() {
    return (
      <>
        <GridItem xs={12} sm={8} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', margin: 'auto' ,border: isWidthDown('xs', this.props.width) ? 'none' : '1px solid rgb(206, 211, 220)',marginBottom:'30px',marginTop:isWidthDown('xs', this.props.width) ?'0px':'25px' }}>
          <h1
            style={{
              marginTop: '15px',
              textAlign: 'center',
              lineHeight: '1.1',
              letterSpacing: '0.5px',
              color: '#2b2d42',
              fontFamily: 'PlayfairDisplay',
            }}
          >
            Setup Distribution
        </h1>
          <hr style={{
            border: '1px solid #E1E4E9',
            width: '10%',
            margin: ' auto',
            marginBottom: '20px'
          }} />
          <GridItem xs={12} sm={10} md={8} style={{ margin: 'auto', width: '100%' }} id="margionZeroPub">
            <h5 style={{
              width: '100%',
              textAlign: 'left',
              marginBottom: '0px',
              fontSize: '16px',
              fontWeight: '600',
              color: '#2b2d42',
            }}> Enable Newsletter</h5>
            <p
              style={{
                textAlign: 'left',
                fontSize: '14px',
                marginBottom: '0px',
                color: 'rgb(153, 163, 180)'
              }}
            >
              Activate newsletter opt-in on your website
			      </p>
            <span >No</span>
            <Switch
              checked={this.state.enable_newsletter}
              onChange={this.postNewsletter}
              color='primary' />
            <span >Yes</span>
          </GridItem>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'baseline',
            width: '100%'
          }}>

            <div style={{ cursor: 'pointer',width: '100%', marginBottom: '5px' }} onClick={() => { this.setState({ showLoader: true }) }}>
              <GridItem xs={12} sm={10} md={8} style={{ margin: 'auto' }} id="margionZeroPub">
                <h5 style={{
                  width: '100%',
                  textAlign: 'left',
                  marginBottom: '0px',
                  fontSize: '16px',
                  fontWeight: '600',
                  color: '#2b2d42',
                }}>Connect Twitter Account</h5>
                <p
                  style={{
                    textAlign: 'left',
                    fontSize: '14px',
                    marginBottom: '0px',
                    color: 'rgb(153, 163, 180)'
                  }}
                >
                  Post and schedule on Twitter account directly from dashboard
			          	</p>
                  <TwitterLogin
                loginUrl={loginUrl}
                onFailure={this.onFailed}
                onSuccess={this.onSuccess}
                requestTokenUrl={`${process.env.REACT_APP_API_SERVER}/schedule/${this.props.domain}/auth/twitter/reverse`}
                style={{
                  border: '0',
                  backgroundColor: '#55acee',
                  borderRadius: '0px',
                  color: 'white',
                  padding: '12px 25px',
                  marginTop: '15px',
                  cursor: 'pointer',
                  fontSize: '14px',
                  textTransform: 'uppercase',
                  width: isWidthDown('xs', this.props.width) ? '100%' : 'auto'
                }}
              >
                <div > connect twitter account</div>
              </TwitterLogin>
                <div style={{marginTop:'10px'}}>{this.state.twitter_user && <h5>You have connected twitter with:{this.state.twitter_user}</h5>}  </div>
              </GridItem>
              
              <GridItem xs={12} sm={10} md={8} style={{ margin: 'auto' }} id="margionZeroPub">
                <h5 style={{
                  width: '100%',
                  textAlign: 'left',
                  marginBottom: '0px',
                  fontSize: '16px',
                  fontWeight: '600',
                  color: '#2b2d42',
                  marginTop: '15px',
                }}>Connect Facebook Page</h5>
                <p
                  style={{
                    textAlign: 'left',
                    fontSize: '14px',
                    marginBottom: '0px',
                    color: 'rgb(153, 163, 180)'
                  }}
                >
                  Post and schedule on Facebook page(s) directly from dashboard
			          	</p>
                  <Button onClick={this.connectFB} style={{ display: 'block', fontSize:"14px", marginTop: '10px', backgroundColor: '#4267B2', color: "white", width: isWidthDown('xs', this.props.width) ? '100%' : 'auto' }}> Connect facebook page </Button>
                {this.state.showLoader && <AppLoader size='20px' />}
                <div style={{ marginTop: '10px' }}>{this.state.facebook_user && <h5>You have connected facebook with: {this.state.facebook_user}</h5>}  </div>
              </GridItem>
              
            </div>
            <Button color="secondary" disabled={this.state.showLoader} style={{
              fontSize: '14px',
              textTransform: 'uppercase',
              margin: 'auto',
              marginTop: '10px',
              marginBottom:'20px',
              width: isWidthDown('xs', this.props.width) ? '100%' : 'auto'
            }} onClick={this.moveNext}>
              {this.state.success ? 'Next' : 'continue'}
            </Button>
          </div>
        </GridItem>
      </>
    )
  }
}
export default (withWidth()(SocialMedia));