import React, { Component } from 'react';
import { connect } from "react-redux";
import Hidden from '@material-ui/core/Hidden';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import getFeeds, { moveFeed, resetFeed } from "../feeds-actions";
import TwitterContainer from "../components/twitterCard.jsx";
import InstaCard from "../components/instaCard.jsx";
import YoutubeCard from "../components/youtubeCard.jsx";
import Button from "components/CustomButtons/Button.jsx";
import AppLoader from "../../../app-components/app-loader.jsx";
import feedStyle from "../../../assets/jss/material-dashboard-pro-react/components/feedStyle.jsx";
import PlaceholderBtn from '../../../app-components/placeholderBtn.jsx';

class FeedContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRefreshButton: false,
    }
  }
  buttonShow() {
    const ref = this;
    setTimeout(() => {
      ref.setState({ showRefreshButton: true })
    }, 45 * 1000)
  }
  componentDidMount() {
    this.props.resetFeed()
    this.props.getFeeds();
    const ref = this;
    setTimeout(() => {
      ref.setState({ showRefreshButton: true })
    }, 45 * 1000)
  }
  updateFeed = () => {
    this.setState({ showRefreshButton: false });
    this.props.getFeeds(true);
    this.forceUpdate();
    this.buttonShow();
  }

  render() {
    const { twitterFeeds, instaFeeds, youtubeFeeds, moveFeed, feedType, feedMessage, classes } = this.props;
    const { isLoadingTwitterFeed } = twitterFeeds;
    const { isLoadingInstaFeed } = instaFeeds;
    const { isLoadingYouTubeFeed } = youtubeFeeds;
    return (
      <>
        <GridContainer>
          {
            this.state.showRefreshButton
            && <Button onClick={this.updateFeed} style={{ position: 'absolute', left: '45%', marginTop: '16px', zIndex: '999', padding: '4px 16px' }}>
            Latest Feed
            <i className="material-icons">
              refresh
            </i>
          </Button>}
          <Hidden xsDown>
            <GridContainer>
              <GridItem sm={12} md={8} className={classes.feedbar}>
                {feedMessage.unhighlight}
              </GridItem>
              <GridItem sm={12} md={4} className={classes.feedbar}>
                {feedMessage.highlight}
              </GridItem>
            </GridContainer>
          </Hidden>
          <div style={{ width: "100%" }}>
            {
              isLoadingYouTubeFeed && <AppLoader
                message={`Loading Feed...`}
              />
            }
          </div>
          <div style={{ width: "100%" }}>
            {
              isLoadingTwitterFeed && <AppLoader
                message={`Loading Feed...`}
              />
            }
          </div>
          <div style={{ width: "100%" }}>
            {
              isLoadingInstaFeed && <AppLoader
                message={`Loading Feed...`}
              />
            }
          </div>
          {(feedType === 'twitter' && !isLoadingTwitterFeed && (!twitterFeeds.unheiglight || (twitterFeeds.unheiglight && Object.keys(twitterFeeds.unheiglight).length === 0)) && (!twitterFeeds.highlight || (twitterFeeds.highlight && Object.keys(twitterFeeds.unheiglight).length === 0))) &&
            <PlaceholderBtn message='Follow an account to get started.' handlePlaceholder={()=>{this.props.handlePlaceholder(null, 3)}}/>
          }
          {
            feedType === 'twitter' && <GridItem id="unheiglightContainer" sm={12} md={9} style={{ textAlign: 'center', borderRight: "3px solid rgb(192, 188, 187)", marginTop: '30px' }}>
              {twitterFeeds.unheiglight && Object.keys(twitterFeeds.unheiglight).map((idStr) => {
                return < TwitterContainer key={idStr} moveFeed={moveFeed} twitrId={idStr} highlight={false} />
              })}
            </GridItem>
          }
          {
            feedType === 'twitter' && <GridItem id="heiglightContainer" sm={12} md={3}>
              {twitterFeeds.highlight && Object.keys(twitterFeeds.highlight).length > 0 && Object.keys(twitterFeeds.highlight).map((idStr, key) => {
                return < TwitterContainer key={idStr} individual={twitterFeeds.highlight[idStr]["individual"]} autoHighlighted={twitterFeeds.highlight[idStr]["autoHighlighted"]} moveFeed={moveFeed} twitrId={idStr} highlight={true} />
              })}
            </GridItem>
          }

          {(feedType === 'instagram' && !isLoadingInstaFeed && (!instaFeeds.unheiglight || (instaFeeds.unheiglight && Object.keys(instaFeeds.unheiglight).length === 0)) && (!instaFeeds.highlight || (instaFeeds.highlight && Object.keys(instaFeeds.unheiglight).length === 0))) &&
            <PlaceholderBtn message='Follow an account to get started.' handlePlaceholder={()=>{this.props.handlePlaceholder(null, 3)}}/>
          }
          {
            feedType === 'instagram' && <GridItem id="unheiglightContainer" sm={12} md={9} style={{ textAlign: 'center', borderRight: "3px solid rgb(192, 188, 187)", marginTop: '30px' }}>
              {instaFeeds && instaFeeds.unheiglight && Object.keys(instaFeeds.unheiglight).length > 0 && Object.keys(instaFeeds.unheiglight).map((idStr) => {
                return < InstaCard key={idStr} moveFeed={moveFeed} shortcode={instaFeeds.unheiglight[idStr]["shortcode"]} idStr={idStr} highlight={false} />
              })}
            </GridItem>
          }
          {
            feedType === 'instagram' && <GridItem id="heiglightContainer" sm={12} md={3} >
              {instaFeeds && instaFeeds.highlight && Object.keys(instaFeeds.highlight).length > 0 && Object.keys(instaFeeds.highlight).map((idStr) => {
                return < InstaCard key={idStr} moveFeed={moveFeed} shortcode={instaFeeds.highlight[idStr]["shortcode"]} idStr={idStr} highlight={true} />
              })}
            </GridItem>
          }
          {(feedType === 'youtube' && !isLoadingYouTubeFeed && (!youtubeFeeds.unheiglight || (youtubeFeeds.unheiglight && Object.keys(youtubeFeeds.unheiglight).length === 0)) && (!youtubeFeeds.highlight || (youtubeFeeds.highlight && Object.keys(youtubeFeeds.unheiglight).length === 0))) &&
            <PlaceholderBtn message='Follow a channel to get started.' handlePlaceholder={()=>{this.props.handlePlaceholder(null, 3)}}/>
          }
          {
            feedType === 'youtube' && <GridItem id="unheiglightContainer" sm={12} md={9} style={{ display: "flex", flexWrap: 'wrap', justifyContent: 'space-around', borderRight: "3px solid rgb(192, 188, 187)", marginTop: '30px' }}>
              {youtubeFeeds && youtubeFeeds.unheiglight && Object.keys(youtubeFeeds.unheiglight).length > 0 && Object.keys(youtubeFeeds.unheiglight).map((idStr) => {
                return < YoutubeCard key={idStr} moveFeed={moveFeed} idStr={idStr} channel={youtubeFeeds.unheiglight[idStr]["channel"]} title={youtubeFeeds.unheiglight[idStr]["title"]} highlight={false} />
              })}
            </GridItem>
          }
          {
            feedType === 'youtube' && <GridItem id="heiglightContainer" sm={12} md={3} >
              {youtubeFeeds && youtubeFeeds.highlight && Object.keys(youtubeFeeds.highlight).length > 0 && Object.keys(youtubeFeeds.highlight).map((idStr) => {
                return < YoutubeCard key={idStr} moveFeed={moveFeed} idStr={idStr} channel={youtubeFeeds.highlight[idStr]["channel"]} title={youtubeFeeds.highlight[idStr]["title"]} highlight={true} />
              })}
            </GridItem>
          }
        </GridContainer>
      </>
    )
  }
}

const mapStateToProps = (store) => {
  const { twitterFeeds, instaFeeds, youtubeFeeds } = store.cms.feeds;
  return {
    twitterFeeds,
    instaFeeds,
    youtubeFeeds
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  const { feedType } = ownProps;
  return {
    getFeeds: (updatesOnly) => dispatch(getFeeds(feedType, updatesOnly)),
    moveFeed: (idStr, feedReducer, highlightType) => dispatch(moveFeed(feedType, idStr, feedReducer, highlightType)),
    resetFeed: () => dispatch(resetFeed())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(feedStyle)(FeedContainer));
