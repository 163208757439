import React, { Component } from 'react';
import ArticlePreviewModal from '../component/article-modal.jsx';
import { connect } from 'react-redux';
import {
  removeArticleIdForPreviewArticle,

  submitArticle,
  approveArticle,
  publishArticle,
  saveAricle

} from '../article-action';
import { getArticleDetailsFromArticleId } from "../../../app-selector.js";

class ArticlePreview extends Component {
  render() {
    const {
      previewArticleId,
      removeArticleIdForPreviewArticle,
      articleDetail,
      isLoadingPreviewArticle,

      articleSettings,
      submitArticle,
      approveArticle,
      publishArticle,
      saveAricle

    } = this.props;
    return (
      <ArticlePreviewModal
        isLoadingPreviewArticle={isLoadingPreviewArticle}
        articleDetail={articleDetail}
        previewArticleId={previewArticleId}

        removeArticleIdForPreviewArticle={removeArticleIdForPreviewArticle}
        articleSettings={articleSettings}

        submitArticle={submitArticle}
        approveArticle={approveArticle}
        publishArticle={publishArticle}
        saveAricle={saveAricle}
      />
    )
  }
}
const mapStateToProps = (store) => {
  const {
    articleSettings
  } = store.cms;
  const { previewArticleId } = articleSettings;
  const articleDetail = getArticleDetailsFromArticleId(store.cms, previewArticleId);
  const { isLoadingPreviewArticle } = articleDetail;
  return {
    previewArticleId,
    articleDetail,
    isLoadingPreviewArticle,
    articleSettings

  }
}

const mapDispatchToProps = (dispatch) => {

  return {
    removeArticleIdForPreviewArticle: () => dispatch(removeArticleIdForPreviewArticle()),
    submitArticle: () => dispatch(submitArticle()),
    approveArticle: () => dispatch(approveArticle()),
    publishArticle: () => dispatch(publishArticle()),
    saveAricle: () => dispatch(saveAricle())

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticlePreview);
