import React from 'react';
import { connect } from 'react-redux';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import AppLoader from "../../../app-components/app-loader.jsx";

import CategoriesList from '../components/categories-list';
import CategoryModal from '../components/category-modal';
import {
  fetchCategories,
  toggleCategoryModal,
  submitCategory,
  setCategoryModalData,
  submitSubCategory
} from '../../website-management-actions.js';

class CategoriesContainer extends React.Component {
  componentDidMount(){
      this.props.fetchCategories();
  }

  render(){
    const {
      isLoadingCategories,
      categories,
      showCategoryModal,
      categoryModalData,
      toggleCategoryModal,
      submitCategory,
      setCategoryModalData,
      editMode,
      submitSubCategory
    } = this.props;
      if(isLoadingCategories)
        return <AppLoader message="Loading Categories"/>
    return (
      <>
        <CategoriesList
          categories={categories}
          toggleCategoryModal={toggleCategoryModal}
          setCategoryModalData={setCategoryModalData}
          submitSubCategory={submitSubCategory}
        />
        <CategoryModal
        showCategoryModal={showCategoryModal}
        toggleCategoryModal={toggleCategoryModal}
        submitCategory = {submitCategory}
        categoryModalData = {categoryModalData}
        editMode={editMode}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    websiteManagementReducer: {
    categoryReducer: {
      isLoadingCategories,
      categories,
      showCategoryModal,
      categoryModalData,
      editMode
    }}} = state;
  return {
    isLoadingCategories,
    categories,
    showCategoryModal,
    categoryModalData,
    editMode
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchCategories: () => dispatch(fetchCategories()),
    toggleCategoryModal: (flag, categoryId) => dispatch(toggleCategoryModal(flag, categoryId)),
    submitCategory: (category) => dispatch(submitCategory(category)),
    setCategoryModalData: (category) => dispatch(setCategoryModalData(category)),
    submitSubCategory: (category, subcategory) => dispatch(submitSubCategory(category, subcategory))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesContainer);
