import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ForgotPasswordForm from "./views/forgot-password-form.jsx";
import forgotPasswordPageStyle from "assets/jss/material-dashboard-pro-react/views/forgotPasswordPageStyle.jsx";
import Header from '../app-components/header.jsx';
import Footer from '../app-components/footer.jsx';

class ForgotPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardAnimaton: "cardHidden",
    };
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <Header />
          <div className={classes.container} style={{marginTop: '150px', height: "calc(100vh - 200px)"}}>
            <GridContainer justify="flex-start">
              <GridItem xs={10} sm={8} md={6}>
                <ForgotPasswordForm  {...this.props} />
              </GridItem>
            </GridContainer>
          </div>
        <Footer/>
      </>
    );
  }
}

ForgotPasswordPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(forgotPasswordPageStyle)(ForgotPasswordPage);
