
import React, { PureComponent } from "react"
import throttle from "lodash/throttle";
import TextField from '@material-ui/core/TextField';

class PageTitle extends PureComponent {
  constructor(props) {
    super(props);
    this.setChangeValue = throttle(this._setChangeValue, 100);
    this.textArea = React.createRef();
    this.state = { length: 0, title: props.title || "" };
  }

  componentDidMount() {
    const { defaultValue } = this.props;
    let length = 0;
    if (defaultValue) {
      length = defaultValue.length;
    }
    this.setState({ length });
  }
  _setChangeValue = (e) => {
    this.props.setPageTitle(e.target.value);
  }
  onChange = (e) => {
    e.persist();
    this.setState({ length: e.target.value.length, title: e.target.value });
    this.setChangeValue(e);
  }
  render() {
    const { title } = this.state;

    return (
      <div style={{ display: "flex", alignItems: "flex-end", marginBottom: '30px' }}>
        <TextField
          id="standard-page-name"
          label="Enter Page Title"
          value={title}
          disabled={this.props.defaultPage}
          onChange={this.onChange}
          onBlur={this.onChange}
          InputProps={{
            style: {
              lineHeight: "1.2",
              fontSize: "24px",
              fontWeight: "bold",
              width: "100%",

            },
          }}
          inputProps={{ maxLength: 150 }}

          multiline={true}
          fullWidth
          margin="normal"
        />
        <p>{`${title && title.length || 0}/150`}</p>
      </div>
    )
  }
}
export default PageTitle;