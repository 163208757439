
import { StoryPostAction, MessageTypes } from "../../app-constants/index.js";
import { setAppMessage } from "../../app-actions.js";
import { MeawwApi } from "../../utils/api.js";
// action

function loadStoryPostRequest() {
  return {
    type: StoryPostAction.LOAD.REQUEST
  }
}
function loadStoryPostFailure() {
  return {
    type: StoryPostAction.LOAD.FAILURE
  }
}
function loadStoryPostSuccess(data) {
  return {
    type: StoryPostAction.LOAD.SUCCESS,
    data
  }
}
function loadTemplateSuccess() {
  return {
    type: StoryPostAction.LOAD.TEMPLATE.SUCCESS
  }
}
function loadTemplateRequest() {
  return {
    type: StoryPostAction.LOAD.TEMPLATE.REQUEST
  }
}
function loadTemplateFailure() {
  return {
    type: StoryPostAction.LOAD.TEMPLATE.FAILURE
  }
}
export default function loadStoryPost() {
  return (dispatch, getState) => {
    const { login: { user: { activeDomain } } } = getState();
    dispatch(loadStoryPostRequest());
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/stories/${activeDomain}`,
          (error, data) => {
            if (error) {
              dispatch(loadStoryPostFailure());
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return;
            }
            dispatch(loadStoryPostSuccess(data.result));
            return;
          })
      })
  }
}

export function setStoryDetail(detail) {
  return (dispatch) => {
    dispatch(loadTemplateRequest());
      convertImgToDataURLviaCanvas(
      process.env.REACT_APP_API_SERVER==="https://dev.pubninja.com"
      ?`${detail.coverPicture}_1200_630${detail.imgExtension}`.replace('//cheesecake-dev-articles.meaww.com','https://development-articles-s3-bucket.s3.amazonaws.com')
      :`${detail.coverPicture}_1200_630${detail.imgExtension}`.replace('//cheesecake.articleassets.meaww.com','https://s3.amazonaws.com/cheesecake.articleassets')
      ,
      function(dataUrl) {
        detail = {...detail, coverPicture: dataUrl};
        //console.log('RESULT:', detail);
        dispatch(setStoryDetailSuccess(detail));
        dispatch(loadTemplateSuccess());
      }
    );
  }
}

function setStoryDetailSuccess(detail) {
  return {
    type: StoryPostAction.SET.STORY_DETAIL,
    storyDetail: detail
  }
}

const convertImgToDataURLviaCanvas = (url, callback, outputFormat) => {
  var img = new Image();
  img.crossOrigin = 'Anonymous';
  img.onload = function() {
    var canvas = document.createElement('CANVAS');
    var ctx = canvas.getContext('2d');
    var dataURL;
    canvas.height = this.height;
    canvas.width = this.width;
    ctx.drawImage(this, 0, 0);
    dataURL = canvas.toDataURL(outputFormat);
    callback(dataURL);
    canvas = null;
  };
  img.src = url;
}

export function componentUnMount(){
  return {
    type: StoryPostAction.LOAD.TEMPLATE.UNMOUNT
  }
}
