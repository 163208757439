import React, { PureComponent } from 'react';
import { DateRange } from "react-date-range";

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css'; // theme css file";
import { format, addDays } from 'date-fns';
import moment from 'moment';



class DateRangePicker extends PureComponent {
  handleRangeChange = (payload) => {
    this.props.setDateRanges(payload);
  }
  render() {
    const {
      dateRange: { selection },
      showCalendar,
      style = {}
    } = this.props;
    if (!showCalendar) {
      return null;
    }

    return (
      <div style={{ zIndex: "200", position: "relative", ...style, color: "green" }}>
        <DateRange
          onChange={this.handleRangeChange}
          moveRangeOnFirstSelection={false}
          ranges={[selection]}
          className={'PreviewArea'}
          maxDate={moment().toDate()}
          minDate={moment("20180401", "YYYYMMDD").toDate()}
        />
      </div>
    )
  }
}


export default DateRangePicker;
