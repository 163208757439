import registerUser from "./register-user/register-user-reducers.js";
import accountManagement from "./account-management/account-management-reducers.js";

import { combineReducers } from "redux";

const userManamgement = combineReducers({
    registerUser: registerUser,
    accountManagement: accountManagement,
});


export default userManamgement;