import React from 'react';
import Button from "components/CustomButtons/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppLoader from "../../../app-components/app-loader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

class newsRoomModal extends React.Component {
  state = {
      imgIndex: 0,
  }
  componentWillUnmount(){
      this.setState({...this.state, imgIndex: 0});
  }
  changeImage = (length, val) => {
    if(length!=1)
      document.getElementById('carouselImg').style.display='none';
    this.setState({...this.state, imgIndex: -1});
    let imgIndex = this.state.imgIndex;
    if(val===1){
      imgIndex = (imgIndex===length-1 || length===1)?0:imgIndex+1;
    }
    else {
      imgIndex = (imgIndex===0 || length===1)?length-1:imgIndex-1;
    }
    this.setState({...this.state, imgIndex});
    document.getElementById('carouselImg').onload=this.loadImage;

  }
  loadImage = () => {
    document.getElementById('carouselImg').style.display='block';
  }
  handleClick = () => {
    this.setState({...this.state, imgIndex: 0});
    this.props.handleClick();
  }
  render(){
    const {
      saveToDraft, draftButtonMessage, showModal, handleClick, modalData
    } = this.props;
    if(modalData===undefined || modalData===null){
      return(
        <Dialog
          open={showModal}
          style={{
            maxWidth: '100%',
            backgroundColor: 'transparent',
            overflow: 'visible',
            margin: 'auto'
          }}>
          <DialogContent>
            <AppLoader/>
          </DialogContent>
        </Dialog>
      );
    }
    else{
      const {
        title,
        headline,
        headline_extended,
        located,
        associations,
        altids:{itemid},
        renditions: {
          nitf: {
            content
          }
        }
      } = modalData;
      const imgcaption = [], imgsrc=[];
      const buttonStyle = {
        color: '#fb4d24',
      };
      for(let item in associations){
        if(associations[item]['image']['data']['item']['renditions']['preview']){
          imgcaption.push(associations[item]['image']['data']['item']['description_caption']);
          imgsrc.push(associations[item]['image']['data']['item']['renditions']['preview']['href']);
        }
      }
      return(
        <Dialog
          open={showModal}
          style={{
            maxWidth: '100%'
          }}
        >
          <DialogTitle>{headline}</DialogTitle>
          <DialogContent>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <div>
                {located}
                <p style={{ fontWeight: 'bold', fontStyle: 'italic'}}>
                  {headline_extended}
                </p>
                {content && content.length && <div dangerouslySetInnerHTML={{__html: content}}>
                  </div>
                }
              </div>
              {imgsrc.length>0 && <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                <span>{this.state.imgIndex+1} / {(imgcaption.length===0)?'':(imgcaption.length)}{(imgcaption.length===1)?' image':' images'}</span>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <span onClick={this.changeImage.bind(null, imgcaption.length, -1)} style={{cursor: 'pointer', fontSize: '2em', color: '#fb4d24' }}>
                   &lt;
                  </span>
                  <div
                    style={{ position: 'relative', height: '250px', width: '400px'}}
                  >
                    <AppLoader/>
                    <img
                      id="carouselImg"
                      src={imgsrc[this.state.imgIndex]}
                      style={{
                        width: '400px',
                        maxWidth: '100%',
                        height: '250px',
                        objectFit: 'contain',
                        padding: '5px',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                      }}
                    />
                  </div>
                  <span onClick={this.changeImage.bind(null, imgcaption.length, 1)} style={{cursor: 'pointer', fontSize: '2em', color: '#fb4d24' }}>
                    &gt;
                  </span>
                </div>
                <div style={{fontSize: '0.8em', margin: '20px 0 50px'}}>
                  {imgcaption[this.state.imgIndex]}
                </div>
              </div>
              }
            </div>
          </DialogContent>
          <DialogActions>
            <GridContainer justify="flex-end">
              <GridItem xs={12} sm={6} style={{display: 'flex', justifyContent: 'center'}}>
              <Button onClick={saveToDraft.bind(null, itemid)} color="secondary">
                Save to my draft
              </Button>
              </GridItem>
              <GridItem xs={12} sm={6} style={{display: 'flex', justifyContent: 'center'}}>
                <Button onClick={this.handleClick} color="primary">
                  Close
                </Button>
              </GridItem>
            </GridContainer>
          </DialogActions>
        </Dialog>
      );
    }
  }
}

export default newsRoomModal;
