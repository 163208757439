import React from "react";
import { Icon } from "antd";

const SocialLinks = ({ fbProfile, twitterProfile, ownWebsite }) => (
  <div style={{ textAlign: "center", fontSize: "20px", marginBottom: "20px" }}>
    {
      fbProfile && <a href={fbProfile} target="_blank" ><Icon type="facebook" style={{ margin: "0px 5px", color: '#2b2d42' }} /></a>
    }
    {
      twitterProfile && <a href={twitterProfile} target="_blank"><Icon type="twitter" style={{ margin: "0px 5px", color: '#2b2d42' }} /></a>
    }
    {
      ownWebsite && <a href={ownWebsite} target="_blank" ><Icon type="global" style={{ margin: "0px 5px", color:'#2b2d42' }} /></a>
    }
  </div>

)
export default SocialLinks;

