

const NewsLetter = {
  GET: {
    REQUEST: "NewsLetter.GET.REQUEST",
    FAILURE: "NewsLetter.GET.FAILURE",
    SUCCESS: "NewsLetter.GET.SUCCESS",
  },
  POST: {
    REQUEST: "NewsLetter.POST.REQUEST",
    FAILURE: "NewsLetter.POST.FAILURE",
    SUCCESS: "NewsLetter.POST.SUCCESS",
  },
  GET_CAMPAIGN: {
    REQUEST: "NewsLetter.Get.Campaign.REQUEST",
    FAILURE: "NewsLetter.Get.Campaign.FAILURE",
    SUCCESS: "NewsLetter.Get.Campaign.SUCCESS",
  },
  ACTION: {
    REQUEST: "NewsLetter.Get.Action.REQUEST",
    FAILURE: "NewsLetter.Get.Action.FAILURE",
    SUCCESS: "NewsLetter.Get.Action.SUCCESS",
  },
  FUll_DETAIL: {
    REQUEST: "NewsLetter.GET.FUll_DETAIL.REQUEST",
    FAILURE: "NewsLetter.GET.FUll_DETAIL.FAILURE",
    SUCCESS: "NewsLetter.GET.FUll_DETAIL.SUCCESS",
  }
}
export default NewsLetter;
