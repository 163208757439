import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import AppLoader from "app-components/app-loader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Close from "@material-ui/icons/Close";
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import Cropper from 'react-easy-crop';

class StoryForm extends React.Component {
  state = {
    crop: { x: 0, y: 0 },
    zoom: 2,
    aspect: 16 / 9,
    croppedAreaPixels: {},
    heading: '',
    actionLink: '',
    error: {
      emailError: "Title is required.",
    },
  }
  handleClose = () => {
    this.setState({
      crop: { x: 0, y: 0 },
      zoom: 2,
      aspect: 16 / 9,
      croppedAreaPixels: {},
      heading: '',
      actionLink: '',
      error: {
        emailError: "Title is required.",
      },
    });
    this.props.closeAddStoryModal();
  }

  onCropChange = crop => {
    this.setState({ crop })
  }
  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  }
  onZoomChange = zoom => {
    this.setState({ zoom })
  }
  handleHeading = (event) => {
    this.setState({ heading: event.target.value })
  }
  handleActionLink = (event) => {
    this.setState({ actionLink: event.target.value })
  }
  saveCoverImage = () => {
    const { croppedAreaPixels, heading, actionLink } = this.state;

    this.props.addNewStory(croppedAreaPixels, heading, actionLink);
    this.setState({ heading: '' });
  }

  render() {
    const {
      heading,
      actionLink,
      error: { emailError, passwordError },
    } = this.state;
    const { classes } = this.props;
    const showDialog = this.props.step >= 1 ? true : false;
    return (
      <Dialog maxWidth={'sm'} fullWidth={true} fullScreen={this.props.fullScreen || false} open={showDialog}>
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}>
          <Button
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={this.handleClose}
          >
            <Close className={classes.modalClose}/>
          </Button>
          <h4 className={classes.modalTitle}>Add Story</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          {this.props.step === 1 &&
            <div>
              <AppLoader message={'Uploading'} />
            </div>
          }
          {this.props.step === 2 &&
            <>
              <h3 style={{ textAlign: 'center', color: '#2b2d42', fontSize: '1.2em' }}>CROP THUMBNAIL</h3>
              <div style={{ position: 'relative', width: '100%', height: '400px', marginBottom: '20px' }}>

                <Cropper
                  image={this.props.image}
                  crop={this.state.crop}
                  zoom={this.state.zoom}
                  aspect={this.state.aspect}
                  onCropChange={this.onCropChange}
                  onCropComplete={this.onCropComplete}
                  onZoomChange={this.onZoomChange}
                  cropShape='round'
                  cropSize={{ width: 300, height: 300 }}
                />
              </div>
              <div style={{
                position: 'relative',
                textAlign: 'center'
              }}>
                <Button color="secondary" onClick={() => { this.props.setStep(3) }}>
                  Add Title & Action Link
                </Button>
              </div>
            </>
          }
          {this.props.step === 3 &&
            <>
              <div style={{ position: 'relative', width: '40%', minWidth: '250px', margin: '5% auto', textAlign: 'center' }}>
                <h4>Add Title and Action Link</h4>
                <div style={{marginTop: '-25px'}}>
                <CustomInput
                  labelText="Story Title"
                  //infoText="Title to be shown for the story."
                  id="story_title"
                  value={heading}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disableUnderline: true,
                    onChange: this.handleHeading,
                  }}
                  helpText={emailError}
                />
                </div>
                <div style={{marginTop: '-25px'}}>
                <CustomInput
                  labelText="Action Link"
                  //infoText="Link of the article or story to open when click on read more."
                  id="action_link"
                  value={actionLink}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disableUnderline: true,
                    onChange: this.handleActionLink,
                  }}
                />
                </div>
                <div style={{display: 'flex'}}>
                  <Button
                    color="secondary"
                    onClick={this.saveCoverImage}
                    disabled={!heading.trim().length}
                  >
                    UPDATE
                </Button>
                  {this.props.disableSubmitButton && <AppLoader size={20} />}
                </div>
              </div>
            </>
          }
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(modalStyle)(withMobileDialog()(StoryForm));
