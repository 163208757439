import React from 'react';
import { withRouter } from "react-router-dom";

import TwitterLogin from "react-twitter-auth";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
//import Button from "@material-ui/core/Button";
import Button from "components/CustomButtons/Button.jsx";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import Card from "components/Card/Card.jsx";

import LoaderModal from '../fb/component/loader-modal';

import { MessageTypes } from "../../app-constants/index.js";

const mandatoryPermissions = ['pages_show_list', 'pages_manage_posts', 'business_management', 'pages_read_engagement'];

const loginUrl = `${process.env.REACT_APP_API_SERVER}/schedule/auth/twitter`;
const requestTokenUrl = `${process.env.REACT_APP_API_SERVER}/schedule/15/auth/twitter/reverse`;

class AddSocialMedia extends React.Component {
  state = {
    showModal: false,
    showLoader: false
  }

  getDebugTokenDB = (obj) => {
    return new Promise((res, rej) => {
      window.FB.api(`/debug_token?input_token=${obj.access_token}`, r => {
        if (r.error) return res(obj);
        obj.debug = r;
        return res(obj);
      });
    });
  }

  debugToken = (objD) => {
    return new Promise((res, rej) => {
      Promise.all(objD.data.map((obj) => this.getDebugTokenDB(obj))).then((d)=>res({pagesArr: d, userObj: objD.auth})).catch(e=>rej(e));
    });
  }

  getAllAccounts = (auth) => {
    return new Promise((res, rej) => {
      window.FB.api('/me/accounts', (r) => {
        if (r.error){
          return rej(r.error.message || r.error);
        } else if (r.data && r.data.length) {
          return res({data: r.data, auth});
        }
        return rej(`Error occured while getting your Facebook Page.`);
      });
    });
  }

  checkMandatoryPermissions = (auth) => {
    return new Promise((res, rej) => {
      window.FB.api('/me/permissions', (r) => {
        if (r.error) {
          return rej(r.error.message || r.error);
        } else if (r.data && r.data.length) {
          const grantedPermissions = [];
          Object.keys(r.data).forEach((index) => {
            const obj = r.data[index];
            if (obj.status === 'granted') {
              grantedPermissions.push(obj.permission);
            }
          });
          const permDiff = mandatoryPermissions.filter((perm) => grantedPermissions.indexOf(perm) === -1 );
          if (permDiff.length === 0) {
            return res(auth);
          } else {
            return rej(`Please allow mandatory permissions ${permDiff.join(', ')}`);
          }
        } else {
          return rej(`Error while getting my permissions from Facebook.`);
        }
      });
    });
  }

  fbLogin = () => {
    this.setState({error: ''});
    return new Promise((resolve, reject) => {
      window.FB.login((res)=>{
        if (res.status === 'connected') {
          return resolve(res.authResponse);
        }
        return reject(`Login error, pls login with facebook`);
      }, {scope: mandatoryPermissions.join(',')});
    });
  }

  connectFB = () => {
    if (window.FB) {
      this.setState({showLoader: true});
      this.fbLogin()
        .then((auth) => {
          return this.checkMandatoryPermissions(auth)
        }).then((auth) => {
          return this.getAllAccounts(auth)
        }).then((obj) => {
          return this.debugToken(obj)
        }).then(data => {
          this.storeAllFbPages(data);
        }).catch((e) => {
            this.setState({showLoader: false});
            this.props.setAppMessage((e.error||{}).message || e.message || JSON.stringify(e),{type: MessageTypes.ERROR});
          //this.setState({error: e});
        });
    } else {
      this.props.setAppMessage('Facebook script loading error, pls turn off your blocker and retry.',{type: MessageTypes.ERROR});
    }
  }

  storeAllFbPages = (res) => {
    //this.props.fetchFbRequest();
    if (res.pagesArr && res.pagesArr.length) {
      //Add a loader while post api is called
      this.props.storeFacebookPage(res)
          .then((data) => {
            //this.props.fetchFbSuccess();
            //this.props.getFacebookpage();
            this.setState({showLoader: false, showModal: false});
            this.props.setAppMessage("Successfully added page.", {type: MessageTypes.SUCCESS, onOk: this.props.getFacebookPage});
          })
          .catch((err)=>{
            //this.props.fetchFbFailure();
            this.setState({showLoader: false});
            this.props.setAppMessage((err.error||{}).message || err.message || JSON.stringify(err), {type: MessageTypes.ERROR});
          });

    } else {
      this.setState({showLoader: false});
      this.props.setAppMessage(`No page data`, {type: MessageTypes.ERROR});
    }
  }

  onSuccess = (response) => {
      response.json().then(user => {
        if(user){
          this.setState({ isAuthenticated: true, verify: true, render: true });
          this.putUser(user);
          //this.getAcount();
        } else {
          ///
        }
      });
    // }
  };

  onFailed = (error) => {
    this.props.setAppMessage(error.toString(), {type: MessageTypes.ERROR});
    this.setState({ showLoader: false});
  };

  putUser = (user) => {
    this.props.updateUser(user).then(data => {
      this.setState({showModal: false, showLoader: false});
      this.props.setAppMessage('Successfully added page', {type: MessageTypes.SUCCESS, onOk: this.props.getTwitterPage});

    }, _ => {
      this.setState({showModal: false, showLoader: false});
      alert(_);
      // Show error Message to the user, I will be adding it later
    });
  }

  removeUser = (data) => {
    this.props.removeUser(data).then(data => {
    }, _ => {
      alert(_);
      // Show error Message to the user, I will be adding it later
    });
  }

  render(){
    const { showModal } = this.state;
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer justify="space-between">
              <GridItem xs={12} md={6}>
                <div style={{float: 'left', marginTop: '14px', fontSize: '1.5em', color: 'black'}}>
                  Your Social Accounts
                </div>
              </GridItem>
              <GridItem xs={12} md={6}>
                <Button
                  onClick={() => {this.setState({ showModal: true })}}
                  style={{ float: isWidthDown('sm', this.props.width) ? 'left' : 'right',
                  marginTop: isWidthDown('sm', this.props.width) ? '30px' : '0'
                }}
                  color="primary"
                >
                  <span style={{ display: "flex"}}>
                    <AddCircleIcon /> &nbsp;<span style={{ paddingTop: '2px' }}>{`Add Account`}</span>
                  </span>
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>

        <LoaderModal
          showModal={this.state.showLoader}
          showLoader={this.state.showLoader}
        />

        <Dialog
          open={showModal}
          fullWidth={true}
          onClose={()=>{this.setState({showModal: false})}}
          maxWidth="sm"
          aria-labelledby="customized-dialog-title"
        >
          <DialogTitle>Select Account Type</DialogTitle>
          <DialogContent>
            <div style={{display: 'flex', justifyContent: 'space-around', padding: '50px 10px'}}>
              <GridContainer justify="center">
              <GridItem xs={12} md={6}>
                <div style={{textAlign: 'center'}}>
                  <Button onClick={this.connectFB} style={{backgroundColor: '#4267B2', color:"white"}}> Add Facebook Page </Button>
                </div>
              </GridItem>
              <GridItem xs={12} md={6}>
                <div style={{cursor: 'pointer', textAlign: 'center'}} onClick={()=>{this.setState({showLoader: true})}}>
                  <TwitterLogin
                    loginUrl={loginUrl}
                    onFailure={this.onFailed}
                    onSuccess={this.onSuccess}
                    requestTokenUrl={requestTokenUrl}
                    style={{
                      border: '0',
                      backgroundColor: '#55acee',
                      borderRadius: '4px',
                      color: 'white',
                      padding: '12px 25px',
                      marginTop: '4px',
                      cursor: 'pointer',
                      fontSize: '12px'
                    }}
                  >
                    <div style={{}}> ADD TWITTER PAGE </div>
                  </TwitterLogin>
                </div>
              </GridItem>
              </GridContainer>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => {this.setState({ showModal: false })}}
            >
              Cancel
          </Button>
          </DialogActions>
        </Dialog >
      </>
    );
  }
}

export default withRouter(withWidth()(AddSocialMedia));
