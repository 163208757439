import { StoryCmsAction } from "../../app-constants/index.js";
import { MeawwApi } from "../../utils/api.js";
import { setAppMessage } from "../../app-actions.js";
import { MessageTypes } from "../../app-constants/index.js";


function getStoryListFailure() {
  return {
    type:StoryCmsAction.STORY_LIST.FAILURE,
  }
}

function getStoryListSuccess(list) {
  return {
    type: StoryCmsAction.STORY_LIST.SUCCESS,
    list,
  }
}

function addStoryListFailure() {
  return {
    type: StoryCmsAction.ADD_STORY.FAILURE,
  }
}

function addStoryListSuccess(newList) {
  return {
    type: StoryCmsAction.ADD_STORY.SUCCESS,
    newList,
  }
}

export function setActiveStoryList(activeStoryList) {
  return {
    type: StoryCmsAction.SET.ACTIVE_STORY,
    activeStoryList
  }
}

export function setActiveStoryListExpiry(activeStoryListExpiry) {
  return {
    type: StoryCmsAction.SET.ACTIVE_STORY_EXPIRY_TIME,
    activeStoryListExpiry,
  }
}

export function getStoryList() {
  return (dispatch, getState) => {
    const { cms, login: { user: { activeDomain } } } = getState();

    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/stories/${activeDomain}/list`,
          (error, data) => {
            if (error) {
              dispatch(getStoryListFailure());
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }))
              return Promise.reject(error);
            }
            if (data.result.list.length) {
              dispatch(setActiveStoryList(data.result.list[0].id));
              dispatch(setActiveStoryListExpiry(data.result.list[0].expiryTime));
              dispatch(getStories(0));
              dispatch(getStories(1));
            }
            if(!data.result.list.length) dispatch(setActiveStoryList('new_list'));
            dispatch(getStoryListSuccess(data.result.list));
            return;
          });
      });
  }
}

export function addNewStoryList(name, domainId, silent, expiry) {
  return (dispatch, getState) => {
    const { cms, login: { user: { activeDomain } } } = getState();
    return MeawwApi()
      .auth()
      .then(api => {
        const data = {
          name,
        }
        if (expiry) data.expiry = expiry;
        return api.post(`/stories/${domainId ? domainId: activeDomain}/list`, {name, expiry},
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }))
              return Promise.reject(error);
            }
            if (!silent) {
              dispatch(setAppMessage(data.result.message, { type: MessageTypes.SUCCESS }))
              dispatch(getStoryList());
            }
            return;
          });
      });
  }
}

export function updateStoryListExpiry(expiryTime) {
  return (dispatch, getState) => {
    const { cms:{ storyCms: { activeStoryList }}, login: { user: { activeDomain } } } = getState();
    return MeawwApi()
      .auth()
      .then(api => {
        return api.put(`/stories/${activeDomain}/list/${activeStoryList}`, { expiryTime },
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }))
              return Promise.reject(error);
            }
            dispatch(setAppMessage(data.result.message, { type: MessageTypes.SUCCESS }))
            dispatch(setActiveStoryListExpiry(expiryTime));
            return;
          });
      });
  }
}

function getStoriesFailure(day, stories) {
  return {
    type: StoryCmsAction.STORIES.FAILURE,
    day,
    stories:[],
  }
}

function getStoriesSuccess(day, stories) {
  return {
    type: StoryCmsAction.STORIES.SUCCESS,
    day,
    stories,
  }
}

export function getStories(day) {
  return (dispatch, getState) => {
    const { cms:{storyCms:{ activeStoryList }}, login: { user: { activeDomain } } } = getState();
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/stories/${activeDomain}/story/${day}/${activeStoryList}`,
          (error, data) => {
            if (error) {
              dispatch(getStoriesFailure(day));
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }))
              return Promise.reject(error);
            }
            dispatch(getStoriesSuccess(day, data.result.stories || []));
            return;
          });
      });
  }
}

function addNewStoryLoading(show) {
    return {
      type: StoryCmsAction.ADD_STORY.LOADING,
      show,
    }
};

export function addNewStory(content, croppedData, heading, linkAction, cb) {
  return (dispatch, getState) => {
    dispatch(addNewStoryLoading(true));
    const { cms:{storyCms:{activeStoryList}}, login: { user: { activeDomain } } } = getState();
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/stories/${activeDomain}/story/${activeStoryList}`,{ content, croppedData, heading, linkAction },
          (error, data) => {
            cb();
            if (error) {
              dispatch(addNewStoryLoading(false));
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            dispatch(getStories(0));
            dispatch(getStories(1));
            dispatch(addNewStoryLoading(false));
            dispatch(setAppMessage(data.result.message, { type: MessageTypes.SUCCESS }))
            return Promise.resolve(data.result);
          });
      });
  }
}

export function deleteStory(storyId) {
  return (dispatch, getState) => {
    const { cms:{ storyCms:{ activeStoryList }}, login: { user: { activeDomain } } } = getState();
    return MeawwApi()
      .auth()
      .then(api => {
        return api.delete(`/stories/${activeDomain}/story/${storyId}`,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }))
              return Promise.reject(error);
            }
            dispatch(getStories(0));
            dispatch(getStories(1));
            return Promise.resolve("Deleted");
          });
      });
  }
}

export function deleteStoryList(listId) {
  return (dispatch, getState) => {
    const { cms:{ storyCms: { activeStoryList }}, login: { user: { activeDomain } } } = getState();
    return MeawwApi()
      .auth()
      .then(api => {
        return api.delete(`/stories/${activeDomain}/list/${activeStoryList}`,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }))
              return Promise.reject(error);
            }
            dispatch(getStoryList());
            dispatch(setAppMessage(data.result.message, { type: MessageTypes.SUCCESS }))
            return;
          });
      });
  }
}
