import React, { PureComponent, Fragment } from 'react';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { getArticleDetailsFromArticleId } from "app-selector.js";
import AppLoader from '../../../app-components/app-loader';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import { connect } from "react-redux";
import throttle from "lodash/throttle";
import TextField from '@material-ui/core/TextField';

import ArticleTitle from "../components/article-title.jsx";
import ArticleSocialTitle from "../components/article-social-title.jsx";
import ArticleSeoTitle from "../components/article-seo-title-n.jsx";
import ArticleSummary from "../components/artilce-summary.jsx";
import ArticleCoverCaption from "../components/article-cover-caption.jsx";
import ArticleCategory from "../components/article-category.jsx";
import ArticleSubCategory from "../components/article-subCategory.jsx";
import ArticleMetaDataModal from "../components/article-meta-data-modal.jsx";
import DisclaimerModal from "../components/disclaimer-modal.jsx";

import {
  setArticleTitle,
  setArticleSummary,
  setArticleCoverCaption,
  setCategoryType,
  setMetaDataModal,
  addDisclaimer,
  setSEOTitle,
  setOgTitle,
  setStorySource,
  setPostCaption,
  setCustomUrl,
  setMetaTags,
  setMetaSubTags,
  setDisclaimerType,

  //tags
  fetchMetaTags,

  fetchDisclaimers,
  setDisclaimerModal,
  setYCategoryType,

} from "../new-story-actions.js";

class ArticleDetailsContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      subCategory: []
    }
  }
  setSubCategory = (subCategory) => {
    this.setState({ ...this.state, subCategory })
  }
  componentWillReceiveProps(props) {
    if (props.categories && props.categories.length > 0 && props.articleDetails.categoryId) {
      const X = props.categories.find((category) => category.id == props.articleDetails.categoryId)
      if (X && X.yCategory && (X.yCategory.length > 0)) this.setState({ ...this.state, subCategory: X.yCategory })
    }
  }
  render() {
    const {
      isLoadingArticleDetails,
      articleDetails,
      setArticleTitle,
      setArticleSummary,
      setArticleCoverCaption,
      setCategoryType,
      setPostCaption,
      setCustomUrl,
      setOgTitle,
      setStorySource,
      setSEOTitle,
      setMetaTags,
      setMetaSubTags,
      setDisclaimerType,



      //meta datas options
      categories,
      fetchMetaTags,

      //meta data modal
      showMetaDataModal,
      setMetaDataModal,
      setDisclaimerModal,

      //disclaimer
      addDisclaimer,
      disclaimers,
      isDisclaimerLoading,
      showDisclaimerModal,



      //preview
      setArticleIdForPreviewArticle,
      setYCategoryType,
      articleSettings,
    } = this.props;

    if (isLoadingArticleDetails || !(articleDetails && articleDetails.containsFullInfo)) {
      return <AppLoader />;
    }

    const {
      title,
      socialTitle,
      seoTitle,
      summary,
      ogTitle,
      coverCaption,
      categoryId,
      yCategoryId
    } = articleDetails;

    return (
      <Fragment>
        <ArticleCoverCaption
          summary={coverCaption}
          piscoSummary={this.props.coverCaption}
          setArticleCoverCaption={setArticleCoverCaption}
        />
        <GridContainer>
          <GridItem xs={9} sm={6} md={6}>
            <ArticleCategory setSubCategory={this.setSubCategory} categoryId={categoryId} setCategoryType={setCategoryType} categories={categories} categories={categories} />
          </GridItem>
          <GridItem xs={9} sm={6} md={6}>
            <ArticleSubCategory setYCategoryType={setYCategoryType} yCategoryId={yCategoryId} subCategories={this.state.subCategory} />
          </GridItem>
        </GridContainer>
        <ArticleSocialTitle
          title={ogTitle}
          setArticleSocialTitle={setOgTitle}
        />
        <ArticleSeoTitle
          title={seoTitle}
          setArticleTitle={setSEOTitle}
        />
        <ArticleTitle
          title={title}
          setTitle={setArticleTitle}
          setArticleTitle={setArticleTitle}
        />
        <ArticleSummary
          summary={summary}
          setArticleSummary={setArticleSummary}
        />
        <ArticleMetaDataModal
          showTableTable={this.props.showTableTable}
          tinyMCEEditor={this.props.tinyMCEEditor}
          showMetaDataModal={showMetaDataModal}
          setMetaDataModal={setMetaDataModal}
          setArticleTitle={setArticleTitle}
          articleDetails={articleDetails}
          setPostCaption={setPostCaption}
          setCustomUrl={setCustomUrl}
          setOgTitle={setOgTitle}
          setSEOTitle={setSEOTitle}
          setMetaTags={setMetaTags}
          setMetaSubTags={setMetaSubTags}
          addDisclaimer={addDisclaimer}
          fetchMetaTags={fetchMetaTags}
          setStorySource={setStorySource}

        />

        <DisclaimerModal
          showDisclaimerModal={showDisclaimerModal}
          setDisclaimerModal={setDisclaimerModal}
          disclaimers={disclaimers}
          isDisclaimerLoading={isDisclaimerLoading}
          setDisclaimerType={setDisclaimerType}
          articleDetails={articleDetails}
          setArticleIdForPreviewArticle={setArticleIdForPreviewArticle}
          articleSettings={articleSettings}

        />



      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const showTableTable = (((state.login||{}).user||{}).permissions || []).indexOf('SHOW_TABLE_CONTENT') > -1 ? true : false;
  const {
    articleSettings,
    newStory: {
      showMetaDataModal,
      disclaimers,
      isDisclaimerLoading,
      showDisclaimerModal,
      articleDetails: {
        coverCaption
      }
    }
  } = state.cms;
  const {
    articleId,
    isLoadingArticleDetails,
    categories
  } = articleSettings;

  const articleDetails = getArticleDetailsFromArticleId(state.cms, articleId);
  return {
    articleId: articleId,
    articleDetails: articleDetails,
    isLoadingArticleDetails: isLoadingArticleDetails,
    categories: categories,
    showMetaDataModal: showMetaDataModal,
    disclaimers: disclaimers,
    isDisclaimerLoading: isDisclaimerLoading,
    showDisclaimerModal: showDisclaimerModal,
    articleSettings: articleSettings,
    coverCaption,
    showTableTable
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setArticleTitle: (title, eventType) => dispatch(setArticleTitle(title, eventType)),
    setArticleSummary: (summary, eventType) => dispatch(setArticleSummary(summary, eventType)),
    setArticleCoverCaption: (cover_caption, eventType) => dispatch(setArticleCoverCaption(cover_caption, eventType)),
    setCategoryType: (categoryType) => dispatch(setCategoryType(categoryType)),
    setYCategoryType: (yCategoryId) => dispatch(setYCategoryType(yCategoryId)),
    //for meta modal
    setSEOTitle: (seoTitle, eventType) => dispatch(setSEOTitle(seoTitle, eventType)),
    setOgTitle: (seoTitle, eventType) => dispatch(setOgTitle(seoTitle, eventType)),
    setStorySource: (storySource) => dispatch(setStorySource(storySource)),
    setPostCaption: (postCaption, eventType) => dispatch(setPostCaption(postCaption, eventType)),
    setCustomUrl: (customUrl, eventType) => dispatch(setCustomUrl(customUrl, eventType)),
    setMetaTags: (metaTags, eventType) => dispatch(setMetaTags(metaTags, eventType)),
    setMetaSubTags: (metaSubTags, eventType) => dispatch(setMetaSubTags(metaSubTags, eventType)),
    setDisclaimerType: (disclaimerId, eventType) => dispatch(setDisclaimerType(disclaimerId, eventType)),

    setMetaDataModal: (visible) => dispatch(setMetaDataModal(visible)),

    //check all functions
    addDisclaimer: () => dispatch(addDisclaimer()),

    //get tags
    fetchMetaTags: () => dispatch(fetchMetaTags()),

    fetchDisclaimers: () => dispatch(fetchDisclaimers()),
    setDisclaimerModal: (visible) => dispatch(setDisclaimerModal(visible))

  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ArticleDetailsContainer);
