import React, { Fragment } from 'react';
import Avatar from '@material-ui/core/Avatar';
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from 'react-router-dom';
import { DEFULT_PROFILE_PICTURE_URL, DEFAULT_COVER_PICURE_URL } from "../../app-constants/image-url.js";
import SocialLinks from "./social-links.jsx";
import RoundButton from "../../components/RoundButton/roundbutton";
import profileDetailStyle from "../../assets/jss/material-dashboard-pro-react/views/profileDetailsViewStyle";


const ProfileDetailsView = (props) => {
  const { userDetails, classes } = props;
  let {
    description,
    fbProfile,
    twitterProfile,
    ownWebsite,
    phoneNumber,
    firstName,
    lastName,
    profilePicture,
    coverPicture,
  } = userDetails;
  if (!profilePicture) {
    profilePicture = DEFULT_PROFILE_PICTURE_URL;
  } 

  if (!coverPicture) {
    coverPicture = DEFAULT_COVER_PICURE_URL;
  }
  return (
    <Fragment>
      <Link to={"/user-settings/edit-profile"}>
        <RoundButton type='edit' location={{ float: 'right', background: "white" }} buttonbackground="white" iconcolor="#2b2d42" />
      </Link>
      <img className={classes.imagePadding} src={coverPicture} width="100%" />
      <Avatar className={classes.avator} src={profilePicture} />
      <h3 className={classes.userName}> {firstName} {lastName}</h3>
      <h4 className={classes.phoneNumber}>{phoneNumber}</h4>
      <p className={classes.description}><strong>About you: </strong>{description}</p>
      <h5 className={classes.socialLink} >{(fbProfile || twitterProfile || ownWebsite) && 'Follow'}</h5>
      <SocialLinks fbProfile={fbProfile} twitterProfile={twitterProfile} ownWebsite={ownWebsite} />
    </Fragment >
  )
}
export default withStyles(profileDetailStyle)(ProfileDetailsView);
