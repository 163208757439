import React, { Component } from "react";
import { connect } from "react-redux";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Hidden from '@material-ui/core/Hidden';
import getLandingPage, {
  setIdForMenuDropDown,
  removeIdForMenuDropDown,
  deleteCurrentPage,
  openLpModal,
  removeLpModal,
  setLpEntry,
  activeCategorie,
  openAddLpModal,
  closeAddLpModal,
  addNewLpEntry,
  COPYCARDID,
  reSetCropedUrl,
  resetAvator,
  activeSubCategorie
} from "../landing-page-action.js";
import loadArticleCategories from '../../../cms/article/article-action.js';
import LandingPageCard from '../components/landing-page-card.jsx';
import CategorySelect from '../components/category-selector.jsx';
import MenuDropDown from '../components/menu-dropdown.jsx';
import Search from '../components/search.jsx';
import ActiveStatus from '../components/activeStatue.jsx';
import LpDialog from '../components/lpmodal.jsx';
import AddLpDialog from '../components/add-landing-page.jsx';
import AppLoader from "../../../app-components/app-loader.jsx";
import landingPageStyle from '../../../assets/jss/material-dashboard-pro-react/views/landingPageStyle';
import { openPisco, closePisco } from '../landing-page-action.js';

class LandingPageContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryValue: '',
      anchorEl: null,
      searchText: ''
    }
  }
  componentDidMount() {
    this.props.loadArticleCategories();
    this.props.getLandingPage();
  }
  handleChange = (e) => {
    this.setState({ ...this.state, categoryValue: e.target.value.categoryVal });
  }
  setAnchorEl = (e) => {
    this.setState({ ...this.state, anchorEl: e });
  }
  setSearch = (search) => {
    this.setState({ ...this.state, searchText: search });
  }
  addModalToggle = () => {
    this.props.setLpType('ADD')
    this.props.reSetCropedUrl()
    this.props.openAddLpModal()
    this.props.resetAvator()
  }
  render() {
    const { categories = [],
      landingPageData = {},
      removeLpModal,
      lpModalId,
      cardId,
      setIdForMenuDropDown,
      deleteCurrentPage,
      removeIdForMenuDropDown,
      setLpEntry,
      activeCategorie,
      getLandingPage,
      categoryName,
      openLpModal,
      openAddLpModal,
      closeAddLpModal,
      isOpenAddLpModal,
      addNewLpEntry,
      categoryId,
      isLoading,
      openPisco,
      COPYCARDID,
      cropedUrl,
      reSetCropedUrl,
      resetAvator,
      activeSubCategorie,
      subCategoryId,
      subCategoryName,
      defaultSubCategory,
      classes
    } = this.props;
    return (
      <>

        <GridContainer style={{ padding: '20px 0' }}>
          <GridItem xs={6} sm={6}>
            <div style={{ display: "flex", alignItems: "center", cursor: "pointer", margin: "20px 0 20px", marginLeft: '4.5%' }}>
              <span style={{ display: "flex" }} onClick={this.addModalToggle} >
                <AddCircleIcon style={{color:"black"}} /> &nbsp;<span style={{ color: "#232323", paddingTop: '2px' }}>{`Add Landing Page`}</span>
              </span>
            </div>
          </GridItem>
          <GridItem xs={6} sm={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ display: 'flex', marginRight: '10%' }}>
              <ActiveStatus text='Active Page' color='#24c32a' />
              <ActiveStatus text='Inactive Page' color='#8d8d8d' />
            </div>
          </GridItem>
          <GridItem xs={12} sm={3} style={{marginBottom: '20px'}}>
            <Search setSearch={this.setSearch} />
          </GridItem>
        </GridContainer>
        {
          isLoading && <AppLoader
            message={`Loading ...`}
          />
        }
        <div className={classes.lpContainer}>
          {
            (!isLoading && Object.entries(landingPageData).length === 0) ? <h3 style={{width: '100%', textAlign: 'center'}}>No Page Found</h3>
              : Object.keys(landingPageData)
                .filter(index =>
                  `${landingPageData[index]['displayName']}${landingPageData[index]['description']}`
                    .toLowerCase()
                    .indexOf(this.state.searchText.toLowerCase()) >= 0)
                .map(val => <LandingPageCard
                    key={val}
                    searchText={this.state.searchText}
                    subCategoryName={subCategoryName}
                    categoryName={categoryName}
                    setIdForMenuDropDown={setIdForMenuDropDown}
                    setAnchorEl={this.setAnchorEl}
                    activeFqdn={this.props.activeDomainDetails.fqdn}
                    landingPage={landingPageData[val]} />
                )
          }
        </div>
        <MenuDropDown openLp={openLpModal} deleteCurrentPage={deleteCurrentPage} cardId={cardId} removeIdForMenuDropDown={removeIdForMenuDropDown} anchorEl={this.state.anchorEl} />
        <LpDialog
          reSetCropedUrl={reSetCropedUrl}
          cropedUrl={cropedUrl}
          COPYCARDID={COPYCARDID}
          openPisco={openPisco}
          categoryName={categoryName}
          setLpEntry={setLpEntry}
          categories={categories}
          lpModalId={lpModalId}
          lpData={landingPageData[lpModalId]}
          subCategoryId={subCategoryId}
          subCategoryName={subCategoryName}
          removeLpModal={removeLpModal} />
        <AddLpDialog
          reSetCropedUrl={reSetCropedUrl}
          cropedUrl={cropedUrl}
          COPYCARDID={COPYCARDID}
          openPisco={openPisco}
          categoryId={categoryId}
          addNewLpEntry={addNewLpEntry}
          categoryName={categoryName}
          isOpenAddLpModal={isOpenAddLpModal}
          closeAddLpModal={closeAddLpModal}
          subCategoryId={subCategoryId}
          subCategoryName={subCategoryName}
        />
      </>
    )
  }
}

const mapStateToProps = (store) => {
  const {
    landingPages: { landingPage: { isLoading, landingPageData },
      landingPageSetting: { cropedUrl, cardId, lpModalId, categoryName, subCategoryId, subCategoryName, categoryId, isOpenAddLpModal, defaultSubCategory = [] } } } = store.tool;
  const { articleSettings: { categories } } = store.cms;
  const {domainMap, activeDomain} = store.login.user;
  const activeDomainDetails = domainMap[activeDomain][0];
  return {
    categories,
    landingPageData,
    cardId,
    lpModalId,
    categoryName,
    categoryId,
    isOpenAddLpModal,
    isLoading,
    cropedUrl,
    subCategoryId,
    defaultSubCategory,
    subCategoryName,
    activeDomainDetails,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadArticleCategories: () => dispatch(loadArticleCategories()),
    openLpModal: () => dispatch(openLpModal()),
    setLpEntry: (formData, id) => dispatch(setLpEntry(formData, id)),
    removeLpModal: () => dispatch(removeLpModal()),
    deleteCurrentPage: () => dispatch(deleteCurrentPage()),
    setIdForMenuDropDown: (id) => dispatch(setIdForMenuDropDown(id)),
    removeIdForMenuDropDown: () => dispatch(removeIdForMenuDropDown()),
    getLandingPage: () => dispatch(getLandingPage()),
    activeSubCategorie: (categoryId, categoryName) => dispatch(activeSubCategorie(categoryId, categoryName)),
    activeCategorie: (categoryId, categoryName) => dispatch(activeCategorie(categoryId, categoryName)),
    openAddLpModal: () => dispatch(openAddLpModal()),
    closeAddLpModal: () => dispatch(closeAddLpModal()),
    addNewLpEntry: (formData) => dispatch(addNewLpEntry(formData)),
    COPYCARDID: () => dispatch(COPYCARDID()),
    reSetCropedUrl: () => dispatch(reSetCropedUrl()),
    resetAvator: () => dispatch(resetAvator())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(landingPageStyle)(LandingPageContainer));
