//pages
import React from 'react';
import WebsiteManagementHomePage from "./website-management-home-page.jsx";
import Apps from "../components/Icon/App.jsx";
import websitesvg from "../assets/icons/settings.svg";
import WebsiteManagement from "./views/website-management.jsx";
import TokenHome from "../scheduler/views/access-token-home.jsx";

import Disclaimers from "./disclaimers/disclaimers-home-page.jsx";
import Categories from "./categories/categories-home-page.jsx";
import GoogleAnalytics from "./google-analytics/google-analytics-home-page.jsx";
import Templates from './templates/templates-home-page.jsx';
import FaviconLogo from "./favicon-logo/favicon-logo-home-page.jsx";
import PagesHome from "./pages/pages-home";
import TinyMCE from "./pages/views/tinymce";
import MapDomain from './map-domain/map-domain.jsx';
import userManagementRoutes from "../user-management/user-management-routes";

var websiteManagementRoutes = [
  {
    path: "/settings/website",
    name: "Website Settings",
    mini: "WB",
    isRequiredAuth: true,
    component: WebsiteManagement,
    requiredPermissions: ["SETTING:WEBSITE_SETTINGS"],
    requiredFeaturePermision: 'WEBSITE_SETTINGS',
  },
  {
    path: "/settings/website/pages/:pageId",
    name: "Pages",
    mini: "P",
    isRequiredAuth: true,
    component: TinyMCE,
    requiredPermissions: ["CREATE:WRITE", "SETTING:WEBSITE_SETTINGS"],
    requiredFeaturePermision: 'WEBSITE_SETTINGS',
    dontShowInSideBar: true,
  },
  {
    path: "/settings/website/pages/:pageId/edit",
    name: "Pages",
    mini: "P",
    isRequiredAuth: true,
    component: TinyMCE,
    requiredPermissions: ["CREATE:WRITE", "SETTING:WEBSITE_SETTINGS"],
    requiredFeaturePermision: 'WEBSITE_SETTINGS',
    dontShowInSideBar: true,
  },
  // {
  //   path: "/settings/disclaimers",
  //   name: "Disclaimers",
  //   mini: "DS",
  //   isRequiredAuth: true,
  //   component: Disclaimers,
  //   requiredPermissions: ["WRITE"],
  //   requiredFeaturePermision: 'TRIAL',
  // },
  // {
  //   path: "/settings/categories",
  //   name: "Categories",
  //   mini: "CA",
  //   isRequiredAuth: true,
  //   component: Categories,
  //   requiredPermissions: ["WRITE"],
  //   requiredFeaturePermision: 'TRIAL',
  // },
  // {
  //   path: "/settings/google-analytics",
  //   name: "Google Analytics",
  //   mini: "GA",
  //   isRequiredAuth: true,
  //   component: GoogleAnalytics,
  //   requiredPermissions: ["WRITE"],
  //   requiredFeaturePermision: 'TRIAL',
  // },
  // {
  //   path: "/settings/facebook/update",
  //   name: "Update Facebook Token",
  //   mini: "UFB",
  //   isRequiredAuth: true,
  //   component: TokenHome,
  //   requiredPermissions: ["SCHEDULE"],
  //   requiredFeaturePermision: 'TRIAL',
  // },
  // {
  //   path: "/settings/favicon-logo",
  //   name: "Favicon and Logo",
  //   mini: "FL",
  //   isRequiredAuth: true,
  //   component: FaviconLogo,
  //   requiredPermissions: ["WRITE"],
  //   requiredFeaturePermision: 'TRIAL',
  // },
  // {
  //   path: "/settings/platform-integration",
  //   name: "Platform Integration",
  //   mini: "PI",
  //   component: PlatformIntegration,
  //   isRequiredAuth: true,
  //   requiredPermissions: ["WRITE"],
  //   requiredFeaturePermision: 'TRIAL',
  // },
  // {
  //   path: "/settings/map-domain",
  //   name: "Map Domain",
  //   mini: "MP",
  //   isRequiredAuth: true,
  //   component: MapDomain,
  //   requiredPermissions: ["WRITE"],
  //   requiredFeaturePermision: 'TRIAL',
  // }
];


export default websiteManagementRoutes;
