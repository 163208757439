import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper";
import AppLoader from "../../../app-components/app-loader.jsx";

const styles = theme => ({
  root: {
    padding: "28px 10px 37px",
    //boxShadow: "0 1px 8px 0 rgba(0, 0, 0, 0.06)",
    //borderRadius: "5px",
    border:'1px solid #ced3dc',
    marginBottom: "20px"
  },
  revenueHeader: {
    fontSize: "19px",
    fontWeight: "500",
    lineHeight: 1.21,
    color: "#2b2d42",
    fontFamily:"Source Sans Pro, sans-serif",
    textAlign: "center"
  },
  revenueValue: {
    marginTop: "20px",
    fontSize: "32px",
    fontFamily:"Source Sans Pro, sans-serif",
    fontWeight: "bold",
    lineHeight: 1.19,
    color: "#ced3dc",
    textAlign: "center"
  }
});
const CalculateData = ({ isLoadingRevenues, data = {} }) => {
  if (isLoadingRevenues) {
    return (<AppLoader isMarginZero />);
  } else if (!isLoadingRevenues && data && Object.keys(data).length === 0) {
    return <span style={{ fontSize: "16px" }}>No Data Found</span>
  } else {
    const { unit, count } = data;
    let formattedCountValue = Number(count);
    if (!Number.isInteger(formattedCountValue)) {
      formattedCountValue = formattedCountValue.toFixed(2);
    }
    if (formattedCountValue === 0) {
      formattedCountValue = "N/A"
    }
    return `${unit} ${formattedCountValue}`
  }
}
const RevenuePaper = (props) => {
  const { classes, header = "" } = props;
  return (
    <div className={classes.root} >
      <Typography className={classes.revenueHeader} variant="title" component="h3">
        {header}
      </Typography>
      <Typography className={classes.revenueValue} variant="title" component="h3">
        <CalculateData {...props} />
      </Typography>
    </div>
  )
};

RevenuePaper.propTypes = {
  classes: PropTypes.object.isRequired,
  header: PropTypes.string.isRequired,
  // count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // unit: PropTypes.string.isRequired
};

export default withStyles(styles)(RevenuePaper);
