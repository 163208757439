import React, { PureComponent } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from '@material-ui/core/Hidden';
import MenuList from "../components/menu-list.jsx";
import BeingReviewed from "./being-edited.jsx";
import moment from 'moment';
import RoundIcon from "../../../components/RoundIcon/roundicon.jsx";
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import articlesStyle from "../../../assets/jss/material-dashboard-pro-react/views/articlesStyle.jsx";

function ArticleRender(props) {
  const { article, articleLetter, articleId, setAnchorElForMenu, setArticleIdForMenuList, options, classes } = props.articleDetail;
  const { title, scheduleStatus, reason, scheduleTime, tag, submitDate, liveDate, ogTitle, seoTitle, exploitDatetime, exploitStatusCode } = article;
  return (
    <GridContainer key={articleId} justify="center" className={classes.articleContainer}>
      <GridItem xs={12} sm={4} md={3} key={articleId + 3} className={classes.articleImgContainer} >
        <div className={classes.articleLetterWrapper} style={props.isMobile ? { margin: '0' } : {}}>
          <img src={article.coverPicture ? article.coverPicture + '_450_250' + article.imgExtension : "https://cheesecake.articleassets.meaww.com/assets/new_article_thumbnail_450x250.png"} width="100%" height="100%" />
        </div>
      </GridItem>
      {
        props.isTablet ? <GridItem sm={7} md={6} key={articleId}>
          <h5 className={classes.articleTitle} style={{ fontSize: "0.9em" }}>
            <p style={{ fontSize: "0.8em", padding: "0px", margin: "0px", color: '##b7b7b7' }}>Website Headline: {title || ''}</p>
            <p style={{ fontSize: "0.8em", padding: "0px", margin: "0px", color: '##b7b7b7' }}>Facebook Headline: {ogTitle || title}</p>
            <p style={{ fontSize: "0.8em", padding: "0px", margin: "0px", color: '##b7b7b7' }}>Google Headline: {seoTitle || title}</p>
            <span style={{ fontSize: "0.8em", padding: "5px", color: '#5d737e' }}> - by {`${article.author.firstname} ${article.author.lastname}`}</span>
            <p>{scheduleStatus == -1 && reason}</p>
            <p>{scheduleStatus == 0 && scheduleTime && `Article Scheduled : ${moment(scheduleTime).format('LLL')}`}</p>
            <p style={{ fontSize: "0.8em", padding: "0px", margin: "0px", color: '##b7b7b7' }}>Submitted Date: {moment(submitDate).format('LLL')}</p>
            <p style={{ fontSize: "0.8em", padding: "0px", margin: "0px", color: '##b7b7b7' }}>Published Date: {moment(liveDate).format('LLL')}</p>
            {
              exploitDatetime && (exploitStatusCode === null || exploitStatusCode !== 200) ? (
                <p style={{
                  fontSize: "0.8em",
                  padding: "0px",
                  margin: "0px",
                  color: exploitStatusCode === 200 ? '#28A745' : '#007BFF' // Green for published (200), blue for scheduled/other
                }}>
                  Exploit Scheduled: {
                    new Date(exploitDatetime).toLocaleString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric',
                      hour12: true,
                    })
                  }
                </p>
              ) : <span></span>
            }
          </h5>
          {article.inReviewBy &&
            <BeingReviewed firstName={article.inReviewBy.firstname} lastName={article.inReviewBy.lastname} profilePicture={article.inReviewBy.profilePictureUrl} />
          }
        </GridItem>
          : (props.isMobile ? <><GridItem xs={11} sm={7} md={6} key={articleId}>
            <h5 className={classes.articleTitle} style={{ fontSize: "0.9em" }}>
              <p style={{ fontSize: "0.8em", padding: "0px", margin: "0px", color: '##b7b7b7' }}>Website Headline: {title || ''}</p>
              <p style={{ fontSize: "0.8em", padding: "0px", margin: "0px", color: '##b7b7b7' }}>Facebook Headline: {ogTitle || title}</p>
              <p style={{ fontSize: "0.8em", padding: "0px", margin: "0px", color: '##b7b7b7' }}>Google Headline: {seoTitle || title}</p>
              <span style={{ fontSize: "0.8em", padding: "5px", color: '#5d737e' }}> - by {`${article.author.firstname} ${article.author.lastname}`}</span>
              <p>{scheduleStatus == -1 && reason}</p>
              <p>{scheduleStatus == 0 && scheduleTime && `Article Scheduled : ${moment(scheduleTime).format('LLL')}`}</p>
              <p style={{ fontSize: "0.8em", padding: "0px", margin: "0px", color: '##b7b7b7' }}>Submitted Date: {moment(submitDate).format('LLL')}</p>
              <p style={{ fontSize: "0.8em", padding: "0px", margin: "0px", color: '##b7b7b7' }}>Published Date: {moment(liveDate).format('LLL')}</p>
              {
              exploitDatetime && (exploitStatusCode === null || exploitStatusCode !== 200) ? (
                <p style={{
                  fontSize: "0.8em",
                  padding: "0px",
                  margin: "0px",
                  color: exploitStatusCode === 200 ? '#28A745' : '#007BFF' // Green for published (200), blue for scheduled/other
                }}>
                  Exploit Scheduled: {
                    new Date(exploitDatetime).toLocaleString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric',
                      hour12: true,
                    })
                  }
                </p>
              ) : <span></span>
            }
            </h5>
          </GridItem>
          </> :
            <GridItem xs={8} sm={7} md={6} key={articleId}>
              <h5 className={classes.articleTitle}>
                <p style={{ fontSize: "0.8em", padding: "0px", margin: "0px", color: '##b7b7b7' }}>Website Headline: {title || ''}</p>
                <p style={{ fontSize: "0.8em", padding: "0px", margin: "0px", color: '##b7b7b7' }}>Facebook Headline: {ogTitle || title}</p>
                <p style={{ fontSize: "0.8em", padding: "0px", margin: "0px", color: '##b7b7b7' }}>Google Headline: {seoTitle || title}</p>
                <span style={{ fontSize: "0.8em", padding: "5px", color: '#5d737e' }}> - by {`${article.author.firstname} ${article.author.lastname}`}</span>
                <p>{scheduleStatus == -1 && reason}</p>
                <p>{scheduleStatus == 0 && scheduleTime && `Article Scheduled : ${moment(scheduleTime).format('LLL')}`}</p>
                <p style={{ fontSize: "0.8em", padding: "0px", margin: "0px", color: '##b7b7b7' }}>Submitted Date: {moment(submitDate).format('LLL')}</p>
                <p style={{ fontSize: "0.8em", padding: "0px", margin: "0px", color: '##b7b7b7' }}>Published Date: {moment(liveDate).format('LLL')}</p>
                {
              exploitDatetime && (exploitStatusCode === null || exploitStatusCode !== 200) ? (
                <p style={{
                  fontSize: "0.8em",
                  padding: "0px",
                  margin: "0px",
                  color: exploitStatusCode === 200 ? '#28A745' : '#007BFF' // Green for published (200), blue for scheduled/other
                }}>
                  Exploit Scheduled: {
                    new Date(exploitDatetime).toLocaleString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric',
                      hour12: true,
                    })
                  }
                </p>
              ) : <span></span>
            }
              </h5>
            </GridItem>)
      }
      {props.isMobile &&
        <GridItem xs={1} sm={1} md={1} className={classes.articleEnd + " " + classes.buttonMobile} key={articleId + 2}>
          <MenuList setAnchorElForMenu={setAnchorElForMenu} options={options} articleId={articleId} setArticleIdForMenuList={setArticleIdForMenuList} />
        </GridItem>
      }
      {!props.isTablet && <GridItem className={classes.articleLetter} md={2} key={articleId + 1}>
        {article.inReviewBy &&
          <BeingReviewed firstName={article.inReviewBy.firstname} lastName={article.inReviewBy.lastname} profilePicture={article.inReviewBy.profilePictureUrl} />
        }
      </GridItem>}
      {!props.isMobile &&
        <GridItem xs={1} sm={1} md={1} className={classes.articleEnd + " " + classes.buttonMobile} key={articleId + 2}>
          <MenuList setAnchorElForMenu={setAnchorElForMenu} options={options} articleId={articleId} setArticleIdForMenuList={setArticleIdForMenuList} />
        </GridItem>
      }
    </GridContainer>
  )
}

class ArticleContainer extends PureComponent {
  render() {
    // rendering for mobile
    if (isWidthDown('xs', this.props.width)) {
      return <ArticleRender articleDetail={this.props} isTablet={false} isMobile={true} />;
    }
    // rendering for tablet
    if (isWidthDown('md', this.props.width) && isWidthUp('xs', this.props.width)) {
      return <ArticleRender articleDetail={this.props} isTablet={true} isMobile={false} />;
    }
    // other devices
    return <ArticleRender articleDetail={this.props} isTablet={false} isMobile={false} />;
  }
}
export default withStyles(articlesStyle)(withWidth()(ArticleContainer));
