import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.jsx';
import { getPost, getPostNew, deletePost, shareDebugPost, reschedulePost, hasReceivedNewScheduleData, changeSortMethod } from "../../schedule-action.js";
import { connect } from 'react-redux';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Assignment from "@material-ui/icons/Assignment";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Modal from "components/Modal/modal.jsx";
import PostOnPage from '../component/schedule';
import moment from "moment";
import DateTime from './time.jsx';
import Button from "components/CustomButtons/Button.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import {setAppMessage} from '../../../app-actions.js';
import { MessageTypes } from "../../../app-constants/index.js";

import PostsTable from './posts-table.jsx';
import LoaderModal from './loader-modal.jsx';
import AppLoader from "../../../app-components/app-loader.jsx";

class ListPost extends React.Component {
  constructor(props) {
    super(props);
    this.getPageData = this.getPageData.bind(this);
    this.getDeleteComponent = this.getDeleteComponent.bind(this);
    this.callDeleteApi = this.callDeleteApi.bind(this);
    this.getTimeCoponent = this.getTimeCoponent.bind(this);
    this.onUpdateFieldChange = this.onUpdateFieldChange.bind(this);
    this.getUpdateModal = this.getUpdateModal.bind(this);
    this.callInvalidateApi = this.callInvalidateApi.bind(this);
    this.invalidateData = this.invalidateData.bind(this);
    this.callReScheduleApi = this.callReScheduleApi.bind(this);
    this.state = {
      disableButtons: false,
      tableData: [],
      newTableData: [],
      testData: [],
      time: '',
      message: '',
      reschedule: {
        link: '',
        time: '',
        postId: 0,
      },
      imgSrcObj: {},
      a: '',
      sortArr: [
        'Time',
        'Title',
        'Caption',
        'Page Name'
      ],
      sortOrder: '(descending)',
      dropDownMessage: 'Time(descending)'
    };
  }
  componentDidMount() {
    this.getPageData();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.scheduleDataUpdated === true) {
      this.getPageData(this.props.sortMethod, false);
    }
  }
  callDeleteApi(post, type) {
    const { post_id, page_name, page_id } = post
    this.props.deletePost({ postId: post_id, pageName: page_name, pageId: +page_id }).then(data => {
      this.getPageData(this.props.sortMethod, false);
    }, _ => {
      if (_.message) {
        // incompete error handling
        // var t = <Modal bodyText={_.message} onCancelClick={() => { }} onSuccessClick={() => { }} />;
        // var Modal = React.createElement("Modal", { bodyText: _.message, onCancelClick: () => { }, onSuccessClick: () => { } });
      }
    });
  }
  callInvalidateApi(postId, link, pageName, pageId) {
    this.props.shareDebugPost({ postId, link, pageName, pageId }).then(data => {
      this.getPageData(this.props.sortMethod, false);
    }, _ => {
      
    });
  }
  callReScheduleApi() {
    moment(this.state.reschedule.time).utc().unix();
    this.props.reschedulePost(this.state.reschedule).then(data => {
      this.getPageData(this.props.sortMethod, false);
    }, _ => {
      //Handle Error
    });
  }
  onUpdateFieldChange(postId, link, pageName, pageId, time) {
    let reschedule = this.state.reschedule;
    reschedule.postId = postId;
    reschedule.link = link;
    reschedule.pageName = pageName;
    reschedule.pageId = pageId;
    reschedule.time = moment(time).utc().unix();
    this.setState({ reschedule: { ...reschedule } });
  }
  getUpdateModal(postId, link, pageName, pageId) {
    return <Modal bodyText={this.getTimeCoponent(postId, link, pageName, pageId)} modalTitle='Reschedule' buttonTitle='Re-Schedule' onCancelClick={() => { }} onSuccessClick={this.callReScheduleApi} />;
  }
  invalidateData(postId, link, pageName, pageId) {
    return <Button style={{width: '150px'}} color="primary" onClick={this.callInvalidateApi.bind(null, postId, link, pageName, pageId)}>Share Debug</Button>
  }
  getTimeCoponent(postId, link, pageName, pageId) {
    return <DateTime time={this.onUpdateFieldChange.bind(null, postId, link, pageName, pageId)} setAppMessage={this.props.setAppMessage}/>
  }
  getDeleteComponent(post, type) {
    const d = <p>Delete Post from Page</p>;
    return <Modal bodyText={d} modalTitle='Delete' buttonTitle='Delete' onCancelClick={() => { }} onSuccessClick={this.callDeleteApi.bind(null, post, 'Delete')} />;
  }

  getPageData(sortMethod = this.props.sortMethod, updateSortOrder = true) {
    // let sortOrder = this.state.sortOrder;
    // if( updateSortOrder ) {
    //   if (sortMethod === this.props.sortMethod)
    //     sortOrder = this.state.sortOrder === '(ascending)' ? '(descending)' : '(ascending)';
    //   else sortOrder = this.state.sortOrder;
    //   let dropDownMessage = `${sortMethod}${sortOrder}`;
    //   this.props.changeSortMethod(sortMethod);
    //   this.setState({ ...this.state, dropDownMessage, sortOrder: sortOrder });
    // }

    this.props.getPost().then(response => {
      //let data = response['The Ded Blood Cells'].data;
      let formattedData = [];
      Object.keys(response).map((tableName) => {
        //console.log(`table`, response[tableName]);
        if (response[tableName].data) {
          formattedData.push({
            [tableName]: this.makeTable(response[tableName].data),
            paging: response[tableName].paging
          });
        } else if (response[tableName].error && response[tableName].error.message) {
          // Please notify this error(( message to the user.
          this.props.setAppMessage(response[tableName].error.message, { type: MessageTypes.ERROR });
        }
      });
      //console.log(`formated`, formattedData);
      //console.log(`data`, data);
      // switch (sortMethod) {
      //   case 'Time': {
      //     if (sortOrder === '(ascending)')
      //       data = data.sort((a, b) => (a.time > b.time) ? 1 : -1);
      //     else
      //       data = data.sort((a, b) => (a.time < b.time) ? 1 : -1);
      //     break;
      //   }
      //   case 'Title': {
      //     if (sortOrder === '(ascending)')
      //       data = data.sort((a, b) => (a.title > b.title) ? 1 : -1);
      //     else
      //       data = data.sort((a, b) => (a.title < b.title) ? 1 : -1);
      //     break;
      //   }
      //   case 'Caption': {
      //     if (sortOrder === '(ascending)')
      //       data = data.sort((a, b) => (a.post_caption > b.post_caption) ? 1 : -1);
      //     else
      //       data = data.sort((a, b) => (a.post_caption < b.post_caption) ? 1 : -1);
      //     break;
      //   }
      //   case 'Page Name': {
      //     if (sortOrder === '(ascending)')
      //       data = data.sort((a, b) => (a.page_name > b.page_name) ? 1 : -1);
      //     else
      //       data = data.sort((a, b) => (a.page_name < b.page_name) ? 1 : -1);
      //     break;
      //   }
      //   default: {
      //     if (sortOrder === '(ascending)')
      //       data = data.sort((a, b) => (a.time > b.time) ? 1 : -1);
      //     else
      //       data = data.sort((a, b) => (a.time < b.time) ? 1 : -1);
      //     break;
      //   }
      // }
      //let formateData = this.makeTable(data);
      this.setState({ newTableData: formattedData });
    }, _ => {
      // Show error Message to the user, I will be adding it later
    });
    this.props.hasReceivedNewScheduleData();
  }

  changeSortMethod = (sortMethod) => {
    this.getPageData(sortMethod);
  }

  makeTable = (data) => {
    return data.map(obj => {
      let imgLinkTag = '';
      if (obj.post_type === 'image' && obj.multiple) {
        imgLinkTag = <img src={obj.asset[0].url} width="100%" />;
      } else if (obj.post_type === 'image' && obj.multiple === 0) {
        imgLinkTag = <img src={obj.link} width="100%" />;
      } else if (obj.post_type === 'video') {
        let vidSrc;
        try {
          vidSrc = obj.asset[0].url;
        } catch (err) {
          vidSrc = '';
        }
        imgLinkTag = <video width="125" height="70" controls>
          <source src={vidSrc} type="video/mp4" />
          Your browser does not support the video tag.
          </video>
      }
      else {
        imgLinkTag = obj.metadata && obj.metadata.image && obj.metadata.image.url && obj.metadata.image.url ? <img src={obj.metadata.image.url} width="100%" /> : '';
      }

      const dateTime = moment.unix(obj.time).local().format("MMM DD, YYYY h:mmA");
      return [
        <GridContainer justify="flex-start">
          <GridItem xs={3} sm={3} md={3}>
            {imgLinkTag}
          </GridItem>
          <GridItem xs={9} sm={9} md={9}>
            {obj.title}
          </GridItem>
        </GridContainer>,
        obj.post_caption,
        dateTime,
        <CustomDropdown
          buttonText="Select"
          dropdownList={[
            this.getDeleteComponent(obj, 'delete'),
            this.getUpdateModal(obj.post_id, obj.link, obj.page_name, obj.page_id),
            this.invalidateData(obj.post_id, obj.link, obj.page_name, obj.page_id),
          ]}
        />
      ];
    });
  }

  newPage = (tableName, direction, key) => {
    //console.log(`req`, tableName, direction, key);
    this.setState({disableButtons: true});
    this.props.getPostNew(tableName, direction, key).then(response => {
      let formattedData = [];
      Object.keys(response).map((tableName) => {
        //console.log(`table`, response[tableName]);
        formattedData.push({
          [tableName]: this.makeTable(response[tableName].data),
          paging: response[tableName].paging
        });
      });
      let oldformattedData = this.state.newTableData;
      let newformattedData = [];
      let newPageName = Object.keys(formattedData[0])[0];
      let newPageData = formattedData[0];
      //console.log(`formated`, oldformattedData, formattedData);

      for(let page of oldformattedData){
        if(Object.keys(page)[0] == newPageName){
          //console.log(`yes`, newPageName);
          newformattedData.push(newPageData);
        }
        else{
          newformattedData.push(page);
        }
      }
      this.setState({ newTableData: newformattedData, disableButtons: false });
    }, _ => {
      // Show error Message to the user, I will be adding it later
    });
  }

  render() {
    if(this.props.isLoadingFacebook) {
      return <></>
    }
    const { classes } = this.props;
    const { newTableData, disableButtons } = this.state;
    return (
      <>
      <LoaderModal
        showModal={disableButtons}
        showLoader={disableButtons}
        showCloseBtn={false}
      />
        {
          newTableData && newTableData.map((table, index) => {
            return(
              <PostsTable
                key={index}
                table={table}
                newPage={this.newPage}
                disableButtons={disableButtons}
              />
            )
          })
        }
      </>
    );
  }
}

const mapStateToProps = (store) => {
  let { scheduleReducers: { scheduleReducer: { scheduleDataUpdated, sortMethod, sortOrder } } } = store;
  return { scheduleDataUpdated, sortMethod, sortOrder };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAppMessage: (message, type) => dispatch(setAppMessage(message, type)),
    getPost: () => dispatch(getPost()),
    getPostNew: (pageName, direction, key) => dispatch(getPostNew(pageName, direction, key)),
    deletePost: (data) => dispatch(deletePost(data)),
    shareDebugPost: (data) => dispatch(shareDebugPost(data)),
    reschedulePost: (data) => dispatch(reschedulePost(data)),
    hasReceivedNewScheduleData: () => dispatch(hasReceivedNewScheduleData()),
    changeSortMethod: (sortMethod) => dispatch(changeSortMethod(sortMethod)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListPost);
