import React, { PureComponent } from 'react';
import IconButton from '@material-ui/core/IconButton';
import withStyles from "@material-ui/core/styles/withStyles";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import articlesStyle from "../../../assets/jss/material-dashboard-pro-react/views/articlesStyle.jsx";


class MenuList extends PureComponent {
  setArticleIdForMenu = (articleId, e) => {
    e.preventDefault();
    this.props.setAnchorElForMenu(e.currentTarget);
    this.props.setArticleIdForMenuList(articleId)
  }
  render() {

    const { classes, articleId } = this.props;
    const open = Boolean(articleId);
    return (
      <div className={classes.articleCenter}>
        <IconButton
          aria-label="More"
          aria-owns={open ? 'long-menu' : undefined}
          aria-haspopup="true"
          onClick={(e) => { this.setArticleIdForMenu(articleId, e) }}
        >
          <MoreVertIcon />
        </IconButton>
      </div >
    )
  }
}

export default withStyles(articlesStyle)(MenuList);
