import React from 'react';
import ReactTable from "react-table";
import moment from "moment";

class TrafficTable extends React.Component {

  add(data, prop) {
    var total = 0
    for (var i = 0, _len = data.length; i < _len; i++) {
      total += data[i][prop]
    }
    return total
  }

  calTableTotal = (data) => {
    const traffic = (this.add(data, "traffic")).toFixed(2)
    return { traffic }

  }

  render() {
    const { data } = this.props;
    let { traffic } = data.length > 0 && this.calTableTotal(data);

    return (
      <>
        <ReactTable
          data={data}
          noDataText="No Data"
          defaultPageSize={10}
          pageSizeOptions={[5, 10, 25, 50]}
          columns={[
            {
              Header: "Date",
              headerStyle: { textAlign: "left", fontWeight: "bold" },
              accessor: 'date'
            },
            {
              Header: "Users",
              headerStyle: { textAlign: "left", fontWeight: "bold" },
              accessor: 'traffic',
              Footer: (
                traffic && <span>
                  <strong>Total:</strong>{" "}
                  {traffic}
                </span>
              )
            }
          ]}
        />
      </>
    )
  }
}

export default TrafficTable;
