import React, { PureComponent } from "react";
import DltWebsiteView from "./view/dlt-website-view";

class DltWebsite extends PureComponent {
  render() {
    return ( <DltWebsiteView/>)
  }
}

export default DltWebsite;
