import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button";
import { withStyles, Typography, IconButton } from "@material-ui/core";
import ConfirmationBox from "./confirmation-box";
/**
 *
 * @param {*} component
 * @param {*} data
 */
export default function withConfirmation(MainComponent,data) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        showModal: false,
      }
    }

    toggleModal = () => {
      this.setState((prev) => {
        return { showModal: !prev.showModal }
      })
    }
    onConfirm = () => {
      this.toggleModal();
      const { onClick } = this.props;
      if (onClick) onClick();
    }
    render() {
      const onClickObj = { onClick: this.toggleModal };
      const newProps = {
        onClick : this.toggleModal,
      };
      return (
        <>
          <ConfirmationBox
            confirmationMessage={ (this.props && this.props.msg) || 'Are you sure to proceed ?'}
            onConfirm={this.onConfirm}
            showConfirmationModal={this.state.showModal}
            toggleConfirmationBox={this.toggleModal}
            removeArticleIdMenuList={false}
            />
          <MainComponent {...this.props} {...newProps} />
        </>
      )
    }
  }
}

