import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from "@material-ui/core/Button";
import RecordAudio from "./record-audio";

class AudioModal extends React.Component {

  render() {
    const { showAudioModal, closeAudioModal } = this.props;
    return (
      <Dialog
        open={showAudioModal}
        fullWidth
        size="sm"
        style={{
          maxWidth: '100%',
          backgroundColor: 'transparent',
          overflow: 'visible',
          margin: 'auto'
        }}>
        <DialogTitle >
          Audio Recorder
        </DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <RecordAudio handleFileUpload={this.props.handleFileUpload} closeAudioModal={closeAudioModal} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAudioModal}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default AudioModal;
