import React from "react";
import { connect } from "react-redux";
import CreatePost from "../components/create-post";
import withFileUpload from "../components/with-file-upload";
//import Button from "@material-ui/core/Button";
import Button from "components/CustomButtons/Button.jsx";
import IconButton from "@material-ui/core/IconButton"
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Divider from '@material-ui/core/Divider';
import ImageIcon from "@material-ui/icons/CameraAlt";
import VideoIcon from "@material-ui/icons/VideoCall";
import AudioIcon from "@material-ui/icons/Audiotrack";
import FeedFilter from "../components/filter.jsx";
import CreateIcon from "@material-ui/icons/LibraryAdd";
import AddPostForm from "../components/add-post-form";
import AddArticleForm from "../components/add-article-form";
import { Link, withRouter } from 'react-router-dom';
import { LocalStorageKeys } from "../../../app-constants/index.js";
import {
  saveMediaToPisco,
  getCategories,
  AddNewPost,
  EditPost,
  InvalidFileError,
  resetBothFilter,
  updateArticleContent,
  fetchArticleDetails,
  changeCoverPicture,
  resetFeed,
  getFeed,
  getVideoThumbnail,
  getBlurImage
} from "../quick-cms-actions.js";
import { setArticleId } from "../../article/article-action";
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import AudioModal from "../components/audio-modal";
import AudioModalConfirm from "../components/audio-modal-confirm";


const FileUploaderImage = withFileUpload(Button);
const FileUploaderVideo = withFileUpload(Button);
const FileUploaderAudio = withFileUpload(Button);


class PostsView extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    showPostForm: false,
    showArticleForm: false,
    imageData: null,
    file: null,
    showAudioModal: false,
    AudioRecordModal: false,
    audioType: '',
  }

  componentDidMount() {
    this.props.getCategories();
  }
  handleFileUpload = (target, type) => {
    const file = target && target.files[0];
    if (type == 'audio/*') {
      this.setState({ showAudioModal: false })
      if (!file.type) {
        file.type = file.type || 'audio/wav'
      }
    }
    const uploadType = type && type.split("/")[0];
    const extension = file && file.type.split("/")[1];
    if (file && type && !(file.type.indexOf(uploadType) > -1)) {
      this.props.InvalidFileError();
      return;
    }
    if (uploadType == 'image' && (extension == 'webp' || extension =='svg+xml' || extension == 'gif') ) {
      this.props.InvalidFileError(`${extension} images are not allowed.`);
      return;
    }
    if (file && file.name) {
      const fr = new FileReader();
      fr.onload = (stream) => {

        this.setState({ file, imageData: stream.target.result });
        this.togglePostForm();
      }
      fr.readAsDataURL(file);
    }
  }

  createNewStoryArticle = () => {
    //this.props.setArticleId(undefined);
    localStorage.setItem(LocalStorageKeys.SELECTED_TAB, 0);
    this.toggleArticleForm();
    //this.props.history.push("/cms/articles");
  }

  togglePostForm = () => {
    this.setState((prev) => {
      return {
        showPostForm: !prev.showPostForm,
      }
    })
  }
  toggleArticleForm = () => {
    this.setState((prev) => {
      return {
        showArticleForm: !prev.showArticleForm,
      }
    })
  }
  resetFilter = () => {
    this.props.resetBothFilter();
  }
  closeAudioModal = () => {
    this.setState({ showAudioModal: false })
  }
  showAudioModal = () => {
    this.setState({ showAudioModal: true })
  }
  setAudioChooseType = (val) => {
    if (val == 'fromrecording') {
      this.setState({ audioType: val, AudioRecordModal: true })
      return;
    }
    this.setState({ audioType: val })
  }
  closeAudioRecModal = () => {
    this.setState({ AudioRecordModal: false })
  }
  render() {
    const { domainMap, activeDomain ,getVideoThumbnail,getBlurImage} = this.props;
    const subscriptionEnabled = domainMap && activeDomain && domainMap[activeDomain] && domainMap[activeDomain][0] && domainMap[activeDomain][0]['subscription_enabled'];
    return (
      <>
        {this.state.showPostForm && <AddPostForm
          savingToPisco={this.props.savingToPisco}
          saveMediaToPisco={this.props.saveMediaToPisco}
          creatingContent={this.props.creatingContent}
          file={this.state.file}
          onClose={this.togglePostForm}
          showPostForm={true}
          categories={this.props.categories}
          postHandler={this.props.AddNewPost}
          mode="create"
          imageData={this.state.imageData}
          getVideoThumbnail={getVideoThumbnail}
          resetFilter={this.resetFilter}
          subscriptionEnabled={subscriptionEnabled}
          user={this.props.user}
          getBlurImage = {getBlurImage}
        />}
        {this.state.showArticleForm && <AddArticleForm
          resetFeed={this.props.resetFeed}
          savingToPisco={this.props.savingToPisco}
          saveMediaToPisco={this.props.saveMediaToPisco}
          fetchArticleDetails={this.props.fetchArticleDetails}
          updateArticleContent={this.props.updateArticleContent}
          creatingContent={this.props.creatingContent}
          onClose={this.toggleArticleForm}
          changeCoverPicture={this.props.changeCoverPicture}
          showArticleForm={true}
          getFeed={this.props.getFeed}
          categories={this.props.categories}
          addNewPost={this.props.AddNewPost}
          updatePost={this.props.EditPost}
          mode="create"
          resetFilter={this.resetFilter}
          subscriptionEnabled={subscriptionEnabled}
          user={this.props.user}
        />}
        <GridContainer>
          <GridItem id="createworkspaceaddphoto" lg={3} sm={4} xs={6} style={{ padding: '0 2px' }}>
            <FileUploaderImage fullWidth type="image/*" color="primary" onChange={this.handleFileUpload}>
                  <ImageIcon size="tiny" /> Add Image
             </FileUploaderImage>
          </GridItem>

          <GridItem id="createworkspaceaddvideo" lg={3} sm={4} xs={6} style={{ padding: '0 2px' }}>
            <FileUploaderVideo fullWidth type="video/*" color="primary" onChange={this.handleFileUpload}>
              <VideoIcon size="tiny" /> Add Video
             </FileUploaderVideo>
          </GridItem>
          <GridItem id="createworkspaceaddaudio" lg={3} sm={4} xs={6} style={{ padding: '0 2px' }}>
            {window.navigator.userAgent.indexOf('iPad') > -1 || window.navigator.userAgent.indexOf('iPhone')> -1 ?
              <FileUploaderAudio fullWidth type="audio/*" color="primary" accept="audio/*" onChange={this.handleFileUpload} capture='microphone'>
              <AudioIcon size="tiny" />  Add Audio
             </FileUploaderAudio>
            :
            <Button fullWidth onClick={this.showAudioModal} color="primary">
              <AudioIcon size="tiny" /> Add Audio
            </Button>
            }

          </GridItem>

          <GridItem id="createworkspaceaddarticle" lg={3} sm={4} xs={6} style={{ padding: '0 2px' }}>
            <Button fullWidth onClick={this.createNewStoryArticle} color="primary">
              <CreateIcon size="tiny" /> Add Article
             </Button>
          </GridItem>
          {/* <GridItem md={4} xs={4} style={{padding: '0 2px'}}>
              <FileUploaderImage fullWidth type="image/*" variant="contained" style={{backgroundColor:"#fb4d24", color:"white", fontSize:'10px'}} onChange={this.handleFileUpload}>
              <ImageIcon size="tiny"/> Add Photo
              </FileUploaderImage>
            </GridItem> */}
          {/* <GridItem md={4} xs={4} style={{padding: '0 2px'}}>
              <FileUploaderVideo fullWidth type="video/*" variant="contained" style={{backgroundColor:"#fb4d24", color:"white", fontSize:'10px'}} onChange={this.handleFileUpload}>
              <VideoIcon size="tiny"/> Add Video
              </FileUploaderVideo>
            </GridItem> */}
          {/* </div> */}
        </GridContainer>
        {this.state.audioType == 'fromrecording' && <AudioModal
          showAudioModal={this.state.AudioRecordModal}
          closeAudioModal={this.closeAudioRecModal}
          handleFileUpload={this.handleFileUpload}
        />}
        {this.state.showAudioModal &&
          <AudioModalConfirm showAudioModal={this.state.showAudioModal}
          closeAudioModal={this.closeAudioModal}
          setAudioChooseType={this.setAudioChooseType}
          handleFileUpload={this.handleFileUpload}
        />}
      </>

    )
  }
}


const mapStateToProps = (state) => {
  const { id: activeUser } = state.login.user;
  const {
    quickCms: {
      savingToPisco,
      creatingContent,
      categories,
    }
  } = state.cms;
  const {
    login: {
      user: {
        domainMap,
        activeDomain,
      }
    }
  } = state;
  return {
    user: state.userManagement.accountManagement.userList[activeUser],
    categories,
    savingToPisco,
    creatingContent,
    domainMap,
    activeDomain,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveMediaToPisco: (file, cb) => dispatch(saveMediaToPisco(file, cb)),
    getCategories: () => dispatch(getCategories()),
    AddNewPost: (artcielId, data, status, silent) => dispatch(AddNewPost(artcielId, data, status, silent)),
    EditPost: (articleId, data, status) => dispatch(EditPost(articleId, data, status)),
    setArticleId: (articleId) => dispatch(setArticleId(articleId)),
    InvalidFileError: (msg) => dispatch(InvalidFileError(msg)),
    resetBothFilter: () => dispatch(resetBothFilter()),
    fetchArticleDetails: (articleId) => dispatch(fetchArticleDetails(articleId)),
    changeCoverPicture: (coverImageForm, articleId) => dispatch(changeCoverPicture(coverImageForm, articleId)),
    updateArticleContent: (htmlContent, mapObject, articleId) => dispatch(updateArticleContent(htmlContent, mapObject, articleId)),
    getFeed: (status, headerType) => dispatch(getFeed(status, headerType)),
    resetFeed: () => dispatch(resetFeed()),
    getVideoThumbnail: (source) => dispatch(getVideoThumbnail(source)),
    getBlurImage :(url)=> dispatch(getBlurImage(url))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withWidth()(PostsView)));
