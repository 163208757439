import React, { PureComponent, Fragment } from "react";
import UserList from "./views/user-list.jsx";

class AccountManagementPage extends PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div style={{ paddingRight: "15px", paddingLeft: "15px" }}>
        <UserList />
      </div>
    )
  }
}
export default AccountManagementPage;