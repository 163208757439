import React, { PureComponent } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { withStyles, Typography, IconButton } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';

import Button from "@material-ui/core/Button";
import Disclaimers from "./disclaimers.jsx";
import { ArticleState, ActionOnArticle } from "app-constants/index.js";
import AppLoader from "../../../app-components/app-loader.jsx";
import { connect } from "react-redux";



const CustomDialogTitle = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, setDisclaimerModal } = props;
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="subheading">
        <div style={{ display: "flex", justifyContent: "center", fontWeight: "bold" }}>
          {children}
        </div>
      </Typography>
      <IconButton aria-label="Close" className={classes.closeButton} onClick={() => {
        setDisclaimerModal(false)
      }}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
});


class DisclaimerModal extends PureComponent {

  getArticleStatue = () => {
    const { articleDetails: { status = 0 }, userDetails: { permissions = [] } } = this.props;

    switch (status) {
      case 4: return "Save";
      case 3: {
        if (permissions.includes("CREATE:PUBLISH")) {
          return "Publish"; //done
        }
        return "Save";
      }
      case 1: return "Approve"; //done
      case 0: return "submit"; //done
      default: return "Save";
    }
  }

  setButtonText = () => {
    const { articleSettings: {
      previewModalAction,
      isSubmitting,
      isApproving,
      isPublishing,
    }
    } = this.props;

    const articleStatus = this.getArticleStatue();
    if (isSubmitting) {
      return <span style={{ display: "flex" }}>{articleStatus}{" "}<AppLoader isSignOutLoader={true} color="inherit" size={20} /></span>
    }
    if (isApproving) {
      return <span style={{ display: "flex" }}>{articleStatus}{" "}<AppLoader isSignOutLoader={true} color="inherit" size={20} /></span>
    }

    if (isPublishing) {
      return <span style={{ display: "flex" }}>{articleStatus}{" "}<AppLoader isSignOutLoader={true} color="inherit" size={20} /></span>
    }
    return articleStatus;
  }

  render() {
    const {
      disclaimers,
      isDisclaimerLoading,

      showDisclaimerModal,
      setDisclaimerModal,
      setDisclaimerType,

      articleDetails,
      setArticleIdForPreviewArticle,
    } = this.props;
    const { disclaimerId } = articleDetails;

    const status = this.setButtonText();
    return (
      <Dialog
        open={showDisclaimerModal}
        fullWidth={true}
        onClose={this.hide}
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
      >
        <CustomDialogTitle setDisclaimerModal={setDisclaimerModal}>Please Select a disclaimer: </CustomDialogTitle>
        <DialogContent>
          <Disclaimers
            disclaimerId={disclaimerId}
            disclaimers={disclaimers}
            setDisclaimerType={setDisclaimerType}
            isDisclaimerLoading={isDisclaimerLoading}
            setDisclaimerType={setDisclaimerType}
            articleDetails={articleDetails}
            setArticleIdForPreviewArticle={setArticleIdForPreviewArticle}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDisclaimerModal(false)}>
            Cancel
        </Button>
          <Button onClick={(e) => {
            e.preventDefault();
            setDisclaimerModal(false);

            setArticleIdForPreviewArticle(articleDetails.articleId, {
              currentState: ArticleState.DRAFT,
              nextState: ArticleState.SUBMITTED,
              requiredAction: ActionOnArticle.SUBMIT
            });
          }}>
            Preview and {status}

          </Button>
        </DialogActions>
      </Dialog >)
  }
}

const mapStateToProps = (state) => {
  const { id: activeUser } = state.login.user;
  const userDetails = state.userManagement.accountManagement.userList[activeUser];
  return {
    userDetails: userDetails
  }
}
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DisclaimerModal);

