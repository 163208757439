import React from "react";
import QuickCmsView from "./views/quick-cms-view";
class QuickCmsHomepage extends React.Component {
  render() {
    return (
        <QuickCmsView/>
    )
  }
}

export default QuickCmsHomepage;
