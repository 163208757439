import React, { Component, PureComponent }
  from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import NoDataFound from "../../../app-components/no-data-found.jsx";
import debounce from "lodash.debounce";
import RoundButton from '../../../components/RoundButton/roundbutton';
import ArticlePreview from '../../article/view/article-preview.jsx';
import articlesStyle from "../../../assets/jss/material-dashboard-pro-react/views/articlesStyle.jsx";
import MenuDropDown from "./menu-dropdown.jsx";
import ArticlListContainer from './article-list-container.jsx';
import Waypoint from 'react-waypoint';
import ProgressModal from "./progress-modal";
import ShareArticleModal from "./share-live-article";
import ScheduleArticleModal from "./schedule-live-article";
import ExploitArticleModal from "./exploit-live-article";
import ScheduleTwitterArticleModal from "./schedule-twitter-live-article";
import { ArticlesState } from "../../../app-constants/index.js";
import ConfirmationBox from "../../../app-components/confirmation-box";
import Modal from "components/Modal/modal";

const AritcleContainerWithRef = React.forwardRef((props, ref) => {
  return <ArticlListContainer innerRef={ref} {...props} />
});

class ArticlesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topButtonShow: false,
      anchorEl: null,
      lastLocation: 'none'
    }
    this.loadArticlesOnScroll = debounce(this.loadArticlesOnScroll, 200);
    this.loadButton = debounce(this.loadButton, 0);
  }

  loadArticlesOnScroll = () => {
    !this.props.isLiveSearch && this.props.getArticles();
  }
  loadButton = () => {
    const scrolledTop = document.getElementById("mainPanel").scrollTop
    if (scrolledTop > 5) {
      if (!this.state.topButtonShow) {
        this.setState({ topButtonShow: true })
      }
    }
    else {
      if (this.state.topButtonShow) {
        this.setState({ topButtonShow: false })
      }
    }
  }

  fetchArticles = () => {
    const listHeight = document.getElementById("artical-list-container").offsetHeight;
    const windowHeight = window.innerHeight;
    const scrolledTop = document.getElementById("mainPanel").scrollTop;
    if (this.state.lastLocation === 'edit-page' && this.props.articleState == 'LiveArticles') {
      this.setState({ lastLocation: 'none' });
      return;
    }
    if ((listHeight - windowHeight - scrolledTop) < 200) {
      this.loadArticlesOnScroll();
    }
  }

  componentDidMount() {
    this.setState({ lastLocation: this.props.lastLocation });
    try {
      document.getElementById("mainPanel").addEventListener('scroll', this.fetchArticles);
      // document.getElementById("searchMainPanel").addEventListener('scroll', this.fetchArticles);
      document.getElementById("mainPanel").addEventListener('scroll', this.loadButton);
    }
    catch (e) {

    }

    if (this.props.currentLiveHeight > 0 && this.props.articleState == 'LiveArticles') {
      setTimeout(() => {
        document.getElementById("mainPanel").scroll({ top: this.props.currentLiveHeight, behavior: 'auto' });
        this.props.setCurrentLiveArticleHeight(0);
      }, 100);
    }
  }

  componentWillUnmount() {
    const currentHeight = document.getElementById("mainPanel").scrollTop;
    this.props.setCurrentLiveArticleHeight(currentHeight);
    try {
      document.getElementById("mainPanel").removeEventListener('scroll', this.fetchArticles);
      document.getElementById("mainPanel").removeEventListener('scroll', this.loadButton);
    }
    catch (e) {

    }
  }
  topFunction = () => {
    document.getElementById("mainPanel") && document.getElementById("mainPanel").scroll({ top: 0, behavior: 'smooth' });
  }

  setAnchorElForMenu = (anchorEl) => {
    this.setState({ ...this.state, anchorEl: anchorEl })
  }

  render() {
    const { user,
      domain,
      options,
      articlesByIds,
      fetchPreviewArticleDetail,
      menuListAnchorEl,
      removeArticleIdForMenuList,
      setArticleIdForPreviewArticle,
      menuListArticleId,
      articlesIds,
      articleLetter,
      setModalData,
      setArticleIdForMenuList,
      loadingArticles,
      hasMoreData,
      toggleProgressModal,
      showPublishProgressModal,
      progressModalData,
      showShareArticleModal,
      shareArticleData,
      toggleShareArticleModal,
      confirmationMessage,
      showConfirmationModal,
      onConfirm,
      toggleConfirmationBox,
      showScheduleArticleModal,
      showExploitArticleModal,
      scheduleArticleData,
      exploitArticleData,
      toggleScheduleArticleModal,
      toggleExploitArticleModal,
      showScheduleTwitterArticleModal,
      scheduleTwitterArticleData,
      toggleScheduleTwitterArticleModal,
    } = this.props;

    const { anchorEl } = this.state;

    return (
      <>
        {this.state.topButtonShow &&
          <RoundButton
            onclick={this.topFunction}
            type="arrow_upward"
            buttonbackground="#CED3DC"
            location={{
              right: '30px',
              bottom: "95px",
              position: 'fixed',
              background: '#CED3DC',
              border: 'none',
              cursor: "pointer",
              zIndex: 9999
            }}
            iconcolor="#2B2D42"
          />}
        <Waypoint>
          <AritcleContainerWithRef
            setAnchorElForMenu={this.setAnchorElForMenu}
            articlesByIds={articlesByIds}
            articleLetter={articleLetter}
            setArticleIdForMenuList={setArticleIdForMenuList}
            articlesIds={articlesIds}
          />
        </Waypoint>
        <div id="artical-list-container">
          {
            <MenuDropDown
              anchorEl={anchorEl}
              user={user}
              domain={domain}
              articlesByIds={articlesByIds}
              anchorEl={this.state.anchorEl}
              menuListAnchorEl={menuListAnchorEl}
              options={options}
              fetchPreviewArticleDetail={fetchPreviewArticleDetail}
              removeArticleIdForMenuList={removeArticleIdForMenuList}
              setArticleIdForPreviewArticle={setArticleIdForPreviewArticle}
              menuListArticleId={menuListArticleId}>
            </MenuDropDown>}

          <ArticlePreview />
        </div>
        {(articlesIds.length == 0 && !loadingArticles) ? <NoDataFound dataTypeName="Media" /> : !hasMoreData && <NoDataFound
          dataTypeName="articles"
          pageNo={articlesIds.length}
          fontSize='1em' />}
        <ProgressModal
          showPublishProgressModal={showPublishProgressModal}
          progressModalData={progressModalData}
          toggleProgressModal={toggleProgressModal}>
        </ProgressModal>
        <ConfirmationBox
          confirmationMessage={confirmationMessage}
          showConfirmationModal={showConfirmationModal}
          onConfirm={onConfirm}
          toggleConfirmationBox={toggleConfirmationBox}
          removeArticleIdMenuList={removeArticleIdForMenuList}
        >
        </ConfirmationBox>
        <ShareArticleModal
          showShareArticleModal={showShareArticleModal}
          shareArticleData={shareArticleData}
          toggleShareArticleModal={toggleShareArticleModal}
        >
        </ShareArticleModal>
        <ScheduleArticleModal
          showScheduleArticleModal={showScheduleArticleModal}
          scheduleArticleData={scheduleArticleData}
          toggleScheduleArticleModal={toggleScheduleArticleModal}
        />
        <ExploitArticleModal
          showExploitArticleModal={showExploitArticleModal}
          exploitArticleData={exploitArticleData}
          toggleExploitArticleModal={toggleExploitArticleModal}
        />
        <ScheduleTwitterArticleModal
          showScheduleTwitterArticleModal={showScheduleTwitterArticleModal}
          scheduleTwitterArticleData={scheduleTwitterArticleData}
          toggleScheduleTwitterArticleModal={toggleScheduleTwitterArticleModal}
        />
      </>
    )
  }
}
export default withStyles(articlesStyle)(ArticlesList);
