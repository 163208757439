

const contentFeedActions = {
  GET: {
    REQUEST: 'Quickcontent.FeedActions.Get.Request',
    FAILURE: 'Quickcontent.FeedActions.Get.Failure',
    SUCCESS: 'Quickcontent.FeedActions.Get.Success'
  },
  FEED: {
    REMOVE: 'Quickcontent.FeedActions.Feed.Remove',
    RESET: 'Quickcontent.FeedActions.Feed.Reset'
  },
  UPDATE: {
    REQUEST: 'Quickcontent.FeedActions.Update.Request',
    FAILURE: 'Quickcontent.FeedActions.Update.Failure',
    SUCCESS: 'Quickcontent.FeedActions.Update.Success'
  },
  RESET: {
    FEED: 'Quickcontent.FeedActions.Reset.Feed',
    HEADER_TYPE: 'Quickcontent.FeedActions.Reset.Header_Type',
    ALL: 'Quickcontent.FeedActions.Reset.All'
  },
  SEARCH: {
    FEED: 'Quickcontent.FeedActions.Search.Feed',
    REQUEST: 'Quickcontent.FeedActions.Search.Request'
  }
}

export default contentFeedActions;
