import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import { connect } from 'react-redux';
import moment from "moment";
import PageSchedule from "./schedule.jsx";
import { scheduleTweet } from "../../schedule-action.js";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class PostOnPage extends React.Component {
  constructor(props) {
    super(props);
    this.getSumittedData = this.getSumittedData.bind(this);
    this.state = {
      success: '',
      error: '',
      user: props.user,
      users: props.users,
      open: false,
      desc: '',
    };

  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onSubmit() {
    const data = {
        "page": {
          "name": this.state.data.pageName,
        },
        "data": {
          "url": this.state.data.link,
          "time": +moment(this.state.data.time),
      },
    };
    this.setState({postData: data}, ()=>{
      this.props.schedulePost(this.state.postData).then(res => {
        let temp = this.state.success;
        temp.message = res.final;
        this.setState({success: temp});
      }, _ => {
        let temp = this.state.error;
        temp.message = _.err;
        this.setState({error: temp});
      });
    });
  };

  getSumittedData(data) {
    let token = {};
    if(data.time && data.pageName && (data.content || data.link)) {
      this.props.users.forEach(obj=>obj.screen_name === data.pageName ? token = obj : false);
      this.props.scheduleTweet({ message: `${data.content} ${data.link}`, date: +moment(data.time),
        username: data.pageName, token: token.oauth_token, tokenSecret: token.oauth_token_secret }).then(res => {
        this.setState({success: res.final,desc: "Tweet got Scheduled",open: true});
      }, _ => {
        this.setState({error: _.err,desc: "Schedule Failed",open: true});
      });
    } else {
      this.setState({desc: "Please Enter/Select Necessary Fields",open: true});
    }
  }

  render() {
    if(this.props.users){
      return (
        <GridContainer justify="flex-start">
          <Dialog
            fullWidth={true}
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Schedule Tweets"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.state.desc}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                OKAY
              </Button>
            </DialogActions>
          </Dialog>
          <h4 style={{color: '#00FF00'}}>{this.state.error || this.state.success}</h4>
          <PageSchedule users={this.props.users} formData={this.getSumittedData}/>
        </GridContainer>
      );
    }
  }
}
const mapStateToProps = (store) => {
  return {};
}
const mapDispatchToProps = (dispatch) => {
  return {
    scheduleTweet: (data) => dispatch(scheduleTweet(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PostOnPage);
