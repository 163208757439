import { combineReducers } from "redux";
import {
  UserAction
} from "../app-constants/index.js";
import pages from "./pages/pages-reducer";

import socialLink from "./social-account/social-reducer";
//--------------------Disclaimers
const defaultDisclamerState = {
  isLoadingDisclaimers: false,
  disclaimers: null,
  showDisclaimerModal: false,
  disclaimerModalData: {
    id: null,
    title: '',
    description: ''
  }
}

const disclaimerReducer = (state = defaultDisclamerState, action) => {
  switch (action.type) {
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return defaultDisclamerState
    }
    case 'fetchDisclaimersRequest': {
      return { ...state, isLoadingDisclaimers: true }
    }
    case 'fetchDisclaimersSuccess': {
      return { ...state, disclaimers: action.disclaimers, isLoadingDisclaimers: false }
    }
    case 'fetchDisclaimersFailure': {
      return { ...state, isLoadingDisclaimers: false }
    }
    case 'toggleDisclaimerModal': {
      if (action.flag === false)
        return { ...state, showDisclaimerModal: action.flag, disclaimerModalData: { title: '', description: '', id: null } }
      else
        return { ...state, showDisclaimerModal: action.flag }
    }
    case 'addDisclaimerSuccess': {
      return { ...state, disclaimerModalData: { title: '', description: '', id: null } }
    }
    case 'setDisclaimerModalData': {
      return { ...state, disclaimerModalData: action.disclaimer, showDisclaimerModal: true }
    }
    default: return state;
  }
}


//--------------------Categories
const defaultCategoryState = {
  isLoadingCategories: false,
  categories: null,
  showCategoryModal: false,
  categoryModalData: {},
  editMode: false
}
const categoryReducer = (state = defaultCategoryState, action) => {
  switch (action.type) {
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return defaultCategoryState
    }
    case 'fetchCategoriesRequest': {
      return { ...state, isLoadingCategories: true }
    }
    case 'fetchCategoriesSuccess': {
      return { ...state, categories: action.categories, isLoadingCategories: false }
    }
    case 'fetchCategoriesFailure': {
      return { ...state, isLoadingCategories: false }
    }
    case 'toggleCategoryModal': {
      return { ...state, showCategoryModal: action.flag, editMode: action.flag === false ? true : false }
    }
    case 'addCategorySuccess': {
      return { ...state, categoryModalData: defaultCategoryState.categoryModalData, editMode: false }
    }
    case 'setCategoryModalData': {
      return { ...state, categoryModalData: action.category, showCategoryModal: true, editMode: true }
    }
    case 'toggleEditMode': {
      return { ...state, editMode: action.flag }
    }
    default: return state;
  }
}

const defaultAnalyticsState = {
  isLoadingAnalytics: false,
  gaID: ''
}

const analyticsReducer = (state = defaultAnalyticsState, action) => {
  switch (action.type) {
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return defaultAnalyticsState
    }
    case 'fetchAnalyticsRequest': {
      return { ...state, isLoadingAnalytics: true }
    }
    case 'fetchAnalyticsFailure': {
      return { ...state, isLoadingAnalytics: false }
    }
    case 'fetchAnalyticsSuccess': {
      return { ...state, gaID: action.gaID, isLoadingAnalytics: false }
    }
    case 'setAnalytics': {
      return { ...state, gaID: action.gaID }
    }
    default: return state;
  }
}

const defaultFaviconLogoState = {
  isLoadingFaviconLogo: false,
  favicon: '',
  logo: '',
  activeDomain: ''
}

const faviconLogoReducer = (state = defaultFaviconLogoState, action) => {
  switch (action.type) {
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return defaultFaviconLogoState
    }
    case 'fetchFaviconLogoRequest': {
      return { ...state, isLoadingFaviconLogo: true }
    }
    case 'fetchFaviconLogoFailure': {
      return { ...state, isLoadingFaviconLogo: false }
    }
    case 'fetchFaviconLogoSuccess': {
      return { ...state, activeDomain: action.activeDomain, favicon: action.favicon, logo: action.logo, isLoadingFaviconLogo: false }
    }
    case 'setFaviconLogo': {
      return { ...state }
    }
    default: return state;
  }
}
const defaultSiteInfo = {
  title: null,
  description: null
}
const siteInfo = (state = defaultSiteInfo, action) => {
  switch (action.type) {
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return defaultFaviconLogoState
    }
    case 'websiteManagement.Set.SiteInfo': {
      const { title, description } = action;
      return { ...state, title, description }
    }
    case 'websiteManagement.fetch.SiteInfo': {
      const { title, description } = action;
      return { ...state, title, description }
    }
    default: return state;
  }
}

//---------------------fetchMapDomainStatus
const defaultMapDomain = {
  isloading: false,
  status: null,
  isMapping: false,
  mappingResponse: {},
  dnsrecords: [],
  hostName: '',
  addingDnsEntry: false,
  deletingDnsEntry: false,
  showEntriesModal: false,
  entriesModalData: {
    id: null,
    type: '',
    name: '',
    content: '',
    ttl: '',
    proxied: '',
  }
}

const MapDomain = (state = defaultMapDomain, action) => {
    switch(action.type) {
      case 'fetchMapDomainRequest': {
        return {...state, isLoading: true}
      }
      case 'fetchMapDomainFailure': {
        return {...state, isLoading: false}
      }
      case 'fetchMapDomainSuccess': {
        return {...state, isLoading: false, mappingResponse: action.data}
      }
      case 'mappingRequest': {
        return {...state, isMapping: true}
      }
      case 'mappingFailure': {
        return {...state, isMapping: false}
      }
      case 'mappingSuccess': {
        return {...state, isMapping: false, status: action.data.status, mappingResponse: action.data}
      }
      case 'dnsrecordssuccess': {
        return {...state, dnsrecords: action.data.data, hostName: action.data.host}
      }
      case 'toggleAddingDnsEntry': {
        return {...state, addingDnsEntry: !state.addingDnsEntry}
      }
      case 'toggleDeletingDnsEntry': {
        return {...state, deletingDnsEntry: !state.deletingDnsEntry}
      }
      case 'setEntriesModalData': {
        return { ...state, entriesModalData: action.data, showEntriesModal: true }
      }
      case 'toggleentriesmodal': {
        if(action.flag == false)
          return {...state, showEntriesModal: action.flag, entriesModalData: defaultMapDomain.entriesModalData}
        return {...state, showEntriesModal: action.flag}
      }
      default: return state;
    }
}

const websiteManagementReducer = combineReducers({
  disclaimerReducer,
  categoryReducer,
  analyticsReducer,
  faviconLogoReducer,
  siteInfo,
  socialLink,
  pages,
  MapDomain,
});

export default websiteManagementReducer;
