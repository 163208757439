import { UserAction, MessageTypes, NotificationTypes, LocalStorageKeys, SessionKeys } from "../app-constants";
import { MeawwApi } from "../utils/api.js";
import { setCookie, isValidEmailAddress, eraseCookie, removePersistedData } from "../utils/utils.js";
import { setAppMessage, addAppNotification } from "../app-actions.js";
import { fetchUserPermissions, fetchMyPermission } from "../user-management/user-permission/user-permission-action.js";




function signInRequest() {
  return {
    type: UserAction.SIGNIN.REQUEST
  }
}
function signInFailure() {
  return {
    type: UserAction.SIGNIN.FAILURE
  }
}
function signInSuccess(userDetails) {
  return {
    type: UserAction.SIGNIN.SUCCESS,
    userDetails: userDetails
  }
}

function persistLoginInfo(userDetails) {
  const { auth: authKey, firstName, lastName, profilePicture = "" } = userDetails;
  //saving into cookie
  setCookie("name", firstName + " " + lastName, 7);
  setCookie("Authorization", authKey, 7);
  setCookie("profile_picture", profilePicture, 7);

  if (profilePicture) {
    localStorage.setItem("ProfilePicture", profilePicture);
  }
  //saving into localStorage
  if (authKey) localStorage.setItem("Authorization", authKey);
  // if(firstName && lastName ) localStorage.setItem("User", JSON.stringify({firstName: firstName, lastName: lastName, profilePicture: profilePicture}))
  //saving auth key in session storage
  sessionStorage.setItem("Authorization", authKey);
  localStorage.setItem(LocalStorageKeys.AUTHORIZATION_KEY, authKey);
  localStorage.setItem(LocalStorageKeys.USER_DETAILS, JSON.stringify(userDetails));
}


export function loginInUser(email, password) {
  return async (dispatch) => {
    try {
      dispatch(signInRequest());
      let loginData = { email, password, role: 'creator' };
      const loginRes = await fetch(`${process.env.REACT_APP_USER_SERVICE_API}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(loginData)
      }).then(res => res.json()).catch((err) => {
        console.log("err",err)
      })
      const { accessToken } = loginRes;
      if (!accessToken) {
        dispatch(setAppMessage(loginRes && loginRes.message, { type: MessageTypes.ERROR }));
        dispatch(signInFailure());
        return Promise.reject();
      }
      localStorage.setItem("accessToken", accessToken);
      const userData = await fetch(`${process.env.REACT_APP_USER_SERVICE_API}/users`, {
        headers :{authorization:accessToken}
      }).then((res) => res.json())
      
      const { domains: [activeDomain] } = userData;
            window.Intercom &&  window.Intercom('update', {email: userData.email, user_id: userData.userUuid});
            if (!activeDomain) {
              const { firstName, lastName, profilePicture } = userData;
              localStorage.setItem("firstName", `${firstName} `)
              localStorage.setItem("lastName", `${lastName} `)
              localStorage.setItem("profilePicture", `${profilePicture}`)
              dispatch(signInSuccess({ ...userData, permissions: [] }));
              return Promise.resolve(userData);
            }
              return dispatch(fetchMyPermission(activeDomain))
            .then(function (permissions) {
              const { firstName, lastName, profilePicture } = userData;
              localStorage.setItem("firstName", `${firstName} `)
              localStorage.setItem("lastName", `${lastName} `)
              localStorage.setItem("profilePicture", `${profilePicture}`);
              return dispatch(signInSuccess({ ...userData, activeDomain, permissions }));
            })
            .catch(err => {
              return dispatch(signInSuccess({ ...userData, activeDomain }))
                .catch(user => {
                  return Promise.reject(err)
                })
            });
    }
    catch (error) {
      if (error) {
        dispatch(signInFailure());
        dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
        return Promise.reject(error);
      }
    }
  }
}

function featurePermisionSuccess(featurePermision) {
  return {
    type: UserAction.DOMAIN_PERMISION.SUCCESS,
    featurePermision
  }
}

export function fetchFeaturePermision() {

  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/acl/domain/${activeDomain}/features/?type=flat`, (err, data) => {
          if (err) {
            return Promise.reject();
          }
          dispatch(featurePermisionSuccess(data));
          return Promise.resolve();
        });
      });
  }
}

export function setForgotPassword(forgotPassword) {
  return {
    type: UserAction.SET.FORGOT_PASSWORD,
    forgotPassword: forgotPassword,
  }
}
export function updateFormData(formData) {
  return {
    type: UserAction.SET.FORM_DATA,
    formData: formData,
  }
}

function resetPasswordRequest() {
  return {
    type: UserAction.RESET_PASSWORD.REQUEST
  }
}
function resetPasswordFailure() {
  return {
    type: UserAction.RESET_PASSWORD.FAILURE
  }
}
function resetPasswordSuccess() {
  return {
    type: UserAction.RESET_PASSWORD.SUCCESS
  }
}


export function resetPassword(email) {
  return async (dispatch) => {
    if (!isValidEmailAddress(email)) {
      dispatch(setAppMessage("Please enter valid Email!", { type: MessageTypes.ERROR }));
      return Promise.reject();
    }
    try {
      dispatch(resetPasswordRequest());
      const loginRes = await fetch(`${process.env.REACT_APP_USER_SERVICE_API}/auth/send-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          common_input: email,
          role: "creator",
          type: "reset",
        })
      }).then(res => res.json());
      dispatch(setAppMessage(loginRes && loginRes.message, { type: MessageTypes.SUCCESS }));
      return Promise.resolve()
    }
    catch (err) {
      console.log("err", err)
      return Promise.reject()
    }
  }
}

function updatePasswordRequest() {
  return {
    type: UserAction.UPDATE_PASSWORD.REQUEST
  }
}

function updatePasswordFailure() {
  return {
    type: UserAction.UPDATE_PASSWORD.FAILURE
  }
}
function updatePasswordSuccess() {
  return {
    type: UserAction.UPDATE_PASSWORD.SUCCESS
  }
}

export function updatePassword(formData) {
  return (dispatch, getState) => {
    const {
      email = "",
      new_password: newPassword = "",
      confirm_new_password: confirmNewPassword = "",
      otp = ""
    } = formData;
    if (newPassword.length < 6) {
      dispatch(setAppMessage(`Password should be atleast 6 characters long`, { type: MessageTypes.ERROR }));
      return;
    }
    if (newPassword !== confirmNewPassword) {
      dispatch(setAppMessage(`Password Do Not Match`, { type: MessageTypes.ERROR }));
      return;
    }

    dispatch(updatePasswordRequest());
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post("/v2/authors/resetPassword", {
          "email": email,
          "password": newPassword,
          "otp": otp
        }, (err, data) => {
          if (err) {
            dispatch(updatePasswordFailure());
            dispatch(setAppMessage(err.toString(), { type: MessageTypes.ERROR }));
            return;
          }
          if (!data.success) {
            dispatch(updatePasswordFailure());
            dispatch(setAppMessage(data.message, { type: MessageTypes.SUCCESS }));
            return;
          }
          dispatch(updatePasswordSuccess());
          dispatch(setAppMessage("Password Reset is Successful!", { type: MessageTypes.SUCCESS }));
          dispatch(setForgotPassword(false));
          return;
        })
      })
  }
}

export function setSignupOTPModal(status) {
  return {
    type: UserAction.SET.SIGNUP_OTP_MODAL,
    status: status
  }
}


function signupRequest() {
  return {
    type: UserAction.SIGNUP.REQUEST
  }
}
function signupSuccess() {
  return {
    type: UserAction.SIGNUP.SUCCESS
  }
}
function signupFailure() {
  return {
    type: UserAction.SIGNUP.FAILURE
  }
}

export function signupUserData({ email, password, firstName: firstname, lastName: lastname }) {
  return (dispatch, getState) => {
    dispatch(signupRequest());
    return MeawwApi()
      .auth()
      .then(api => {
        // return api.post("/signup", {
        return api.post("/v2/authors/signup", {
          email: email,
          password: password,
          first_name: firstname,
          last_name: lastname,
        }, (error, data) => {
          if (error) {
            dispatch(signupFailure());
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }))
            return;
          }
          dispatch(signupSuccess());
          dispatch(setSignupEmail(email));
          dispatch(setSignupOTPModal(true));
        })
      })
  }
}

export function setSignupEmail(email) {
  return {
    type: UserAction.SET.SIGNUP_EMAIL,
    email: email
  }
}


function verifyingOtpRequest() {
  return {
    type: UserAction.VERIFYING_OTP.REQUEST
  }
}
function verifyingOtpFailure() {
  return {
    type: UserAction.VERIFYING_OTP.FAILURE
  }
}
function verifyingOtpSuccess() {
  return {
    type: UserAction.VERIFYING_OTP.SUCCESS

  }
}

export function verifySignupOTP({ otp }) {
  return (dispatch, getState) => {
    const { signupEmail } = getState().login;
    dispatch(verifyingOtpRequest());
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post("/verifysignupotp", {
          otp: otp,
          email: signupEmail
        }, (error, { success, message } = {}) => {
          if (error) {
            dispatch(verifyingOtpFailure());
            dispatch(setSignupOTPModal(false));
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }))
            return Promise.reject(error);
          }
          dispatch(verifyingOtpSuccess());
          dispatch(setAppMessage(`${message}.Please Signin to Continue...`, { type: MessageTypes.SUCCESS }));
          dispatch(setSignupOTPModal(false));
          return Promise.resolve();
        })
      });
  }
}

// export function signOutUser() {
//   return (dispatch, getState) => {
//     removePersistedData();
//     dispatch({
//       type: UserAction.SIGN_OUT.SUCCESS,
//     });
//     return Promise.resolve();
//   }
// }
function signoutRequest() {
  return {
    type: UserAction.SIGN_OUT.REQUEST
  }
}
function signoutSuccess() {
  return {
    type: UserAction.SIGN_OUT.SUCCESS
  }
}
function signoutFailure() {
  return {
    type: UserAction.SIGN_OUT.FAILURE
  }
}
export function signOutUser() {
  return (dispatch, getState) => {
    sessionStorage.removeItem(SessionKeys.ACTIVE_DOMAIN);
    localStorage.removeItem("accessToken")
    if(window.gapi && window.gapi.auth2)
      window.gapi.auth2.getAuthInstance().signOut();
    dispatch(signoutRequest())
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post("/user/logout",
          (error, { success, message } = {}) => {
            if (error) {
              dispatch(signoutFailure());
              // dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }))
              return Promise.reject(error);
            }
            removePersistedData();
            dispatch(signoutSuccess());
            return Promise.resolve();
          })
      });
  }
}


export function verifyEmail(payload) {
  return async (dispatch) => {
    try {
      dispatch(resetPasswordRequest());
      const loginRes = await fetch(`${process.env.REACT_APP_USER_SERVICE_API}/auth/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      }).then(res => res.json());
      loginRes && loginRes.message && dispatch(setAppMessage(loginRes && loginRes.message, { type: MessageTypes.SUCCESS }));
      return Promise.resolve(loginRes)
    }
    catch (err) {
      console.log("err", err)
      return Promise.reject()
    }
  }
}

export function resetPasswordWithToken (payload,token){
  return async (dispatch) => {
    try {
      dispatch(resetPasswordRequest());
      const loginRes = await fetch(`${process.env.REACT_APP_USER_SERVICE_API}/auth/reset-password?token=${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      }).then(res => res.json());
     
      loginRes && loginRes.message && dispatch(setAppMessage(loginRes && loginRes.message, { type: MessageTypes.SUCCESS }));
      return Promise.resolve(loginRes)
    }
    catch (err) {
      console.log("err", err)
      return Promise.reject()
    }
  }
}

