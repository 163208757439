import React from 'react';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import hamburger from '../assets/icons/hamburger.svg';
import logo from '../assets/img/pubninja-logo.png';
import './footer.scss';

import facebookIcon from '../assets/icons/social-icons/facebook.svg';
import twitterIcon from '../assets/icons/social-icons/twitter.svg';
import instagramIcon from '../assets/icons/social-icons/instagram.svg';
import linkedinIcon from '../assets/icons/social-icons/linkedin.svg';

import Hidden from '@material-ui/core/Hidden';

function Footer(props) {
  return (
    <div className="website-footer">
      {/*
        <div className="footer-top">
        <GridContainer>
        <GridItem xs={6}>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <div className="footer-title">
            Are you Ready To Work Together?
          </div>
          <Hidden smUp>
            <GridContainer justify="center">
              <GridItem xs={3}>
                <div className="footer-title-line">
                </div>
              </GridItem>
            </GridContainer>
          </Hidden>
          <Hidden xsDown>
            <GridContainer justify="flex-end">
              <GridItem xs={3}>
                <div className="footer-title-line">
                </div>
              </GridItem>
            </GridContainer>
          </Hidden>
          <div className="footer-subtitle">
            To get in touch, write to <span style={{fontWeight: '600', fontStyle: 'italic'}}>hello@pubninja.com</span>
          </div>
        </GridItem>
        </GridContainer>
      </div>
      */}
        <GridContainer>
          <GridItem xs={12}>
          <div className="footer-divider"></div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
          <div className="footer-bottom">
          </div>
          </GridItem>
        </GridContainer>
    </div>
  );
}

export default Footer;
