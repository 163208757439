import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Checkbox from '@material-ui/core/Checkbox';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AppLoader from "../../../app-components/app-loader.jsx";
import CategoryDropdown from "../components/category-dropdown";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import DraftjsEditor from "../../../cms/new-story/components/draftjs-editor.jsx";
import Cropper from 'react-easy-crop';
import withUpload from '../components/with-file-upload';
import Switch from '@material-ui/core/Switch';

const WithUploadButton = withUpload(Button);
class ArticlePostForm extends React.Component {
  state = {
    post_title: '',
    post_description: '',
    type: "text/html",
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 16 / 9,
    croppedAreaPixels: {},
    error: {
      postTitle: "Title is requried",
      postDescription: null,
    },
    cover_image:"",
    metadata: {},
    articleId: null,
    premium: false,
    content: '',
    category: null,
    creatingContent: false,
    showCropper: false,
    objectMap: {},
    uploadingCover: false,
    uploadingImage: false,
    htmlContent: null,
  }

  componentDidMount() {
    if (this.props.mode == "create") {
      this.addNewPost('draft');
      return;
    }
    if (this.props.mode === 'edit') {
      this.toggleCreatingContent();
      const { title, description, headerContent, paidContent, categoryId, articleId, coverPicture, imgExtension } = this.props.feedContent;
      this.props.fetchArticleDetails(articleId).then(res => {
        const { result: { html, objectMap } } = res;
        this.setState({
          post_title: title,
          post_description: description || '',
          premium: !!paidContent,
          metadata: JSON.parse(headerContent),
          category: categoryId,
          htmlContent: html,
          cover_image: coverPicture ? `${coverPicture}_1200_630${imgExtension}` :'',
          objectMap,
          articleId,
        });
      }).catch((err) => {

      }).finally(res => {
        this.toggleCreatingContent();
      });
      return;
    }
  }
  addNewPost = (status) => {
    this.toggleCreatingContent();
    let { post_title, post_description, premium, type, content, metadata, category } = this.state;
    category = category || (this.props.categories.length && this.props.categories[0].id);
    let data = {
      title: post_title,
      description: post_description,
      premium,
      type,
      content,
      metadata,
      category,
    };
    this.props.addNewPost(null, data, status, true).then(res => {
      const { result: { metadata: { articleId } } } = res.newArticle;
      this.setState({ articleId });
      // this.updateDefaultCoverImage(articleId);
      this.toggleCreatingContent();
    }).catch(err => {
      console.log("err", err);
      this.props.onClose();
      this.toggleCreatingContent();
    });
  }

  updateDefaultCoverImage = (articleId) => {
    const source = "https://cheesecake.articleassets.meaww.com/pubninja/default_article_cover_image.jpg";
    const cropData = {
      "x": 0,
      "y": 0,
      "scale": 0.01
    };
    this.props.changeCoverPicture({source, cropData}, articleId)
      .then(res => {
        const { result: { coverPicture, imgExtension } } = res;
        this.setState({ cover_image: `${coverPicture}_1200_630${imgExtension}` }, () => {
          this.toggleCreatingContent();
        })
      })
      .catch(err => {
        this.props.onClose();
      })
  }

  onCropChange = crop => {
    this.setState({ crop })
  }
  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  }
  onZoomChange = zoom => {
    this.setState({ zoom })
  }

  setCategory = (value) => {
    this.setState({ category: value });
  }

  handlePostTitle = (e) => {
    this.setState({ post_title: e.target.value });
  }

  toggleCoverImage = () => {
    this.setState((prev) => {
      return {
        showCropper: !prev.showCropper,
      }
    });
  }
  handleMediaUpload = (file, cb) => {
    // conosle.log("file", file, cb);
    this.setState({ uploadingImage: true });
    const format = file.type.split('/')[0];
    if (format !== "image") return alert("Invalid File Format.");
    this.props.saveMediaToPisco(file).then(res => {
      const { localUploadResponse: { cdn } } = res;
      cb(null, cdn);
    }).catch(err => {
      cb(err);
    }).finally(() => {
      this.setState({ uploadingImage: false });
    });
  }

  updatePost = (status) => {
    this.toggleCreatingContent();
    let { post_title, post_description, premium, type, content, metadata, category } = this.state;
    category = category || (this.props.categories.length && this.props.categories[0].id);
    let data = {
      title: post_title,
      description: post_description,
      premium,
      type,
      content,
      metadata,
      category,
    };
    const { feedContent } = this.props;
    this.props.updatePost((feedContent && feedContent.articleId) || this.state.articleId, data, status).then(res => {
    }).catch(err => {
    }).finally(() => {
      this.props.resetFeed();
      this.props.getFeed('draft&live');
      this.toggleCreatingContent();
      this.props.onClose();
    });
  }

  handlePostDescription = (e) => {
    this.setState({ post_description: e.target.value });
  }

  updateArticleContent = (htmlContent, mapObject) => {
    const { feedContent } = this.props;
    const articleId = (feedContent && feedContent.articleId) || this.state.articleId;
    this.props.updateArticleContent(htmlContent, mapObject, articleId);
  }

  toggleCoverUploader = () => {
    this.setState((prev) => {
      return {
        uploadingCover: !prev.uploadingCover,
      }
    })
  }
  onCoverImageUpload = (target, type) => {
    const cropData = {
      "x": 0,
      "y": 0,
      "scale": 0.01
    };
    this.toggleCoverUploader();
    const format = (target && target.files[0] && target.files[0].type).split('/')[1];
    const allowedFormat = ['jpg', 'jpeg', 'png'];
    if (allowedFormat.indexOf(format) == -1) {
      alert('File not supported');
      return;
    }
    this.props.saveMediaToPisco(target.files[0]).then(res => {
      const { localUploadResponse: { cdn } } = res;
      this.props.changeCoverPicture({ source: `https:${cdn}`, cropData }, this.state.articleId)
        .then(response => {
          const { result: { coverPicture, imgExtension } } = response;
          this.setState({ cover_image: `${coverPicture}_1200_630${imgExtension}` })
        })
        .catch(err => {
          console.log("image creating", err);
        });
    }).catch(err => {
      console.log("err upload", err);
    }).finally(res => {
      this.toggleCoverUploader();
    });

  }
  handlePremiumContent = (e) => {
    this.setState((prev) => {
      return {
        premium: !prev.premium,
      }
    })
  }

  toggleCreatingContent = () => {
    this.setState((prev) => {
      return {
        creatingContent:!prev.creatingContent,
      }
    })
  }
  render() {
    const { error, creatingContent, cover_image, showCropper,uploadingCover } = this.state;
    const { savingToPisco, file, imageData, feedContent, fullScreen, user : {permissions} } = this.props;
    const hasPublishPermission = permissions.includes("CREATE:PUBLISH");
    return (
      <Dialog fullScreen={fullScreen} maxWidth='md' open={this.props.showArticleForm}>
        <DialogContent>
          {creatingContent && <AppLoader message="Processing...." />}
          {!creatingContent && <GridContainer style={cover_image ? {marginTop:"-50px"} :{}}>
            {<GridItem xs={12}>
              <div>
                <WithUploadButton style={cover_image ? {position:'relative', top:'50px', left:'20px',} :{}} color="primary" onChange={this.onCoverImageUpload}><i className="material-icons">
                      add_a_photo
                    </i>ADD IMAGE</WithUploadButton>
                {uploadingCover && <AppLoader size={20} message="uploading cover image" />}
              </div>
            { cover_image && <div style={{ textAlign: "center" ,minWidth:"95%", minHeight:'320px'}}>
                {!uploadingCover && <img src={cover_image} width="95%" height="320px" style={{objectFit: 'contain'}}/>}
              </div>}
              {!!this.props.categories.length && <CategoryDropdown initalValue={this.props.feedContent && this.props.feedContent.categoryId} setCategory={this.setCategory} categories={this.props.categories} />}
              <CustomInput
                labelText="Article Title"
                id="post_title"
                value={this.state.post_title}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disableUnderline: true,
                  onChange: this.handlePostTitle,
                }}
                helpText={!this.state.post_title && error.postTitle}
                style={{marginBottom:'-15px'}}
              />
              <CustomInput
                labelText="Article Description"
                //infoText="Title to be shown for the story."
                id="post_description"
                value={this.state.post_description}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disableUnderline: true,
                  onChange: this.handlePostDescription,
                }}
                helpText={error.postDescription}
              />
              {/** Uncomment this code when premium content services are enabled */}
              {
                this.props.subscriptionEnabled == 1 &&
                <div style={{marginBottom: '25px'}}>
                  <span style={{marginRight: '30px'}}> Free Content </span>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.premium}
                        onChange={this.handlePremiumContent}
                        value="Premium Content"
                        color="primary"
                      />
                    }
                  />
                  <span> Premium Content </span>
                </div>
              }

              {this.state.uploadingImage && <div style={{backgroundColor:'green', color:"white", borderRadius:"5px", paddingLeft:'5px', paddingRight:'5px', textAlign:'left', margin:'5px'}}>Uploading Media....</div>}
              <DraftjsEditor
                objectMap={this.state.objectMap}
                htmlContent={this.state.htmlContent}
                updateApi={this.updateArticleContent}
                handleMediaUpload={this.handleMediaUpload}
              />
            </GridItem>}
            {!feedContent && <GridItem xs={12} style={{ marginTop: '20px', textAlign: 'center' }}>
              <GridContainer>
                <GridItem xs={12} md={4} style={{ marginBottom: '5px' }}>
                  <Button color="secondary" disabled={!this.state.post_title || !this.props.categories.length} onClick={this.updatePost.bind(null, 'draft')}>Save Draft</Button>
                </GridItem>
                {hasPublishPermission && <GridItem xs={12} md={4} style={{ marginBottom: '5px' }} id={`createworkspacepublish`}>
                  <Button color="secondary" disabled={!this.state.post_title || !this.props.categories.length} color="primary" onClick={this.updatePost.bind(null, 'publish')}>Publish</Button>
                </GridItem>}
                <GridItem xs={12} md={4}>
                  <Button color="primary" onClick={this.props.onClose}>Cancel</Button>
                </GridItem>
              </GridContainer>
            </GridItem>}
            {feedContent && <GridItem xs={12} style={{ marginTop: '20px' }}>
              <GridContainer>
                <GridItem xs={12} md={4}>
                  <Button color="secondary" disabled={!this.state.post_title || !this.props.categories.length} onClick={this.updatePost.bind(null, feedContent.status != 4 ? 'draft': 'live')}>Save Changes</Button>
                </GridItem>
                {(feedContent.status != 4 && hasPublishPermission) && <GridItem xs={12} md={4} id={`createworkspacepublish`}>
                  <Button color="secondary" disabled={!this.state.post_title || !this.props.categories.length} color="primary" onClick={this.updatePost.bind(null, 'publish')}>Publish</Button>
                </GridItem>}
                <GridItem xs={12} md={4}>
                  <Button color="primary" onClick={this.props.onClose}>Cancel</Button>
                </GridItem>
              </GridContainer>
            </GridItem>}
          </GridContainer>
          }
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    )
  }
}

export default withMobileDialog()(ArticlePostForm);
