import React, { Component } from 'react';
import { connect } from "react-redux";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import {getCustomFeeds, getAllCustomDiscovery, getAllDiscoveryState} from "../custom-feeds-actions";
import Table from "components/Table/Table.jsx";
import moment from 'moment';
import feedStyle from "../../../assets/jss/material-dashboard-pro-react/components/feedStyle.jsx";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from "components/CustomButtons/Button.jsx";
import InfiniteScroll from 'react-infinite-scroll-component';

class CustomFeedContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feed:[],
      allDiscoveryLinks: [],
      allDiscoveryState: [],
      selectedName: '',
      selectedState: '',
      formatTableData: [],
      start: 50,
      count: 50,
    }
  }
  fetchMoreData = () => {
    console.log("called");
    const { count, start } = this.state;
    console.log("ab >>>>>>>>", count, start);
    this.setState({ ...this.state, start: this.state.start + count });
    this.getFeed(this.state.selectedName, this.state.selectedState, start);
  };
  getTableData(data) {
    const tableData = [];
    data.forEach((obj, i) => {
      tableData.push(
        [
          obj.imageLink && <img width="100px" key={i} src={obj.imageLink} />,
          <span key={i} style={{fontWeight: 'bold'}}>{obj.title}</span>,
          obj.discoveryName,
          obj.website,
          obj.state,
          moment(obj.publishTime).format(),
          <a href={obj.link} target="_blank" key={i}>Link</a>
        ]
      );
    });
    return tableData;
  }
  getFeed(name='', state='', start) {
    let isTopsite = this.props.isTopsite ? 1 : 0;
    this.props.getCustomFeeds(name, state, isTopsite, start).then((d) => {
      this.setState({...this.state, feed: this.state.feed.concat(d), formatTableData: this.getTableData(this.state.feed.concat(d))});
    }).catch((e) => {
      console.log(">>>>>>>>>>>>>>>>>", e);
    });
  }
  onNameChange(e) {
    this.setState({...this.state, selectedName: e.target.value, selectedState: '', start: 50, feed: []});
    this.getFeed(e.target.value, '', 0);
  }
  onStateChange(e) {
    this.setState({...this.state, selectedState: e.target.value, selectedName: '', start: 50, feed: []});
    this.getFeed('', e.target.value, 0);
  }
  componentDidMount() {
    this.props.getAllCustomDiscovery(this.props.isTopsite).then((d) => {
      this.setState({...this.state, allDiscoveryLinks: d});
    }).catch((e) => {
      console.log("ERRor in getting all discovery links", e);
    });
    this.props.getAllDiscoveryState(this.props.isTopsite).then((d) => {
      this.setState({...this.state, allDiscoveryState: d});
    }).catch((e) => {
      console.log("ERRor in getting all discovery links", e);
    });
    this.getFeed();
  }
  reloadFeed(){
    let isTopsite = this.props.isTopsite ? 1 : 0;
    this.props.getCustomFeeds(this.state.selectedName, isTopsite).then((d) => {
      this.setState({...this.state, feed: d});
      alert("Reloaded");
    }).catch((e) => {
      console.log(">>>>>>>>>>>>>>>>>", e);
    });
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={6} md={6}>
          <Select
            value={this.state.selectedName}
            label="Filter by Name"
            onChange= {this.onNameChange.bind(this)}
          >
            <MenuItem value={''}>NO FILTER</MenuItem>
            {this.state.allDiscoveryLinks.map((obj, key) => {
              return <MenuItem key={key} value={obj.name}>{obj.name}</MenuItem>
            })}
          </Select>
        </GridItem>
        <GridItem xs={6} md={6}>
          <Select
            value={this.state.selectedState}
            label="Filter by Name"
            onChange= {this.onStateChange.bind(this)}
          >
            <MenuItem value={''}>NO FILTER</MenuItem>
            {this.state.allDiscoveryState.map((obj, key) => {
              return <MenuItem key={key} value={obj.state}>{obj.state}</MenuItem>
            })}
          </Select>
        </GridItem>
        <GridItem xs={6} md={6}>
          <Button color="secondary" size="lg" onClick={this.reloadFeed.bind(this)}>
            Reload Feed
          </Button>
        </GridItem>
        <GridItem xs={12} md={12}>
          { this.state.feed && !!this.state.feed.length &&
            <div id="scrollableDiv" style={{ height: 900, overflow: "auto" }}>
              <InfiniteScroll
                dataLength={this.state.feed.length}
                next={this.fetchMoreData.bind(this)}
                hasMore={true}
                loader={<h4>Loading...</h4>}
                scrollableTarget="scrollableDiv"
              >
                {<Table
                  tableHead={[
                    "image",
                    "title",
                    "name",
                    "website",
                    "state",
                    "publish time",
                    "link"
                  ]}
                  tableData={this.state.formatTableData}
                />}
              </InfiniteScroll>
            </div>
          }
        </GridItem>
      </GridContainer>
    )
  }
}

const mapStateToProps = (store) => {
  return {}
}
const mapDispatchToProps = (dispatch) => {
  return {
    getCustomFeeds: (name, state, isTopsite, start) => dispatch(getCustomFeeds(name, state, isTopsite, start)),
    getAllCustomDiscovery: (isTopsite) => dispatch(getAllCustomDiscovery(isTopsite)),
    getAllDiscoveryState: (isTopsite) => dispatch(getAllDiscoveryState(isTopsite)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(feedStyle)(CustomFeedContainer));
