
/* For adding new category make  category as a key
*/
const CATEGORY_PARAMS = {
    celebrity: {
        label: 'D.O.B',
        field: 'dob'
    },
    tv: {
        label: 'Launch Date',
        field: 'launchDate'
    },
    music: {
        label: 'Launch Date',
        field: 'launchDate'
    },
    OTHER: {
        label: 'date',
        field: 'date'
    },
}

export default CATEGORY_PARAMS;
