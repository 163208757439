import React, { PureComponent, Fragment } from "react";
import UserActionOptions from "../components/user-action-options.jsx";
import UserActionsButton from "../components/user-actions-button.jsx";
import { deleteDomainUser } from "../account-management-actions.js";
import { connect } from "react-redux";

class UserActions extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null
    }
  }

  deleteDomainUser = () => {
    const { userDetails, deleteDomainUser } = this.props;
    deleteDomainUser(userDetails);
  }

  showUserActions = event => {
    this.setState({
      anchorEl: event.currentTarget
    });
  }
  hideUserActions = event => {
    this.setState({ anchorEl: null });
  }

  render() {
    const { anchorEl } = this.state;
    const { userDetails } = this.props;
    return (
      <div style={{ justifyContent: "flex-end" }}>
        <UserActionsButton
          handleClick={this.showUserActions}
          open={!!anchorEl}
        />
        <UserActionOptions
          handleClose={this.hideUserActions}
          anchorEl={anchorEl}
          deleteDomainUser={this.deleteDomainUser}
          userDetails={userDetails}
        />
      </div>
    );
  }
}

const mapStateToPropsForUserDetails = state => {
  return {}
}
const mapDispatchToPropsForUserDetails = (dispatch) => {
  return {
    deleteDomainUser: (userDetails) => dispatch(deleteDomainUser(userDetails))
  }
}

export default connect(mapStateToPropsForUserDetails, mapDispatchToPropsForUserDetails)(UserActions);


