import React, { PureComponent, Fragment } from "react";
import AppLoader from "../../../app-components/app-loader";


import AddCircleIcon from "@material-ui/icons/AddCircle";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

class Disclaimers extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { disclaimerId: props.disclaimerId };
  }
  handleArticleTypeChange = (e) => {
    this.setState({ disclaimerId: e.target.value });
    this.props.setDisclaimerType(e.target.value)
  }

  render() {
    const {
      isDisclaimerLoading,
      disclaimers,


    } = this.props;
    const { disclaimerId } = this.state;

    if (isDisclaimerLoading) {
      return <AppLoader />
    }
    return (
      <div>
        <FormControl component="fieldset" >
          <RadioGroup
            aria-label="Disclaimers"
            name="disclaimer"
            value={disclaimerId == null ? "0" : disclaimerId.toString()}
            onChange={this.handleArticleTypeChange}
          >

            {
              disclaimers.map(({ id, title, description }) => {
                return (
                  <FormControlLabel
                    key={id}
                    value={id.toString()}
                    control={<Radio />}
                    label={<span style={{ display: "inline-block", marginTop: "10px", marginBottom: "10px" }}><strong>{title}: </strong>{description}</span>}
                  />

                )
              })
            }
            <FormControlLabel
              value={"0"}
              control={<Radio />}
              label={<span style={{ fontWeight: "bold" }}>None Of the Above</span>}
            />
          </RadioGroup>
        </FormControl>
      </div >
    )
  }
}

export default Disclaimers;