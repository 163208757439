import React from 'react';
//import Button from '@material-ui/core/Button';
import Button from "components/CustomButtons/Button";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Calendar } from 'react-date-range';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import moment from 'moment';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CATEGORY_PARAMS from '../../../utils/category-config.js';
import InformationTooltip from "../../../app-components/information-tooltip";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import {
  URL_VALIDATION,
  FACEBOOK_VALIDATION,
  TWITTER_VALIDATION,
  INSTA_VALIDATION
} from "../../../app-constants/common.js";

const getKeyCondition = (id, val) => {
  if (id == 'fbUrl') {
    return !FACEBOOK_VALIDATION.test(val)
  }
  if (id == 'twitterUrl') {
    return !TWITTER_VALIDATION.test(val)
  }
  if (id == 'instaUrl') {
    return !INSTA_VALIDATION.test(val)
  }
  if (id == 'heroImage') {
    return !URL_VALIDATION.test(val)
  }
  return false
}
const validation = (error = {}, id, val) => {
  if (id == 'displayName') {
    (val == '') ? error = { ...error, [id]: true } : error = { ...error, [id]: false }
    return { error, val }
  }
  if (getKeyCondition(id, val)) {
    if (val == "") {
      error = { ...error, [id]: false };
      val = null;
    }
    else {
      error = { ...error, [id]: true };
    }
  }
  else {
    error = { ...error, [id]: false };
  }
  return { error, val }
}
class LpDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      displayName: null,
      description: null,
      searchName: null,
      dob: null,
      fbUrl: null,
      twitterUrl: null,
      instaUrl: null,
      id: null,
      formatDate: null,
      heroImage: null,
      active: 1,
      error: {
        displayName: false,
        fbUrl: false,
        twitterUrl: false,
        instaUrl: false,
        heroImage: false
      }
    }
  }
  handleChange = event => {
    const { id, value } = event.target;
    const { val, error } = validation(this.state.error, id, value)
    this.setState({ ...this.state, [id]: val, error })
  }
  handleActiveChange = event => {
    const { name, value } = event.target;
    this.setState({ ...this.state, [name]: value })
  }
  componentWillReceiveProps(props) {
    if (props.cropedUrl) {
      this.setState({ ...this.state, heroImage: props.cropedUrl })
      return
    }
    const { lpData = {} } = props;
    const date = CATEGORY_PARAMS[props.subCategoryName] ? CATEGORY_PARAMS[props.subCategoryName]['field'] : 'date';
    const { id, displayName, description, searchName, fbUrl, twitterUrl, instaUrl, heroImage, active } = lpData;
    this.setState({ ...this.state, id, displayName, heroImage, description, searchName, ['dob']: (lpData[date] ? moment(lpData[date]) : null), fbUrl, twitterUrl, instaUrl, formatDate: `${lpData[date] ? moment(lpData[date]).format("YYYY-MM-DD") : ''}`, active })
  }
  onSubmit = () => {
    const { lpModalId, setLpEntry, categoryName, subCategoryName } = this.props;
    const date = CATEGORY_PARAMS[subCategoryName] ? CATEGORY_PARAMS[subCategoryName]['field'] : 'date';
    const { displayName, description, heroImage, active, dob, fbUrl, twitterUrl, instaUrl, searchName } = this.state;
    setLpEntry({ searchName, displayName, description, [date]: dob, fbUrl, twitterUrl, heroImage, instaUrl, active }, lpModalId)
  }
  handleSelect = (date) => {
    this.setState({ ...this.state, ['dob']: moment(date), ['formatDate']: `${moment(date).format("YYYY-MM-DD")}` }, () => {
      this.handleClose()
    })
  }
  openPiscoForAvator = () => {
    this.props.openPisco();
    setTimeout(() => {
      this.props.COPYCARDID();
      this.props.removeLpModal();
    }, 800)
  }
  closeLpModal = () => {
    this.setState({ id: null, displayName: null, heroImage: null, description: null, searchName: null, dob: null, fbUrl: null, twitterUrl: null, instaUrl: null, formatDate: null, active: 1 })
    this.props.reSetCropedUrl();
    this.props.removeLpModal();
  }
  isValid = () => {
    const { displayName, heroImage, fbUrl, twitterUrl, instaUrl } = this.state.error;
    if (!displayName && !heroImage && !fbUrl && !twitterUrl && !instaUrl) {
      return true
    }
    else {
      return false
    }
  }
  handleOpen = () => {
    this.setState({ ...this.state, open: true })
  }
  handleClose = () => {
    this.setState({ ...this.state, open: false })
  }
  render() {
    const { lpModalId, categoryName, subCategoryId, subCategoryName, openPisco, categories, lpData = {} } = this.props;
    const { displayName, id, description, heroImage, searchName, dob, fbUrl, twitterUrl, instaUrl } = this.state;
    let dateTooltip = "Select the date of birth for the profile.";
    //console.log(`cat`, categoryName);
    switch (categoryName) {
      case 'lifestyle': {
        dateTooltip = "Select the date.";
        break;
      }
      case 'movies': {
        dateTooltip = "Select the date.";
        break;
      }
      case 'music': {
        dateTooltip = "Select the date.";
        break;
      }
      case 'news': {
        dateTooltip = "Select the date.";
        break;
      }
      case 'tv': {
        dateTooltip = "Select the date.";
        break;
      }
      case 'celebrity': {
        dateTooltip = "Select the date of birth for the profile.";
        break;
      }
      default: dateTooltip = "Select the date.";
    }
    return (
      <div>
        <Dialog
          open={Boolean(lpModalId)}
          onClose={this.closeLpModal}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Edit {displayName || ''} </DialogTitle>
          <DialogContent>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormControl style={{ width: '50%', marginTop: '1%' }}>
                <InputLabel htmlFor="age-simple">Select Active/Inactive <InformationTooltip text="The status of the profile." /></InputLabel>
                <Select
                  value={`${this.state.active == 1 ? 'Active' : 'Inactive'}`}
                  renderValue={() => `${this.state.active == 1 ? 'Active' : 'Inactive'}`}
                  onChange={this.handleActiveChange}
                  inputProps={{
                    name: 'active',
                    id: 'active',
                  }}
                >
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={0}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <CustomInput
              required
              margin="dense"
              id="displayName"
              labelText="Name"
              infoText="Name for the profile."
              value={displayName || ''}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disableUnderline: true,
                onChange: this.handleChange,
              }}
              error={this.state.error.displayName}
              success={!!this.state.displayName && !this.state.error.displayName}
              helperText={this.state.error.displayName ? 'Empty name not allowed' : ''}
              fullWidth
            />
            <CustomInput
              required
              margin="normal"
              id="searchName"
              labelText="Search Term"
              infoText="Enter the search term for the pofile."
              value={searchName || ''}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: this.handleChange,
                disableUnderline: true
              }}
              error={this.state.error.searchName}
              success={!!this.state.searchName && !this.state.error.searchName}
              helperText={this.state.error.searchName ? 'Empty Search Term not allowed' : ''}
            />
            <CustomInput
              id="description"
              margin="normal"
              labelText="description"
              infoText="Add description for the profile."
              value={description || ''}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disableUnderline: true,
                onChange: this.handleChange,
                multiline: true,
                rowsMax: 15
              }}
              success={!!this.state.description && !this.state.error.description}
            />
            <CustomInput
              id="heroImage"
              labelText="Upload Profile Picture"
              infoText="upload the profile picture."
              value={heroImage || ''}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <AddCircleIcon style={{ color: "#2b2d42", cursor: 'pointer' }} onClick={this.openPiscoForAvator} />
                  </InputAdornment>
                ),
                onChange: this.handleChange,
              }}
              error={this.state.error.heroImage}
              success={!!this.state.heroImage && !this.state.error.heroImage}
              helperText={this.state.error.heroImage ? 'Invalid Image url' : ''}
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={!this.isValid()} onClick={this.onSubmit} color="primary">
              UPDATE
            </Button>
            <Button onClick={this.closeLpModal} color="secondary">
              CANCEL
            </Button>
          </DialogActions>
        </Dialog>
      </div >
    );
  }
}
export default LpDialog
