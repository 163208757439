const AppAction = {
  SET: {
    MESSAGE: "AppAction.Set.Message",
  },
  CLEAR: {
    MESSAGE: "AppAction.Clear.Message",
    ALL_MESSAGES: "AppAction.clear.AllMessages",
  },
  ADD: {
    NOTIFICATION: "AppAction.Set.Notification",
  },
  REMOVE: {
    NOTIFICATION: "AppAction.Remove.Notification",
    ALL_NOTIFICATIONS: "AppAction.Remove.AllNotifications",
  },
  SETWEBSITE_INFO: {
    SUCCESS: 'SetWebsiteInfo.Success.Info'
  }
}

export default AppAction;
