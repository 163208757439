import React, { PureComponent, Fragment } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import { setArticleId } from "../../article/article-action.js";
import { connect, } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import { ArticleTypes } from "app-constants/index.js";

const styles = themes => ({
  root: {
    "color": "red"
  }
})

const CreateStoryButton = ({ classes, openArticleModal }) => (
  <GridContainer className={classes.root}>
    <GridItem>
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer", margin: "20px 0 20px" }}>
        <AddCircleIcon />&nbsp;&nbsp;<span style={{ color: "#232323" }}>Upload Video</span>
      </div>
    </GridItem>
  </GridContainer>
);
var CreateStory = withStyles(styles)(CreateStoryButton);


const CustomDialogTitle = withStyles(theme => ({
  root: {
    // borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, closeArticleModal } = props;
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="subheading">
        <div style={{ display: "flex", justifyContent: "center", fontWeight: "bold" }}>
          {children}
        </div>

      </Typography>
      {closeArticleModal ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={closeArticleModal}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const articleTypeStyles = (theme) => {
  return {
    dialogPaper: {
      minWidth: "400px"
    },
  }
}

class ArticleTypeModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      articleType: ArticleTypes.NORMAL,
      showArticleTypeModal: props.showArticleTypeModal
    }
  }


  handleArticleTypeChange = (e) => {
    this.setState({ articleType: e.target.value });
  }

  createNewArticle = () => {
    this.props.setArticleId(undefined);
  }

  render() {
    const { classes, closeArticleModal } = this.props;
    const { articleType, showArticleTypeModal } = this.state;
    return (
      <Dialog
        open={true}
        fullWidth={true}
        // classes={{ paper: classes.dialogPaper }}
        onClose={this.hide}
        maxWidth="xs"
        aria-labelledby="customized-dialog-title"
      >
        <CustomDialogTitle
          id="customized-dialog-title"
          closeArticleModal={closeArticleModal}
        >
          Article Type
        </CustomDialogTitle>
        <DialogContent style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div>
            <FormControl component="fieldset" >
              <RadioGroup
                aria-label="Article-Type"
                name="articleType"
                value={articleType}
                onChange={this.handleArticleTypeChange}
              >
                <FormControlLabel
                  value={ArticleTypes.NORMAL}
                  control={<Radio />}
                  label={<span style={{ fontWeight: articleType === ArticleTypes.NORMAL ? "bold" : "normal" }}>Normal Article </span>}

                />
              </RadioGroup>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <Link to={{
            pathname: this.props.location.pathname + "/new-story",
            state: { contentType: articleType }
          }}>
            <Button onClick={this.createNewArticle} color="primary">
              Create Article
            </Button>
          </Link>

          <Button onClick={closeArticleModal} >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToPropsArticleType = (state) => {
  return {}

}
const mapDispatchToProps = (dispatch) => {
  return {
    setArticleId: (articleId) => dispatch(setArticleId(articleId))
  }
}
const ArticleType = compose(
  withRouter,
  connect(mapStateToPropsArticleType, mapDispatchToProps),
)(withStyles(articleTypeStyles)(ArticleTypeModal));

class CreateNewArticleContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showArticleTypeModal: false
    }
  }
  openArticleModal = () => {
    this.setState({ showArticleTypeModal: true })
  }
  closeArticleModal = () => {
    this.setState({ showArticleTypeModal: false })
  }

  render() {
    const { showArticleTypeModal } = this.state;
    return (
      <Fragment>
        <CreateStory openArticleModal={this.openArticleModal} />
        {showArticleTypeModal && <ArticleType closeArticleModal={this.closeArticleModal} />}
      </Fragment>
    )
  }
}


export default CreateNewArticleContainer;
