import React, { Component } from 'react';
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import instalogo from '../../assets/icons/insta.svg';
import twitterlogo from '../../assets/icons/twitter.svg';
import facebook from '../../assets/icons/facebook.svg';
import snapchatlogo from '../../assets/icons/snapchat.png';
import linkedinlogo from '../../assets/icons/linkedin.png';
import youtubelogo from '../../assets/icons/youtube.svg';
import pintrestlogo from '../../assets/icons/pinterest.svg';
import flipboardlogo from '../../assets/icons/flipboard.svg';

class SocialAccountForm extends Component {
  state = {
    fb: this.props.socialLink.fb_url,
    insta: this.props.socialLink.insta_url,
    twitter: this.props.socialLink.twitter_url,
    youtube: this.props.socialLink.yt_url,
    pinterest: this.props.socialLink.pintrest_url,
    linkedin: this.props.socialLink.linkedin_url,
    snapchat: this.props.socialLink.snapchat_url,
    flipboard: this.props.socialLink.flipboard_url,
  }
  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  }
  updateSocialAccount = () => {
    const {
      fb,
      insta,
      twitter,
      youtube,
      pinterest,
      linkedin,
      snapchat,
      flipboard } = this.state;
    this.props.SetSocialAccount({
      fb_url: fb,
      insta_url: insta,
      twitter_url: twitter,
      yt_url: youtube,
      pintrest_url: pinterest,
      linkedin_url: linkedin,
      snapchat_url: snapchat,
      flipboard_url: flipboard
    })
  }
  render() {
    const { fb, insta, twitter, pinterest, youtube, linkedin, snapchat, flipboard } = this.state;
    return (
      <>
        <div >
          <GridItem xs={12} sm={12} md={6} style={{ margin: 'auto' }}>
            <CustomInput
              labelText="Facebook"
              id="fb"
              value={fb}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={facebook} width='20px' />
                  </InputAdornment>
                ),
                onChange: this.handleChange,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ margin: 'auto' }}>
            <CustomInput
              labelText="Instagram"
              id="insta"
              value={insta}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={instalogo} width='20px' />
                  </InputAdornment>
                ),
                onChange: this.handleChange,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ margin: 'auto' }}>
            <CustomInput
              labelText="Twitter"
              id="twitter"
              value={twitter}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={twitterlogo} width='20px' />
                  </InputAdornment>
                ),
                onChange: this.handleChange,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ margin: 'auto' }}>
            <CustomInput
              labelText="Youtube"
              id="youtube"
              value={youtube}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={youtubelogo} width='34px' />
                  </InputAdornment>
                ),
                onChange: this.handleChange,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ margin: 'auto' }}>
            <CustomInput
              labelText="Pinterest"
              id="pinterest"
              value={pinterest}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={pintrestlogo} width='20px' />
                  </InputAdornment>
                ),
                onChange: this.handleChange,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ margin: 'auto' }}>
            <CustomInput
              labelText="LinkedIn"
              id="linkedin"
              value={linkedin}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={linkedinlogo} width="20px" />
                  </InputAdornment>
                ),
                onChange: this.handleChange,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ margin: 'auto' }}>
            <CustomInput
              labelText="Snapchat"
              id="snapchat"
              value={snapchat}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={snapchatlogo} width="20px" />
                  </InputAdornment>
                ),
                onChange: this.handleChange,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ margin: 'auto' }}>
            <CustomInput
              labelText="Flipboard"
              id="flipboard"
              value={flipboard}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={flipboardlogo} width='20px' />
                  </InputAdornment>
                ),
                onChange: this.handleChange,
              }}
            />
          </GridItem>
        </div>
        <Button onClick={this.updateSocialAccount} color="primary" style={{ margin: 'auto', display: 'block' }}> Save</Button>
      </>
    )
  }
}
export default SocialAccountForm;
