//pages
import React from 'react';
import ToolHomePage from "./tool-home.jsx";
import Apps from "../components/Icon/App.jsx";
import toolsIcon from "../assets/icons/tools-icon.png";
import EditerPickHomePage from "./editer-pick/editer-pick-home";
import LandingHomePage from "./landing-page/landing-page-home-page";
import LandingBodyHomePage from "./landing-page/landing-body-page-home-page";

const Icon = () => <Apps src={toolsIcon} />;

var toolRoutes = [
  {
    collapse: true,
    path: "/tools",
    name: "Tools",
    state: "openTools",
    icon: Icon,
    isRequiredAuth: true,
    component: ToolHomePage,
    requiredPermissions: ["CREATE:PUBLISH", "CREATE:WRITE", "CREATE:EDIT"],

    views: [
      {
        path: "/tools/landing-page",
        name: "Landing Page",
        mini: "LP",
        isRequiredAuth: true,
        component: LandingHomePage,
        requiredPermissions: ["CREATE:PUBLISH"],
        requiredFeaturePermision: 'LANDING_PAGE'
      },
      {
        path: "/tools/landing-page-body",
        name: "Landing Page Body",
        mini: "LPB",
        isRequiredAuth: true,
        component: LandingBodyHomePage,
        requiredPermissions: ["CREATE:PUBLISH"],
        requiredFeaturePermision: 'LANDING_PAGE'
      },
      {
        path: '/tools/custom-feeds',
        name: `Custom Feeds`,
        mini: "CF",
        isRequiredAuth: true,
        component: EditerPickHomePage,
        requiredPermissions: ["CREATE:PUBLISH"],
        requiredFeaturePermision: 'EDITOR_PICK'
      },
    ]
  },
];

export default toolRoutes;
