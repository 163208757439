
import React, { PureComponent } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import withStyles from "@material-ui/core/styles/withStyles";
import RenderContent from "./content-type";
import Paper from "@material-ui/core/Paper";
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';

class ContentFeed extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: true,
    }
  }

  setIdForMenu(e, id, Content) {
    this.props.setAnchorEl(e.currentTarget)
    this.props.setId(id, Content)
  }


  render() {
    const open = true;
    const {
      content: Content,
      title,
      thumbnail
    } = this.props;
    const { headerContent, headerType = "", articleId, status ,coverPicture =''} = Content;
    try {
      JSON.parse(headerContent);
    } catch (err) {
      return null;
    }
    if (!headerType) {
      return null;
    }
    return (

      <Paper style={{ width: "100%", position: 'relative', padding: '20px 0', borderTop: '1px solid #eeeeee', borderBottom: '1px solid #eeeeee' }}>
        <div style={{ display: 'flex', }}>
          <div style={{ width: isWidthDown('sm', this.props.width) ? '40%' : '50%', paddingRight: '15px' }}>
            {/* <p style={{marginTop:'-5px'}}><span style={{ paddingLeft:'5px',paddingRight:'5px', backgroundColor:'red', color:'white'}}>{status  === 4? 'Live':'Draft'}</span></p> */}
            <p style={{
              fontWeight: "bold",
              textAlign: "left",
              color: "#000000",
            }} >
              {title}
            </p>
          </div>
          
        { (headerType.match(/text\/.*/) && !coverPicture) ?<></>: <div style={{ borderRadius: '0px', width: isWidthDown('sm', this.props.width) ? '60%' : '50%', background: `${(headerType && headerType.match(/audio\/.*/)) ? 'white' : 'black'}`, height: `${(headerType && headerType.match(/audio\/.*/)) ? 'auto' : '160px'}` }}>
            <RenderContent content={headerContent} thumbnail={thumbnail} articleData={Content} contentType={headerType || ""} renderContentStyle={{ borderTopLeftRadius: 'inherit', borderTopRightRadius: 'inherit', objectFit: 'cover', height: '100%' }} isMobile = { isWidthDown('sm', this.props.width) ? true:false} />
          </div> }
          
          <div style={{
            position: "absolute",
            right: "7px",
            top: "25px",
            background: "white",
            borderRadius: "100px",
            width: "30px",
            height: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}>
            <IconButton
              aria-label="More"
              aria-owns={open ? 'long-menu' : undefined}
              aria-haspopup="true"
              onClick={(e) => { this.setIdForMenu(e, articleId, Content) }}
            >
              <MoreVertIcon />
            </IconButton>
          </div>
        </div >
      </Paper>
    )
  }
}
export default withWidth()(ContentFeed);
    // export default ContentFeed;
