import { RegisterUserAction, MessageTypes } from "../../app-constants/index.js";

import { MeawwApi } from "utils/api.js";
import { setAppMessage,clearAllAppMessages  } from "../../app-actions.js";

const registerNewUserRequest = () => {
  return {
    type: RegisterUserAction.REGISTER.NEW_USER.REQUEST
  };
};

const registerNewUserFailure = () => {
  return {
    type: RegisterUserAction.REGISTER.NEW_USER.FAILURE
  };
};

const registerNewUserSuccess = (data) => {
  return {
    type: RegisterUserAction.REGISTER.NEW_USER.SUCCESS,
    msg: data
  }
}

function clearAppMessage() {
  return (dispatch, getState) => {
    dispatch(clearAllAppMessages());
  }
}

function sendResetPasswordLink() {
  return (dispatch, getState) => {
    const { login: { user: { email } } } = getState();
    MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/user/password/forgot`, {
          email,
        },
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            dispatch(setAppMessage('Verification email sent.', { type: MessageTypes.SUCCESS }));
            return Promise.resolve(data);
          })
      });
  }
}

export const registerNewUser = ({ email, firstName, lastName }) => {
  return (dispatch, getState) => {
    const { login: { user: { activeDomain } } } = getState();

    dispatch(registerNewUserRequest());
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/acl/domain/${activeDomain}/add-user`, {
          email,
          firstName,
          lastName,
        }, (error, data) => {
            dispatch(registerNewUserFailure());
            if (error) {
              if (error.message == 'Verify your email to continue') {
                dispatch(setAppMessage('Please verify email to add new users.Click OKAY to get verification email.', { type: MessageTypes.ERROR, onOk: () => dispatch(sendResetPasswordLink()), onCancel: () => dispatch(clearAppMessage()) }));
              } else {
                dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              }
              return Promise.reject(error);
          }
          // dispatch(setAppMessage(data.message, {
          //   type: MessageTypes.SUCCESS,
          // }));
          dispatch(registerNewUserSuccess(data.message))
          return Promise.resolve(data);
        });
      });
  }
}

export function register(email, password) {
  return dispatch => {

  }
}
export function clearMsg() {
  return {
    type: RegisterUserAction.REGISTER.CLEAR
  }
}