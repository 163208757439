import React, { Component } from 'react';


class OwnSideBar extends Component {

  render() {
    return (
      <div>
        <input type='range' max="100" min="0" style={{width:'100%'}}/>
      </div>
    )
  }
}
export default OwnSideBar;