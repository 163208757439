import React,  { PureComponent, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles, Typography, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import TSchedule from '../../../scheduler/twitter/component/schedule';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
//import Button from '@material-ui/core/Button';
import Button from "components/CustomButtons/Button.jsx";

import { connect } from 'react-redux';
import moment from "moment";
import { scheduleTweet,getAccount } from "../../../scheduler/schedule-action.js";

const CustomDialogTitle = withStyles(theme => ({
  root: {
    // borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, toggleScheduleTwitterArticleModal } = props;
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="subheading">
        <div style={{ justifyContent: "center", fontWeight: "bold" }}>
          {children}
        </div>
      </Typography>
      <IconButton aria-label="Close" className={classes.closeButton} onClick={() => {
        toggleScheduleTwitterArticleModal(false);
      }}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
});

class scheduleTwitterLiveArticle extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      showToolTip:false,
      articleCoverPicture: '',
      rawCoverPictureUrl: '',
      error: '',
      users: [],
      open: false,
      desc: '',
    };
    this.getSumittedData = this.getSumittedData.bind(this);
  }

  componentDidMount () {
    this.getAcount();
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  getAcount() {
    this.props.getAccount().then(data => {
      if (data && data.length > 0) {
        this.setState({ users: data , render: true });
      } else {
        this.setState({ render: true });
      }
    }, _ => {
      alert(_);
      // Show error Message to the user, I will be adding it later
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.scheduleTwitterArticleData.coverPicture !== this.state.rawCoverPictureUrl) {
      if (typeof nextProps.scheduleTwitterArticleData.coverPicture !== 'undefined') {
        this.setState({
          articleCoverPicture: `${nextProps.scheduleTwitterArticleData.coverPicture}_450_250${nextProps.scheduleTwitterArticleData.imgExtension}`,
          rawCoverPictureUrl: nextProps.scheduleTwitterArticleData.coverPicture,
        });
      }
    }
  }

  copyToClipboard = (scheduleArticleData) => {
    navigator.clipboard.writeText(scheduleArticleData && scheduleArticleData.url || "url not present.")
      .then(() => {
        this.setState({ showToolTip: true });
        setTimeout(() => {
          this.setState({ showToolTip: false });
        }, 1500);
    })
    .catch(err => {
      console.error('Could not copy text: ', err);
    });
  };
  getSumittedData(data) {
    let token = {};
    if(data.time && (data.content || data.link)) {
    this.state.users.forEach(obj=>obj.screen_name === data.pageName ? token = obj : false);
    //alert(+moment(data.time));
    const utmLink = `${data.content} ${data.link}`;
    this.props.scheduleTweet({ message: utmLink, date: +moment(data.time),
      username: token.screen_name, token: token.oauth_token, tokenSecret: token.oauth_token_secret }).then(res => {
      this.setState({desc: "Tweet got Scheduled",open: true});
      this.props.toggleScheduleTwitterArticleModal(false);
    }, _ => {
      this.setState({error: _.err,desc: "Schedule Failed",open: true});
    });
    } else {
      this.setState({desc: "Please Enter/Select Necessary Fields",open: true});
    }
  }
  render() {
    const {
      showScheduleTwitterArticleModal,
      toggleScheduleTwitterArticleModal,
      scheduleTwitterArticleData,
    } = this.props;
    const postCaption = [`Post Caption`, `${this.props.scheduleTwitterArticleData.postCaption}`];
    const ogTitle = [`Og Title`, `${this.props.scheduleTwitterArticleData.ogTitle}`];
    const data = [postCaption, ogTitle];
    const link = `${this.props.scheduleTwitterArticleData.postCaption || this.props.scheduleTwitterArticleData.ogTitle || ''} ${this.props.scheduleTwitterArticleData.url}`.trim();
    return (
      <Dialog
        id="scheduleContainer"
        open={showScheduleTwitterArticleModal}
        onClose={this.hide}
        fullWidth
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
        style={{margin: '-35px', zIndex: '10001'}}
      >
        <CustomDialogTitle toggleScheduleTwitterArticleModal={toggleScheduleTwitterArticleModal}>Schedule Twitter Article</CustomDialogTitle>
        <DialogContent style={{display:"flex"}}>
          <GridContainer>
            <GridItem xs={12}>
              <Dialog
                fullWidth={true}
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{"Schedule Tweets"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {this.state.desc}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose} color="primary">
                    OKAY
                  </Button>
                </DialogActions>
              </Dialog>
              {this.state.articleCoverPicture && this.state.articleCoverPicture.length ? <img style={{width: '100%'}} src={this.state.articleCoverPicture} /> : '' }
              <Table
                tableHeaderColor="primary"
                tableData={data}
                coloredColls={[3]}
                colorsColls={["primary"]}
              />
              <TSchedule link={link} formData={this.getSumittedData} users={this.state.users} disableLink={true}/>
              <div style={{'color': 'red'}}>{this.state.error}</div>
           </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = (store) => {
  return {};
}
const mapDispatchToProps = (dispatch) => {
  return {
    scheduleTweet: (data) => dispatch(scheduleTweet(data)),
    getAccount: () => dispatch(getAccount()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(scheduleTwitterLiveArticle);
