import React from "react";
import { connect } from "react-redux";
import ContentFeedView from "./content-feed-view";
import PostsView from "./post-view";
import StoriesView from "./stories-views";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Paper from "@material-ui/core/Paper";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';

const QuickCMSView = (props) => {
  return (
    <Card style={{ marginTop: '-15px', border: '1px solid #CED3DC', boxShadow: 'none', borderRadius: '2px' }}>
      <p id="createworkspaceyourwebsite" style={{ margin: '10px auto auto 18px', textTransform: "uppercase" }}>
        Your website :  <a href={`\/\/${props.getDomainName()}`} target="_blank" style={{  fontWeight: '500', textTransform: 'lowercase', textDecoration:"underline" }}>
          {props.getDomainName()}
        </a>
      </p>
      <CardBody style={{ padding: '0 20px' }}>
        <GridContainer >
          <GridItem xs={12} md={8}>
            <GridContainer>
              {
                props.mobile
                  ?
                  <GridItem xs={12} md={4} >
                    <div style={{ padding: '10px 10px 0 0', marginBottom: '-30px' }}>
                      <StoriesView />
                    </div>
                  </GridItem>
                  :
                  <></>
              }
              <GridItem xs={12} style={{ marginTop: '45px' }}>
                <div style={{ height: '90px' }}>
                  <PostsView />
                </div>
              </GridItem>
              {
                <GridItem xs={12}>
                    <div>
                      <div style={{ padding: '10px 10px 0 0' }}>
                        <ContentFeedView />
                      </div>
                    </div>
                  </GridItem>
              }
            </GridContainer>
          </GridItem>
          {
            props.mobile
              ?
              <></>
              :
              <GridItem xs={12} md={4} >
                <div style={{ padding: props.mobile ? '10px 10px 0 0 ' : '10px 10px 0 35px' }}>
                  <StoriesView />
                </div>
              </GridItem>
          }
        </GridContainer>
      </CardBody>
    </Card>
  )
}

class QuickCmsView extends React.Component {
  getDomainName = () => {
    const { domainMap, activeDomain } = this.props;
    let domainName = '';
    try {
      domainName = domainMap[activeDomain][0]['fqdn']
    }
    catch (err) {
      domainName = ''
    }
    return domainName;
  }
  render() {
    return (
      <>
        <GridContainer spacing={16}>
          {/* <GridItem xs={false} md={1} style={{ marginTop: '5px', textAlign: "center" }} >
          </GridItem> */}
          {
            isWidthDown('sm', this.props.width) ? <QuickCMSView getDomainName={this.getDomainName} mobile={true} />
              : (<>
                <GridItem xs={false} md={false}></GridItem>
                <GridItem xs={12} md={12} style={{ marginTop: '50px' }}>
                  <QuickCMSView getDomainName={this.getDomainName} mobile={false} />
                </GridItem>
              </>)
          }
          <GridItem xs={false} md={false} style={{ marginTop: '5px', textAlign: "center" }} >
          </GridItem>
        </GridContainer>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const { activeDomain, domainMap } = state.login.user;
  return {
    ...state.stories,
    activeDomain: activeDomain,
    domainMap
  }
}

const mapDispatchToProps = (dispatch) => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withWidth()(QuickCmsView));
