import React from 'react';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Assignment from "@material-ui/icons/Assignment";
import Button from "components/CustomButtons/Button.jsx";

import AppLoader from "../../../app-components/app-loader.jsx";

function PostsTable(props){
  const { table, disableButtons } = props;
  const tableName = Object.keys(table)[0];
  const tableData = Object.values(table)[0];
  const prevKey = Object.values(table)[1].previous;
  const nextKey = Object.values(table)[1].next;

  return (
    <>
    <GridContainer justify="flex-start">
      <GridItem xs={12} sm={12} md={12} style={{marginTop:'20px', border:'1px solid #ced3dc'}}>
        <div>
          <CardHeader color="primary" icon>
            <h4 style={{ 'color': '#000', marginTop: '20px' }}>Posts scheduled on
              <span style={{color: '#2B2D42', fontWeight:"bold"}}> {tableName}</span>
            </h4>
            {/*
              this.state.tableData.length > 0 && <div style={{ float: 'right' }}>
                <CustomDropdown
                  buttonText="SORT BY"
                  dropdownList={this.state.sortArr}
                  onClick={this.changeSortMethod.bind(null)}
                />
              </div>

            */}
          </CardHeader>
          <CardBody>
            <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', textAlign: "center" }}>
              <Button
                style={{ marginRight: "10px", backgroundColor: '#fb4d24', display: prevKey===undefined?'none':'inline' }}
                disabled={disableButtons}
                onClick={()=>{props.newPage(tableName, `before`, prevKey)}}
              >
                prev
              </Button>
              <Button
                style={{ backgroundColor: '#fb4d24', display: nextKey===undefined?'none':'inline' }}
                disabled={disableButtons}
                onClick={()=>{props.newPage(tableName, `after`, nextKey)}}
              >
                next
              </Button>
                {/* disableButtons && <div style={{position: 'absolute', right: '0'}}><AppLoader size={20} /></div> */}
            </div >
            {/*
              this.state.tableData.length > 0 && <p>
              Sorting by <b>{this.state.dropDownMessage}</b>
            </p>
            */
            }
            {tableData.length > 0 ? (
              <Table
                tableHead={[
                  `Content`,
                  "Caption",
                  "Time",
                  "Action"
                ]}
                tableData={tableData}
              />
            ) : ''}
          </CardBody>
        </div>
      </GridItem>

    </GridContainer>
    </>
  )
}

export default PostsTable;
