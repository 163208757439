import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import RevenuePaper from "../components/revenue-paper.jsx";
import { fetchFixedTimeSpecificRevenues } from "../revenue-actions.js";


class FixedTimeSpecificRevenues extends PureComponent {
  componentDidMount() {
    const { fetchFixedTimeSpecificRevenues } = this.props;
    fetchFixedTimeSpecificRevenues();
  }
  componentWillReceiveProps({ activeDomain }) {
    const { fetchFixedTimeSpecificRevenues, activeDomain: prevActiveDomain } = this.props;
    if (activeDomain !== prevActiveDomain) {
      fetchFixedTimeSpecificRevenues();
    }
  }
  render() {
    const { fixedTimeSpecificRevenues, isLoadingRevenues } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4} lg={4}>
          <RevenuePaper header="Yesterdays’s Revenue" isLoadingRevenues={isLoadingRevenues} data={fixedTimeSpecificRevenues.yesterday} />
        </GridItem>
        <GridItem xs={12} sm={6} md={4} lg={4}>
          <RevenuePaper header="Last week’s Revenue" isLoadingRevenues={isLoadingRevenues} data={fixedTimeSpecificRevenues.lastw} />
        </GridItem>
        <GridItem xs={12} sm={6} md={4} lg={4}>
          <RevenuePaper header="Last month’s Revenue" isLoadingRevenues={isLoadingRevenues} data={fixedTimeSpecificRevenues.lastm} />
        </GridItem>
      </GridContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.analysis.revenue.fixedTimeSpecificRevenues,
    activeDomain: state.login.user.activeDomain
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFixedTimeSpecificRevenues: () => dispatch(fetchFixedTimeSpecificRevenues()),
  }
}
FixedTimeSpecificRevenues.propTypes = {
  activeDomain: PropTypes.number,
  fixedTimeSpecificRevenues: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(FixedTimeSpecificRevenues);


