import React, { PureComponent } from "react";
import { clearAppMessage } from "./app-actions.js";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import withStyles from "@material-ui/core/styles/withStyles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Icon from "@material-ui/core/Icon";
import Button from "components/CustomButtons/Button.jsx";

import { MessageTypes } from "./app-constants/index.js";
import { DialogContentText } from "@material-ui/core";

function Transition(props) {
  return <Slide direction="down" {...props} />
}

const theme = {
  dialogTitle: {
    color: '#2b2d42',
    fontFamily: 'SourceSansPro',
    fontSize: '16px',
    textAlign: 'center',
    fontWeight: 'normal',
  },
  iconStyle: {
    textAlign: "center",
    position: "relative",
    top: "3px"
  },
  errorIconColor: {
    color: "red"
  },
  successIconColor: {
    color: "green"
  },
  infoIconColor: {
    color: "grey"
  }
  ,
  meaDialogContainer: {
    // backgroundColor: '#2b2d42',
    boxShadow: 'none',
    borderRadius: 'none !important',
    // opacity: '0.7',
  },
  meaDialog: {
    backgroundColor: '#ffffff',
    boxShadow: 'none',
    borderRadius: '0px',
    opacity: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '290px'
  }
};

class AppMessages extends PureComponent {
  constructor(props) {
    super(props);
  }
  getIcon = (type, classes) => {
    switch (type) {
      case MessageTypes.ERROR:
        return (<Icon className={`${classes.iconStyle} ${classes.errorIconColor}`}>error</Icon>)
      case MessageTypes.SUCCESS:
        return (<Icon className={`${classes.iconStyle} ${classes.successIconColor}`}>check_circle</Icon>)
      case MessageTypes.INFO:
        return (<Icon className={`${classes.iconStyle} ${classes.infoIconColor}`}>info</Icon>)
      default:
        return null;
    }
  }
  getParticularMessage = () => {
    const { messages } = this.props;
    const message = messages && messages.length ? messages[messages.length - 1] : null;
    return message;
  }
  onOkHandler = () => {
    const { onAcknowledgedAppMessage } = this.props;
    const { onOk, id } = this.getParticularMessage();
    const message = this.getParticularMessage();
    if (parseInt(message.message) == 401) {
      window.location = "/login-home/login"
      return;
    }
    if (onOk) {
      onOk();
    }
    onAcknowledgedAppMessage(id);
  }
  onCancelHandler = () => {
    const { onAcknowledgedAppMessage } = this.props;
    const { onCancel, id } = this.getParticularMessage();
    onCancel();
    onAcknowledgedAppMessage(id);
  }

  render() {
    const { classes } = this.props;
    const message = this.getParticularMessage();
    if (!message) {
      return null;
    }
    let messageText = message.message;
    if (parseInt(messageText) == 401) {
      messageText = "User is not logged in, redirecting to login page"
    }
    if (parseInt(messageText) == 426) {
      messageText = "You are using an old version of Pubninja dashboard. Please refresh the page to get the latest version."
    }
    const { onCancel = null, type } = message || {};
    const showOnCancelMessage = typeof onCancel === "function";
    return (
      <Dialog
        id="meassageContainer"
        open={messageText !== null}
        TransitionComponent={Transition}
        keepMounted
        maxWidth={"xs"}
        fullWidth={true}
        classes={
          {
            root: classes.meaDialogContainer,
            paper: classes.meaDialog
          }
        }
      >
        <DialogTitle
          disableTypography={true}
          style={{ paddingBottom: '0px' }}
        >
          {/* {this.getIcon(type, classes)} */}
          <h2 className={classes.dialogTitle} style={{fontSize: '2em'}}>{type}</h2>
          <div style={{ width: '50px', borderTop: 'solid 1px #cecece', margin: '0 auto 25px'}}></div>
        </DialogTitle>

        <DialogContent>
          {
            typeof messageText == 'string' ? <DialogContentText
              style={{
                fontFamily: 'SourceSansPro',
                color: '#2b2d42',
                textAlign: 'center',
              }} dangerouslySetInnerHTML={{ __html: messageText }} />
              : <DialogContentText style={{
                fontFamily: 'SourceSansPro',
                color: '#2b2d42',
                textAlign: 'center',
              }}>
                {messageText}
              </DialogContentText>
          }

        </DialogContent>
        <DialogActions style={{ paddingBottom: '10px' }}>
          {
            showOnCancelMessage ? <Button key="cancel" color='secondary' onClick={this.onCancelHandler}>Cancel</Button> : null
          }
          <Button key="ok" color='secondary' onClick={this.onOkHandler}>OKAY</Button>
        </DialogActions>
      </Dialog >
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('state.app.messages', state.app.messages)
  return {
    messages: state.app.messages,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onAcknowledgedAppMessage: id => dispatch(clearAppMessage(id))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(theme)(AppMessages));
