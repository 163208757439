import React, { PureComponent, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Pisco from "pisco-ui";
// import "pisco-ui/lib/styles.css";


class PiscoHomePage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isPaneOpen: false,
      isPaneOpenLeft: false,
      isOpen:true,
    }
  }
  _hidePiscoModal = () => {
    this.setState({
      isOpen:false,
      activePiscoContent: undefined
    });

  }

  render() {
    const {
      activeDomain,
    } = this.props;

    let piscoType = "Image";
    if (activeDomain == 15 || activeDomain == 107) {
      piscoType = "Both"
    }
    return (
      <Fragment>
        <div id="pisco-video-container">
        <Pisco
            endpoint={process.env.REACT_APP_API_SERVER}
            headers={{
              authorization: localStorage.getItem('accessToken')
            }
            }
            injectQuery={{ domainId: activeDomain }}
            disableGetty={activeDomain !== 15}
            type={piscoType}
            mode="repo"
          />
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  const {
    cms: {
      articleSettings: {
        articleId
      },
      newStory: {
        showPiscoModal,
        activePiscoContentType
      }
    },
    login: { user }
  } = state;
  return {
    activeDomain: user.activeDomain
  }
}

const mapDispatchToProps = dispatch => ({


})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PiscoHomePage));
