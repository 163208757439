import React, { PureComponent } from 'react';

class ActiveStatus extends PureComponent {
  render() {
    const { text, color } = this.props;
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <span style={{ marginLeft: '15px', width: '9px', borderRadius: '50px', height: '9px', background: `${color}`, fontSize: '10px' }}></span>
        <span style={{ paddingLeft: '5px', paddingRight: '10px', color: 'black' }}>{text}</span>
      </div>
    )
  }
}
export default ActiveStatus;