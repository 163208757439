import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import SidePane from "react-sliding-pane";
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import {
  liveSearch,
  resetSearchArticle,
  getArticles,
  resetCurrentPage,
  hideLiveTab
} from '../articles-actions.js';
import { ArticlesState } from "../../../app-constants/index.js";
import ViewArticles from './view-articles';
import loadArticleCategories from '../../article/article-action.js';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import searchStyle from "../../../assets/jss/material-dashboard-pro-react/views/searchStyle.jsx";
import ArticleActionOptions from "../../../utils/article-action.js";
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';

function formatDateDisplay(startDate, endDate, defaultText) {
  let date = "";
  if (!startDate) {
    date = defaultText;
  } else {
    date = startDate.format("Do[, ]MMM[ -- ]")
  }
  if (!endDate) {
    date += defaultText;
  } else {
    date += endDate.format("Do[, ]MMM")
  }
  return date;
}
class LiveSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formatDate: '',
      showArticle: false,
      isPaneOpen: this.props.showSearch,
      showCalendar: false,
      categoryValue: [],
      subCategoryValue: [],
      checkedSubCategory: [],
      author: '',
      select: {
        endDate: null,
        startDate: null,
        key: 'selection',
      }
    }
  }
  componentDidMount() {
    setTimeout(() => {
      this.props.hideLiveTab()
      this.props.resetSearchArticle()
      this.setState({ ...this.state, showArticle: true })
    }, 1000)
    this.props.loadArticleCategories();
  }
  openCalendar = () => {
    this.setState({
      showCalendar: !this.state.showCalendar
    })
  }
  getValueFromInput = (e) => {
    const search = document.querySelector('#liveArticleSearch');
    const category = this.state.categoryValue.map((val) => val.id)
    const subcategory = this.state.checkedSubCategory.map((val) => val.id)
    const author = this.state.author;
    const searchKeyword = search && search.value;
    const { startDate, endDate } = this.state.select;
    let liveDate = {
      "min": moment(startDate).format("YYYY-MM-DD"),
      "max": moment(endDate).format("YYYY-MM-DD")
    }
    if (!startDate && !endDate) {
      liveDate = undefined
    }
    this.props.liveSearch(searchKeyword, category, subcategory, author.trim(), liveDate);
  }
  _handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.getValueFromInput(e)
    }
  }
  handleChange = (e) => {
    const subCategory = e.target.value.reduce((a, b) => {
      const { yCategory } = b
      a = a.concat(yCategory)
      return a
    }, [])

    this.setState({ ...this.state, categoryValue: e.target.value, ['subCategoryValue']: subCategory, ['checkedSubCategory']: subCategory });
  }
  handleSubChange = (e) => {
    this.setState({ ...this.state, ['checkedSubCategory']: e.target.value });
  }
  authorChange = (e) => {
    this.setState({ ...this.state, author: e.target.value });
  }
  handleSelect = (range) => {
    const formatDate = formatDateDisplay(moment(range.selection.startDate), moment(range.selection.endDate));
    this.setState({ ...this.state, ["select"]: range.selection, formatDate });
  }
  reSetDate = () => {
    this.setState({
      ...this.state, ["select"]: {
        endDate: null,
        startDate: null,
        key: 'selection',
      },
      ['formatDate']: ''
    });
  }
  componentWillUnmount() {
    this.props.getArticles()
  }

  render() {
    const {
      categories = [],
      getArticles,
      resetSearchArticle,
      setShowSearch,
      resetCurrentPage,
      classes,
    } = this.props;

    const { subCategoryValue, checkedSubCategory } = this.state;
    return (
      <SidePane
        isOpen={this.state.isPaneOpen}
        title='Search'
        subtitle='Live Articles Search'
        from='right'
        width={isWidthDown('sm', this.props.width)?"100%":"80%"}
        onRequestClose={() => {
          // triggered on "<" on left top click or on outside click
          resetSearchArticle()
          resetCurrentPage()
          setShowSearch(false)
          getArticles()
          this.setState({ ...this.state, isPaneOpen: false });
        }}
      >
        <div style={{ display: "flex", justifyContent: 'flex-end', paddingRight: '10%', width: '100%' }}>
          <div style={{ display: "flex", justifyContent: 'flex-end' }}>
            <GridContainer justify="space-between">
              <GridItem xs={5} sm={2}>
                <TextField
                  style={{ marginTop: '0px', width: '120px' }}
                  label="Author"
                  type="search"
                  margin="normal"
                  onChange={this.authorChange}
                />
              </GridItem>
              <GridItem xs={5} sm={2} style={{display: 'flex'}}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="demo-controlled-open-select">Calendar</InputLabel>
                  <Select
                    value={`${this.state.formatDate}`}
                    renderValue={() => `${this.state.formatDate}`}
                  >
                    <DateRange
                      ranges={[this.state.select]}
                      onChange={this.handleSelect}
                      maxDate={moment().toDate()}
                      minDate={moment("20180401", "YYYYMMDD").toDate()}
                    />
                  </Select>
                </FormControl>
                <div style={{ alignSelf: 'flex-end', marginBottom: '7px'}}>
                  <i className="material-icons" style={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => { this.reSetDate() }}>
                    clear
                </i>
                </div>
              </GridItem>
              <GridItem xs={5} sm={2}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="demo-controlled-open-select">Category</InputLabel>
                  <Select
                    multiple
                    value={this.state.categoryValue}
                    renderValue={selected => selected.map(({ category }) => category).join(', ')}
                    onChange={this.handleChange}
                  >
                    {
                      categories.map((category, id) => (
                        <MenuItem key={id} value={category}>
                          <Checkbox

                            checked={this.state.categoryValue.indexOf(category) > -1} />
                          <ListItemText primary={category.category} />
                        </MenuItem>))
                    }
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={5} sm={2}>
                <FormControl className={classes.formControl} style={{width: '100%', overflow: 'scroll'}}>
                  <InputLabel htmlFor="demo-controlled-open-select">Subcategory</InputLabel>
                  <Select
                    multiple
                    value={checkedSubCategory}
                    renderValue={selected => selected.map(({ category }) => category).join(', ')}
                    onChange={this.handleSubChange}
                  >
                    {
                      subCategoryValue.map((category, id) => (
                        <MenuItem key={category.id} value={category}>
                          <Checkbox

                            checked={checkedSubCategory.indexOf(category) > -1}
                          />
                          <ListItemText primary={category.category} />
                        </MenuItem>))
                    }
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
          </div>
        </div>

        <div style={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center", flexDirection: 'column' }}>
          <div style={{ position: 'relative', marginTop: "15px", width: '80%', minWidth: '300px' }}>
            <input
              id="liveArticleSearch"
              placeholder="Search article title, keywords..."
              onKeyPress={(e) => { this._handleKeyPress(e) }}
              style={{
                width: "100%",
                height: "46px",
                borderRadius: "5px",
                border: "none",
                backgroundColor: "#f5f5f5",
                paddingLeft: "10px",
                fontSize: "16px",
                border: '1px solid #e7e7e7',
                boxShadow: " 0 1px 8px 0 rgba(0, 0, 0, 0.06)"
              }} />
            <div style={{ position: "absolute", right: '15px', top: '15px', cursor: "pointer" }}>
              <i className="material-icons" onClick={(e) => { this.getValueFromInput(e) }}>
                search
            </i>
            </div>
          </div>
          <div id="searchMainPanel" ref="searchMainPanel" style={{ marginLeft: '50px', marginRight: '50px', marginTop: '15px' }}>
            {
              this.state.showArticle && <ViewArticles isLiveSearch={true} liveSearch={this.getValueFromInput} options={ArticleActionOptions.Live} articleLetter="L" articleState={ArticlesState.LIVE_ARTICLES} />
            }
          </div>
        </div>
      </SidePane >
    )
  }
}
const mapStateToProps = (state) => {
  const { categories } = state.cms.articleSettings;
  return {
    categories
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    hideLiveTab: () => dispatch(hideLiveTab()),
    getArticles: () => dispatch(getArticles(ArticlesState.LIVE_ARTICLES)),
    resetCurrentPage: () => dispatch(resetCurrentPage(ArticlesState.LIVE_ARTICLES)),
    resetSearchArticle: () => dispatch(resetSearchArticle()),
    loadArticleCategories: () => dispatch(loadArticleCategories()),
    liveSearch: (searchKeyword, category, subcategory, author, liveDate) => dispatch(liveSearch(searchKeyword, category, subcategory, author, liveDate))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(searchStyle)(withWidth()(LiveSearch)));
