import React from 'react';
import Button from "components/CustomButtons/Button.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


class CSVFakePreview extends React.Component {

  render() {
    const { openinfoModal, closeInfoModal, renderData } = this.props;
    return (
      <>
        <Dialog
          fullWidth
          maxWidth='md'
          id="news-letter-modal"
          open={openinfoModal}
          onClose={closeInfoModal}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title"> CSV Preview Example </DialogTitle>
          <DialogContent>
            <Table style={{ minWidth: '650px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>email</TableCell>
                  <TableCell align="right">firstName</TableCell>
                  <TableCell align="right">lastName</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  renderData.map((ele, key) => {
                    return (
                      <TableRow key={key}>
                        <TableCell align="right">{ele[0]}</TableCell>
                        <TableCell align="right">{ele[1]}</TableCell>
                        <TableCell align="right">{ele[2]}</TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeInfoModal} color="secondary">
                CANCEL
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );

  }
}
export default CSVFakePreview;

