import React from "react";
import { connect } from "react-redux";
import { removeUser, updateUser, verifyUser, getAccount } from "../schedule-action";
import PostOnPage from "./component/schedule-on-page";
import ListPost from "./component/show-schedule-post";
import TwitterLogin from "react-twitter-auth";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import AppLoader from "app-components/app-loader.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const loginUrl = `${process.env.REACT_APP_API_SERVER}/schedule/auth/twitter`;
const requestTokenUrl = `${process.env.REACT_APP_API_SERVER}/schedule/15/auth/twitter/reverse`;

class Twitter extends React.Component {
  constructor (props) {
    super(props);
    this.state = { verify: false, isAuthenticated: false,users: [], user: "", token: "", message: "", render: false,tweets:'',open: false };
    this.putUser = this.putUser.bind(this);
    this.removeUser  = this.removeUser.bind(this);
  }

  onSuccess = (response) => {
      response.json().then(user => {
        if(user){
          this.setState({ isAuthenticated: true, verify: true, render: true });
          this.putUser(user);
          this.getAcount();
        } else {
          this.setState({ open: true });
        }
      });
    // }
  };

  onFailed = (error) => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };


  componentDidMount () {
    this.setState({ isContinue: false});
    this.getAcount();
  }

  getAcount() {
    this.props.getAccount().then(data => {
      if (data && data.length > 0) {
        this.setState({ verify: true, users: data, isAuthenticated: true, render: true });
      } else {
        this.setState({ isAuthenticated: false, verify: false, render: false });
        this.setState({ render: true });
      }
    }, _ => {
      // alert(_);
      // Show error Message to the user, I will be adding it later
    });
  };

  verifyUser() {
    this.props.verifyUser().then(data => {
      if (data.length > 0) {
        this.setState({ verify: true, user: data[0], isAuthenticated: true, render: true });
      } else {
        this.setState({ render: true });
      }
    }, _ => {
      alert(_);
      // Show error Message to the user, I will be adding it later
    });
  };

  putUser (user) {
    this.props.updateUser(user).then(data => {
    }, _ => {
      alert(_);
      // Show error Message to the user, I will be adding it later
    });
  }

  removeUser (data) {
    this.props.removeUser(data).then(data => {
    }, _ => {
      alert(_);
      // Show error Message to the user, I will be adding it later
    });
  }

  logout(e) {
    e.preventDefault();
    this.removeUser(this.state.users[e.target.id].id);
    // this.setState({isContinue: false, user: '', users: []});
  }

  continue(e) {
    e.preventDefault();
    this.setState({isContinue: true, user: this.state.users[e.target.id]})
  }



  render () {
    let dialog = <Dialog
      open={this.state.open}
      onClose={this.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{"Authentication Failed"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        Duplicate user/Adding Account failed
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.handleClose} color="primary">
          Okay
        </Button>
      </DialogActions>
    </Dialog>;
    let empty = <GridContainer justify="flex-start">
      <CardBody>
        {dialog}
        {/*<Card>*/}
        <div style={{fontSize: '1.3em', paddingTop:'10px', paddingBottom:'10px', textAlign:'center', margin:'20px', lineHeight:"2", border:'1px solid #ced3dc'}}>
          There are no active Twitter pages.<br/>You  can add a Twitter page by going to the <span style={{color: '#2b2d42', fontWeight:"bold"}}>Settings > Manage Social Accounts</span> Section
        </div>
        {/*
          <TwitterLogin loginUrl={loginUrl}
                            onFailure={this.onFailed} onSuccess={this.onSuccess}
                            requestTokenUrl={requestTokenUrl}/>
        */}
        {/*</Card>*/}
      </CardBody>
    </GridContainer>;

    if (this.state.users && this.state.isAuthenticated ) {
      let  main = (<GridContainer justify="flex-start">
        <GridItem xs={12} md={12}>
        <CardBody>
          {dialog}
          {/*
            <TwitterLogin loginUrl={loginUrl}
                          onFailure={this.onFailed} onSuccess={this.onSuccess}
                          requestTokenUrl={requestTokenUrl}/>
          */}
          <PostOnPage users={this.state.users} user={this.state.user}/>
          <ListPost />
        </CardBody>
        </GridItem>
      </GridContainer>);
      return (main);
    }

    else {
      return (empty);
    }
  }
}

const mapStateToProps = (store) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    verifyUser: () => dispatch(verifyUser()),
    getAccount: () => dispatch(getAccount()),
    updateUser: (data) => dispatch(updateUser(data)),
    removeUser: (data) => dispatch(removeUser(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Twitter);
