const ActionOnArticle = {
    EDIT: "Edit",
    WRITE: "Write",
    SUBMIT: "Submit",
    LIVE: "Live",
    APPROVE: "Approve",
    DELETE: "Delete",
    TOGGLE_MSN_GALLERY: 'Enable on Msn Gallery',
    REVIEW_AND_EDIT: "Review and Edit",
    FINISH_EDIT: "FinishEdit",
    SEND_FEEDBACK: "SendFeedback",
    PUBLISH: "Publish",
    UNPUBLISH: "UnPublish",
    VIEW_ON_WEBSITE: "ViewOnWebsite",
    SHARE: "Share",
    CLEAR_CACHE: "ClearCache",
    SHARE_DEBUG: "ShareDebug",
    FORCE_EDIT: "ForceEdit",
    PREVIEW: "Preview",
    TOGGLE_ADS: "ToggleAds",
    FACEBOOK_SCHEDULE: "ScheduleOnFacebook",
    EXPLOIT_SCHEDULE: "ExploitSchedule",
    SEND_NOTIFICATION: "SendNotification",
    SCHEDULE_ARTICLE: 'ScheduleArtcile',
    RESCHEDULE_ARTICLE: 'ReScheduleArtcile',
    CANCEL_SCHEDULED_ARTICLE: "CancelScheduledArticle",
    CANCEL_SCHEDULE_EXPLOIT_ARTICLE: "CancelScheduleExploitArticle"
}
export default ActionOnArticle;
