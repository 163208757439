import React, { PureComponent } from "react";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import { withStyles } from '@material-ui/core';
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

class FeedFilter extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      current: this.props.feedFilterDefaultState
    }
    this.FeedFilterRef = React.createRef();
    // this.resetCurrentState = this.resetCurrentState.bind(this);
  }
  handleSubCategoryChange = (e) => {
    this.props.resetFilterFeed(e.target.value)
    // this.props.setFeedType(e.target.value);
  }
  componentDidUpdate(prevProps) {
    if (this.props.feedFilterDefaultState !== prevProps.feedFilterDefaultState) {
      this.setState({ current: this.props.feedFilterDefaultState })
      this.props.setFeedType(this.props.feedFilterDefaultState);
    }
  }
  render() {
    const { classes, } = this.props;
    return (
      <div style={{ marginBottom: "20px" }} ref={this.FeedFilterRef}>
        <FormControl
          fullWidth
          className={classes.selectLabel}
        >
          <InputLabel
            htmlFor="feed"
            className={classes.selectLabel}
          >
            Status
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={this.state.current}
            onChange={this.handleSubCategoryChange}
            inputProps={{
              name: "feed-type",
              id: "feed-type"
            }}
          >
            {
              ["All", "Draft", "Live"].map((item, id) => {
                return (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    key={id}
                    value={item}
                  >
                    {item}
                  </MenuItem>)
              })
            }
          </Select>
        </FormControl>
      </div>
    )
  }
}
export default withStyles(customSelectStyle)(FeedFilter);