import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import "assets/scss/material-dashboard-pro-react.css?v=1.4.0";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import "react-table/react-table.css";

import store from "./app-store.js";
import App from "./app.jsx";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const ownTheme = createMuiTheme({
  typography: {
    "fontFamily": "Source Sans Pro, sans-serif",
    root: {
      "color": "#2B2D42",
    }
    // "background": "#2b2d42 !important"
    // "secondary": "#2b2d42 !important"
  },
  palette: {
    primary: {
      // light: '#ff8e8c',
      main: '#2B2D42',
      dark: '#2B2D42',
      // contrastText: '#fff',
    },
    secondary: {
      // light: '#4da9b7',
      main: '#5D737E',
      dark: '#5D737E',
      // contrastText: '#000',
    },
  },
  shape: {
    boxShadow: 'none',
    borderRadius: 'unset'
  },
  overrides: {
    MuiPaper: {
      root: {
        boxShadow: 'none !important',
        // border: '1px solid  #2B2D42'
      },
    },
  }
});

ReactDOM.render(
  <MuiThemeProvider theme={ownTheme}>
    <Provider store={store}>
      <App />
    </Provider>
  </MuiThemeProvider>,
  document.getElementById("root")
);
