import React, { PureComponent } from "react";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import { withStyles } from '@material-ui/core';
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

class CategoryDropdown extends PureComponent {
  state = {
    current: this.props.initalValue || this.props.categories[0].id,
  }
  constructor(props) {
    super(props);
  }
  handleSubCategoryChange = (e) => {
    this.setState({ current: e.target.value })
    this.props.setCategory(e.target.value);
  }
  render() {
    const { classes, } = this.props;
    return (
      <div style={{ marginBottom: "20px" }}>
        <FormControl
          fullWidth
          className={classes.selectLabel}
        >
          <InputLabel
            htmlFor="feed"
            className={classes.selectLabel}

          >
            Category
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={this.state.current}
            onChange={this.handleSubCategoryChange}
            inputProps={{
              name: "feed-type",
              id: "feed-type",
              classes: {
                icon: classes.iconMargin
              },
            }}
          >
            {
              this.props.categories.map((item, id) => {
                return (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    key={item.id}
                    value={item.id}
                  >
                    {item.categoryVal}
                  </MenuItem>)
              })
            }
          </Select>
        </FormControl>
      </div >
    )
  }
}
export default withStyles(customSelectStyle)(CategoryDropdown);
