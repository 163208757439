import React, { PureComponent } from "react";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import { withStyles } from '@material-ui/core';
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

class ArticleSubCategory extends PureComponent {
  constructor(props) {
    super(props);
  }
  handleSubCategoryChange = (e) => {
    this.props.setYCategoryType(e.target.value);
  }
  render() {
    const { classes, yCategoryId, subCategories } = this.props;
    return (
      <div style={{ marginBottom: "20px" }}>
        <FormControl
          fullWidth
          className={classes.selectLabel}
        >
          <InputLabel
            htmlFor="article-sub-category"
            className={classes.selectLabel}
          >
            SUB CATEGORY
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={yCategoryId ? yCategoryId : 'None'}
            onChange={this.handleSubCategoryChange}
            inputProps={{
              name: "article-sub-category",
              id: "article-sub-category",
              classes: {
                icon: classes.iconMargin
              },
            }}
          >
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              key={subCategories.length}
              value={null}
            >
              <em>None</em>
            </MenuItem>
            {
              subCategories && subCategories.map(({ id, category }) => {
                // if (id === 0) {
                //   return null;
                // }
                return (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    key={id}
                    value={id}
                  >
                    {category}
                  </MenuItem>)
              })
            }
          </Select>
        </FormControl>
      </div>
    )
  }
}
export default withStyles(customSelectStyle)(ArticleSubCategory);
