import React from 'react';

import AppLoader from "../../app-components/app-loader.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import Close from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import LoaderModal from '../fb/component/loader-modal';

class ShowSocialMedia extends React.Component {
  state={
    deleteItem: '',
    deleteType: '',
    showDeleteModal: false
  }

  render(){
    const {
      isLoadingFacebook,
      isLoadingTwitter,
      facebookPages,
      twitterPages,
      deleteFacebookPage,
      deleteTwitterPage,
      isDeleting
    } = this.props;
    const {deleteItem, deleteType, showDeleteModal} = this.state;

    if(isLoadingTwitter && isLoadingFacebook)
      return <AppLoader message="Loading Pages"/>
    return (
      <>
        <GridContainer>
          <GridItem xs={12} md={12}>
            <div style={{padding: '30px 10px'}}>
            {
              (
                (!isLoadingFacebook && (!facebookPages || (facebookPages && facebookPages.length===0)))
              &&
                (!isLoadingTwitter && (!twitterPages || (twitterPages && twitterPages.length===0)))
              )
              &&
              'Add an account to get started.'
            }
            {
              facebookPages && facebookPages.length>0 && facebookPages.map((item, index) => {
                return(
                  <div
                    key={index}
                    style= {{padding: '10px 0', cursor: 'pointer', borderTop: '1px solid #bdbdbd', borderBottom: '1px solid #bdbdbd'}}
                  >
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                      <p style={{margin: '0', fontSize: '1.1em'}}>{item.name}</p>
                      <p style={{margin: '0', color: 'gray'}}>Facebook Page</p>
                    </div>
                    <div
                      style={{ display: "flex", paddingTop: '9px'}}
                      onClick={()=>{this.setState({showDeleteModal: true, deleteItem: item, deleteType: 'facebook'})}}
                    >
                      <span style={{ }}>{`Remove`}</span>
                      &nbsp;
                      <Close style={{color: 'black'}}/>
                    </div>
                    </div>
                  </div>
                )
              })
            }

            {
              twitterPages && twitterPages.length>0 && twitterPages.map((item, index) => {
                return(
                  <div
                    key={index}
                    style= {{padding: '10px 0', cursor: 'pointer', borderTop: '1px solid #bdbdbd', borderBottom: '1px solid #bdbdbd'}}
                  >
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <div>
                        <p style={{margin: '0', fontSize: '1.1em'}}>{item.screen_name}</p>
                        <p style={{margin: '0', color: 'gray'}}>Twitter Page</p>
                      </div>
                      <div
                        style={{ display: "flex", paddingTop: '9px'}}
                        onClick={()=>{this.setState({showDeleteModal: true, deleteItem: item, deleteType: 'twitter'})}}
                      >
                        <span style={{ }}>{`Remove`}</span>
                        &nbsp;
                        <Close style={{color: 'black'}}/>
                      </div>
                    </div>

                  </div>
                )
              })
            }
            </div>
          </GridItem>
        </GridContainer>

        <Dialog
          open={showDeleteModal}
          fullWidth={true}
          onClose={()=>{this.setState({showDeleteModal: false})}}
          maxWidth="sm"
          aria-labelledby="customized-dialog-title"
        >
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <div style={{display: 'flex', justifyContent: 'space-around', padding: '50px 10px', fontSize: '1.2em'}}>
              <ul>
                <li>You will no longer be able to post to <b style={{color: '#fb4d24'}}>{(deleteType == 'facebook')?`${deleteItem && deleteItem.name}`:`${deleteItem && deleteItem.screen_name}`}</b>.</li>
                <li>All users will lose access to this account.</li>
                <li>All your current scheduled posts on this page will be deleted.</li>
              </ul>
            </div>
          </DialogContent>
          <DialogActions>
          <Button
            onClick={() => {(deleteType=='facebook')?deleteFacebookPage(deleteItem):deleteTwitterPage(deleteItem); this.setState({showDeleteModal: false,deleteType: '', deleteItem: ''})}}
            style={{backgroundColor: '#fb4d24', color: 'white'}}
          >
            Yes
        </Button>
            <Button
              onClick={() => {this.setState({ showDeleteModal: false, deleteItem: '', deleteType: '' })}}
            >
              Cancel
          </Button>
          </DialogActions>
        </Dialog >

        <LoaderModal
          showModal={isDeleting}
          showLoader={isDeleting}
          message={"Deleting"}
        />
      </>
    );
  }
}

export default ShowSocialMedia;
