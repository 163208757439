const NewStoryAction = {
  SET: {
    EDITOR: {
      INITIAL_STATE: "NewStoryAction.Set.Editor.InitialState",
      CONTENT: "NewStoryAction.Set.Editor.Content",
    },
    IMAGE_FLIPPER: {
      SHOW: "NewStoryAction.Set.ImageFlipper.Show",
      HIDE: "NewStoryAction.Set.ImageFlipper.Hide",
      REQUEST: "NewStoryAction.Set.ImageFlipper.Request",
      SUCCESS: "NewStoryAction.Set.ImageFlipper.Success",
      FAILURE: "NewStoryAction.Set.ImageFlipper.Failure"
    },
    BLOCK_QUOTE: {
      SHOW: "NewStoryAction.Set.BlockQuote.Show",
      HIDE: "NewStoryAction.Set.BlockQuote.Hide",
    },
    NEW_ARTICLE: {
      ALLOW_UPDATE: "NewStoryAction.Set.NewArticle.AllowUpdate",
      ARTICLE_ID: "NewStoryAction.Set.NewArticle.ArticleId",
      CATEGORIES: "NewStoryAction.Set.NewArticle.Categories",
    },
    PISCO: {
      CONTENT: "NewStoryAction.Set.Pisco.Content",
      COVER: 'NewStoryAction.Set.Pisco.CoverUrl',
      RESET_COVER: 'NewStoryAction.Set.Pisco.ResetCoverUrl',
      VIDEO: "NewStoryAction.Set.Pisco.Video",
    },
    ARTICLE_DETAILS: {
      META_DETAILS: "NewStoryAction.Set.ArticleDetails.MetaDetails",
      META_TAGS_LIST: "NewStoryAction.Set.ArticleDetails.MetaTagsList",
      SUB_CATEGORIES_LIST: "NewStoryAction.Set.ArticleDetails.SubCategoriesList",
      SUB_CATEGORIES_LIST_MODAL: "NewStoryAction.Set.ArticleDetails.SubCategoriesListModal",
      META_DATA_MODAL: "NewStoryAction.Set.ArticleDetails.MetaDataModal",

      SHARE_TYPE: "NewStoryAction.Set.ArticleDetails.ShareType",
      CATEGORY_TYPE: "NewStoryAction.Set.ArticleDetails.CategoryType",
      SUB_CATEGORY_TYPE: "NewStoryAction.Set.ArticleDetails.SubCategoryType",
      STORY_TYPE: "NewStoryAction.Set.ArticleDetails.StoryType",
      ARTICLE_TITLE: "NewStoryAction.Set.ArticleDetails.ArticleTitle",
      ARTICLE_SUMMARY: "NewStoryAction.Set.ArticleDetails.ArticleSummary",
      ARTICLE_COVER_PICTURE: "NewStoryAction.Set.ArticleDetails.ArticleCoverCaption",
      SEO_TITLE: "NewStoryAction.Set.ArticleDetails.SEOTitle",
      POST_CAPTION: "NewStoryAction.Set.ArticleDetails.PostCaption",
      CUSTOM_URL: "NewStoryAction.Set.ArticleDetails.CustomUrl",
      ARTICLE_TYPE: "NewStoryAction.Set.ArticleDetails.ArticleType",
      TIME_SPECIFIC: "NewStoryAction.Set.ArticleDetails.TimeSpecific",
      AUTHOR_NAME: "NewStoryAction.Set.ArticleDetails.AuthorName",
      META_TAGS: "NewStoryAction.Set.ArticleDetails.MetaTags",

    },

  },
  APPEND: {
    EDITOR: {
      CONTENT: "NewStoryAction.Edit.Editor.Content"
    },
    BLOCK_QUOTE: {
      CONTENT: "NewStoryAction.Append.BlockQuote.Content"
    },
  },
  PISCO: {
    OPEN: "NewStoryAction.Pisco.Open",
    CLOSE: "NewStoryAction.Pisco.Close",

  },
  UPLOAD: {
    IMAGE: {
      REQUEST: "NewStoryAction.Uplaod.Image.Request",
      FAILURE: "NewStoryAction.Upload.Image.Failure",
      SUCCESS: "NewStoryAction.Uplaod.Image.Success",
    },
    COVER_PICTURE: {
      REQUEST: "NewStoryAction.Uplaod.CoverPicture.Request",
      FAILURE: "NewStoryAction.Upload.CoverPicture.Failure",
      SUCCESS: "NewStoryAction.Uplaod.CoverPicture.Success",
    }
  },
  FETCH: {
    ARTICLE_DETAILS: {
      REQUEST: "NewStoryAction.Uplaod.ArticleDetails.Request",
      FAILURE: "NewStoryAction.Upload.ArticleDetails.Failure",
      SUCCESS: "NewStoryAction.Uplaod.ArticleDetails.Success",
    }
  },
  ADD: {
    ARTICLE_DETAILS: {
      AUTHOR_NAME: "NewStoryAction.Add.ArticleDetails.AuthorName",
    }
  },
  SAVE: {
    REQUEST: "NewStoryAction.Save.Request",
    FAILURE: "NewStoryAction.Save.Failure",
    SUCCESS: "NewStoryAction.Save.Success",
  },
  CREATE: {
    REQUEST: "NewStoryAction.CREATE.Request",
    FAILURE: "NewStoryAction.CREATE.Failure",
    SUCCESS: "NewStoryAction.CREATE.Success",
  }
}
export default NewStoryAction;
