import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import RegisterPage from "./views/register";

import { Helmet } from "react-helmet";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import { } from "./onboarding-action";

class OnboardingHomePage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      redirectToReferrer: false,
    };
  }

  render() {
    const { classes } = this.props;
    const { redirectToReferrer } = this.state;
    let { from } = this.props.location.state || { from: { pathname: "/" } };
    if (redirectToReferrer) return <Redirect to={from} />;

    return (
      <div className={classes.container} style={{ height: '100%' }}>
        <Helmet title="Sign Up | PubNinja - You Publish. The rest we manage." />
        <RegisterPage />
      </div>
    );
  }
}

OnboardingHomePage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(OnboardingHomePage);
