import { MeawwApi } from "../../utils/api.js";
import { NewsRoomAction } from "../../app-constants/action-types/index.js";
import { setAppMessage } from "../../app-actions.js";
import { MessageTypes } from "../../app-constants/index.js";
import socket from "../../utils/socket";

function loadNewsRoomCategoriesSuccess(data) {
  return {
    type: NewsRoomAction.CATEGORIES.SUCCESS,
    data
  };
}

export function loadNewsRoomCategories() {
  return (dispatch, getState) => {
    const { login: { user: { activeDomain } }, cms: { newsRoom: { newsRoomReducer: { categoryId } } } } = getState();
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/newsroom/${activeDomain}/categories`, (error, data) => {
          if (error) {
            dispatch(
              setAppMessage(error.message, { type: MessageTypes.ERROR })
            );
            return Promise.reject(error);
          }
          //Setting default active Category
          if(categoryId === null)
            dispatch(activeCategorie(data[0].id, data[0].name));
          dispatch(loadNewsRoomCategoriesSuccess(data));
          return Promise.resolve(data);
        });
      });
  };
}

export function activeCategorie(categoryId, categoryName) {
  return {
    type: NewsRoomAction.CATEGORY.SETACTIVE,
    categoryId,
    categoryName
  };
}

export function loadNewsRoomArticles() {
  return (dispatch, getState) => {
    const { login: { user: {activeDomain} }, cms: {newsRoom: { newsRoomReducer: {categoryId, articlesData, pageNo, searchQuery, invalidSearch }, newsRoomSetting: { token} } } } = getState();
    if(pageNo === 1 && searchQuery === null)
      dispatch(newsRoomActionRequest());

    return MeawwApi()
      .auth()
      .then((api)=>{
        let requestparams =  {"q": searchQuery};
        if(searchQuery === null){
          requestparams = categoryId===null?requestparams:{...requestparams, "productId": [categoryId]};
        }
        requestparams = (pageNo===1||token===null)?requestparams
        :
          {...requestparams, "page": [
            {
              "qt": token,
              "pageNo": pageNo
            }
          ]}
        return api.post(`/newsroom/${activeDomain}/feed`,
          requestparams,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            if(data.err){
              let errorMessage = JSON.parse(data.err.response.text).error.message;
              if(searchQuery)
                errorMessage = 'Invalid characters in search string.';
              dispatch(setAppMessage(errorMessage, { type: MessageTypes.ERROR }));
              dispatch(newsRoomActionSuccess([], items));
              return;
            }
            let responseObject = Object.values(data)[0];
            let { data: newdata } = responseObject;
            let token = responseObject["id"];
            dispatch(saveToken(token));
            if(responseObject.data === undefined)
              return Promise.reject(error);
            let items = responseObject.data.items;
            dispatch(newsRoomActionSuccess(articlesData, items));
            const windowHeight = window.innerHeight;
            let rect = document.getElementById('scrollFixer').getBoundingClientRect().top;
            if (newdata.total_items > 0 && rect - window.innerHeight < 100 && invalidSearch === false ) {
              dispatch(setLiveArticlesPageNumber(pageNo+1));
              dispatch(loadNewsRoomArticles());
            }
            return Promise.resolve(data);
      });
    });
  };
}

function saveToken(token){
    return {
      type: NewsRoomAction.TOKEN.SAVE,
      token
    }
}

function newsRoomActionRequest() {
  return {
    type: NewsRoomAction.LOAD.REQUEST
  };
}
function newsRoomActionSuccess(articlesData, items) {
  return {
    type: NewsRoomAction.LOAD.SUCCESS,
    articlesData,
    items
  };
}

export function openNewsRoomModal(id) {
  return (dispatch, getState) => {
    dispatch(openNewsRoomModalRequest());
    const { login: { user: {activeDomain} }, cms: {newsRoom: { newsRoomSetting: { showModal } } } } = getState();
    return MeawwApi()
    .auth()
    .then((api)=>{
      return api.get(`/newsroom/${activeDomain}/itemMeta/${id}`,
        (error, data) => {
          if (error) {
            dispatch(
              setAppMessage(error.message, { type: MessageTypes.ERROR })
            );
            return Promise.reject(error);
          }
          let responseObject = data.itemMeta.data;
          let item = responseObject.item;
          dispatch(sendNewsRoomModalData(item));
          return Promise.resolve(data);
      })
    });
  }
}

function openNewsRoomModalRequest() {
  return {
    type: NewsRoomAction.NEWSROOMMODAL.REQUEST
  };
}

function sendNewsRoomModalData(modalData) {
  return {
    type: NewsRoomAction.NEWSROOMMODAL.OPEN,
    modalData
  };
}
export function closeNewsRoomModal() {
  return {
    type: NewsRoomAction.NEWSROOMMODAL.CLOSE
  };
}

export function setLiveArticlesPageNumber(pageNo) {
  return {
    type: NewsRoomAction.SET.PAGE_NUMBER,
    pageNo: pageNo
  }
}

export function saveToDraft(itemId) {
  return (dispatch, getState) => {
    dispatch(closeNewsRoomModal());
    dispatch(toggleProgressModal());
    const { login: { user: {activeDomain} }, login: { user: {id} } } = getState();
    const socketRoomId = `${itemId}|${id}`;
    const progressModalData = [];
    socket.emit('room', socketRoomId);
    socket.onevent = (packet) => {
      dispatch(addToProgressModal(packet.data));
    };
    return MeawwApi()
    .auth()
    .then((api)=>{
      return api.get(`/newsroom/${activeDomain}/draft/${itemId}`,
        (error, data) => {
          if (error) {
            //const msg = error.msg || error.response.text;
            dispatch(
              setAppMessage(error.message, { type: MessageTypes.ERROR })
            );
            return Promise.reject(error);
          }
          return Promise.resolve(data);
      })
    });
  }
}


export function startSearch(searchQuery){
  return (dispatch) => {
    dispatch(newsRoomActionRequest());
    dispatch(isSearching(searchQuery));
    dispatch(resetPageNum());
    dispatch(loadNewsRoomArticles(searchQuery));
  }
}

function isSearching(searchQuery){
  return {
    type: NewsRoomAction.SEARCH.START,
    searchQuery
  }
}

function resetPageNum(){
    return {
      type: NewsRoomAction.SETTING.RESETPAGE
    }
}

export function unmountRoutine(){
  return (dispatch, getState) => {
    const { login: { user: {activeDomain} }, cms: {newsRoom: { newsRoomSetting: { showModal } } } } = getState();
    const dataSetting = {
      token: null,
      showModal: false,
      modalData: null,
      draftButtonMessage: null,
    }
    const dataReducer = {
      pageNo: 1,
      isLoading: false,
      searchResults: {}
    }
    dispatch(unmountRoutineSuccessSetting(dataSetting));
    dispatch(unmountRoutineSuccessReducer(dataReducer));
  }
}

function unmountRoutineSuccessSetting(data){
  return {
    type: NewsRoomAction.UNMOUNT.SETTING.SUCCESS,
    data
  }
}

function unmountRoutineSuccessReducer(data){
  return {
    type: NewsRoomAction.UNMOUNT.REDUCER.SUCCESS,
    data
  }
}

export function refreshArticles(){
  return (dispatch, getState) => {
    const { login: { user: {activeDomain} }, cms: {newsRoom: { newsRoomReducer: {categoryId, pageNo}, newsRoomSetting: { showModal } } } } = getState();
    dispatch(resetArticleState());
    dispatch(loadNewsRoomArticles());
  }
}

function resetArticleState(){
  return {
    type: NewsRoomAction.REFRESH
  }
}

export function toggleProgressModal(){
  return {
    type: NewsRoomAction.DRAFT.TOGGLEMODAL
  }
}

function addToProgressModal(item){
  return {
    type: NewsRoomAction.DRAFT.ADDDATA,
    item
  }
}
