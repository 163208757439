import React from 'react';
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

class Templates extends React.Component {
  componentDidMount(){
      //this.props.fetchgaID();
  }

  render(){
    return (
      <>
      Templates go here.
      </>
    );
  }
}

export default Templates;
