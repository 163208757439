import { combineReducers } from "redux";
import articles from "./articles/articles-reducers.js";
import articleSettings from "./article/article-reducers.js";
import newStory from "../cms/new-story/new-story-reducers.js";
import feeds from "../cms/content-discovery/feeds-reducers.js";
import StoryPost from '../cms/posts/posts-reducer.js';
import newsRoom from '../cms/news-room/news-room-reducers.js';
import storyCms from "../cms/story-cms/story-cms-reducers.js";
import QuickCms from "../cms/quick-cms/quick-cms-reducers.js";

const cmsReducers = combineReducers({
    articles: articles,
    articleSettings: articleSettings,
    newStory: newStory,
    feeds: feeds,
    newsRoom,
    StoryPost,
    storyCms,
    quickCms: QuickCms,
});

export default cmsReducers;
