import React, { PureComponent } from 'react';
import Button from '../../components/CustomButtons/Button.jsx';
import Checkbox from '@material-ui/core/Checkbox';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import AppLoader from "app-components/app-loader.jsx";

class CreateType extends PureComponent {
  state = {
    step:0,
    isLoading :false,
    AudioCheck: false,
    VideoCheck: false,
    ImageCheck: false,
    BlogCheck: false,
    OtherCheck: false,
    name :this.props.user.firstName + ' ' + (this.props.user.lastName ? this.props.user.lastName : '') || '',
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    this.props.getCreateType().then((data) => {
      this.setState({ isLoading: false });
      const createTypes = JSON.parse(data.store).createType
      if(Object.keys(createTypes).length >0){
        const { AudioCheck, VideoCheck, ImageCheck, BlogCheck, OtherCheck } = createTypes;
        this.setState({ AudioCheck, VideoCheck, ImageCheck, BlogCheck, OtherCheck })
      }
    }).catch(() => {
      this.setState({ isLoading: false });
    })
  }
  handleChange = event => {
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
  };
  increaseStep = () => {
    this.setState({ step: 1 });
  }
  setWebCategory = () => {
    const { AudioCheck, VideoCheck, ImageCheck, BlogCheck, OtherCheck, name: title } = this.state;
    this.props.setCreateType({ AudioCheck, VideoCheck, ImageCheck, BlogCheck, OtherCheck });
    const index = title.trim().indexOf(' ')
    let name = null;
    if (index == -1) {
      name = {
        firstName: title
      }
    }
    else {
      name = {
        firstName: title.slice(0, index) || '',
        lastName: title.slice(index + 1) || ''
      }
    }
    this.props.updateUser(name);
    this.props.enableNextTab('websiteButton');
    this.props.increaseState();
  }
  handleNameChange = (e) => {
    this.setState({name:e.target.value})
  }
  render() {
    const { AudioCheck, VideoCheck, ImageCheck, BlogCheck, OtherCheck, step } = this.state;
    return (
      <>
        <GridItem xs={12} sm={8} md={6} style={{ marginTop: '35px', display: step == 0 ? 'block': 'none' }}>
          <div style={{
            marginTop: '15px'
          }}>
            <h1 style={{ textAlign: 'center', fontFamily: 'PlayfairDisplay' }}>
              Welcome to PubNinja
        </h1>
            <hr style={{
              border: '1px solid #E1E4E9',
              width: '10%',
              margin: ' auto'
            }} />
            <p style={{
              lineHeight: '1.75',
              fontFamily: 'SourceSansPro',
              fontSize: '16px',
              color: '#2b2d42',
              textAlign: 'center',
              marginTop: '50px',
            }}>
              Welcome to PubNinja! We are excited to have you. PubNinja, An all-in-one creator platform to kick start your music/video channel, podcast, storytelling, community, publication and a lot more.
          <br />
              <br />
              You are just few steps away from starting your own website.
        </p>
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '30px' }}>
          <Button color="secondary" onClick={this.increaseStep}>Start Creating</Button>
        </div>
          </div>
        </GridItem>
        <GridItem xs={12} sm={8} md={6} style={{ display: "none", marginTop: '30px ', display: step == 1 ? 'block' : 'none', border: isWidthDown('xs', this.props.width) ? 'none' : '1px solid rgb(206, 211, 220)' }}>
        <div>
          <h1
            style={{
              textAlign: 'center',
              marginTop: '5px',
              fontFamily: 'PlayfairDisplay'
            }}
          >
            All about, you !
				</h1>
          <hr style={{
            border: '1px solid #E1E4E9',
            width: '10%',
            margin: ' auto'
          }} />
          <GridItem xs={12} sm={10} md={8} style={{ margin: 'auto' }} id="margionZeroPub">
            <h5 style={{
              width: '100%',
              textAlign: 'left',
              marginBottom: '-20px',
              marginTop: '15px',
              fontSize: '16px',
              fontWeight: '600',
              color: '#2b2d42',
            }}>Tell us your name</h5>
          </GridItem>
          <GridItem xs={12} sm={10} md={8} style={{ margin: 'auto', }} id="margionZeroPub">
            <CustomInput
              id="name"
              value={this.state.name}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: this.handleNameChange,
                placeholder: "e.g. JOHN DOE*",
              }}
            // helpText={error.titleError}
            />
          </GridItem>
          <GridItem xs={12} sm={10} md={8} style={{ margin: 'auto' }} id="margionZeroPub">
            {
              this.state.isLoading ? <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><AppLoader /></div> :
                <>
                  <h5 style={{
                    width: '100%',
                    textAlign: 'left',
                    marginBottom: '-20px',
                    marginTop: '15px',
                    fontSize: '16px',
                    fontWeight: '600',
                    color: '#2b2d42',
                  }}> What kind of Creator are you?</h5>
                  <p
                    style={{
                      marginTop: '20px',
                      textAlign: 'left',
                      fontSize: '14px',
                      marginBottom: '0px',
                      color: 'rgb(153, 163, 180)'
                    }}
                  >
                    You could be a publisher, podcaster, musician, photographer, vlogger and the list goes on. You can select one or more.
				</p>
                  <div
                    style={{
                      marginTop: '00px',
                      display: 'flex',
                      justifyContent: 'space-around'
                    }}
                  >
                    <div>
                      <Checkbox
                        checked={AudioCheck}
                        onChange={this.handleChange}
                        name="AudioCheck"
                        value={AudioCheck.toString()}
                        inputProps={{
                          'aria-label': 'primary checkbox',
                        }}
                      />
                      <span>AUDIO</span>
                    </div>
                    <div>
                      <Checkbox
                        checked={VideoCheck}
                        onChange={this.handleChange}
                        name="VideoCheck"
                        value={VideoCheck.toString()}
                        inputProps={{
                          'aria-label': 'primary checkbox',
                        }}
                      />
                      <span>VIDEO</span>
                    </div>
                    <div>
                      <Checkbox
                        checked={ImageCheck}
                        onChange={this.handleChange}
                        name="ImageCheck"
                        value={ImageCheck.toString()}
                        inputProps={{
                          'aria-label': 'primary checkbox',
                        }}
                      />
                      <span>IMAGE</span>
                    </div>
                    <div>
                      <Checkbox
                        checked={BlogCheck}
                        onChange={this.handleChange}
                        name="BlogCheck"
                        value={BlogCheck.toString()}
                        inputProps={{
                          'aria-label': 'primary checkbox',
                        }}
                      />
                      <span>BLOG</span>
                    </div>
                    <div>
                      <Checkbox
                        checked={OtherCheck}
                        onChange={this.handleChange}
                        value={OtherCheck.toString()}
                        name="OtherCheck"
                        inputProps={{
                          'aria-label': 'primary checkbox',
                        }}
                      />
                      <span>OTHERS</span>
                    </div>
           
                  </div>
                </>
            }
          </GridItem>
          <div style={{
            marginTop: '15px',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '15px',
            width: '100%'
          }}>
       
            <Button color="secondary" style={isWidthDown('xs', this.props.width) ? { width: '100%' } : {}} onClick={this.setWebCategory} >
              continue
				</Button>
          </div>
        </div >
        </GridItem>
        </>
      )
    }
  }
const style = {
  padd: {
    margin:'auto',
    padding :'0px !important'
  }
}
export default (withWidth()(CreateType));
//