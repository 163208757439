import React, { PureComponent, Fragment, Component } from "react";
import AppLoader from "app-components/app-loader.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { Redirect, withRouter } from "react-router-dom";
import Subscription from "./subscriptions";
import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';

class Earn extends PureComponent {
  constructor(props) {
    super(props);
  }
  state = {
    subscriptionEnabled: false,
    annualPrice: 0,
    monthlyPrice: 0,
    showLoader: false,
    isAdEnable: false,
    subscribeData: null,
    annualPriceError:'',
  }
  componentDidMount() {
    //this.props.fetchFeatures();
  }
  
  updateErrorStatus = (annualPriceError) => {
    this.setState({ annualPriceError });
  }
  toggleSubscription = (event) => {
    this.setState({ subscriptionEnabled: event.target.checked });
  }
  handleAdChange = (event) => {
      this.setState({isAdEnable:event.target.checked})
  }
  updateAdInfo = (isAdEnable) => {
    this.setState({
      isAdEnable,
    });
  }
  updateSubscriptionInfo = (subscribeData) => {
    this.setState({ subscribeData });
  }
  finishOnboarding = () => {
    setTimeout(() => {
      this.setState({ showLoader: false });
      this.props.history.push("/landing");
    }, 7000);
  }
  finalCall = () => {
    if (this.state.subscriptionEnabled) {
      const { subscribeData } = this.state;
      if (subscribeData == null || !!Number(subscribeData.annualPriceFloat) == false || !!Number(subscribeData.monthlyPriceFloat) == false) {
        this.props.showErrorMessage('Price cannot be 0 or null');
        return;
      }
      const offerings = subscribeData.features.filter(item => item.checked === true).map(item => item.title);
      if (offerings.length > 3 || offerings.length < 1) {
        this.props.showErrorMessage('You need to select atleast 1 item and atmost 3 items.');
        return;
      }
      this.setState({ showLoader: true });
      if (this.state.isAdEnable) this.props.enabldeAd(this.state.isAdEnable);
      this.props.setFeatures(this.state.subscribeData, true);
      this.finishOnboarding();
      
    } else {
      this.setState({ showLoader: true });
      if (this.state.isAdEnable) this.props.enabldeAd(this.state.isAdEnable);
      this.finishOnboarding();
    }
    
    
  }
    render() {
        return (
          <GridContainer>
          <GridItem xs={12} sm={8} md={6} style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',margin:'auto',marginTop:'20px',border: isWidthDown('xs', this.props.width) ? 'none' : '1px solid rgb(206, 211, 220)'}}>
            {  this.state.showLoader ? (<div style={{
              width: '100%',
              height: '60vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <AppLoader message="Getting Everything Ready!" />
              </div>) : 
              <>
              <h1
              style={{
                textAlign: 'center',
                marginTop: '5px',
                fontFamily: 'PlayfairDisplay'
              }}
            >
              Setup Monetization
            </h1>
            <hr style={{
              border: '1px solid #E1E4E9',
              width: '10%',
              margin: ' auto'
                  }} />
                <GridItem xs={12} sm={10} md={8} style={{ margin: 'auto', width: '100%' }} id="margionZeroPub">
                  <h5 style={{
                    width: '100%',
                    textAlign: 'left',
                    marginBottom: '0px',
                    marginTop: '10px',
                    fontSize: '16px',
                    fontWeight: '600',
                    color: '#2b2d42',
                  }}>Enable Ads for Website</h5>
                  <p
                    style={{
                      textAlign: 'left',
                      fontSize: '14px',
                      marginBottom: '0px',
                      color: 'rgb(153, 163, 180)'
                    }}
                  >
                    This will start to show ads to website visitors. (Eg: Google Ads)
                  </p>
                  <span >No</span>
                  <Switch
                    checked={this.state.isAdEnable}
                    onChange={this.handleAdChange}
                    color='primary' />
                  <span >Yes</span>
                </GridItem>
            <GridItem xs={12} sm={10} md={8} style={{ margin: 'auto', width: '100%' }} id="margionZeroPub">
                <h5 style={{
                  width: '100%',
                  textAlign: 'left',
                  marginBottom: '0px',
                  marginTop:'10px',
                  fontSize: '16px',
                  fontWeight: '600',
                  color: '#2b2d42',
                }}>Enable Paid Subscription</h5>
                <p
                  style={{
                    textAlign: 'left',
                    fontSize: '14px',
                    marginBottom: '0px',
                    color: 'rgb(153, 163, 180)'
                  }}
                >
                This will allow you to publish content on your website, behind a paywall
                </p>
                <span >No</span>
                <Switch
                    checked={this.state.subscriptionEnabled}
                    onChange={this.toggleSubscription}
                    color='primary' />
                    <span >Yes</span>
                    {this.state.subscriptionEnabled && <Subscription
                      fetchFeatures={this.props.fetchFeatures || []}
                      features={this.props.features}
                      isFetchingFeatures={this.props.isFetchingFeatures}
                      isSettingFeatures={this.props.isSettingFeatures}
                      setFeatures={this.props.setFeatures}
                      updateErrorStatus={this.updateErrorStatus}
                      updateSubscriptionInfo={this.updateSubscriptionInfo}
                      subscriptionEnabled={this.state.subscriptionEnabled}
                    />}
            </GridItem>                  
                  <Button color="secondary" disabled={(this.state.subscriptionEnabled && this.state.annualPriceError != '')} onClick = {this.finalCall} style={{
              fontSize: '14px',
              textTransform: 'uppercase',
              margin: 'auto',
              marginTop: '10px',
              marginBottom:'20px',
              width: isWidthDown('xs', this.props.width) ? '100%' : 'auto'
            }}>
              start publishing
            </Button>
                  </>
              }
          </GridItem>
        </GridContainer>
        )
    }
}
export default withRouter((withWidth()(Earn)));