import { MeawwApi } from "../../utils/api.js";
import {
  LandingPageAction, UserAction
} from "../../app-constants/index.js";
import { setAppMessage } from "../../app-actions.js";
import { MessageTypes } from "../../app-constants/index.js";


function landingPageActionSuccess(data) {
  return {
    type: LandingPageAction.LAOD.SUCCESS,
    data
  }
}
function landingPageActionRequest() {
  return {
    type: LandingPageAction.LAOD.REQUEST
  }
}
export function setIdForMenuDropDown(id) {
  return {
    type: LandingPageAction.SETTING.SET,
    cardId: id
  }
}
export function openLpModal() {
  return {
    type: LandingPageAction.LPMODAL.SET
  }
}
export function removeLpModal() {
  return {
    type: LandingPageAction.LPMODAL.REMOVE
  }
}
export function removeIdForMenuDropDown() {
  return {
    type: LandingPageAction.SETTING.REMOVE
  }
}

function deleteLandingPage(cardId) {
  return {
    type: LandingPageAction.DELETE.PAGE,
    cardId
  }
}
function updateLandingPageActionSuccess(formData, id) {
  return {
    type: LandingPageAction.UPDATE.SUCCESS,
    formData,
    cardId: id
  }
}
export function openAddLpModal() {
  return {
    type: LandingPageAction.ADDLPMODAL.OPEN
  }
}
export function closeAddLpModal() {
  return {
    type: LandingPageAction.ADDLPMODAL.CLOSE
  }
}
export function activeCategorie(categoryId, categoryName) {
  return {
    type: LandingPageAction.CATEGORY.SETACTIVE,
    categoryId,
    categoryName
  }
}
function addNewLpEntrySuccess(data) {
  return {
    type: LandingPageAction.ADDNEWLP.SUCCESS,
    data
  }
}
export function openPisco() {
  return {
    type: LandingPageAction.PISCO.OPEN
  }
}
export function closePisco() {
  return {
    type: LandingPageAction.PISCO.CLOSE
  }
}
export function COPYCARDID() {
  return {
    type: LandingPageAction.PISCO.COPYCARDID
  }
}
export function PASTECARDID() {
  return {
    type: LandingPageAction.PISCO.PASTECARDID
  }
}
export function setAvator(avator) {
  return {
    type: LandingPageAction.AVATOR.SET,
    avator
  }
}
function addCropUrl(cropedUrl) {
  return {
    type: LandingPageAction.AVATOR.CROPEDURL,
    cropedUrl
  }
}
export function reSetCropedUrl() {
  return {
    type: LandingPageAction.RESETCROPEDURL.RESET
  }
}
export function resetAvator() {
  return {
    type: LandingPageAction.RESETCROPEDURL.RESETAVATOR
  }
}
export function deleteCurrentPage() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    const { cardId } = getState().tool.landingPages.landingPageSetting;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.delete(`/lp/${activeDomain}/entry/${cardId}`,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            dispatch(setAppMessage(data.result, { type: MessageTypes.SUCCESS }));
            dispatch(deleteLandingPage(cardId))
            return Promise.resolve(data);
          });
      })
  }
}
export default function getLandingPage() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    dispatch(landingPageActionRequest())
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/lp/${activeDomain}/n_entry/`,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            dispatch(landingPageActionSuccess(data.result))
            return Promise.resolve(data);
          });
      })
  }
}
export function setLpEntry(formData, id) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.put(`/lp/${activeDomain}/entry/${id}`,
          formData,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            dispatch(updateLandingPageActionSuccess(formData, id))
            dispatch(removeLpModal())
            dispatch(setAppMessage(data.result, { type: MessageTypes.SUCCESS }));
            return Promise.resolve(data);
          });
      })
  }
}
export function addNewLpEntry(formData, id) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/lp/${activeDomain}/n_entry/`,
          formData,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            dispatch(addNewLpEntrySuccess({ id: data.result.entryId, ...formData.meta, searchName: formData.searchName }))
            dispatch(closeAddLpModal())
            dispatch(setAppMessage(data.result.message, { type: MessageTypes.SUCCESS }));
            return Promise.resolve(data);
          });
      })
  }
}

export function cropedAvator(avator) {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    const { lpModalId } = getState().tool.landingPages.landingPageSetting;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/lp/${activeDomain}/entry/${lpModalId}/cover`,
          avator,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            dispatch(addCropUrl(data.result.heroImage))
            // dispatch(closeAddLpModal())
            return Promise.resolve(data);
          });
      })
  }
}

export function activeSubCategorie(subCategoryId, subCategoryName, defaultSubCategory) {
  return {
    type: 'landingPage.set.subCategory',
    subCategoryId,
    subCategoryName,
    defaultSubCategory
  }
} 