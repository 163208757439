

// Meaww Private template


import React from 'react';
import meawwlogo from "../../../assets/icons/meawwlogo.svg";


function Post4(props) {
  const { storyData: { coverImage, title, }, style } = props;

  const trimmedTitle = title.length > 118 ? title.substring(0,118) + '...' : title;

  return (
    <div style={style}>
      <div>
        <img
          src={coverImage}
          alt="thumbnail"
          style={style.coverImage} />
      </div>
      <div style={style.body}>
        <div style={style.body.title}>
          {title}
        </div>
      </div>
      <div style={{
        position: 'absolute',
        bottom: '-5px',
        right: '-5px',
        background: '#024084',
        paddingTop: '15px',
        paddingLeft: '15px',
        borderTopLeftRadius: '90px',
        display: 'flex'
      }}>
        <img style={{ width: '30px', paddingBottom: '5px' }} src={meawwlogo} />
      </div>
    </div>
  );
}

export default Post4;
