import React, { PureComponent } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { withStyles, Typography, IconButton } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import ArticleSeoTitle from "./article-seo-title.jsx";
import ArticlePostCaption from "./article-post-caption.jsx";
import ArticleOgTitle from "./article-og-title.jsx";
import ArticleCustomUrl from "./article-custom-url.jsx";
import ArticleMetaTags from "./article-meta-tags.jsx";
import ArticleMetaSource from "./article-meta-source.jsx";
import ArticleTitle from "./article-title.jsx";
import Checkbox from "@material-ui/core/Checkbox";

import Button from "@material-ui/core/Button";

const CustomDialogTitle = withStyles(theme => ({
  root: {
    // borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, setMetaDataModal } = props;
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="subheading">
        <div style={{ display: "flex", justifyContent: "center", fontWeight: "bold" }}>
          {children}
        </div>
      </Typography>
      <IconButton aria-label="Close" className={classes.closeButton} onClick={() => setMetaDataModal(false)}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
});

const modalStyles = theme => ({
  root: {
    overflowY: "visible"
  }
})

class ArticleMetaDataModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultStateValue();
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevProps.showMetaDataModal !== this.props.showMetaDataModal && this.props.showMetaDataModal === true) {
      const h2Elements = document.getElementsByTagName('h2');
      let haveClass = false;
      for(let i = 0; i < h2Elements.length; i++) {
        if(h2Elements[i].classList.contains('quickLinks')) {
          haveClass = true;
          break;
        }
      }
      if (haveClass) {
        for(let i = 0; i < h2Elements.length; i++) {
          if(h2Elements[i].classList.contains('quickLinks')) {
          } else if (h2Elements[i].textContent && h2Elements[i].textContent.length) {
            const id = h2Elements[i].textContent.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
            h2Elements[i].setAttribute('id', id);
            h2Elements[i].setAttribute('class', 'quickLinks');
            this.props.tinyMCEEditor.current.editor.execCommand('mceSave');
          }
        }
      }
      this.setState({...this.state, checked: haveClass})
    }
  }

  getDefaultStateValue() {
    return {allTags: [], checked: false};
  }
  allTag(tagsArr) {
    this.setState({...this.state, tagsArr: tagsArr});
  }
  updateMeta(e) {
    if (this.state.tagsArr && this.state.tagsArr.length && this.props.articleDetails.tags && this.props.articleDetails.tags.length) {
      const tagObj = this.props.articleDetails.tags[0];
      const selectedTagArr = this.state.tagsArr.filter((obj) => obj.tag === tagObj.tag);
      if (selectedTagArr.length && selectedTagArr[0].sub_tag && selectedTagArr[0].sub_tag.length && typeof tagObj.subTag !== 'undefined' && tagObj.subTag === null) {
        alert("Sub tag is mandatory for this Tag");
        return false;
      }
    }
    if (this.state.tagsArr && this.state.tagsArr.length && this.props.articleDetails.tags && this.props.articleDetails.tags.length === 0) {
      alert("Tag is mandatory!!");
      return false;
    }
    e.preventDefault();
    this.props.addDisclaimer();
  }
  onCheckboxClick() {
    const showTableContent = !this.state.checked;
    const content = this.props.tinyMCEEditor.current.editor.getContent();
    if (content) {
      const h2Elements = document.getElementsByTagName('h2');
      if (showTableContent) {
        for(let i = 0; i < h2Elements.length; i++){
          if (h2Elements[i].textContent && h2Elements[i].textContent.length) {
            const id = h2Elements[i].textContent.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
            h2Elements[i].setAttribute('id', id);
            h2Elements[i].setAttribute('class', 'quickLinks');
          }
        }
      } else {
        for(let i = 0; i < h2Elements.length; i++){
          h2Elements[i].removeAttribute('id');
          h2Elements[i].setAttribute('class', 'split');
        }
      }
    }
    this.props.tinyMCEEditor.current.editor.execCommand('mceSave');
    this.setState({...this.state, checked: !this.state.checked});
  }
  render() {
    const {

      //actions
      showMetaDataModal,
      setMetaDataModal,
      addDisclaimer,

      //data
      articleDetails,
      //action reducers
      setPostCaption,
      setOgTitle,
      setStorySource,
      setArticleTitle,
      setSEOTitle,
      setCustomUrl,
      setMetaTags,
      setMetaSubTags,
      fetchMetaTags,
      classes,
    } = this.props;
    const { title, ogTitle, seoTitle, customUrl, postCaption, tags, storySource } = articleDetails;

    return (
      <Dialog
        open={showMetaDataModal}
        fullWidth={true}
        classes={{ paper: classes.dialogPaper }}
        onClose={this.hide}
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
        className={classes.root}
      >
        <CustomDialogTitle
          setMetaDataModal={setMetaDataModal}>
          Set Article Meta data
        </CustomDialogTitle>
        <DialogContent style={{ overflowY: "visible" }}>

          {
            this.props.articleDetails &&
            this.props.articleDetails.domainId &&
            (this.props.articleDetails.domainId === 9662 || this.props.articleDetails.domainId === 15) &&
            <ArticleCustomUrl
              urlString={customUrl}
              setUrlString={setCustomUrl}
            />
          }

          <ArticleOgTitle
            ogTitle={postCaption}
            setOgTitle={setPostCaption}
          />

          {
            this.props.showTableTable &&
            <div>
              <Checkbox style={{marginLeft: '-15px'}}
                id='ifCheckedContUpdate'
                checked={this.state.checked}
                onChange={this.onCheckboxClick.bind(this)}
                inputProps={{ 'aria-label': 'controlled' }}
              /> Show Table of Content
            </div>
          }

          <ArticleMetaTags
            tags={tags}
            setMetaTags={setMetaTags}
            setMetaSubTags={setMetaSubTags}
            fetchMetaTags={fetchMetaTags}
            getTags={this.allTag.bind(this)}
          />

          <ArticleMetaSource
            storySource={storySource}
            setStorySource={setStorySource}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={
            () => {
              this.setState(this.getDefaultStateValue());
              setMetaDataModal(false);
            }
          }>
            Cancel
        </Button>
          <Button onClick={this.updateMeta.bind(this)}>
            Save and Continue
        </Button>
        </DialogActions>
      </Dialog >)
  }
}

export default withStyles(modalStyles)(ArticleMetaDataModal);

