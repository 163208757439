import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import Apps from "components/Icon/App.jsx";
import schareIcon from "assets/icons/distribution-icon.png";
import earnIcon from "assets/icons/monetization-icon.png";
import contentIcon from "assets/icons/content-icon.png";
import SocialMedia from "../components/social-media";
import Addwebsite from "./add-website";
import CreateType from "../components/creater-type";
import EarnComponent from "../components/earn";
import { fetchFeaturePermision } from "../../login/login-actions";
import AppLoader from "../../app-components/app-loader.jsx";
import { registerDomain, ShowSuccessMessage,setOtherDomainDetail } from "../../domain-management/domain-management-action";
// import FaviconLogo from "../../website-management/favicon-logo/favicon-logo-home-page";
import { InvalidFileError } from '../../cms/quick-cms/quick-cms-actions.js';
import { fetchUserDomains, setActiveDomain } from "../../user-settings/user-settings-actions";
import { SetSocialAccount } from "../../website-management/social-account/social-account-action";
import { setFaviconLogo } from "../../website-management/website-management-actions";
import { saveMediaToPisco } from "../../cms/quick-cms/quick-cms-actions";
import { updateUser, storeFacebookPage } from "../../manage-social/manage-social-actions";
import { setCreateType, getCreateType, bringYourContent,enabldeAd } from "../onboarding-action";
import { setAppMessage } from '../../app-actions.js';
import getNewsletter from '../../manage-social/news-letter/news-letter-action';
import { postNewsletter } from '../../manage-social/news-letter/news-letter-action';
import {
  submitAccountInfo,
  submitOtp,
  openOtpModal,
  closeOtpModal,
  generateOtp,
  getBlacklistedBanks,
  fetchFeatures,
  setFeatures,
  showErrorMessage,
} from '../../analytics/account-info/account-info-actions';
const Share = () => <Apps src={schareIcon} />;
const Earn = ()=> <Apps src={earnIcon} />;
const WebsiteIcon = () => <Apps src={contentIcon} />;

function TabContainer(props) {
    return (
      <Typography component="div" style={{ padding: 8 * 2 }}>
        {props.children}
      </Typography>
    );
}
  
const styles = {
  root: {
    flexGrow: 1,
  },
  tabPosition: {
    position: 'fixed',
    background: 'white',
    zIndex :'999999'
  }
};

class Website extends React.Component {
  state = {
    value: 0,
    earn: true,
    websiteButton: true,
    share: true,
    domainId:null,
    domain: '',
    website: '',
    imageData: '',
    description: '',
    migratueUrl: '',
    file: '',
    isAdEnable: false,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };
  
  increaseState = () => {
    const { value } = this.state;
    if (value == 3) {
      this.setState({ value: 0 })
      return;
    }
    this.setState({value : value +1})
  }

  enableNextTab = (key) => {
    this.setState({ [key]: false });
  }
  disableTab = (key) => {
    this.setState({ [key]: true });
  }
  updateDomainDetailForOnBoard = ({ domainId, domain, website, imageData, description, migratueUrl, file }) => {
    this.setState({ domainId, domain, website, imageData, description, migratueUrl, file });
  }
  
  render() {
    const { classes } = this.props;
      const { value } = this.state;
    return (
      <Paper square className={classes.root}>
        <Tabs
          value={this.state.value}
          onChange={this.handleChange}
          variant="fullWidth"
          indicatorColor="secondary"
          textColor="secondary"
          centered
          classes={
            {scroller :classes.tabPosition } 
          }
            >
          <Tab icon={<PersonPinIcon />} disabled={this.state.value != 0} label="Profile" />
          <Tab icon={<WebsiteIcon />} disabled={this.state.value != 1} label='Website'/>
          <Tab icon={<Share />} disabled={this.state.value != 2} label="Share" />
          <Tab icon={<Earn />} disabled={this.state.value != 3} label="Earn" /> 
        </Tabs>
        <div>
          {value === 0 && <TabContainer>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <CreateType
              setCreateType={this.props.setCreateType}
              createTypes={this.props.createTypes}
              getCreateType={this.props.getCreateType}
              user={this.props.user}
              updateUser={this.props.updateUser}
              increaseState={this.increaseState}
              enableNextTab={this.enableNextTab} />
            </div>
          </TabContainer>}
          {value === 1 && <TabContainer>
            <Addwebsite
              updateDomainDetailForOnBoard={this.updateDomainDetailForOnBoard}
              enableNextTab={this.enableNextTab}
              increaseState={this.increaseState}
              domain={this.state.domain}
              website={this.state.website}
              imageData={this.state.imageData}
              description={this.state.description}
              getNewsletter={this.props.getNewsletter}
              migratueUrl={this.state.migratueUrl}
              file={this.state.file}
              disableTab={this.disableTab}
            />
          </TabContainer>} 
          {value === 2 && <TabContainer>
            <SocialMedia
              activeDomain={this.state.domain}
              storeFacebookPage={this.props.storeFacebookPage}
              getNewsletter={this.props.getNewsletter}
              website_visible={this.props.website_visible}
              updateUser={this.props.updateUser}
              postNewsletter={this.props.postNewsletter}
              enableNextTab={this.enableNextTab}
              setAppMessage={this.props.setAppMessage}
              increaseState={this.increaseState}
              fetchFeatures={this.props.fetchFeatures}
            />
          </TabContainer>}
          {value == 3 && <TabContainer>
            <EarnComponent
              enableNextTab={this.enableNextTab}
              // increaseState={this.increaseState}
              fetchFeatures={this.props.fetchFeatures}
              features={this.props.features}
              isFetchingFeatures={this.props.isFetchingFeatures}
              isSettingFeatures={this.props.isSettingFeatures}
              setFeatures={this.props.setFeatures}
              subscriptionEnabled={this.props.subscriptionEnabled}
              enabldeAd={this.props.enabldeAd}
              isAdEnable = {this.state.isAdEnable}
              showErrorMessage={this.props.showErrorMessage}
            /></TabContainer>}   
        </div>
      </Paper>
    );
  }
}
const mapStateToProps = (state) => {
  const { isDomainLoading, createType } = state.app;
  let { user } = state.login;
  const { featurePermision } = state.login.domain;
  const { activeDomain } = state.login.user;
  const firstName = localStorage.getItem("firstName") || ''
  const lastName = localStorage.getItem("lastName") || '';
  const {
    login: {
      user: {
        email
      }
    },
    analysis: {
      accountInfo: {
        isSubmittingInfo,
        isSubmittingOtp,
        showOtpModal,
        otpError,
        otpVerified,
        blacklistedBanks,
        features,
        isFetchingFeatures,
        isSettingFeatures,
        agreementHtml,
        submittingAgreement,
        agreementSubmitted,
        bankDetails,
        fetchingBankDetails,
        subscriptionEnabled,
      }
    }
  } = state;
  const { website_visible, isNewsLetterLoading } = state.manageSocialReducer;

  if (!user.firstName) {
    user = {
      firstName,
      lastName: lastName ? lastName : ''
    }
  }
  return {
    website_visible,
    isNewsLetterLoading,
    isDomainLoading,
    featurePermision,
    createTypes: createType,
    user,
    activeDomain,
    isSubmittingInfo,
    isSubmittingOtp,
    showOtpModal,
    otpError,
    otpVerified,
    blacklistedBanks,
    features,
    isFetchingFeatures,
    isSettingFeatures,
    agreementHtml,
    submittingAgreement,
    agreementSubmitted,
    bankDetails,
    fetchingBankDetails,
    subscriptionEnabled,
   };
}

const mapDispatchToProps = (dispatch) => {

  return {
    fetchUserDomains: () => dispatch(fetchUserDomains()),
    fetchFeaturePermision: () => dispatch(fetchFeaturePermision()),
    registerDomain: (domain) => dispatch(registerDomain(domain)),
    setActiveDomain: (domainId) => dispatch(setActiveDomain(domainId)),
    SetSocialAccount: (social, msg) => dispatch(SetSocialAccount(social, msg)),
    saveMediaToPisco: (file) => dispatch(saveMediaToPisco(file)),
    setFaviconLogo: (favicon, logo, silent) => dispatch(setFaviconLogo(favicon, logo, silent)),
    ShowSuccessMessage: (msg) => dispatch(ShowSuccessMessage(msg)),
    InvalidFileError: (msg) => dispatch(InvalidFileError(msg)),
    setCreateType: (type) => dispatch(setCreateType(type)),
    getCreateType: () => dispatch(getCreateType()),
    bringYourContent: (migration) => dispatch(bringYourContent(migration)),
    updateUser: (user) => dispatch(updateUser(user)),
    setAppMessage: (message, obj) => dispatch(setAppMessage(message, obj)),
    getNewsletter: (isOnBoard, domainId) => dispatch(getNewsletter(isOnBoard, domainId)),
    postNewsletter: (silent) => dispatch(postNewsletter(silent)),
    submitAccountInfo: (data) => dispatch(submitAccountInfo(data)),
    submitOtp: (otp, phoneNumber) => dispatch(submitOtp(otp, phoneNumber)),
    openOtpModal: () => dispatch(openOtpModal()),
    closeOtpModal: () => dispatch(closeOtpModal()),
    generateOtp: (phoneNumber) => dispatch(generateOtp(phoneNumber)),
    submitOtp: (otp, phoneNumber) => dispatch(submitOtp(otp, phoneNumber)),
    getBlacklistedBanks: () => dispatch(getBlacklistedBanks()),
    fetchFeatures: () => dispatch(fetchFeatures()),
    setFeatures: (data, silent) => dispatch(setFeatures(data, silent)),
    enabldeAd: (val) => dispatch(enabldeAd(val)),
    storeFacebookPage: (arrPages) => dispatch(storeFacebookPage(arrPages)),
    showErrorMessage: (msg) => dispatch(showErrorMessage(msg)),
  }
}
Website.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(styles)(Website));