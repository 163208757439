import React from "react";
import SidePane from "react-sliding-pane";
import Pisco from "pisco-ui";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Cropper from 'react-easy-crop'
import { Button } from "@material-ui/core";
import AppLoader from "app-components/app-loader.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";


class PiscoStoryUpload extends React.Component {

  state = {
    showSidePane: false,
    image: 'https://raw.githubusercontent.com/ramon82/assets/master/zuck.js/users/1.jpg',
    content: {},
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 16 / 9,
    step: 1,
    croppedAreaPixels: {},
    heading: '',
    actionLink: '',
    error: {
      emailError: "Title is required.",
    }
  }

  toggleSidePane = () => {
    this.setState((prev) => {
      return {
        showSidePane: !prev.showSidePane,
        step: 1,
        heading: '',
        actionLink: '',
      }
    })
  }
  onCropChange = crop => {
    this.setState({ crop })
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  }

  onZoomChange = zoom => {
    this.setState({ zoom })
  }

  handleHeading = (event) => {
    this.setState({ heading: event.target.value })
  }

  handleActionLink = (event) => {
    this.setState({ actionLink: event.target.value })
  }
  saveCoverImage = () => {
    const { content, croppedAreaPixels, heading, actionLink } = this.state;
    this.props.addNewStory(content, croppedAreaPixels, heading, actionLink, this.toggleSidePane);
  }
  render() {

    const {
      heading,
      actionLink,
      error: { emailError, passwordError }
    } = this.state;
    return (
      <>
      <div onClick={this.toggleSidePane} style={{ display: "flex", alignItems: "center", cursor: "pointer", margin: "20px 0 20px" }}>
        <AddCircleIcon style={{ color:'#2B2D42' }} />&nbsp;&nbsp;<span style={{ color: "#2B2D42" }}>Upload Story</span>
      </div>
      <SidePane
      overlayClassName='pisco-pane'
      isOpen={this.state.showSidePane}
      title='Upload Story'
      from='right'
      width='80%'
      onRequestClose={this.toggleSidePane}
        >
          {this.props.newStoryLoading && <span><AppLoader message="Creating Story..." /></span>}
          {!this.props.newStoryLoading && this.state.step === 1 && <Pisco
            endpoint={process.env.REACT_APP_API_SERVER}
            headers={{
              authorization: localStorage.getItem('accessToken')
            }
            }
            injectQuery={{ domainId: 15 }}
            disableGetty={this.props.domain !== 'meaww'}
            type="Image"
            mode="embed"
            onEmbed={content => {
              this.setState({ step: 2, content: content });
            }} />}
          {this.state.step === 2 &&
            <>
              <h3 style={{ textAlign: 'center' }}>Story Cover Photo</h3>
              <div style={{ position: 'relative', width: '60%', height: '60%', margin: '5% auto' }}>

                <Cropper
                  image={this.state.content.s3Url || this.state.image}
                  crop={this.state.crop}
                  zoom={this.state.zoom}
                  aspect={this.state.aspect}
                  onCropChange={this.onCropChange}
                  onCropComplete={this.onCropComplete}
                  onZoomChange={this.onZoomChange}
                  cropShape='round'
                  cropSize={{ width: 300, height: 300 }}
                />
              </div>
            </>
          }
          {this.state.step === 2 &&
            <div style={{
              position: 'relative',
              width: '60%', height: '60%', margin: '3% auto', textAlign: 'center'
            }}>
              <Button variant="contained" color="primary" onClick={() => { this.setState({ step: 3 }) }}>Add Title & Link</Button>
            </div>
          }
          {this.state.step === 3 &&
            <>
              <div style={{ position: 'relative', width: '40%', height: '60%', margin: '20% auto', textAlign: 'center' }}>
                <h4>Story Title and Action Link</h4>
                <CustomInput
                  labelText="Story Title"
                  //infoText="Title to be shown for the story."
                  id="story_title"
                  value={heading}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disableUnderline: true,
                    onChange: this.handleHeading,
                  }}
                  helpText={emailError}
                /><br />
                <CustomInput
                  labelText="Action Link"
                  //infoText="Link of the article or story to open when click on read more."
                  id="action_link"
                  value={actionLink}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disableUnderline: true,
                    onChange: this.handleActionLink,
                  }}
                /><br /><br />
                <Button variant="contained" onClick={this.saveCoverImage} disabled={!heading.trim().length} color="primary">Save Story</Button>
              </div>
            </>
          }
        </SidePane>
      </>
    )
  }
}

export default PiscoStoryUpload;
