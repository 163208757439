import React, { PureComponent, StrictMode } from 'react';

import Card from "components/Card/Card.jsx";
import RevenueGraph from "./revenue-graph.jsx";
import AppLoader from "../../../app-components/app-loader.jsx";

const noDataFoundStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#ced3dc",
  fontWeight: "bold"
}

function RenderRevenueData({ header, isLoadingRevenues, RenderData, revenues, graphDataType, startDate, endDate, classes, isShowingRevenueStats, ...rest }) {
  if (isLoadingRevenues) {
    return <AppLoader message={`${header} loading...`} />
  }
  if (revenues.length === 0 && !isShowingRevenueStats) {
    return (<h4 style={{ ...noDataFoundStyles }}> No Data Found</h4 >)
  }
  return (<RenderData
    revenues={revenues}
    startDate={startDate}
    endDate={endDate}
    graphDataType={graphDataType}
  />)
}

class RevenueCard extends PureComponent {
  render() {
    const {
      renderData: RenderData = RevenueGraph,
      showTitleOnHeader = false,
      header = "",
    } = this.props;

    return (
      <div style={{ background: "white", border:'1px solid #ced3dc', marginTop:'30px', marginBottom:'30px' }}>
        {
          showTitleOnHeader && <h4 style={{ fontWeight: "bold", textAlign: "left", fontSize: "18px", marginTop: "0px" }} > {header}</h4>
        }
        <div style={{ minHeight: (!showTitleOnHeader ? "194px" : "0px"), position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <RenderRevenueData {...this.props} RenderData={RenderData} />
        </div>
        {
          !showTitleOnHeader && <h4 style={{ fontWeight: "bold", textAlign: "center", fontSize: "18px" }} >{header}</h4>
        }
      </div >
    )
  }
}

export default RevenueCard;
