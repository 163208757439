import React from "react";
import CreateNewArticleContainer from "./view/create-new-article-container";
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});


class ArticleHomePage extends React.PureComponent {
  render() {
    return (
      <>
        <CreateNewArticleContainer />
        <Grid container spacing={12} style={{ marginTop:"50px"}}>
        <Grid item xs={3}>
            <Paper style={{ margin: "5px", height: "200px" }}>
              <img src = {`https://i.ytimg.com/vi/9QbltzIUV6w/hqdefault.jpg`}
                width="100%"
                height="100%"
                style = {{cursor: 'pointer'}}
                //onClick = {props.changeiframeState}
              />
            </Paper>
        </Grid>
        <Grid item xs={3}>
            <Paper style={{ margin: "5px", height: "200px" }}>
              <img src = {`https://i.ytimg.com/vi/LCCns4LXpng/hqdefault.jpg`}
                width="100%"
                height="100%"
                style = {{cursor: 'pointer'}}
                //onClick = {props.changeiframeState}
              />
            </Paper>
        </Grid>
        <Grid item xs={3}>
            <Paper style={{ margin: "5px", height: "200px" }}>
              <img src = {`https://i.ytimg.com/vi/wZeQdrt1lYQ/hqdefault.jpg`}
                width="100%"
                height="100%"
                style = {{cursor: 'pointer'}}
                //onClick = {props.changeiframeState}
              />
            </Paper>
        </Grid>
        <Grid item xs={3}>
            <Paper style={{ margin: "5px", height: "200px" }}>
              <img src = {`https://i.ytimg.com/vi/6d_pIkk512A/hqdefault.jpg`}
                width="100%"
                height="100%"
                style = {{cursor: 'pointer'}}
                //onClick = {props.changeiframeState}
              />
            </Paper>
        </Grid>
      </Grid>
      </>
    );
  }
}

export default withStyles(styles)(ArticleHomePage);
