import { RevenueAction, MessageTypes } from "../../app-constants/index.js";
import { setAppMessage } from "../../app-actions.js";
import { MeawwApi } from "../../utils/api.js";
import { VariableTimeSpecificRevenueTypes, RevenueDataTypes } from "../../app-constants/index.js";

const fetchFixedTimeSpecificRevenuesRequest = () => ({
  type: RevenueAction.LOAD.FIXED_TIME_SPECIFIC_REVENUES.REQUEST,
});

const fetchFixedTimeSpecificRevenuesFailure = () => ({
  type: RevenueAction.LOAD.FIXED_TIME_SPECIFIC_REVENUES.FAILURE,
});

const fetchFixedTimeSpecificRevenuesSuccess = data => ({
  type: RevenueAction.LOAD.FIXED_TIME_SPECIFIC_REVENUES.SUCCESS,
  data: data
});

export function fetchFixedTimeSpecificRevenues() {
  return (dispatch, getState) => {
    const { login: { user: { activeDomain } } } = getState();
    dispatch(fetchFixedTimeSpecificRevenuesRequest());
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/customers/revenue?domainId=${activeDomain}`,
          (error, data) => {
            if (error) {
              dispatch(fetchFixedTimeSpecificRevenuesFailure());
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return;
            }
            dispatch(fetchFixedTimeSpecificRevenuesSuccess({ revenues: data }));
          })
      })
  }
}

const fetchVariableTimeSpecificRevenuesRequest = () => ({
  type: RevenueAction.LOAD.VARIABLE_TIME_SPECIFIC_REVENUES.REQUEST,
});

const fetchVariableTimeSpecificRevenuesFailure = () => ({
  type: RevenueAction.LOAD.VARIABLE_TIME_SPECIFIC_REVENUES.FAILURE,
});

const fetchVariableTimeSpecificRevenuesSuccess = (revenueType, data) => ({
  type: RevenueAction.LOAD.VARIABLE_TIME_SPECIFIC_REVENUES.SUCCESS,
  data: data,
  revenueType: revenueType
});

export function fetchVariableTimeSpecificRevenues(revenueType, options = {}) {
  return (dispatch, getState) => {
    const {
      login: {
        user: { activeDomain }
      },
      analysis: {
        revenue: {
          variableTimeSpecificRevenues: {
            variableRevenuesSettings: {
              startDate,
              endDate
            }
          }
        }
      }
    } = getState();

    dispatch(fetchVariableTimeSpecificRevenuesRequest(revenueType));
    const { output = [] } = options;
    const outputTypesQueryParams = output.map(option => `&output[]=${option}`).join("");
    const queryParams = `domainId=${activeDomain}&startDate=${startDate.format("YYYY[-]MM[-]DD")}&endDate=${endDate.format("YYYY[-]MM[-]DD")}${outputTypesQueryParams}`;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/customers/revenueStats?${queryParams}`,
          (error, data) => {
            if (error) {
              dispatch(fetchVariableTimeSpecificRevenuesFailure(revenueType));
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return
            }
            dispatch(fetchVariableTimeSpecificRevenuesSuccess(revenueType, { revenues: data }));
            return
          });
      })
  }
}

const setDateRangeInterval = (startDate, endDate) => ({
  type: RevenueAction.SET.VARIABLE_TIME_SPECIFIC_REVENUES.DATE_RANGE,
  startDate: startDate,
  endDate: endDate
});

export function setRevenuesDateRangesInterval(startDate, endDate) {
  return (dispatch) => {
    dispatch(setDateRangeInterval(startDate, endDate));
    dispatch(fetchVariableTimeSpecificRevenues(VariableTimeSpecificRevenueTypes.DAILY_ECPM, { startDate, endDate, output: [RevenueDataTypes.ECPM] }));
    dispatch(fetchVariableTimeSpecificRevenues(VariableTimeSpecificRevenueTypes.DAILY_REVENUE, { startDate, endDate, output: [RevenueDataTypes.REVENUE] }));
    dispatch(fetchVariableTimeSpecificRevenues(VariableTimeSpecificRevenueTypes.REVENUE_STATS, { startDate, endDate, output: [RevenueDataTypes.ECPM, RevenueDataTypes.REVENUE, RevenueDataTypes.SESSIONS] }));
  }
}
