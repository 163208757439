import React, { PureComponent } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import feedStyle from "../../../assets/jss/material-dashboard-pro-react/components/feedStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
// import twitterRender from "../feedRender.js";

class TwitterContainer extends PureComponent {
  constructor(props) {
    super(props);
    // this.twitterContainer = React.createRef();
    this.highlightContainer = React.createRef();
  }
  unhighlightfeedMove() {
    const { twitrId, moveFeed } = this.props;
    moveFeed(twitrId, "twitterFeeds", "highlight");
  }
  highlightfeedMove() {
    const { twitrId, moveFeed } = this.props;
    moveFeed(twitrId, "twitterFeeds", "unhighlight")
  }
  twitterRender() {

    const { highlight, twitrId, individual, autoHighlighted, classes } = this.props;
    if (twitrId) {
      const feedId = `#${highlight ? 'highlight' + twitrId : 'unhighlight' + twitrId}`;

      window.twttr && window.twttr.init && window.twttr.widgets.createTweet(
        twitrId,
        document.querySelector(feedId),
        {
          align: 'center',
          conversation: 'none',
          dnt: true,
          width: 250,
          height: 300
        }
      ).then((d) => {
        document.querySelector(`#renderLoader${twitrId}`) && (document.querySelector(`#renderLoader${twitrId}`).style.display = 'none')
        if (individual) {
          return null
        }
      })
    }
  }
  componentDidMount() {
    this.twitterRender()
  }

  render() {
    const { highlight, twitrId, autoHighlighted, classes } = this.props;
    return (
      <div id={`${highlight ? 'highlight' + twitrId : 'unhighlight' + twitrId}`} style={{display:'inline-block',marginRight:'2%',textAlign:'justify', height: '300px', overflowY: 'scroll', marginTop: '16px' }} >
        {autoHighlighted ? <Tooltip title="Auto hightlighted tweet" placement="right">
            <i className={`material-icons ${classes.hightlight}`} >
              {highlight || autoHighlighted ? 'bookmark' : 'bookmark_border'}
            </i></Tooltip> :
          <Tooltip title={`Click to ${highlight ? 'unhighlight' : 'highlight'}`} placement="right">
            <i className={`material-icons ${classes.hightlight}`} onClick={_ => {
              highlight ? this.highlightfeedMove() : this.unhighlightfeedMove()
            }}>
              {highlight || autoHighlighted ? 'bookmark' : 'bookmark_border'}
            </i></Tooltip>}
        <div id={`renderLoader${twitrId}`} style={{ width: '250px', height: '300px', display: "flex", justifyContent: "center", alignItems: "center" }}><h6>Loading...</h6></div>
      </div>
    )
  }
}

export default withStyles(feedStyle)(TwitterContainer);