import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { Helmet } from "react-helmet";
import { Row, Col, Layout } from "antd";
// import UserProfile from "./components/edit-profile.jsx";
import ProfileDetails from "./view/profile-details.jsx";

class UserProfileDetailsPage extends Component {
  render() {
    return (
      <ProfileDetails />
    )
  }
}

export default UserProfileDetailsPage;


