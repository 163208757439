import React from 'react';
import arrowSvg from "../../../assets/icons/arrow.svg";

function jsonConcat(o1, o2) {
  for (var key in o2) {
    o1[key] = o2[key];
  }
  return o1;
}
function Post2(props) {
  const { storyData: { coverImage, title, description, action }, style, isStory, removeField } = props;

  const trimmedTitle = title.length > 118 ? title.substring(0,118) + '...' : title;
  const trimmedDescription = description.length > 118 ? description.substring(0,118) + '...': description;

  return (
    <div style={style}>
      <div style={style.body}>
        <div style={style.body.title}>
          {title}
        </div>
      </div>
      <div>
        <img
          src={coverImage}
          alt="thumbnail"
          style={style.coverImage} />
      </div>
      <div>
        {removeField == 'description' ? <></>
          : <div style={style.body.description}>
            {description}
          </div>
        }
        {isStory && <><div style={style.footer}>
        </div>
          <div style={style.swipeUpButtonWrapper}>
            <div style={jsonConcat({ display: 'flex', flexDirection: 'column', marginBottom: '-10px' }, style.swipeUpStyle)}>
              <img src={arrowSvg} />
              <img src={arrowSvg} style={jsonConcat({ marginTop: '-31px', opacity: '0.5' }, style.swipeUpSvg)} />
            </div>
            <div style={style.swipeUpButton}>{action}</div>
          </div>
        </>
        }
      </div>
    </div>
  );
}

export default Post2;
