import React from 'react';
import ReactTable from "react-table";
import moment from "moment";

class SubscribersRevenueTable extends React.Component {
  add(data, prop) {
    var total = 0
    for (var i = 0, _len = data.length; i < _len; i++) {
      total += data[i][prop]
    }
    return total
  }

  calTableTotal = (data) => {
    const subsRevInr = this.add(data, "subsRevInr").toFixed(2)
    const subsRevUsd = (this.add(data, "subsRevUsd") ).toFixed(2)
    return { subsRevInr, subsRevUsd }

  }
  render() {
    const { data } = this.props;
    let { subsRevInr, subsRevUsd } = data.length > 0 && this.calTableTotal(data);

    return (
      <>
        <ReactTable
          data={data}
          noDataText="No Data"
          defaultPageSize={10}
          pageSizeOptions={[5, 10, 25, 50]}
          columns={[
            {
              Header: "Date",
              headerStyle: { textAlign: "left", fontWeight: "bold" },
              accessor: 'date'
            },
            {
              Header: "(INR)",
              headerStyle: { textAlign: "left", fontWeight: "bold" },
              accessor: 'subsRevInr',
              Footer: (
                subsRevInr && <span>
                  <strong>Total:</strong>{" "}
                  {subsRevInr}
                </span>
              )
            },
            {
              Header: "(USD)",
              headerStyle: { textAlign: "left", fontWeight: "bold" },
              accessor: 'subsRevUsd',
              Footer: (
                subsRevUsd && <span>
                  <strong>Total:</strong>{" "}
                  {subsRevUsd}
                </span>
              )
            },
          ]}
        />
      </>
    )
  }
}

export default SubscribersRevenueTable;
