import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from "@material-ui/core/styles/withStyles";
import beingEditedStyle from "../../../assets/jss/material-dashboard-pro-react/views/beingEditedStyle.jsx";


const BeingReviewed = (props) => {
  const { classes, firstName = "", lastName = "", profilePicture, isShow = true } = props;
  return (
    <div className={classes.beingEditedWrapper} >
      {isShow && <div className={classes.round}>
        <Tooltip disableFocusListener title={`${firstName} ${lastName}`.replace(/(^\w)|( \w)/g, c => c.toUpperCase())}>
          {profilePicture ? <img src={profilePicture} className={classes.beingEditedprofilePic} alt="" /> : < h5 className={classes.name} > {(firstName || 'User').charAt(0).toUpperCase() + (lastName|| 'User').charAt(0).toUpperCase()}</h5>}
        </Tooltip>
      </div>}
      <span className={classes.beingEditedText}>Being edited by {firstName}</span>
    </div>
  )
}
export default withStyles(beingEditedStyle)(BeingReviewed);