export function getArticleDetailsFromArticleId(cms, articleId) {
    if (articleId) {
        return cms.articles.articlesByIds[articleId] || {};
    }
    return {};
}
export function getArticleContentFromArticleId(cms, articleId) {
    const article = cms.articles.articlesByIds[articleId];
    if (article) {
        const contents = article.contents;
        if (contents) {
            return contents
        }
    }
    return ""
}
