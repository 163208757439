import React, { PureComponent } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import articlesStyle from "../../../assets/jss/material-dashboard-pro-react/views/articlesStyle.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

class EditerPickCard extends React.Component {

  state = {
    headline: '',
  };

  headlineChange({ target: { value } }) {
    this.setState({headline: value});
  }

  render() {
    const { classes, article, type, selectNewArticle } = this.props;
    return (
      <GridContainer key={article.articleId + 1} justify="center" style={{ width: `${type == 'SET' || type == 'ADD' ? '99%' : 'unset'}` }} className={classes.articleContainer}>
        <GridItem xs={10} sm={10} md={10} key={article.articleId + 2}>
          <h5 className={classes.articleTitle}>
            {article.title}<br />
            <span style={{ fontSize: "0.8em", padding: "5px" }}> - by {`${article.author.firstname} ${article.author.lastname}`}</span>
          </h5>
        </GridItem>
        <GridItem xs={10} sm={10} md={10}>
          <CustomInput
              labelText="Headline"
              infoText="Enter Headline to be show on homepage"
              id="headline"
              value={this.state.headline}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: this.headlineChange.bind(this),
              }}
            />
        </GridItem>
        <GridItem xs={2} sm={2} md={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={article.articleId} onClick={() => { selectNewArticle(article, this.state.headline)}}>
          <Button style={{ color: '#2b2d42' }}>
            {type}
          </Button>
        </GridItem>
      </GridContainer>
    )
  }
}

export default withStyles(articlesStyle)(EditerPickCard);
