import { combineReducers } from "redux";
import landingPages from "../tool/landing-page/landing-page-reducers.js";
import editerPick from "../tool/editer-pick/editer-pick-reducer.js";

const toolReducers = combineReducers({
    landingPages,
    editerPick,
});

export default toolReducers;
