import React from "react";
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const UserActionsButton = ({ handleClick, open }) => (
  <IconButton
    aria-label="More"
    aria-owns={open ? 'long-menu' : undefined}
    aria-haspopup="true"
    onClick={handleClick}
  >
    <MoreVertIcon />
  </IconButton>
);

export default UserActionsButton;