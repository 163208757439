import React, { PureComponent } from "react";
import FeedList from "./views/feed.jsx";

class ContentDiscoveryHomePage extends PureComponent {
    
    render() {
        return (<FeedList />)
    }
}

export default ContentDiscoveryHomePage;