import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Paper from '@material-ui/core/Paper';
import withStyles from "@material-ui/core/styles/withStyles";
import articleHomeStyle from '../assets/jss/material-dashboard-pro-react/views/articlehomeStyle';
// core components
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import NoData from "../app-components/no-data-found";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 2 }}>
      {props.children}
    </Typography>
  );
}

class PlatformIntegration extends PureComponent {
  state = {
    value: parseInt(localStorage.getItem('platform-key')) || 0,
    showSearch: false,
  };
  handleChange = (event, value) => {
    localStorage.setItem('platform-key', value);
    this.setState({ value });
  };
  setShowSearch = (flag) => {
    this.props.showLiveTab();
    this.setState({ ...this.state, ['showSearch']: flag })
  }
  render() {
    const { isShowLiveTab, classes } = this.props;
    const { value } = this.state;
    return (<div>
      <Helmet title="WordPress Import | PubNinja - You Publish. The rest we manage." />
      {this.props.children}
      <Paper xs={12} sm={12} md={12} className={classes.root} >

        <Tabs
          fullWidth
          value={value}
          onChange={this.handleChange}
          scrollable
          scrollButtons="on"
          textColor="inherit"
          classes={{
            root: classes.tabsRoot
          }}
        >
          <Tab label="IA" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
          <Tab label="AMP " classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
          <Tab label="ANF " classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
          <Tab label="Flipboard " classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />

        </Tabs>
        {value === 0 && <TabContainer><NoData fontSize='2em' pageNo={0} dataTypeName="IA Articles" /></TabContainer>}
        {value === 1 && <TabContainer><NoData fontSize='2em' pageNo={0} dataTypeName="AMP Articles" /></TabContainer>}
        {value === 2 && <TabContainer><NoData fontSize='2em' pageNo={0} dataTypeName="ANF Articles" /></TabContainer>}
        {value === 3 && <TabContainer><NoData fontSize='2em' pageNo={0} dataTypeName="Flipboard Articles" /></TabContainer>}

      </Paper >
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(articleHomeStyle)(PlatformIntegration));
