
import { StoriesAction, MessageTypes } from "../../app-constants/index.js";
import { setAppMessage } from "../../app-actions.js";
import { MeawwApi } from "../../utils/api.js";

function loadLiveStoriesRequest() {
  return {
    type: StoriesAction.LOAD.REQUEST
  }
}
function loadLiveStoriesFailure() {
  return {
    type: StoriesAction.LOAD.FAILURE
  }
}
function loadLiveStoriesSuccess(articles) {
  return {
    type: StoriesAction.LOAD.SUCCESS,
    articles: articles
  }
}

export function loadLiveStories() {
  return (dispatch, getState) => {
    const { login: { user: { activeDomain } }, stories: { pageNo } } = getState();
    dispatch(loadLiveStoriesRequest());
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/article/${activeDomain}?live=1&page=${pageNo}&perPage=50`,
          (error, data) => {
            if (error) {
              dispatch(loadLiveStoriesFailure());
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return;
            }
            dispatch(loadLiveStoriesSuccess(data.result));
            return
          })
      })
  }
}

export function setLiveArticlesPageNumber(pageNo) {
  return {
    type: StoriesAction.SET.PAGE_NUMBER,
    pageNo: pageNo
  }
}