import { combineReducers } from "redux";
import {
  UserAction
} from "../app-constants/index.js";
import { NewsLetter } from "../app-constants";


//--------------------Disclaimers
const defaultManageSocialState = {
  isLoadingFacebook: false,
  isLoadingTwitter: false,
  isNewsLetterLoading: false,
  isNewsLetterActionLoading: false,
  isNewsLetterCampagionLoading: false,
  facebookPages: null,
  // facebookPages: [
  //   {name: 'Thoughts of a backbencher'},
  //   {name: 'The DBC'}
  // ],
  twitterPages: null,
  // twitterPages: [
  //   {screen_name: 'Elon Musk', id: '143'},
  //   {screen_name: 'Neil Degrasse Tyson'},
  //   {screen_name: 'Namo'}
  // ]
  isDeleting: false,
  isFullDetailLoading: false,
  fullDetail: {},
  viewCamp: {}
}

const manageSocialReducer = (state = defaultManageSocialState, action) => {
  switch (action.type) {
    case UserAction.SET.ACTIVE_DOMAIN:
    case UserAction.SIGN_OUT.SUCCESS: {
      return defaultManageSocialState;
    }
    case 'fetchFbRequest': {
      return { ...state, isLoadingFacebook: true }
    }
    case 'fetchFbSuccess': {
      return {
        ...state,
        facebookPages: action.data,
        //facebookPages: defaultManageSocialState.facebookPages,
        isLoadingFacebook: false
      }
    }
    case 'fetchFbFailure': {
      return { ...state, isLoadingFacebook: false }
    }
    case 'fetchTwitterRequest': {
      return { ...state, isLoadingTwitter: true }
    }
    case 'fetchTwitterSuccess': {
      return {
        ...state,
        twitterPages: action.data,
        //twitterPages: defaultManageSocialState.twitterPages,
        isLoadingTwitter: false
      }
    }
    case 'fetchTwitterFailure': {
      return { ...state, isLoadingTwitter: false }
    }
    case 'deleteItemRequest': {
      return { ...state, isDeleting: true }
    }
    case 'deleteItemSuccess': {
      return { ...state, isDeleting: false }
    }
    case 'deleteItemFailure': {
      return { ...state, isDeleting: false }
    }
    case NewsLetter.GET.SUCCESS: {
      return { ...state, ...action.data, isNewsLetterLoading: false }
    }
    case NewsLetter.GET.REQUEST: {
      return { ...state, isNewsLetterLoading: true }
    }
    case NewsLetter.GET.FAILURE: {
      return { ...state, isNewsLetterLoading: false }
    }
    case NewsLetter.ACTION.REQUEST: {
      return { ...state, isNewsLetterActionLoading: true }
    }
    case NewsLetter.ACTION.FAILURE: {
      return { ...state, isNewsLetterActionLoading: false }
    }
    case NewsLetter.ACTION.SUCCESS: {
      return { ...state, isNewsLetterActionLoading: false }
    }
    case NewsLetter.GET_CAMPAIGN.SUCCESS: {
      return { ...state, isNewsLetterCampagionLoading: false, viewCamp: action.data }
    }
    case NewsLetter.GET_CAMPAIGN.REQUEST: {
      return { ...state, isNewsLetterCampagionLoading: true, viewCamp: action.data }
    }
    case NewsLetter.GET_CAMPAIGN.FAILURE: {
      return { ...state, isNewsLetterCampagionLoading: false, viewCamp: action.data }
    }
    case NewsLetter.FUll_DETAIL.REQUEST: {
      return { ...state, isFullDetailLoading: true, fullDetail: action.data }
    }
    case NewsLetter.FUll_DETAIL.FAILURE: {
      return { ...state, isFullDetailLoading: false, fullDetail: action.data }
    }
    case NewsLetter.FUll_DETAIL.SUCCESS: {
      return { ...state, isFullDetailLoading: false, fullDetail: action.data }
    }
    default: return state;
  }
}

export default manageSocialReducer;
