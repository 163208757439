import React from 'react';
import { connect } from 'react-redux';
import moment from "moment";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import AppLoader from "app-components/app-loader.jsx";

import DateRangePicker from '../components/date-range-picker.jsx';
import SelectedDateRange from '../components/selected-date-range.jsx';

import AdsRevenueTable from '../components/ads-revenue-table.jsx';
import SubscribersRevenueTable from '../components/subscribers-revenue-table.jsx';
import TrafficTable from '../components/traffic-table.jsx';
import PageViewsTable from '../components/page-views-table.jsx';

import {
  setDateRangeInterval,
  getStatistics,
  getPageviews,
} from '../statistics-actions.js';

class Statistics extends React.Component {
  state = {
    showCalendar: false,
    dateRange: {
      selection: {
        startDate: this.props.startDate,
        endDate: this.props.endDate,
        key: 'selection',
      },
    }
  }

  componentDidMount() {
    this.props.getStatistics();
    //this.props.getPageviews();
  }

  toggleCalendar = () => {
    this.setState({
      showCalendar: !this.state.showCalendar
    })
  }

  setDateRanges = (payload) => {
    const { setDateRangeInterval } = this.props;
    this.setState({
      dateRange: {
        ...this.state["dateRange"],
        ...payload,
      },
    });

    const { selection: { startDate, endDate } } = payload;
    if (startDate.getTime() !== endDate.getTime()) {
      this.setState({ showCalendar: false });
      setDateRangeInterval(moment(startDate), moment(endDate));
    }
  }

  render() {
    //console.log('####', moment().format(), '&&&', moment().unix(), 'sdassa', moment.unix(moment().unix()).format());
    const { startDate, endDate, isLoadingStatistics, statistics, isLoadingPageViews, pageviews } = this.props;
    const { showCalendar, dateRange } = this.state;
    if(isLoadingStatistics) {
      return <AppLoader />
    }
    if(!statistics)
      return <></>

    const { revenueAds, revenueSubscribers, traffic } = statistics;
    return (
      <>
        <GridContainer justify="flex-start">
          <GridItem xs={12} sm={12} md={12} style={{height: '50px'}}>
            <div style={{ position: "relative", paddingBottom: "25px", marginTop: "2px" }}>
              <div style={{ position: "absolute" }} >
                <SelectedDateRange startDate={startDate} endDate={endDate} onClick={this.toggleCalendar} />
                <DateRangePicker
                  style={{ position: "absolute", right: "0px", marginTop:'60px' }}
                  showCalendar={showCalendar}
                  dateRange={dateRange}
                  setDateRanges={this.setDateRanges}
                  style={{ zIndex: "200" }}
                  startDate={startDate}
                  endDate={endDate}
                />
              </div>
            </div>
          </GridItem >
          <GridItem xs={12} md={4} >
            <div style={{fontWeight: 'bold', fontSize: '1.1em', padding: '30px 0 25px 0'}}>
              Revenue from Ads
            </div>
            <AdsRevenueTable data={statistics}/>
          </GridItem>
          <GridItem xs={12} md={4} >
            <div style={{fontWeight: 'bold', fontSize: '1.1em', padding: '30px 0 25px 0'}}>
              Revenue from Subscribers
            </div>
            <SubscribersRevenueTable data={statistics}/>
          </GridItem>
          <GridItem xs={12} md={4} >
            <div style={{fontWeight: 'bold', fontSize: '1.1em', padding: '30px 0 25px 0'}}>
              Traffic Insights
            </div>
            <TrafficTable data={statistics}/>
          </GridItem>
          <GridItem xs={12} >
            <div style={{fontWeight: 'bold', fontSize: '1.1em', padding: '30px 0 25px 0'}}>
              Sessions
            </div>
            <PageViewsTable data={pageviews} getPageviews={this.props.getPageviews}/>
          </GridItem>
        </GridContainer>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    analysis: {
      statistics: {
        startDate, endDate, isLoadingStatistics, statistics,
        isLoadingPageViews, pageviews,
      }
    }
  } = state;

  return {
    startDate, endDate, isLoadingStatistics, statistics,
    isLoadingPageViews, pageviews,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDateRangeInterval: (startDate, endDate) => dispatch(setDateRangeInterval(startDate, endDate)),
    getStatistics: () => dispatch(getStatistics()),
    getPageviews: (startDate, endDate, pageNum, order, orderBy, limit) => dispatch(getPageviews(startDate, endDate, pageNum, order, orderBy, limit)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
