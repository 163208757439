
import { StoriesAction, UserAction } from "../../app-constants/index.js";

const storiesDefaultState = {
  articles: [],
  pageNo: 1,
  isLoading: false,
}

const stories = (state = storiesDefaultState, action) => {
  switch (action.type) {
    //resetting
    case UserAction.SIGN_OUT.SUCCESS:
    case UserAction.SET.ACTIVE_DOMAIN: {
      return storiesDefaultState;
    }

    case StoriesAction.LOAD.REQUEST: {
      return {
        ...state,
        articles: [],
        isLoading: true
      };
    }

    case StoriesAction.LOAD.SUCCESS: {
      return {
        ...state,
        articles: action.articles,
        isLoading: false
      };
    }
    case StoriesAction.LOAD.FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case StoriesAction.SET.PAGE_NUMBER: {
      return { ...state, pageNo: action.pageNo }
    }
    default: return state;
  }
}
export default stories;