import { StoryCmsAction } from "../../app-constants/index";


const defaultState = {
  list: [],
  stories: {},
  older:[],
  activeStoryList: 'new_list',
  activeStoryListExpiry: 24,
  newStoryLoading:false,
}
const storyCms = (state=defaultState, action) => {

  switch (action.type) {
    case StoryCmsAction.STORY_LIST.FAILURE: {
      return state;
    }
    case StoryCmsAction.STORY_LIST.SUCCESS: {
      return {
        ...state,
        list: action.list,
        stories: {},
      }
    }
    case StoryCmsAction.ADD_STORY.LOADING: {
      return {
        ...state,
        newStoryLoading: action.show,
      }
    }
    case StoryCmsAction.ADD_STORY.SUCCESS: {

    }
    case StoryCmsAction.SET.ACTIVE_STORY: {
      return {
        ...state,
        activeStoryList: action.activeStoryList
      }
    }
    case StoryCmsAction.SET.ACTIVE_STORY_EXPIRY_TIME : {
      return {
        ...state,
        activeStoryListExpiry: action.activeStoryListExpiry
      }
    }
    case StoryCmsAction.STORIES.SUCCESS: {
      const stories = { ...state.stories, [action.day]:action.stories}
      return {
        ...state,
        stories
      }
    }
    case StoryCmsAction.STORIES.FAILURE: {
      return {
        ...state,
        stories: {},
      }
    }
  }
  return state;
}

export default storyCms;
