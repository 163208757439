
import React from 'react';
import WebsiteManagementHomePage from "../website-management/website-management-home-page";
import Apps from "../components/Icon/App.jsx";
import websiteManagementRoutes from "../website-management/website-management-routes";
import userManagementRoutes from "../user-management/user-management-routes";
import ManageSocialAccounts from "../manage-social/views/manage-social-accounts";
import settingsIcon from "../assets/icons/settings-icon.png";
const Icon = () => <Apps src={settingsIcon} />;

let settingRoutes = [
  {
    collapse: true,
    path: "/settings",
    name: "Settings",
    state: "openWebsiteManagement",
    icon: Icon,
    isRequiredAuth: true,
    component: WebsiteManagementHomePage,
    requiredPermissions: ["SETTING:WEBSITE_SETTINGS", "SETTING:USER_MANAGEMENT", "SHARE:MANAGE_ACCOUNTS"],
    views: [
      ...userManagementRoutes,
      ...websiteManagementRoutes,
      {
        path: "/manage-social/manage",
        name: "Manage Social Accounts",
        mini: "MN",
        isRequiredAuth: true,
        component: ManageSocialAccounts,
        requiredFeaturePermision: 'SOCIAL_SCHEDULE',
        requiredPermissions: ["SHARE:MANAGE_ACCOUNTS"]
      },
    ]
  },
]

export default settingRoutes;
