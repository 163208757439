import React, { Component } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import withConfirmation from "../../../app-components/confirmation-hoc";

const MenuItemWithConfirm = withConfirmation(MenuItem);
class MenuDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    }
  }

  render() {
    const { anchorEl, openLp, cardId, deleteCurrentPage, removeIdForMenuDropDown } = this.props;
    const open = Boolean(cardId);
    if (!anchorEl) {
      return null
    }
    return (
      <Popover
        id="menu-popover"
        anchorEl={anchorEl}
        open={open}
        onClose={removeIdForMenuDropDown}
        PaperProps={{
          style: {
            width: 100,
            border: 'solid 1px #ced3dc',
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          style={{
            color: '#5d737e',
            fontSize: '12px'
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = '#fdfaf5';
            e.target.style.color = '#2b2d42';
            e.target.style.fontWeight = '500';
          }
          }
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = '#ffffff';
            e.target.style.color = '#5d737e';
            e.target.style.fontWeight = 'unset';
          }}
          onClick={openLp}>
          <i className="material-icons" style={{ fontSize: '18px', paddingRight: '5px' }}>
            edit
          </i>
          Edit
        </MenuItem>
      </Popover >
    )
  }
}

export default MenuDropDown;
