import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import { fetchVariableTimeSpecificRevenues, setRevenuesDateRangesInterval } from "../revenue-actions.js";
import { VariableTimeSpecificRevenueTypes, RevenueDataTypes } from "../../../app-constants/index.js";

class DailyRevenueGraph extends PureComponent {
  componentDidMount() {
    const { startDate, endDate, fetchDailyevenues } = this.props;
    this.fetchRevenues({ startDate, endDate });
  }
  componentWillReceiveProps({ activeDomain }) {
    const { activeDomain: prevActiveDomain } = this.props;
    if (activeDomain !== prevActiveDomain) {
      this.fetchRevenues();
    }
  }
  fetchRevenues = () => {
    const { fetchDailyevenues } = this.props;
    fetchDailyevenues({ output: [RevenueDataTypes.REVENUE] });
  }
  setDateRangeInterval = ({ startDate, endDate }) => {
    const { setDateInterval } = this.props;
    setDateInterval({ startDate, endDate, output: [RevenueDataTypes.REVENUE] });
  }
  render() {
    const { renderGraph } = this.props;
    return (
      <div>
        {
          renderGraph({
            ...this.props,
            graphDataType: RevenueDataTypes.REVENUE,
            setDateRangeInterval: this.setDateRangeInterval,
            header: "Revenue (USD)"
          })
        }
      </div>
    )
  }
}

const mapStatToProps = state => {
  return {
    ...state.analysis.revenue.variableTimeSpecificRevenues.dailyRevenues,
    activeDomain: state.login.user.activeDomain
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchDailyevenues: (options) => dispatch(fetchVariableTimeSpecificRevenues(VariableTimeSpecificRevenueTypes.DAILY_REVENUE, options)),
    setDateInterval: (options) => dispatch(setRevenuesDateRangesInterval(VariableTimeSpecificRevenueTypes.DAILY_REVENUE, options))
  }

}

export default connect(mapStatToProps, mapDispatchToProps)(DailyRevenueGraph);
