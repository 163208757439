import {
  ArticleTypes,
  MessageTypes,
  LocalStorageKeys,
  NotificationTypes,
  SessionKeys,
  ArticleAction,
  NewStoryAction,
  PiscoContentTypes,
  ArticleState
} from "../../app-constants";
import throttle from "lodash/throttle";
import { setArticleId } from "../article/article-action";
import { MeawwApi } from "../../utils/api";
import { setAppMessage, addAppNotification } from "../../app-actions";

function createNewStoryRequest() {
  return {
    type: NewStoryAction.CREATE.REQUEST,
  }
}
function createNewStorySuccess() {
  return {
    type: NewStoryAction.CREATE.SUCCESS,
  }
}
function createNewStoryFailure() {
  return {
    type: NewStoryAction.CREATE.FAILURE,
  }
}
export function createNewStory(articleType = ArticleTypes.NORMAL) {
  return (dispatch, getState) => {
    dispatch(setArticleId(undefined));
    dispatch(createNewStoryRequest());
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(
          `/article/${activeDomain}`,
          (error, data) => {
            if (error) {
              dispatch(createNewStoryFailure());
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              return Promise.reject(error);
            }
            const {
              message,
              metadata: { articleId }
            } = data.result;
            sessionStorage.setItem(SessionKeys.ARTICLE_ID, articleId.toString());
            return Promise.all([
              dispatch(createNewStorySuccess()),
              dispatch(addAppNotification(message, { type: NotificationTypes.SUCCESS })),
              dispatch(setArticleId(articleId)),
              dispatch({
                type: ArticleAction.CREATE.ARTICLE,
                articleId: articleId,
                articleState: ArticleState.DRAFT
              })
            ])
              .then((data, articleId) => {
                return Promise.resolve(articleId)
              })
          }
        );
      });
  };
}


//article meta

function updateArticleMeta({ articleId, activeDomain, formData, dispatch, eventType, successMessage, showSuccessNotification }) {
  return MeawwApi()
    .auth()
    .then(api => {
      return api.put(`/article/${activeDomain}/meta/${articleId}`, formData,
        (error, data) => {
          if (error) {
            dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }))
            return;
          }
          sessionStorage.setItem(SessionKeys.META_DATA, "true");
          if (eventType && eventType === "blur" || showSuccessNotification) {
            dispatch(addAppNotification(successMessage, { type: NotificationTypes.SUCCESS }))
          }
          return
        });
    });
}

const metaDataUpdateThrottledFunctions = {};

const getThrottledFunction = ({ formField, throttlingTime }) => {
  if (!metaDataUpdateThrottledFunctions[formField]) {
    metaDataUpdateThrottledFunctions[formField] = throttle(updateArticleMeta, throttlingTime || 3000);
  }
  return metaDataUpdateThrottledFunctions[formField];
}

function setArticleMeta({
  actionType,
  value,
  validation = null,
  showSuccessNotification = false,
  successMessage = "",
  eventType,
  formField,
  storeField,
  dispatchFunc = [],
  throttlingTime,
}) {
  return function returnedFunction(dispatch, getState) {
    const { articleId } = getState().cms.articleSettings;
    const { articles: { articlesByIds} } = getState().cms;
    const { activeDomain } = getState().login.user;
    if (validation) {
      const { updatedValue = value, isValid = true, message = "" } = validation(value);
      value = updatedValue;
      if (!isValid) {
        dispatch(addAppNotification(message, { type: NotificationTypes.ERROR }))
      }
    }
    if (dispatchFunc && dispatchFunc.length > 0) {
      dispatchFunc.forEach(eachDispatchFunc => eachDispatchFunc(dispatch));
    }
    dispatch({ type: actionType, [storeField]: value, articleId: articleId });
    // const formData = new FormData();
    // formData.set(formField, value);
    const formData = { [formField]: formField === 'subTag' ? value.subTagId : value };
    try {
      if (formField == 'yCategoryId') {
        const categoryId = articlesByIds[articleId].categoryId;
        formData.categoryId = categoryId;
      }
    } catch (err) {
      console.log("err", "unable to get main category for the article");
    }
    

    const throttledFunctions = getThrottledFunction({ formField, throttlingTime });
    return throttledFunctions({ articleId, activeDomain, formData, activeDomain, dispatch, eventType, successMessage, showSuccessNotification });
  }
}

export function setArticleTitle(title, eventType) {
  return setArticleMeta({
    actionType: ArticleAction.SET.ARTICLE_TITLE,
    value: title,
    formField: "title",
    storeField: "title",
    successMessage: "Article Title Submitted Successfully!",
    eventType: eventType,
    validation: title => {
      if (title.trim().length >= 301) {
        title = title.substring(0, 300);
        return {
          updatedValue: title,
          isValid: false,
          message: "Title should be 10 to 100 characters",
        }
      }
      return {};
    },
  });
}

export function setArticleSummary(summary, eventType) {
  return setArticleMeta({
    actionType: ArticleAction.SET.ARTICLE_SUMMARY,
    value: summary,
    formField: "summary",
    storeField: "summary",
    successMessage: "Article Summary Submitted Successfully!",
    eventType: eventType,
    validation: summary => {
      if (summary && summary.trim().length > 300) {
        summary = summary.substring(0, 300);
        return {
          updatedValue: summary,
          isValid: false,
          message: "Summary should be less than 300 characters",
        }
      }
      return {};
    },
  });
}
export function setArticleCoverCaption(summary, eventType) {
  return setArticleMeta({
    actionType: ArticleAction.SET.ARTICLE_COVER_CAPTION,
    value: summary,
    formField: "cover_caption",
    storeField: "cover_caption",
    successMessage: "Article Cover Caption Submitted Successfully!",
    eventType: eventType,
    validation: summary => {
      if (summary && summary.trim().length > 300) {
        summary = summary.substring(0, 300);
        return {
          updatedValue: summary,
          isValid: false,
          message: "Cover Caption should be less than 300 characters",
        }
      }
      return {};
    },
  });
}
export function clearArticleCoverCaption() {
  return dispatch => {
    dispatch({
      type: ArticleAction.SET.CLEAR_ARTICLE_COVER_CAPTION
    });
  }
}
export function setCategoryType(categoryType) {
  // return (dispatch, getState) => {
  //   const { articleId } = getState().cms.articleSettings;
  //   const { activeDomain } = getState().login.user;
  //   return MeawwApi()
  //     .auth()
  //     .then(api => {
  //       return api.put(`/article/${activeDomain}/meta/${articleId}`, { categoryId: categoryType, title: "fjdkasnfk" },
  //         (error, data) => {
  //           if (error) {
  //             dispatch(setAppMessage(error.toString(), { type: MessageTypes.ERROR }))
  //             return;
  //           }
  //           sessionStorage.setItem(SessionKeys.META_DATA, "true");
  //           dispatch(addAppNotification("Category Updated Successfully!", { type: NotificationTypes.SUCCESS }))
  //           return
  //         });
  //     });
  // }


  return setArticleMeta({
    actionType: ArticleAction.SET.CATEGORY_TYPE,
    throttlingTime: 0,
    value: categoryType,
    formField: "categoryId",
    storeField: "categoryId",
    successMessage: "Category Updated Successfully!",
  });
}

export function setYCategoryType(yCategoryId) {
  return setArticleMeta({
    actionType: ArticleAction.SET.SUB_CATEGORY_TYPE,
    throttlingTime: 0,
    value: yCategoryId,
    formField: "yCategoryId",
    storeField: "yCategoryId",
    successMessage: "Sub Category Updated Successfully!",
  });
}
export function setSEOTitle(seoTitle, eventType) {
  return setArticleMeta({
    actionType: ArticleAction.SET.SEO_TITLE,
    value: seoTitle,
    formField: "seoTitle",
    storeField: "seoTitle",
    successMessage: "Google Headline Submitted Successfully!",
    eventType: eventType,
    validation: seoTitle => {
      if (seoTitle.trim().length > 300) {
        seoTitle = seoTitle.substring(0, 300);
        return {
          updatedValue: seoTitle,
          isValid: false,
          message: "SeoTitle should be 10 to 100 characters",
        }
      }
      return {};
    },
  });
}
export function setOgTitle(ogTitle, eventType) {
  return setArticleMeta({
    actionType: ArticleAction.SET.OG_TITLE,
    value: ogTitle,
    formField: "ogTitle",
    storeField: "ogTitle",
    successMessage: "OG Title Submitted Successfully!",
    eventType: eventType,
    // validation: ogTitle => {
    //   if (ogTitle.trim().length >= 300) {
    //     ogTitle = ogTitle.substring(0, 300);
    //     return {
    //       updatedValue: ogTitle,
    //       isValid: false,
    //       message: "OgTitle should be 10 to 100 chanracters",
    //     }
    //   }
    //   return {};
    // },
  });
}

export function setStorySource(storySource) {
  return setArticleMeta({
    actionType: ArticleAction.SET.STORY_SOURCE,
    value: storySource,
    formField: "storySource",
    storeField: "storySource",
    successMessage: "story source Submitted Successfully!",
    validation: storySource => {
      return {};
    },
    // eventType: eventType,
  });
}


export function setPostCaption(postCaption, eventType) {
  return setArticleMeta({
    actionType: ArticleAction.SET.POST_CAPTION,
    value: postCaption,
    formField: "postCaption",
    storeField: "postCaption",
    successMessage: "Post Caption Submitted Successfully!",
    eventType: eventType,
    validation: postCaption => {
      if (postCaption.trim().length >= 301) {
        postCaption = postCaption.substring(0, 300);
        return {
          updatedValue: postCaption,
          isValid: false,
          message: "PostCaption should be 2 to 100 characters",
        }
      }
      return {};
    },
  });
}

export function setCustomUrl(customUrl, eventType) {
  return setArticleMeta({
    actionType: ArticleAction.SET.CUSTOM_URL,
    value: customUrl,
    formField: "customUrl",
    storeField: "customUrl",
    successMessage: "Custom Url Submitted Successfully!",
    eventType: eventType,
    validation: customUrl => {
      if (customUrl.trim().length >= 1001) {
        customUrl = customUrl.substring(0, 1000);
        return {
          updatedValue: customUrl,
          isValid: false,
          message: "customUrl should be 2 to 1000 characters",
        }
      }
      return {};
    },
  });
}

export function setMetaTags(tags, eventType) {
  return setArticleMeta({
    actionType: ArticleAction.SET.META_TAGS,
    value: tags,
    formField: "tag",
    storeField: "tag",
    successMessage: "Tags Updated Successfully!",
    eventType: eventType,
  });
}
export function setMetaSubTags(tags, eventType) {
  return setArticleMeta({
    actionType: ArticleAction.SET.META_SUB_TAGS,
    value: tags,
    formField: "subTag",
    storeField: "subTag",
    successMessage: "subTag Updated Successfully!",
    eventType: eventType,
  });
}

export function setDisclaimerType(disclaimerType, eventType) {
  return setArticleMeta({
    actionType: ArticleAction.SET.DISCLAIMER_TYPE,
    value: disclaimerType,
    formField: "disclaimerId",
    storeField: "disclaimerId",
    successMessage: "disclaimerId Updated Successfully!",
    eventType: eventType,
  })
}

export function setArticleVideoHeader(videoHeader) {
  return setArticleMeta({
    actionType: ArticleAction.SET.VIDEO_HEADER,
    // throttlingTime: 0,
    value: videoHeader,
    formField: "videoHeader",
    storeField: "videoHeader",
    successMessage: "Video Header Updated Successfully!",
    eventType: "change"
  });
}
export function deleteArticleVideoheader() {

  return (dispatch) => {
    dispatch(setArticleVideoHeader(""));
  }

}

// export function setCategoryType(categoryType) {
//   return (dispatch, getState) => {
//     const { articleSettings: { articleId }, login: { user: { activeDomain } } } = getState();
//     dispatch({
//       type: ArticleAction.SET.CATEGORY_TYPE,
//       categoryType: categoryType,
//       articleId: articleId,
//     }).then(() => {
//       // dispatch(setStoryType(0));
//       // dispatch(loadStoryTypes(categoryType, true));
//       submitArticleMeta(dispatch, getState);
//     });
//   }
// }

//===================PISCO START=====================
export function showPiscoModal(contentType = PiscoContentTypes.CONTENT) {
  return {
    type: NewStoryAction.PISCO.OPEN,
    contentType: contentType
  }
}

export function hidePiscoModal() {
  return { type: NewStoryAction.PISCO.CLOSE }
}

export function setPiscoContent(content) {
  return {
    type: NewStoryAction.SET.PISCO.CONTENT,
    content: content
  }
}

export function clearPiscoContent() {
  return {
    type: NewStoryAction.SET.PISCO.CONTENT,
    content: {}
  }
}
//===================PISCO END=====================

export function setCoverPictureUrl(url) {
  return {
    type: NewStoryAction.SET.PISCO.COVER,
    content: url
  };
}
function resetCoverPictureUrl() {
  return {
    type: NewStoryAction.SET.PISCO.RESET_COVER,
    content: null
  }
}

export function setCoverPictureCropData(cropData) {
  return {
    type: ArticleAction.SET.COVER_PICTURE.CROP_DATA,
    cropData: cropData
  }
}



export function uploadCoverPictureRequest(articleId) {
  return {
    type: ArticleAction.UPLOAD.COVER_PICTURE.REQUEST,
    articleId: articleId
  }
}

export function uploadCoverPicturefailure(articleId) {
  return {
    type: ArticleAction.UPLOAD.COVER_PICTURE.FAILURE,
    articleId: articleId
  }
}
export function uploadCoverPictureSuccess(articleId, coverPictureDetails) {
  return {
    type: ArticleAction.UPLOAD.COVER_PICTURE.SUCCESS,
    articleId: articleId,
    coverPictureDetails: coverPictureDetails,
  }
}

export function changeCoverPicture(changeCoverForm) {
  return (dispatch, getState) => {
    const { cms: { articleSettings: { articleId } }, login: { user: { activeDomain } } } = getState();
    dispatch(uploadCoverPictureRequest(articleId));
    return MeawwApi()
      .auth()
      .then(api => {
        return api.post(`/article/${activeDomain}/cover/${articleId}`,
          changeCoverForm,
          (error, data) => {
            if (error) {
              dispatch(uploadCoverPicturefailure(articleId));
              dispatch(setAppMessage(error.toString(), { type: MessageTypes.ERROR }));
              return;
            }
            dispatch(uploadCoverPictureSuccess(articleId, data));
            dispatch(resetCoverPictureUrl());
            dispatch(addAppNotification("Cover Image Updated Successfully", { type: NotificationTypes.SUCCESS }));
            return
          })
      })
  }
}

export function setCoverPictureImageSource(imageSource) {
  return {
    type: ArticleAction.SET.COVER_PICTURE.IMAGE_SOURCE,
    imageSource: imageSource,
  }
}


export function updateEditorContent(contents) {
  return (dispatch, getState) => {
    const { articleSettings: { articleId }, articles: { articlesByIds } } = getState().cms;
    const articleDetails = articlesByIds[articleId];
    dispatch({
      type: ArticleAction.SET.CONTENTS,
      contents: contents,
      articleId: articleId,
      articleDetails: articleDetails
    });
  }
}

//******************** Start Block-Quote ******************
export function showBlockQuote() {
  return { type: NewStoryAction.SET.BLOCK_QUOTE.SHOW }
}

export function hideBlockQuote() {
  return { type: NewStoryAction.SET.BLOCK_QUOTE.HIDE }
}
//dont need this action creator
export function appendBlockQuote(blockQuote) {
  return { type: NewStoryAction.APPEND.BLOCK_QUOTE.CONTENT, blockQuote: blockQuote }
}
//******************** End  Block-Quote ******************


//get tinymce content for particulat articleId
export function setInitialState() {
  return (dispatch, getState) => {
    const { articleId } = getState().cms.articleSettings;
    const { activeDomain } = getState().login.user;
    return MeawwApi().auth().then(api => {
      return api.get(`/article/${activeDomain}/draft/${articleId}`,
        (error, data) => {
          if (error) {
            // dispatch(addAppNotification(error.toString(), {type: NotificationTypes.ERROR}))
            return;
          }
          dispatch({ type: NewStoryAction.SET.EDITOR.INITIAL_STATE, editorContent: data.result, articleId: articleId });
          return data;
        });
    });
  }
}


export function updateApi(editorContent) {
  return (dispatch, getState) => {
    const store = getState();
    const {
      articles: {
        articlesByIds,
      },
      articleSettings: { articleId }
    } = store.cms;
    const { activeDomain } = store.login.user;
    // addToStore(editorContent, articleId);
    const { contents, contentType } = articlesByIds[articleId];
    if (editorContent === null || editorContent === undefined) {
      editorContent = contents;
    }

    //check content type
    if (contentType === undefined || contentType === null) {
      return
    } else if (contentType === ArticleTypes.GALLERY) {
      editorContent = JSON.stringify(editorContent);
    }
    MeawwApi().auth().then(api => {
      return api.put(`/article/${activeDomain}/draft/${articleId}`, {
        "html": editorContent
      }, (error, data) => {
        if (error) {
          dispatch(setAppMessage(error.message.toString(), { type: MessageTypes.ERROR }));
          return
        }
        dispatch(addAppNotification("Content saved Successfully!", { type: NotificationTypes.SUCCESS, }));
        return data;
      });
    });
  }
}



//******************* Start New Article Configuration ********
export function setContentUpdatable(update = false) {
  return { type: NewStoryAction.SET.NEW_ARTICLE.ALLOW_UPDATE, update: update }
}
//******************* End New Article Configuration **********

//******************* image uploading start **********
export function imageUploadingRequest() {
  return { type: NewStoryAction.UPLOAD.IMAGE.REQUEST }
}
export function imageUploadingFailure() {
  return { type: NewStoryAction.UPLOAD.IMAGE.FAILURE }
}
export function imageUploadingSuccess() {
  return { type: NewStoryAction.UPLOAD.IMAGE.SUCCESS }
}


export function uploadImageInMCE(formData, success, failureCB) {
  return (dispatch, getState) => {
    const { articleId } = getState().articleSettings;
    dispatch(imageUploadingRequest());
    return MeawwApi()
      .auth()
      .then(api => {
        // console.log('start uploading here');
        return api.post(`/imageUpload/${articleId}`, {
          isFormData: true,
          data: formData,
        }, (error, data) => {
          if (error) {
            // console.log('some error while uplaoding');
            // console.log("error from api==", error);
            dispatch(imageUploadingFailure());

            failureCB(error);
            return
          } else {
            //show it in toast
            // console.log("sharetype updated successfully")
            dispatch(imageUploadingSuccess());
            dispatch(addAppNotification("Image uploaded successfully!", { type: NotificationTypes.SUCCESS }))
            success(data.location);
            return
          }
        });
      })
  }
}
//******************* image uploading end **********


export function allowFillMetaData() {
  return (dispatch, getState) => {
    const { articleSettings: { articleId }, articles: { articlesByIds } } = getState().cms;
    const { login: { user: { activeDomain } } } = getState();
    const {
      coverPicture: coverPicture,
      title,
      summary,
      shareType: shareType,
      categoryId: categoryType,
      yCategoryId: storyType,
      ogTitle,
      seoTitle,
      source,
    } = articlesByIds[articleId];
    //uncomments this
    if (!coverPicture || coverPicture.length === 0) {
      dispatch(setAppMessage("Cover Image is missing!", { type: MessageTypes.ERROR }));
      return;
    }
    if (!ogTitle || ogTitle.length >= 301) {
      dispatch(setAppMessage("Facebook Headline length should be at max 100 characters!", { type: MessageTypes.ERROR }));
      return;
    }
    if (!seoTitle || seoTitle.length >= 301) {
      dispatch(setAppMessage("Google Headline length should be at max 100 characters!", { type: MessageTypes.ERROR }));
      return;
    }
    if (!summary || summary.length >= 301) {
      dispatch(setAppMessage("Summary cannot be more than 100 chars or it cannot be empty", { type: MessageTypes.ERROR }));
      return;
    }
    const { isValid, message } = validateArticleDatass({ title, summary, shareType, categoryType, storyType, activeDomain });
    if (!isValid) {
      dispatch(setAppMessage(message, { type: MessageTypes.ERROR }));
      return;
    }
    dispatch(setMetaDataModal(true));
    return;
  }
}

// start article settings and validation
export function validateArticleDatass({ title, summary = "", shareType, categoryType, storyType, activeDomain }) {
  if (activeDomain && activeDomain === 15 && storyType === null) {
    return {
      isValid: false,
      message: "Sub Category is mandatory!"
    };
  }
  // if (shareType === null) {
  //   return {
  //     isValid: false,
  //     message: "Please select share type!"
  //   };
  // } else if (!parseInt(categoryType)) {
  //   return {
  //     isValid: false,
  //     message: "Please select category type!"
  //   };
  // }
  // else if (shareType === "meaww" && !parseInt(storyType)) {
  //   return {
  //     isValid: false,
  //     message: "Story type is not choosen!"
  //   }
  // }
  // else
  // if (title === "Untitled" || title === undefined || title === null || title.trim().length < 10 || title.trim().length > 150) {
  //   return {
  //     isValid: false,
  //     message: "Title should be 10 to 150 characters!"
  //   };
  // }
  if (summary && summary.trim().length > 300) {
    return {
      isValid: false,
      message: "Summary should be less than 300 characters!"
    };
  } else {
    return {
      isValid: true,
      message: "",
    }
  }
}

export function isValidDisclaimerId({ disclaimer_id: disclaimerId }) {
  if (!(parseInt(disclaimerId) >= 0)) {
    return {
      isValid: false,
      message: "Please select a specific disclaimer!"
    };
  } else {
    return {
      isValid: true,
      message: ""
    }
  }
}


//meta modal
export function setMetaDataModal(visible) {
  return {
    type: ArticleAction.SET.META_DATA_MODAL,
    visible: visible,
  }
}
//end meta modal

//start discalimer
export function loadDisclaimerFailure() {
  return {
    type: ArticleAction.LOAD.ARTICLE_DISCLAIMER.FAILURE
  }
}
export function loadDisclaimerRequest() {
  return {
    type: ArticleAction.LOAD.ARTICLE_DISCLAIMER.REQUEST
  }
}
export function loadDisclaimerSuccess(disclaimers) {
  return {
    type: ArticleAction.LOAD.ARTICLE_DISCLAIMER.SUCCESS,
    disclaimers: disclaimers
  }
}
export function setDisclaimerModal(visible) {
  return {
    type: ArticleAction.SET.DISCLAIMER_MODAL,
    visible: visible,
  }
}


export function fetchDisclaimers() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    dispatch(loadDisclaimerRequest());
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/article/${activeDomain}/disclaimer`,
          (error, data) => {
            if (error) {
              dispatch(setAppMessage(error.message, { type: MessageTypes.ERROR }));
              dispatch(loadDisclaimerFailure());
              return Promise.reject(error);
            }
            dispatch(loadDisclaimerSuccess(data.result));
            return Promise.resolve(data.result);
          });
      })
  }
}
export function addDisclaimer() {
  return (dispatch, getState) => {
    const { articleSettings: { articleId }, articles: { articlesByIds } } = getState().cms;
    const articleDetails = articlesByIds[articleId];
    const { isValid, message } = validateArticleData(articleDetails);
    if (!isValid) {
      dispatch(setAppMessage(message, { type: MessageTypes.ERROR }));
      return;
    } else {
      dispatch(setMetaDataModal(false));
      dispatch(setDisclaimerModal(true));
      dispatch(fetchDisclaimers());
    }
    // }
  }
}
// End article disclaimer



// start article form validation
export function validateArticleData({
  title = "",
  summary = "",
  domainId,
  seoTitle = "",
  postCaption = "",
  customUrl = "",
  el_type: articleType,
  tags: tagsList = [],
  disclaimerId,
  time_specific: timeSpecific }) {
  if (title.length < 10 || title.length >= 301) {
    return {
      isValid: false,
      message: "Title should be 10 to 100 characters!"
    };
  } else if (summary && summary.trim().length >= 301) {
    return {
      isValid: false,
      message: "Summary should be less than 100 characters!"
    }
  } else if (!seoTitle || (seoTitle.length < 10 || seoTitle.length >= 301)) {
    return {
      isValid: false,
      message: "Google Headline should be 10 to 100 characters!!!"
    };
  } else if (!postCaption || (postCaption.length < 2 || postCaption.length >= 301)) {
    return {
      isValid: false,
      message: "Post caption should be 2 to 100 characters!"
    };
  }
  // else if (tagsList && tagsList.length === 0) {
  //   return {
  //     isValid: false,
  //     message: "Please enter Meta tags!"
  //   };
  // }
  // else if (!(parseInt(articleType) >= 0)) {
  //   return {
  //     isValid: false,
  //     message: "Please select article type!"
  //   };
  // }
  // else if (!(parseInt(timeSpecific) >= 0)) {
  //   return {
  //     isValid: false,
  //     message: "Please select time specific or not!"
  //   };
  // }

  else {
    if (domainId === 9662 || domainId === 15) {
      if (!customUrl || (customUrl.length < 2 || customUrl.length >= 1001)) {
        return {
          isValid: false,
          message: "Custom Url should be 2 to 1000 characters!"
        };
      } else {
        return {
          isValid: true,
          message: ""
        }
      }
    } else {
      return {
        isValid: true,
        message: ""
      }
    }
  }
}
//end article form validation



export function fetchMetaTags() {
  return (dispatch, getState) => {
    const { activeDomain } = getState().login.user;
    return MeawwApi()
      .auth()
      .then(api => {
        return api.get(`/tag/new/${activeDomain}`, (error, data) => {
          if (error) {
            return Promise.resolve([]);
          }
          return Promise.resolve(data.result);
        });
      })
  }
}

