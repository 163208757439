import React, { Component } from "react";
import { connect } from "react-redux";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import withStyles from "@material-ui/core/styles/withStyles";
import ViewArticles from './view-articles';
import {
  showLiveTab,
  resetSearchArticle,
  getArticles,
} from '../articles-actions.js';
import { ArticlesState, LocalStorageKeys } from "../../../app-constants/index.js";
import articleHomeStyle from '../../../assets/jss/material-dashboard-pro-react/views/articlehomeStyle';
import LiveSearch from "./liveSearch.jsx";
import ArticleActionOptions from "../../../utils/article-action.js";
import { setLastLocation } from "../../article/article-action";

import withWidth, { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 2 }}>
      {props.children}
    </Typography>
  );
}



class ArticleList extends Component {
  state = {
    value: parseInt(localStorage.getItem(LocalStorageKeys.SELECTED_TAB)) || 0,
    last: sessionStorage.getItem('last_location') || 'none',
    showSearch: false,
  };
  handleChange = (event, value) => {
    localStorage.setItem(LocalStorageKeys.SELECTED_TAB, value);
    this.props.setLastLocation('none');
    this.setState({ value });
  };
  setShowSearch = (flag) => {
    this.props.showLiveTab();
    this.setState({ ...this.state, ['showSearch']: flag })
  }

  componentDidMount() {
    this.props.showLiveTab();
    if (this.props.lastLocation == 'edit-page' && this.state.value == 3) {
    } else {
      this.state.value === 3 && this.props.resetSearchArticle();
      this.state.value === 3 && this.props.getArticles();
    }
  }
  componentWillUnmount() {
    this.props.setLastLocation('none');
  }
  render() {
    const { isShowLiveTab, classes } = this.props;
    const { value } = this.state;
    return (
      <Paper xs={12} sm={12} md={12} className={classes.root} >
        {value === 4 && <div style={{ position: 'relative', display: 'flex', cursor: 'pointer', zIndex: '1' }}>
          <div style={{ position: 'absolute', top: isWidthDown('xs', this.props.width)?'-25px':'15px', right: isWidthDown('xs', this.props.width)?'20px':'0' }}>
            <i className="material-icons" onClick={() => {
              this.setShowSearch(true)
            }
            }>
              search
            </i>
          </div>
        </div>}
        <Tabs
          fullWidth
          centered
          value={value}
          onChange={this.handleChange}
          scrollable
          scrollButtons="off"
          textColor="inherit"
          classes={{
            root: classes.tabsRoot,
            flexContainer: classes.flexContainer,
            indicator: classes.indicatiorColor
          }}
        >
          <Tab label="Draft" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} style={{fontSize: '0.8em'}}/>
          <Tab label="Submitted " classes={{ root: classes.tabRoot, selected: classes.tabSelected }} style={{fontSize: '0.8em'}} />
          <Tab label="Approved " classes={{ root: classes.tabRoot, selected: classes.tabSelected }} style={{fontSize: '0.8em'}} />
          <Tab label="Scheduled " classes={{ root: classes.tabRoot, selected: classes.tabSelected }} style={{fontSize: '0.8em'}} />
          <Tab label="Published " classes={{ root: classes.tabRoot, selected: classes.tabSelected }} style={{fontSize: '0.8em'}} />

        </Tabs>
        {value === 0 && <TabContainer><ViewArticles options={ArticleActionOptions.Draft} articleLetter="D" articleState={ArticlesState.DRAFT_ARTICLES} /></TabContainer>}
        {value === 1 && <TabContainer><ViewArticles options={ArticleActionOptions.Submit} articleLetter="S" articleState={ArticlesState.SUBMITTED_ARTICLES} /></TabContainer>}
        {value === 2 && <TabContainer><ViewArticles options={ArticleActionOptions.Approve} articleLetter="A" articleState={ArticlesState.APPROVED_ARTICLES} /></TabContainer>}
        {value === 3 && <TabContainer><ViewArticles options={ArticleActionOptions.Queue} articleLetter="Q" articleState={ArticlesState.QUEUED_ARTICLES} /></TabContainer>}
        {value === 4 && isShowLiveTab && <TabContainer><ViewArticles options={ArticleActionOptions.Live} articleLetter="L" articleState={ArticlesState.LIVE_ARTICLES} /></TabContainer>}
        {(this.state.showSearch && <div id="searchMainPanel" ref="searchMainPanel">
          <LiveSearch setShowSearch={this.setShowSearch} showSearch={this.state.showSearch} />
        </div>)}
      </Paper >
    );
  }
}
const mapStateToProps = (store) => {
  const { isShowLiveTab, } = store.cms.articles.articlesSettings;
  const { lastLocation } = store.cms.articleSettings;
  return {
    isShowLiveTab,
    lastLocation
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getArticles: () => dispatch(getArticles(ArticlesState.LIVE_ARTICLES)),
    resetSearchArticle: () => dispatch(resetSearchArticle()),
    showLiveTab: () => { dispatch(showLiveTab()) },
    setLastLocation: (location) => dispatch(setLastLocation(location)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(articleHomeStyle)(withWidth()(ArticleList)));
