// ##############################
// // // Edit profile page styles
// #############################

import {
    container,
    cardTitle
} from "assets/jss/material-dashboard-pro-react.jsx";

const editProfilePageStyle = theme => ({
    container: {
        ...container,
        zIndex: "4",
        [theme.breakpoints.down("sm")]: {
            paddingBottom: "100px"
        }
    },
    cardHeader: {
        margin: "0px",
        textAlign: "center"
    },
    cardTitle: {
        ...cardTitle,
        color: "#FFFFFF"
    },
    justifyContentCenter: {
        justifyContent: "center !important"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    customButtonClass: {
        "&,&:focus,&:hover": {
            color: "#FFFFFF"
        },
        marginLeft: "5px",
        marginRight: "5px"
    },
    imageContainer: {
        display: "flex",
        justifyContent: "space-around"
    }

})

export default editProfilePageStyle;
