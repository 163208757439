import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import withFileUpload from "../components/with-file-upload";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import AppLoader from "../../../app-components/app-loader.jsx";
import CategoryDropdown from "../components/category-dropdown";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import ShowAudio from "./show-audio";
import Switch from '@material-ui/core/Switch';
const FileUploaderImage = withFileUpload(Button);

class AddPostForm extends React.Component {
  state = {
    post_title: '',
    post_description: '',
    type: null,
    error: {
      postTitle: "Title is requried",
      postDescription: null,
    },
    metadata: {},
    premium: false,
    content: null,
    category: null,
    thumbnail: null,
    defaultAudioThumbnail: "https://cdn.pubninja.com/assets/new-feed/audio_thumbnail.png",
    isChangeThumbnail : false,
  }
  componentDidMount() {
    const { file, mode } = this.props;
    // let uploadType = this.props.type.split("/")[0];
    // let fileType = this.props.file.type.split("/")[0];
    // if (uploadType !== fileType) {
    //   this.props.onClose();
    // }
    if (mode === 'edit') {
      const { title, description, headerContent, paidContent, categoryId } = this.props.feedContent;
      this.setState({
        post_title: title,
        post_description: description || '',
        premium: !!paidContent,
        metadata: JSON.parse(headerContent),
        content: JSON.parse(headerContent).cdn,
        thumbnail :JSON.parse(headerContent).thumbnail || null,
        category: categoryId,
      });
      return;
    }
    this.props.saveMediaToPisco(this.props.file).then(res => {
      const { localUploadResponse } = res;
      if (localUploadResponse) {
        const metadata = {
          cdn: localUploadResponse.cdn,
          s3Url: localUploadResponse.s3Url,
        };
        if (localUploadResponse.type === 'Video') {
          metadata.brightcoveVideoId = localUploadResponse.brightcove_video_id;
          this.props.getVideoThumbnail(`https:${localUploadResponse.cdn}`).then((data) => {
            metadata.thumbnail = data.result.url;
            this.setState({thumbnail : data.result.url,metadata})
          }).catch((err) => {
            console.log(err)
          })
        }
        if (localUploadResponse.type === 'Image') {
          this.props.getBlurImage(`https:${localUploadResponse.cdn}`).then((data) => {
            metadata.blur = (data.result && data.result.url);
          })
        }
        if (localUploadResponse.type === "Audio") {
          const { defaultAudioThumbnail} = this.state;
          const thumbnail = defaultAudioThumbnail;
          metadata.thumbnail = defaultAudioThumbnail;
          this.setState({thumbnail});
        }
        this.setState({ content: localUploadResponse.cdn, metadata });
        this.setState({ type: file.type });
      }
    })
      .catch(error => {
        // this.props.onClose();
      })
  }
  addNewPost = (status) => {
    let { post_title, post_description, premium, type, content, metadata, category } = this.state;
    category = category || (this.props.categories.length && this.props.categories[0].id);
    let data = {
      title: post_title,
      description: post_description,
      premium,
      type,
      content,
      metadata,
      category,
    };
    const { feedContent } = this.props;
    this.props.postHandler(feedContent && feedContent.articleId, data, status).then(res => {
      this.props.mode === 'create' && this.props.resetFilter();
      this.props.onClose();
    }).catch(err => {
      this.props.onClose();
    });
  }

  setCategory = (value) => {
    this.setState({ category: value });
  }
  handlePostTitle = (e) => {
    this.setState({ post_title: e.target.value });
  }
  handlePostDescription = (e) => {
    this.setState({ post_description: e.target.value });
  }
  handlePremiumContent = (e) => {
    this.setState((prev) => {
      return {
        premium: !prev.premium,
      }
    })
  }
  handleFileUpload = (target,type) => {
    const file = target && target.files[0];
    const uploadType = type && type.split("/")[0];
    const extension = file && file.type.split("/")[1];
    if (file && type && !(file.type.indexOf(uploadType) > -1)) {
      this.props.InvalidFileError();
      return;
    }
    if (uploadType == 'image' && (extension == 'webp' || extension =='svg+xml' || extension == 'gif') ) {
      this.props.InvalidFileError(`${extension} images are not allowed.`);
      return;
    }
    if (file && file.name) {
      this.setState({isChangeThumbnail: true})
      this.props.saveMediaToPisco(file).then((data) => {
        const { localUploadResponse } = data;
        const { metadata } = this.state;
        metadata.thumbnail = `https:${localUploadResponse.cdn}`;
        if (localUploadResponse.type === 'Image') {
          this.setState({thumbnail :`https:${localUploadResponse.cdn}`,metadata,isChangeThumbnail :false})
        }
      }).catch(() => {
        this.setState({isChangeThumbnail : false})
      })
    }
  }
  render() {
    const { error, type, thumbnail, isChangeThumbnail } = this.state;
    const { savingToPisco, file, imageData, creatingContent, feedContent, fullScreen, user : {permissions} } = this.props;
    const hasPublishPermission = permissions.includes("CREATE:PUBLISH");
    return (
      <Dialog fullScreen={fullScreen} open={this.props.showPostForm}>
        <DialogContent>
          {creatingContent && <AppLoader message="Processing Request...." />}
          {!creatingContent && <GridContainer>
            <GridItem xs={12}>
              <div style={{ textAlign: "center", maxWidth: '90vw', width: '100%', height: '200px', background: `${(file && file.type.startsWith('audio') || feedContent && feedContent.headerType.startsWith('audio')) ? 'white' : '#ced3dc'}`, objectFit: "contain", }}>
                {savingToPisco && !isChangeThumbnail && <AppLoader message="Uploading Media" />}
                {!savingToPisco && file && file.type.startsWith('image') && <img style={{ height: '100%', maxWidth: '80vw' }} src={imageData} />}
                {(!savingToPisco || isChangeThumbnail)&& file && file.type.startsWith('video') && <video key={thumbnail} style={{ height: '100%', maxWidth: '80vw' }} controls poster={thumbnail}> <source src={imageData} type={file.type} />Your browser does not support HTML5 video.</video>}
                {(!savingToPisco || isChangeThumbnail) &&  file && file.type.startsWith('audio') && <div key={thumbnail} style={{ height: '100%', maxWidth: '80vw',margin:'auto' }}><ShowAudio  thumbnail={thumbnail} src={this.state.metadata && this.state.metadata.s3Url || imageData}  /> </div>}
                {!savingToPisco && feedContent && feedContent.headerType.startsWith('image') && <img style={{ height: '100%', maxWidth: '80vw' }} src={JSON.parse(feedContent.headerContent).cdn}/>}
                {(!savingToPisco || isChangeThumbnail) && feedContent && feedContent.headerType.startsWith('video') && <video key={thumbnail} style={{ height: '100%', maxWidth: '80vw' }} poster={thumbnail} controls> <source src={JSON.parse(feedContent.headerContent).cdn} type={feedContent.headerType} />Your browser does not support HTML5 video.</video>}
                {(!savingToPisco || isChangeThumbnail) && feedContent && feedContent.headerType.startsWith('audio') && <div key={thumbnail}style={{ height: '100%', maxWidth: '80vw', margin:'auto' }}><ShowAudio src={JSON.parse(feedContent.headerContent).cdn} thumbnail={thumbnail} /> </div>}
              </div>
            </GridItem>
            <GridItem xs={12}>
              {!(feedContent && feedContent.headerType.startsWith('image')) && <div style={{ display:'flex'}}>
                {(thumbnail || this.props.mode == "edit") && <><FileUploaderImage fullWidth style={{width:'100%',pointerEvents :`${isChangeThumbnail?'none':'auto'}`}} fullWidth type="image/*" disabled={isChangeThumbnail} onChange={this.handleFileUpload}>
                  change thumbnail
                </FileUploaderImage>{isChangeThumbnail && <AppLoader size={20}/>}</>}
            </div> }
            </GridItem>
            
            {<GridItem xs={12} style={{ marginTop: '20px' }}>
              {!!this.props.categories.length && <CategoryDropdown initalValue={this.props.feedContent && this.props.feedContent.categoryId} setCategory={this.setCategory} categories={this.props.categories} />}
              <CustomInput
                labelText="Title"
                //infoText="Title to be shown for the story."
                id="post_title"
                value={this.state.post_title}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disableUnderline: true,
                  onChange: this.handlePostTitle,
                }}
                helpText={!this.state.post_title && error.postTitle}
              />
              <div style={{ marginTop: '-20px' }}>
                <span style={{fontSize:'10px',fontFamily: "'Source Sans Pro', sans-serif", color:'#AAAAAA'}}>DESCRIPTION</span>
                <TextField
                  id="standard-full-width"
                  multiline
                  fullWidth
                  rowsMax={4}
                  value={this.state.post_description}
                  style={{
                    border: '1px solid #ced3dc',
                    paddingTop: '2px',
                    fontFamily: "'Source Sans Pro', sans-serif",
                    borderBottom:'none',

                  }}
                  placeholder="Description"
                  onChange={this.handlePostDescription}
                  InputLabelProps={{

                  }}
                />
                {/* <CustomInput
                  labelText="Multi Description"
                  //infoText="Title to be shown for the story."
                  id="post_description"
                  multiline
                  value={this.state.post_description}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disableUnderline: true,
                    onChange: this.handlePostDescription,
                  }}
                  helpText={error.postDescription}
                /> */}
              </div>
              {/** Uncomment this code when premium content services are enabled */}
              {
                this.props.subscriptionEnabled == 1 &&
                <div style={{marginBottom: '25px'}}>
                  <span style={{marginRight: '30px'}}> Free Content </span>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.premium}
                        onChange={this.handlePremiumContent}
                        value="Premium Content"
                        color="primary"
                      />
                    }
                  />
                  <span> Premium Content </span>
                </div>
              }

            </GridItem>}
            {!feedContent && <GridItem xs={12} style={{ marginTop: '20px', textAlign: 'center' }}>
              <GridContainer>
                <GridItem xs={12} md={4} style={{ marginBottom: '5px' }}>
                  <Button color="secondary" disabled={!this.state.post_title || !this.state.content || !this.props.categories.length} onClick={this.addNewPost.bind(null, 'draft')}>Save Draft</Button>
                </GridItem>
                {hasPublishPermission && <GridItem xs={12} md={4} style={{ marginBottom: '5px' }} id={`createworkspacepublish`}>
                  <Button color="secondary" disabled={!this.state.post_title || !this.state.content || !this.props.categories.length} color="secondary" onClick={this.addNewPost.bind(null, 'publish')}>Upload</Button>
                </GridItem>}
                <GridItem xs={12} md={4}>
                  <Button color="primary" onClick={this.props.onClose}>Cancel</Button>
                </GridItem>
              </GridContainer>
            </GridItem>}
            {feedContent && <GridItem xs={12} style={{ marginTop: '20px' }}>
              <GridContainer>
                <GridItem xs={12} md={4}>
                  <Button color="secondary" disabled={isChangeThumbnail || !this.state.post_title || !this.state.content || !this.props.categories.length} onClick={this.addNewPost.bind(null, feedContent.status != 4 ? 'draft' : 'live')}>Save Changes</Button>
                </GridItem>
                {(feedContent.status != 4 && hasPublishPermission) && <GridItem xs={12} md={4} id={`createworkspacepublish`}>
                  <Button color="secondary" disabled={isChangeThumbnail || !this.state.post_title || !this.state.content || !this.props.categories.length} color="primary" onClick={this.addNewPost.bind(null, 'publish')}>Publish</Button>
                </GridItem>}
                <GridItem xs={12} md={4}>
                  <Button color="primary" onClick={this.props.onClose}>Cancel</Button>
                </GridItem>
              </GridContainer>
            </GridItem>}
          </GridContainer>
          }
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    )
  }
}

export default withMobileDialog()(AddPostForm);
