import React from "react";
import { uppercaseFirstLetter } from "../../../utils/utils.js";

const UserDetailsPreview = ({ userDetails: { firstName, lastName, email } }) => {
    if (!firstName || !lastName) {
        return null
    }
    return (
        <div style={{ backgroundColor: "white", textAlign: "center", marginBottom: "10px", paddingTop: "5px", paddingBottom: "15px", borderRadius: "5px" }}>
            <h3><strong>{`${uppercaseFirstLetter(firstName)} ${uppercaseFirstLetter(lastName)}`}</strong></h3>
            <strong>{email}</strong>
        </div>
    )
}

export default UserDetailsPreview;