import React from 'react';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import hamburger from './hamburger.svg';
import logo from '../../assets/img/pubninja-logo.png';
import './header.scss';

function toggleNav() {
  //document.getElementById('header-nav').classList.toggle('hidden');
  let navItems = document.getElementsByClassName('mobile-nav');
  for (let navItem of navItems) {
    navItem.classList.toggle('hidden');
  }
}

function Header(props) {
  const { hideSignIn = false } = props;
  return (
    <div className="onboard-header">
      <div className="onboard-header-inner">
        <div className="navbar-mob-top">
          <div className="navbar-logo">
            <a href="https://pubninja.com/">
              <img className="logo" src={logo} />
            </a>
          </div>
        </div>
        {!hideSignIn &&
          <div class="signin-button-container">
            <a href="https://dash.pubninja.com/login-home/login">
              <button class="signin-button">Sign In</button>
            </a>
          </div>
        }

      </div>
    </div>
  );
}

export default Header;
