import React, { PureComponent } from 'react';
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import debounce from "lodash.debounce";

import '../../assets/scss/website-styles.scss';
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import { loginInUser } from "../login-actions.js";
import { isValidEmailAddress } from "../../utils/utils.js";

import AppLoader from "../../app-components/app-loader.jsx";

import { onboardUser } from "../../onboard/onboarding-action";

import googleIcon from '../../assets/icons/google-icon.svg';

export function isEmptyStroyPage(isEmpty = false) {
  return isEmpty
}

class LoginForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      newUser: false,
      search:null,
      hasLoggedIn: false,
      cardAnimaton: "cardHidden",
      redirectToReferrer: props.location.state,
      email: "",
      password: "",
      registerEmail: "",
      firstName: "",
      lastName: "",
      error: {
        emailError: "",
        registerEmailError: "",
        passwordError: ""
      }
    };
    this.validateEmailAndUpdateErrorStatus = debounce(this.validateEmailAndUpdateErrorStatus, 200);
  }

  componentDidMount() {
    const { state } = this.props.location;
    let search = state && state.from && state.from.search;
    this.setState({ search });
    if(window.gapi)
      this.renderGoogleLoginButton();
    else
      window.addEventListener('google-loaded',this.renderGoogleLoginButton);
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  renderGoogleLoginButton = () => {
    window.gapi.signin2.render('g-signin2', {
    'onsuccess': this.onSignIn
  });
    window.gapi.signin2.render('g-signin2-2', {
    'onsuccess': this.onSignIn
  });
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  validateLoginData = () => {
    const { email, password='' } = this.state;
    let isValid = true;
    if (!isValidEmailAddress(email)) {
      isValid = false
      this.setState({
        error: {
          ...this.state.error,
          emailError: "Please Enter Valid Email!"
        }
      });
    }
    if (password.length < 2 || password.length > 20) {
      isValid = false;
      this.setState({
        error: {
          ...this.state.error,
          passwordError: "Please Enter Valid Password!"
        }
      })
    }
    return isValid;
  }

  loginUser = (e) => {
    e.preventDefault();
     this.setState({newUser: false});
    if (!this.validateLoginData()) {
      return;
    }
    this.props.loginInUser(this.state.email, this.state.password)
      .then(data => {
        if (data && data.userInfo && !data.userInfo.domains.length) {
          isEmptyStroyPage(true)
          this.props.history.push('/domain-management/addwebsite');
          return null;
        }
        const { redirectToReferrer } = this.state;
        let { from } = this.props.location.state || { from: { pathname: "/" } };
        if (redirectToReferrer) {
          this.props.history(from)
        } else {
          this.props.history.push("/landing");
        }
      }).catch(err => {

      });
  };

  setLoginInfo = (field, value) => {
    this.setState({ [field]: value })
  }
  //TODO
  validateEmailAndUpdateErrorStatus = (value) => {

  }
  setEmail = ({ target: { value } }) => {
    this.setLoginInfo("email", value);
    if (!isValidEmailAddress(value)) {
      this.setState({
        error: {
          ...this.state.error,
          emailError: "Please Enter Valid Email!"
        }
      })
    } else {
      this.setState({
        error: {
          ...this.state.error,
          emailError: ""
        }
      })
    }
  }

  setPassword = ({ target: { value } }) => {
    this.setLoginInfo("password", value);
    if (value.length < 2 || value.length > 20) {
      this.setState({
        error: {
          ...this.state.error,
          passwordError: "Please Enter Valid Password, min length 6 and max length 20!"
        }
      })
    } else {
      this.setState({
        error: {
          ...this.state.error,
          passwordError: ""
        }
      })
    }
  }

  forgotPassword = () => {
    this.props.history.push("/login-home/forgot-password");
  }

  onEmailChange = (event) => {
    this.setState({ registerEmail: event.target.value });
  }

  validateData = () => {
    const { registerEmail } = this.state;
    let isValid = true;

    if (!isValidEmailAddress(registerEmail)) {
      isValid = false;
      this.setState({
        error: {
          ...this.state.error,
          registerEmailError: "Please Enter Valid Email!"
        }
      });
    }
    return isValid;
  }

  handleGetStarted = () => {
    this.setState({ newUser: true })
    if (!this.validateData()) {
      return;
    }
    const { registerEmail, firstname, lastname, search} = this.state;
    if (registerEmail == '') {
      return;
    }
    this.props.onboardNewUser(registerEmail, firstname, lastname, search).then((res) => {
      this.props.history.push("/landing");
    }).catch(err => {
    }).finally(() => {
      this.setState({
        registerEmail: '',
        firstname: '',
        lastname: '',
      })
    });
  }

  onSignIn = (googleUser) => {
    this.setState({ hasLoggedIn: true });
    var profile = googleUser.getBasicProfile();
    this.props.loginInUser(profile.getEmail(),'google' , googleUser.getAuthResponse().id_token)
    .then(data => {
      if (data && data.userInfo && !data.userInfo.domains.length) {
        isEmptyStroyPage(true)
        this.props.history.push('/domain-management/addwebsite');
        return null;
      }
      const { redirectToReferrer } = this.state;
      let { from } = this.props.location.state || { from: { pathname: "/" } };
      if (redirectToReferrer) {
        this.props.history(from)
      } else {
        this.props.history.push("/landing");
      }
    }).catch(err => {

    });
  }


  render() {
    const { classes, isFetching, isAuthenticated } = this.props;

    const {
      redirectToReferrer,
      email,
      password,
      error: { emailError, passwordError }
    } = this.state;
    let { from } = this.props.location.state || { from: { pathname: "/" } };
    if (redirectToReferrer && isAuthenticated) return <Redirect to={from} />;

    return (
      <>
        <GridContainer>
          <GridItem xs={12} md={6}>
            <GridContainer>
              <GridItem xs={12} md={6}>
                <div className="login-heading">
                </div>
              </GridItem>
              <GridItem xs={12}>
                <form onSubmit={this.loginUser}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <div className="form-name">LOG IN </div>
                    </GridItem>
                    <GridItem xs={10} md={8} style={{ marginTop:'-15px'}}>
                      <CustomInput
                        labelText="EMAIL ADDRESS&#42;"
                        id="email"
                        value={email}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: this.setEmail,
                        }}
                        helpText={emailError}
                      />
                    </GridItem>
                    <GridItem xs={10} md={8} style={{ marginTop:'-45px'}}>
                      <CustomInput
                      labelText="PASSWORD&#42;"
                      id="password"
                      value={password}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputType="password"
                      inputProps={{
                        onChange: this.setPassword
                      }}
                      helpText={passwordError}
                    />
                    </GridItem>
                    <GridItem xs={12} style={{ marginTop:'-35px'}}>
                      <div className={classes.forgotPassword} onClick={this.forgotPassword}>Lost your password?</div>
                    </GridItem>
                    <GridItem xs={12} style={{display: 'flex'}}>
                      <Button type="submit" color="secondary" onClick={this.loginUser} style={{ pointerEvents: `${isFetching ? "none" : "auto"}` }}>
                        LOG IN
                        {/* isFetching && <AppLoader color="inherit" size={20} isSignOutLoader={true} /> */}
                      </Button>
                      {/* <div style={{margin: '19px 10px 0', fontSize: '0.7em'}}>
                        OR
                      </div>
                      <div style={{marginTop: '7px', display: 'flex'}} id="g-signin2-2"  onClick={() => {this.setState({newUser: false})}}></div> */}
                      {isFetching && !this.state.newUser && <AppLoader size={20} isSignOutLoader={true} />}
                      </GridItem>
                    </GridContainer>
                </form>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} md={6}>
            <GridContainer>
              {/* <GridItem xs={12}>
                <div className="login-heading signup-form ">
                  Be a Creator!
                </div>
                <div className="text-underline"></div>
              </GridItem> */}
              <GridItem xs={12}>
                {/* <form>
                  <GridContainer>
                    <GridItem xs={12}>
                      <div className="form-name">SIGN UP NOW!</div>
                    </GridItem>
                    <GridItem xs={12}>
                      <GridContainer>
                        <GridItem xs={10} md={8}>
                          <CustomInput
                            labelText="EMAIL ADDRESS&#42;"
                            id="registerEmail"
                            value={this.state.registerEmail}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              onChange: this.onEmailChange,
                            }}
                            helpText={this.state.error.registerEmailError}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} style={{marginTop: '-20px', display: 'flex',}}>
                      <Button color="secondary" onClick={this.handleGetStarted}>
                        CREATE NOW
                      </Button>
                      {isFetching && this.state.newUser && <AppLoader size={20} isSignOutLoader={true} />}
                    </GridItem>
                    <GridItem xs={12} style={{ marginTop:'-10px'}}>
                      <div className={classes.forgotPassword}>
                        <a href="https://pubninja.com/terms.html" style={{color: '#5d737e', fontWeight: 'normal'}}>By clicking on 'CREATE NOW' you agree to our <span style={{textDecoration: 'Underline'}}>TERMS & CONDITIONS.</span></a>
                      </div>
                    </GridItem>
                    </GridContainer>
                </form> */}
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </ >

    )
  }
}
const mapStateToProps = (state) => {
  const { isFetching, isAuthenticated } = state.login;
  return {
    isFetching: isFetching,
    isAuthenticated: isAuthenticated
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loginInUser: (email, password, token) => dispatch(loginInUser(email, password, token)),
    onboardNewUser: (email, firstname, lastname, search) => dispatch(onboardUser(email, firstname, lastname, search)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm));
