import React from "react";
import { Helmet } from "react-helmet";

import AccountInfo from './views/account-info.jsx';

function AccountInfoHomePage(props) {
  return (
    <>
    <Helmet title="Account Information | PubNinja - You Publish. The rest we manage." />
    <AccountInfo />
    </>
  )
}
export default AccountInfoHomePage;
